import React, { type ComponentProps, forwardRef } from 'react';
import { StyleSheet } from 'react-native';
import { HostedFrame, type HostedFrameRef, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { getEnvVars } from '@order/utils';

import { useIsApplePaySupported } from '../hooks';
import { FRAME_ID } from '../utils';

export const ApplePayHostedFrame = forwardRef<
  HostedFrameRef,
  ApplePayHostedFrameProps
>((props, ref) => {
  const { onMessage } = props;

  const { t } = useLocalizationContext();

  if (!useIsApplePaySupported()) return null;

  return (
    <HostedFrame
      ref={ref}
      frameId={FRAME_ID}
      frameHeight={0}
      title={t('general.apple-pay')}
      source={applePaySource}
      style={styles.content}
      containerStyle={styles.content}
      onMessage={onMessage}
    />
  );
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ApplePayHostedFrameProps = Pick<
  ComponentProps<typeof HostedFrame>,
  'ref' | 'onMessage'
>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  content: {
    flex: 0,
    height: 0,
    backgroundColor: theme.colors.OATMEAL,
    marginVertical: theme.spacing['1'],
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const applePaySource = {
  uri: getEnvVars().STRIPE_EMBEDDED_APPLE_PAY_PAYMENT_SHEET_URL,
};
