import React, { type ComponentProps } from 'react';
import { theme } from '@garnish/constants';
import { HorizontalScrollRail, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The rail that contains the reward cards for the bag.
 */
export const BagRewardsRail = (
  props: Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    'children' | 'heading'
  >,
) => {
  const { minWidth } = useResponsive();

  return (
    <HorizontalScrollRail
      withoutHeaderBorder
      heading={props.heading}
      headingIcon="IconRewardFill"
      showNavControls={minWidth.isSM}
      gap={theme.spacing['4']}
      outerOffset={-theme.spacing['4']}
      itemVisiblePercentThreshold={99}
      countPosition={minWidth.isSM ? 'left' : 'right'}
    >
      {props.children}
    </HorizontalScrollRail>
  );
};
