import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Container, theme, useResponsive } from '@sg/garnish';

import { type useCreditCardOptions } from '@order/hooks';

import type {
  GiftCardCheckoutFormFieldRules,
  GiftCardCheckoutFormReturn,
} from '../../form';
import { GiftCardCheckoutCardImage } from '../GiftCardCheckoutCardImage';
import { GiftCardCheckoutContainer } from '../GiftCardCheckoutContainer';
import { GiftCardCheckoutForm } from '../GiftCardCheckoutForm';
import { GiftCardCheckoutTitle } from '../GiftCardCheckoutTitle';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutContent = (
  props: GiftCardCheckoutContentProps,
) => {
  const {
    screenTitle,
    sectionTitles,
    giftCardImage,
    form,
    rules,
    paymentMethods,
    submitForm,
  } = props;

  const { minWidth } = useResponsive();

  // ─── Large Breakpoints ───────────────────────────────────────────────

  if (minWidth.isMD) {
    return (
      <GiftCardCheckoutContainer>
        <View style={styles.columnsContainer}>
          {giftCardImage ? (
            <View style={styles.imageColumn}>
              <GiftCardCheckoutCardImage
                url={giftCardImage.url}
                accessibilityLabel={giftCardImage.a11yLabel}
              />
            </View>
          ) : null}

          <ScrollView style={styles.formColumn}>
            <GiftCardCheckoutTitle>{screenTitle}</GiftCardCheckoutTitle>

            <GiftCardCheckoutForm
              sectionTitles={sectionTitles}
              form={form}
              rules={rules}
              paymentMethods={paymentMethods}
              submitForm={submitForm}
            />
          </ScrollView>
        </View>
      </GiftCardCheckoutContainer>
    );
  }

  // ─── Small Breakpoints ───────────────────────────────────────────────

  return (
    <GiftCardCheckoutContainer>
      <GiftCardCheckoutTitle>{screenTitle}</GiftCardCheckoutTitle>

      {giftCardImage ? (
        <Container>
          <GiftCardCheckoutCardImage
            url={giftCardImage.url}
            accessibilityLabel={giftCardImage.a11yLabel}
          />
        </Container>
      ) : null}

      <GiftCardCheckoutForm
        sectionTitles={sectionTitles}
        form={form}
        rules={rules}
        paymentMethods={paymentMethods}
        submitForm={submitForm}
      />
    </GiftCardCheckoutContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  columnsContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  imageColumn: {
    width: '50%',
    backgroundColor: theme.colors.LINEN,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['10'],
  },
  formColumn: {
    width: '50%',
    backgroundColor: theme.colors.OATMEAL,
    paddingTop: theme.spacing['12'],
    paddingBottom: theme.spacing['10'],
    paddingHorizontal: theme.spacing['15'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutContentProps = {
  screenTitle?: string;
  sectionTitles?: {
    amount: string;
    details: string;
    deliveryDate: string;
    deliveryMethod: string;
    recipientEmail: string;
    paymentMethod: string;
  };
  giftCardImage?: {
    url: string;
    a11yLabel: string;
  };
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
  paymentMethods: ReturnType<typeof useCreditCardOptions>['creditCardOptions'];
  submitForm: () => Promise<void>;
};
