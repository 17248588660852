import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Switch } from '@sg/garnish';

/**
 * Toggle for credit in the bag.
 */
export const BagCreditToggle = (props: BagCreditToggleProps) => {
  const { shouldUseCredit, availableCredit, appliedCredit, onChange } = props;

  const { formatNumber } = useIntl();

  const available = formatNumber(Number(availableCredit) / 100, {
    style: 'currency',
    currency: 'USD',
  });

  const applied = formatNumber(Number(appliedCredit) / 100, {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['14']}>
        <FormattedMessage {...messages.hint} values={{ available, applied }} />
      </BodyText>

      <Switch size="large" value={shouldUseCredit} onChange={onChange} />
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  hint: {
    defaultMessage: 'Use {applied} of {available} credits',
    description: 'Bag | Credit Toggle | Hint',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagCreditToggleProps = {
  availableCredit: number;
  appliedCredit: number;
  shouldUseCredit: boolean;
  onChange: (value: boolean) => void;
};
