import React from 'react';
import { useResponsive } from '@sg/garnish';

import { type BagScrollViewProps } from './BagScrollView.props';
import { BagScrollView as BagScrollViewSm } from './BagScrollView.sm';
import { BagScrollView as BagScrollViewXs } from './BagScrollView.xs';

/**
 * ScrollView for the bag.
 * On XS it has an animated header that is not needed on SM+.
 */
export const BagScrollView = (props: BagScrollViewProps) => {
  const { minWidth } = useResponsive();

  if (minWidth.isSM) {
    return <BagScrollViewSm {...props} />;
  }

  return <BagScrollViewXs {...props} />;
};
