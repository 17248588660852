import React from 'react';

import { MenuMoreInfoText } from '../MenuMoreInfoText';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoRestaurantAddress = (
  props: MenuMoreInfoRestaurantAddressProps,
) => {
  const { shortAddress, city, state, zipCode } = props;

  return (
    <MenuMoreInfoText>
      {formatRestaurantAddress({ shortAddress, city, state, zipCode })}
    </MenuMoreInfoText>
  );
};

/**
 * Returns the full address by combining short address, city, state and zip code.
 */
function formatRestaurantAddress(addressData: RestaurantAddressData) {
  const { shortAddress, city = '', state = '', zipCode = '' } = addressData;

  return [shortAddress, city, zipCode ? `${state} ${zipCode}` : state]
    .filter(Boolean)
    .join(', ')
    .trim();
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoRestaurantAddressProps = RestaurantAddressData;

type RestaurantAddressData = {
  shortAddress: string;
  city?: string;
  state?: string;
  zipCode?: string;
};
