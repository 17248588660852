import React from 'react';
import {
  BodyText,
  formatPhoneNumber,
  getIosModalPresentationConfig,
} from '@sg/garnish';

import { MenuMoreInfo } from '@order/components/menu';

import { Modal } from '../../../Modal';
import {
  type MenuContentOrderChannel,
  type MenuContentRestaurantData,
} from '../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentMoreInfoModal = (
  props: MenuContentMoreInfoModalProps,
) => {
  const { isVisible, onClose, restaurant, orderChannel, locationName } = props;

  const { phone, hours, address, city, state, zipCode } = restaurant;

  // ─────────────────────────────────────────────────────────────────────

  const formattedPhoneNumber = phone
    ? formatPhoneNumber({ phoneNumber: phone, shouldMinify: true }).national
    : null;

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderPhoneNumber = formattedPhoneNumber !== null;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      visible={isVisible}
      size="small"
      fitHeight
      onRequestClose={onClose}
      {...getIosModalPresentationConfig()}
    >
      <MenuMoreInfo.Container>
        <MenuMoreInfo.ModalHeader>
          <MenuMoreInfo.ModalCloseButton onPress={onClose} />

          <MenuMoreInfo.Heading
            orderChannel={orderChannel}
            locationName={locationName}
          />
        </MenuMoreInfo.ModalHeader>

        <MenuMoreInfo.RestaurantAddress
          shortAddress={address}
          city={city}
          state={state}
          zipCode={zipCode}
        />

        {shouldRenderPhoneNumber ? (
          <BodyText sizeMatch={['16']}>{formattedPhoneNumber}</BodyText>
        ) : null}

        <MenuMoreInfo.Timetable
          permanentHours={hours.permanent}
          storeHours={hours.store}
        />
      </MenuMoreInfo.Container>
    </Modal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentMoreInfoModalProps = {
  isVisible: boolean;
  onClose: () => void;
  orderChannel: MenuContentOrderChannel;
  locationName: string;
  restaurant: Pick<
    MenuContentRestaurantData,
    'phone' | 'hours' | 'flexMessage' | 'address' | 'city' | 'state' | 'zipCode'
  >;
};
