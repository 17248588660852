import React, { useRef } from 'react';
import type { PressableProps, ViewStyle } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../hooks';
import { switchcase } from '../../../utils';
import { BodyText } from '../../Text';
import { BagIcon } from './BagIcon';

export const ButtonBagIcon = (props: ButtonBagIconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { palette, bagCount, children, style, ...pressableProps } = props;

  const pressableRef = useRef(null);
  const { isHovered, isFocused } = usePressableState(pressableRef);

  // ────────────────────────────────────────────────────────────────────

  const pressableStyle = [
    styles.pressable,
    style,
    (isHovered || isFocused) && styles.pressableHover,
  ];
  const iconFill = switchcase({
    white: theme.colors.WHITE,
    black: theme.colors.BLACK,
    lime: theme.colors.LIME,
  })(theme.colors.WHITE)(palette);
  const iconStroke = switchcase({
    white: theme.colors.WHITE,
    black: theme.colors.DARK_KALE,
    lime: theme.colors.DARK_KALE,
  })(theme.colors.WHITE)(palette);
  const countStyle = switchcase({
    white: styles.blackText,
    black: styles.whiteText,
    lime: styles.blackText,
  })(styles.blackText)(palette);

  // ────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableRef}
      accessibilityRole="button"
      style={pressableStyle}
      {...pressableProps}
    >
      <BagIcon stroke={iconStroke} fill={iconFill} />

      {bagCount === undefined ? null : (
        <View pointerEvents="none" style={styles.countWrapper}>
          <BodyText size={5} style={countStyle}>
            {bagCount > 0 ? bagCount : ''}
          </BodyText>
        </View>
      )}
    </Pressable>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    borderRadius: theme.radius.small,
    position: 'relative',
    width: 32,
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },

  countWrapper: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing['2'],
  },
  blackText: {
    color: theme.colors.TEXT_COLOR,
  },
  whiteText: {
    color: theme.colors.WHITE,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ButtonBagIconProps = Readonly<{
  palette: 'white' | 'black' | 'lime';
  bagCount?: number;
  style?: ViewStyle;
}> &
  PressableProps;
