import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AddressTypes, DisplayText, Map, ModalRow, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { DeliveryLocationCard } from '../DeliveryLocationCard';
import { RestaurantLocationCard } from '../RestaurantLocationCard';
import type { ConfirmLocationLayoutProps } from './ConfirmLocationLayout.types';
import { ConfirmLocationLayoutFooter } from './ConfirmLocationLayoutFooter';

export const ConfirmLocationLayoutSM = (props: ConfirmLocationLayoutProps) => {
  const {
    deliveryAddress,
    isLoading,
    onContinue,
    onMoreLocations,
    orderType,
    pins,
    region,
    restaurant,
  } = props;

  const { t } = useLocalizationContext();

  const isDelivery = orderType === AddressTypes.delivery;

  return (
    <View testID="confirm-location.container-sm" style={styles.container}>
      <View style={styles.mapContainer}>
        <Map
          draggable={false}
          showControls={false}
          region={region}
          pins={pins}
        />
      </View>

      <View style={styles.contentContainer}>
        <ModalRow>
          <DisplayText size={4} style={styles.headline}>
            {t('location.confirm-location', { channel: orderType })}
          </DisplayText>

          {isDelivery && deliveryAddress ? (
            <DeliveryLocationCard deliveryAddress={deliveryAddress} />
          ) : (
            <RestaurantLocationCard
              orderType={orderType}
              restaurant={restaurant}
            />
          )}
        </ModalRow>

        <ConfirmLocationLayoutFooter
          isLoading={isLoading}
          onMoreLocations={onMoreLocations}
          onContinue={onContinue}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 500,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.OATMEAL,
  },
  mapContainer: {
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
    width: '50%',
  },
  contentContainer: {
    flex: 1,
    width: '50%',
    justifyContent: 'space-around',
    paddingTop: 48,
  },
  headline: {
    marginBottom: theme.spacing['2'],
  },
});
