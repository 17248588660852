import { convert24HoursTo12Hours, getCurrentWeekDay } from '@sg/garnish';

/**
 * Generates a timetable based on the provided permanent and store (aka actual)
 * hours.
 */
export function generateStoreHoursTimetable(params: FormatHoursParams) {
  const { permanentHours, storeHours } = params;

  const allWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const permanentHoursMap = convertToHoursMap(permanentHours);
  const storeHoursMap = convertToHoursMap(storeHours);
  const currentWeekDay = getCurrentWeekDay();

  return allWeek.reduce<FormattedHoursGroup[]>((timetable, weekDay) => {
    const permanentHourGroup = permanentHoursMap.get(weekDay);
    const storeHourGroup = storeHoursMap.get(weekDay);
    const isToday = currentWeekDay.toLowerCase() === weekDay;

    // ─── Non-operating Day ───────────────────────

    if (!storeHourGroup && !permanentHourGroup) return timetable;

    // Handle temporary closed days

    // ─── Temporary Closed ────────────────────────

    if (permanentHourGroup && !storeHourGroup) {
      return [
        ...timetable,
        {
          day: weekDay,
          range: '-',
          isTemporary: true,
          isUnavailable: true,
          isToday,
        },
      ];
    }

    // ─── Store Hours ─────────────────────────────

    if (storeHourGroup) {
      const { start, end } = storeHourGroup;

      // NOTE: Some stores may have a modified timetable during holidays, etc.
      const isTemporary =
        start !== permanentHourGroup?.start || end !== permanentHourGroup?.end;
      const range = `${convert24HoursTo12Hours(start)} - ${convert24HoursTo12Hours(end)}`;

      return [
        ...timetable,
        {
          day: weekDay,
          range,
          isToday,
          isTemporary,
          isUnavailable: false,
        },
      ];
    }

    return timetable;
  }, []);
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Converts provided hour groups array to a Map.
 */
function convertToHoursMap(hourGroups: readonly HoursGroup[]) {
  return new Map<string, HoursGroup>(
    hourGroups.map((group) => [
      group.day.toLowerCase(),
      { day: group.day, start: group.start, end: group.end },
    ]),
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type FormatHoursParams = {
  permanentHours: readonly HoursGroup[];
  storeHours: readonly HoursGroup[];
};

type FormattedHoursGroup = {
  day: string;
  range: string;
  isToday: boolean;
  isTemporary: boolean;
  isUnavailable: boolean;
};

type HoursGroup = {
  day: string;
  start: string;
  end: string;
};
