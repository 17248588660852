import {
  BagCheckoutContainer,
  BagContentContainer,
  BagDialogItemWithoutPadding,
  BagDynamicModal,
  BagScrollView,
} from './Containers';
import { BagPaymentHeaderContainer } from './Containers/BagPaymentHeaderContainer';
import {
  BagCheckoutCta,
  BagContinueToBagCta,
  BagContinueToCheckoutCta,
  BagCreditToggle,
  BagFloatingCtas,
  BagMenuIconCta,
  BagViewMenuCta,
} from './Ctas';
import { BagUtensilsCta } from './Ctas/BagUtensilsCta';
import { BagDisclaimers, BagFooter, BagSubtotal } from './Footer';
import { BagGiftCardForm } from './GiftCard';
import {
  BagChannelHeader,
  BagHeaderContainer,
  BagLocationFromConnector,
  BagLocationHeader,
  BagLocationToConnector,
  BagTimeConnector,
  BagTimeHeader,
} from './Header';
import { BagHeaderLine } from './Header/BagHeaderLine';
import { BagLedger } from './Ledger';
import { BagPaymentMethodForm } from './PaymentMethodForm';
import {
  BagDropoffLocationPicker,
  BagPaymentMethodPicker,
  BagTimePicker,
} from './Pickers';
import {
  BagPromoField,
  BagPromoRewardsContainer,
  BagReward,
  BagRewardsRail,
} from './PromoRewards';
import { BagSelectedPaymentMethod } from './SelectedPaymentMethod';
import { BagEmptyState, BagLoadingState } from './States';
import { BagStripe } from './Stripe';
import {
  BagFutureDateWarning,
  BagRewardWarning,
  BagWarningModal,
} from './Warnings';

export { sumLedgerTotal } from './Ledger';

export const Bag = {
  //
  // ─── Containers ───────────────────────────────────────────────────────────

  DynamicModal: BagDynamicModal,
  ScrollView: BagScrollView,
  ContentContainer: BagContentContainer,
  CheckoutContainer: BagCheckoutContainer,
  PaymentHeaderContainer: BagPaymentHeaderContainer,
  DialogItemWithoutPadding: BagDialogItemWithoutPadding,

  // ─── States ───────────────────────────────────────────────────────────────

  LoadingState: BagLoadingState,
  EmptyState: BagEmptyState,

  // ─── Warnings ─────────────────────────────────────────────────────────────

  WarningModal: BagWarningModal,
  FutureDateWarning: BagFutureDateWarning,
  RewardWarning: BagRewardWarning,

  // ─── Pickers ──────────────────────────────────────────────────────────────

  TimePicker: BagTimePicker,
  DropoffLocationPicker: BagDropoffLocationPicker,
  PaymentMethodPicker: BagPaymentMethodPicker,

  // ─── Header ───────────────────────────────────────────────────────────────

  HeaderLine: BagHeaderLine,
  ChannelHeader: BagChannelHeader,
  HeaderContainer: BagHeaderContainer,
  LocationFromConnector: BagLocationFromConnector,
  LocationHeader: BagLocationHeader,
  LocationToConnector: BagLocationToConnector,
  TimeConnector: BagTimeConnector,
  TimeHeader: BagTimeHeader,

  // ─── Promo Code Rewards ───────────────────────────────────────────────────

  PromoRewardsContainer: BagPromoRewardsContainer,
  RewardsRail: BagRewardsRail,
  Reward: BagReward,
  PromoField: BagPromoField,

  // ─── Payment method ───────────────────────────────────────────────────────

  PaymentMethodForm: BagPaymentMethodForm,

  // ─── Giftcard ─────────────────────────────────────────────────────────────

  GiftCardForm: BagGiftCardForm,

  // ─── Footer ──────────────────────────────────────────────────────────

  Footer: BagFooter,
  Subtotal: BagSubtotal,
  Disclaimers: BagDisclaimers,

  // ─── Checkout ─────────────────────────────────────────────────────────────

  SelectedPaymentMethod: BagSelectedPaymentMethod,
  CreditToggle: BagCreditToggle,
  Ledger: BagLedger,
  Stripe: BagStripe,

  // ─── CTAs ─────────────────────────────────────────────────────────────────

  ViewMenuCta: BagViewMenuCta,
  MenuIconCta: BagMenuIconCta,
  UtensilsCta: BagUtensilsCta,
  ContinueToBagCta: BagContinueToBagCta,
  ContinueToCheckoutCta: BagContinueToCheckoutCta,
  FloatingCtas: BagFloatingCtas,
  CheckoutCta: BagCheckoutCta,
};
