import React, { useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { BodyText, Icon, theme, usePressableState } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagPaymentMethodAddCta = (props: AddCtaProps) => {
  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  return (
    <Pressable
      ref={ref}
      style={[styles.addCta, isInteracting ? styles.addCtaInteracting : null]}
      accessibilityRole="button"
      accessibilityLabel={props.title}
      onPress={props.onPress}
    >
      <Icon name="IconPlus" width={24} height={24} />

      <BodyText sizeMatch={['24']}>{props.title}</BodyText>
    </Pressable>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  addCta: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    margin: -theme.spacing['2'],
    padding: theme.spacing['2'],
  },
  addCtaInteracting: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.xxxlarge,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type AddCtaProps = {
  title: string;
  onPress: () => void;
};
