import React, { memo, useCallback } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { LI, UL } from '@expo/html-elements';
import * as Clipboard from 'expo-clipboard';
import { theme, useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { Location } from '../../LocationResultCard.types';
import {
  LocationResultsCardCTAButton,
  LocationResultsCardCTALink,
} from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultCardCTAs = memo(
  (props: LocationResultCardCTAsProps) => {
    const { phone, address, lng, lat } = props.location;

    const shouldRenderCallCTA = phone !== undefined;
    const shouldRenderUberCTA =
      address !== undefined && lat !== undefined && lng !== undefined;

    return (
      <UL style={styles.infoCTAList}>
        {shouldRenderCallCTA ? (
          <LI style={styles.infoCTAListItem}>
            <LocationResultsCardPhoneCTA phone={phone} />
          </LI>
        ) : null}

        <LI style={styles.infoCTAListItem}>
          <LocationResultsCardDirectionsCTA address={address} />
        </LI>

        {shouldRenderUberCTA ? (
          <LI style={styles.infoCTAListItem}>
            <LocationResultsCardUberCTA address={address} lng={lng} lat={lat} />
          </LI>
        ) : null}

        <LI style={styles.infoCTAListItem}>
          <LocationResultsCardCopyCTA address={address} />
        </LI>
      </UL>
    );
  },
);

// ─────────────────────────────────────────────────────────────────────────────

const LocationResultsCardPhoneCTA = memo(
  (props: Required<Pick<Location, 'phone'>>) => {
    const { phone } = props;
    const formattedPhoneNumber = phone.startsWith('+') ? phone : `+${phone}`;

    const { t } = useLocalizationContext();

    return (
      <LocationResultsCardCTALink
        label={t('location.details.call')}
        iconName="IconPhone"
        href={`tel:${formattedPhoneNumber}`}
      />
    );
  },
);

const LocationResultsCardDirectionsCTA = memo(
  (props: Pick<Location, 'address'>) => {
    const { address } = props;

    const { t } = useLocalizationContext();

    return (
      <LocationResultsCardCTALink
        label={t('location.details.directions')}
        iconName="IconMap"
        href={getLocationAddressGeoLink(address)}
      />
    );
  },
);

const LocationResultsCardUberCTA = memo(
  (props: Required<Pick<Location, 'address' | 'lat' | 'lng'>>) => {
    const { t } = useLocalizationContext();

    return (
      <LocationResultsCardCTALink
        label={t('location.details.uber')}
        iconName="IconCar"
        href={getLocationUberLink(props)}
      />
    );
  },
);

const LocationResultsCardCopyCTA = memo((props: Pick<Location, 'address'>) => {
  const { address } = props;

  const { t } = useLocalizationContext();
  const { push: addBanner } = useNoticeBannersStackContext();

  const copyToClipboard = useCallback(async () => {
    await Clipboard.setStringAsync(address);
    addBanner(
      {
        id: 'location-card-copy-address-banner',
        palette: 'success',
        text: t('location.details.feedback'),
      },
      true,
    );
  }, [addBanner, address, t]);

  return (
    <LocationResultsCardCTAButton
      label={t('location.details.copy')}
      iconName="IconCopy"
      onPress={copyToClipboard}
    />
  );
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Returns cross-platform location address link
 */
function getLocationAddressGeoLink(address: Location['address']) {
  return (
    Platform.select({
      web: `https://www.google.com/maps/dir/?api=1&destination=${address}`,
      ios: `https://maps.apple.com/?daddr=${address}`,
      default: `https://www.google.com/maps/dir/?api=1&destination=${address}`,
    }) ?? ''
  );
}

/**
 * Returns Uber universal URL for the provided location
 */
function getLocationUberLink(
  location: Required<Pick<Location, 'address' | 'lng' | 'lat'>>,
) {
  const { address, lat, lng } = location;

  const uberLinkBase = 'https://m.uber.com/ul';
  const uberLinkSearchParams = {
    action: 'setPickup',
    client_id: 'sweetgreen',
    pickup: 'my_location',
    'dropoff[formatted_address]': encodeURIComponent(address),
    'dropoff[latitude]': lat,
    'dropoff[longitude]': lng,
  };

  return `${uberLinkBase}?${Object.entries(uberLinkSearchParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  infoCTAList: {
    marginTop: 0,
    marginBottom: theme.spacing['2'],
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  infoCTAListItem: {
    marginRight: 12,
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardCTAsProps = Readonly<{
  location: Location;
}>;
