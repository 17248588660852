/* eslint-disable functional/immutable-data */

import React, { forwardRef, useEffect } from 'react';
import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { logger as LOG } from '@garnish/logger';
import {
  ANIMATED_DIALOG_ANIMATION_CONFIG,
  HostedFrame,
  type HostedFrameMessageEvent,
  type HostedFrameRef,
  theme,
} from '@sg/garnish';

import { getEnvVars } from '@order/utils';

LOG.enable('BagStripe');
export const logger = LOG.extend('BagStripe');

/**
 * Wrapper for the stripe hosted field.
 */
export const BagStripe = forwardRef<HostedFrameRef, BagStripeProps>(
  (props, ref) => {
    const { show, setAvailableExpressPayments, onPaymentMethodId } = props;

    const handleMessages = useCallback(
      (event: HostedFrameMessageEvent) => {
        const message = parseMessage(event);

        // Logging messages.
        if (message.log) logger.info(message);

        // Payment request setup result.
        if (message.paymentRequestReady || message.stripeFailure) {
          setAvailableExpressPayments({
            canApplePay: Boolean(message.canApplePay),
            canGooglePay: Boolean(message.canGooglePay),
          });
        }

        // Payment processing result.
        if (message.paymentMethodId) {
          onPaymentMethodId(message.paymentMethodId);
        }
      },
      [setAvailableExpressPayments, onPaymentMethodId],
    );

    const hiddenStyle = show ? null : styles.hidden;

    // ─── Opacity Animation ──────────────────────────────────────────────────

    const opacitySV = useSharedValue(0);

    const opacityStyle = useAnimatedStyle(() => {
      return { opacity: opacitySV.value };
    }, []);

    useEffect(() => {
      opacitySV.value = withTiming(
        show ? 1 : 0,
        ANIMATED_DIALOG_ANIMATION_CONFIG,
      );
    }, [opacitySV, show]);

    return (
      <Animated.View style={[styles.container, hiddenStyle, opacityStyle]}>
        <HostedFrame
          ref={ref}
          frameId="BAG.STRIPE"
          frameHeight={theme.spacing['24']}
          source={source}
          onMessage={handleMessages}
        />
      </Animated.View>
    );
  },
);

// ─── Helpers ────────────────────────────────────────────────────────────────

const parseMessage = (event: HostedFrameMessageEvent) => {
  const dataString = event.nativeEvent.data ?? '{}';
  const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

  return parsedData;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    minHeight: theme.spacing['24'],
  },
  hidden: {
    display: 'none',
  },
  disabled: {
    opacity: 0.4,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type HostedFieldsPayload = Readonly<{
  log?: string;
  paymentMethodId?: string;
  paymentRequestReady?: boolean;
  stripeFailure?: boolean;
  canApplePay?: boolean;
  canGooglePay?: boolean;
}>;

type BagStripeProps = {
  show: boolean;
  onPaymentMethodId: (paymentMethodId: string) => void;
  setAvailableExpressPayments: (params: PaymentAvailabilityParams) => void;
};

type PaymentAvailabilityParams = {
  canApplePay: boolean;
  canGooglePay: boolean;
};

// ─── Source ─────────────────────────────────────────────────────────────────

const source = {
  uri: getEnvVars().STRIPE_EMBEDDED_PAYMENT_URL,
};
