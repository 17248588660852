import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { theme } from '@garnish/constants';

export const BagIcon = (props: BagIconProps) => {
  const { stroke = theme.colors.DARK_KALE, fill = theme.colors.LIME } = props;

  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      focusable={false}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.33398V29.334H28V9.33398H4Z"
        fill={fill}
        stroke={stroke}
      />
      <Path
        d="M10 9.33268C10 8.66602 10 7.59935 10 5.99935C10 3.99935 13.3333 2.66602 16 2.66602C18.6667 2.66602 22 3.99935 22 5.99935C22 7.59935 22 8.66602 22 9.33268"
        stroke={stroke}
      />
    </Svg>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

type BagIconProps = Readonly<{
  stroke?: string;
  fill?: string;
}>;
