import React, { memo, useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import Animated, {
  useAnimatedRef,
  useSharedValue,
} from 'react-native-reanimated';
import { useNavigation } from '@react-navigation/native';
import {
  AnimatedDialog,
  AnimatedDialogItem,
  AnimatedHeader,
  BodyText,
  useKeyboardAvoiderPadding,
} from '@sg/garnish';

import {
  APPLE_PAY,
  Bag,
  GOOGLE_PAY,
  sumLedgerTotal,
} from '@order/features/ordering';
import { StripeCreditCardForm } from '@order/integrations';

import {
  useExpressPayments,
  type useOrderState,
  useUpdatePaymentAmount,
  useUpdateStripeLoading,
} from '../../hooks';
import { BagDeliveryPreferencesForm } from '../BagDeliveryPreferencesForm';
import { BagTipping } from '../BagTipping';

/**
 * Animated dialog that shows up when choosing options in the bag.
 */
export const BagCheckout = memo((props: BagCheckoutProps) => {
  const { stripeReference, isExpressPaymentsEnabled } = useExpressPayments();
  const { orderState } = props;

  // ─── Flags ────────────────────────────────────────────────────────────────

  const {
    isFetchingInitialData,
    isChangingTime,
    isChangingDeliveryPreferences,
    isSubmittingOrder,
    isAddingPaymentMethod,
    isApplyingGiftCard,
    isPerformingBagAction,
    isShowingCheckoutLedger,
    isChangingPaymentMethod,
    isShowingPaymentMethodForm,
    isShowingGiftCardForm,
    isShowingModal,
  } = orderState;

  // ─── Context ──────────────────────────────────────────────────────────────

  const {
    wantedTime = '',
    selectedTip,
    paymentMethodId,
    cart,
    paymentMethods,
    availableCredit,
    shouldUseCredit,
    expressPayments: { canApplePay, canGooglePay },
  } = orderState;

  // ─── Cart ─────────────────────────────────────────────────────────────────

  const {
    orderChannel = 'pickup',
    restaurantId = '',
    deliveryOrderDetail,
    deliveryPreferences,
    wantedTimes,
    ledger,
  } = cart;

  const { creditToBeApplied, total } = sumLedgerTotal(
    ledger,
    selectedTip,
    availableCredit,
    shouldUseCredit,
  );

  // ─── Actions ──────────────────────────────────────────────────────────────

  const {
    cancelRequest,
    changeDeliveryPreferences,
    changeTime,
    changeTip,
    requestPaymentMethodChange,
    changePaymentMethod,
    changeCreditUsage,
    requestPaymentMethodForm,
    submitPaymentMethodForm,
    requestGiftCardForm,
    submitGiftCardForm,
    setAvailableExpressPayments,
    requestOrderSubmission,
    goBack,
  } = orderState;

  // ─── Removing native header ───────────────────────────────────────────────
  // This is removed here for now because the other bag screen (v1) still uses it.

  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation]);

  // ─── Express Payments ─────────────────────────────────────────────────────

  const isPayingWithExpressPayment =
    paymentMethodId === APPLE_PAY || paymentMethodId === GOOGLE_PAY;

  const shouldShowExpressPayment =
    isShowingCheckoutLedger && isPayingWithExpressPayment;

  useUpdatePaymentAmount(stripeReference, total, isFetchingInitialData);
  useUpdateStripeLoading(stripeReference, isSubmittingOrder);

  // ─── Animated Dialog Scrolling ────────────────────────────────────────────

  const animatedDialogScrollRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useSharedValue(0);
  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // eslint-disable-next-line functional/immutable-data
      scrollOffset.value = event.nativeEvent.contentOffset.y;
    },
    [scrollOffset],
  );

  // ─── Keyboard Avoiding Padding ────────────────────────────────────────────

  const { keyboardPadding } = useKeyboardAvoiderPadding();
  const contentContainerStyle = [
    keyboardPadding,
    styles.dialogContentInnerContainer,
  ];

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <AnimatedDialog show={isShowingModal} dismissModal={cancelRequest}>
      <Animated.ScrollView
        ref={animatedDialogScrollRef}
        contentContainerStyle={contentContainerStyle}
        stickyHeaderIndices={[0]}
        onScroll={handleOnScroll}
      >
        {/* Header */}
        {isShowingModal ? (
          <Bag.DialogItemWithoutPadding>
            <AnimatedHeader
              palette="cream"
              scrollOffset={scrollOffset}
              onBackBtnPress={goBack}
              onCloseBtnPress={cancelRequest}
            >
              {isShowingCheckoutLedger ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.checkout} />
                </BodyText>
              ) : null}

              {isChangingTime ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.chooseTime} />
                </BodyText>
              ) : null}

              {isChangingDeliveryPreferences ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.editDeliveryPreferences} />
                </BodyText>
              ) : null}

              {isChangingPaymentMethod ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.selectPaymentOption} />
                </BodyText>
              ) : null}

              {isShowingPaymentMethodForm ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.addCard} />
                </BodyText>
              ) : null}

              {isShowingGiftCardForm ? (
                <BodyText sizeMatch={['22']}>
                  <FormattedMessage {...messages.redeemGiftCard} />
                </BodyText>
              ) : null}
            </AnimatedHeader>
          </Bag.DialogItemWithoutPadding>
        ) : null}

        {isChangingTime ? (
          <AnimatedDialogItem>
            <Bag.TimePicker
              time={wantedTime}
              times={wantedTimes}
              changeTime={changeTime}
            />
          </AnimatedDialogItem>
        ) : null}

        {/* Delivery preferences form */}
        {isChangingDeliveryPreferences ? (
          <AnimatedDialogItem>
            <BagDeliveryPreferencesForm
              deliveryOrderDetail={deliveryOrderDetail}
              deliveryPreferences={deliveryPreferences}
              onSubmit={changeDeliveryPreferences}
            />
          </AnimatedDialogItem>
        ) : null}

        {/* Payment method picker */}
        {isChangingPaymentMethod ? (
          <AnimatedDialogItem>
            <Bag.PaymentMethodPicker
              paymentMethods={paymentMethods}
              paymentMethodId={paymentMethodId}
              canApplePay={canApplePay}
              canGooglePay={canGooglePay}
              onRequestPaymentMethodForm={requestPaymentMethodForm}
              onRequestGiftCardForm={requestGiftCardForm}
              changePaymentMethod={changePaymentMethod}
            />
          </AnimatedDialogItem>
        ) : null}

        {/* Payment method form */}
        {isShowingPaymentMethodForm ? (
          <AnimatedDialogItem>
            <Bag.PaymentMethodForm>
              <StripeCreditCardForm
                excludedFields={['nickname', 'isDefault']}
                palette="cream"
                isSubmitting={isAddingPaymentMethod}
                handleOnSave={submitPaymentMethodForm}
              />
            </Bag.PaymentMethodForm>
          </AnimatedDialogItem>
        ) : null}

        {/* Giftcard form */}
        {isShowingGiftCardForm ? (
          <AnimatedDialogItem>
            <Bag.GiftCardForm
              isLoading={isApplyingGiftCard}
              onSubmit={submitGiftCardForm}
            />
          </AnimatedDialogItem>
        ) : null}

        {/* Checkout ledger */}
        {isShowingCheckoutLedger ? (
          <Bag.DialogItemWithoutPadding>
            <Bag.CheckoutContainer>
              <View>
                <Bag.SelectedPaymentMethod
                  paymentMethodId={paymentMethodId}
                  paymentMethods={paymentMethods}
                  isDisabled={isSubmittingOrder}
                  requestPaymentMethodChange={requestPaymentMethodChange}
                  requestPaymentMethodForm={requestPaymentMethodForm}
                />

                {availableCredit > 0 ? (
                  <Bag.CreditToggle
                    shouldUseCredit={shouldUseCredit}
                    availableCredit={availableCredit}
                    appliedCredit={creditToBeApplied}
                    onChange={changeCreditUsage}
                  />
                ) : null}
              </View>

              <BagTipping
                restaurantId={restaurantId}
                orderChannel={orderChannel}
                selectedTip={selectedTip}
                subtotal={ledger?.subtotal ?? 0}
                isDisabled={isPerformingBagAction}
                onTipChange={changeTip}
              />

              <Bag.Ledger
                tip={selectedTip}
                ledger={ledger}
                availableCredit={availableCredit}
                shouldUseCredit={shouldUseCredit}
              />
            </Bag.CheckoutContainer>
          </Bag.DialogItemWithoutPadding>
        ) : null}

        {isShowingCheckoutLedger && !shouldShowExpressPayment ? (
          <Bag.DialogItemWithoutPadding>
            <Bag.CheckoutCta
              paymentMethodId={paymentMethodId}
              isLoading={isSubmittingOrder}
              isDisabled={isPerformingBagAction}
              onCheckout={requestOrderSubmission}
            />
          </Bag.DialogItemWithoutPadding>
        ) : null}

        {isExpressPaymentsEnabled ? (
          <Bag.Stripe
            show={shouldShowExpressPayment}
            ref={stripeReference}
            setAvailableExpressPayments={setAvailableExpressPayments}
            onPaymentMethodId={requestOrderSubmission}
          />
        ) : null}

        {isShowingCheckoutLedger ? (
          <Bag.DialogItemWithoutPadding>
            <Bag.Disclaimers orderChannel={orderChannel} />
          </Bag.DialogItemWithoutPadding>
        ) : null}
      </Animated.ScrollView>
    </AnimatedDialog>
  );
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  addCard: {
    defaultMessage: 'Add card',
    description: 'Checkout | Add card | Title',
  },
  checkout: {
    defaultMessage: 'Checkout',
    description: 'Checkout | Checkout | Title',
  },
  chooseTime: {
    defaultMessage: 'Choose a time',
    description: 'Checkout | Choose a time | Title',
  },
  editDeliveryPreferences: {
    defaultMessage: 'Edit delivery preferences',
    description: 'Checkout | Edit delivery preferences | Title',
  },
  selectPaymentOption: {
    defaultMessage: 'Select payment option',
    description: 'Checkout | Select payment option | Title',
  },
  redeemGiftCard: {
    defaultMessage: 'Redeem gift card',
    description: 'Checkout | Redeem gift card | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dialogContentInnerContainer: Platform.select({
    web: {
      flex: 1,
    },
    default: {
      flexGrow: 1,
    },
  }),
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagCheckoutProps = {
  orderState: ReturnType<typeof useOrderState>;
};
