import React, { type ComponentProps } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, PressableLink, theme } from '@sg/garnish';

import { Modal, SignedOutViewContent } from '@order/components';

import { giftCardsSignedOutModalMessages as messages } from './GiftCardsSignedOutModal.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsSignedOutModal = (
  props: GiftCardsSignedOutModalProps,
) => {
  const { isVisible, toggleModal, redirectTo } = props;

  const { formatMessage } = useIntl();

  return (
    <Modal
      visible={isVisible}
      accessibilityLabel={formatMessage(messages.modalLabel)}
      onRequestClose={toggleModal}
      size="small"
      fitHeight
    >
      <Modal.CloseBtn onClick={toggleModal} />

      <View>
        <SignedOutViewContent
          title={formatMessage(messages.modalTitle)}
          redirectTo={redirectTo}
          onJoinOrSignInaBtnPress={toggleModal}
        />

        <View style={styles.guestLinkContainer}>
          <PressableLink
            to={EXTERNAL_GIFT_CARD_PURCHASE_URL}
            onPress={toggleModal}
          >
            <BodyText sizeMatch={['14']} underline style={styles.guestLinkText}>
              <FormattedMessage {...messages.continueAsGuest} />
            </BodyText>
          </PressableLink>
        </View>
      </View>
    </Modal>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const EXTERNAL_GIFT_CARD_PURCHASE_URL =
  'https://sweetgreen.myguestaccount.com/en-us/guest/egift?page=cardInfo';

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  guestLinkContainer: {
    alignItems: 'center',
    paddingBottom: theme.spacing[6],
  },
  guestLinkText: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsSignedOutModalProps = {
  isVisible: boolean;
  toggleModal: () => void;
  redirectTo: RedirectTo;
};

type RedirectTo = ComponentProps<typeof SignedOutViewContent>['redirectTo'];
