import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { theme } from '@garnish/constants';

import { Container } from '../../Container';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders absolutely positioned header component that uses fade in/out transitions based
 * on the scroll position shared value (provided externally).
 */
export const FloatingHeader = (props: FloatingHeaderProps) => {
  const { children, scrollDistance, offset = 0 } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerAnimatedStyles = useMemo(() => {
    // NOTE: We begin the fade in transition, after reaching the actual offset point.
    const fadeInTransitionStartPosition = offset + 1;
    const fadeInTransitionEndPosition = offset + FLOATING_HEADER_HEIGHT;

    return {
      opacity: scrollDistance.interpolate({
        inputRange: [
          fadeInTransitionStartPosition,
          fadeInTransitionEndPosition,
        ],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      }),

      // NOTE: We set `zIndex` conditionally to avoid covering
      //       content until the header is visible
      zIndex: scrollDistance.interpolate({
        inputRange: [
          fadeInTransitionStartPosition - 1,
          fadeInTransitionStartPosition,
        ],
        outputRange: [-1, 1],
        extrapolate: 'clamp',
      }),
    };
  }, [offset, scrollDistance]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={[styles.header, headerAnimatedStyles]}>
      <SafeAreaView edges={['top']}>
        <Container
          wrapperStyle={styles.headerOuterContainer}
          style={styles.headerInnerContainer}
        >
          {children}
        </Container>
      </SafeAreaView>
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

export const FLOATING_HEADER_HEIGHT = 72;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    opacity: 0,
    backgroundColor: theme.colors.OATMEAL,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
  headerOuterContainer: {
    height: FLOATING_HEADER_HEIGHT,
    paddingVertical: theme.spacing['2'],
  },
  headerInnerContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FloatingHeaderProps = Readonly<{
  children: ReactNode;
  scrollDistance: Animated.Value;
  offset?: number;
}>;
