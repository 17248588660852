import React from 'react';
import { formatGoogleAddress } from '@sg/garnish';

import { CostChannel, type Location } from '@order/graphql';
import { useResetLastInteractedMenuStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { LoadingAnimation, NotFoundView } from '../../components';
import { useHeaderRight } from '../../components/Header/Header.hooks';
import type { HeaderIconItems } from '../../components/Header/Header.props';
import { useDeliveryOrderInFlight } from '../../hooks';
import type { DeliveryMenuScreenProps } from '../../navigation/AppNavigation.props';
import { MenuScreenContent } from '../MenuScreen/MenuScreen.content';
import { useMenuScreenReset } from '../MenuScreen/MenuScreen.navigation';
import { MenuHeaderTitle } from '../MenuScreen/subcomponents';
import { DeliveryMenuScreenV2 } from './DeliveryMenuScreenV2';
import {
  useAddressQuery,
  useDeliveryRestaurantQuery,
} from './GraphQL/DeliveryRestaurant.query.generated';

// ────────────────────────────────────────────────────────────────────────────────

export const DeliveryMenuScreen = (props: DeliveryMenuScreenProps) => {
  const isMenuRedesignEnabled = useFeatureFlag(
    'CELS-2852-redesign-menu-enabled',
  );

  if (isMenuRedesignEnabled) {
    return <DeliveryMenuScreenV2 />;
  }

  return <DeliveryMenuScreenLegacy {...props} />;
};

const DeliveryMenuScreenLegacy = ({
  route,
  navigation,
}: DeliveryMenuScreenProps) => {
  const { addressId } = route.params ?? {};

  const { t } = useLocalizationContext();
  const resetLastInteractedMenuStore = useResetLastInteractedMenuStore();

  useMenuScreenReset(navigation);

  // ───── In Flight Warning ──────

  useDeliveryOrderInFlight(); // results later used from cache.

  // ─────────── Queries ──────────
  const [
    {
      fetching: fetchingRestaurant,
      data: restaurantData,
      stale: isStaleRestaurantData,
    },
  ] = useDeliveryRestaurantQuery({
    variables: {
      input: { addressId },
      costChannel: CostChannel.DeliveryCostChannel,
    },
    requestPolicy: 'cache-and-network',
    pause: !addressId,
  });
  const [
    { fetching: fetchingAddress, data: addressData, stale: isStaleAddressData },
  ] = useAddressQuery({
    variables: { addressId },
    requestPolicy: 'cache-and-network',
    pause: !addressId,
  });

  // ─────────── Data ─────────────
  const {
    location,
    estimate,
    restaurant,
    address,
    deliveryDetails,
    estimateHeader,
  } = useDeliveryMenuData({ addressId, restaurantData, addressData });

  const isStaleData = isStaleRestaurantData || isStaleAddressData;
  const fetching = fetchingRestaurant || fetchingAddress;

  // –––– HEADER ––––––––––

  const navigateToLocationsScreen = React.useCallback(() => {
    navigation.navigate('Locations');
  }, [navigation]);

  const headerLabel = t('menu.hero.title.delivery');

  const menuHeaderTitleFn = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components -- @REVIEW
    () => () => (
      <MenuHeaderTitle
        label={headerLabel}
        locationName={location}
        estimateHeader={estimate ? estimateHeader : undefined}
        onPress={navigateToLocationsScreen}
      />
    ),
    [
      headerLabel,
      location,
      estimate,
      estimateHeader,
      navigateToLocationsScreen,
    ],
  );

  const handlePressDietaryRestrictions = React.useCallback(() => {
    navigation.navigate('Modal', { screen: 'DietaryRestrictions' });
  }, [navigation]);

  const headerRightItems = React.useMemo<HeaderIconItems>(
    () => [
      {
        testID: 'nav-bar.dietary-restrictions',
        key: 'dietary-restrictions',
        icon: 'IconFilter',
        accessibilityLabel: t(
          'nav-bar.dietary-restrictions.accessibility-label',
        ),
        accessibilityHint: t('nav-bar.dietary-restrictions.accessibility-hint'),
        onPress: handlePressDietaryRestrictions,
      },
    ],
    [t, handlePressDietaryRestrictions],
  );

  const headerRight = useHeaderRight({ items: headerRightItems });

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: menuHeaderTitleFn,
      headerRight,
    });
  }, [navigation, menuHeaderTitleFn, headerRight]);

  // ─────────────────────────────────────────────────────────────────

  const shouldRedirectToNotFoundScreen =
    !fetching && !isStaleData && (!restaurant || !address);

  // ─────────────────────────────────────────────────────────────────

  if (fetching) return <LoadingAnimation />;

  if (shouldRedirectToNotFoundScreen) {
    return (
      <NotFoundView
        btnLabel={t('not-found-view.select-another-location')}
        onBtnPress={navigateToLocationsScreen}
        onMount={resetLastInteractedMenuStore}
      />
    );
  }

  if (!restaurant) return null;

  return (
    <MenuScreenContent
      restaurantSlug={restaurant?.slug}
      restaurantData={restaurant}
      deliveryDetails={deliveryDetails}
      navigation={navigation}
    />
  );
};

type DeliveryRestaurantQuery = ReturnType<typeof useDeliveryRestaurantQuery>;
type AddressQuery = ReturnType<typeof useAddressQuery>;
const useDeliveryMenuData = ({
  addressId,
  restaurantData,
  addressData,
}: Readonly<{
  addressId: string;
  restaurantData: DeliveryRestaurantQuery['0']['data'];
  addressData: AddressQuery['0']['data'];
}>) => {
  const { t } = useLocalizationContext();

  // Data Extraction
  const locationByAddress = restaurantData?.locationByAddress as Location;
  const address = addressData?.address;
  const deliveryAddress = formatGoogleAddress(address as never, false, false);

  // Location Name
  const location =
    address?.name ?? formatGoogleAddress(address as never, false, true);

  // Delivery ETA
  const start = locationByAddress?.estimateRange?.start;
  const end = locationByAddress?.estimateRange?.end;
  const estimate = start === end ? start : `${start}-${end}`;

  // Restaurant Data
  const restaurant = locationByAddress?.restaurant;
  const vendor = locationByAddress?.vendor ?? {};
  const deliveryFee = restaurant?.deliveryFee ?? 0;

  // Localization
  const estimateLabel = t('delivery.estimate', { estimate });
  const estimateHeader = t('delivery.estimate-header', { location, estimate });

  return React.useMemo(() => {
    const deliveryDetails = {
      addressId,
      addressName: address?.name ?? '',
      deliveryAddress,
      location,
      vendorRestaurantId: vendor.restaurantId,
      vendor: vendor.name,
      tip: 0,
      deliveryFee,
      ETA: estimateLabel,
    };

    return {
      location,
      restaurant,
      address,
      estimate,
      deliveryDetails,
      estimateHeader,
    };
  }, [
    addressId,
    address,
    deliveryAddress,
    restaurant,
    location,
    vendor.restaurantId,
    vendor.name,
    deliveryFee,
    estimateLabel,
    estimate,
    estimateHeader,
  ]);
};
