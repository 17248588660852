import React, { useCallback, useMemo } from 'react';
import type { ViewProps } from 'react-native';
import { CustomizableProductHeader } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { useProductDetailsScreenContext } from '../../../../ProductDetailsScreen.provider';
import { initialContext } from '../../../../state/ProductModifications.model';

export const ProductDetailsHeader = (props: ProductDetailsHeaderProps) => {
  const isSumCaloriesEnabled = useSumCaloriesFeatureFlag();

  // eslint-disable-next-line functional/immutable-data
  initialContext.isSumCaloriesEnabled = isSumCaloriesEnabled;

  const { t, formatPrice, formatNumber } = useLocalizationContext();
  const {
    customizeLineItemName,
    isModifiableProduct,
    modifications,
    product,
    quantity,
  } = useProductDetailsScreenContext();

  const { calories, netPriceChange, customName } = modifications;
  const cost = product?.cost ?? 0;

  const price = useMemo(() => {
    const formattedPrice = formatPrice(cost + netPriceChange, 'USD');

    if (quantity > 1) return `${formattedPrice}/${t('pdp.modifications.each')}`;

    return formattedPrice;
  }, [cost, quantity, t, formatPrice, netPriceChange]);

  const formattedCalories = useMemo(() => {
    const formattedCaloriesWithText = `${formatNumber(calories)} ${t(
      'general.calories-short',
    )}`;

    if (quantity > 1) {
      return `${formattedCaloriesWithText}/${t('pdp.modifications.each')}`;
    }

    return formattedCaloriesWithText;
  }, [calories, quantity, t, formatNumber]);

  const handleFinishEditing = useCallback(
    (text: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      customizeLineItemName(text);
    },
    [customizeLineItemName],
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <CustomizableProductHeader
      value={customName}
      price={price}
      calories={formattedCalories}
      isModifiableProduct={isModifiableProduct}
      textInputAccessibilityLabel={t('pdp.details.customize-line-item-name')}
      style={props.style}
      onFinishEditing={handleFinishEditing}
    />
  );
};

const useSumCaloriesFeatureFlag = () => {
  return useFeatureFlag('MNU-834-short-term-sum-calories-enabled', {
    listenForChanges: true,
  });
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDetailsHeaderProps = Pick<ViewProps, 'style'>;
