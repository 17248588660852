import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DisplayText, theme, VStack } from '@sg/garnish';

import type {
  DeliveryOrderDetail,
  DeliveryOrderDetailInput,
  Product,
} from '@order/graphql';

import { UpsellProduct } from '../UpsellProduct';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellCategory = (props: UpsellCategoryProps) => {
  const {
    name,
    products,
    deliveryOrderDetail,
    upsellIdsBeingAdded,
    addUpsellToBag,
  } = props;

  return (
    <View>
      <DisplayText sizeMatch={['24']} style={styles.categoryName}>
        {name}
      </DisplayText>

      <VStack
        gap={theme.spacing['4']}
        hasDivider={true}
        dividerColor={theme.colors.LIGHT}
      >
        {products.map((product) => (
          <UpsellProduct
            key={product.id}
            product={product}
            deliveryOrderDetail={deliveryOrderDetail}
            isAddingUpsell={upsellIdsBeingAdded.includes(product.id)}
            addUpsellToBag={addUpsellToBag}
          />
        ))}
      </VStack>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  categoryName: {
    marginTop: theme.spacing['6'],
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UpsellCategoryProps = {
  name: string;
  products: {
    id: string;
    name: string;
    cost: number;
    channelCost?: number;
    imageUrl: string;
    outOfStock: boolean;
    menuId: number;
  }[];
  deliveryOrderDetail?: DeliveryOrderDetail;
  upsellIdsBeingAdded: string[];
  addUpsellToBag: (payload: {
    product: Partial<Product>;
    deliveryDetails?: DeliveryOrderDetailInput;
  }) => void;
};
