import React, { type PropsWithChildren, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, Text, View } from 'react-native';
import { LI, UL } from '@expo/html-elements';
import { theme } from '@garnish/constants';

import { MenuMoreInfoText } from '../MenuMoreInfoText';
import { generateStoreHoursTimetable } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoTimetable = (props: MenuMoreInfoTimetableProps) => {
  const { permanentHours, storeHours } = props;

  // ─── Derived Data ────────────────────────────────────────────────────

  const timetable = useMemo(
    () => generateStoreHoursTimetable({ permanentHours, storeHours }),
    [permanentHours, storeHours],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <UL style={styles.listContainer}>
        {timetable.map((hourGroup) => {
          const { day, range, isToday, isUnavailable, isTemporary } = hourGroup;

          const dayLowercase = day.toLowerCase();

          return (
            <LI key={day} style={styles.listItemContainer}>
              <MenuMoreInfoHoursText
                isUnavailable={isUnavailable}
                isToday={isToday}
              >
                <FormattedMessage
                  {...messages.weekDay}
                  values={{ day: dayLowercase, fallback: day }}
                />

                {isToday ? (
                  <Text>
                    {' '}
                    <FormattedMessage {...messages.todayLabel} />
                  </Text>
                ) : null}

                {isTemporary ? (
                  <Text>
                    {' '}
                    <FormattedMessage {...messages.temporaryHoursLabel} />
                  </Text>
                ) : null}
              </MenuMoreInfoHoursText>

              <MenuMoreInfoHoursText
                isUnavailable={isUnavailable}
                isToday={isToday}
              >
                {range}
              </MenuMoreInfoHoursText>
            </LI>
          );
        })}
      </UL>
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const MenuMoreInfoHoursText = (props: MenuMoreInfoHoursTextParams) => {
  const { children, isUnavailable, isToday } = props;

  const textStyles = [
    styles.text,
    isUnavailable ? styles.textUnavailable : undefined,
  ];

  return (
    <MenuMoreInfoText bold={isToday} style={textStyles}>
      {children}
    </MenuMoreInfoText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  weekDay: {
    defaultMessage: `{day, select,
      sun {Sun}
      mon {Mon}
      tue {Tue}
      wed {Wed}
      thu {Thu}
      fri {Fri}
      sat {Sat}
      other {{fallback}}
    }`,
    description: 'Menu | More info | Hours | Weekday',
  },
  todayLabel: {
    defaultMessage: '(Today)',
    description: 'Menu | More info | Hours | Today label',
  },
  temporaryHoursLabel: {
    defaultMessage: '(Temporary hours)',
    description: 'Menu | More info | Hours | Temporary hours label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['3'],
  },
  listContainer: {
    display: 'flex', // required
    flexDirection: 'column', // required
    padding: 0,
  },
  listItemContainer: {
    display: 'flex', // required
    flexDirection: 'row', // required
    columnGap: theme.spacing['2'],
    justifyContent: 'space-between',
  },

  text: {
    flexShrink: 0,
  },
  textUnavailable: {
    color: theme.colors.CAUTION,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoTimetableProps = {
  permanentHours: readonly HoursGroup[];
  storeHours: readonly HoursGroup[];
};

type MenuMoreInfoHoursTextParams = PropsWithChildren<{
  isToday: boolean;
  isUnavailable: boolean;
}>;

type HoursGroup = {
  day: string;
  start: string;
  end: string;
};
