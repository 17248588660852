import type { ComponentProps } from 'react';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import type { RouteProp } from '@react-navigation/native';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { LocationSearch, TransparentModal } from '@order/components';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import { useLocationConfirm } from '../../hooks';
import { LeftIcon, RightIcon } from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderProductSearchLocationScreen = () => {
  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'OrderProductSearchLocation'>>();
  const { productSlug: baseProductSlug } = params ?? {};

  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.getParent()?.goBack();
  }, [navigation]);

  const handleDismiss = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  const { currentBreakpoint } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const [selectedLocationData, setSelectedLocationData] =
    useState<SelectedLocation>();

  const { isFetchingProduct, isProductNotAvailable, handleLocationConfirm } =
    useLocationConfirm({
      baseProductSlug,
      restaurantId: selectedLocationData?.restaurantId,
      restaurantSlug: selectedLocationData?.restaurantSlug,
      deliveryAddressId: selectedLocationData?.deliveryOrderDetailAddressId,
      deliveryVendorName: selectedLocationData?.deliveryOrderDetailVendorName,
      deliveryVendorRestaurantId:
        selectedLocationData?.deliveryOrderDetailVendorRestaurantId,
      deliveryFee: selectedLocationData?.deliveryOrderDetailDeliveryFee,
    });

  const onOpenRestaurant = useCallback<OnOpenRestaurant>((context) => {
    const { activeLocation } = context;

    if (!activeLocation) return;

    const { restaurantId, restaurantSlug } = activeLocation;

    setSelectedLocationData({ restaurantId, restaurantSlug });
  }, []);

  const onOpenDeliveryRestaurant = useCallback<OnOpenDeliveryRestaurant>(
    (context) => {
      const { deliveryLocation } = context;

      if (!deliveryLocation) return;

      const {
        restaurantId,
        restaurantSlug,
        deliveryVendorRestaurantId,
        deliveryVendorName,
        restaurantDeliveryFee,
        addressId,
      } = deliveryLocation;

      setSelectedLocationData({
        restaurantId,
        restaurantSlug,
        deliveryOrderDetailAddressId: addressId,
        deliveryOrderDetailVendorName: deliveryVendorName,
        deliveryOrderDetailVendorRestaurantId: deliveryVendorRestaurantId,
        deliveryOrderDetailDeliveryFee: restaurantDeliveryFee,
      });
    },
    [],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: currentBreakpoint.isXS
        ? () => <LeftIcon onPress={handleGoBack} />
        : undefined,
      headerRight: currentBreakpoint.isXS
        ? () => <RightIcon onPress={handleDismiss} />
        : undefined,
      headerShown: currentBreakpoint.isXS,
    });
  }, [navigation, currentBreakpoint.isXS, handleGoBack, handleDismiss]);

  // navigate to the selected product details screen if it's available
  useEffect(() => {
    const isProductAvailable = selectedLocationData && !isFetchingProduct;

    if (isProductAvailable) {
      void handleLocationConfirm();
    }
  }, [
    isFetchingProduct,
    isProductNotAvailable,
    handleLocationConfirm,
    selectedLocationData,
  ]);

  // Reset state when navigating away
  useFocusEffect(
    useCallback(() => {
      setSelectedLocationData(undefined);
    }, []),
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!isFocused) return null;

  return (
    <TransparentModal
      testID="change-location"
      disableAnimation={currentBreakpoint.isXS}
      size="large"
      offset="10%"
      onClose={handleDismiss}
    >
      <LocationSearch
        navigateToLocation={onOpenRestaurant}
        navigateToDeliveryLocation={onOpenDeliveryRestaurant}
        interactedLocationRestaurantSlug={selectedLocationData?.restaurantSlug}
        interactedLocationDeliveryAddressId={
          selectedLocationData?.deliveryOrderDetailAddressId
        }
        withSafeAreaInsets
      />
    </TransparentModal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OnOpenRestaurant = NonNullable<LocationSearchProps['navigateToLocation']>;

type OnOpenDeliveryRestaurant = NonNullable<
  LocationSearchProps['navigateToLocation']
>;

type SelectedLocation = Readonly<{
  deliveryOrderDetailAddressId?: string;
  restaurantId?: string;
  restaurantSlug?: string;
  deliveryOrderDetailVendorName?: string;
  deliveryOrderDetailVendorRestaurantId?: string;
  deliveryOrderDetailDeliveryFee?: number;
}>;

type LocationSearchProps = ComponentProps<typeof LocationSearch>;
