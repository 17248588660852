import { Platform } from 'react-native';
import { FONTS, theme } from '@garnish/constants';
import { hostedFieldPostMessageSnippet } from '@sg/garnish';

import { getBaseUrl } from '@order/utils';

import { FRAME_HEIGHT } from './constants';

/**
 * JS-only implementation of the Stripe SDK.
 * This returns an HTML page to be injected into iFrames.
 * @see {@link https://stripe.com/docs/js/element}
 */

export function submitStripe() {
  return 'window.submitStripe();';
}

export function getStripeSource(props: StripeWebViewOptions) {
  const {
    stripeKey,
    clientSecret,
    postalCodeErrorMessage,
    backgroundColor = 'transparent',
  } = props;

  const viewPortMetaTag = Platform.select({
    native:
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />',
    default:
      '<meta content="width=device-width, initial-scale=1" name="viewport" />',
  });

  return {
    baseUrl: getBaseUrl(),
    html: `
    <html lang="en">
    <head>
        ${viewPortMetaTag}
        <title>Stripe</title>

        <script src="https://js.stripe.com/v3/"></script>
        <script type="text/javascript">

          // ─── React Connection ────────────────────────────────────────

          ${hostedFieldPostMessageSnippet}

          // ─── Styling Options ─────────────────────────────────────────

          const fontOptions = {
            fonts: [
              {
                family: '${fontFamily}',
                src: 'url(https://res.cloudinary.com/sweetgreen/raw/upload/v1663095073/fonts/woff2/SweetSansText-Regular_b8o7du.woff2)',
              }
            ]
          };

          const inputStyleOptions = {
            style: {
              base: {
                fontFamily: '${fontFamily}',
                fontSize: "16px",
                color: "${theme.colors.OPACITY.DARK_KALE.DARKEST}",
              },
            },
          };

          // ─── Stripe Initialization ───────────────────────────────────

          const stripeKey = "${stripeKey}";
          const clientSecret = "${clientSecret}";

          const stripe = Stripe(stripeKey);
          const elements = stripe.elements({ clientSecret, ...fontOptions });

          // ─── Elements Initialization ─────────────────────────────────

          const cardNumberElement = elements.create("cardNumber", {
            ...inputStyleOptions,
            showIcon: true,
          });
          const cardExpiryElement = elements.create(
            "cardExpiry",
            inputStyleOptions
          );
          const cardCvcElement = elements.create("cardCvc", {
            ...inputStyleOptions,
            placeholder: "123",
          });

          // ─── Mounting Elements ───────────────────────────────────────

          addEventListener("DOMContentLoaded", () => {
            cardNumberElement.mount("#card-number");
            cardExpiryElement.mount("#expiration-date");
            cardCvcElement.mount("#cvv");
          });

          // ─── Submitting Stripe ───────────────────────────────────────

         async function submitStripe() {
          postMessage({ log: 'Submitting', saving: true });

          try {
            const validZipCodeLengths = [5, 7, 9, 11];
            const postalCode = document.getElementById('zip-code-field')?.value?.trim();

            if (!postalCode || !document.getElementById('zip-code-field').checkValidity() || !validZipCodeLengths.includes(postalCode.length)) {
              postMessage({ error: { message: "${postalCodeErrorMessage}" }, saving: false });
              return;
            }

            const { error: submitError } = await elements.submit();
            const { error: confirmError, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
              payment_method: {
                card: cardNumberElement,
                billing_details: {
                  address: {
                    postal_code: postalCode,
                  },
                },
              },
            });

            if (setupIntent?.payment_method) {
              postMessage({ paymentMethodId: setupIntent.payment_method, saving: false });
              return
            }

            if (submitError) {
              postMessage({ error: submitError, saving: false });
              return
            }

            if (confirmError) {
              postMessage({ error: confirmError, saving: false });
              return
            }

            postMessage({ error: 'Unknown', saving: false });
          } catch(error) {
            postMessage({ error: error.message || error || 'Unknown', saving: false });
          }
        }
        </script>

        <!-- Font Family -->
        <style>
          @font-face {
            font-family: ${FONTS.SWEET_SANS_TEXT_REGULAR};
            font-display: auto;
            src: url(https://res.cloudinary.com/sweetgreen/raw/upload/v1663095073/fonts/woff2/SweetSansText-Regular_b8o7du.woff2);
          }
        </style>

        <style>
          html {
            height: ${FRAME_HEIGHT}px;
          }

          body {
            font-family: ${fontFamily};
            font-variant-ligatures: none;
            margin: 0;
            background-color: ${backgroundColor};
          }

          .container {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .row {
            display: flex;
            gap: 16px;
          }

          .column {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 4px;
          }

          .label {
            font-size: 12px;
            line-height: 18px;
            text-decoration-line: none;
            text-transform: uppercase;
          }

          .hosted-field {
            flex: 1;
            color: ${theme.colors.OPACITY.DARK_KALE.DARKEST};
            background-color: ${theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT};
            border-radius: ${theme.spacing['2']}px;
            position: relative;
            outline: 0;
            font-size: ${theme.spacing['4']}px;
            padding: ${theme.spacing['4']}px ${theme.spacing['3']}px
          }

          input {
            background-color: transparent;
            border: none;
            display: block;
            font-family: ${fontFamily};
            ${stripeFontStyles}
            margin: 0;
            padding: 0;
            width: 100%;
            color: ${theme.colors.OPACITY.DARK_KALE.DARKEST};
          }

          input:focus {
            outline: none;
          }
        </style>
      </head>

      <body>
        <div class="container">
          <div class="column">
            <label class="label" for="card-number">Card Number</label>
            <div id="card-number" class="hosted-field"></div>
          </div>

          <div class="row">
            <div class="column">
              <label class="label" for="expiration-date">Exp. Date</label>
              <div id="expiration-date" class="hosted-field"></div>
            </div>

            <div class="column">
              <label class="label" for="cvv">CVV</label>
              <div id="cvv" class="hosted-field"></div>
            </div>
          </div>

          <div class="column">
            <label class="label" for="zip-code">Zip Code</label>
            <div id="zip-code" class="hosted-field">
              <input
                id="zip-code-field"
                autocomplete="postal-code"
                autocorrect="off"
                spellcheck="false"
                type="text"
                name="zip-code"
                inputmode="numeric"
                pattern="[0-9]*"
                aria-label="Credit Zip Code"
                placeholder="12345"
                aria-invalid="false"
                tabindex="0"
              />
            </div>
          </div>
        </div>
      </body>
    </html>
  `,
  };
}

// ─── Types ─────────────────────────────────────────────────────────────

type StripeWebViewOptions = Readonly<{
  stripeKey: string | undefined;
  clientSecret: string | undefined;
  postalCodeErrorMessage: string;
  backgroundColor?: string;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const fontFamily = `${FONTS.SWEET_SANS_TEXT_REGULAR},system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif`;
const stripeFontStyles = `
  height: 1.2em;
  line-height: 1.2em;
  font-size: 16px;
`;
