import { Easing } from 'react-native-reanimated';

export const ANIMATION_CONFIG = {
  duration: 350,
  easing: Easing.out(Easing.exp),
};

// ────────────────────────────────────────────────────────────────────────────────

export const getMaxSwipeDistance = (snapPoints: SnapPoints) => {
  'worklet';

  return snapPoints.at(-1) || 0;
};

// ────────────────────────────────────────────────────────────────────────────────

export const clampVerticalSwipeDistance = (
  snapPoints: SnapPoints,
  swipeDistance: number,
) => {
  'worklet';

  const maxSwipeDistance = getMaxSwipeDistance(snapPoints);

  if (swipeDistance < 0) return 0;

  if (swipeDistance > maxSwipeDistance) return maxSwipeDistance;

  return swipeDistance;
};

// ────────────────────────────────────────────────────────────────────────────────

export const getSnapPointDistanceFromTop = (
  snapPoints: SnapPoints,
  targetSnapPointIndex: number,
) => {
  'worklet';

  const maxSwipeDistance = getMaxSwipeDistance(snapPoints);
  const snapPointDistance = snapPoints[targetSnapPointIndex] || 0;

  return maxSwipeDistance - snapPointDistance;
};

// ────────────────────────────────────────────────────────────────────────────────

export const getSwipeDirection = (swipeDistance: number) => {
  'worklet';

  return swipeDistance <= 0 ? 'up' : 'down';
};

// ────────────────────────────────────────────────────────────────────────────────

export const getFormattedSwipeDistanceFromTop = (
  snapPoints: SnapPoints,
  currentSwipeDistance: number,
) => {
  'worklet';

  const maxSwipeDistance = getMaxSwipeDistance(snapPoints);
  const clampedSwipeDistance = clampVerticalSwipeDistance(
    snapPoints,
    currentSwipeDistance,
  );

  return maxSwipeDistance - clampedSwipeDistance;
};

// ────────────────────────────────────────────────────────────────────────────────

export const getSnapPointIndexByDistanceAndDirection = ({
  snapPoints = [0],
  swipeDistance = 0,
  direction,
}: Readonly<{
  swipeDistance?: number;
  direction: 'up' | 'down';
  snapPoints?: SnapPoints;
}>) => {
  'worklet';

  // try to find the closest snap point based on swipe distance and direction
  const closestSnapPointIndex = snapPoints.findIndex(
    (snapPoint) => swipeDistance <= snapPoint,
  );
  const targetSnapPointIndex =
    direction === 'up' ? closestSnapPointIndex : closestSnapPointIndex - 1;

  // set fallback snap point based on the swipe direction
  const fallbackSnapPointIndex = direction === 'up' ? snapPoints.length - 1 : 0;

  // return closest snap point index or the fallback if it wasn't found
  return targetSnapPointIndex < 0
    ? fallbackSnapPointIndex
    : targetSnapPointIndex;
};

// ────────────────────────────────────────────────────────────────────────────────

export const checkIfFullyExpanded = (
  snapPoints: SnapPoints,
  currentSnapPointIndex: number,
) => {
  'worklet';

  return currentSnapPointIndex === snapPoints.length - 1;
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

export type SnapPoints = readonly number[];
