import React, { useEffect } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { Main } from '@expo/html-elements';
import { theme, useFunctionPolling, useResponsive } from '@sg/garnish';

import { AppFooter, PageWrapper } from '@order/components';
import { useCart } from '@order/hooks';

export const CheckoutWrapper = (props: ViewProps) => {
  const { fetchCart } = useCart();
  const { match } = useResponsive();

  const responsiveStyle = match([
    styles.wrapperMobile,
    styles.wrapperTablet,
    styles.wrapperDesktop,
  ]);
  const style = [styles.wrapper, responsiveStyle];

  const containerResponsiveStyle = match([
    {},
    styles.containerTabletUp,
    styles.containerDesktopUp,
  ]);
  const containerStyles = [styles.container, containerResponsiveStyle];

  // ─── EFFECTS ─────────────────────────────────────────────────────

  // fetch the cart on mount (and re-fetch on interval)
  useEffect(fetchCart, [fetchCart]);
  useFunctionPolling(30 * 1000, fetchCart);

  // ─────────────────────────────────────────────────────────────────

  return (
    <PageWrapper
      withHeaderTitle
      withoutFooter
      contentContainerStyle={style}
      testID="sg-order-checkout-wrapper"
    >
      <Main style={containerStyles}>{props.children}</Main>
      <AppFooter />
    </PageWrapper>
  );
};

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.colors.QUINOA,
  },
  wrapperMobile: {
    backgroundColor: theme.colors.OATMEAL,
  },
  wrapperTablet: {
    paddingTop: theme.spacing['6'],
  },
  wrapperDesktop: {
    paddingTop: theme.spacing['10'],
  },
  container: {
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
  },
  containerTabletUp: {
    maxWidth: 500,
  },
  containerDesktopUp: {
    maxWidth: 1024,
  },
});
