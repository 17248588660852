/* eslint-disable functional/immutable-data */

import type { RefObject } from 'react';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ViewStyle,
} from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import { PanGestureHandler, ScrollView } from 'react-native-gesture-handler';
import type { SharedValue } from 'react-native-reanimated';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';
import { measureElement, ShadowDivider, switchcase, theme } from '@sg/garnish';

import {
  BOTTOM_SHEET_SHADOW_HEIGHT,
  BOTTOM_SHEET_SHADOW_OFFSET,
  BOTTOM_SHEET_SHADOW_TOP_POSITION,
  BOTTOM_SHEET_TOP_HANDLER_HEIGHT,
  BOTTOM_SHEET_TOP_HANDLER_PLACEHOLDER_HEIGHT,
} from '../../constants';
import type { UseBottomSheetGesturesProps } from './BottomSheet.hooks';
import { useBottomSheetGestures } from './BottomSheet.hooks';

/**
 * "Bottom Sheet" component.
 *
 * Intended primarily as an interaction on mobile devices where
 * it can be used as an alternative to dialogs and menus.
 *
 * It supports multiple snap points and nested scroll.
 *
 * @param children Inner content.
 * @param snapPoints Snap points array. Points should be positive numbers in ascending order.
 * @param [initialSnapPointIndex]
 * @param [isSwipingDisabled] Disables swiping and hides the handler.
 * @param [ref] Optional React ref to assign sheet external controls (swipeTo, collapse, expand).
 *
 * This component is based on React Native Reanimated library.
 * @see https://docs.swmansion.com/react-native-reanimated/
 */
export const BottomSheet = memo(
  forwardRef((props: BottomSheetProps, ref) => {
    const {
      snapPoints = [0],
      initialSnapPointIndex = 0,
      isSwipingDisabled,
      children,
      outerContent,
      palette = 'oatmeal',
      dynamicColor,
      onSnapPointChange,
    } = props;

    const scrollViewRef = useRef<ScrollView>(null);

    const [isSwipingEnabled, setIsSwipingEnabled] =
      useState(!isSwipingDisabled);
    const [contentOffsetHeight, setContentOffsetHeight] = useState(0);

    // ─────────────────────────────────────────────────────────────────

    const {
      handleSwipeGestures,
      swipeToSnapPoint,
      isFullyExpanded,
      swipedDistance,
    } = useBottomSheetGestures({
      snapPoints,
      initialSnapPointIndex,
      isSwipingEnabled,
      onSnapPointChange,
    });

    const shouldRenderContentOffsetView =
      contentOffsetHeight > 0 && !isFullyExpanded;
    const contentOffsetStyle = useStyle(
      () => ({ height: contentOffsetHeight }),
      [contentOffsetHeight],
    );

    // ─── Helpers ─────────────────────────────────────────────────────

    // disable swiping while scrolling to prevent events collision (mobile only)
    const toggleNestedScroll = useCallback(
      (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        if (Platform.OS === 'web' || !isFullyExpanded) return;
        setIsSwipingEnabled(event.nativeEvent.contentOffset.y <= 0);
      },
      [isFullyExpanded],
    );

    const updateContentOffsetHeight = useCallback(async () => {
      const contentWrapperRef = scrollViewRef as unknown as RefObject<View>;
      const { height } = await measureElement(contentWrapperRef);

      setContentOffsetHeight(height);
    }, []);

    // ─── Effects ─────────────────────────────────────────────────────

    // assign helpers to the external ref for remote interactions.
    useEffect(() => {
      if (!ref) return;

      const externalControls = {
        scrollViewRef,
        swipedDistance,
        swipeTo: swipeToSnapPoint,
        collapse() {
          swipeToSnapPoint(0);
        },
        expand() {
          swipeToSnapPoint(snapPoints.length - 1);
        },
      };

      (ref as React.MutableRefObject<BottomSheetExternalControls>).current =
        externalControls;
    }, [
      ref,
      snapPoints.length,
      swipedDistance,
      isFullyExpanded,
      swipeToSnapPoint,
    ]);

    // reset nested <ScrollView> scroll position on collapse.
    useEffect(() => {
      const scrollView = scrollViewRef.current;

      return () => {
        const hasValidScrollView =
          scrollView?.scrollTo &&
          scrollView
            ?.getScrollResponder?.()
            ?.scrollResponderGetScrollableNode?.() &&
          scrollView.getScrollableNode?.();

        if (!isFullyExpanded || !hasValidScrollView) return;
        scrollView.scrollTo({ y: 0, animated: true });
      };
    }, [isFullyExpanded]);

    // enable/disable swiping on corresponding prop change
    useEffect(() => {
      setIsSwipingEnabled(!isSwipingDisabled);
    }, [isSwipingDisabled]);

    // update content offset height when content is changed
    useLayoutEffect(() => {
      void updateContentOffsetHeight();
    }, [children, updateContentOffsetHeight]);

    // ─── Palette Styles ──────────────────────────────────────────────

    const paletteContentStyle = switchcase({
      oatmeal: styles.bottomSheetOatmeal,
      kale: styles.bottomSheetKale,
    })(styles.bottomSheetOatmeal)(palette);
    const paletteHandlerStyle = switchcase({
      oatmeal: styles.handlerOatmeal,
      kale: styles.handlerKale,
    })(styles.bottomSheetOatmeal)(palette);

    // ─── Styles ──────────────────────────────────────────────────────

    const bottomSheetAnimatedStyles = useAnimatedStyle(() => {
      return {
        transform: [{ translateY: swipedDistance.value }],
      };
    });
    const bottomSheetStyle = [
      styles.bottomSheet,
      paletteContentStyle,
      !isFullyExpanded && styles.bottomSheetCollapsed,
      dynamicColor,
      bottomSheetAnimatedStyles,
    ];

    // ─────────────────────────────────────────────────────────────────

    return (
      <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
        <View style={styles.wrapperContainer} pointerEvents="box-none">
          <PanGestureHandler
            onGestureEvent={handleSwipeGestures}
            simultaneousHandlers={scrollViewRef}
            activeOffsetY={[-10, 10]}
            enabled={Platform.OS !== 'web'}
          >
            <Animated.View
              style={bottomSheetStyle}
              testID="bottom-sheet-container"
            >
              <BottomSheetShadow />

              {/* -------------------------------------------------------------------- */}
              {/*                                                                      */}
              {/*     The second pan gesture handler is used only for web platform     */}
              {/*     where the user can swipe the sheet only using the handler        */}
              {/*     because of the conflict between scroll and pan events.           */}
              {/*                                                                      */}
              {/*     For mobile platforms it's only used for presentation.            */}
              {/*                                                                      */}
              {/* -------------------------------------------------------------------- */}

              {isSwipingDisabled ? (
                <View style={styles.topSpacer} />
              ) : (
                <PanGestureHandler
                  onGestureEvent={handleSwipeGestures}
                  simultaneousHandlers={scrollViewRef}
                  enabled={Platform.OS === 'web'}
                >
                  <View
                    style={styles.handlerWrapper}
                    testID="bottom-sheet-handler"
                  >
                    <View style={[styles.handler, paletteHandlerStyle]} />
                  </View>
                </PanGestureHandler>
              )}

              <ScrollView
                ref={scrollViewRef}
                onScroll={toggleNestedScroll}
                scrollEventThrottle={16}
                scrollEnabled={isFullyExpanded}
                contentContainerStyle={styles.grow}
                bounces={false}
                overScrollMode="never"
                fadingEdgeLength={10}
                testID="bottom-sheet-inner-scroll-view"
              >
                {children}

                {shouldRenderContentOffsetView ? (
                  <View style={contentOffsetStyle} />
                ) : null}
              </ScrollView>

              {outerContent}
            </Animated.View>
          </PanGestureHandler>
        </View>
      </View>
    );
  }),
);

// ─── Subcomponents ──────────────────────────────────────────────────────────────

const BottomSheetShadow = () => (
  <View style={styles.shadowWrapper}>
    <ShadowDivider
      height={BOTTOM_SHEET_SHADOW_HEIGHT}
      offset={BOTTOM_SHEET_SHADOW_OFFSET}
      orientation="up"
    />
  </View>
);

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperContainer: {
    overflow: 'hidden',
    justifyContent: 'flex-end',
    flexGrow: 1,
    maxHeight: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  bottomSheetOatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
  bottomSheetKale: {
    backgroundColor: theme.colors.KALE,
  },
  bottomSheet: {
    height: '100%',
  },
  bottomSheetCollapsed: {
    borderTopStartRadius: theme.radius.medium,
    borderTopEndRadius: theme.radius.medium,
  },
  topSpacer: {
    height: BOTTOM_SHEET_TOP_HANDLER_PLACEHOLDER_HEIGHT,
  },
  handlerWrapper: {
    height: BOTTOM_SHEET_TOP_HANDLER_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  handler: {
    width: 64,
    height: 4,
    borderRadius: 2,
  },
  handlerOatmeal: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHT,
  },
  handlerKale: {
    backgroundColor: theme.colors.AVOCADO,
  },
  shadowWrapper: {
    position: 'absolute',
    top: BOTTOM_SHEET_SHADOW_TOP_POSITION,
    right: 0,
    left: 0,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

export type BottomSheetProps = Readonly<{
  children: React.ReactNode;
  outerContent?: React.ReactNode;
  palette?: 'kale' | 'oatmeal';
  dynamicColor?: ViewStyle;
  snapPoints: readonly number[];
  initialSnapPointIndex?: number;
  isSwipingDisabled?: boolean;
}> &
  Pick<UseBottomSheetGesturesProps, 'onSnapPointChange'>;

export type BottomSheetExternalControls = Readonly<{
  swipedDistance: SharedValue<number>;
  scrollViewRef: React.RefObject<ScrollView>;
  swipeTo: (snapPointIndex: number) => void;
  collapse: () => void;
  expand: () => void;
}>;
