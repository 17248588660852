import { createModel } from 'xstate/lib/model';

import type {
  Ingredient,
  IngredientModifications,
  MixedDressingDetails,
} from '../../../graphql/types';
import type { IngredientModificationWithQuantity } from '../types';
import type { ProductIngredientModifications } from './ProductModifications.types';

// ────────────────────────────────────────────────────────────────────────────────

export const initialContext: ProductIngredientModifications = {
  defaults: new Map(),
  ingredientsModifications: null,
  active: [],
  additions: [],
  removals: [],
  substitutions: [],
  lastKnownModifications: {
    active: [],
    additions: [],
    removals: [],
    substitutions: [],
  },
  limitations: {},
  mixedDressingDetails: [],
  calories: 0,
  netPriceChange: 0,
  maxModifications: 0,
  isCustom: false,
  isModifiable: false,
  customName: '',
  isSumCaloriesEnabled: false,
};

const PRODUCT_SETUP_EVENT = (
  ingredientsModifications: IngredientModifications,
  defaultIngredients: readonly Ingredient[],
  activeIngredients: readonly Ingredient[],
  maxModifications: number,
  mixedDressingDetails: readonly MixedDressingDetails[],
  isCustom: boolean,
  isModifiable: boolean,
  customName: string,
  calories?: number,
) => ({
  ingredientsModifications,
  defaultIngredients,
  activeIngredients,
  maxModifications,
  mixedDressingDetails,
  isCustom,
  isModifiable,
  customName,
  calories,
});

export const events = {
  // ─── GENERAL ────────────────────────────────────────────────────────

  SETUP: PRODUCT_SETUP_EVENT,
  UPDATE: PRODUCT_SETUP_EVENT,
  RESET: () => ({}),

  // ─── CUSTOMIZATION ──────────────────────────────────────────────────

  START_CUSTOMIZATION: () => ({}),
  CANCEL_CUSTOMIZATION: () => ({}),
  CONTINUE_CUSTOMIZATION: () => ({}),
  FINISH_CUSTOMIZATION: () => ({}),

  // ────────────────────────────────────────────────────────────────────

  ADD_INGREDIENT_MODIFICATION: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({ ingredientModification }),

  REMOVE_INGREDIENT_MODIFICATION: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({
    ingredientModification,
  }),

  TOGGLE_BREAD_INGREDIENT_MODIFICATION: () => ({}),

  // ─── DRESSING ───────────────────────────────────────────────────────

  SET_INITIAL_DRESSING_MODE: () => ({}),

  GENERATE_MIXED_DRESSINGS_DETAILS: () => ({}),

  TOGGLE_DRESSING_MODE: () => ({}),

  INCREASE_DRESSING_PORTION_NUMBER: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({
    ingredientModification,
  }),
  DECREASE_DRESSING_PORTION_NUMBER: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({
    ingredientModification,
  }),

  INCREASE_DRESSING_WEIGHT: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({
    ingredientModification,
  }),
  DECREASE_DRESSING_WEIGHT: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => ({
    ingredientModification,
  }),

  // ─── CUSTOM NAME ────────────────────────────────────────────────────

  CUSTOMIZE_LINE_ITEM_NAME: (customName: string) => ({ customName }),

  // ─── ERROR MESSAGES ─────────────────────────────────────────────────

  OUT_OF_STOCK_INGREDIENTS: (
    outOfStockModifications: IngredientModificationWithQuantity,
  ) => ({ outOfStockModifications }),
};

// ─── MODEL ──────────────────────────────────────────────────────────────────────

export const ProductModificationsModel = createModel(initialContext, {
  events,
});
