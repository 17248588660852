import React, { type ComponentProps, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import { useLocationResultsScrolling } from '../../../../hooks';
import { LocationResultCardLoading } from '../../../LocationResultCard';
import { locationResultsListsV2Messages as messages } from '../../LocationResultsListsV2.messages';
import { type LocationResultsListsV2Props } from '../../LocationResultsListsV2.types';
import { LocationResultsListsV2Card } from '../LocationResultsListsV2Card';
import { LocationResultsListsV2Group } from '../LocationResultsListsV2Group';
import { LocationResultsListsV2GroupStack } from '../LocationResultsListsV2GroupStack';
import { LocationResultsListsV2ViewMap } from '../LocationResultsListsV2ViewMap';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2Locations = (
  props: LocationResultsListsV2LocationsProps,
) => {
  const {
    variation,
    selectedLocationSearchType,
    recentLocations = [],
    nearbyLocations = { pickup: [], outpost: [] },
    locations = [],
    focusedLocationId,
    interactedLocationRestaurantSlug,
    interactedLocationDeliveryAddressId,
    isLoading,
    onRecentOrNearbyLocationCardPress,
    onLocationCardFocus,
    onViewMapPress,
    onLocationCardPress,
    onDeliveryLocationCardPress,
  } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const nearbyPickupLocations = nearbyLocations.pickup;
  const nearbyOutpostLocations = nearbyLocations.outpost;
  const hasNearbyLocations =
    (selectedLocationSearchType === 'pickup' &&
      nearbyPickupLocations.length > 0) ||
    (selectedLocationSearchType === 'outpost' &&
      nearbyOutpostLocations.length > 0);

  // ─────────────────────────────────────────────────────────────────────────────

  const {
    listScrollViewRef,
    setListItemScrollPosition,
    deleteListItemScrollPosition,
  } = useLocationResultsScrolling({
    activePinLocationId: focusedLocationId,
    isExpanded: true,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const locationCardProps = useMemo<LocationResultsListsV2CardProps>(
    () => ({
      variation,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      shouldShowLocationOrderChannel: false,
      onRecentOrNearbyLocationCardPress,
      onFocus: onLocationCardFocus,
      onLayout: setListItemScrollPosition,
      onUmount: deleteListItemScrollPosition,
      onLocationCardPress,
      onDeliveryLocationCardPress,
    }),
    [
      variation,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      onRecentOrNearbyLocationCardPress,
      onLocationCardFocus,
      setListItemScrollPosition,
      deleteListItemScrollPosition,
      onLocationCardPress,
      onDeliveryLocationCardPress,
    ],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const contentContainerStyle = [
    styles.scrollContentContainer,
    match([styles.scrollContentContainerXS, undefined]),
  ];

  const loadingContainerStyles = [
    styles.loadingContainer,
    match([styles.loadingContainerXS, undefined]),
  ];

  const loadingPlaceholders = selectedLocationSearchType === 'delivery' ? 1 : 5;

  // ─── Loading View ────────────────────────────────────────────────────

  if (isLoading) {
    return (
      <View style={styles.container}>
        <View style={loadingContainerStyles}>
          <LocationResultsListsV2GroupStack>
            {Array.from({ length: loadingPlaceholders }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <LocationResultCardLoading key={index} />
            ))}
          </LocationResultsListsV2GroupStack>
        </View>
      </View>
    );
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <ScrollView ref={listScrollViewRef} style={contentContainerStyle}>
        {onViewMapPress ? (
          <LocationResultsListsV2ViewMap onPress={onViewMapPress} />
        ) : null}

        {/* ─── Recent Locations ──────────────────────── */}

        {recentLocations.length > 0 ? (
          <LocationResultsListsV2Group
            heading={formatMessage(messages.recentLocationsHeading)}
          >
            {recentLocations.map((location) => (
              <LocationResultsListsV2Card
                key={location.id}
                location={location}
                {...locationCardProps}
                shouldShowLocationOrderChannel={true}
              />
            ))}
          </LocationResultsListsV2Group>
        ) : null}

        {/* ─── Nearby Locations ──────────────────────── */}

        {hasNearbyLocations ? (
          <LocationResultsListsV2Group
            heading={formatMessage(messages.nearbyLocationsHeading)}
          >
            {selectedLocationSearchType === 'pickup'
              ? nearbyPickupLocations.map((location) => (
                  <LocationResultsListsV2Card
                    key={location.id}
                    location={location}
                    {...locationCardProps}
                  />
                ))
              : null}

            {selectedLocationSearchType === 'outpost'
              ? nearbyOutpostLocations.map((location) => (
                  <LocationResultsListsV2Card
                    key={location.id}
                    location={location}
                    {...locationCardProps}
                  />
                ))
              : null}
          </LocationResultsListsV2Group>
        ) : null}

        {/* ─── Standard Locations ────────────────────── */}

        {locations.length > 0 ? (
          <LocationResultsListsV2Group>
            {locations.map((location) => (
              <LocationResultsListsV2Card
                key={location.id}
                location={location}
                {...locationCardProps}
              />
            ))}
          </LocationResultsListsV2Group>
        ) : null}
      </ScrollView>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  scrollContentContainer: {
    flex: 1,
    paddingHorizontal: theme.spacing[4],
  },
  scrollContentContainerXS: {
    paddingTop: theme.spacing[6],
  },
  loadingContainer: {
    paddingHorizontal: theme.spacing[4],
  },
  loadingContainerXS: {
    paddingTop: theme.spacing[4],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2LocationsProps = {
  onViewMapPress?: () => void;
} & Pick<
  LocationResultsListsV2Props,
  | 'variation'
  | 'selectedLocationSearchType'
  | 'locations'
  | 'nearbyLocations'
  | 'recentLocations'
  | 'isLoading'
  | 'interactedLocationRestaurantSlug'
  | 'interactedLocationDeliveryAddressId'
  | 'onRecentOrNearbyLocationCardPress'
  | 'onLocationCardPress'
  | 'onDeliveryLocationCardPress'
  | 'onLocationCardFocus'
  | 'focusedLocationId'
>;

type LocationResultsListsV2CardProps = Omit<
  ComponentProps<typeof LocationResultsListsV2Card>,
  'location'
>;
