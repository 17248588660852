import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { type DeliveryOrderDetail, type Order } from '@sg/graphql-schema';

import {
  type ChangeLineItemNameParams,
  type ChangeLineItemQuantityParams,
  type OrderingContext,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';

import {
  RemoveLineItemDocument,
  type RemoveLineItemMutation,
  type RemoveLineItemMutationVariables,
  UpdateLineItemDocument,
  type UpdateLineItemMutation,
  type UpdateLineItemMutationVariables,
} from '../../GraphQL/BagCart.generated';
import { prepareCart } from '../../utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook for updating the line item in the cart.
 * If a {quantity} of 0 is passed, it will call the remove mutation.
 * Otherwise it will call the update mutation for either quantity or custom name.
 */
export const useUpdateLineItem = () => {
  const client = useClient();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();

  // ─── Mutation ─────────────────────────────────────────────────────────────

  const updateLineItemMutation = useCallback(
    async (
      lineItem: PartialLineItem,
      params:
        | ChangeLineItemQuantityParams['params']
        | ChangeLineItemNameParams['params'],
    ) => {
      const updateMutation = client.mutation<
        UpdateLineItemMutation,
        UpdateLineItemMutationVariables
      >;

      const removeMutation = client.mutation<
        RemoveLineItemMutation,
        RemoveLineItemMutationVariables
      >;

      const isUpdateQuantityRequest = 'quantity' in params;
      const isUpdateNameRequest = 'customName' in params;

      if (isUpdateQuantityRequest && params.quantity === 0) {
        const response = await removeMutation(
          RemoveLineItemDocument,
          {
            input: {
              lineItemId: params.lineItemId,
            },
          },
          {
            requestPolicy: 'network-only',
          },
        ).toPromise();

        return response.data?.removeFromCart;
      }

      const quantityToUpdate = isUpdateQuantityRequest
        ? params.quantity
        : lineItem.quantity ?? 0;

      const nameToUpdate = isUpdateNameRequest
        ? params.customName
        : lineItem.customName;

      const response = await updateMutation(
        UpdateLineItemDocument,
        {
          input: {
            lineItemId: params.lineItemId,
            productId: params.productId,
            quantity: quantityToUpdate,
            customName: nameToUpdate,
            mixedDressingDetails: lineItem.mixedDressingDetails ?? [],
            additions: lineItem.addedIngredients?.map(getIngredientId) ?? [],
            removals: lineItem.removedIngredients?.map(getIngredientId) ?? [],
            substitutions: [],
          },
        },
        {
          requestPolicy: 'network-only',
        },
      ).toPromise();

      return response.data?.editLineItemInCart;
    },
    [client.mutation],
  );

  // ─── Helpers ──────────────────────────────────────────────────────────────

  const updateLineItem = useCallback(
    async (
      context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: ChangeLineItemQuantityParams | ChangeLineItemNameParams,
    ) => {
      const { lineItems } = context.cart;
      const lineItem = lineItems.find(({ id }) => id === params.lineItemId);

      if (!lineItem) return;

      const response = await updateLineItemMutation(lineItem, params);

      if (
        response?.__typename === 'EditLineItemInCartSuccess' ||
        response?.__typename === 'RemoveFromCartSuccess'
      ) {
        return prepareCart(response.cart as Partial<Order>);
      }

      const errorMessage =
        (response?.__typename === 'ValidationError'
          ? response?.message ?? response?.fieldErrors?.[0].message
          : response?.message) ?? formatMessage(messages.defaultError);

      addNoticeBanner({ text: errorMessage, palette: 'caution' });
    },
    [addNoticeBanner, formatMessage, updateLineItemMutation],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { updateLineItem };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const getIngredientId = (ingredient?: { id?: string }) => {
  return { ingredientId: ingredient?.id ?? '' };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  defaultError: {
    defaultMessage: 'Something went wrong, try again in a few moments...',
    description: 'Bag | Update Line Item | Default Error',
  },
});
