import React, { useCallback } from 'react';
import { Platform, Share } from 'react-native';
import { theme } from '@garnish/constants';

import { useLocalizationContext } from '@order/Localization';

import { ShareOption } from './ShareOption';
import type { ShareOptionProps } from './types';

export const MoreShareOption = ({ shareLink }: ShareOptionProps) => {
  const { t } = useLocalizationContext();

  const handleShare = useCallback(async () => {
    const shareContent = t('account.refer-friend.share.content', { shareLink });

    if (Platform.OS !== 'web') {
      await Share.share(
        {
          message: shareContent,
        },
        {
          tintColor: theme.colors.DARK_KALE,
        },
      );

      return;
    }

    await navigator?.share?.({ text: shareContent });
  }, [t, shareLink]);

  return (
    <ShareOption
      testID="account.refer-friend.share.more"
      hint={t('account.refer-friend.share.more')}
      iconName="IconPlus"
      iconColor="black"
      iconSize={24}
      bgColor={theme.colors.OATMEAL}
      borderColor={theme.colors.BLACK}
      handleShare={handleShare}
    />
  );
};
