import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { type AddressType } from '@sg/garnish';

import { BagHeaderLink } from '../BagHeaderLink';

/**
 * Location selector for the bag header.
 */
export const BagLocationHeader = (props: BagLocationHeaderProps) => {
  const {
    orderChannel,
    locationName,
    requestDeliveryPreferencesChange,
    requestChannelOrLocationChange,
  } = props;

  const { formatMessage } = useIntl();
  const accessibilityLabel = formatMessage(messages.accessibilityLabel, {
    location: locationName,
  });

  const onPress =
    orderChannel === 'delivery'
      ? requestDeliveryPreferencesChange
      : requestChannelOrLocationChange;

  return (
    <BagHeaderLink accessibilityLabel={accessibilityLabel} onPress={onPress}>
      {locationName}
    </BagHeaderLink>
  );
};

export const BagLocationFromConnector = () => {
  return <FormattedMessage {...messages.from} />;
};

export const BagLocationToConnector = () => {
  return <FormattedMessage {...messages.to} />;
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Change location: {location}',
    description: 'Bag | Header | Change location',
  },
  from: {
    defaultMessage: 'from',
    description: 'Bag | Header | From',
  },
  to: {
    defaultMessage: 'to',
    description: 'Bag | Header | To',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLocationHeaderProps = {
  locationName: string;
  orderChannel: AddressType;
  requestDeliveryPreferencesChange: () => void;
  requestChannelOrLocationChange: () => void;
};
