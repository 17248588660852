/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useResponsive, webOnlyStyles } from '@sg/garnish';

import { LocationSearchTypePicker } from '../../../LocationSearchTypePicker';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns `LocationSearchTypePicker` component render function to use it
 * as a custom `headerTitle` component for React Navigation
 */
export const useRenderLocationSearchTypePicker = (
  props: ComponentProps<typeof LocationSearchTypePicker>,
) => {
  const {
    locationSearchType,
    onLocationSearchTypeChange,
    isDisabled = false,
  } = props;

  const { currentBreakpoint } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyle = currentBreakpoint.isXS
    ? Platform.select({ web: locationsSearchTypeHeaderWebStyles })
    : styles.locationsSearchTypeHeader;

  // ─────────────────────────────────────────────────────────────────────

  return useCallback(
    () => (
      <View style={containerStyle}>
        <LocationSearchTypePicker
          locationSearchType={locationSearchType}
          onLocationSearchTypeChange={onLocationSearchTypeChange}
          isDisabled={isDisabled}
        />
      </View>
    ),
    [
      containerStyle,
      isDisabled,
      locationSearchType,
      onLocationSearchTypeChange,
    ],
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  locationsSearchTypeHeader: {
    flex: 1,
    alignItems: 'center',
  },
});

/**
 * The header title container in React Navigation has a `maxWidth` property to prevent title overflow.
 * Unfortunately, it causes layout issues in our situation because the content of this component is larger
 * than the specified `maxWidth`. Fortunately, the issue only occurs on the Web platform.
 *
 * There is a corresponding `headerTitleContainerStyle` property, which in theory can be used to reset
 * the previously mentioned `maxWidth`, but as of this writing (10/22) it is not working (prop is not passed).
 *
 * That is why we use the tried-and-true absolute position + transform approach to
 * bypass `maxWidth` and correctly place our custom header.
 */
const locationsSearchTypeHeaderWebStyles = webOnlyStyles({
  position: 'absolute',
  left: '-50%',

  // NOTE: TS is not happy about web VS native types collision here.
  transform: [{ translateX: '-50%' }] as any,
});
