import React, { useLayoutEffect } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { NoticeBannersStackProvider, useResponsive } from '@sg/garnish';

import { LoadingAnimation, NotFoundView } from '@order/components';
import type { Location } from '@order/graphql';
import { useDeliveryRestaurantQuery } from '@order/graphql';
import { useCustomScreenTitle } from '@order/hooks';
import { useSetLastInteractedMenuStore } from '@order/LastInteractedStore';

import { useReorderIfRequired } from '../../../ReorderingScreen';
import {
  ProductDetailsScreenProvider,
  useProductDetailsScreenContext,
} from '../../ProductDetailsScreen.provider';
import { ProductModals } from '../ProductModals';
import { ProductDetailsMD } from './ProductDetails.md';
import { ProductDetailsXS } from './ProductDetails.xs';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductDetails = (props: ProductDetailsProps) => {
  const { addressId, restaurantSlug, testID } = props;

  useSetLastInteractedMenuStore({ restaurantSlug, addressId });

  return (
    <ProductDetailsScreenProvider {...props}>
      <NoticeBannersStackProvider>
        <ProductDetailsContent testID={testID} />
      </NoticeBannersStackProvider>
    </ProductDetailsScreenProvider>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const DeliveryProductDetails = (props: ProductDetailsProps) => {
  const { addressId, ingredientIds, name: customName } = props;

  // ─── Queries ─────────────────────────────────────────────────────

  const [{ fetching, data }] = useDeliveryRestaurantQuery({
    variables: { input: { addressId } },
    pause: !addressId,
  });

  // ─── Data ────────────────────────────────────────────────────────

  const locationByAddress = data?.locationByAddress as Location;
  const restaurant = locationByAddress?.restaurant;
  const restaurantSlug = restaurant?.slug;
  const vendor = locationByAddress?.vendor;
  const deliveryFee = restaurant?.deliveryFee ?? 0;

  // ─── Render Control ──────────────────────────────────────────────

  if (fetching) return <LoadingAnimation />;

  if (data !== undefined && !restaurant) return <NotFoundView />;

  return (
    <ProductDetails
      {...props}
      restaurantSlug={restaurantSlug}
      vendor={vendor}
      ingredientIds={ingredientIds}
      deliveryFee={deliveryFee}
      name={customName}
      isDelivery
    />
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const ProductDetailsContent = ({
  testID = 'pdp',
}: ProductDetailsContentProps) => {
  const { minWidth } = useResponsive();
  const { fetching, product, error, addressId, restaurantSlug } =
    useProductDetailsScreenContext();
  const productName = product?.name ?? '';

  useReorderIfRequired(restaurantSlug, addressId);

  // ─── Navigation Options ──────────────────────────────────────────

  useCustomScreenTitle(productName);
  const navigation = useNavigation();

  useLayoutEffect(() => {
    navigation.setOptions({ title: productName });
  }, [navigation, productName]);

  // ─────────────────────────────────────────────────────────────────

  if (fetching) return <LoadingAnimation />;

  if (Boolean(error) || !product) return <NotFoundView />;

  return (
    <View style={styles.container} testID={testID?.concat('.container')}>
      {minWidth.isMD ? <ProductDetailsMD /> : <ProductDetailsXS />}

      <ProductModals />
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDetailsContentProps = Pick<ViewProps, 'testID'>;

type ProductDetailsProps = React.ComponentProps<
  typeof ProductDetailsScreenProvider
> &
  Pick<ViewProps, 'testID'> & { isDelivery?: boolean };
