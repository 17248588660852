import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, FadeView, LoadingPlaceholder, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { CreditCardFormLineItem } from './CreditCardFormLineItem';

/**
 * Placeholder component for credit card form loading state.
 */
export const CreditCardFormLoadingState = (
  props: CreditCardFormLoadingStateProps,
) => {
  return (
    <FadeView {...props} style={[styles.container, props.style]}>
      <CardNumber />

      <CreditCardFormLineItem style={styles.rowWithFlex}>
        <ExpirationDate />

        <CVV />
      </CreditCardFormLineItem>

      <PostalCode />
    </FadeView>
  );
};

const CardNumber = () => {
  const { t } = useLocalizationContext();

  return (
    <CreditCardFormLineItem style={styles.column}>
      <View style={styles.column}>
        <BodyText style={styles.label} sizeMatch={['12']}>
          {t('credit-card-form.card-number')}
        </BodyText>

        <LoadingPlaceholder rows={1} rowHeight={52} palette="cream" />
      </View>
    </CreditCardFormLineItem>
  );
};

const ExpirationDate = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={[styles.column, styles.marginRight]}>
      <BodyText style={styles.label} sizeMatch={['12']}>
        {t('credit-card-form.expiration-date')}
      </BodyText>

      <LoadingPlaceholder rows={1} rowHeight={52} palette="cream" />
    </View>
  );
};

const CVV = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={[styles.column, styles.marginLeft]}>
      <BodyText style={styles.label} sizeMatch={['12']}>
        {t('credit-card-form.cvv')}
      </BodyText>

      <LoadingPlaceholder rows={1} rowHeight={52} palette="cream" />
    </View>
  );
};

const PostalCode = () => {
  const { t } = useLocalizationContext();

  return (
    <CreditCardFormLineItem style={styles.column}>
      <BodyText style={styles.label} sizeMatch={['12']}>
        {t('credit-card-form.zip-code')}
      </BodyText>

      <LoadingPlaceholder rows={1} rowHeight={52} palette="cream" />
    </CreditCardFormLineItem>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 1,
    backgroundColor: theme.colors.OATMEAL,
    ...StyleSheet.absoluteFillObject,
  },
  rowWithFlex: {
    flex: 1,
    flexDirection: 'row',
  },
  label: {
    textTransform: 'uppercase',
  },
  column: {
    flex: 1,
    gap: theme.spacing['1'],
  },
  marginRight: {
    marginRight: theme.spacing['3'],
  },
  marginLeft: {
    marginLeft: theme.spacing['3'],
  },
});

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardFormLoadingStateProps = ComponentProps<typeof FadeView>;
