import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useStyle } from 'react-native-style-utilities';
import type { AddressType } from '@sg/garnish';
import {
  Modal,
  ScrollViewWithHeaderTitle,
  theme,
  useFluidSize,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

import { AppFooter, OrderToHealthKit, PageWrapper } from '@order/components';
import type {
  CourierDetails,
  DeliveryPreferenceType,
  FlattenedOrderStatuses,
  Ledger,
  PartialLineItem,
} from '@order/graphql';
import { usePermanentNoticeBanner } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { ignoreTimezone } from '@order/utils';

import { OrderStatusSweetpassUpgradeCta } from './components';
import { OrderStatusContactUs } from './components';
import { OrderStatusDetails } from './OrderStatusDetails';

export const OrderStatusLayout = (props: OrderStatusScreenProps) => {
  const { currentBreakpoint } = useResponsive();

  useLateNotice(props.isLate);

  if (currentBreakpoint.isXS) return <MobileOrderStatusScreen {...props} />;

  return <DesktopOrderStatusScreen {...props} />;
};

const DesktopOrderStatusScreen = ({
  info,
  children,
  shouldRenderSweetpassUpsellCta,
  orderId,
  restaurantId,
  canCancel,
  refetchOrderStatus,
  ...rest
}: OrderStatusScreenProps) => {
  const fluid = useFluidSize({ min: 1024, max: 1440 });
  const width = fluid(376, 478);
  const widthStyle = useStyle(() => ({ width }), [width]);
  const orderStatusContainerStyles = [
    styles.orderStatusContainer,
    widthStyle,
    webOnlyStyles({ userSelect: 'auto' }),
  ];

  const desktopContentStyles = [
    styles.desktopContent,
    webOnlyStyles({ userSelect: 'auto' }),
  ];

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  return (
    <PageWrapper contentContainerStyle={styles.pageWrapper}>
      <View
        testID="order-status.desktop.container"
        style={styles.desktopScreenContainer}
      >
        <View style={styles.desktopMapContainer}>{children}</View>
        <View style={orderStatusContainerStyles}>
          <View style={styles.infoContainer}>{info}</View>
          <View style={styles.divider} />
          <ScrollView style={desktopContentStyles}>
            {shouldRenderSweetpassUpsellCta ? (
              <OrderStatusSweetpassUpgradeCta />
            ) : null}

            <OrderStatusDetails {...rest} />

            {/* NOTE: The contact us card with in-app order cancellation
                      should be rendered inside the main scroll container. */}
            {isInAppOrderCancellationEnabled ? (
              <OrderStatusContactUs
                orderId={orderId}
                canCancel={canCancel}
                restaurantId={restaurantId}
                refetchOrderStatus={refetchOrderStatus}
              />
            ) : null}
          </ScrollView>

          {/* NOTE: The contact us floating bar with Kustomer order cancellation
                    should be rendered outside the main scroll container. */}
          {isInAppOrderCancellationEnabled ? null : (
            <OrderStatusContactUs
              orderId={orderId}
              canCancel={canCancel}
              restaurantId={restaurantId}
              refetchOrderStatus={refetchOrderStatus}
            />
          )}
        </View>
      </View>
    </PageWrapper>
  );
};

const MobileOrderStatusScreen = ({
  info,
  footer,
  children,
  orderWantedTime = '',
  isOrderToHealthKitEnabled,
  orderToHealthKitLineItems,
  shouldRenderSweetpassUpsellCta,
  orderId,
  restaurantId,
  canCancel,
  refetchOrderStatus,
  ...rest
}: OrderStatusScreenProps) => {
  const shouldRenderOrderToHealthKit =
    isOrderToHealthKitEnabled &&
    orderToHealthKitLineItems?.length !== undefined;

  return (
    <>
      <ScrollViewWithHeaderTitle contentContainerStyle={styles.grow}>
        <View
          testID="order-status.mobile.container"
          style={styles.mobileScreenContainer}
        >
          <View style={styles.infoContent}>{info}</View>
          <View style={styles.mobileMapContainer}>{children}</View>
          <View style={styles.mobileDetails}>
            {shouldRenderSweetpassUpsellCta ? (
              <OrderStatusSweetpassUpgradeCta />
            ) : null}

            <OrderStatusDetails {...rest} />

            {shouldRenderOrderToHealthKit ? (
              <View style={styles.orderToHealthKitContainer}>
                <OrderToHealthKit
                  lineItems={orderToHealthKitLineItems}
                  orderWantedTime={ignoreTimezone(orderWantedTime)}
                />
              </View>
            ) : null}

            <OrderStatusContactUs
              orderId={orderId}
              canCancel={canCancel}
              restaurantId={restaurantId}
              refetchOrderStatus={refetchOrderStatus}
            />
          </View>
        </View>
        <AppFooter />
      </ScrollViewWithHeaderTitle>

      {footer ? (
        <Modal.Footer style={styles.mobileDetails}>{footer}</Modal.Footer>
      ) : null}
    </>
  );
};

const useLateNotice = (isLate?: boolean) => {
  const { t } = useLocalizationContext();
  const text = t('order-status-info.late');

  usePermanentNoticeBanner(Boolean(isLate), text, 'late-notice');
};

type OrderStatusScreenProps = Readonly<{
  info: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  orderId?: string;
  orderType: AddressType;
  orderStatus: FlattenedOrderStatuses;
  deliveryAddressName?: string;
  deliveryAddressStreet?: string;
  deliveryAddressSecondaryStreet?: string;
  deliveryAddressCity?: string;
  deliveryAddressState?: string;
  deliveryAddressZipCode?: string;
  deliveryAddressNotes?: string;
  deliveryPreference?: DeliveryPreferenceType;
  locationName?: string;
  locationAddress?: string;
  locationCity?: string;
  locationState?: string;
  locationZipCode?: string;
  dropoffLocation?: string;
  restaurantId?: string;
  lineItems: readonly PartialLineItem[];
  orderToHealthKitLineItems?: ComponentProps<
    typeof OrderToHealthKit
  >['lineItems'];
  isOrderToHealthKitEnabled: boolean;
  ledger: Partial<Ledger>;
  courierName?: CourierDetails['name'];
  courierPhone?: CourierDetails['phone'];
  orderWantedTime?: string | undefined;
  isLate?: boolean;
  canCancel: boolean;
  shouldRenderSweetpassUpsellCta: boolean;
  refetchOrderStatus: () => void;
}>;

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
  pageWrapper: {
    height: '100%',
  },
  desktopScreenContainer: {
    flexDirection: 'row',
    height: '100%',
    backgroundColor: theme.colors.KHAKI_3,
  },
  mobileScreenContainer: {
    overflow: 'hidden',
    backgroundColor: theme.colors.KHAKI_3,
    marginBottom: theme.spacing['4'],
  },
  mobileMapContainer: {
    position: 'relative',
    height: 300,
    borderTopColor: theme.colors.NEUTRAL_4,
    borderBottomColor: theme.colors.NEUTRAL_4,
    marginBottom: theme.spacing['4'],
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  desktopMapContainer: {
    position: 'relative',
    flex: 1,
  },
  orderStatusContainer: {
    borderLeftColor: theme.colors.NEUTRAL_1,
    borderLeftWidth: 1,
  },
  desktopContent: {
    padding: theme.spacing['10'],
  },
  infoContent: {
    flex: 1,
    paddingTop: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['10'],
  },
  mobileDetails: {
    borderTopColor: theme.colors.DARK_KALE,
    paddingHorizontal: theme.spacing['4'],
  },
  infoContainer: {
    padding: theme.spacing['10'],
  },
  orderToHealthKitContainer: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.GRAY,
    marginTop: theme.spacing['10'],
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.GREEN_1,
  },
});
