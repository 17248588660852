/* eslint-disable functional/immutable-data */

import React, { Fragment, useCallback, useEffect } from 'react';
import {
  type LayoutChangeEvent,
  Platform,
  StyleSheet,
  View,
  type ViewProps,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
  type WithTimingConfig,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { Portal } from '@gorhom/portal';

import { useResponsive } from '../../hooks';
import { FocusScope } from '../FocusScope';
import { AnimatedDialogOverlay } from './AnimatedDialogOverlay';

// ─────────────────────────────────────────────────────────────────────────────
// This is a react-native-reanimated view that behaves like a modal.
// ─────────────────────────────────────────────────────────────────────────────

export const AnimatedDialog = (props: AnimatedDialogProps) => {
  const { children, show, dismissModal } = props;

  // ─── Portal ───────────────────────────────────────────────────────────────
  // On XS breakpoints, we use a Portal to get the overlay to go over the header.

  const { match } = useResponsive();
  const MaybePortal = Platform.select({
    web: match([Portal, Fragment]),
    default: Fragment,
  });

  // ─── Height Animation ─────────────────────────────────────────────────────

  const height = useSharedValue(0);

  const heightAnimation = useAnimatedStyle(() => {
    return {
      height: height.value,
    };
  });

  const syncHeight = useCallback(
    (event: LayoutChangeEvent) => {
      const layoutHeight = event.nativeEvent.layout.height;

      height.value = withTiming(layoutHeight, ANIMATED_DIALOG_ANIMATION_CONFIG);
    },
    [height],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <MaybePortal>
      <View
        style={styles.modalContainer}
        pointerEvents={show ? 'auto' : 'none'}
      >
        <AnimatedDialogOverlay show={show} dismissModal={dismissModal} />

        <Animated.View
          pointerEvents="none"
          style={[styles.modalBackground, heightAnimation]}
        />

        <View onLayout={syncHeight} style={styles.dialogContentOuterContainer}>
          <FocusScope enabled={show}>{children}</FocusScope>
        </View>
      </View>
    </MaybePortal>
  );
};

export const AnimatedDialogItem = (props: ViewProps) => {
  const { bottom } = useSafeAreaInsets();

  // ─── Animated Values ─────────────────────────────────────────────────

  const opacitySV = useSharedValue(0);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = useStyle(
    () => ({ paddingBottom: ANIMATED_DIALOG_ITEM_PADDING + bottom }),
    [bottom],
  );

  const containerAnimatedStyles = useAnimatedStyle(() => {
    return { opacity: opacitySV.value };
  }, []);

  const containerStyles = [
    styles.itemContainer,
    containerDynamicStyles,
    containerAnimatedStyles,
    props.style,
  ];

  // ─── Effects ─────────────────────────────────────────────────────────

  // NOTE: Fade in/out component on mount/unmount
  useEffect(() => {
    opacitySV.value = withDelay(
      ANIMATED_DIALOG_ITEM_TRANSITION_DELAY,
      withTiming(1, ANIMATED_DIALOG_ANIMATION_CONFIG),
    );

    return () => {
      opacitySV.value = withTiming(0, ANIMATED_DIALOG_ANIMATION_CONFIG);
    };
  }, [opacitySV]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={containerStyles}>{props.children}</Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

export const ANIMATED_DIALOG_ITEM_TRANSITION = 400;
export const ANIMATED_DIALOG_ITEM_TRANSITION_DELAY = 100;
export const ANIMATED_DIALOG_ITEM_PADDING = theme.spacing['4'];
export const ANIMATED_DIALOG_ANIMATION_CONFIG: WithTimingConfig = {
  duration: ANIMATED_DIALOG_ITEM_TRANSITION,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    justifyContent: 'flex-end',
    zIndex: theme.zIndex.modal,
  },
  modalBackground: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: 0,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
    backgroundColor: theme.colors.CREAM,
    zIndex: theme.zIndex.modalBackdrop,
    ...theme.elevations[2],
  },
  itemContainer: {
    opacity: 0,
    padding: ANIMATED_DIALOG_ITEM_PADDING,
  },
  dialogContentOuterContainer: {
    flexShrink: 1,
    marginTop: 120,
    zIndex: theme.zIndex.modal,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimatedDialogProps = {
  children: React.ReactNode;
  show: boolean;
  dismissModal: () => void;
};
