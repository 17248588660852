import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { Region } from '@sg/garnish';
import { Modal, theme, useResponsive } from '@sg/garnish';

import { LoadingAnimation } from '../LoadingAnimation';
import { NotFoundView } from '../NotFoundView';
import { TransparentModal } from '../TransparentModal';
import type {
  DeliveryLocationCardProps,
  RestaurantLocationCardProps,
} from './subcomponents';
import { ConfirmLocationLayout } from './subcomponents';

export const ConfirmLocation = (props: ConfirmLocationProps) => {
  const { isFetching, restaurant, hasError, region, onClose, ...restProps } =
    props;

  if (isFetching) {
    return (
      <ConfirmLocationContainer onClose={onClose}>
        <View style={styles.notLoadedView}>
          <LoadingAnimation />
        </View>
      </ConfirmLocationContainer>
    );
  }

  if (hasError || !restaurant || !region) {
    return (
      <ConfirmLocationContainer onClose={onClose}>
        <View style={styles.notLoadedView}>
          <NotFoundView withoutFooter />
        </View>
      </ConfirmLocationContainer>
    );
  }

  return (
    <ConfirmLocationContainer onClose={onClose}>
      <ConfirmLocationLayout
        region={region}
        restaurant={restaurant}
        {...restProps}
      />
    </ConfirmLocationContainer>
  );
};

const ConfirmLocationContainer = (
  props: ViewProps & Pick<ConfirmLocationProps, 'onClose'>,
) => {
  const { children, onClose } = props;

  const { match, minWidth } = useResponsive();

  return (
    <TransparentModal
      testID="confirm-location"
      size={match(['full', 'large'])}
      onClose={onClose}
      mobileEdges={['right', 'bottom', 'left']}
    >
      {minWidth.isSM ? (
        <Modal.FloatingCloseBtn onPress={onClose} style={styles.closeBtn} />
      ) : null}

      {children}
    </TransparentModal>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  notLoadedView: {
    flex: 1,
    paddingVertical: theme.spacing['6'],
    backgroundColor: theme.colors.OATMEAL,
  },
  closeBtn: {
    marginTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ConfirmLocationProps = Readonly<{
  isFetching: boolean;
  hasError: boolean;
  region?: Region | null;
  restaurant?: RestaurantLocationCardProps['restaurant'] | null;
  deliveryAddress?: DeliveryLocationCardProps['deliveryAddress'] | null;
  onClose: () => void;
}> &
  Omit<
    ComponentProps<typeof ConfirmLocationLayout>,
    'restaurant' | 'region' | 'deliveryAddress'
  >;
