import React from 'react';
import type { AccessibilityProps, ImageSourcePropType } from 'react-native';
import { Image, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCardImg = (props: BenefitCardImgProps) => {
  const { source, accessibilityLabel } = props;

  const { match } = useResponsive();

  const imgStyles = match([styles.imgXS, styles.imgSM, styles.imgMD]);

  return (
    <Image
      source={source}
      style={imgStyles}
      // @ts-expect-error TS(2769): No overload matches this call.
      contentFit="contain"
      aria-label={accessibilityLabel}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMG_SIZE_XS = 80;
const IMG_SIZE_SM = 120;
const IMG_SIZE_LG = 200;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgXS: {
    width: IMG_SIZE_XS,
    height: IMG_SIZE_XS,
    marginRight: theme.spacing['4'],
  },
  imgSM: {
    width: IMG_SIZE_SM,
    height: IMG_SIZE_SM,
    marginBottom: theme.spacing['4'],
  },
  imgMD: {
    width: IMG_SIZE_LG,
    height: IMG_SIZE_LG,
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardImgProps = Readonly<{
  source: ImageSourcePropType;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
}>;
