import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import type { Button } from '@sg/garnish';

import type { ContentfulAsset, ContentfulEntry } from '@order/Contentful';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import type { DurationUnit } from '@order/graphql';

export const useSweetpassBenefitsContentfulData = (
  props: SweetpassBenefitsContentfulDataProps,
) => {
  const {
    tag = '',
    planPrice,
    billingFrequencyUnit,
    isEligibleForFreeTrial,
  } = props;

  const { data: contentfulData, fetching: isLoading } =
    useContentfulContentTypeEntry<ContentfulSweetpassBenefitsPage>({
      contentType: 'sweetpassBenefitsPage',
      tags: tag ? [tag] : [],
      include: 3,
    });

  const content = useMemo(
    () => ({
      title: contentfulData?.fields?.title ?? '',
      content:
        contentfulData?.fields?.content?.map?.((rawContent) =>
          contentFromContentful(
            rawContent,
            planPrice,
            billingFrequencyUnit,
            isEligibleForFreeTrial,
          ),
        ) ?? [],
    }),
    [contentfulData, planPrice, billingFrequencyUnit, isEligibleForFreeTrial],
  );

  return { content, isLoading };
};

// ––– TYPES –––––––––––––––––––––––––

type SweetpassBenefitsContentfulDataProps = Readonly<{
  tag?: SweetpassBenefitsPageTag;
  planPrice: number;
  billingFrequencyUnit: DurationUnit;
  isEligibleForFreeTrial?: boolean;
}>;

export type SweetpassBenefitsPageTag =
  | 'sweetpass-benefits-page'
  | 'sweetpass-plus-benefits-page';

export type SweetpassBenefitsPageBenefitCtaHandler = (
  action: SweetpassBenefitsBenefit['action'],
) => void;

export type SweetpassBenefitsPageUpgradeCtaHandler = (
  action: SweetpassBenefitsCta['action'],
) => void;

export type SweetpassBenefitsPageCardCtaHandler = (
  action: SweetpassBenefitsCardCta['action'],
) => void;

// ––– Benefits Page –––––––––––––––––––––––––

export type SweetpassBenefitsPage = Readonly<{
  title: string;
  content: readonly SweetpassBenefitsContent[];
}>;

type ContentfulSweetpassBenefitsPage = Readonly<{
  title: string;
  freeTrialTitle: string;
  content: ReadonlyArray<ContentfulEntry<ContentfulSweetpassBenefitsSection>>;
}>;

// ––– Content (Benefit List, CTA, Cards) ––––

export type SweetpassBenefitsContent =
  | SweetpassBenefitsCta
  | SweetpassBenefitList
  | SweetpassBenefitsCardsSection
  | SweetpassUpgradeUnknownContent;

type ContentfulSweetpassBenefitsSection =
  | ContentfulSweetpassBenefitsBenefitList
  | ContentfulSweetpassBenefitsCta
  | ContentfulSweetpassBenefitsCards;

export type SweetpassUpgradeUnknownContent = Readonly<{
  type: string;
  id: string;
}>;

const contentFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsSection>,
  planPrice: number,
  billingFrequencyUnit: DurationUnit,
  isEligibleForFreeTrial?: boolean,
): SweetpassBenefitsContent => {
  const contentTypeId = entry.sys.contentType.sys.id;

  if (contentTypeId === 'sweetpassBenefitsList') {
    return benefitListFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassBenefitsBenefitList>,
    );
  }

  if (contentTypeId === 'sweetpassBenefitsPageCta') {
    return ctaFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassBenefitsCta>,
      planPrice,
      billingFrequencyUnit,
      isEligibleForFreeTrial,
    );
  }

  if (contentTypeId === 'sweetpassBenefitsCardsSection') {
    return cardsSectionFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassBenefitsCards>,
    );
  }

  return {
    type: contentTypeId,
    id: entry.sys.id,
  };
};

// ––– CTA –––––––––––––––––––––––––––––––––––––––––––––––

type SweetpassBenefitsUpgradeCtaAction = 'upgrade_to_sweetpass_plus';

export type SweetpassBenefitsCta = Readonly<{
  type: 'sweetpassBenefitsCta';
  id: string;
  title: string;
  action: SweetpassBenefitsUpgradeCtaAction;
  palette: SweetpassBenefitScreenUpgradeCtaPalette;
}>;

type SweetpassBenefitScreenUpgradeCtaPalette = NonNullable<
  ComponentProps<typeof Button>['palette']
>;

type ContentfulSweetpassBenefitsCta = Readonly<{
  titleV3: string;
  freeTrialTitle: string;
  action: SweetpassBenefitsCta['action'];
  palette: string;
}>;

const ctaFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsCta>,
  planPrice: number,
  billingFrequencyUnit: DurationUnit,
  isEligibleForFreeTrial?: boolean,
): SweetpassBenefitsCta => {
  const regularCta = entry.fields.titleV3
    .replace('{planPrice}', `${planPrice}`)
    .replace('{billingFrequencyUnit}', billingFrequencyUnit?.toLowerCase());
  const freeTrialCta = entry.fields.freeTrialTitle ?? regularCta;

  return {
    type: 'sweetpassBenefitsCta',
    id: entry.sys.id,
    title: isEligibleForFreeTrial ? freeTrialCta : regularCta,
    action: entry.fields.action as SweetpassBenefitsUpgradeCtaAction,
    palette: entry.fields.palette as SweetpassBenefitScreenUpgradeCtaPalette,
  };
};

// ––– Benefit List ––––––––––––––––––––––––––––

export type SweetpassBenefitsPageBenefitListPaletteTag =
  | 'regular'
  | 'greyed-out';

export type SweetpassBenefitList = Readonly<{
  type: 'sweetpassBenefitsList';
  id: string;
  title: string;
  benefits: readonly SweetpassBenefitsBenefit[];
  palette: SweetpassBenefitsPageBenefitListPaletteTag;
}>;

type ContentfulSweetpassBenefitsBenefitList = Readonly<{
  title: string;
  benefitsList: ReadonlyArray<
    ContentfulEntry<ContentfulSweetpassUpgradeBenefit>
  >;
  palette: string;
}>;

const benefitListFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsBenefitList>,
): SweetpassBenefitList => ({
  type: 'sweetpassBenefitsList',
  id: entry.sys.id,
  title: entry.fields.title,
  benefits: entry.fields.benefitsList.map(benefitFromContentful),
  palette: entry.fields.palette as SweetpassBenefitsPageBenefitListPaletteTag,
});

// ––– Benefit ––––––––––––––––––––––––––––––––––

export type SweetpassBenefitsBenefitAction = 'challenges-rewards' | null;

type SweetpassBenefitsBenefit = Readonly<{
  type: 'sweetpassBenefitsBenefit';
  id: string;
  image: {
    url: string;
    description: string;
  };
  titleText: string;
  bodyText: string;
  action?: SweetpassBenefitsBenefitAction;
}>;

type ContentfulSweetpassUpgradeBenefit = Readonly<{
  image: ContentfulAsset;
  titleText: string;
  bodyText: string;
  action: string;
  palette: string;
}>;

const benefitFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassUpgradeBenefit>,
): SweetpassBenefitsBenefit => ({
  type: 'sweetpassBenefitsBenefit',
  id: entry.sys.id,
  image: {
    description: entry.fields.image.fields.description,
    url: entry.fields.image.fields.file.url,
  },
  titleText: entry.fields.titleText,
  bodyText: entry.fields.bodyText,
  action: entry.fields.action as SweetpassBenefitsBenefitAction,
});

// ––– Cards ––––––––––––––––––––––––––––

export type SweetpassBenefitsCardsSection = Readonly<{
  type: 'sweetpassBenefitsCardsSection';
  id: string;
  cards: readonly SweetpassBenefitsCardsCard[];
}>;

type ContentfulSweetpassBenefitsCards = Readonly<{
  content: ReadonlyArray<ContentfulEntry<ContentfulSweetpassBenefitsCard>>;
}>;

const cardsSectionFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsCards>,
): SweetpassBenefitsCardsSection => ({
  type: 'sweetpassBenefitsCardsSection',
  id: entry.sys.id,
  cards: entry.fields.content.map(cardFromContentful),
});

// ––– Card CTA –––––––––––––––––––––––––

type SweetpassBenefitsCardCtaAction = 'view-all' | 'edit';

type SweetpassBenefitsCardCta = Readonly<{
  type: 'sweetpassBenefitsCardCta';
  id: string;
  title: string;
  action: SweetpassBenefitsCardCtaAction;
}>;

type ContentfulSweetpassBenefitsCardCta = Readonly<{
  title: string;
  action: string;
}>;

const cardCtaFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsCardCta>,
): SweetpassBenefitsCardCta => ({
  type: 'sweetpassBenefitsCardCta',
  id: entry.sys.id,
  title: entry.fields.title,
  action: entry.fields.action as SweetpassBenefitsCardCta['action'],
});

// ––– Card –––––––––––––––––––––––––––––

export type SweetpassBenefitsPageCardPaletteTag = 'cucumber' | 'quinoa';

type SweetpassBenefitsCardsCard = Readonly<{
  type: 'sweetpassBenefitsCard';
  id: string;
  title: string;
  description: string;
  cta: SweetpassBenefitsCardCta;
  palette: SweetpassBenefitsPageCardPaletteTag;
}>;

type ContentfulSweetpassBenefitsCard = Readonly<{
  title: string;
  description: string;
  cta: ContentfulEntry<ContentfulSweetpassBenefitsCardCta>;
  palette: string;
}>;

const cardFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassBenefitsCard>,
): SweetpassBenefitsCardsCard => ({
  type: 'sweetpassBenefitsCard',
  id: entry.sys.id,
  title: entry.fields.title,
  description: entry.fields.description,
  cta: cardCtaFromContentful(entry.fields.cta),
  palette: entry.fields.palette as SweetpassBenefitsPageCardPaletteTag,
});
