import React, { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Icon, usePressableState, webOnlyStyles } from '@sg/garnish';

/**
 * Menu icon to be shown floating at the bottom of the bag.
 */
export const BagMenuIconCta = (props: BagMenuIconCtaProps) => {
  const { cta, isDisabled, onPress } = props;
  const { formatMessage } = useIntl();

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);
  const hoverStyle = isInteracting ? styles.viewMenuCtaHover : null;

  const disabledStyle = isDisabled ? styles.viewMenuCtaDisabled : null;

  const transitionStyle = webOnlyStyles({
    transition: `background-color ${theme.transitions.base}ms, color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms`,
  });

  return (
    <Pressable
      ref={ref}
      style={[styles.viewMenuCta, hoverStyle, disabledStyle, transitionStyle]}
      disabled={isDisabled}
      accessibilityLabel={formatMessage(messages.a11yLabel)}
      onPress={onPress}
    >
      <Icon name="IconMenuBowl" width={24} height={24} />

      <BodyText sizeMatch={['14']}>{cta}</BodyText>
    </Pressable>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'View menu',
    description: 'Bag | Floating CTAs | View Menu',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  viewMenuCta: {
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.CREAM,
    borderRadius: theme.radius.xxxlarge,
    ...theme.elevations[6],
  },
  viewMenuCtaDisabled: {
    backgroundColor: theme.colors.LIGHT,
  },
  viewMenuCtaHover: {
    backgroundColor: theme.colors.LINEN,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagMenuIconCtaProps = {
  cta: string;
  isDisabled: boolean;
  onPress: () => void;
};
