import React from 'react';

import { Bag } from '@order/features/ordering';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useCloseBag } from '../hooks';
import { BagCheckout, BagContent } from './components';
import { useOrderState } from './hooks';

export const BagScreenV2 = () => {
  const isExpressPaymentsEnabled = useFeatureFlag(
    'permanent-order-stripe-express-checkout-enabled',
  );

  const orderState = useOrderState({ isExpressPaymentsEnabled });
  const { closeBag } = useCloseBag();

  return (
    <Bag.DynamicModal onClose={closeBag}>
      <BagContent orderState={orderState} />

      <BagCheckout orderState={orderState} />
    </Bag.DynamicModal>
  );
};
