import type { ComponentProps } from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';
import { useMachine } from '@xstate/react';
import type { NoticeBanner } from '@sg/garnish';
import { useNoticeBannersStackContext } from '@sg/garnish';

import type {
  Ingredient,
  IngredientModifications,
  MixedDressingDetails,
} from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { type ModalStackParamList } from '../../../navigation/AppNavigation.props';
import type { IngredientModificationWithQuantity } from '../types';
import { useProductModificationsAnnouncements } from './hooks';
import type {
  ProductModificationsModelContext,
  ProductModificationsModelEvent,
} from './ProductModifications.machine';
import { ProductModificationsMachine } from './ProductModifications.machine';
import {
  checkIfBreadIsActive,
  checkIfBreadIsAvailable,
  getActiveIngredientModification,
  getActiveIngredientModificationsMap,
  getActiveModificationsQty,
  getBreadIngredientModification,
  getDressingPortionsMaxNumber,
  getDressingsDetails,
  getModificationKindLimit,
  getOutOfStockIngredientsNames,
  getUnderTheLimitKinds,
} from './utils';

export const useProductModificationsMachine = (
  props: UseProductModificationsMachineProps,
) => {
  const {
    ingredients,
    isCustomProduct,
    isModifiableProduct,
    productName,
    calories,
    hasStaleData,
  } = props;

  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'ProductDetails'>>();
  const { push: pushNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const {
    announceAddedIngredientModification,
    announceIncreasedIngredientModification,
    announceDecreasedIngredientModification,
    announceRemovedIngredientModification,
  } = useProductModificationsAnnouncements();

  // ────────────────────────────────────────────────────────────────────

  const addNoticeBanner = useCallback(
    (bannerProps: AddNoticeBannerProps) => {
      pushNoticeBanner({ ...bannerProps, palette: 'neutral' }, true);
    },
    [pushNoticeBanner],
  );

  const onOutOfStockIngredients = useCallback(
    (ctx: ProductModificationsModelContext) => {
      const outOfStockIngredientsNames = getOutOfStockIngredientsNames(ctx);
      const outOfStockIngredients = outOfStockIngredientsNames.join(', ');

      track('86ingredient_error', {
        restaurant: params.restaurantSlug,
        product: params.productSlug,
        ingredient: outOfStockIngredients,
      });

      addNoticeBanner({
        id: 'out-of-stock-ingredients',
        text: t('pdp.modifications.outOfStock', { outOfStockIngredients }),
        highlights: outOfStockIngredientsNames,
        autoHideTimeout: 'never',
      });
    },
    [params, track, addNoticeBanner, t],
  );

  const onMinUnmet = useCallback(
    (ctx: ProductModificationsModelContext) => {
      const { active, limitations } = ctx;

      const kinds = getUnderTheLimitKinds(active, limitations);
      const kind = kinds[0];

      const { kindMinLimit } = getModificationKindLimit(kind, limitations);

      addNoticeBanner({
        id: 'min-unmet',
        text: t('pdp.modifications.minUnmet', { kind, kindMinLimit }),
        highlights: [kind, String(kindMinLimit)],
      });
    },
    [addNoticeBanner, t],
  );

  const onMaxModificationsExceeded = useCallback(() => {
    track('modify.max_modifications');

    addNoticeBanner({
      id: 'max-exceeded',
      text: t('pdp.modifications.maxModificationsExceeded'),
    });
  }, [addNoticeBanner, track, t]);

  const onMaxKindQtyExceeded = useCallback(
    (
      ctx: ProductModificationsModelContext,
      event: ProductModificationsModelEvent,
    ) => {
      const ingredientModification =
        event.type === 'ADD_INGREDIENT_MODIFICATION' &&
        event.ingredientModification;

      if (!ingredientModification) return;

      const ingredientModificationKind = ingredientModification.kind ?? '';

      addNoticeBanner({
        id: 'max-kind-qty-exceeded',
        text: t('pdp.modifications.maxKindQtyExceeded', {
          kind: ingredientModificationKind,
        }),
      });
    },
    [addNoticeBanner, t],
  );

  const onMaxKindExceeded = useCallback(
    (
      ctx: ProductModificationsModelContext,
      event: ProductModificationsModelEvent,
    ) => {
      const ingredientModification =
        event.type === 'ADD_INGREDIENT_MODIFICATION' &&
        event.ingredientModification;

      if (!ingredientModification) return;

      const ingredientModificationKind = ingredientModification.kind ?? '';

      const { kindMaxLimit } = getModificationKindLimit(
        ingredientModificationKind,
        ctx.limitations,
      );

      track('modify.max_kind_limit_exceeded', {
        kind: ingredientModificationKind,
      });

      addNoticeBanner({
        id: 'max-kind-limit-exceeded',
        text: t('pdp.modifications.maxKindLimitExceeded', {
          kind: ingredientModificationKind,
          kindMaxLimit,
        }),
      });
    },
    [addNoticeBanner, track, t],
  );

  // ────────────────────────────────────────────────────────────────────

  const [state, send] = useMachine(ProductModificationsMachine, {
    actions: {
      onOutOfStockIngredients,
      onMaxKindQtyExceeded,
      onMaxKindExceeded,
      onMaxModificationsExceeded,
      onMinUnmet,
      onMaxDressingsPortionsExceeded() {
        onMaxDressingsPortionsExceeded();
      },
    },
  });

  const modifications = state.context;
  const activeModificationsQty = getActiveModificationsQty(modifications);

  const activeIngredientsModificationsMap = getActiveIngredientModificationsMap(
    modifications.active,
  );

  const isCustomizationActive = state.matches('active.modification.active');

  // ─── BREAD ──────────────────────────────────────────────────────────

  const isBreadAvailable = checkIfBreadIsAvailable(state.context);
  const isBreadActive = checkIfBreadIsActive(state.context);
  const breadIngredientModification = useMemo(
    () => getBreadIngredientModification(state.context),
    [state.context],
  );

  const toggleBread = useCallback(
    () => send('TOGGLE_BREAD_INGREDIENT_MODIFICATION'),
    [send],
  );

  // ─── DRESSING ───────────────────────────────────────────────────────

  const shouldShowNoDressingModal = state.matches(
    'active.modification.active.confirmNoDressing',
  );
  const shouldShowTooFewBasesModal = state.matches(
    'active.modification.active.confirmTooFewBases',
  );
  const isDressingOnTheSide = state.matches('active.dressing.onTheSide');
  const isDressingMixedIn = state.matches('active.dressing.mixedIn');
  const dressingMode =
    (isDressingOnTheSide ? 'onTheSide' : '') ||
    (isDressingMixedIn ? 'mixedIn' : '');
  const maxDressingsPortionsNumber = getDressingPortionsMaxNumber(
    state.context,
  );
  const dressingsDetails = useMemo(
    () => getDressingsDetails(state.context),
    [state.context],
  );

  const toggleDressingMode = useCallback(() => {
    send('TOGGLE_DRESSING_MODE');
  }, [send]);

  const increaseDressingWeight = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      send('INCREASE_DRESSING_WEIGHT', { ingredientModification });
    },
    [send],
  );

  const decreaseDressingWeight = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      send('DECREASE_DRESSING_WEIGHT', { ingredientModification });
    },
    [send],
  );

  const increaseDressingPortionsNumber = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      send('INCREASE_DRESSING_PORTION_NUMBER', { ingredientModification });
    },
    [send],
  );

  const decreaseDressingPortionsNumber = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      send('DECREASE_DRESSING_PORTION_NUMBER', { ingredientModification });
    },
    [send],
  );

  const onMaxDressingsPortionsExceeded = useCallback(() => {
    addNoticeBanner({
      id: 'max-portions-exceeded',
      text: t('pdp.modifications.dressings.max-portions', {
        max: getDressingPortionsMaxNumber(state.context),
      }),
    });
  }, [addNoticeBanner, state.context, t]);

  // ─── MODIFICATIONS ──────────────────────────────────────────────────

  const addIngredientModification = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      const { ingredient } = ingredientModification;
      const { id: ingredientId, name: ingredientName } = ingredient;

      // ────────────────────────────────────────────────────────────────────

      const { context: updatedContext } = send('ADD_INGREDIENT_MODIFICATION', {
        ingredientModification,
      });
      const updatedActiveModificationsQty =
        getActiveModificationsQty(updatedContext);
      const isModificationAdded =
        activeModificationsQty < updatedActiveModificationsQty;

      const selectedIngredientModification = getActiveIngredientModification(
        updatedContext,
        ingredientId,
      );
      const shouldAnnounceAddedIngredientModification =
        isModificationAdded &&
        selectedIngredientModification?.quantity &&
        selectedIngredientModification.quantity === 1;
      const shouldAnnounceIncreasedIngredientModification =
        isModificationAdded &&
        selectedIngredientModification?.quantity &&
        selectedIngredientModification.quantity > 1;

      if (isModificationAdded) {
        track('modify.ingredient_added', { ingredient: ingredientName });
      }

      // ─────────────────────────────────────────────

      if (shouldAnnounceAddedIngredientModification) {
        announceAddedIngredientModification(ingredientName);
      }

      if (shouldAnnounceIncreasedIngredientModification) {
        announceIncreasedIngredientModification(
          ingredientName,
          selectedIngredientModification.quantity,
        );
      }

      // ─────────────────────────────────────────────

      return isModificationAdded;
    },
    [
      activeModificationsQty,
      announceAddedIngredientModification,
      announceIncreasedIngredientModification,
      send,
      track,
    ],
  );

  const removeIngredientModification = useCallback(
    (ingredientModification: IngredientModificationWithQuantity) => {
      const { ingredient } = ingredientModification;
      const { id: ingredientId, name: ingredientName } = ingredient;

      const { context: updatedContext } = send(
        'REMOVE_INGREDIENT_MODIFICATION',
        { ingredientModification },
      );
      const updatedActiveModificationsQty =
        getActiveModificationsQty(updatedContext);
      const isModificationRemoved =
        activeModificationsQty > updatedActiveModificationsQty;
      const selectedIngredientModification = getActiveIngredientModification(
        updatedContext,
        ingredientId,
      );
      const shouldAnnounceDecreasedIngredientModification =
        isModificationRemoved &&
        selectedIngredientModification?.quantity &&
        selectedIngredientModification.quantity > 0;
      const shouldAnnounceRemovedIngredientModification =
        isModificationRemoved && !selectedIngredientModification?.quantity;

      if (isModificationRemoved) {
        track('modify.ingredient_removed', { ingredient: ingredientName });
      }

      // ─────────────────────────────────────────────

      if (shouldAnnounceDecreasedIngredientModification) {
        announceDecreasedIngredientModification(
          ingredientName,
          selectedIngredientModification.quantity,
        );
      }

      if (shouldAnnounceRemovedIngredientModification) {
        announceRemovedIngredientModification(ingredientName);
      }

      // ─────────────────────────────────────────────

      return isModificationRemoved;
    },
    [
      activeModificationsQty,
      announceDecreasedIngredientModification,
      announceRemovedIngredientModification,
      send,
      track,
    ],
  );

  const startCustomization = useCallback(() => {
    send('START_CUSTOMIZATION');
  }, [send]);

  const cancelCustomization = useCallback(() => {
    track('modify.cancel');
    send('CANCEL_CUSTOMIZATION');
  }, [track, send]);

  const continueCustomization = useCallback(() => {
    send('CONTINUE_CUSTOMIZATION');
  }, [send]);

  const finishCustomization = useCallback(() => {
    track('modify.done');
    send('FINISH_CUSTOMIZATION');
  }, [track, send]);

  const customizeLineItemName = useCallback(
    (customName: string) => {
      send('CUSTOMIZE_LINE_ITEM_NAME', { customName });
    },
    [send],
  );

  // ─── PRODUCT DATA ────────────────────────────────────────────────────

  // NOTE: To avoid side effects, we use `ref` to memoize product details

  const productDetails = {
    ...ingredients,
    isCustom: isCustomProduct,
    isModifiable: isModifiableProduct,
    customName: productName,
    calories,
  };

  const productDetailsRef = useRef(productDetails);

  // eslint-disable-next-line functional/immutable-data
  productDetailsRef.current = productDetails;

  // ─── EFFECTS ────────────────────────────────────────────────────────

  // setup initial product data
  useLayoutEffect(() => {
    const isProductResolved = ingredients && isCustomProduct !== undefined;
    const shouldPreventMachineSetup = !isProductResolved;

    if (shouldPreventMachineSetup) return;

    send('SETUP', productDetailsRef.current);
  }, [ingredients, isCustomProduct, send]);

  // update stale product data
  useLayoutEffect(() => {
    if (!hasStaleData) return;

    return () => {
      send('UPDATE', productDetailsRef.current);
    };
  }, [hasStaleData, send]);

  return useMemo(
    () => ({
      // ─── MODIFICATIONS ───────────────────────────

      modifications,
      addIngredientModification,
      removeIngredientModification,
      activeIngredientsModificationsMap,

      // ─── CUSTOMIZATION MODE ──────────────────────

      isCustomizationActive,
      startCustomization,
      cancelCustomization,
      continueCustomization,
      finishCustomization,

      // ─── BREAD ───────────────────────────────────

      isBreadAvailable,
      isBreadActive,
      breadIngredientModification,
      toggleBread,

      // ─── DRESSINGS ───────────────────────────────

      dressingsDetails,
      dressingMode,
      toggleDressingMode,
      increaseDressingWeight,
      decreaseDressingWeight,
      increaseDressingPortionsNumber,
      decreaseDressingPortionsNumber,
      maxDressingsPortionsNumber,
      shouldShowNoDressingModal,

      // ─── BASES ───────────────────────────────────
      shouldShowTooFewBasesModal,

      // ─── CUSTOM NAME ─────────────────────────────

      customizeLineItemName,
    }),
    [
      modifications,
      addIngredientModification,
      removeIngredientModification,
      activeIngredientsModificationsMap,
      isCustomizationActive,
      startCustomization,
      cancelCustomization,
      continueCustomization,
      finishCustomization,
      isBreadAvailable,
      isBreadActive,
      breadIngredientModification,
      toggleBread,
      dressingsDetails,
      dressingMode,
      toggleDressingMode,
      increaseDressingWeight,
      decreaseDressingWeight,
      increaseDressingPortionsNumber,
      decreaseDressingPortionsNumber,
      maxDressingsPortionsNumber,
      shouldShowNoDressingModal,
      shouldShowTooFewBasesModal,
      customizeLineItemName,
    ],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseProductModificationsMachineProps = Readonly<{
  ingredients: Ingredients;
  hasStaleData: boolean;
  isCustomProduct?: boolean;
  isModifiableProduct?: boolean;
  productName?: string;
  calories?: number;
}>;

type Ingredients = Readonly<{
  ingredientsModifications: IngredientModifications;
  defaultIngredients: readonly Ingredient[];
  activeIngredients: readonly Ingredient[];
  maxModifications: number;
  mixedDressingDetails: readonly MixedDressingDetails[];
}> | null;

type AddNoticeBannerProps = Readonly<{
  id: string;
}> &
  Pick<
    ComponentProps<typeof NoticeBanner>,
    'text' | 'highlights' | 'autoHideTimeout'
  >;
