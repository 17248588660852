import { useMemo } from 'react';
import { format, isValid } from 'date-fns';

import type {
  ContentfulAsset,
  ContentfulEntry,
  ContentfulTag,
} from '@order/Contentful';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import { TierName } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { isSweetpassHomeActionSupported } from '../../utils';

export const useSweetpassHomeContentfulData = ({
  sweetpassTier,
  firstName,
  joinDate,
}: UseSweetpassHomeLoadedContentProps) => {
  const { t } = useLocalizationContext();

  const {
    data: contentfulData,
    fetching: isLoadingContentfulData,
    error: contentfulError,
  } = useContentfulContentTypeEntry<ContentfulSweetpassHomePage>({
    contentType: SWEETPASS_HOME_PAGE_CONTENT_ID,
    include: 2,
  });

  const sweetpassHomeContentfulData = useMemo(() => {
    const content = parseContent(
      contentfulData?.fields?.content
        ?.map((field) => contentFromContentful(field, sweetpassTier))
        ?.filter(Boolean) as readonly SweetpassHomeContent[],
    );

    const formattedJoinDate = isValid(new Date(joinDate ?? ''))
      ? format(new Date(joinDate ?? ''), 'MMMM yyyy')
      : '';

    const infoText =
      content?.sweetpassHomeStats?.infoText ??
      t('sweetpass.home.stats.member-since');

    const noticeText = content?.sweetpassHomeStats?.noticeText;
    const noticeTextLink = content?.sweetpassHomeStats?.noticeTextLink;
    const noticeTextLinkAction =
      content?.sweetpassHomeStats?.noticeTextLinkAction;

    const ctaText =
      content?.sweetpassHomeStats?.ctaText ?? t('sweetpass.home.stats.cta');

    return {
      ...content,
      sweetpassHomeStats: {
        id: content?.sweetpassHomeStats?.id ?? 'fallback',
        type: content?.sweetpassHomeStats?.type ?? 'sweetpassHomeStats',
        headerNameText: t('sweetpass.home.header-name', {
          firstName,
        }),
        headerMembershipText: t('sweetpass.home.header-membership', {
          membership:
            sweetpassTier === TierName.SweetpassPlus
              ? 'sweetpass+'
              : 'sweetpass',
        }),
        infoText: infoText?.replace('{sweetpass_join_date}', formattedJoinDate),
        noticeText,
        noticeTextLink,
        noticeTextLinkAction,
        ctaText,
      },
    };
  }, [contentfulData?.fields?.content, firstName, joinDate, sweetpassTier, t]);

  return {
    isLoadingContentfulData,
    sweetpassHomeContentfulData,
    contentfulError,
  };
};

type UseSweetpassHomeLoadedContentProps = Readonly<{
  sweetpassTier?: TierName;
  joinDate?: string | null;
  firstName?: string | null;
}>;

// ––– Home Page –––––––––––––––––––––––––

type ContentfulSweetpassHomePage = Readonly<{
  content: ReadonlyArray<ContentfulEntry<ContentfulSweetpassHomeContent>>;
}>;

// ––– Content (Stats, Actions, Challenges, Rewards) ––––

export type SweetpassHomeContent =
  | SweetpassHomeKnownContent
  | SweetpassHomeUnknownContent;

export type SweetpassHomeKnownContent =
  | SweetpassHomeStatsContent
  | SweetpassHomeActionsRailContent
  | SweetpassHomeChallengesRailContent
  | SweetpassHomeRewardsRailContent;

type ContentfulSweetpassHomeContent =
  | ContentfulSweetpassHomeStats
  | ContentfulSweetpassHomeActionsRail
  | ContentfulSweetpassHomeChallengesRail
  | ContentfulSweetpassHomeRewardsRail;

export type SweetpassHomeUnknownContent = Readonly<{
  type: string;
  id: string;
}>;

const SWEETPASS_HOME_PAGE_CONTENT_ID = 'sweetpassHomePage';

const contentFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeContent>,
  sweetpassTier?: TierName,
): SweetpassHomeContent | null => {
  if (!checkIfContentIsValid(entry)) return null;

  const contentTypeId = entry?.sys?.contentType?.sys?.id;
  const tag = entry?.metadata?.tags?.[0]?.sys?.id;
  const isTagValid = tag === sweetpassTier;

  if (tag && !isTagValid) return null;

  if (contentTypeId === 'sweetpassHomeStats') {
    return statsFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassHomeStats>,
    );
  }

  if (contentTypeId === 'sweetpassHomeActionsRail') {
    return actionsRailFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassHomeActionsRail>,
    );
  }

  if (contentTypeId === 'sweetpassHomeChallengesRail') {
    return challengesRailFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassHomeChallengesRail>,
    );
  }

  if (contentTypeId === 'sweetpassHomeRewardsRail') {
    return rewardsRailFromContentful(
      entry as ContentfulEntry<ContentfulSweetpassHomeRewardsRail>,
    );
  }

  return {
    type: contentTypeId,
    id: entry.sys.id,
  };
};

// ––– Stats –––––––––––––––––––––––––––––––––––

export type SweetpassHomeStatsContent = Readonly<{
  type: 'sweetpassHomeStats';
  id: string;
  headerNameText?: string;
  headerMembershipText?: string;
  infoText: string;
  noticeText: string;
  noticeTextLink: string;
  noticeTextLinkAction: string;
  ctaText: string;
}>;

type ContentfulSweetpassHomeStats = Readonly<{
  infoText: string;
  noticeText: string;
  noticeTextLink: string;
  noticeTextLinkAction: string;
  ctaText: string;
}>;

const statsFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeStats>,
): SweetpassHomeStatsContent | null => {
  if (!checkIfContentIsValid(entry)) return null;

  return {
    type: 'sweetpassHomeStats',
    id: entry.sys.id,
    infoText: entry.fields.infoText,
    noticeText: entry.fields.noticeText,
    noticeTextLink: entry.fields.noticeTextLink,
    noticeTextLinkAction: entry.fields.noticeTextLinkAction,
    ctaText: entry.fields.ctaText,
  };
};

// ––– Actions Rail ––––––––––––––––––––––––––––

export type SweetpassHomeActionsRailContent = Readonly<{
  type: 'sweetpassHomeActionsRail';
  id: string;
  titleText: string;
  bodyText?: string;
  actionCards: readonly SweetpassHomeActionCardItem[];
}>;

type ContentfulSweetpassHomeActionsRail = Readonly<{
  titleText: string;
  bodyText?: string;
  actionCards: ReadonlyArray<
    ContentfulEntry<ContentfulSweetpassHomeActionCard>
  >;
}>;

const actionsRailFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeActionsRail>,
): SweetpassHomeActionsRailContent | null => {
  if (!checkIfContentIsValid(entry)) return null;

  return {
    type: 'sweetpassHomeActionsRail',
    id: entry.sys.id,
    titleText: entry.fields.titleText,
    bodyText: entry.fields.bodyText,
    actionCards: entry.fields.actionCards
      .map(actionCardItemFromContentful)
      .filter(isActionValid) as readonly SweetpassHomeActionCardItem[],
  };
};

function isActionValid(actionCard?: SweetpassHomeActionCardItem | null) {
  if (!actionCard) return false;

  const isActionNotSupported =
    actionCard?.action && !isSweetpassHomeActionSupported(actionCard?.action);

  return Boolean(actionCard) && !isActionNotSupported;
}

// ––– Action Card ––––––––––––––––––––––––––––––––––

export type SweetpassHomeActionCardItem = Readonly<{
  id: string;
  content: {
    headingText: string;
    bodyText: string;
    image: {
      url: string;
      description: string;
    };
  };
  action?: string;
  actionUrl?: string;
  layout: string;
  theme: string;
  tags?: readonly string[];
}>;

type ContentfulSweetpassHomeActionCard = Readonly<{
  headingText: string;
  bodyText: string;
  image: ContentfulAsset;
  action?: string;
  actionUrl?: string;
  layout: string;
  theme: string;
  tags?: readonly ContentfulTag[];
}>;

const actionCardItemFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeActionCard>,
): SweetpassHomeActionCardItem | null => {
  if (!checkIfContentIsValid(entry)) return null;

  const tags = entry.metadata.tags.map((tag) => tag.sys.id);

  return {
    id: entry.sys.id,
    content: {
      headingText: entry.fields.headingText,
      bodyText: entry.fields.bodyText,
      image: {
        description: entry.fields.image.fields.description,
        url: entry.fields.image.fields.file.url,
      },
    },
    action: entry.fields.action,
    actionUrl: entry.fields.actionUrl,
    layout: entry.fields.layout,
    theme: entry.fields.theme,
    tags,
  };
};

// ––– Challenges Rail ––––––––––––––––––––––––––––

export type SweetpassHomeChallengesRailContent = Readonly<{
  type: 'sweetpassHomeChallengesRail';
  id: string;
  titleText: string;
  bodyText?: string;
  emptyContentText: string;
  emptyContentImage: {
    url: string;
    description: string;
  };
}>;

type ContentfulSweetpassHomeChallengesRail = Readonly<{
  titleText: string;
  bodyText?: string;
  emptyContentText: string;
  emptyContentImage: ContentfulAsset;
}>;

const challengesRailFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeChallengesRail>,
): SweetpassHomeChallengesRailContent | null => {
  if (!checkIfContentIsValid(entry)) return null;

  return {
    type: 'sweetpassHomeChallengesRail',
    id: entry.sys.id,
    titleText: entry.fields.titleText,
    bodyText: entry.fields.bodyText,
    emptyContentText: entry.fields.emptyContentText,
    emptyContentImage: {
      description: entry.fields.emptyContentImage.fields.description,
      url: entry.fields.emptyContentImage.fields.file.url,
    },
  };
};

// ––– Rewards Rail ––––––––––––––––––––––––––––

export type SweetpassHomeRewardsRailContent = Readonly<{
  type: 'sweetpassHomeRewardsRail';
  id: string;
  titleText: string;
  bodyText?: string;
  emptyContentText: string;
  emptyContentImage: {
    url: string;
    description: string;
  };
}>;

type ContentfulSweetpassHomeRewardsRail = Readonly<{
  titleText: string;
  bodyText?: string;
  emptyContentText: string;
  emptyContentImage: ContentfulAsset;
}>;

const rewardsRailFromContentful = (
  entry: ContentfulEntry<ContentfulSweetpassHomeRewardsRail>,
): SweetpassHomeRewardsRailContent | null => {
  if (!checkIfContentIsValid(entry)) return null;

  return {
    type: 'sweetpassHomeRewardsRail',
    id: entry.sys.id,
    titleText: entry.fields.titleText,
    bodyText: entry.fields.bodyText,
    emptyContentText: entry.fields.emptyContentText,
    emptyContentImage: {
      description: entry.fields.emptyContentImage.fields.description,
      url: entry.fields.emptyContentImage.fields.file.url,
    },
  };
};

// ─── Content Validation ────────────────────────────────────────────────

const checkIfContentIsValid = (entry: ContentfulEntry<unknown>) => {
  const hasValidContentId = Boolean(entry?.sys?.id);
  const hasContentFields = Boolean(entry?.fields);

  return hasValidContentId && hasContentFields;
};

// ─── Content Parsing ───────────────────────────────────────────────────

type ParsedContent = Readonly<{
  sweetpassHomeStats?: SweetpassHomeStatsContent;
  sweetpassHomeActionsRail?: SweetpassHomeActionsRailContent;
  contentItems: ReadonlyArray<
    | SweetpassHomeChallengesRailContent
    | SweetpassHomeRewardsRailContent
    | SweetpassHomeContent
  >;
}>;

const parseContent = (
  contents?: readonly SweetpassHomeContent[],
): ParsedContent | undefined => {
  if (!contents) return;

  return contents.reduce<ParsedContent>(
    (acc, content) => {
      const key = content.type;

      if (['sweetpassHomeStats', 'sweetpassHomeActionsRail'].includes(key)) {
        return {
          ...acc,
          [key]: content,
        };
      }

      return {
        ...acc,
        contentItems: [...acc.contentItems, content],
      };
    },
    {
      contentItems: [],
    },
  );
};
