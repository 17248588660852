import React, { type ComponentProps, useMemo } from 'react';
import type { ImageSourcePropType } from 'react-native';
import { Image as RNImage, Platform } from 'react-native';

import { CloudinaryImage } from './CloudinaryImage';
import { ContentfulImage } from './ContentfulImage';
import { Image } from './Image';
import { type ImageProps } from './Image.types';

// Loads remote images through cloudinary and falls back to local image.
export const FallbackImage = React.memo(
  ({
    baseUrl,
    testID: baseTestID,
    defaultImage,
    resizeMode,
    style,
    cloudinaryConfig,
    contentfulOptions,
    ...rest
  }: FallbackImageProps) => {
    // Image Error
    const [imageLoadError, setImageLoadError] = React.useState(false);
    const handleImageLoadError = React.useCallback(() => {
      setImageLoadError(true);
    }, [setImageLoadError]);

    const isRemote = baseUrl && !imageLoadError;

    const imageProps: Omit<ComponentProps<typeof Image>, 'source'> = useMemo(
      () => ({
        style,
        resizeMode,
        onError: handleImageLoadError,
        testID: baseTestID?.concat(isRemote ? '-remote' : '-local'),
        ...rest,
      }),
      [baseTestID, handleImageLoadError, isRemote, resizeMode, rest, style],
    );

    // When Contentful options are specified, load remote image from Contentful
    if (isRemote && contentfulOptions) {
      return (
        <ContentfulImage
          baseUrl={baseUrl}
          options={contentfulOptions}
          {...imageProps}
          testID={baseTestID?.concat('.contentful')}
        />
      );
    }

    // When Cloudinary config is specified, load remote image from Contentful
    if (isRemote && cloudinaryConfig) {
      return (
        <CloudinaryImage
          baseUrl={baseUrl}
          config={cloudinaryConfig}
          {...imageProps}
          testID={baseTestID?.concat('.cloudinary')}
        />
      );
    }

    // return null if remote image could not be loaded and no default image was specified
    if (!isRemote && !defaultImage) {
      return null;
    }

    // load image directly if neither Cloudinary config nor Contentful options were specified,
    // or use default image if remote image could not be loaded
    return (
      <Image
        source={isRemote ? { uri: baseUrl } : defaultImage}
        {...imageProps}
        testID={baseTestID?.concat(isRemote ? '.remote' : '.default')}
      />
    );
  },
);

if (Platform.OS === 'web') {
  // eslint-disable-next-line functional/immutable-data
  RNImage.resolveAssetSource = (source) => {
    return {
      uri: String(source),
      width: 1,
      height: 1,
      scale: 1,
    };
  };
}

type FallbackImageProps = Readonly<{
  baseUrl: string;
  source?: string;
  defaultImage?: ImageSourcePropType;
  contentfulOptions?: React.ComponentProps<typeof ContentfulImage>['options'];
  cloudinaryConfig?: React.ComponentProps<typeof CloudinaryImage>['config'];
}> &
  Omit<ImageProps, 'source'>;
