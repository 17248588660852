import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, View } from 'react-native';
import { DisplayText, type IconName, theme } from '@sg/garnish';

import {
  type PaymentMethod,
  type PaymentMethodType,
} from '../../../machines/ordering-machine.types';
import { APPLE_PAY, getCardName, GOOGLE_PAY } from '../../../utils';
import { BagPaymentMethodAddCta } from './BagPaymentMethodAddCta';
import { BagPaymentMethodCheckCta } from './BagPaymentMethodCheckCta';

// ────────────────────────────────────────────────────────────────────────────

export const BagPaymentMethodPicker = (props: BagPaymentMethodPickerProps) => {
  const {
    paymentMethods,
    paymentMethodId = '',
    canApplePay,
    canGooglePay,
    changePaymentMethod,
    onRequestPaymentMethodForm,
    onRequestGiftCardForm,
  } = props;

  const { formatMessage } = useIntl();
  const getReadableCard = useCallback(
    (paymentMethod?: PaymentMethod) => {
      return getCardName(formatMessage, paymentMethod);
    },
    [formatMessage],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.section}>
          <DisplayText sizeMatch={['32']}>
            <FormattedMessage
              defaultMessage="Select payment option"
              description="Bag | Payment method picker | Title"
            />
          </DisplayText>
        </View>

        <View style={[styles.section, styles.divider]}>
          {canApplePay ? (
            <BagPaymentMethodCheckCta
              id={APPLE_PAY}
              icon="IconApplePayPayment"
              checked={paymentMethodId === APPLE_PAY}
              title={formatMessage(messages.applePay)}
              onPress={changePaymentMethod}
            />
          ) : null}

          {canGooglePay ? (
            <BagPaymentMethodCheckCta
              id={GOOGLE_PAY}
              icon="IconGooglePay"
              checked={paymentMethodId === GOOGLE_PAY}
              title={formatMessage(messages.googlePay)}
              onPress={changePaymentMethod}
            />
          ) : null}

          {paymentMethods.map((pm) => (
            <BagPaymentMethodCheckCta
              key={pm.id}
              id={pm.id}
              icon={CREDIT_CARDS_ICONS[pm.cardType]}
              checked={paymentMethodId === pm.id}
              title={getReadableCard(pm)}
              onPress={changePaymentMethod}
            />
          ))}

          <BagPaymentMethodAddCta
            title={formatMessage(messages.addNewCard)}
            onPress={onRequestPaymentMethodForm}
          />
        </View>

        <View style={styles.section}>
          <BagPaymentMethodAddCta
            title={formatMessage(messages.redeemGiftcard)}
            onPress={onRequestGiftCardForm}
          />
        </View>
      </ScrollView>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const CREDIT_CARDS_ICONS: Record<PaymentMethodType, IconName> = {
  VISA: 'IconCcVisa',
  MASTER_CARD: 'IconCcMasterCard',
  DISCOVER: 'IconCcDiscover',
  AMEX: 'IconCcAmex',
  AMERICAN_EXPRESS: 'IconCcAmex',
  UNSUPPORTED: 'IconCreditCard',
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  scrollView: {
    flexGrow: 1,
  },
  section: {
    gap: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
    paddingVertical: theme.spacing['4'],
  },
  divider: {
    paddingHorizontal: theme.spacing['8'],
    marginHorizontal: -theme.spacing['4'],
    borderBottomWidth: theme.spacing['2'],
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  applePay: {
    defaultMessage: 'Apple Pay',
    description: 'Bag | Payment method picker | Apple Pay',
  },
  googlePay: {
    defaultMessage: 'Google Pay',
    description: 'Bag | Payment method picker | Google Pay',
  },
  addNewCard: {
    defaultMessage: 'Add new card',
    description: 'Bag | Payment method picker | Add new card',
  },
  redeemGiftcard: {
    defaultMessage: 'Redeem gift card',
    description: 'Bag | Payment method picker | Redeem gift card',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagPaymentMethodPickerProps = {
  paymentMethods: readonly PaymentMethod[];
  paymentMethodId: string | undefined;
  canApplePay: boolean;
  canGooglePay: boolean;
  changePaymentMethod: (paymentMethodId: string) => void;
  onRequestPaymentMethodForm: () => void;
  onRequestGiftCardForm: () => void;
};
