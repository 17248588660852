import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagCheckoutContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['4'],
    gap: theme.spacing['6'],
  },
});
