import { StyleSheet } from 'react-native';
import { theme } from '@sg/garnish';

export const MENU_HERO_SHARED_STYLES = {
  heroHeight: 290,
};

export const styles = StyleSheet.create({
  // ─── WRAPPERS ───────────────────────────────────────────────────────

  heroWrapper: {
    backgroundColor: theme.colors.KHAKI_3,
    minHeight: MENU_HERO_SHARED_STYLES.heroHeight,
  },
  heroInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  // ─── BLOCKS ─────────────────────────────────────────────────────────

  infoColumnDesktop: {
    flex: 1,
    height: '100%',
    paddingVertical: theme.spacing['16'],
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  infoColumnTablet: {
    flex: 2,
    height: '100%',
    paddingVertical: theme.spacing['16'],
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  restaurantColumn: {
    flex: 1,
    height: '100%',
    paddingVertical: theme.spacing['16'],
    paddingLeft: theme.spacing['10'],
  },
  illustrationColumn: {
    flex: 1,
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingLeft: theme.spacing['10'],
  },

  // ─── ELEMENTS ───────────────────────────────────────────────────────

  illustration: {
    width: 240,
    height: 240,
  },
});
