import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { isToday } from 'date-fns';
import { formatDate } from '@sg/garnish';

import { ignoreTimezone } from '@order/utils';

import { BagHeaderLink } from '../BagHeaderLink';

/**
 * Time selector for the bag header.
 */
export const BagTimeHeader = (props: BagTimeHeaderProps) => {
  const { time, requestTimeChange } = props;

  const dateInfo = useDateInfo(time);
  const { formatMessage } = useIntl();

  return (
    <BagHeaderLink
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      onPress={requestTimeChange}
    >
      {dateInfo.time}
    </BagHeaderLink>
  );
};

export const BagTimeConnector = (props: Pick<BagTimeHeaderProps, 'time'>) => {
  const { time } = props;

  const dateInfo = useDateInfo(time);

  return (
    <FormattedMessage
      {...messages.timePrefix}
      values={{ state: dateInfo.state, day: dateInfo.day }}
    />
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const useDateInfo = (time: string) => {
  const validTime = ignoreTimezone(time);
  const { formatMessage } = useIntl();

  if (!validTime) {
    return {
      time: formatMessage({
        defaultMessage: 'soon',
        description: 'Bag | Header | Soon',
      }),
      day: '',
      state: 'invalid' as const,
    };
  }

  if (isToday(validTime)) {
    return {
      time: formatDate(validTime, 'h:mma'),
      day: '',
      state: 'today' as const,
    };
  }

  return {
    time: formatDate(validTime, 'h:mma'),
    day: formatDate(validTime, 'EEEE'),
    state: 'future' as const,
  };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  timePrefix: {
    defaultMessage: `{state, select,
    today {at}
    future {on {day},}
    other {}
  }`,
    description: 'Bag | Header | Time prefix',
  },
  accessibilityLabel: {
    defaultMessage: 'Change order time',
    description: 'Bag | Header | Time a11y',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagTimeHeaderProps = {
  time: string;
  requestTimeChange: () => void;
};
