/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * Run `yarn nx run order:generate-types` and edit the `.graphql` files
 *
 * See codegen config in .graphqlrc.yml for details
 */

/* eslint-disable unicorn/no-abusive-eslint-disable */ /* eslint-disable eslint-comments/no-unlimited-disable */ /* eslint-disable */ /* cspell:disable */

import { cacheExchange } from '@urql/exchange-graphcache';
import {
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
} from '@urql/exchange-graphcache';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import type * as Types from '@sg/graphql-schema';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string };
  join__FieldSet: { input: any; output: any };
  link__Import: { input: any; output: any };
};

export type AccountLocked = {
  readonly __typename?: 'AccountLocked';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** Error when a user has an active payment method */
export type ActivePaymentMethodError = UserError & {
  readonly __typename?: 'ActivePaymentMethodError';
  readonly errorMessage: Scalars['String']['output'];
};

export type AddAddressInput = {
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['input'];
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryStreet?: InputMaybe<Scalars['String']['input']>;
  readonly state: Scalars['String']['input'];
  readonly street: Scalars['String']['input'];
  readonly zipCode: Scalars['String']['input'];
};

export type AddAddressResponseUnion =
  | AddAddressSuccess
  | InvalidAddress
  | ValidationError;

export type AddAddressSuccess = {
  readonly __typename?: 'AddAddressSuccess';
  readonly address: Address;
};

export type AddCardFunctionalityDeprecated = UserError & {
  readonly __typename?: 'AddCardFunctionalityDeprecated';
  readonly errorMessage: Scalars['String']['output'];
};

export type AddLineItemBatchToCartInput = {
  readonly lineItems: ReadonlyArray<CartInput>;
};

export type AddLineItemToCartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetails?: InputMaybe<DeliveryOrderDetailInput>;
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

export type AddLineItemToCartResponseUnion =
  | AddLineItemToCartSuccess
  | LineItemModificationsInvalidException
  | MaxModificationsExceeded
  | ProductOutOfStock
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type AddLineItemToCartSuccess = {
  readonly __typename?: 'AddLineItemToCartSuccess';
  readonly cart: Order;
};

export type AddSubscriptionPaymentMethodResponseUnion =
  | AddSubscriptionPaymentMethodSuccessResponse
  | InvalidPaymentMethod
  | PaymentMethodAlreadyExistsError
  | UnknownUserError;

/** The result of adding a payment method to a subscription */
export type AddSubscriptionPaymentMethodSuccessResponse = {
  readonly __typename?: 'AddSubscriptionPaymentMethodSuccessResponse';
  /** Payment Method details */
  readonly creditCard: SubscriptionCreditCard;
  /** Payment Method Token */
  readonly paymentMethodToken: Scalars['String']['output'];
};

export type Addons = {
  readonly __typename?: 'Addons';
  readonly products: ReadonlyArray<Product>;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly notes: Maybe<Scalars['String']['output']>;
  /** Address Line 2 */
  readonly secondaryStreet: Maybe<Scalars['String']['output']>;
  readonly state: Scalars['String']['output'];
  /** Address Line 1 */
  readonly street: Scalars['String']['output'];
  readonly zipCode: Scalars['String']['output'];
};

export enum ApplyRewardFailureCode {
  LoyaltyNotProcessed = 'LOYALTY_NOT_PROCESSED',
  LoyaltyProgramUnavailable = 'LOYALTY_PROGRAM_UNAVAILABLE',
  RestaurantNotAcceptingOrders = 'RESTAURANT_NOT_ACCEPTING_ORDERS',
  RewardExpired = 'REWARD_EXPIRED',
  RewardNotApplicable = 'REWARD_NOT_APPLICABLE',
  RewardNotFound = 'REWARD_NOT_FOUND',
}

/** Validate and preview rewards success response */
export type ApplyRewardFailureMetadata = {
  readonly __typename?: 'ApplyRewardFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

export type ApplyRewardInput = {
  /** The ID of the order the reward is being applied to */
  readonly orderId: Scalars['ID']['input'];
  /** The ID of the reward being applied to the order */
  readonly rewardId: Scalars['ID']['input'];
};

export type ApplyRewardResponseUnion =
  | ApplyRewardSuccess
  | RewardNotApplied
  | ValidationError;

export type ApplyRewardSuccess = {
  readonly __typename?: 'ApplyRewardSuccess';
  readonly order: Order;
};

export type Asset = {
  readonly __typename?: 'Asset';
  readonly url: Scalars['String']['output'];
};

export type AvailableWantedTime = {
  readonly __typename?: 'AvailableWantedTime';
  readonly deliveryOffset: Scalars['Int']['output'];
  readonly time: Scalars['String']['output'];
};

export type BaseProduct = {
  readonly __typename?: 'BaseProduct';
  readonly asset: Asset;
  readonly description: Scalars['String']['output'];
  readonly heading: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isDeepLinkingEnabled: Scalars['Boolean']['output'];
  readonly isSeasonal: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly slug: Scalars['String']['output'];
  readonly subheading: Scalars['String']['output'];
};

export type BillingAccount = {
  readonly __typename?: 'BillingAccount';
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Scalars['String']['output'];
  readonly expirationState: ExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export enum BraintreeCardType {
  AmEx = 'AmEx',
  CarteBlanche = 'CarteBlanche',
  ChinaUnionPay = 'ChinaUnionPay',
  DinersClubInternational = 'DinersClubInternational',
  Discover = 'Discover',
  Elo = 'Elo',
  Jcb = 'JCB',
  Laser = 'Laser',
  Maestro = 'Maestro',
  MasterCard = 'MasterCard',
  Solo = 'Solo',
  Switch = 'Switch',
  UkMaestro = 'UKMaestro',
  Unknown = 'Unknown',
  Visa = 'Visa',
}

/** CX Credit */
export type CxCredit = {
  readonly __typename?: 'CXCredit';
  /** Display name of offer */
  readonly displayName: Scalars['String']['output'];
  /** ID of offer from loyalty vendor */
  readonly id: Scalars['ID']['output'];
};

export type CanCancel = {
  readonly __typename?: 'CanCancel';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  /** Order ID validating on */
  readonly orderId: Scalars['Float']['output'];
  /** Time in minutes before the order can no longer be canceled (sent to store) */
  readonly timeToFire: Scalars['Float']['output'];
};

/** Cannot find order to check if cancelation is allowed */
export type CanCancelAlreadySent = UserError & {
  readonly __typename?: 'CanCancelAlreadySent';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Generic Error for a failed can cancel query */
export type CanCancelFailed = UserError & {
  readonly __typename?: 'CanCancelFailed';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanCancelInput = {
  /** Gravy customer ID parent to the order being canceled. */
  readonly customerId: Scalars['String']['input'];
  /** Gravy order ID to be canceled. */
  readonly orderId: Scalars['String']['input'];
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Gravy restaurant ID parent to the order being canceled. */
  readonly restaurantId: Scalars['String']['input'];
  /** Watned time of order in ISO format. */
  readonly wantedTime: Scalars['DateTime']['input'];
};

/** Cannot find order to check if cancelation is allowed */
export type CanCancelNotFound = UserError & {
  readonly __typename?: 'CanCancelNotFound';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanCancelResponseUnion =
  | CanCancel
  | CanCancelAlreadySent
  | CanCancelFailed
  | CanCancelNotFound
  | CanCancelUnauthorized
  | CantCancelAlreadyCanceled
  | CantCancelLimitExcceeded
  | CantCancelOldOrders;

/** Unauthorized */
export type CanCancelUnauthorized = UserError & {
  readonly __typename?: 'CanCancelUnauthorized';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanNotReorder = {
  readonly __typename?: 'CanNotReorder';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** Generic Error for a failed cancellation */
export type CancelOrderFailed = UserError & {
  readonly __typename?: 'CancelOrderFailed';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelOrderInput = {
  /** Gravy customer ID parent to the order being canceled. */
  readonly customerId: Scalars['String']['input'];
  /** Gravy order ID to be canceled. */
  readonly orderId: Scalars['String']['input'];
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Description for why the order is being canceled. */
  readonly reason: Scalars['String']['input'];
  /** Refund the order if cancel succeeds?. */
  readonly refund: Scalars['Boolean']['input'];
  /** Gravy restaurant ID parent to the order being canceled. */
  readonly restaurantId: Scalars['String']['input'];
};

export type CancelOrderResponseUnion =
  | CancelOrderFailed
  | CancelOrderSuccess
  | CancelSuccessRefundFailed
  | CancellationFailedWithDelivery
  | CancellationLimitExceeded
  | OrderAlreadyCanceled
  | OrderAlreadySentToKitchen
  | OrderNotFound;

export type CancelOrderSuccess = {
  readonly __typename?: 'CancelOrderSuccess';
  readonly orderId: Scalars['String']['output'];
  /** Reason for a failed order or a success string. */
  readonly reason: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelSubscriptionAtCycleEndResponseUnion =
  | CancelSubscriptionAtCycleEndSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionAtCycleEndSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionAtCycleEndSuccessResponse';
  /** Canceled Subscription */
  readonly subscription: PaymentSubscription;
};

export type CancelSubscriptionForCustomerResponseUnion =
  | CancelSubscriptionForCustomerSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionForCustomerSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionForCustomerSuccessResponse';
  /** Indicates if the subscription was successfully cancelled */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelSubscriptionResponseUnion =
  | CancelSubscriptionSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionSuccessResponse';
  /** Canceled Subscription */
  readonly subscription: PaymentSubscription;
};

export type CancelSuccessRefundFailed = {
  readonly __typename?: 'CancelSuccessRefundFailed';
  /** Cancel succeeded but refund failed. */
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cancellation failed with delivery service */
export type CancellationFailedWithDelivery = UserError & {
  readonly __typename?: 'CancellationFailedWithDelivery';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cannot cancel more then one order per user per day */
export type CancellationLimitExceeded = UserError & {
  readonly __typename?: 'CancellationLimitExceeded';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cannot refund subscription transaction user error */
export type CannotRefundSubscriptionTransactionUserError = UserError & {
  readonly __typename?: 'CannotRefundSubscriptionTransactionUserError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Order has already been canceled */
export type CantCancelAlreadyCanceled = UserError & {
  readonly __typename?: 'CantCancelAlreadyCanceled';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Cannot cancel more then one order per user per day */
export type CantCancelLimitExcceeded = UserError & {
  readonly __typename?: 'CantCancelLimitExcceeded';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Order is greater then 2 days old */
export type CantCancelOldOrders = UserError & {
  readonly __typename?: 'CantCancelOldOrders';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export enum CardExpirationState {
  Expired = 'EXPIRED',
  Nearing = 'NEARING',
  Ok = 'OK',
}

export enum CardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  MasterCard = 'MASTER_CARD',
  Unsupported = 'UNSUPPORTED',
  Visa = 'VISA',
}

export type CartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

/** A Challenge is a configured SessionM Campaign with the "is_challenge: true" flag set in the campaign custom payload */
export type Challenge = {
  readonly __typename?: 'Challenge';
  /** Field is integer representation of the total amount of times the challenge behavior was completed by the customer. */
  readonly achieved: Scalars['Float']['output'];
  /** Field originates from SessionM Activity feed configuration message description. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Field originates from SessionM Activity feed configuration message subheader. */
  readonly descriptiveTitle: Scalars['String']['output'];
  readonly expirationDate: Scalars['DateTime']['output'];
  /** Field is a number representation of the challenge goal. Ex. A two STEP challenge would have a goal of `2.` A SPEND $20 challenge would have a goal of `2000` */
  readonly goal: Scalars['Float']['output'];
  /** Field is the SessionM campaign id */
  readonly id: Scalars['ID']['output'];
  readonly legalTerms: Scalars['String']['output'];
  /** Field is `progress` divided by `goal` times 100. To be used to render progress bar. */
  readonly percentageCompleted: Scalars['Float']['output'];
  readonly permalink: Scalars['String']['output'];
  /** Field indicates the current count completed toward challenge goal. */
  readonly progress: Scalars['Float']['output'];
  /** Field is copy to show on the challenge card to indicate progress. Determined by challenge status and progress fields. */
  readonly progressCopy: Scalars['String']['output'];
  readonly status: ChallengeStatus;
  /** Field represents the type of challenge, e.g. FLASH. Defaults to DEFAULT */
  readonly theme: ChallengeTheme;
  /** Field originates from SessionM Activity feed configuration message header. */
  readonly title: Scalars['String']['output'];
};

/** Challenge celebration data originating from campaign information on SessionM. To be used to render challenge celebration messaging. */
export type ChallengeCelebration = {
  readonly __typename?: 'ChallengeCelebration';
  /** Field originates from SessionM Activity feed configuration message description. When a user has multiple rewards to be "celebrated" this field is hardcoded. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Completed challenges ids */
  readonly challengesIds: ReadonlyArray<Scalars['String']['output']>;
  /** Expiration date for reward. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly expirationDate: Maybe<Scalars['DateTime']['output']>;
  /** Field is hardcoded in challenges-svc */
  readonly rewardUsageDisclaimer: Scalars['String']['output'];
  /** Field originates from SessionM Activity Feed configuration message header. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly title: Maybe<Scalars['String']['output']>;
};

/** Challenge celebration data originating from campaign information on SessionM. To be used to render challenge celebration messaging. */
export type ChallengeCelebrationV2 = {
  readonly __typename?: 'ChallengeCelebrationV2';
  /** Field originates from SessionM Activity feed configuration message description. When a user has multiple rewards to be "celebrated" this field is hardcoded. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Completed challenges ids */
  readonly challengesIds: ReadonlyArray<Scalars['String']['output']>;
  /** Expiration date for reward. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly expirationDate: Maybe<Scalars['DateTime']['output']>;
  /** Field originates from SessionM Activity Feed configuration message header. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly title: Scalars['String']['output'];
};

export type ChallengeOptInResultUnion = Challenge | LoyaltyOptInFailed;

/** Reward available to the customer */
export type ChallengeReward = {
  readonly __typename?: 'ChallengeReward';
  readonly expiresAt: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  /** The Premium reward is only available to a customer 1x every twelve hours. The redeemable field allows the UI to indicate whether the reward is currently available to be used. */
  readonly redeemable: Maybe<Scalars['Boolean']['output']>;
  /** The Premium reward is only available to a customer 1x every twelve hours. The redeemableAt will populate if reward is not redeemable with the next available date. */
  readonly redeemableAt: Maybe<Scalars['DateTime']['output']>;
  /** The channel(s) in which the reward can be redeemed */
  readonly redemptionChannel: RedemptionChannel;
  /** Type PREMIUM refers to Premium Subscription Reward, NON_PREMIUM rewards not associated with loyalty program */
  readonly rewardType: RewardType;
  /** Field references the offer terms and conditions configured in SessionM. If that field is not configured, returns the default SG terms url. */
  readonly terms: Scalars['String']['output'];
};

/** NOT_STARTED = Customer is qualified for challenge, STARTED = Customer has opted in, IN_PROGRESS: challenge is in progress, COMPLETED: customer has completed the challenge */
export enum ChallengeStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

/** FLASH = a flash challenge, DEFAULT = default theme */
export enum ChallengeTheme {
  Default = 'DEFAULT',
  Flash = 'FLASH',
}

export type ClosedLoyaltyTransactionDetailsInput = {
  readonly createdAt: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly isInFlight: Scalars['Boolean']['input'];
  readonly isInFlightDelivery: Scalars['Boolean']['input'];
  readonly lineItems: ReadonlyArray<OrderLineItemInput>;
  readonly orderType: Scalars['String']['input'];
  readonly payments: ReadonlyArray<OrderPaymentInput>;
  readonly restaurantEntityId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantParentId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantUtcOffset?: InputMaybe<Scalars['Float']['input']>;
  readonly selectedRewards?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']>
  >;
  readonly subtotal: Scalars['Float']['input'];
  readonly tax: Scalars['Float']['input'];
  readonly updatedAt?: InputMaybe<Scalars['String']['input']>;
  readonly wantedTime?: InputMaybe<Scalars['String']['input']>;
};

export enum ConflictType {
  DeliveryIneligible = 'DELIVERY_INELIGIBLE',
  IngredientUnavailable = 'INGREDIENT_UNAVAILABLE',
  NoConflict = 'NO_CONFLICT',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
}

/** The cost of the product may vary depending on the channel of the order. Use DELIVERY_COST_CHANNEL if the current order is delivery, and DEFAULT_COST_CHANNEL otherwise. Marketplace and Outpost cost channels are is implicit based on the request and restaurant. */
export enum CostChannel {
  DefaultCostChannel = 'DEFAULT_COST_CHANNEL',
  DeliveryCostChannel = 'DELIVERY_COST_CHANNEL',
  PickupCostChannel = 'PICKUP_COST_CHANNEL',
}

export type CourierDetails = {
  readonly __typename?: 'CourierDetails';
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
};

export type CreateAccountInput = {
  /** Must be in YYYY-MM-DD format. */
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  /** Must be a valid US phone number, exactly 10 digits with no formatting. */
  readonly phoneNumber: Scalars['String']['input'];
};

export type CreateAccountResponseUnion = CreateAccountSuccess | ValidationError;

export type CreateAccountSuccess = {
  readonly __typename?: 'CreateAccountSuccess';
  readonly customer: Customer;
};

/** Create Customer Loyalty Profile Result */
export type CreateLoyaltyProfileResult = {
  readonly __typename?: 'CreateLoyaltyProfileResult';
  /** External user id */
  readonly id: Scalars['ID']['output'];
};

export type CreateSetupIntent = {
  readonly __typename?: 'CreateSetupIntent';
  /** The Stripe created setup intent secret, required for the client to confirm its creation */
  readonly clientSecret: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type CreateSubscriptionResponseUnion =
  | CreateSubscriptionResult
  | FreeTrialNotEligible
  | UnknownUserError;

export type CreateSubscriptionResult = {
  readonly __typename?: 'CreateSubscriptionResult';
  /** First date that billing should happen */
  readonly firstBillingDate: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the subscription has a free trial associated with it. */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The ID of the subscription */
  readonly id: Scalars['ID']['output'];
  /** Indicates if the subscription is currently on a free trial (was not paid for yet). */
  readonly isTrialActive: Scalars['Boolean']['output'];
  /** The status of the subscription */
  readonly status: Scalars['String']['output'];
  /** The amount that will be charged */
  readonly subscriptionAmount: Scalars['String']['output'];
  /**
   * Indicates if the subscription has trial period
   * @deprecated Use "hasFreeTrial" instead
   */
  readonly trialDuration: Scalars['Boolean']['output'];
};

export type Credit = {
  readonly __typename?: 'Credit';
  readonly amount: Scalars['Float']['output'];
  readonly createdAt: Scalars['String']['output'];
  /** The type of credit */
  readonly creditType: CreditType;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expiresAt: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly remainingAmount: Scalars['Float']['output'];
  readonly storefrontEligibilityTitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export enum CreditType {
  AllNativeStorefronts = 'ALL_NATIVE_STOREFRONTS',
  InStoreOnly = 'IN_STORE_ONLY',
  OnlineOnly = 'ONLINE_ONLY',
}

export enum CustomProductType {
  Bowl = 'BOWL',
  Plate = 'PLATE',
}

/** Customer details including available credits */
export type Customer = {
  readonly __typename?: 'Customer';
  readonly addresses: ReadonlyArray<Address>;
  /** The amount of credit available to the customer. Credit is applied after tax. */
  readonly availableCredit: Scalars['Int']['output'];
  readonly billingAccounts: ReadonlyArray<BillingAccount>;
  readonly birthday: Maybe<Scalars['String']['output']>;
  /** A detail list of available credits to the customer. */
  readonly credits: ReadonlyArray<Credit>;
  /** Is this user marked for deletion. */
  readonly deletionInProgress: Maybe<Scalars['Boolean']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  /** Level Up Share URL to be used when sharing by email. */
  readonly referralShareUrlEmail: Scalars['String']['output'];
  /** Gravy-generated UUID used for analytics purposes */
  readonly trackingUuid: Maybe<Scalars['String']['output']>;
  /** Whether or not the customer will use any available credit on their next transaction. */
  readonly useCredit: Scalars['Boolean']['output'];
};

export type CustomerNotFound = UserError & {
  readonly __typename?: 'CustomerNotFound';
  readonly errorMessage: Scalars['String']['output'];
};

export type CustomerPass = {
  readonly __typename?: 'CustomerPass';
  readonly authenticationToken: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
};

/** Failure to fetch Customer Support Credits */
export type CustomerSupportCreditsFailureError = UserError & {
  readonly __typename?: 'CustomerSupportCreditsFailureError';
  readonly errorMessage: Scalars['String']['output'];
};

export type CustomerSupportCreditsResponseUnion =
  | CustomerSupportCreditsFailureError
  | CustomerSupportCreditsSuccessResponse;

/** Customer Support Credits Result */
export type CustomerSupportCreditsSuccessResponse = {
  readonly __typename?: 'CustomerSupportCreditsSuccessResponse';
  /** Credits allocated for customer support team to issue to customers */
  readonly credits: ReadonlyArray<CxCredit>;
};

export type DeclinedPaymentMethod = UserError & {
  readonly __typename?: 'DeclinedPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type DeleteAddressResponseUnion =
  | DeleteAddressSuccess
  | InvalidAddress
  | ValidationError;

export type DeleteAddressSuccess = {
  readonly __typename?: 'DeleteAddressSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type DeleteBillingAccountInput = {
  /** Billing account id. */
  readonly id: Scalars['Float']['input'];
};

export type DeleteBillingAccountResponse = {
  readonly __typename?: 'DeleteBillingAccountResponse';
  readonly success: Scalars['Boolean']['output'];
};

export type DeletePaymentMethodCardInput = {
  /** Id of card to delete */
  readonly id: Scalars['String']['input'];
};

export type DeleteSubscriptionPaymentMethodResponseUnion =
  | ActivePaymentMethodError
  | DeleteSubscriptionPaymentMethodSuccess
  | UnknownUserError;

/** The result of deleting a payment method from a subscription */
export type DeleteSubscriptionPaymentMethodSuccess = {
  readonly __typename?: 'DeleteSubscriptionPaymentMethodSuccess';
  /** Payment Methods */
  readonly creditCards: ReadonlyArray<SubscriptionCreditCard>;
};

export type DeliveryAlreadyInFlight = UserError & {
  readonly __typename?: 'DeliveryAlreadyInFlight';
  readonly errorMessage: Scalars['String']['output'];
};

export type DeliveryOrderDetail = {
  readonly __typename?: 'DeliveryOrderDetail';
  readonly address: Maybe<Address>;
  /** Delivery Fee in cents */
  readonly deliveryFee: Scalars['Float']['output'];
  /** Estimated delivery time for an delivery order, usually used as a range with +- 15mins offset. For outpost orders this is represented by the order "wantedTime". */
  readonly estimatedDeliveryTime: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly orderId: Scalars['ID']['output'];
  /** Courier tip in cents */
  readonly tip: Scalars['Float']['output'];
  readonly vendor: Scalars['String']['output'];
  readonly vendorRestaurantId: Scalars['String']['output'];
};

export type DeliveryOrderDetailInput = {
  readonly addressId: Scalars['ID']['input'];
  readonly deliveryFee: Scalars['Float']['input'];
  readonly tip: Scalars['Float']['input'];
  readonly vendor: Scalars['String']['input'];
  readonly vendorRestaurantId: Scalars['String']['input'];
};

export enum DeliveryPreferenceType {
  Curbside = 'CURBSIDE',
  Door = 'DOOR',
  LeaveAtDoor = 'LEAVE_AT_DOOR',
}

export type DeliverySpecifyAddress = UserError & {
  readonly __typename?: 'DeliverySpecifyAddress';
  readonly errorMessage: Scalars['String']['output'];
};

export type DietaryProperty = {
  readonly __typename?: 'DietaryProperty';
  readonly id: Scalars['ID']['output'];
  readonly name: DietaryPropertyKind;
};

export enum DietaryPropertyKind {
  Dairy = 'DAIRY',
  Fish = 'FISH',
  Gluten = 'GLUTEN',
  Meat = 'MEAT',
  Nuts = 'NUTS',
  Soy = 'SOY',
}

/** Discount on payment plan */
export type Discount = {
  readonly __typename?: 'Discount';
  /** The quantity of the discount */
  readonly amount: Maybe<Scalars['String']['output']>;
  /** The description of the discount */
  readonly description: Maybe<Scalars['String']['output']>;
  /** Discount identifier */
  readonly id: Scalars['ID']['output'];
  /** The name of the discount */
  readonly name: Scalars['ID']['output'];
};

export enum DressingMode {
  Mixed = 'MIXED',
  NotDressable = 'NOT_DRESSABLE',
  OnTheSide = 'ON_THE_SIDE',
  Removed = 'REMOVED',
}

export enum DressingWeight {
  Heavy = 'HEAVY',
  Light = 'LIGHT',
  Medium = 'MEDIUM',
}

export type DropOffLocation = {
  readonly __typename?: 'DropOffLocation';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

/** Unit used for handling duration types. */
export enum DurationUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type EditAddressInput = {
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['input'];
  readonly id: Scalars['ID']['input'];
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryStreet?: InputMaybe<Scalars['String']['input']>;
  readonly state: Scalars['String']['input'];
  readonly street: Scalars['String']['input'];
  readonly zipCode: Scalars['String']['input'];
};

export type EditLineItemInCartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly lineItemId: Scalars['ID']['input'];
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

export type EditLineItemInCartResponseUnion =
  | EditLineItemInCartSuccess
  | MaxModificationsExceeded
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type EditLineItemInCartSuccess = {
  readonly __typename?: 'EditLineItemInCartSuccess';
  readonly cart: Order;
};

export type EstimateRange = {
  readonly __typename?: 'EstimateRange';
  readonly end: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
};

export enum ExpirationState {
  Expired = 'EXPIRED',
  Nearing = 'NEARING',
  Ok = 'OK',
}

export type FavoriteLineItemInput = {
  readonly favorited: Scalars['Boolean']['input'];
  readonly lineItemId: Scalars['ID']['input'];
  readonly productId: Scalars['ID']['input'];
};

export type FavoritedLineItemsInput = {
  readonly page: Scalars['Int']['input'];
};

export type FeedbackInput = {
  readonly id: Scalars['ID']['input'];
  readonly isFeedbackComplete?: InputMaybe<Scalars['Boolean']['input']>;
  readonly questionId: Scalars['ID']['input'];
  readonly responseIds: ReadonlyArray<Scalars['ID']['input']>;
};

export type FeedbackQuestion = {
  readonly __typename?: 'FeedbackQuestion';
  readonly id: Scalars['ID']['output'];
  readonly responses: Maybe<ReadonlyArray<FeedbackResponse>>;
  readonly slug: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<Scalars['String']['output']>;
};

export type FeedbackResponse = {
  readonly __typename?: 'FeedbackResponse';
  readonly id: Scalars['ID']['output'];
  readonly text: Scalars['String']['output'];
};

export type FeedbackSuccess = {
  readonly __typename?: 'FeedbackSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type FieldValidationError = {
  readonly __typename?: 'FieldValidationError';
  readonly field: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
};

export enum FlattenedOrderStatuses {
  Completed = 'completed',
  Delivering = 'delivering',
  Failed = 'failed',
  Preparing = 'preparing',
  ReadyForCourier = 'readyForCourier',
  Received = 'received',
}

export type ForgotPasswordInput = {
  readonly email: Scalars['String']['input'];
};

export type ForgotPasswordResponseUnion =
  | ForgotPasswordSuccess
  | ValidationError;

export type ForgotPasswordSuccess = {
  readonly __typename?: 'ForgotPasswordSuccess';
  readonly success: Scalars['Boolean']['output'];
};

/** An error that occurs as a result of attempting to subscribe to a free trial plan without being eligible. */
export type FreeTrialNotEligible = UserError & {
  readonly __typename?: 'FreeTrialNotEligible';
  readonly errorMessage: Scalars['String']['output'];
};

/** input argument for geo distance based location search querys */
export type GeoCoordinates = {
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
};

/** "Gift card associated with another account" user error */
export type GiftCardAssociatedWithAnotherAccountError = UserError & {
  readonly __typename?: 'GiftCardAssociatedWithAnotherAccountError';
  readonly errorMessage: Scalars['String']['output'];
};

/** The customer's gift card balance result */
export type GiftCardBalance = {
  readonly __typename?: 'GiftCardBalance';
  /** Customer's ID. NOTE: It can be useful for client-side caching. */
  readonly customerId: Scalars['String']['output'];
  /** Customer's gift card balance in cents */
  readonly giftCardBalance: Scalars['Float']['output'];
};

export type GiftCardBalanceResponseUnion =
  | GiftCardBalance
  | UnableToGetGiftCardBalanceError;

/** The gift card order's delivery details */
export type GiftCardDeliveryDetail = {
  readonly __typename?: 'GiftCardDeliveryDetail';
  readonly deliveryDateTime: Maybe<Scalars['DateTime']['output']>;
  readonly deliveryMethods: ReadonlyArray<Scalars['String']['output']>;
  readonly isAsap: Scalars['Boolean']['output'];
};

/** Gift card was not found */
export type GiftCardNotFoundError = UserError & {
  readonly __typename?: 'GiftCardNotFoundError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Successful gift card order details */
export type GiftCardOrderDetail = {
  readonly __typename?: 'GiftCardOrderDetail';
  /** Delivery options for the gift card order. */
  readonly deliveryDetail: GiftCardDeliveryDetail;
  /** Gift card order ID. */
  readonly id: Scalars['String']['output'];
  /** Gift card order purchase time. */
  readonly purchasedAt: Scalars['DateTime']['output'];
  /** Purchased gift cards. */
  readonly userGiftCards: ReadonlyArray<UserGiftCard>;
};

export type GiftCardOrderDetailResponseUnion =
  | GiftCardOrderDetail
  | GiftCardOrderNotFoundError;

/** Gift card order was not found */
export type GiftCardOrderNotFoundError = UserError & {
  readonly __typename?: 'GiftCardOrderNotFoundError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Successful gift card purchase history */
export type GiftCardPurchaseHistory = {
  readonly __typename?: 'GiftCardPurchaseHistory';
  /** Customer's ID. It can be useful for client-side caching. */
  readonly customerId: Scalars['String']['output'];
  /** A list of gift card orders for this user. */
  readonly giftCardOrders: ReadonlyArray<GiftCardOrderDetail>;
};

export type GiftCardRedemptionDetailResponseUnion =
  | GiftCardNotFoundError
  | GiftCardRedemptionDetailSuccess;

/** Successful gift card redemption details */
export type GiftCardRedemptionDetailSuccess = {
  readonly __typename?: 'GiftCardRedemptionDetailSuccess';
  /** Gift card asset id. */
  readonly assetId: Maybe<Scalars['String']['output']>;
  /** Gift card balance. */
  readonly balance: Scalars['Float']['output'];
  /** Gift card number. */
  readonly cardNumber: Scalars['String']['output'];
  /** Gift card reg code. */
  readonly cardRegCode: Scalars['String']['output'];
  /** Message for gift card recipient. */
  readonly recipientMessage: Maybe<Scalars['String']['output']>;
  /** Gift card recipient's name. */
  readonly recipientName: Scalars['String']['output'];
  /** Gift card redemption id. */
  readonly redeemId: Scalars['String']['output'];
  /** Gift card sender's name. */
  readonly senderName: Scalars['String']['output'];
};

export enum GravyStatusType {
  ArrivedAtDropoff = 'ARRIVED_AT_DROPOFF',
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  DeliveryFailed = 'DELIVERY_FAILED',
  KitchenCompleted = 'KITCHEN_COMPLETED',
  OrderCreated = 'ORDER_CREATED',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusPreorderFailed = 'ORDER_STATUS_PREORDER_FAILED',
  OrderSubmitted = 'ORDER_SUBMITTED',
  SubmitFailed = 'SUBMIT_FAILED',
  Unknown = 'UNKNOWN',
}

export type Guest = {
  readonly __typename?: 'Guest';
  readonly email: Maybe<Scalars['String']['output']>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName: Maybe<Scalars['String']['output']>;
};

/** Used to determine if the user has unseen challenges, meaning they haven't yet queried for the challenge. */
export type HasUnseenChallenges = {
  readonly __typename?: 'HasUnseenChallenges';
  readonly hasUnseenChallenges: Scalars['Boolean']['output'];
};

export type InStoreOrder = {
  readonly __typename?: 'InStoreOrder';
  readonly id: Scalars['ID']['output'];
  readonly wantedTime: Scalars['String']['output'];
};

export type IncorrectPaymentMethod = UserError & {
  readonly __typename?: 'IncorrectPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Ingredient = {
  readonly __typename?: 'Ingredient';
  readonly asset: Asset;
  readonly dietaryProperties: ReadonlyArray<DietaryProperty>;
  readonly id: Scalars['ID']['output'];
  readonly kind: Maybe<IngredientKind>;
  readonly name: Scalars['String']['output'];
};

export enum IngredientKind {
  Base = 'BASE',
  Dressing = 'DRESSING',
  Premium = 'PREMIUM',
  Topping = 'TOPPING',
}

export type IngredientModification = {
  readonly __typename?: 'IngredientModification';
  readonly additionCost: Scalars['Float']['output'];
  readonly calories: Scalars['Float']['output'];
  readonly ingredient: Ingredient;
  /** Asserts whether the ingredient modification is a "grain" type (like "rice," "quinoa," etc.) */
  readonly isGrain: Scalars['Boolean']['output'];
  readonly kind: Maybe<Scalars['String']['output']>;
  /** Is the modification able to be mixed-in to the salad, primarily used for dressings */
  readonly mixable: Scalars['Boolean']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
  readonly removalCost: Scalars['Float']['output'];
  readonly subKind: Maybe<Scalars['String']['output']>;
  readonly substitutionCost: Scalars['Float']['output'];
};

export type IngredientModificationInput = {
  readonly ingredientId: Scalars['ID']['input'];
};

export type IngredientModificationKind = {
  readonly __typename?: 'IngredientModificationKind';
  /** The kind allows multiple quantity selections for a modification. */
  readonly allowMultipleQuantity: Scalars['Boolean']['output'];
  /** The number of free ingredient modifications that can be added of this kind. */
  readonly freeAggregateQuantity: Scalars['Int']['output'];
  /** The maximum number of ingredient modifications that can be of this kind. */
  readonly maxAggregateQuantity: Scalars['Int']['output'];
  /** The minimum number of ingredient modifications that can be of this kind. */
  readonly minAggregateQuantity: Scalars['Int']['output'];
  readonly modifications: ReadonlyArray<IngredientModification>;
};

export type IngredientModifications = {
  readonly __typename?: 'IngredientModifications';
  readonly bases: IngredientModificationKind;
  readonly bread: IngredientModificationKind;
  readonly dressings: IngredientModificationKind;
  readonly premiums: IngredientModificationKind;
  readonly toppings: IngredientModificationKind;
};

export type IngredientSubstitutionModificationInput = {
  readonly addedIngredientId: Scalars['ID']['input'];
  readonly removedIngredientId: Scalars['ID']['input'];
};

export type InvalidAddress = {
  readonly __typename?: 'InvalidAddress';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidBillingAccount = UserError & {
  readonly __typename?: 'InvalidBillingAccount';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidCredentials = {
  readonly __typename?: 'InvalidCredentials';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidCustomerPhone = UserError & {
  readonly __typename?: 'InvalidCustomerPhone';
  readonly errorMessage: Scalars['String']['output'];
};

/** "Invalid gift card" user error */
export type InvalidGiftCardError = UserError & {
  readonly __typename?: 'InvalidGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

export type InvalidInput = {
  readonly __typename?: 'InvalidInput';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidOrder = {
  readonly __typename?: 'InvalidOrder';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** An error that occurs as a result of submitting an invalid payment method. */
export type InvalidPaymentMethod = UserError & {
  readonly __typename?: 'InvalidPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
};

/** Deprecated, incorrect zip errors are generalized as "InvalidSubmittedBillingAccount" to avoid giving attackers useful information. */
export type InvalidPostalCode = UserError & {
  readonly __typename?: 'InvalidPostalCode';
  readonly errorMessage: Scalars['String']['output'];
};

export type InvalidPromoCode = {
  readonly __typename?: 'InvalidPromoCode';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidRegistrationCode = {
  readonly __typename?: 'InvalidRegistrationCode';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidSubmittedBillingAccount = UserError & {
  readonly __typename?: 'InvalidSubmittedBillingAccount';
  readonly errorMessage: Scalars['String']['output'];
};

export type InvalidTip = UserError & {
  readonly __typename?: 'InvalidTip';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type IssueCustomerSupportCreditResult = {
  readonly __typename?: 'IssueCustomerSupportCreditResult';
  readonly success: Scalars['Boolean']['output'];
};

/** JWT token used to login a user in Kustomer */
export type Kustomer = {
  readonly __typename?: 'Kustomer';
  readonly token: Scalars['String']['output'];
};

export type Ledger = {
  readonly __typename?: 'Ledger';
  /** Items that reduce the post-tax cost (i.e. total) of the order. */
  readonly credits: ReadonlyArray<LedgerItem>;
  /** Sum of all credits */
  readonly creditsTotal: Scalars['Int']['output'];
  /** Items that reduce the pre-tax cost of the order, usually the result of applying offers. */
  readonly discounts: ReadonlyArray<LedgerItem>;
  /** Sum of all discounts */
  readonly discountsTotal: Scalars['Int']['output'];
  readonly fees: ReadonlyArray<LedgerItem>;
  /** Sum of all fees */
  readonly feesTotal: Scalars['Int']['output'];
  /** Sum of the cost of all line items. */
  readonly subtotal: Scalars['Int']['output'];
  readonly tax: Scalars['Int']['output'];
  /** Tip applied to order in cents */
  readonly tip: Scalars['Int']['output'];
};

export type LedgerItem = {
  readonly __typename?: 'LedgerItem';
  readonly amount: Scalars['Int']['output'];
  /** May be shown as a tooltip or flyout in clients */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
};

export type LineItem = {
  readonly __typename?: 'LineItem';
  /** The ingredients that have been added on to the line item when compared to the product's default ingredients. */
  readonly addedIngredients: ReadonlyArray<Ingredient>;
  /** The total number of calories in the line item */
  readonly calories: Scalars['Int']['output'];
  /** The per item cost multiplied by the quantity, in cents. */
  readonly cost: Scalars['Int']['output'];
  /** The custom name for the line item that has been specified by the user. */
  readonly customName: Maybe<Scalars['String']['output']>;
  /** Name of the customer who ordered this lineItem */
  readonly customerName: Scalars['String']['output'];
  /** Dressing status, if the product is dressable. Otherwise NOT_DRESSABLE. */
  readonly dressingMode: DressingMode;
  /** Whether the line item is marked as favorite. */
  readonly favorited: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  /** The ingredients that make up the line item. These ingredients include any added or removed ingredients compared to the product's default ingredients. */
  readonly ingredients: ReadonlyArray<Ingredient>;
  /** Whether the line item has been customized. */
  readonly isCustom: Scalars['Boolean']['output'];
  /** Information outlining which dressings are mixed into the line item and their associated weights. */
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetails>;
  /** The per item cost of the product as configured, in cents. */
  readonly perItemCost: Scalars['Int']['output'];
  /** The product associated with a line item. */
  readonly product: Product;
  /** The number of products in the line item. */
  readonly quantity: Scalars['Int']['output'];
  /** The ingredients that have been removed from the line item when compared to the product's default ingredients. */
  readonly removedIngredients: ReadonlyArray<Ingredient>;
  readonly slug: Scalars['ID']['output'];
};

export type LineItemBySlugInput = {
  readonly slug: Scalars['ID']['input'];
};

export type LineItemInput = {
  readonly id: Scalars['ID']['input'];
};

export type LineItemModificationsInvalidException = UserError & {
  readonly __typename?: 'LineItemModificationsInvalidException';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type LineItemNotFound = {
  readonly __typename?: 'LineItemNotFound';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type LineItemRecommendation = {
  readonly __typename?: 'LineItemRecommendation';
  /** The date and time the recommendation was created. */
  readonly createdAt: Scalars['String']['output'];
  /** The description of the recommendation - commonly the list of ingredients. */
  readonly description: Scalars['String']['output'];
  /** The identifier for the complete item provided by this recommendation. */
  readonly id: Scalars['String']['output'];
  /** The ingredients that make up this recommendation. */
  readonly ingredientIds: ReadonlyArray<Scalars['String']['output']>;
  readonly ingredients: ReadonlyArray<Ingredient>;
  /** The name of the recommendation. */
  readonly name: Scalars['String']['output'];
  /** Whether the recommendation is based on a previous ordered item or is a new item */
  readonly recommendationType: RecommendationType;
};

export type LineItemResponseUnion =
  | LineItemNotFound
  | LineItemSuccess
  | ValidationError;

export type LineItemSuccess = {
  readonly __typename?: 'LineItemSuccess';
  readonly lineItem: LineItem;
};

export type Location = {
  readonly __typename?: 'Location';
  readonly estimateRange: EstimateRange;
  readonly restaurant: Restaurant;
  readonly status: LocationStatus;
  readonly vendor: Vendor;
};

export type LocationByAddressInput = {
  readonly address1?: InputMaybe<Scalars['String']['input']>;
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  /** id for an address present in the current session. */
  readonly addressId?: InputMaybe<Scalars['ID']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  /** google places id used for delivery locations search */
  readonly googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  readonly lat?: InputMaybe<Scalars['Float']['input']>;
  readonly lon?: InputMaybe<Scalars['Float']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly zipcode?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationByAddressResponseUnion =
  | InvalidInput
  | Location
  | NoValidRestaurants
  | ValidationError;

/**
 * Combines search result meta for match score and with location data
 * Note:
 * - when allLocations are queried the score is 1.0 for every result
 * - for geo-distance queries the score is the distance from the base coodinates
 */
export type LocationSearchResult = {
  readonly __typename?: 'LocationSearchResult';
  readonly location: StoreLocation;
  readonly score: Scalars['Float']['output'];
};

export enum LocationStatus {
  Closed = 'CLOSED',
  Invalid = 'INVALID',
  Open = 'OPEN',
}

export type LoginInput = {
  readonly email: Scalars['String']['input'];
  readonly grecaptcha: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

export type LoginResponseUnion =
  | AccountLocked
  | InvalidCredentials
  | LoginSuccess
  | ValidationError;

export type LoginSuccess = {
  readonly __typename?: 'LoginSuccess';
  readonly csrf: Scalars['String']['output'];
};

export type LogoutInput = {
  readonly customerId: Scalars['ID']['input'];
};

export type LogoutResponseUnion = LogoutSuccess | ValidationError;

export type LogoutSuccess = {
  readonly __typename?: 'LogoutSuccess';
  readonly csrf: Scalars['String']['output'];
};

/** Error thrown when a user fails to opt into a challenge */
export type LoyaltyOptInFailed = UserError & {
  readonly __typename?: 'LoyaltyOptInFailed';
  readonly errorMessage: Scalars['String']['output'];
};

/** Customer Loyalty Profile */
export type LoyaltyProfile = {
  readonly __typename?: 'LoyaltyProfile';
  /** External user id */
  readonly customerId: Scalars['ID']['output'];
  /** Customer has subscribed before */
  readonly hasPaidTierHistory: Scalars['Boolean']['output'];
  /** Date the loyalty profile was created */
  readonly joinDate: Scalars['DateTime']['output'];
  /** Loyalty ID (SessionM Profile ID) of the current customer */
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  /** Customer Tier */
  readonly tier: Maybe<Tier>;
  /** Customer Tier */
  readonly tierStatus: Maybe<TierStatus>;
  /** User trial eligibility. If empty then the user is not eligible */
  readonly trialEligible: Maybe<ReadonlyArray<TrialEligibility>>;
};

export type MaxModificationsExceeded = {
  readonly __typename?: 'MaxModificationsExceeded';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Menu = {
  readonly __typename?: 'Menu';
  readonly categories: ReadonlyArray<MenuCategory>;
  readonly id: Scalars['String']['output'];
};

export type MenuCategory = {
  readonly __typename?: 'MenuCategory';
  readonly id: Scalars['ID']['output'];
  readonly isCustom: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly products: ReadonlyArray<Product>;
};

export type MenuCollection = {
  readonly __typename?: 'MenuCollection';
  readonly color: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly primaryDescription: Scalars['String']['output'];
  readonly primaryImageUrl: Scalars['String']['output'];
  readonly primaryTitle: Scalars['String']['output'];
  readonly products: ReadonlyArray<Product>;
  readonly secondaryDescription: Maybe<Scalars['String']['output']>;
  readonly secondaryTitle: Maybe<Scalars['String']['output']>;
};

export type MinimumDeliverySubtotalNotMet = UserError & {
  readonly __typename?: 'MinimumDeliverySubtotalNotMet';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type MixedDressingDetails = {
  readonly __typename?: 'MixedDressingDetails';
  readonly ingredientId: Scalars['ID']['output'];
  readonly weight: DressingWeight;
};

export type MixedDressingDetailsInput = {
  readonly ingredientId: Scalars['ID']['input'];
  readonly weight: DressingWeight;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Adds an address to the customer's list of addresses. */
  readonly addAddress: AddAddressResponseUnion;
  readonly addLineItemBatchToCart: AddLineItemToCartResponseUnion;
  readonly addLineItemToCart: AddLineItemToCartResponseUnion;
  /** Add a payment method to a subscription */
  readonly addSubscriptionPaymentMethod: AddSubscriptionPaymentMethodResponseUnion;
  readonly applyReward: ApplyRewardResponseUnion;
  /** Takes an order cancel request input and attempts to cancel said order returning success or failure. */
  readonly cancelOrder: CancelOrderResponseUnion;
  /** Cancel a subscription */
  readonly cancelSubscription: CancelSubscriptionResponseUnion;
  /** Cancel a subscription at cycle end */
  readonly cancelSubscriptionAtCycleEnd: CancelSubscriptionAtCycleEndResponseUnion;
  /** Vendor endpoint to cancel subscription, requires bearer auth token */
  readonly cancelSubscriptionForCustomer: CancelSubscriptionForCustomerResponseUnion;
  /**
   * Takes a challenge ID (a.k.a. SessionM campaign ID) as an argument and opts customer into the identified challenge.  Returns the challenge details upon success. If an error occurs, the error field will be populated in the response: errors[0].message = modal title, errors[0].extension.detailedMessage = modal description/friendly message detailing what went wrong
   * @deprecated Use challengeOptInV2
   */
  readonly challengeOptIn: Challenge;
  /** Takes a challenge ID (a.k.a. SessionM campaign ID) as an argument and opts customer into the identified challenge.  Returns the challenge details upon success. If an error occurs, LoyaltyOptInFailed will be returned with the error details in the error field. */
  readonly challengeOptInV2: ChallengeOptInResultUnion;
  /** Creates a new account for the customer. */
  readonly createAccount: CreateAccountResponseUnion;
  /** Creates loyalty profile. New profiles are added to the free tier (1) by default if a tierId is not provided. */
  readonly createLoyaltyProfile: CreateLoyaltyProfileResult;
  readonly createPaymentIntent: Scalars['String']['output'];
  readonly createPaymentSubscriptionProfile: PaymentSubscriptionProfile;
  readonly createSetupPaymentIntent: CreateSetupIntent;
  /** Create a subscription */
  readonly createSubscription: CreateSubscriptionResult;
  /** Create a subscription */
  readonly createSubscriptionV2: CreateSubscriptionResponseUnion;
  /** Removes an address from the customer's list of addresses. */
  readonly deleteAddress: DeleteAddressResponseUnion;
  /** Deletes an existing billing account. */
  readonly deleteBillingAccount: DeleteBillingAccountResponse;
  readonly deletePaymentMethodCard: PaymentMethodCard;
  readonly editLineItemInCart: EditLineItemInCartResponseUnion;
  /** Favorites a Line Item. */
  readonly favoriteLineItem: LineItem;
  /** Handle user password recovery. */
  readonly forgotPassword: ForgotPasswordResponseUnion;
  /** Issue credit (aka sessionm offers) as sweet touches within the Kustomer platform. */
  readonly issueCustomerSupportCredit: IssueCustomerSupportCreditResult;
  /** Logs the user in. */
  readonly login: LoginResponseUnion;
  /** Logs the user out. */
  readonly logout: LogoutResponseUnion;
  /**
   * Opts into the free tier.
   * @deprecated Use optInFreeTierV2
   */
  readonly optInFreeTier: Scalars['Boolean']['output'];
  /** Opts into the free tier. */
  readonly optInFreeTierV2: Maybe<LoyaltyProfile>;
  /** Opts out of loyalty program. */
  readonly optOutLoyalty: OptOutLoyaltyResponseUnion;
  /** Opts a user out of loyalty. Requires a bearer auth token */
  readonly optOutLoyaltyForCustomer: OptOutLoyaltyForCustomerResponseUnion;
  /** Redemption of a gift card */
  readonly redeemGiftCard: RedeemGiftCardResponseUnion;
  /** Refund a transaction for a customer */
  readonly refundLoyaltyTransactionForCustomer: RefundLoyaltyTransactionResponseUnion;
  /** Takes an order refund request input and attempts to refund said order returning success or failure. */
  readonly refundOrder: RefundOrderSuccess;
  /** Registers a new user. */
  readonly register: RegisterResponseUnion;
  readonly registerGuest: Maybe<RegisterGuestResponseUnion>;
  /** Rejoin a previously canceled subscription */
  readonly rejoinSubscription: RejoinSubscriptionResponseUnion;
  readonly removeFromCart: RemoveFromCartResponseUnion;
  readonly removeReward: RemoveRewardResponseUnion;
  /** Remove a payment method from a subscription */
  readonly removeSubscriptionPaymentMethod: DeleteSubscriptionPaymentMethodResponseUnion;
  /** @deprecated Deprecated in favor of the "reorderOrder" mutation. */
  readonly reorder: ReorderResponseUnion;
  readonly reorderLineItem: ReorderResponseUnion;
  readonly reorderOrder: ReorderResponseUnion;
  readonly requestAccountDeletion: Maybe<RequestAccountDeletionResponseUnion>;
  /** Resend registration verification code. */
  readonly resendVerificationCode: ResendVerificationCodeResponseUnion;
  readonly sendLoyaltyTransaction: Scalars['Boolean']['output'];
  readonly setDefaultPaymentMethod: PaymentProfile;
  /** Signs the user in. */
  readonly signIn: SignInResponseUnion;
  /**
   * Submits a new billing account, using the braintree nonce.
   *       Will fail with an InvalidBillingAccountException if the credit card is invalid/expired.
   *       Will fail with a TooManyRequestsException if too many failed attempts are made.
   */
  readonly submitBraintreeBillingAccount: SubmitBraintreeBillingAccountResponseUnion;
  readonly submitFeedback: FeedbackSuccess;
  /** Order a gift card */
  readonly submitGiftCardOrderUsingPaymentMethod: SubmitGiftCardOrderUsingPaymentMethodResponseUnion;
  readonly submitInStoreFeedback: FeedbackSuccess;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderAsPayInStore: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace without a wanted time */
  readonly submitOrderAsPayInStoreASAP: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderAsPrepaid: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace without a wanted time */
  readonly submitOrderAsPrepaidASAP: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order, paid for using one of the customer's existing billing accounts. */
  readonly submitOrderUsingBillingAccount: SubmitOrderUsingBillingAccountResponseUnion;
  /** Submits an order, paid for using one of the customer's existing payment methods. */
  readonly submitOrderUsingPaymentMethod: SubmitOrderUsingPaymentMethodResponseUnion;
  /**
   * Submits an order, paid for using one of the customer's existing billing accounts.
   * @deprecated Use the submitOrderUsingPaymentMethod mutation instead
   */
  readonly submitOrderUsingPaymentMethodCard: SubmitOrderUsingBillingAccountResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderWithPaymentMethod: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits a gift card code. */
  readonly submitPromoCode: SubmitPromoCodeResponseUnion;
  /** Submits code to SessionM. If unsuccessful, code is submitted to LevelUp. */
  readonly submitPromoOrGiftCardCode: SubmitPromoCodeOrGiftCardResponseUnion;
  readonly unlockLoyaltyTransaction: Scalars['Boolean']['output'];
  /** Updates an address form the customer's list of addresses. */
  readonly updateAddress: UpdateAddressResponseUnion;
  /** Updates an existing billing account. */
  readonly updateBillingAccount: UpdateBillingAccountResponse;
  readonly updateCustomer: Maybe<UpdateCustomerResponse>;
  readonly updateKioskCustomer: Maybe<UpdateKioskCustomerResponse>;
  readonly updatePaymentMethod: PaymentMethodCard;
  /** Update the payment method for a subscription */
  readonly updateSubscriptionPaymentMethod: UpdateSubscriptionPaymentMethodSuccessResponse;
  /** Sets whether to use credit on next purchase. */
  readonly updateUseCredit: UpdateUseCreditResponse;
  readonly validateAndPreviewRewards: RewardsValidateAndPreviewUnion;
  readonly validateAndReserveRewards: RewardsValidateAndReserveUnion;
  /** Verify a newly registered user. */
  readonly verifyRegistration: VerifyRegistrationUnion;
};

export type MutationAddAddressArgs = {
  input: AddAddressInput;
};

export type MutationAddLineItemBatchToCartArgs = {
  input: AddLineItemBatchToCartInput;
};

export type MutationAddLineItemToCartArgs = {
  input: AddLineItemToCartInput;
};

export type MutationAddSubscriptionPaymentMethodArgs = {
  paymentNonce: Scalars['String']['input'];
};

export type MutationApplyRewardArgs = {
  input: ApplyRewardInput;
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};

export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionAtCycleEndArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionForCustomerArgs = {
  uuid: Scalars['String']['input'];
};

export type MutationChallengeOptInArgs = {
  challengeId: Scalars['String']['input'];
};

export type MutationChallengeOptInV2Args = {
  challengeId: Scalars['String']['input'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateLoyaltyProfileArgs = {
  tierId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateSubscriptionArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSubscriptionV2Args = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteAddressArgs = {
  addressId: Scalars['ID']['input'];
};

export type MutationDeleteBillingAccountArgs = {
  input: DeleteBillingAccountInput;
};

export type MutationDeletePaymentMethodCardArgs = {
  input: DeletePaymentMethodCardInput;
};

export type MutationEditLineItemInCartArgs = {
  input: EditLineItemInCartInput;
};

export type MutationFavoriteLineItemArgs = {
  input: FavoriteLineItemInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationIssueCustomerSupportCreditArgs = {
  creditId: Scalars['String']['input'];
  legacyInternalId: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationOptOutLoyaltyForCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  legacyInternalId?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRedeemGiftCardArgs = {
  code: Scalars['String']['input'];
  regCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRefundLoyaltyTransactionForCustomerArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  customerId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export type MutationRefundOrderArgs = {
  input: RefundOrderInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationRegisterGuestArgs = {
  input: RegisterGuestInput;
};

export type MutationRejoinSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationRemoveFromCartArgs = {
  input: RemoveFromCartInput;
};

export type MutationRemoveRewardArgs = {
  input: RemoveRewardInput;
};

export type MutationRemoveSubscriptionPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationReorderArgs = {
  input: ReorderInput;
};

export type MutationReorderLineItemArgs = {
  input: ReorderInput;
};

export type MutationReorderOrderArgs = {
  input: ReorderInput;
};

export type MutationResendVerificationCodeArgs = {
  input: ResendVerificationCodeInput;
};

export type MutationSendLoyaltyTransactionArgs = {
  input: ClosedLoyaltyTransactionDetailsInput;
};

export type MutationSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationSubmitBraintreeBillingAccountArgs = {
  input: SubmitBraintreeBillingAccountInput;
};

export type MutationSubmitFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationSubmitGiftCardOrderUsingPaymentMethodArgs = {
  input: SubmitGiftCardOrderUsingPaymentMethodInput;
};

export type MutationSubmitInStoreFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationSubmitOrderAsPayInStoreArgs = {
  input: SubmitOrderInput;
};

export type MutationSubmitOrderAsPayInStoreAsapArgs = {
  input: SubmitOrderAsapInput;
};

export type MutationSubmitOrderAsPrepaidArgs = {
  input: SubmitOrderInput;
};

export type MutationSubmitOrderAsPrepaidAsapArgs = {
  input: SubmitOrderAsapInput;
};

export type MutationSubmitOrderUsingBillingAccountArgs = {
  input: SubmitOrderUsingBillingAccountInput;
};

export type MutationSubmitOrderUsingPaymentMethodArgs = {
  input: SubmitOrderUsingPaymentMethodInput;
};

export type MutationSubmitOrderUsingPaymentMethodCardArgs = {
  input: SubmitOrderUsingPaymentMethodCardInput;
};

export type MutationSubmitOrderWithPaymentMethodArgs = {
  input: SubmitOrderWithPaymentMethodInput;
};

export type MutationSubmitPromoCodeArgs = {
  input: SubmitPromoCodeInput;
};

export type MutationSubmitPromoOrGiftCardCodeArgs = {
  input: SubmitPromoOrGiftCardCodeInput;
};

export type MutationUnlockLoyaltyTransactionArgs = {
  input: ClosedLoyaltyTransactionDetailsInput;
};

export type MutationUpdateAddressArgs = {
  input: EditAddressInput;
};

export type MutationUpdateBillingAccountArgs = {
  input: UpdateBillingAccountInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateKioskCustomerArgs = {
  input: UpdateKioskCustomerInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationUpdateSubscriptionPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateUseCreditArgs = {
  useCredit: Scalars['Boolean']['input'];
};

export type MutationValidateAndPreviewRewardsArgs = {
  input: RewardsValidationInput;
};

export type MutationValidateAndReserveRewardsArgs = {
  input: RewardsValidationInput;
};

export type MutationVerifyRegistrationArgs = {
  input: VerifyRegistrationInput;
};

/** "No Balance Gift card" user error */
export type NoBalanceGiftCardError = UserError & {
  readonly __typename?: 'NoBalanceGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

export type NoValidRestaurants = {
  readonly __typename?: 'NoValidRestaurants';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type OpenLoyaltyTransactionDetailsInput = {
  readonly createdAt: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly isInFlight: Scalars['Boolean']['input'];
  readonly isInFlightDelivery: Scalars['Boolean']['input'];
  readonly lineItems: ReadonlyArray<OrderLineItemInput>;
  readonly orderType: Scalars['String']['input'];
  readonly restaurantEntityId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantParentId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantUtcOffset?: InputMaybe<Scalars['Float']['input']>;
  readonly selectedRewards?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']>
  >;
  readonly subtotal: Scalars['Float']['input'];
  readonly tax: Scalars['Float']['input'];
  readonly updatedAt?: InputMaybe<Scalars['String']['input']>;
  readonly wantedTime?: InputMaybe<Scalars['String']['input']>;
};

export type OptOutLoyaltyForCustomerResponseUnion =
  | OptOutLoyaltyForCustomerSuccessResponse
  | UnknownUserError;

/** Opt Out Loyalty For Customer Result */
export type OptOutLoyaltyForCustomerSuccessResponse = {
  readonly __typename?: 'OptOutLoyaltyForCustomerSuccessResponse';
  /** Indicates if the user has successfully been opted out */
  readonly success: Scalars['Boolean']['output'];
};

export type OptOutLoyaltyResponseUnion =
  | OptOutLoyaltySuccessResponse
  | UnknownUserError;

/** Opt Out Loyalty Result */
export type OptOutLoyaltySuccessResponse = {
  readonly __typename?: 'OptOutLoyaltySuccessResponse';
  /** Indicates if the user has successfully been opted out */
  readonly success: Scalars['Boolean']['output'];
};

/** Order entity */
export type Order = {
  readonly __typename?: 'Order';
  readonly asset: Asset;
  readonly availableWantedTimes: ReadonlyArray<AvailableWantedTime>;
  /** Checks if this order is in the received state, but only if the wanted time is recent (12hrs). */
  readonly canCancel: Scalars['Boolean']['output'];
  /** Whether or not this order belongs to a restaurant with support for tracking the orders with a QSR system. This is always true for delivery orders, for pickup or outpost, it checks the tracking status of the restaurant. This flag affects what we should the customer in the order status feature. */
  readonly canTrackOrderStatus: Scalars['Boolean']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly deliveryOrderDetail: Maybe<DeliveryOrderDetail>;
  readonly description: Scalars['String']['output'];
  readonly dropoffLocation: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly includeUtensils: Scalars['Boolean']['output'];
  /** Indicates whether the order was canceled. */
  readonly isCanceled: Scalars['Boolean']['output'];
  /** Whether or not this order is an inflight order, this is done by checking the session.inFlightOrderId on a field resolver. */
  readonly isInFlight: Scalars['Boolean']['output'];
  /** Whether or not this order is an inflight delivery order, this is done by checking the session.inFlightDeliveryOrderId on a field resolver. */
  readonly isInFlightDelivery: Scalars['Boolean']['output'];
  /** Whether or not this order is pending the feedback from "rate your order". */
  readonly isPendingFeedback: Scalars['Boolean']['output'];
  readonly ledger: Ledger;
  readonly lineItems: ReadonlyArray<LineItem>;
  readonly orderType: OrderType;
  readonly payments: Maybe<ReadonlyArray<Payment>>;
  readonly restaurant: Restaurant;
  readonly selectedRewards: ReadonlyArray<Scalars['String']['output']>;
  readonly subtotal: Scalars['Int']['output'];
  readonly total: Maybe<Scalars['Int']['output']>;
  readonly updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Used by customers to reference the order when speaking to our support team. */
  readonly vendorId: Scalars['String']['output'];
  readonly wantedTime: Scalars['DateTime']['output'];
};

/** Order has already been canceled */
export type OrderAlreadyCanceled = UserError & {
  readonly __typename?: 'OrderAlreadyCanceled';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Order sent to kitchen */
export type OrderAlreadySentToKitchen = UserError & {
  readonly __typename?: 'OrderAlreadySentToKitchen';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type OrderConflict = {
  readonly __typename?: 'OrderConflict';
  readonly product: Product;
  /** Reorder conflict type */
  readonly type: ConflictType;
  readonly unavailableIngredients: ReadonlyArray<Ingredient>;
};

export type OrderLineItemInput = {
  readonly brinkPlu: Scalars['Float']['input'];
  readonly cost: Scalars['Float']['input'];
  readonly perItemCost: Scalars['Float']['input'];
  readonly quantity: Scalars['Float']['input'];
};

/** Order not found */
export type OrderNotFound = UserError & {
  readonly __typename?: 'OrderNotFound';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type OrderPaymentInput = {
  readonly amountInCents: Scalars['Float']['input'];
  readonly id: Scalars['String']['input'];
  readonly paymentTime: Scalars['String']['input'];
  readonly processor: Scalars['String']['input'];
};

export type OrderStatus = {
  readonly __typename?: 'OrderStatus';
  readonly courierDetails: Maybe<CourierDetails>;
  readonly customerId: Scalars['ID']['output'];
  /** Flattened list of order statuses, which collapses the different per order type order statuses into a standard list. */
  readonly flattenedOrderStatus: FlattenedOrderStatuses;
  readonly gravyStatus: GravyStatusType;
  readonly gravyUpdatedAt: Scalars['String']['output'];
  readonly order: Order;
  readonly orderId: Maybe<Scalars['ID']['output']>;
  readonly orderOccasion: Scalars['String']['output'];
  /** Raw list of order statuses, each status comes with the order type and there are multiple variants per each step. */
  readonly orderStatus: Scalars['String']['output'];
};

export type OrderStatusResponseUnion = InvalidOrder | OrderStatus;

export enum OrderType {
  Delivery = 'DELIVERY',
  InStore = 'IN_STORE',
  Pickup = 'PICKUP',
}

export type OrdersInput = {
  readonly page: Scalars['Int']['input'];
  readonly take: Scalars['Int']['input'];
};

export type OrdersInputV2 = {
  readonly includeCanceledOrders?: InputMaybe<Scalars['Boolean']['input']>;
  readonly includePlacedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  readonly page: Scalars['Int']['input'];
  readonly take: Scalars['Int']['input'];
};

export type OrdersResponseSuccess = {
  readonly __typename?: 'OrdersResponseSuccess';
  readonly orders: ReadonlyArray<Order>;
  readonly pagination: Pagination;
};

export type OrdersResponseUnion = OrdersResponseSuccess | ValidationError;

export type Pagination = {
  readonly __typename?: 'Pagination';
  /** Last available page. */
  readonly last: Scalars['Int']['output'];
  /** The page requested. */
  readonly page: Scalars['Int']['output'];
  /** Items per page. */
  readonly take: Scalars['Int']['output'];
  /** Total number of items available. */
  readonly total: Scalars['Int']['output'];
};

/** Payment entity */
export type Payment = {
  readonly __typename?: 'Payment';
  readonly amountInCents: Scalars['Float']['output'];
  readonly id: Scalars['ID']['output'];
  readonly paymentTime: Scalars['DateTime']['output'];
  readonly processor: Scalars['String']['output'];
};

/** Payment method already exists */
export type PaymentMethodAlreadyExistsError = UserError & {
  readonly __typename?: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: Scalars['String']['output'];
};

export type PaymentMethodBillingAccount = {
  readonly __typename?: 'PaymentMethodBillingAccount';
  /** The type of card */
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodCard = {
  readonly __typename?: 'PaymentMethodCard';
  /** The type of card */
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodCardUnion =
  | PaymentMethodBillingAccount
  | PaymentMethodCard;

export enum PaymentMethods {
  PaymentMethodCard = 'PAYMENT_METHOD_CARD',
  PayInStore = 'PAY_IN_STORE',
  Prepaid = 'PREPAID',
}

/** Payment Plan */
export type PaymentPlan = {
  readonly __typename?: 'PaymentPlan';
  /** ID of plan from Braintree */
  readonly braintreePlanId: Scalars['ID']['output'];
  /** Tier plan belongs to */
  readonly tier: Tier;
};

/** Customer Payment Profile */
export type PaymentProfile = {
  readonly __typename?: 'PaymentProfile';
  readonly defaultCardId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
};

/** Customer Subscription object */
export type PaymentSubscription = {
  readonly __typename?: 'PaymentSubscription';
  readonly balance: Scalars['String']['output'];
  /**
   * The frequency in which subscription payments should be made.
   * @deprecated Deprecated in favor of `billingRecurrence`
   */
  readonly billingFrequencyUnit: Maybe<DurationUnit>;
  readonly billingHistory: ReadonlyArray<SubscriptionTransactions>;
  readonly billingPeriodEndDate: Maybe<Scalars['String']['output']>;
  readonly billingPeriodStartDate: Maybe<Scalars['String']['output']>;
  /** The recurrence in which subscription payments should be made. */
  readonly billingRecurrence: Maybe<DurationUnit>;
  readonly createdAt: Scalars['String']['output'];
  /** The first billing date of this subscription. */
  readonly firstBillingDate: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the subscription has a free trial associated with it. */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The ID of the subscription */
  readonly id: Scalars['ID']['output'];
  /** Indicates if the subscription is currently on a free trial (was not paid for yet). */
  readonly isTrialActive: Scalars['Boolean']['output'];
  readonly nextBillingDate: Scalars['String']['output'];
  readonly nextBillingPeriodAmount: Scalars['String']['output'];
  readonly paidThroughDate: Maybe<Scalars['DateTime']['output']>;
  readonly planId: Scalars['String']['output'];
  readonly price: Maybe<Scalars['String']['output']>;
  readonly status: PaymentSubscriptionStatus;
};

/** Braintree Customer Payment Subscription Profile */
export type PaymentSubscriptionProfile = {
  readonly __typename?: 'PaymentSubscriptionProfile';
  readonly creditCards: Maybe<ReadonlyArray<SubscriptionCreditCard>>;
  readonly id: Scalars['ID']['output'];
  readonly transactionHistory: ReadonlyArray<SubscriptionTransactions>;
};

export enum PaymentSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Expired = 'Expired',
  PastDue = 'PastDue',
  Pending = 'Pending',
}

export type Product = {
  readonly __typename?: 'Product';
  readonly asset: Asset;
  readonly brinkPlu: Maybe<Scalars['Int']['output']>;
  readonly calories: Scalars['Int']['output'];
  readonly categoryId: Scalars['String']['output'];
  readonly categoryName: Scalars['String']['output'];
  /** The cost of the product may vary depending on the channel of the order. Use DELIVERY_COST_CHANNEL if the current order is delivery, and DEFAULT_COST_CHANNEL otherwise. Marketplace and Outpost cost channels are is implicit based on the request and restaurant. */
  readonly channelCost: Scalars['Int']['output'];
  /** Base cost, in cents */
  readonly cost: Scalars['Int']['output'];
  /** If a product is custom, this field provides the type of that product, otherwise the field will be null. Clients must only use this field for cosmetic purposes; for example, displaying a custom product card on the menu page. */
  readonly customType: Maybe<CustomProductType>;
  readonly description: Scalars['String']['output'];
  readonly dietaryProperties: ReadonlyArray<DietaryProperty>;
  /** Date that product will no longer be valid */
  readonly expirationDate: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly ingredientModifications: IngredientModifications;
  readonly ingredients: ReadonlyArray<Ingredient>;
  readonly isCustom: Scalars['Boolean']['output'];
  readonly isModifiable: Scalars['Boolean']['output'];
  readonly isSalad: Scalars['Boolean']['output'];
  readonly label: Maybe<ProductLabel>;
  /** The total number of additions, removals, and substitutions that are allowed to be modified for this product */
  readonly maxModifications: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
  readonly restaurant: Restaurant;
  readonly restaurantId: Scalars['ID']['output'];
  readonly slug: Scalars['String']['output'];
  readonly throttleItem: Scalars['Boolean']['output'];
};

export type ProductChannelCostArgs = {
  costChannel?: InputMaybe<CostChannel>;
};

export type ProductLabel = {
  readonly __typename?: 'ProductLabel';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type ProductOutOfStock = UserError & {
  readonly __typename?: 'ProductOutOfStock';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type ProductUnavailable = UserError & {
  readonly __typename?: 'ProductUnavailable';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** Promo code has already been applied */
export type PromoCodeAlreadyApplied = UserError & {
  readonly __typename?: 'PromoCodeAlreadyApplied';
  readonly errorMessage: Scalars['String']['output'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly addons: Addons;
  readonly address: Maybe<Address>;
  /** Returns all sweetgreen locations (that aren't hidden etc) */
  readonly allLocations: Maybe<ReadonlyArray<Maybe<LocationSearchResult>>>;
  readonly baseProduct: Maybe<BaseProduct>;
  /** Returns if an order can be canceled and the amount of minutes before the order cannot be canceled. */
  readonly canCancel: ReadonlyArray<CanCancelResponseUnion>;
  readonly cart: Maybe<Order>;
  readonly categoryUpsells: ReadonlyArray<UpsellCategory>;
  /**
   * Returns the challenge celebration details available for a customer from newly completed challenges. If multiple challenges have been newly completed, returns hardcoded description and disclaimer with no title or expirationDate. Returns null if customer has already viewed celebration messaging.
   * @deprecated Deprecated in favor of the "challengeCelebrationModal" query.
   */
  readonly challengeCelebration: Maybe<ChallengeCelebration>;
  /** Returns the challenge celebration details available for a customer from newly completed challenges. Returns null if customer has already viewed celebration messaging. */
  readonly challengeCelebrationV2: Maybe<ChallengeCelebrationV2>;
  /** Returns all eligible rewards available to the customer */
  readonly challengeRewards: ReadonlyArray<ChallengeReward>;
  /** Returns customers eligible challenges */
  readonly challenges: ReadonlyArray<Challenge>;
  readonly currentCustomer: Maybe<Customer>;
  /** Pass card for scan to pay, usually for adding to apple wallet */
  readonly customerPass: Maybe<CustomerPass>;
  /** Returns available credits allocated for customer support team to issue. Requires a bearer auth token. */
  readonly customerSupportCredits: CustomerSupportCreditsResponseUnion;
  readonly favoritedLineItems: ReadonlyArray<LineItem>;
  readonly feedbackQuestions: ReadonlyArray<FeedbackQuestion>;
  /** Returns all plans associated to tierId */
  readonly fetchPlansByTierId: Maybe<ReadonlyArray<PaymentPlan>>;
  readonly getSubscriptions: ReadonlyArray<PaymentSubscription>;
  /** Customer's gift card balance */
  readonly giftCardBalance: GiftCardBalanceResponseUnion;
  /** Customer's gift card order detail */
  readonly giftCardOrderDetail: GiftCardOrderDetailResponseUnion;
  /** Customer's gift card purchase history */
  readonly giftCardPurchaseHistory: GiftCardPurchaseHistory;
  /** Gift card redepmtion details */
  readonly giftCardRedemptionDetail: GiftCardRedemptionDetailResponseUnion;
  /** Returns a boolean indicating if user has any unseen challenges */
  readonly hasUnseenChallenges: HasUnseenChallenges;
  readonly inStoreFeedbackQuestions: ReadonlyArray<FeedbackQuestion>;
  readonly inStoreOrderLastPendingFeedback: Maybe<InStoreOrder>;
  readonly inStorePaymentMethods: ReadonlyArray<Credit>;
  /** Kustomer JWT token used to login the user in Kustomer */
  readonly kustomerToken: Maybe<Kustomer>;
  readonly lineItem: Maybe<LineItemResponseUnion>;
  readonly lineItemBySlug: Maybe<LineItemResponseUnion>;
  readonly lineItemRecommendations: ReadonlyArray<LineItemRecommendation>;
  readonly locationByAddress: LocationByAddressResponseUnion;
  /** Returns existing loyalty profile, otherwise creates one */
  readonly loyaltyProfile: Maybe<LoyaltyProfile>;
  readonly menuCollection: MenuCollection;
  readonly menuCollections: ReadonlyArray<MenuCollection>;
  /** Get all non-refunded transactions for a customer */
  readonly nonRefundedLoyaltyTransactionsForCustomer: ReadonlyArray<SubscriptionTransactions>;
  readonly order: Order;
  readonly orderLastPendingFeedback: Maybe<Order>;
  readonly orderStatus: Maybe<OrderStatusResponseUnion>;
  readonly orders: OrdersResponseUnion;
  readonly ordersV2: OrdersResponseUnion;
  readonly paymentMethodCards: ReadonlyArray<PaymentMethodCardUnion>;
  /** Returns a payment profile entry matching the customer customerId provided */
  readonly paymentProfile: Maybe<PaymentProfile>;
  /** Returns a payment subscription profile matching the customer customerId provided */
  readonly paymentSubscriptionProfile: Maybe<PaymentSubscriptionProfile>;
  readonly product: Product;
  readonly productForRestaurant: Maybe<Product>;
  readonly restaurant: Maybe<Restaurant>;
  readonly restaurantCapacity: RestaurantCapacity;
  readonly rewards: ReadonlyArray<Reward>;
  /** Returns all rewards available to the user */
  readonly rewardsForUser: ReadonlyArray<ChallengeReward>;
  /** Returns all sweetgreen locations within topLeft and bottomRight map coordinates~ */
  readonly searchLocationsByBoundingBox: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /** Returns the sweetgreen location matching the entityId */
  readonly searchLocationsByEntityId: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /** Returns sweetgreen locations by string weighted by keyword match */
  readonly searchLocationsByString: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given kilometer radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInKilometerRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given kilometer radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInMeterRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given mile radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInMileRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  readonly session: Session;
  /** Returns a subscription plan matching the planId provided */
  readonly subscriptionPlanById: Maybe<SubscriptionPlan>;
  /** Returns an array of subscription plans matching the planIds provided */
  readonly subscriptionPlansByIds: Maybe<ReadonlyArray<SubscriptionPlan>>;
};

export type QueryAddressArgs = {
  addressId: Scalars['ID']['input'];
};

export type QueryAllLocationsArgs = {
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBaseProductArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCanCancelArgs = {
  input: ReadonlyArray<CanCancelInput>;
};

export type QueryCategoryUpsellsArgs = {
  restaurantId: Scalars['String']['input'];
};

export type QueryFavoritedLineItemsArgs = {
  input: FavoritedLineItemsInput;
};

export type QueryFeedbackQuestionsArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryFetchPlansByTierIdArgs = {
  tierId: Scalars['ID']['input'];
};

export type QueryGetSubscriptionsArgs = {
  status?: InputMaybe<ReadonlyArray<SubscriptionStatus>>;
};

export type QueryGiftCardOrderDetailArgs = {
  orderId: Scalars['String']['input'];
};

export type QueryGiftCardRedemptionDetailArgs = {
  id: Scalars['String']['input'];
};

export type QueryInStoreFeedbackQuestionsArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryLineItemArgs = {
  input: LineItemInput;
};

export type QueryLineItemBySlugArgs = {
  input: LineItemBySlugInput;
};

export type QueryLocationByAddressArgs = {
  input: LocationByAddressInput;
};

export type QueryMenuCollectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMenuCollectionsArgs = {
  menuId: Scalars['ID']['input'];
};

export type QueryNonRefundedLoyaltyTransactionsForCustomerArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryOrderStatusArgs = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrdersArgs = {
  input: OrdersInput;
};

export type QueryOrdersV2Args = {
  input: OrdersInputV2;
};

export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductForRestaurantArgs = {
  baseProductSlug: Scalars['String']['input'];
  restaurantId: Scalars['String']['input'];
};

export type QueryRestaurantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRestaurantCapacityArgs = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  minimumLineItemCapacity?: InputMaybe<Scalars['Int']['input']>;
  restaurantId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchLocationsByBoundingBoxArgs = {
  bottomRight: GeoCoordinates;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  topLeft: GeoCoordinates;
};

export type QuerySearchLocationsByEntityIdArgs = {
  entityId: Scalars['String']['input'];
};

export type QuerySearchLocationsByStringArgs = {
  searchString: Scalars['String']['input'];
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInKilometerRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInMeterRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInMileRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySubscriptionPlanByIdArgs = {
  planId: Scalars['ID']['input'];
};

export type QuerySubscriptionPlansByIdsArgs = {
  planIds: ReadonlyArray<Scalars['ID']['input']>;
};

export enum RecommendationType {
  Historical = 'HISTORICAL',
  New = 'NEW',
}

export type RedeemGiftCardResponseUnion =
  | GiftCardAssociatedWithAnotherAccountError
  | GiftCardBalance
  | InvalidGiftCardError
  | NoBalanceGiftCardError
  | UnableToRedeemGiftCardError;

/** InStore = Reward can be redeemed in store, Delivery = Reward can be redeemed in delivery, Pickup = Reward can be redeemed in pickup, All = Reward can be redeemed in all channels */
export enum RedemptionChannel {
  All = 'All',
  Delivery = 'Delivery',
  InStore = 'InStore',
  Outpost = 'Outpost',
  Pickup = 'Pickup',
}

export type RefundLoyaltyTransactionResponseUnion =
  | CannotRefundSubscriptionTransactionUserError
  | RefundLoyaltyTransactionSuccessResponse;

/** The result of adding a payment method to a subscription */
export type RefundLoyaltyTransactionSuccessResponse = {
  readonly __typename?: 'RefundLoyaltyTransactionSuccessResponse';
  /** Whether the refund was successful or not */
  readonly success: Scalars['Boolean']['output'];
};

export type RefundOrderInput = {
  /** Person making the adjustment to the order payment. */
  readonly adjuster: Scalars['String']['input'];
  /** Array of Gravy IDs to be refunded. */
  readonly order_ids: ReadonlyArray<Scalars['String']['input']>;
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Description for why the order is being refunded. */
  readonly reason: Scalars['String']['input'];
};

export type RefundOrderSuccess = {
  readonly __typename?: 'RefundOrderSuccess';
  /** Array of refund results for each refunded order. */
  readonly refundResults: ReadonlyArray<RefundResult>;
};

export type RefundResult = {
  readonly __typename?: 'RefundResult';
  /** Gravy order ID being refunded. */
  readonly id: Scalars['String']['output'];
  /** Reason for a failed order or a success string. */
  readonly reason: Scalars['String']['output'];
  /** Boolean representing if the order successfully refunded or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type RegisterGuestFailed = {
  readonly __typename?: 'RegisterGuestFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RegisterGuestInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterGuestResponseUnion =
  | RegisterGuestFailed
  | RegisterGuestSuccess
  | ValidationError;

export type RegisterGuestSuccess = {
  readonly __typename?: 'RegisterGuestSuccess';
  readonly csrf: Scalars['String']['output'];
  /** Guest details. */
  readonly guest: Guest;
};

export type RegisterInput = {
  /** Must be in YYYY-MM-DD format. */
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly grecaptcha: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
  /** Must be a valid US phone number, exactly 10 digits with no formatting. */
  readonly phoneNumber: Scalars['String']['input'];
};

export type RegisterResponseUnion = RegisterSuccess | ValidationError;

export type RegisterSuccess = {
  readonly __typename?: 'RegisterSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type RejoinSubscriptionResponseUnion =
  | RejoinSubscriptionSuccessResponse
  | SubscriptionCannotBeReactivatedError
  | UnknownUserError;

export type RejoinSubscriptionSuccessResponse = {
  readonly __typename?: 'RejoinSubscriptionSuccessResponse';
  /** Rejoined Subscription */
  readonly subscription: PaymentSubscription;
};

export type RemoveFromCartInput = {
  readonly lineItemId: Scalars['ID']['input'];
};

export type RemoveFromCartResponseUnion =
  | RemoveFromCartSuccess
  | ValidationError;

export type RemoveFromCartSuccess = {
  readonly __typename?: 'RemoveFromCartSuccess';
  readonly cart: Order;
  readonly success: Scalars['Boolean']['output'];
};

export type RemoveRewardInput = {
  /** The ID of the order the reward is being removed from */
  readonly orderId: Scalars['ID']['input'];
};

export type RemoveRewardResponseUnion =
  | RemoveRewardSuccess
  | RewardNotRemoved
  | ValidationError;

export type RemoveRewardSuccess = {
  readonly __typename?: 'RemoveRewardSuccess';
  readonly order: Order;
};

export type ReorderConflict = {
  readonly __typename?: 'ReorderConflict';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  /** Indicates if all products in the order are unavailable */
  readonly isNothingAvailable: Scalars['Boolean']['output'];
};

export type ReorderInput = {
  readonly deliveryOrderDetails?: InputMaybe<DeliveryOrderDetailInput>;
  readonly destinationRestaurantId: Scalars['ID']['input'];
  readonly id: Scalars['ID']['input'];
  /** Ignore order conflicts and submit reorder */
  readonly ignoreConflicts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReorderResponseUnion =
  | CanNotReorder
  | ReorderConflict
  | ReorderSuccess
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type ReorderSuccess = {
  readonly __typename?: 'ReorderSuccess';
  readonly cart: Order;
  /** Indicates if the reorder supports mixing */
  readonly isMixingSupported: Scalars['Boolean']['output'];
};

export type RequestAccountDeletionFailed = {
  readonly __typename?: 'RequestAccountDeletionFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RequestAccountDeletionResponseUnion =
  | RequestAccountDeletionFailed
  | RequestAccountDeletionSuccess;

export type RequestAccountDeletionSuccess = {
  readonly __typename?: 'RequestAccountDeletionSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type ResendVerificationCodeInput = {
  readonly email: Scalars['String']['input'];
  readonly grecaptcha: Scalars['String']['input'];
};

export type ResendVerificationCodeResponseUnion =
  | ResendVerificationCodeSuccess
  | ValidationError;

export type ResendVerificationCodeSuccess = {
  readonly __typename?: 'ResendVerificationCodeSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type Restaurant = {
  readonly __typename?: 'Restaurant';
  readonly address: Scalars['String']['output'];
  readonly asset: Asset;
  /** Some outposts have multiple shelves within them (say, on different floors), and allow customers to select one */
  readonly availableDropOffLocations: ReadonlyArray<DropOffLocation>;
  readonly city: Scalars['String']['output'];
  readonly deliveryFee: Scalars['Int']['output'];
  readonly deliveryMinSubtotal: Scalars['Float']['output'];
  readonly entityId: Maybe<Scalars['Int']['output']>;
  readonly flexMessage: Maybe<Scalars['String']['output']>;
  readonly hours: RestaurantHours;
  readonly id: Scalars['ID']['output'];
  readonly isAcceptingOrders: Scalars['Boolean']['output'];
  readonly isOutpost: Scalars['Boolean']['output'];
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly menu: Menu;
  readonly name: Scalars['String']['output'];
  readonly notAcceptingOrdersReason: Scalars['String']['output'];
  readonly parentId: Maybe<Scalars['Int']['output']>;
  readonly phone: Scalars['String']['output'];
  readonly showDeliveryFeeDisclosure: Scalars['Boolean']['output'];
  readonly showDeliveryPriceDifferenciationDisclosure: Scalars['Boolean']['output'];
  readonly showOutpostPriceDifferenciationDisclosure: Scalars['Boolean']['output'];
  readonly slug: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly utcOffset: Maybe<Scalars['Int']['output']>;
  readonly zipCode: Scalars['String']['output'];
};

export type RestaurantCapacity = {
  readonly __typename?: 'RestaurantCapacity';
  /** available capacity slots */
  readonly availableWantedTimes: ReadonlyArray<RestaurantCapacitySlot>;
  readonly restaurantAddress: Scalars['String']['output'];
  readonly restaurantHours: Scalars['String']['output'];
  readonly restaurantId: Scalars['ID']['output'];
};

export type RestaurantCapacitySlot = {
  readonly __typename?: 'RestaurantCapacitySlot';
  readonly availableLineItems: Scalars['Float']['output'];
  /** A timeslot in Day, HH:MM format using timezone of the store's location */
  readonly formatted: Scalars['String']['output'];
  readonly maxLineItems: Scalars['Float']['output'];
  /** A timeslot in ISO8601 date time format using timezone of the store's location */
  readonly original: Scalars['String']['output'];
};

export type RestaurantHours = {
  readonly __typename?: 'RestaurantHours';
  readonly formatted: Maybe<Scalars['String']['output']>;
  readonly permanent: ReadonlyArray<RestaurantStoreHours>;
  readonly store: ReadonlyArray<RestaurantStoreHours>;
};

export type RestaurantIsNotAcceptingOrders = UserError & {
  readonly __typename?: 'RestaurantIsNotAcceptingOrders';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantMaxDeliveryQuantityExceeded = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantMaxQuantityExceeded = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantStoreHours = {
  readonly __typename?: 'RestaurantStoreHours';
  /** Day of the week */
  readonly day: Scalars['String']['output'];
  /** Time formatted as hh:mm (ex. 10:00). Will show as "CLOSED" if the store is closed for the day */
  readonly end: Scalars['String']['output'];
  /** Time formatted as hh:mm (ex. 10:00). Will show as "CLOSED" if the store is closed for the day */
  readonly start: Scalars['String']['output'];
};

/** Entity contributes to the Rewards entity in Monograph with fields provided by Challenges SVC */
export type Reward = {
  readonly __typename?: 'Reward';
  /** Date the reward expires */
  readonly expirationDate: Maybe<Scalars['String']['output']>;
  /** SessionM Offer ID - Not direct match to ID in Reward entity in Monograph but included in the serialized value */
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  /** If true, reward can be used and UI should allow it to be selected on the bag */
  readonly redeemable: Scalars['Boolean']['output'];
  /** If redeemable is false, this will populate with the next timestamp in UTC when the offer can be used. */
  readonly redeemableAt: Maybe<Scalars['DateTime']['output']>;
  readonly rewardType: RewardType;
};

/** Reward discount */
export type RewardDiscount = {
  readonly __typename?: 'RewardDiscount';
  readonly amount: Scalars['Float']['output'];
  readonly appliedTime: Scalars['String']['output'];
  readonly discountSource: Scalars['String']['output'];
  readonly discountedLineIdQuantities: Maybe<
    ReadonlyArray<Scalars['Float']['output']>
  >;
  readonly discountedLineIds: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly displayName: Scalars['String']['output'];
  readonly imageUrl: Maybe<Scalars['String']['output']>;
  readonly offerCustomData: Maybe<Scalars['String']['output']>;
  readonly posDiscountId: Maybe<Scalars['String']['output']>;
  readonly referenceId: Scalars['String']['output'];
  readonly referenceIdType: Scalars['String']['output'];
  readonly stackOrder: Scalars['Float']['output'];
  readonly status: Maybe<Scalars['String']['output']>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly userOfferCustomData: Maybe<Scalars['String']['output']>;
};

/** Indicates that adding, removing, or validating a reward failed and that an error should be shown to the customer. The end result is always that the cart's current reward is cleared; clients should update their internal state to reflect this. */
export type RewardNotApplied = {
  readonly __typename?: 'RewardNotApplied';
  /**
   * Provides more detail about the reason reward application failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value.
   * @deprecated Use failureReasons and failureMetadata instead
   */
  readonly failureCode: ApplyRewardFailureCode;
  /** Provides even more detail about the reason reward application failed. Clients use these for more refined messaging. */
  readonly failureMetadata: ApplyRewardFailureMetadata;
  /** Provides more detail about the reason reward application failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value. */
  readonly failureReasons: ReadonlyArray<Scalars['String']['output']>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RewardNotRemoved = {
  readonly __typename?: 'RewardNotRemoved';
  /** Provides more detail about the reason reward removal failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value. */
  readonly failureCode: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RewardNotValidated = UserError & {
  readonly __typename?: 'RewardNotValidated';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureCode: ValidateRewardFailureCode;
  readonly failureMetadata: Maybe<ValidateRewardFailureMetadata>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** PREMIUM = Reward is part of loyalty program, NON_PREMIUM = Reward is outcome of challenge/other outlet but not loyalty program */
export enum RewardType {
  NonPremium = 'NON_PREMIUM',
  Premium = 'PREMIUM',
}

/** Validate and preview rewards error response */
export type RewardValidateAndPreviewError = {
  readonly __typename?: 'RewardValidateAndPreviewError';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureMetadata: Maybe<RewardValidationFailureMetadata>;
  readonly failureReasons: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly offerId: Maybe<Scalars['ID']['output']>;
};

/** Validate and reserve rewards error response */
export type RewardValidateAndReserveError = {
  readonly __typename?: 'RewardValidateAndReserveError';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureMetadata: Maybe<RewardValidationFailureMetadata>;
  readonly failureReasons: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly offerId: Maybe<Scalars['ID']['output']>;
};

/** Reward Validation Failure Metadata */
export type RewardValidationFailureMetadata = {
  readonly __typename?: 'RewardValidationFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

/** Validation error response */
export type RewardValidationGeneralError = {
  readonly __typename?: 'RewardValidationGeneralError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Validate and preview rewards success response */
export type RewardsValidateAndPreviewSuccess = {
  readonly __typename?: 'RewardsValidateAndPreviewSuccess';
  readonly rewardDiscountPreviews: ReadonlyArray<RewardDiscount>;
};

export type RewardsValidateAndPreviewUnion =
  | RewardValidateAndPreviewError
  | RewardValidationGeneralError
  | RewardsValidateAndPreviewSuccess;

/** Validate and reserve rewards success response */
export type RewardsValidateAndReserveSuccess = {
  readonly __typename?: 'RewardsValidateAndReserveSuccess';
  readonly reservedRewardDiscounts: ReadonlyArray<RewardDiscount>;
};

export type RewardsValidateAndReserveUnion =
  | RewardValidateAndReserveError
  | RewardValidationGeneralError
  | RewardsValidateAndReserveSuccess;

export type RewardsValidationInput = {
  readonly rewardIds: ReadonlyArray<Scalars['String']['input']>;
  readonly transaction: OpenLoyaltyTransactionDetailsInput;
};

export type Session = {
  readonly __typename?: 'Session';
  readonly cart: Maybe<Order>;
  readonly csrf: Scalars['String']['output'];
  /** ID of the order currently in-flight, restricted to delivery orders. */
  readonly inFlightDeliveryOrderId: Maybe<Scalars['ID']['output']>;
  /** ID of the order currently in-flight. */
  readonly inFlightOrderId: Maybe<Scalars['ID']['output']>;
  readonly isLoggedIn: Scalars['Boolean']['output'];
};

export type SignInFailed = {
  readonly __typename?: 'SignInFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type SignInResponseUnion =
  | CustomerNotFound
  | SignInFailed
  | SignInSuccess
  | ValidationError;

export type SignInSuccess = {
  readonly __typename?: 'SignInSuccess';
  readonly customer: Customer;
};

/** Reduced version of Restaurant, with fields required for location search. */
export type StoreLocation = {
  readonly __typename?: 'StoreLocation';
  readonly acceptingOrders: Scalars['Boolean']['output'];
  readonly address: Scalars['String']['output'];
  readonly city: Scalars['String']['output'];
  readonly comingSoon: Maybe<Scalars['Boolean']['output']>;
  readonly crossStreet: Maybe<Scalars['String']['output']>;
  readonly enabled: Scalars['Boolean']['output'];
  readonly entityId: Maybe<Scalars['ID']['output']>;
  readonly flexMessage: Maybe<Scalars['String']['output']>;
  readonly hidden: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly isOutpost: Maybe<Scalars['Boolean']['output']>;
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly menuId: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly nativeDeliveryPriceDifferentiationEnabled: Maybe<
    Scalars['Boolean']['output']
  >;
  readonly notAcceptingOrdersReason: Maybe<Scalars['String']['output']>;
  readonly oloId: Scalars['ID']['output'];
  readonly outpostPriceDifferentiationEnabled: Maybe<
    Scalars['Boolean']['output']
  >;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly pickupInstructions: Maybe<Scalars['String']['output']>;
  readonly regionId: Maybe<Scalars['ID']['output']>;
  readonly showWarningDialog: Scalars['Boolean']['output'];
  readonly slug: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly storeHours: Maybe<Scalars['String']['output']>;
  readonly warningDialogDescription: Maybe<Scalars['String']['output']>;
  readonly warningDialogTimeout: Maybe<Scalars['Float']['output']>;
  readonly warningDialogTitle: Maybe<Scalars['String']['output']>;
  readonly zipCode: Scalars['String']['output'];
};

export type SubmitBraintreeBillingAccountInput = {
  /** Whether or not to make this credit card the default payment method. */
  readonly isDefault: Scalars['Boolean']['input'];
  /** Optional nickname for this credit card. */
  readonly nickname?: InputMaybe<Scalars['String']['input']>;
  /** Nonce generated by Braintree. */
  readonly nonce: Scalars['String']['input'];
};

export type SubmitBraintreeBillingAccountResponseUnion =
  | AddCardFunctionalityDeprecated
  | InvalidPostalCode
  | InvalidSubmittedBillingAccount
  | SubmitBraintreeBillingAccountSuccessResponse
  | TooManyRequests;

export type SubmitBraintreeBillingAccountSuccessResponse = {
  readonly __typename?: 'SubmitBraintreeBillingAccountSuccessResponse';
  readonly billingAccount: BillingAccount;
};

export type SubmitGiftCardCodeSuccess = {
  readonly __typename?: 'SubmitGiftCardCodeSuccess';
  /** Total credit after applying gift card */
  readonly totalCredit: Scalars['Float']['output'];
};

/** "Unable to purchase gift card" user error */
export type SubmitGiftCardOrderError = UserError & {
  readonly __typename?: 'SubmitGiftCardOrderError';
  readonly errorMessage: Scalars['String']['output'];
};

export type SubmitGiftCardOrderUsingPaymentMethodInput = {
  readonly assetId: Scalars['String']['input'];
  readonly balance: Scalars['Float']['input'];
  readonly deliveryDateTime?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryMethods: ReadonlyArray<Scalars['String']['input']>;
  readonly isAsap: Scalars['Boolean']['input'];
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethodId: Scalars['String']['input'];
  readonly quantity: Scalars['Float']['input'];
  readonly recipientEmail?: InputMaybe<Scalars['String']['input']>;
  readonly recipientName: Scalars['String']['input'];
  readonly senderName: Scalars['String']['input'];
  readonly utcOffset: Scalars['Float']['input'];
};

export type SubmitGiftCardOrderUsingPaymentMethodResponseUnion =
  | GiftCardOrderDetail
  | SubmitGiftCardOrderError;

export type SubmitOrderAsapInput = {
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: OrderType;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
};

export type SubmitOrderInput = {
  /** If the client has their own identifier for this order, they should provide it here for potential correlation on sweetgreen's end. For example, delivery marketplaces should provide their order id. */
  readonly externalReferenceId?: InputMaybe<Scalars['String']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: OrderType;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingBillingAccountInput = {
  /** Customer's saved LevelUp billing account */
  readonly billingAccountId: Scalars['ID']['input'];
  /** Shortcut for updating the customer's contact number */
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetail?: InputMaybe<DeliveryOrderDetailInput>;
  /** Selected from restaurant.availableDropoffLocations, if it exists */
  readonly dropoffLocationId?: InputMaybe<Scalars['ID']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** Whether or not to apply the customer's available credit towards the transaction */
  readonly useCredit: Scalars['Boolean']['input'];
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingBillingAccountResponseUnion =
  | DeliveryAlreadyInFlight
  | DeliverySpecifyAddress
  | InvalidBillingAccount
  | InvalidCustomerPhone
  | InvalidTip
  | MinimumDeliverySubtotalNotMet
  | ProductUnavailable
  | RestaurantIsNotAcceptingOrders
  | RewardNotValidated
  | SubmitOrderUsingBillingAccountSuccess
  | SubmitOrderUsingPaymentMethodCardSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderUsingBillingAccountSuccess = {
  readonly __typename?: 'SubmitOrderUsingBillingAccountSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderUsingPaymentMethodCardInput = {
  /** Shortcut for updating the customer's contact number */
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetail?: InputMaybe<DeliveryOrderDetailInput>;
  /** Selected from restaurant.availableDropoffLocations, if it exists */
  readonly dropoffLocationId?: InputMaybe<Scalars['ID']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** Customer's saved Payment Method card */
  readonly paymentMethodCardId: Scalars['ID']['input'];
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** Whether or not to apply the customer's available credit towards the transaction */
  readonly useCredit: Scalars['Boolean']['input'];
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingPaymentMethodCardSuccess = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodCardSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderUsingPaymentMethodInput = {
  /** Shortcut for updating the customer's contact number */
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetail?: InputMaybe<DeliveryOrderDetailInput>;
  /** Selected from restaurant.availableDropoffLocations, if it exists */
  readonly dropoffLocationId?: InputMaybe<Scalars['ID']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** Customer's saved Payment Method */
  readonly paymentMethodId: Scalars['ID']['input'];
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** Whether or not to apply the customer's available credit towards the transaction */
  readonly useCredit: Scalars['Boolean']['input'];
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingPaymentMethodResponseUnion =
  | DeclinedPaymentMethod
  | DeliveryAlreadyInFlight
  | DeliverySpecifyAddress
  | IncorrectPaymentMethod
  | InvalidCustomerPhone
  | InvalidTip
  | MinimumDeliverySubtotalNotMet
  | ProductUnavailable
  | RestaurantIsNotAcceptingOrders
  | RewardNotValidated
  | SubmitOrderUsingPaymentMethodSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderUsingPaymentMethodSuccess = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderWithPaymentMethodInput = {
  /** If the client has their own identifier for this order, they should provide it here for potential correlation on sweetgreen's end. For example, delivery marketplaces should provide their order id. */
  readonly externalReferenceId?: InputMaybe<Scalars['String']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: Scalars['String']['input'];
  /** the order payment method, ie: prepaid or cash */
  readonly paymentMethod: PaymentMethods;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderWithPaymentMethodResponseUnion =
  | MinimumDeliverySubtotalNotMet
  | SubmitOrderWithPaymentMethodSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderWithPaymentMethodSuccess = {
  readonly __typename?: 'SubmitOrderWithPaymentMethodSuccess';
  readonly order: Order;
};

export type SubmitPromoCodeInput = {
  /** LevelUp gift card code. */
  readonly code: Scalars['String']['input'];
};

export type SubmitPromoCodeOrGiftCardResponseUnion =
  | InvalidPromoCode
  | PromoCodeAlreadyApplied
  | SubmitGiftCardCodeSuccess
  | SubmitPromoCodeSuccessV2
  | ValidationError;

export type SubmitPromoCodeResponseUnion =
  | InvalidPromoCode
  | SubmitPromoCodeSuccess
  | ValidationError;

export type SubmitPromoCodeSuccess = {
  readonly __typename?: 'SubmitPromoCodeSuccess';
  /** LevelUp loyalty id associated with promo code. */
  readonly loyaltyId: Scalars['Float']['output'];
};

export type SubmitPromoCodeSuccessV2 = {
  readonly __typename?: 'SubmitPromoCodeSuccessV2';
  /** Placeholder value that always returns true. Use `__typename` to identify success */
  readonly placeholder: Scalars['Boolean']['output'];
};

export type SubmitPromoOrGiftCardCodeInput = {
  /** Promotion or gift card code */
  readonly code: Scalars['String']['input'];
};

/** Error when a user is trying to rejoin a subscription that is already canceled */
export type SubscriptionCannotBeReactivatedError = UserError & {
  readonly __typename?: 'SubscriptionCannotBeReactivatedError';
  readonly errorMessage: Scalars['String']['output'];
};

export type SubscriptionCreditCard = {
  readonly __typename?: 'SubscriptionCreditCard';
  readonly cardType: BraintreeCardType;
  readonly expirationMonth: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly expirationYear: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly lastFour: Scalars['String']['output'];
};

/** Customer Subscription Plan */
export type SubscriptionPlan = {
  readonly __typename?: 'SubscriptionPlan';
  /** The value that specifies the day of the month that the Braintree gateway will charge the subscription each billing cycle */
  readonly billingDayOfMonth: Maybe<Scalars['String']['output']>;
  /** Specifies the billing interval of the plan in months */
  readonly billingFrequency: Scalars['String']['output'];
  /**
   * The frequency in which subscription payments should be made.
   * @deprecated Deprecated in favor of `billingRecurrence`
   */
  readonly billingFrequencyUnit: Maybe<DurationUnit>;
  /** The recurrence in which subscription payments should be made. */
  readonly billingRecurrence: Maybe<DurationUnit>;
  /** The date/time the plan was created in UTC format */
  readonly createdAt: Scalars['String']['output'];
  /** The currency for the transaction */
  readonly currencyIsoCode: Scalars['String']['output'];
  /** The description of the plan */
  readonly description: Maybe<Scalars['String']['output']>;
  /** The plan identifier */
  readonly discounts: Maybe<ReadonlyArray<Discount>>;
  /** Whether this plan supports a free trial */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The plan identifier */
  readonly id: Scalars['String']['output'];
  /** The name of the plan */
  readonly name: Scalars['String']['output'];
  /** The base price for the plan */
  readonly price: Scalars['String']['output'];
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  PastDue = 'PAST_DUE',
  Pending = 'PENDING',
}

export type SubscriptionTransactions = {
  readonly __typename?: 'SubscriptionTransactions';
  readonly amount: Scalars['String']['output'];
  readonly creditCard: SubscriptionCreditCard;
  readonly date: Scalars['DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly refund: Scalars['Boolean']['output'];
  readonly transactionCreditCard: TransactionCreditCard;
};

/** Loyalty Tier */
export type Tier = {
  readonly __typename?: 'Tier';
  /** Loyalty Tier Display Name */
  readonly displayName: Scalars['String']['output'];
  /** Tier ID */
  readonly id: Scalars['ID']['output'];
  /** Loyalty Tier Name */
  readonly name: TierName;
  /** Next tier */
  readonly nextTier: Maybe<Tier>;
  /** Tier Group */
  readonly tierGroup: TierGroup;
};

/** Tier Group */
export type TierGroup = {
  readonly __typename?: 'TierGroup';
  /** Tier Group ID */
  readonly id: Scalars['ID']['output'];
  /** Tier Group Name */
  readonly name: Scalars['ID']['output'];
};

export enum TierName {
  OptedOut = 'OPTED_OUT',
  Sweetpass = 'SWEETPASS',
  SweetpassPlus = 'SWEETPASS_PLUS',
}

/** Loyalty Tier */
export type TierStatus = {
  readonly __typename?: 'TierStatus';
  /** Tier ID */
  readonly id: Scalars['ID']['output'];
  /** Tier Status Name */
  readonly name: TierStatusName;
};

export enum TierStatusName {
  Active = 'ACTIVE',
  CancellationPending = 'CANCELLATION_PENDING',
  FreeTrial = 'FREE_TRIAL',
  PastDue = 'PAST_DUE',
  Processing = 'PROCESSING',
}

export type TimeslotUnavailable = UserError & {
  readonly __typename?: 'TimeslotUnavailable';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type TooManyRequests = UserError & {
  readonly __typename?: 'TooManyRequests';
  readonly errorMessage: Scalars['String']['output'];
};

export type TransactionCreditCard = {
  readonly __typename?: 'TransactionCreditCard';
  readonly cardType: BraintreeCardType;
  /** Credit card ID, if the Credit Card was removed Transaction ID */
  readonly id: Scalars['ID']['output'];
  readonly lastFour: Scalars['String']['output'];
};

/** Trial Eligibility */
export type TrialEligibility = {
  readonly __typename?: 'TrialEligibility';
  /** The trial duration length */
  readonly duration: Scalars['Int']['output'];
  /** The trial duration unit */
  readonly durationUnit: DurationUnit;
};

/** "Unable to get gift card balance" user error */
export type UnableToGetGiftCardBalanceError = UserError & {
  readonly __typename?: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: Scalars['String']['output'];
};

/** "Unable to redeem gift card" user error */
export type UnableToRedeemGiftCardError = UserError & {
  readonly __typename?: 'UnableToRedeemGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Generic user error */
export type UnknownUserError = UserError & {
  readonly __typename?: 'UnknownUserError';
  readonly errorMessage: Scalars['String']['output'];
};

export type UpdateAddressResponseUnion =
  | InvalidAddress
  | UpdateAddressSuccess
  | ValidationError;

export type UpdateAddressSuccess = {
  readonly __typename?: 'UpdateAddressSuccess';
  readonly address: Address;
};

export type UpdateBillingAccountInput = {
  /** Billing account id. */
  readonly id: Scalars['Float']['input'];
  /** Whether or not to make this credit card the default payment method. */
  readonly isDefault: Scalars['Boolean']['input'];
  /** Optional nickname for this credit card. */
  readonly nickname?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBillingAccountResponse = {
  readonly __typename?: 'UpdateBillingAccountResponse';
  readonly billingAccount: BillingAccount;
};

export type UpdateCustomerInput = {
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerResponse = {
  readonly __typename?: 'UpdateCustomerResponse';
  readonly customer: Customer;
};

export type UpdateKioskCustomerInput = {
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateKioskCustomerResponse = {
  readonly __typename?: 'UpdateKioskCustomerResponse';
  readonly customer: Customer;
};

export type UpdatePaymentMethodInput = {
  /** Id of card to update */
  readonly id: Scalars['String']['input'];
  /** New default flag value of card to update */
  readonly isDefault: Scalars['Boolean']['input'];
  /** New nickname of card to update */
  readonly nickname: Scalars['String']['input'];
};

export type UpdateSubscriptionPaymentMethodSuccessResponse = {
  readonly __typename?: 'UpdateSubscriptionPaymentMethodSuccessResponse';
  /** Updated Subscription */
  readonly subscription: PaymentSubscription;
};

export type UpdateUseCreditResponse = {
  readonly __typename?: 'UpdateUseCreditResponse';
  readonly customer: Customer;
};

export type UpsellCategory = {
  readonly __typename?: 'UpsellCategory';
  readonly categoryRanking: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly products: ReadonlyArray<UpsellCategoryProduct>;
  readonly restaurantId: Scalars['String']['output'];
};

export type UpsellCategoryProduct = {
  readonly __typename?: 'UpsellCategoryProduct';
  readonly cost: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly menuId: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
};

export type UserError = {
  readonly errorMessage: Scalars['String']['output'];
};

/** The purchased gift card details */
export type UserGiftCard = {
  readonly __typename?: 'UserGiftCard';
  /** Gift card asset id. */
  readonly assetId: Maybe<Scalars['String']['output']>;
  /** Gift card balance. */
  readonly balance: Scalars['Float']['output'];
  /** Gift card id. */
  readonly id: Scalars['String']['output'];
  /** User provided message sent to the gift card recipient. */
  readonly message: Maybe<Scalars['String']['output']>;
  /** User provided email of the gift card recipient. */
  readonly recipientEmail: Maybe<Scalars['String']['output']>;
  /** User provided name of the gift card recipient. */
  readonly recipientName: Scalars['String']['output'];
};

export enum ValidateRewardFailureCode {
  CartNotFound = 'CART_NOT_FOUND',
  ChannelRestriction = 'CHANNEL_RESTRICTION',
  DayRestriction = 'DAY_RESTRICTION',
  LoyaltyLocationNotFound = 'LOYALTY_LOCATION_NOT_FOUND',
  LoyaltyNotProcessed = 'LOYALTY_NOT_PROCESSED',
  LoyaltyProgramUnavailable = 'LOYALTY_PROGRAM_UNAVAILABLE',
  MissingSku = 'MISSING_SKU',
  PriceMaximum = 'PRICE_MAXIMUM',
  PriceMinimum = 'PRICE_MINIMUM',
  RestaurantNotAcceptingOrders = 'RESTAURANT_NOT_ACCEPTING_ORDERS',
  RewardExpired = 'REWARD_EXPIRED',
  RewardNotApplicable = 'REWARD_NOT_APPLICABLE',
  RewardNotFound = 'REWARD_NOT_FOUND',
  TimeRestriction = 'TIME_RESTRICTION',
}

/** Reward Validation Metadata */
export type ValidateRewardFailureMetadata = {
  readonly __typename?: 'ValidateRewardFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

export type ValidationError = {
  readonly __typename?: 'ValidationError';
  readonly fieldErrors: ReadonlyArray<FieldValidationError>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Vendor = {
  readonly __typename?: 'Vendor';
  readonly name: Scalars['String']['output'];
  readonly restaurantId: Scalars['String']['output'];
};

export type VerifyRegistrationInput = {
  readonly code: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
};

export type VerifyRegistrationSuccess = {
  readonly __typename?: 'VerifyRegistrationSuccess';
  readonly csrf: Scalars['String']['output'];
  readonly customer: Customer;
};

export type VerifyRegistrationUnion =
  | InvalidRegistrationCode
  | ValidationError
  | VerifyRegistrationSuccess;

export enum Join__Graph {
  DodGraphql = 'DOD_GRAPHQL',
  FulfillmentSvc = 'FULFILLMENT_SVC',
  LoyaltySvc = 'LOYALTY_SVC',
  MonographSvc = 'MONOGRAPH_SVC',
  PaymentsSvc = 'PAYMENTS_SVC',
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}

export type CustomerNameQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerNameQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
  } | null;
};

export type AddAddressMutationVariables = Exact<{
  input: AddAddressInput;
}>;

export type AddAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly addAddress:
    | {
        readonly __typename: 'AddAddressSuccess';
        readonly address: {
          readonly __typename?: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type UpdateAddressMutationVariables = Exact<{
  input: EditAddressInput;
}>;

export type UpdateAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAddress:
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'UpdateAddressSuccess';
        readonly address: {
          readonly __typename?: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAddress:
    | { readonly __typename: 'DeleteAddressSuccess'; readonly success: boolean }
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type PaymentProfileQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentProfile: {
    readonly __typename?: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  } | null;
};

export type SetDefaultPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;

export type SetDefaultPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly setDefaultPaymentMethod: {
    readonly __typename: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  };
};

export type CartLedgerFragment = {
  readonly __typename?: 'Ledger';
  readonly tax: number;
  readonly subtotal: number;
  readonly feesTotal: number;
  readonly discountsTotal: number;
  readonly creditsTotal: number;
  readonly tip: number;
  readonly discounts: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
  readonly credits: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
  readonly fees: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
};

export type CartLineItemFragment = {
  readonly __typename?: 'LineItem';
  readonly id: string;
  readonly slug: string;
  readonly quantity: number;
  readonly cost: number;
  readonly customName: string | null;
  readonly perItemCost: number;
  readonly favorited: boolean;
  readonly isCustom: boolean;
  readonly dressingMode: DressingMode;
  readonly addedIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly kind: IngredientKind | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  }>;
  readonly removedIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly kind: IngredientKind | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  }>;
  readonly product: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly isCustom: boolean;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: IngredientKind | null;
    }>;
  };
  readonly mixedDressingDetails: ReadonlyArray<{
    readonly __typename?: 'MixedDressingDetails';
    readonly ingredientId: string;
    readonly weight: DressingWeight;
  }>;
};

export type CartRestaurantFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly city: string;
  readonly state: string;
  readonly address: string;
  readonly zipCode: string;
  readonly isOutpost: boolean;
  readonly deliveryMinSubtotal: number;
  readonly showDeliveryFeeDisclosure: boolean;
  readonly deliveryFee: number;
  readonly availableDropOffLocations: ReadonlyArray<{
    readonly __typename?: 'DropOffLocation';
    readonly id: string;
    readonly name: string;
  }>;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type AvailableWantedTimeFragment = {
  readonly __typename?: 'AvailableWantedTime';
  readonly time: string;
  readonly deliveryOffset: number;
};

export type DeliveryOrderDetailFragment = {
  readonly __typename?: 'DeliveryOrderDetail';
  readonly id: string;
  readonly tip: number;
  readonly deliveryFee: number;
  readonly vendor: string;
  readonly orderId: string;
  readonly vendorRestaurantId: string;
  readonly estimatedDeliveryTime: string | null;
  readonly address: {
    readonly __typename?: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly zipCode: string;
    readonly deliveryPreference: DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string | null;
    readonly notes: string | null;
  } | null;
};

export type CartDataFragment = {
  readonly __typename?: 'Order';
  readonly id: string;
  readonly orderType: OrderType;
  readonly canTrackOrderStatus: boolean;
  readonly ledger: {
    readonly __typename?: 'Ledger';
    readonly tax: number;
    readonly subtotal: number;
    readonly feesTotal: number;
    readonly discountsTotal: number;
    readonly creditsTotal: number;
    readonly tip: number;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
  };
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly slug: string;
    readonly quantity: number;
    readonly cost: number;
    readonly customName: string | null;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly isCustom: boolean;
    readonly dressingMode: DressingMode;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly isCustom: boolean;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly ingredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
      }>;
    };
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: DressingWeight;
    }>;
  }>;
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly city: string;
    readonly state: string;
    readonly address: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly deliveryMinSubtotal: number;
    readonly showDeliveryFeeDisclosure: boolean;
    readonly deliveryFee: number;
    readonly availableDropOffLocations: ReadonlyArray<{
      readonly __typename?: 'DropOffLocation';
      readonly id: string;
      readonly name: string;
    }>;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  };
  readonly availableWantedTimes: ReadonlyArray<{
    readonly __typename?: 'AvailableWantedTime';
    readonly time: string;
    readonly deliveryOffset: number;
  }>;
  readonly deliveryOrderDetail: {
    readonly __typename?: 'DeliveryOrderDetail';
    readonly id: string;
    readonly tip: number;
    readonly deliveryFee: number;
    readonly vendor: string;
    readonly orderId: string;
    readonly vendorRestaurantId: string;
    readonly estimatedDeliveryTime: string | null;
    readonly address: {
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly deliveryPreference: DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly name: string | null;
      readonly notes: string | null;
    } | null;
  } | null;
};

export type CartQueryVariables = Exact<{ [key: string]: never }>;

export type CartQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  } | null;
};

export type AddLineItemToCartSuccessFieldsFragment = {
  readonly __typename?: 'AddLineItemToCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type EditLineItemInCartSuccessFieldsFragment = {
  readonly __typename?: 'EditLineItemInCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type RemoveFromCartSuccessFieldsFragment = {
  readonly __typename?: 'RemoveFromCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type RestaurantMaxQuantityExceededFieldsFragment = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly message: string;
  readonly status: number;
};

export type MaxModificationsExceededFieldsFragment = {
  readonly __typename?: 'MaxModificationsExceeded';
  readonly message: string;
  readonly status: number;
};

export type RestaurantMaxDeliveryQuantityExceededFieldsFragment = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly message: string;
  readonly status: number;
};

export type ProductOutOfStockFieldsFragment = {
  readonly __typename?: 'ProductOutOfStock';
  readonly message: string;
  readonly status: number;
};

export type AddLineItemToCartMutationVariables = Exact<{
  input: AddLineItemToCartInput;
}>;

export type AddLineItemToCartMutation = {
  readonly __typename?: 'Mutation';
  readonly addLineItemToCart:
    | {
        readonly __typename: 'AddLineItemToCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | { readonly __typename: 'LineItemModificationsInvalidException' }
    | {
        readonly __typename: 'MaxModificationsExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ProductOutOfStock';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type EditLineItemInCartMutationVariables = Exact<{
  input: EditLineItemInCartInput;
}>;

export type EditLineItemInCartMutation = {
  readonly __typename?: 'Mutation';
  readonly editLineItemInCart:
    | {
        readonly __typename: 'EditLineItemInCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'MaxModificationsExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type RemoveFromCartMutationVariables = Exact<{
  input: RemoveFromCartInput;
}>;

export type RemoveFromCartMutation = {
  readonly __typename?: 'Mutation';
  readonly removeFromCart:
    | {
        readonly __typename: 'RemoveFromCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type AddonsQueryVariables = Exact<{
  costChannel?: InputMaybe<CostChannel>;
}>;

export type AddonsQuery = {
  readonly __typename?: 'Query';
  readonly addons: {
    readonly __typename?: 'Addons';
    readonly products: ReadonlyArray<{
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly cost: number;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
  };
};

export type ChallengeCelebrationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ChallengeCelebrationQuery = {
  readonly __typename?: 'Query';
  readonly challengeCelebration: {
    readonly __typename?: 'ChallengeCelebration';
    readonly title: string | null;
    readonly celebrationCopy: string;
    readonly rewardUsageDisclaimer: string;
    readonly expirationDate: string | null;
    readonly challengesIds: ReadonlyArray<string>;
  } | null;
};

export type ChallengeCelebrationModalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ChallengeCelebrationModalQuery = {
  readonly __typename?: 'Query';
  readonly challengeCelebrationV2: {
    readonly __typename?: 'ChallengeCelebrationV2';
    readonly title: string;
    readonly celebrationCopy: string;
    readonly expirationDate: string | null;
    readonly challengesIds: ReadonlyArray<string>;
  } | null;
};

export type UnableToGetGiftCardBalanceErrorFieldsFragment = {
  readonly __typename?: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: string;
};

export type GiftCardBalanceFieldsFragment = {
  readonly __typename?: 'GiftCardBalance';
  readonly customerId: string;
  readonly giftCardBalance: number;
};

export type GiftCardBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type GiftCardBalanceQuery = {
  readonly __typename?: 'Query';
  readonly giftCardBalance:
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'UnableToGetGiftCardBalanceError';
        readonly errorMessage: string;
      };
};

export type PickupOutpostRestaurantNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PickupOutpostRestaurantNameQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly name: string;
  } | null;
};

export type ActiveCartDeliveryDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActiveCartDeliveryDetailsQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly googlePlaceId: string;
        readonly name: string | null;
      } | null;
    } | null;
  } | null;
};

export type AddDeliveryAddressMutationVariables = Exact<{
  input: AddAddressInput;
}>;

export type AddDeliveryAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly addAddress:
    | {
        readonly __typename: 'AddAddressSuccess';
        readonly address: {
          readonly __typename?: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type CustomerAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAddressesQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly deliveryPreference: DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly name: string | null;
      readonly notes: string | null;
    }>;
  } | null;
};

export type RestaurantDetailsFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly deliveryFee: number;
};

export type DeliveryLocationByAddressQueryVariables = Exact<{
  input: LocationByAddressInput;
}>;

export type DeliveryLocationByAddressQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: LocationStatus;
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly phone: string;
          readonly flexMessage: string | null;
          readonly deliveryFee: number;
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type DeliveryLocationByAddressWithDisclosureFieldsQueryVariables =
  Exact<{
    input: LocationByAddressInput;
  }>;

export type DeliveryLocationByAddressWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: LocationStatus;
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly showDeliveryPriceDifferenciationDisclosure: boolean;
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly phone: string;
          readonly flexMessage: string | null;
          readonly deliveryFee: number;
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type LastPlacedOrderDetailsQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type LastPlacedOrderDetailsQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
          } | null;
        }>;
      }
    | { readonly __typename?: 'ValidationError' };
};

export type LocationDetailsFragment = {
  readonly __typename?: 'StoreLocation';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean | null;
  readonly phone: string | null;
  readonly storeHours: string | null;
  readonly flexMessage: string | null;
  readonly enabled: boolean;
  readonly acceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string | null;
  readonly imageUrl: string;
  readonly hidden: boolean;
  readonly showWarningDialog: boolean;
  readonly warningDialogDescription: string | null;
  readonly warningDialogTimeout: number | null;
  readonly warningDialogTitle: string | null;
};

export type LocationsSearchByAreaQueryVariables = Exact<{
  topLeft: GeoCoordinates;
  bottomRight: GeoCoordinates;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationsSearchByAreaQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByBoundingBox: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type LocationsSearchByAreaWithDisclosureFieldsQueryVariables = Exact<{
  topLeft: GeoCoordinates;
  bottomRight: GeoCoordinates;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationsSearchByAreaWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByBoundingBox: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly outpostPriceDifferentiationEnabled: boolean | null;
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type LocationsSearchBySearchStringQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationsSearchBySearchStringQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByString: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly score: number;
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables =
  Exact<{
    searchString: Scalars['String']['input'];
    showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  }>;

export type LocationsSearchBySearchStringWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByString: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly score: number;
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly outpostPriceDifferentiationEnabled: boolean | null;
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type RecommendedItemFragment = {
  readonly __typename?: 'LineItemRecommendation';
  readonly id: string;
  readonly name: string;
  readonly recommendationType: RecommendationType;
  readonly ingredientIds: ReadonlyArray<string>;
  readonly description: string;
};

export type LineItemRecommendationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LineItemRecommendationsQuery = {
  readonly __typename?: 'Query';
  readonly lineItemRecommendations: ReadonlyArray<{
    readonly __typename?: 'LineItemRecommendation';
    readonly id: string;
    readonly name: string;
    readonly recommendationType: RecommendationType;
    readonly ingredientIds: ReadonlyArray<string>;
    readonly description: string;
  }>;
};

export type CancelOrderSuccessFieldsFragment = {
  readonly __typename?: 'CancelOrderSuccess';
  readonly success: boolean;
  readonly reason: string;
};

export type CancelSuccessRefundFailedFieldsFragment = {
  readonly __typename?: 'CancelSuccessRefundFailed';
  readonly errorMessage: string;
};

export type CancelOrderFailedFieldsFragment = {
  readonly __typename?: 'CancelOrderFailed';
  readonly errorMessage: string;
};

export type CancellationLimitExceededFieldsFragment = {
  readonly __typename?: 'CancellationLimitExceeded';
  readonly errorMessage: string;
};

export type CancellationFailedWithDeliveryFieldsFragment = {
  readonly __typename?: 'CancellationFailedWithDelivery';
  readonly errorMessage: string;
};

export type OrderAlreadyCanceledFieldsFragment = {
  readonly __typename?: 'OrderAlreadyCanceled';
  readonly errorMessage: string;
};

export type OrderNotFoundFieldsFragment = {
  readonly __typename?: 'OrderNotFound';
  readonly errorMessage: string;
};

export type OrderAlreadySentToKitchenFieldsFragment = {
  readonly __typename?: 'OrderAlreadySentToKitchen';
  readonly errorMessage: string;
};

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;

export type CancelOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelOrder:
    | {
        readonly __typename: 'CancelOrderFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancelOrderSuccess';
        readonly success: boolean;
        readonly reason: string;
      }
    | {
        readonly __typename: 'CancelSuccessRefundFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationFailedWithDelivery';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationLimitExceeded';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadyCanceled';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadySentToKitchen';
        readonly errorMessage: string;
      }
    | { readonly __typename: 'OrderNotFound'; readonly errorMessage: string };
};

export type InvalidGiftCardErrorFieldsFragment = {
  readonly __typename?: 'InvalidGiftCardError';
  readonly errorMessage: string;
};

export type GiftCardAssociatedWithAnotherAccountErrorFieldsFragment = {
  readonly __typename?: 'GiftCardAssociatedWithAnotherAccountError';
  readonly errorMessage: string;
};

export type UnableToRedeemGiftCardErrorFieldsFragment = {
  readonly __typename?: 'UnableToRedeemGiftCardError';
  readonly errorMessage: string;
};

export type GiftCardBalanceResponseFieldsFragment = {
  readonly __typename?: 'GiftCardBalance';
  readonly customerId: string;
  readonly giftCardBalance: number;
};

export type NoBalanceGiftCardErrorFieldsFragment = {
  readonly __typename?: 'NoBalanceGiftCardError';
  readonly errorMessage: string;
};

export type RedeemGiftCardMutationVariables = Exact<{
  code: Scalars['String']['input'];
  regCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type RedeemGiftCardMutation = {
  readonly __typename?: 'Mutation';
  readonly redeemGiftCard:
    | {
        readonly __typename: 'GiftCardAssociatedWithAnotherAccountError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'InvalidGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'NoBalanceGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnableToRedeemGiftCardError';
        readonly errorMessage: string;
      };
};

export type SweetpassInfoForAccountDeletionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SweetpassInfoForAccountDeletionQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: TierName;
    } | null;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: TierStatusName;
    } | null;
  } | null;
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly status: PaymentSubscriptionStatus;
    readonly nextBillingDate: string;
  }>;
};

export type RequestAccountDeletionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestAccountDeletionMutation = {
  readonly __typename?: 'Mutation';
  readonly requestAccountDeletion:
    | { readonly __typename?: 'RequestAccountDeletionFailed' }
    | {
        readonly __typename: 'RequestAccountDeletionSuccess';
        readonly success: boolean;
      }
    | null;
};

export type SweetpassBillingHistoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SweetpassBillingHistoryQuery = {
  readonly __typename?: 'Query';
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly transactionHistory: ReadonlyArray<{
      readonly __typename?: 'SubscriptionTransactions';
      readonly id: string;
      readonly date: string;
      readonly amount: string;
      readonly refund: boolean;
      readonly transactionCreditCard: {
        readonly __typename?: 'TransactionCreditCard';
        readonly id: string;
        readonly cardType: BraintreeCardType;
        readonly lastFour: string;
      };
    }>;
  } | null;
};

export type CustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly trackingUuid: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
    readonly email: string | null;
    readonly useCredit: boolean;
    readonly availableCredit: number;
    readonly deletionInProgress: boolean | null;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly name: string | null;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly country: string;
      readonly deliveryPreference: DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly notes: string | null;
    }>;
    readonly billingAccounts: ReadonlyArray<{
      readonly __typename: 'BillingAccount';
      readonly id: string;
      readonly description: string;
      readonly nickname: string | null;
      readonly isDefault: boolean;
      readonly cardType: CardType;
      readonly expirationState: ExpirationState;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly description: string | null;
      readonly amount: number;
      readonly remainingAmount: number;
      readonly createdAt: string;
      readonly expiresAt: string | null;
    }>;
  } | null;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;

export type CreateAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly createAccount:
    | {
        readonly __typename: 'CreateAccountSuccess';
        readonly customer: {
          readonly __typename?: 'Customer';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SignInCustomerMutationVariables = Exact<{ [key: string]: never }>;

export type SignInCustomerMutation = {
  readonly __typename?: 'Mutation';
  readonly signIn:
    | { readonly __typename: 'CustomerNotFound'; readonly errorMessage: string }
    | {
        readonly __typename: 'SignInFailed';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'SignInSuccess';
        readonly customer: {
          readonly __typename?: 'Customer';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ChallengeDataFragment = {
  readonly __typename: 'Challenge';
  readonly achieved: number;
  readonly celebrationCopy: string;
  readonly descriptiveTitle: string;
  readonly expirationDate: string;
  readonly goal: number;
  readonly id: string;
  readonly legalTerms: string;
  readonly percentageCompleted: number;
  readonly permalink: string;
  readonly progress: number;
  readonly progressCopy: string;
  readonly status: ChallengeStatus;
  readonly title: string;
};

export type ChallengesQueryVariables = Exact<{ [key: string]: never }>;

export type ChallengesQuery = {
  readonly __typename?: 'Query';
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: ChallengeStatus;
    readonly title: string;
  }>;
};

export type ChallengesWithThemeQueryVariables = Exact<{ [key: string]: never }>;

export type ChallengesWithThemeQuery = {
  readonly __typename?: 'Query';
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly theme: ChallengeTheme;
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: ChallengeStatus;
    readonly title: string;
  }>;
};

export type ChallengeOptInMutationVariables = Exact<{
  challengeId: Scalars['String']['input'];
}>;

export type ChallengeOptInMutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptIn: {
    readonly __typename: 'Challenge';
    readonly theme: ChallengeTheme;
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: ChallengeStatus;
    readonly title: string;
  };
};

export type ChallengeOptInV2MutationVariables = Exact<{
  challengeId: Scalars['String']['input'];
}>;

export type ChallengeOptInV2Mutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptInV2:
    | {
        readonly __typename: 'Challenge';
        readonly theme: ChallengeTheme;
        readonly achieved: number;
        readonly celebrationCopy: string;
        readonly descriptiveTitle: string;
        readonly expirationDate: string;
        readonly goal: number;
        readonly id: string;
        readonly legalTerms: string;
        readonly percentageCompleted: number;
        readonly permalink: string;
        readonly progress: number;
        readonly progressCopy: string;
        readonly status: ChallengeStatus;
        readonly title: string;
      }
    | {
        readonly __typename: 'LoyaltyOptInFailed';
        readonly errorMessage: string;
      };
};

export type HasUnseenChallengesQueryVariables = Exact<{ [key: string]: never }>;

export type HasUnseenChallengesQuery = {
  readonly __typename?: 'Query';
  readonly hasUnseenChallenges: {
    readonly __typename?: 'HasUnseenChallenges';
    readonly hasUnseenChallenges: boolean;
  };
};

export type RewardsQueryVariables = Exact<{ [key: string]: never }>;

export type RewardsQuery = {
  readonly __typename?: 'Query';
  readonly rewards: ReadonlyArray<{
    readonly __typename?: 'Reward';
    readonly id: string;
    readonly name: string;
    readonly expirationDate: string | null;
    readonly rewardType: RewardType;
    readonly redeemable: boolean;
    readonly redeemableAt: string | null;
  }>;
};

export type RewardNotAppliedFieldsFragment = {
  readonly __typename?: 'RewardNotApplied';
  readonly message: string;
  readonly status: number;
  readonly failureCode: ApplyRewardFailureCode;
  readonly failureReasons: ReadonlyArray<string>;
  readonly failureMetadata: {
    readonly __typename?: 'ApplyRewardFailureMetadata';
    readonly requiredChannel: string | null;
    readonly requiredDays: ReadonlyArray<number> | null;
  };
};

export type RewardNotRemovedFieldsFragment = {
  readonly __typename?: 'RewardNotRemoved';
  readonly message: string;
  readonly status: number;
};

export type ApplyRewardMutationVariables = Exact<{
  input: ApplyRewardInput;
}>;

export type ApplyRewardMutation = {
  readonly __typename?: 'Mutation';
  readonly applyReward:
    | {
        readonly __typename: 'ApplyRewardSuccess';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RewardNotApplied';
        readonly message: string;
        readonly status: number;
        readonly failureCode: ApplyRewardFailureCode;
        readonly failureReasons: ReadonlyArray<string>;
        readonly failureMetadata: {
          readonly __typename?: 'ApplyRewardFailureMetadata';
          readonly requiredChannel: string | null;
          readonly requiredDays: ReadonlyArray<number> | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type RemoveRewardMutationVariables = Exact<{
  input: RemoveRewardInput;
}>;

export type RemoveRewardMutation = {
  readonly __typename?: 'Mutation';
  readonly removeReward:
    | {
        readonly __typename: 'RemoveRewardSuccess';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RewardNotRemoved';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type CurrentCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCustomerQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly trackingUuid: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
    readonly email: string | null;
    readonly useCredit: boolean;
    readonly availableCredit: number;
    readonly deletionInProgress: boolean | null;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly name: string | null;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly country: string;
      readonly deliveryPreference: DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly notes: string | null;
    }>;
    readonly billingAccounts: ReadonlyArray<{
      readonly __typename: 'BillingAccount';
      readonly id: string;
      readonly description: string;
      readonly nickname: string | null;
      readonly isDefault: boolean;
      readonly cardType: CardType;
      readonly expirationState: ExpirationState;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly description: string | null;
      readonly amount: number;
      readonly remainingAmount: number;
      readonly createdAt: string;
      readonly expiresAt: string | null;
    }>;
  } | null;
};

export type UpdateUseCreditMutationVariables = Exact<{
  useCredit: Scalars['Boolean']['input'];
}>;

export type UpdateUseCreditMutation = {
  readonly __typename?: 'Mutation';
  readonly updateUseCredit: {
    readonly __typename: 'UpdateUseCreditResponse';
    readonly customer: {
      readonly __typename?: 'Customer';
      readonly id: string;
      readonly useCredit: boolean;
    };
  };
};

export type SuccessfulForgotPasswordFieldsFragment = {
  readonly __typename?: 'ForgotPasswordSuccess';
  readonly success: boolean;
};

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  readonly __typename?: 'Mutation';
  readonly forgotPassword:
    | {
        readonly __typename: 'ForgotPasswordSuccess';
        readonly success: boolean;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SuccessfulLoginFieldsFragment = {
  readonly __typename?: 'LoginSuccess';
  readonly csrf: string;
};

export type InvalidCredentialsFieldsFragment = {
  readonly __typename?: 'InvalidCredentials';
  readonly message: string;
  readonly status: number;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  readonly __typename?: 'Mutation';
  readonly login:
    | { readonly __typename: 'AccountLocked' }
    | {
        readonly __typename: 'InvalidCredentials';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'LoginSuccess'; readonly csrf: string }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SuccessfulLogoutFieldsFragment = {
  readonly __typename?: 'LogoutSuccess';
  readonly csrf: string;
};

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;

export type LogoutMutation = {
  readonly __typename?: 'Mutation';
  readonly logout:
    | { readonly __typename: 'LogoutSuccess'; readonly csrf: string }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SuccessfulRegisterFieldsFragment = {
  readonly __typename?: 'RegisterSuccess';
  readonly success: boolean;
};

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterMutation = {
  readonly __typename?: 'Mutation';
  readonly register:
    | { readonly __typename: 'RegisterSuccess'; readonly success: boolean }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ResendVerificationCodeSuccessFieldsFragment = {
  readonly __typename?: 'ResendVerificationCodeSuccess';
  readonly success: boolean;
};

export type ResendVerificationCodeMutationVariables = Exact<{
  input: ResendVerificationCodeInput;
}>;

export type ResendVerificationCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly resendVerificationCode:
    | {
        readonly __typename: 'ResendVerificationCodeSuccess';
        readonly success: boolean;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type VerifyRegistrationSuccessFieldsFragment = {
  readonly __typename?: 'VerifyRegistrationSuccess';
  readonly csrf: string;
  readonly customer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly trackingUuid: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
    readonly email: string | null;
    readonly useCredit: boolean;
    readonly availableCredit: number;
    readonly deletionInProgress: boolean | null;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly name: string | null;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly country: string;
      readonly deliveryPreference: DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly notes: string | null;
    }>;
    readonly billingAccounts: ReadonlyArray<{
      readonly __typename: 'BillingAccount';
      readonly id: string;
      readonly description: string;
      readonly nickname: string | null;
      readonly isDefault: boolean;
      readonly cardType: CardType;
      readonly expirationState: ExpirationState;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly description: string | null;
      readonly amount: number;
      readonly remainingAmount: number;
      readonly createdAt: string;
      readonly expiresAt: string | null;
    }>;
  };
};

export type InvalidRegistrationCodeFieldsFragment = {
  readonly __typename?: 'InvalidRegistrationCode';
  readonly message: string;
  readonly status: number;
};

export type VerifyRegistrationMutationVariables = Exact<{
  input: VerifyRegistrationInput;
}>;

export type VerifyRegistrationMutation = {
  readonly __typename?: 'Mutation';
  readonly verifyRegistration:
    | {
        readonly __typename: 'InvalidRegistrationCode';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      }
    | {
        readonly __typename: 'VerifyRegistrationSuccess';
        readonly csrf: string;
        readonly customer: {
          readonly __typename?: 'Customer';
          readonly id: string;
          readonly trackingUuid: string | null;
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly phoneNumber: string | null;
          readonly email: string | null;
          readonly useCredit: boolean;
          readonly availableCredit: number;
          readonly deletionInProgress: boolean | null;
          readonly addresses: ReadonlyArray<{
            readonly __typename?: 'Address';
            readonly id: string;
            readonly street: string;
            readonly secondaryStreet: string | null;
            readonly name: string | null;
            readonly city: string;
            readonly state: string;
            readonly zipCode: string;
            readonly country: string;
            readonly deliveryPreference: DeliveryPreferenceType;
            readonly googlePlaceId: string;
            readonly latitude: number;
            readonly longitude: number;
            readonly notes: string | null;
          }>;
          readonly billingAccounts: ReadonlyArray<{
            readonly __typename: 'BillingAccount';
            readonly id: string;
            readonly description: string;
            readonly nickname: string | null;
            readonly isDefault: boolean;
            readonly cardType: CardType;
            readonly expirationState: ExpirationState;
          }>;
          readonly credits: ReadonlyArray<{
            readonly __typename?: 'Credit';
            readonly id: string;
            readonly title: string;
            readonly description: string | null;
            readonly amount: number;
            readonly remainingAmount: number;
            readonly createdAt: string;
            readonly expiresAt: string | null;
          }>;
        };
      };
};

export type OrdersQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type OrdersQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly description: string;
          readonly orderType: OrderType;
          readonly total: number | null;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly quantity: number;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly slug: string;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type BillingAccountFragment = {
  readonly __typename: 'BillingAccount';
  readonly id: string;
  readonly description: string;
  readonly nickname: string | null;
  readonly isDefault: boolean;
  readonly cardType: CardType;
  readonly expirationState: ExpirationState;
};

export type AddressFragment = {
  readonly __typename?: 'Address';
  readonly id: string;
  readonly street: string;
  readonly secondaryStreet: string | null;
  readonly name: string | null;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly country: string;
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly notes: string | null;
};

export type CustomerCreditsFragment = {
  readonly __typename?: 'Credit';
  readonly id: string;
  readonly title: string;
  readonly description: string | null;
  readonly amount: number;
  readonly remainingAmount: number;
  readonly createdAt: string;
  readonly expiresAt: string | null;
};

export type CustomerDataFragment = {
  readonly __typename?: 'Customer';
  readonly id: string;
  readonly trackingUuid: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly email: string | null;
  readonly useCredit: boolean;
  readonly availableCredit: number;
  readonly deletionInProgress: boolean | null;
  readonly addresses: ReadonlyArray<{
    readonly __typename?: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly name: string | null;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly country: string;
    readonly deliveryPreference: DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly notes: string | null;
  }>;
  readonly billingAccounts: ReadonlyArray<{
    readonly __typename: 'BillingAccount';
    readonly id: string;
    readonly description: string;
    readonly nickname: string | null;
    readonly isDefault: boolean;
    readonly cardType: CardType;
    readonly expirationState: ExpirationState;
  }>;
  readonly credits: ReadonlyArray<{
    readonly __typename?: 'Credit';
    readonly id: string;
    readonly title: string;
    readonly description: string | null;
    readonly amount: number;
    readonly remainingAmount: number;
    readonly createdAt: string;
    readonly expiresAt: string | null;
  }>;
};

export type ValidationErrorFieldsFragment = {
  readonly __typename?: 'ValidationError';
  readonly message: string;
  readonly status: number;
  readonly fieldErrors: ReadonlyArray<{
    readonly __typename?: 'FieldValidationError';
    readonly field: string;
    readonly message: string;
  }>;
};

export type CustomerCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerCreditsQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly availableCredit: number;
    readonly useCredit: boolean;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly remainingAmount: number;
      readonly expiresAt: string | null;
      readonly storefrontEligibilityTitle: string;
      readonly creditType: CreditType;
      readonly createdAt: string;
    }>;
  } | null;
};

export type DeletePaymentMethodCardMutationVariables = Exact<{
  input: DeletePaymentMethodCardInput;
}>;

export type DeletePaymentMethodCardMutation = {
  readonly __typename?: 'Mutation';
  readonly deletePaymentMethodCard: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
  };
};

export type DeliveryInFlightQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type DeliveryInFlightQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly isInFlightDelivery: boolean;
        }>;
      }
    | { readonly __typename: 'ValidationError' };
};

export type InStoreOrderLastPendingFeedbackQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InStoreOrderLastPendingFeedbackQuery = {
  readonly __typename?: 'Query';
  readonly inStoreOrderLastPendingFeedback: {
    readonly __typename?: 'InStoreOrder';
    readonly id: string;
    readonly wantedTime: string;
  } | null;
};

export type PaymentMethodCardsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodCardsQuery = {
  readonly __typename?: 'Query';
  readonly paymentMethodCards: ReadonlyArray<
    | {
        readonly __typename: 'PaymentMethodBillingAccount';
        readonly id: string;
        readonly description: string | null;
        readonly nickname: string | null;
        readonly isDefault: boolean;
        readonly cardType: CardType;
        readonly expirationState: CardExpirationState;
      }
    | {
        readonly __typename: 'PaymentMethodCard';
        readonly id: string;
        readonly description: string | null;
        readonly nickname: string | null;
        readonly isDefault: boolean;
        readonly cardType: CardType;
        readonly expirationState: CardExpirationState;
      }
  >;
};

export type InvalidPromoCodeFieldsFragment = {
  readonly __typename?: 'InvalidPromoCode';
  readonly message: string;
  readonly status: number;
};

export type SubmitPromoOrGiftCardCodeMutationVariables = Exact<{
  input: SubmitPromoOrGiftCardCodeInput;
}>;

export type SubmitPromoOrGiftCardCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly submitPromoOrGiftCardCode:
    | {
        readonly __typename: 'InvalidPromoCode';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'PromoCodeAlreadyApplied' }
    | { readonly __typename: 'SubmitGiftCardCodeSuccess' }
    | { readonly __typename: 'SubmitPromoCodeSuccessV2' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SuccessfulLogoutCustomerFieldsFragment = {
  readonly __typename?: 'LogoutSuccess';
  readonly csrf: string;
};

export type LogoutCustomerMutationVariables = Exact<{
  input: LogoutInput;
}>;

export type LogoutCustomerMutation = {
  readonly __typename?: 'Mutation';
  readonly logout:
    | { readonly __typename: 'LogoutSuccess'; readonly csrf: string }
    | { readonly __typename: 'ValidationError' };
};

export type GetSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetSessionQuery = {
  readonly __typename?: 'Query';
  readonly session: {
    readonly __typename?: 'Session';
    readonly csrf: string;
    readonly isLoggedIn: boolean;
  };
};

export type SubmitPaymentMethodMutationVariables = Exact<{
  input: UpdatePaymentMethodInput;
}>;

export type SubmitPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePaymentMethod: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
    readonly description: string | null;
    readonly nickname: string | null;
    readonly isDefault: boolean;
    readonly cardType: CardType;
    readonly expirationState: CardExpirationState;
  };
};

export type SubmitBraintreeBillingAccountMutationVariables = Exact<{
  input: SubmitBraintreeBillingAccountInput;
}>;

export type SubmitBraintreeBillingAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly submitBraintreeBillingAccount:
    | {
        readonly __typename: 'AddCardFunctionalityDeprecated';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'InvalidPostalCode';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'InvalidSubmittedBillingAccount';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'SubmitBraintreeBillingAccountSuccessResponse';
        readonly billingAccount: {
          readonly __typename?: 'BillingAccount';
          readonly id: string;
          readonly description: string;
          readonly nickname: string | null;
          readonly isDefault: boolean;
          readonly cardType: CardType;
          readonly expirationState: ExpirationState;
        };
      }
    | { readonly __typename: 'TooManyRequests'; readonly errorMessage: string };
};

export type GetPaymentSubscriptionProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPaymentSubscriptionProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly id: string;
      readonly cardType: BraintreeCardType;
      readonly lastFour: string;
    }> | null;
  } | null;
};

export type SweetpassNextBillingDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SweetpassNextBillingDateQuery = {
  readonly __typename?: 'Query';
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly nextBillingDate: string;
  }>;
};

export type SweetpassPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SweetpassPaymentMethodsQuery = {
  readonly __typename?: 'Query';
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly status: PaymentSubscriptionStatus;
  }>;
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly id: string;
      readonly cardType: BraintreeCardType;
      readonly lastFour: string;
      readonly expirationYear: string | null;
      readonly expirationMonth: string | null;
      readonly isActive: boolean;
    }> | null;
  } | null;
};

export type AddSweetpassPaymentMethodMutationVariables = Exact<{
  nonce: Scalars['String']['input'];
}>;

export type AddSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly addSubscriptionPaymentMethod:
    | {
        readonly __typename: 'AddSubscriptionPaymentMethodSuccessResponse';
        readonly paymentMethodToken: string;
        readonly creditCard: {
          readonly __typename?: 'SubscriptionCreditCard';
          readonly id: string;
          readonly cardType: BraintreeCardType;
          readonly lastFour: string;
          readonly expirationMonth: string | null;
          readonly expirationYear: string | null;
          readonly isActive: boolean;
        };
      }
    | {
        readonly __typename: 'InvalidPaymentMethod';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'PaymentMethodAlreadyExistsError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnknownUserError';
        readonly errorMessage: string;
      };
};

export type UpdateSweetpassPaymentMethodMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;

export type UpdateSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSubscriptionPaymentMethod: {
    readonly __typename: 'UpdateSubscriptionPaymentMethodSuccessResponse';
    readonly subscription: {
      readonly __typename?: 'PaymentSubscription';
      readonly id: string;
    };
  };
};

export type RemoveSweetpassPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;

export type RemoveSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly removeSubscriptionPaymentMethod:
    | {
        readonly __typename: 'ActivePaymentMethodError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'DeleteSubscriptionPaymentMethodSuccess';
        readonly creditCards: ReadonlyArray<{
          readonly __typename: 'SubscriptionCreditCard';
          readonly id: string;
          readonly cardType: BraintreeCardType;
          readonly lastFour: string;
        }>;
      }
    | {
        readonly __typename: 'UnknownUserError';
        readonly errorMessage: string;
      };
};

export type AddSubscriptionPaymentMethodSuccessResponseFieldsFragment = {
  readonly __typename: 'AddSubscriptionPaymentMethodSuccessResponse';
  readonly paymentMethodToken: string;
  readonly creditCard: {
    readonly __typename?: 'SubscriptionCreditCard';
    readonly id: string;
    readonly cardType: BraintreeCardType;
    readonly lastFour: string;
    readonly expirationMonth: string | null;
    readonly expirationYear: string | null;
    readonly isActive: boolean;
  };
};

export type UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragment = {
  readonly __typename: 'UpdateSubscriptionPaymentMethodSuccessResponse';
  readonly subscription: {
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
  };
};

export type DeleteSubscriptionPaymentMethodSuccessFieldsFragment = {
  readonly __typename: 'DeleteSubscriptionPaymentMethodSuccess';
  readonly creditCards: ReadonlyArray<{
    readonly __typename: 'SubscriptionCreditCard';
    readonly id: string;
    readonly cardType: BraintreeCardType;
    readonly lastFour: string;
  }>;
};

export type PaymentMethodAlreadyExistsErrorFieldsFragment = {
  readonly __typename: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: string;
};

export type ActivePaymentMethodErrorFieldsFragment = {
  readonly __typename: 'ActivePaymentMethodError';
  readonly errorMessage: string;
};

export type UnknownUserErrorFieldsFragment = {
  readonly __typename: 'UnknownUserError';
  readonly errorMessage: string;
};

type UserErrorFields_ActivePaymentMethodError_Fragment = {
  readonly __typename: 'ActivePaymentMethodError';
  readonly errorMessage: string;
};

type UserErrorFields_AddCardFunctionalityDeprecated_Fragment = {
  readonly __typename: 'AddCardFunctionalityDeprecated';
  readonly errorMessage: string;
};

type UserErrorFields_CanCancelAlreadySent_Fragment = {
  readonly __typename: 'CanCancelAlreadySent';
  readonly errorMessage: string;
};

type UserErrorFields_CanCancelFailed_Fragment = {
  readonly __typename: 'CanCancelFailed';
  readonly errorMessage: string;
};

type UserErrorFields_CanCancelNotFound_Fragment = {
  readonly __typename: 'CanCancelNotFound';
  readonly errorMessage: string;
};

type UserErrorFields_CanCancelUnauthorized_Fragment = {
  readonly __typename: 'CanCancelUnauthorized';
  readonly errorMessage: string;
};

type UserErrorFields_CancelOrderFailed_Fragment = {
  readonly __typename: 'CancelOrderFailed';
  readonly errorMessage: string;
};

type UserErrorFields_CancellationFailedWithDelivery_Fragment = {
  readonly __typename: 'CancellationFailedWithDelivery';
  readonly errorMessage: string;
};

type UserErrorFields_CancellationLimitExceeded_Fragment = {
  readonly __typename: 'CancellationLimitExceeded';
  readonly errorMessage: string;
};

type UserErrorFields_CannotRefundSubscriptionTransactionUserError_Fragment = {
  readonly __typename: 'CannotRefundSubscriptionTransactionUserError';
  readonly errorMessage: string;
};

type UserErrorFields_CantCancelAlreadyCanceled_Fragment = {
  readonly __typename: 'CantCancelAlreadyCanceled';
  readonly errorMessage: string;
};

type UserErrorFields_CantCancelLimitExcceeded_Fragment = {
  readonly __typename: 'CantCancelLimitExcceeded';
  readonly errorMessage: string;
};

type UserErrorFields_CantCancelOldOrders_Fragment = {
  readonly __typename: 'CantCancelOldOrders';
  readonly errorMessage: string;
};

type UserErrorFields_CustomerNotFound_Fragment = {
  readonly __typename: 'CustomerNotFound';
  readonly errorMessage: string;
};

type UserErrorFields_CustomerSupportCreditsFailureError_Fragment = {
  readonly __typename: 'CustomerSupportCreditsFailureError';
  readonly errorMessage: string;
};

type UserErrorFields_DeclinedPaymentMethod_Fragment = {
  readonly __typename: 'DeclinedPaymentMethod';
  readonly errorMessage: string;
};

type UserErrorFields_DeliveryAlreadyInFlight_Fragment = {
  readonly __typename: 'DeliveryAlreadyInFlight';
  readonly errorMessage: string;
};

type UserErrorFields_DeliverySpecifyAddress_Fragment = {
  readonly __typename: 'DeliverySpecifyAddress';
  readonly errorMessage: string;
};

type UserErrorFields_FreeTrialNotEligible_Fragment = {
  readonly __typename: 'FreeTrialNotEligible';
  readonly errorMessage: string;
};

type UserErrorFields_GiftCardAssociatedWithAnotherAccountError_Fragment = {
  readonly __typename: 'GiftCardAssociatedWithAnotherAccountError';
  readonly errorMessage: string;
};

type UserErrorFields_GiftCardNotFoundError_Fragment = {
  readonly __typename: 'GiftCardNotFoundError';
  readonly errorMessage: string;
};

type UserErrorFields_GiftCardOrderNotFoundError_Fragment = {
  readonly __typename: 'GiftCardOrderNotFoundError';
  readonly errorMessage: string;
};

type UserErrorFields_IncorrectPaymentMethod_Fragment = {
  readonly __typename: 'IncorrectPaymentMethod';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidBillingAccount_Fragment = {
  readonly __typename: 'InvalidBillingAccount';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidCustomerPhone_Fragment = {
  readonly __typename: 'InvalidCustomerPhone';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidGiftCardError_Fragment = {
  readonly __typename: 'InvalidGiftCardError';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidPaymentMethod_Fragment = {
  readonly __typename: 'InvalidPaymentMethod';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidPostalCode_Fragment = {
  readonly __typename: 'InvalidPostalCode';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidSubmittedBillingAccount_Fragment = {
  readonly __typename: 'InvalidSubmittedBillingAccount';
  readonly errorMessage: string;
};

type UserErrorFields_InvalidTip_Fragment = {
  readonly __typename: 'InvalidTip';
  readonly errorMessage: string;
};

type UserErrorFields_LineItemModificationsInvalidException_Fragment = {
  readonly __typename: 'LineItemModificationsInvalidException';
  readonly errorMessage: string;
};

type UserErrorFields_LoyaltyOptInFailed_Fragment = {
  readonly __typename: 'LoyaltyOptInFailed';
  readonly errorMessage: string;
};

type UserErrorFields_MinimumDeliverySubtotalNotMet_Fragment = {
  readonly __typename: 'MinimumDeliverySubtotalNotMet';
  readonly errorMessage: string;
};

type UserErrorFields_NoBalanceGiftCardError_Fragment = {
  readonly __typename: 'NoBalanceGiftCardError';
  readonly errorMessage: string;
};

type UserErrorFields_OrderAlreadyCanceled_Fragment = {
  readonly __typename: 'OrderAlreadyCanceled';
  readonly errorMessage: string;
};

type UserErrorFields_OrderAlreadySentToKitchen_Fragment = {
  readonly __typename: 'OrderAlreadySentToKitchen';
  readonly errorMessage: string;
};

type UserErrorFields_OrderNotFound_Fragment = {
  readonly __typename: 'OrderNotFound';
  readonly errorMessage: string;
};

type UserErrorFields_PaymentMethodAlreadyExistsError_Fragment = {
  readonly __typename: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: string;
};

type UserErrorFields_ProductOutOfStock_Fragment = {
  readonly __typename: 'ProductOutOfStock';
  readonly errorMessage: string;
};

type UserErrorFields_ProductUnavailable_Fragment = {
  readonly __typename: 'ProductUnavailable';
  readonly errorMessage: string;
};

type UserErrorFields_PromoCodeAlreadyApplied_Fragment = {
  readonly __typename: 'PromoCodeAlreadyApplied';
  readonly errorMessage: string;
};

type UserErrorFields_RestaurantIsNotAcceptingOrders_Fragment = {
  readonly __typename: 'RestaurantIsNotAcceptingOrders';
  readonly errorMessage: string;
};

type UserErrorFields_RewardNotValidated_Fragment = {
  readonly __typename: 'RewardNotValidated';
  readonly errorMessage: string;
};

type UserErrorFields_SubmitGiftCardOrderError_Fragment = {
  readonly __typename: 'SubmitGiftCardOrderError';
  readonly errorMessage: string;
};

type UserErrorFields_SubscriptionCannotBeReactivatedError_Fragment = {
  readonly __typename: 'SubscriptionCannotBeReactivatedError';
  readonly errorMessage: string;
};

type UserErrorFields_TimeslotUnavailable_Fragment = {
  readonly __typename: 'TimeslotUnavailable';
  readonly errorMessage: string;
};

type UserErrorFields_TooManyRequests_Fragment = {
  readonly __typename: 'TooManyRequests';
  readonly errorMessage: string;
};

type UserErrorFields_UnableToGetGiftCardBalanceError_Fragment = {
  readonly __typename: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: string;
};

type UserErrorFields_UnableToRedeemGiftCardError_Fragment = {
  readonly __typename: 'UnableToRedeemGiftCardError';
  readonly errorMessage: string;
};

type UserErrorFields_UnknownUserError_Fragment = {
  readonly __typename: 'UnknownUserError';
  readonly errorMessage: string;
};

export type UserErrorFieldsFragment =
  | UserErrorFields_ActivePaymentMethodError_Fragment
  | UserErrorFields_AddCardFunctionalityDeprecated_Fragment
  | UserErrorFields_CanCancelAlreadySent_Fragment
  | UserErrorFields_CanCancelFailed_Fragment
  | UserErrorFields_CanCancelNotFound_Fragment
  | UserErrorFields_CanCancelUnauthorized_Fragment
  | UserErrorFields_CancelOrderFailed_Fragment
  | UserErrorFields_CancellationFailedWithDelivery_Fragment
  | UserErrorFields_CancellationLimitExceeded_Fragment
  | UserErrorFields_CannotRefundSubscriptionTransactionUserError_Fragment
  | UserErrorFields_CantCancelAlreadyCanceled_Fragment
  | UserErrorFields_CantCancelLimitExcceeded_Fragment
  | UserErrorFields_CantCancelOldOrders_Fragment
  | UserErrorFields_CustomerNotFound_Fragment
  | UserErrorFields_CustomerSupportCreditsFailureError_Fragment
  | UserErrorFields_DeclinedPaymentMethod_Fragment
  | UserErrorFields_DeliveryAlreadyInFlight_Fragment
  | UserErrorFields_DeliverySpecifyAddress_Fragment
  | UserErrorFields_FreeTrialNotEligible_Fragment
  | UserErrorFields_GiftCardAssociatedWithAnotherAccountError_Fragment
  | UserErrorFields_GiftCardNotFoundError_Fragment
  | UserErrorFields_GiftCardOrderNotFoundError_Fragment
  | UserErrorFields_IncorrectPaymentMethod_Fragment
  | UserErrorFields_InvalidBillingAccount_Fragment
  | UserErrorFields_InvalidCustomerPhone_Fragment
  | UserErrorFields_InvalidGiftCardError_Fragment
  | UserErrorFields_InvalidPaymentMethod_Fragment
  | UserErrorFields_InvalidPostalCode_Fragment
  | UserErrorFields_InvalidSubmittedBillingAccount_Fragment
  | UserErrorFields_InvalidTip_Fragment
  | UserErrorFields_LineItemModificationsInvalidException_Fragment
  | UserErrorFields_LoyaltyOptInFailed_Fragment
  | UserErrorFields_MinimumDeliverySubtotalNotMet_Fragment
  | UserErrorFields_NoBalanceGiftCardError_Fragment
  | UserErrorFields_OrderAlreadyCanceled_Fragment
  | UserErrorFields_OrderAlreadySentToKitchen_Fragment
  | UserErrorFields_OrderNotFound_Fragment
  | UserErrorFields_PaymentMethodAlreadyExistsError_Fragment
  | UserErrorFields_ProductOutOfStock_Fragment
  | UserErrorFields_ProductUnavailable_Fragment
  | UserErrorFields_PromoCodeAlreadyApplied_Fragment
  | UserErrorFields_RestaurantIsNotAcceptingOrders_Fragment
  | UserErrorFields_RewardNotValidated_Fragment
  | UserErrorFields_SubmitGiftCardOrderError_Fragment
  | UserErrorFields_SubscriptionCannotBeReactivatedError_Fragment
  | UserErrorFields_TimeslotUnavailable_Fragment
  | UserErrorFields_TooManyRequests_Fragment
  | UserErrorFields_UnableToGetGiftCardBalanceError_Fragment
  | UserErrorFields_UnableToRedeemGiftCardError_Fragment
  | UserErrorFields_UnknownUserError_Fragment;

export type InvalidPaymentMethodFieldsFragment = {
  readonly __typename: 'InvalidPaymentMethod';
  readonly errorMessage: string;
};

export type SubscriptionPlanByIdQueryVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;

export type SubscriptionPlanByIdQuery = {
  readonly __typename?: 'Query';
  readonly subscriptionPlanById: {
    readonly __typename?: 'SubscriptionPlan';
    readonly id: string;
    readonly price: string;
    readonly hasFreeTrial: boolean;
    readonly billingFrequencyUnit: DurationUnit | null;
  } | null;
};

export type SubscriptionPlansByIdsQueryVariables = Exact<{
  planIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SubscriptionPlansByIdsQuery = {
  readonly __typename?: 'Query';
  readonly subscriptionPlansByIds: ReadonlyArray<{
    readonly __typename?: 'SubscriptionPlan';
    readonly id: string;
    readonly price: string;
    readonly hasFreeTrial: boolean;
    readonly billingFrequency: string;
    readonly billingFrequencyUnit: DurationUnit | null;
  }> | null;
};

export type SubscribeMutationVariables = Exact<{
  planId: Scalars['String']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscribeMutation = {
  readonly __typename?: 'Mutation';
  readonly createSubscriptionV2:
    | {
        readonly __typename: 'CreateSubscriptionResult';
        readonly id: string;
        readonly trialDuration: boolean;
        readonly status: string;
        readonly firstBillingDate: string | null;
        readonly subscriptionAmount: string;
      }
    | {
        readonly __typename: 'FreeTrialNotEligible';
        readonly errorMessage: string;
      }
    | { readonly __typename?: 'UnknownUserError' };
};

export type SweetpassTierQueryVariables = Exact<{ [key: string]: never }>;

export type SweetpassTierQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename: 'LoyaltyProfile';
    readonly customerId: string;
    readonly loyaltyId: string | null;
    readonly joinDate: string;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: TierName;
      readonly nextTier: {
        readonly __typename?: 'Tier';
        readonly id: string;
        readonly name: TierName;
      } | null;
    } | null;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: TierStatusName;
    } | null;
    readonly trialEligible: ReadonlyArray<{
      readonly __typename?: 'TrialEligibility';
      readonly duration: number;
      readonly durationUnit: DurationUnit;
    }> | null;
  } | null;
};

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: UpdatePaymentMethodInput;
}>;

export type UpdatePaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePaymentMethod: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
    readonly description: string | null;
    readonly nickname: string | null;
    readonly isDefault: boolean;
    readonly cardType: CardType;
    readonly expirationState: CardExpirationState;
  };
};

export type UpdateBillingAccountMutationVariables = Exact<{
  input: UpdateBillingAccountInput;
}>;

export type UpdateBillingAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly updateBillingAccount: {
    readonly __typename?: 'UpdateBillingAccountResponse';
    readonly billingAccount: {
      readonly __typename: 'BillingAccount';
      readonly id: string;
      readonly description: string;
      readonly nickname: string | null;
      readonly isDefault: boolean;
      readonly cardType: CardType;
      readonly expirationState: ExpirationState;
    };
  };
};

export type KustomerTokenQueryVariables = Exact<{ [key: string]: never }>;

export type KustomerTokenQuery = {
  readonly __typename?: 'Query';
  readonly kustomerToken: {
    readonly __typename?: 'Kustomer';
    readonly token: string;
  } | null;
};

export type CreatePaymentIntentMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreatePaymentIntentMutation = {
  readonly __typename?: 'Mutation';
  readonly createPaymentIntent: string;
};

export type CategoryUpsellsQueryVariables = Exact<{
  restaurantId: Scalars['String']['input'];
}>;

export type CategoryUpsellsQuery = {
  readonly __typename?: 'Query';
  readonly categoryUpsells: ReadonlyArray<{
    readonly __typename?: 'UpsellCategory';
    readonly id: string;
    readonly name: string;
    readonly categoryRanking: number;
    readonly products: ReadonlyArray<{
      readonly __typename?: 'UpsellCategoryProduct';
      readonly id: string;
      readonly name: string;
      readonly cost: number;
      readonly imageUrl: string;
      readonly outOfStock: boolean;
      readonly menuId: number;
    }>;
  }>;
};

export type GetAvailableWantedTimesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableWantedTimesQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
  } | null;
};

export type DeliveryEstimateQueryVariables = Exact<{
  input: LocationByAddressInput;
}>;

export type DeliveryEstimateQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type SubmitOrderUsingPaymentMethodCardMutationVariables = Exact<{
  input: SubmitOrderUsingPaymentMethodCardInput;
}>;

export type SubmitOrderUsingPaymentMethodCardMutation = {
  readonly __typename?: 'Mutation';
  readonly submitOrderUsingPaymentMethodCard:
    | { readonly __typename: 'DeliveryAlreadyInFlight' }
    | { readonly __typename: 'DeliverySpecifyAddress' }
    | { readonly __typename: 'InvalidBillingAccount' }
    | { readonly __typename: 'InvalidCustomerPhone' }
    | {
        readonly __typename: 'InvalidTip';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'MinimumDeliverySubtotalNotMet' }
    | {
        readonly __typename: 'ProductUnavailable';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantIsNotAcceptingOrders';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RewardNotValidated';
        readonly message: string;
        readonly status: number;
        readonly failureCode: ValidateRewardFailureCode;
      }
    | { readonly __typename: 'SubmitOrderUsingBillingAccountSuccess' }
    | {
        readonly __typename: 'SubmitOrderUsingPaymentMethodCardSuccess';
        readonly order: { readonly __typename?: 'Order'; readonly id: string };
      }
    | { readonly __typename: 'TimeslotUnavailable' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SubmitOrderUsingPaymentMethodMutationVariables = Exact<{
  input: SubmitOrderUsingPaymentMethodInput;
}>;

export type SubmitOrderUsingPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly submitOrderUsingPaymentMethod:
    | { readonly __typename: 'DeclinedPaymentMethod' }
    | { readonly __typename: 'DeliveryAlreadyInFlight' }
    | { readonly __typename: 'DeliverySpecifyAddress' }
    | { readonly __typename: 'IncorrectPaymentMethod' }
    | { readonly __typename: 'InvalidCustomerPhone' }
    | {
        readonly __typename: 'InvalidTip';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'MinimumDeliverySubtotalNotMet' }
    | {
        readonly __typename: 'ProductUnavailable';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantIsNotAcceptingOrders';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RewardNotValidated';
        readonly message: string;
        readonly status: number;
        readonly failureCode: ValidateRewardFailureCode;
      }
    | {
        readonly __typename: 'SubmitOrderUsingPaymentMethodSuccess';
        readonly order: { readonly __typename?: 'Order'; readonly id: string };
      }
    | { readonly __typename: 'TimeslotUnavailable' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SubmitOrderUsingPaymentMethodSuccessFragment = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodSuccess';
  readonly order: { readonly __typename?: 'Order'; readonly id: string };
};

export type SubmitOrderSuccessFragment = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodCardSuccess';
  readonly order: { readonly __typename?: 'Order'; readonly id: string };
};

export type RewardNotValidatedFieldsFragment = {
  readonly __typename?: 'RewardNotValidated';
  readonly message: string;
  readonly status: number;
  readonly failureCode: ValidateRewardFailureCode;
};

export type ProductUnavailableFieldsFragment = {
  readonly __typename?: 'ProductUnavailable';
  readonly message: string;
  readonly status: number;
};

export type RestaurantIsNotAcceptingOrdersFieldsFragment = {
  readonly __typename?: 'RestaurantIsNotAcceptingOrders';
  readonly message: string;
  readonly status: number;
};

export type InvalidTipFieldsFragment = {
  readonly __typename?: 'InvalidTip';
  readonly message: string;
  readonly status: number;
};

export type AddressQueryVariables = Exact<{
  addressId: Scalars['ID']['input'];
}>;

export type AddressQuery = {
  readonly __typename?: 'Query';
  readonly address: {
    readonly __typename?: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly zipCode: string;
    readonly deliveryPreference: DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string | null;
    readonly notes: string | null;
  } | null;
};

export type DeliveryRestaurantQueryVariables = Exact<{
  input: LocationByAddressInput;
  costChannel?: InputMaybe<CostChannel>;
}>;

export type DeliveryRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: LocationStatus;
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly address: string;
          readonly phone: string;
          readonly isOutpost: boolean;
          readonly deliveryFee: number;
          readonly showDeliveryFeeDisclosure: boolean;
          readonly isAcceptingOrders: boolean;
          readonly notAcceptingOrdersReason: string;
          readonly flexMessage: string | null;
          readonly hours: {
            readonly __typename?: 'RestaurantHours';
            readonly formatted: string | null;
          };
          readonly menu: {
            readonly __typename?: 'Menu';
            readonly id: string;
            readonly categories: ReadonlyArray<{
              readonly __typename?: 'MenuCategory';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly products: ReadonlyArray<{
                readonly __typename?: 'Product';
                readonly calories: number;
                readonly categoryId: string;
                readonly description: string;
                readonly id: string;
                readonly isModifiable: boolean;
                readonly isCustom: boolean;
                readonly customType: CustomProductType | null;
                readonly isSalad: boolean;
                readonly name: string;
                readonly outOfStock: boolean;
                readonly restaurantId: string;
                readonly slug: string;
                readonly throttleItem: boolean;
                readonly cost: number;
                readonly asset: {
                  readonly __typename?: 'Asset';
                  readonly url: string;
                };
                readonly ingredients: ReadonlyArray<{
                  readonly __typename?: 'Ingredient';
                  readonly id: string;
                  readonly name: string;
                  readonly asset: {
                    readonly __typename?: 'Asset';
                    readonly url: string;
                  };
                }>;
                readonly label: {
                  readonly __typename?: 'ProductLabel';
                  readonly id: string;
                  readonly name: string;
                } | null;
                readonly dietaryProperties: ReadonlyArray<{
                  readonly __typename?: 'DietaryProperty';
                  readonly id: string;
                  readonly name: DietaryPropertyKind;
                }>;
              }>;
            }>;
          };
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type FavoritesQueryVariables = Exact<{
  input: FavoritedLineItemsInput;
}>;

export type FavoritesQuery = {
  readonly __typename?: 'Query';
  readonly favoritedLineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly slug: string;
    readonly customName: string | null;
    readonly quantity: number;
    readonly cost: number;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly dressingMode: DressingMode;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly isCustom: boolean;
      readonly description: string;
      readonly label: {
        readonly __typename?: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly restaurant: {
        readonly __typename?: 'Restaurant';
        readonly id: string;
      };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: DressingWeight;
    }>;
  }>;
};

export type SubmitGiftCardOrderUsingPaymentMethodMutationVariables = Exact<{
  input: SubmitGiftCardOrderUsingPaymentMethodInput;
}>;

export type SubmitGiftCardOrderUsingPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly submitGiftCardOrderUsingPaymentMethod:
    | {
        readonly __typename: 'GiftCardOrderDetail';
        readonly id: string;
        readonly purchasedAt: string;
        readonly deliveryDetail: {
          readonly __typename?: 'GiftCardDeliveryDetail';
          readonly deliveryMethods: ReadonlyArray<string>;
          readonly isAsap: boolean;
          readonly deliveryDateTime: string | null;
        };
        readonly userGiftCards: ReadonlyArray<{
          readonly __typename?: 'UserGiftCard';
          readonly id: string;
          readonly balance: number;
          readonly assetId: string | null;
          readonly recipientEmail: string | null;
          readonly recipientName: string;
          readonly message: string | null;
        }>;
      }
    | {
        readonly __typename: 'SubmitGiftCardOrderError';
        readonly errorMessage: string;
      };
};

export type GiftCardPurchasePaymentProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GiftCardPurchasePaymentProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentProfile: {
    readonly __typename?: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  } | null;
};

export type GiftCardOrderDetailFragment = {
  readonly __typename?: 'GiftCardOrderDetail';
  readonly id: string;
  readonly purchasedAt: string;
  readonly deliveryDetail: {
    readonly __typename?: 'GiftCardDeliveryDetail';
    readonly deliveryMethods: ReadonlyArray<string>;
    readonly isAsap: boolean;
    readonly deliveryDateTime: string | null;
  };
  readonly userGiftCards: ReadonlyArray<{
    readonly __typename?: 'UserGiftCard';
    readonly id: string;
    readonly balance: number;
    readonly assetId: string | null;
    readonly recipientEmail: string | null;
    readonly recipientName: string;
    readonly message: string | null;
  }>;
};

export type SubmitGiftCardOrderErrorFragment = {
  readonly __typename?: 'SubmitGiftCardOrderError';
  readonly errorMessage: string;
};

export type GiftCardOrderDetailQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;

export type GiftCardOrderDetailQuery = {
  readonly __typename?: 'Query';
  readonly giftCardOrderDetail:
    | {
        readonly __typename: 'GiftCardOrderDetail';
        readonly id: string;
        readonly deliveryDetail: {
          readonly __typename?: 'GiftCardDeliveryDetail';
          readonly deliveryDateTime: string | null;
        };
        readonly userGiftCards: ReadonlyArray<{
          readonly __typename?: 'UserGiftCard';
          readonly id: string;
          readonly balance: number;
          readonly assetId: string | null;
          readonly recipientEmail: string | null;
          readonly recipientName: string;
          readonly message: string | null;
        }>;
      }
    | {
        readonly __typename: 'GiftCardOrderNotFoundError';
        readonly errorMessage: string;
      };
};

export type GiftCardRedemptionDetailQueryVariables = Exact<{
  giftCardRedemptionDetailId: Scalars['String']['input'];
}>;

export type GiftCardRedemptionDetailQuery = {
  readonly __typename?: 'Query';
  readonly giftCardRedemptionDetail:
    | { readonly __typename: 'GiftCardNotFoundError' }
    | {
        readonly __typename: 'GiftCardRedemptionDetailSuccess';
        readonly balance: number;
        readonly assetId: string | null;
        readonly cardNumber: string;
        readonly cardRegCode: string;
        readonly recipientName: string;
        readonly recipientMessage: string | null;
        readonly senderName: string;
        readonly redeemId: string;
      };
};

export type GetOrderStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename?: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: FlattenedOrderStatuses;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
          readonly orderType: OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly isPendingFeedback: boolean;
          readonly dropoffLocation: string | null;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        };
      }
    | null;
};

export type GetOrderStatusFieldsFragment = {
  readonly __typename: 'OrderStatus';
  readonly flattenedOrderStatus: FlattenedOrderStatuses;
  readonly order: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly wantedTime: string;
    readonly orderType: OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly isPendingFeedback: boolean;
    readonly dropoffLocation: string | null;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly name: string;
      readonly isOutpost: boolean;
    };
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
  };
};

export type SignInMutationVariables = Exact<{ [key: string]: never }>;

export type SignInMutation = {
  readonly __typename?: 'Mutation';
  readonly signIn:
    | { readonly __typename: 'CustomerNotFound'; readonly errorMessage: string }
    | {
        readonly __typename: 'SignInFailed';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'SignInSuccess';
        readonly customer: {
          readonly __typename?: 'Customer';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type LogInCustomerSuccessfulLoginFieldsFragment = {
  readonly __typename?: 'LoginSuccess';
  readonly csrf: string;
};

export type LogInCustomerInvalidCredentialsFieldsFragment = {
  readonly __typename?: 'InvalidCredentials';
  readonly message: string;
  readonly status: number;
};

export type LogInCustomerMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LogInCustomerMutation = {
  readonly __typename?: 'Mutation';
  readonly login:
    | { readonly __typename: 'AccountLocked' }
    | {
        readonly __typename: 'InvalidCredentials';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'LoginSuccess'; readonly csrf: string }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type MenuCartQueryVariables = Exact<{ [key: string]: never }>;

export type MenuCartQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
    };
  } | null;
};

export type CategoryProductFragment = {
  readonly __typename?: 'Product';
  readonly calories: number;
  readonly categoryId: string;
  readonly description: string;
  readonly id: string;
  readonly isModifiable: boolean;
  readonly isCustom: boolean;
  readonly customType: CustomProductType | null;
  readonly isSalad: boolean;
  readonly name: string;
  readonly outOfStock: boolean;
  readonly restaurantId: string;
  readonly slug: string;
  readonly throttleItem: boolean;
  readonly cost: number;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly ingredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  }>;
  readonly label: {
    readonly __typename?: 'ProductLabel';
    readonly id: string;
    readonly name: string;
  } | null;
  readonly dietaryProperties: ReadonlyArray<{
    readonly __typename?: 'DietaryProperty';
    readonly id: string;
    readonly name: DietaryPropertyKind;
  }>;
};

export type CategoryFragment = {
  readonly __typename?: 'MenuCategory';
  readonly id: string;
  readonly name: string;
  readonly isCustom: boolean;
  readonly products: ReadonlyArray<{
    readonly __typename?: 'Product';
    readonly calories: number;
    readonly categoryId: string;
    readonly description: string;
    readonly id: string;
    readonly isModifiable: boolean;
    readonly isCustom: boolean;
    readonly customType: CustomProductType | null;
    readonly isSalad: boolean;
    readonly name: string;
    readonly outOfStock: boolean;
    readonly restaurantId: string;
    readonly slug: string;
    readonly throttleItem: boolean;
    readonly cost: number;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly label: {
      readonly __typename?: 'ProductLabel';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
  }>;
};

export type RestaurantMenuFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly address: string;
  readonly phone: string;
  readonly isOutpost: boolean;
  readonly deliveryFee: number;
  readonly showDeliveryFeeDisclosure: boolean;
  readonly isAcceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string;
  readonly flexMessage: string | null;
  readonly hours: {
    readonly __typename?: 'RestaurantHours';
    readonly formatted: string | null;
  };
  readonly menu: {
    readonly __typename?: 'Menu';
    readonly id: string;
    readonly categories: ReadonlyArray<{
      readonly __typename?: 'MenuCategory';
      readonly id: string;
      readonly name: string;
      readonly isCustom: boolean;
      readonly products: ReadonlyArray<{
        readonly __typename?: 'Product';
        readonly calories: number;
        readonly categoryId: string;
        readonly description: string;
        readonly id: string;
        readonly isModifiable: boolean;
        readonly isCustom: boolean;
        readonly customType: CustomProductType | null;
        readonly isSalad: boolean;
        readonly name: string;
        readonly outOfStock: boolean;
        readonly restaurantId: string;
        readonly slug: string;
        readonly throttleItem: boolean;
        readonly cost: number;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
        }>;
        readonly label: {
          readonly __typename?: 'ProductLabel';
          readonly id: string;
          readonly name: string;
        } | null;
        readonly dietaryProperties: ReadonlyArray<{
          readonly __typename?: 'DietaryProperty';
          readonly id: string;
          readonly name: DietaryPropertyKind;
        }>;
      }>;
    }>;
  };
};

export type RestaurantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  costChannel?: InputMaybe<CostChannel>;
}>;

export type RestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly address: string;
    readonly phone: string;
    readonly isOutpost: boolean;
    readonly deliveryFee: number;
    readonly showDeliveryFeeDisclosure: boolean;
    readonly isAcceptingOrders: boolean;
    readonly notAcceptingOrdersReason: string;
    readonly flexMessage: string | null;
    readonly hours: {
      readonly __typename?: 'RestaurantHours';
      readonly formatted: string | null;
    };
    readonly menu: {
      readonly __typename?: 'Menu';
      readonly id: string;
      readonly categories: ReadonlyArray<{
        readonly __typename?: 'MenuCategory';
        readonly id: string;
        readonly name: string;
        readonly isCustom: boolean;
        readonly products: ReadonlyArray<{
          readonly __typename?: 'Product';
          readonly calories: number;
          readonly categoryId: string;
          readonly description: string;
          readonly id: string;
          readonly isModifiable: boolean;
          readonly isCustom: boolean;
          readonly customType: CustomProductType | null;
          readonly isSalad: boolean;
          readonly name: string;
          readonly outOfStock: boolean;
          readonly restaurantId: string;
          readonly slug: string;
          readonly throttleItem: boolean;
          readonly cost: number;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly ingredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          }>;
          readonly label: {
            readonly __typename?: 'ProductLabel';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        }>;
      }>;
    };
  } | null;
};

export type MenuRedirectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MenuRedirectQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
  } | null;
};

export type BaseProductQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type BaseProductQuery = {
  readonly __typename?: 'Query';
  readonly baseProduct: {
    readonly __typename: 'BaseProduct';
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly heading: string;
    readonly subheading: string;
    readonly isSeasonal: boolean;
    readonly isDeepLinkingEnabled: boolean;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  } | null;
};

export type BaseProductDataFragment = {
  readonly __typename?: 'BaseProduct';
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly heading: string;
  readonly subheading: string;
  readonly isSeasonal: boolean;
  readonly isDeepLinkingEnabled: boolean;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type OrderProductRestaurantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrderProductRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly name: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly slug: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly phone: string;
    readonly flexMessage: string | null;
    readonly isAcceptingOrders: boolean;
    readonly notAcceptingOrdersReason: string;
    readonly hours: {
      readonly __typename?: 'RestaurantHours';
      readonly formatted: string | null;
    };
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  } | null;
};

export type OrderProductRestaurantDataFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly isAcceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string;
  readonly hours: {
    readonly __typename?: 'RestaurantHours';
    readonly formatted: string | null;
  };
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type ProductForRestaurantQueryVariables = Exact<{
  baseProductSlug: Scalars['String']['input'];
  restaurantId: Scalars['String']['input'];
}>;

export type ProductForRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly productForRestaurant: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly slug: string;
  } | null;
};

export type OrderStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder'; readonly message: string }
    | {
        readonly __typename: 'OrderStatus';
        readonly gravyStatus: GravyStatusType;
        readonly gravyUpdatedAt: string;
        readonly orderOccasion: string;
        readonly orderStatus: string;
        readonly flattenedOrderStatus: FlattenedOrderStatuses;
        readonly courierDetails: {
          readonly __typename?: 'CourierDetails';
          readonly name: string | null;
          readonly phone: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly description: string;
          readonly dropoffLocation: string | null;
          readonly orderType: OrderType;
          readonly total: number | null;
          readonly canTrackOrderStatus: boolean;
          readonly isPendingFeedback: boolean;
          readonly wantedTime: string;
          readonly vendorId: string;
          readonly canCancel: boolean;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly city: string;
            readonly state: string;
            readonly zipCode: string;
            readonly name: string;
            readonly address: string;
            readonly slug: string;
            readonly latitude: number;
            readonly longitude: number;
            readonly isOutpost: boolean;
            readonly phone: string;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
          };
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly calories: number;
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | null;
};

export type OrderStatusPollingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrderStatusPollingQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder'; readonly message: string }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: FlattenedOrderStatuses;
        readonly courierDetails: {
          readonly __typename?: 'CourierDetails';
          readonly name: string | null;
          readonly phone: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
          readonly canCancel: boolean;
        };
      }
    | null;
};

export type CanCancelOrderQueryVariables = Exact<{
  input: ReadonlyArray<CanCancelInput> | CanCancelInput;
}>;

export type CanCancelOrderQuery = {
  readonly __typename?: 'Query';
  readonly canCancel: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export type FavoriteLineItemMutationVariables = Exact<{
  input: FavoriteLineItemInput;
}>;

export type FavoriteLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly favoriteLineItem: {
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly favorited: boolean;
  };
};

export type GiftCardPurchaseHistoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GiftCardPurchaseHistoryQuery = {
  readonly __typename?: 'Query';
  readonly giftCardPurchaseHistory: {
    readonly __typename: 'GiftCardPurchaseHistory';
    readonly giftCardOrders: ReadonlyArray<{
      readonly __typename?: 'GiftCardOrderDetail';
      readonly id: string;
      readonly purchasedAt: string;
      readonly userGiftCards: ReadonlyArray<{
        readonly __typename?: 'UserGiftCard';
        readonly id: string;
        readonly balance: number;
        readonly recipientEmail: string | null;
        readonly recipientName: string;
      }>;
    }>;
  };
};

export type OrderFieldsFragment = {
  readonly __typename?: 'Order';
  readonly id: string;
  readonly orderType: OrderType;
  readonly wantedTime: string;
  readonly description: string;
  readonly total: number | null;
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly customName: string | null;
    readonly isCustom: boolean;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly isCustom: boolean;
      readonly categoryName: string;
    };
  }>;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly name: string;
    readonly isOutpost: boolean;
  };
  readonly deliveryOrderDetail: {
    readonly __typename?: 'DeliveryOrderDetail';
    readonly id: string;
    readonly address: {
      readonly __typename?: 'Address';
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};

export type OrderHistoryQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type OrderHistoryQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly total: number;
          readonly last: number;
        };
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly canCancel: boolean;
          readonly id: string;
          readonly orderType: OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly total: number | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type OrdersV2QueryVariables = Exact<{
  input: OrdersInputV2;
}>;

export type OrdersV2Query = {
  readonly __typename?: 'Query';
  readonly ordersV2:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly total: number;
          readonly last: number;
        };
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly isCanceled: boolean;
          readonly id: string;
          readonly orderType: OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly total: number | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type CanCancelOrdersQueryVariables = Exact<{
  input: ReadonlyArray<CanCancelInput> | CanCancelInput;
}>;

export type CanCancelOrdersQuery = {
  readonly __typename?: 'Query';
  readonly canCancel: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export type PdpLineItemDetailsFragment = {
  readonly __typename?: 'LineItem';
  readonly id: string;
  readonly quantity: number;
  readonly isCustom: boolean;
  readonly customName: string | null;
  readonly ingredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
  }>;
  readonly mixedDressingDetails: ReadonlyArray<{
    readonly __typename?: 'MixedDressingDetails';
    readonly ingredientId: string;
    readonly weight: DressingWeight;
  }>;
};

export type LineItemSuccessFieldsFragment = {
  readonly __typename?: 'LineItemSuccess';
  readonly lineItem: {
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly quantity: number;
    readonly isCustom: boolean;
    readonly customName: string | null;
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    }>;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: DressingWeight;
    }>;
  };
};

export type LineItemNotFoundFieldsFragment = {
  readonly __typename?: 'LineItemNotFound';
  readonly message: string;
  readonly status: number;
};

export type GetLineItemQueryVariables = Exact<{
  input: LineItemInput;
}>;

export type GetLineItemQuery = {
  readonly __typename?: 'Query';
  readonly getLineItem:
    | {
        readonly __typename: 'LineItemNotFound';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LineItemSuccess';
        readonly lineItem: {
          readonly __typename?: 'LineItem';
          readonly id: string;
          readonly quantity: number;
          readonly isCustom: boolean;
          readonly customName: string | null;
          readonly ingredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          }>;
          readonly mixedDressingDetails: ReadonlyArray<{
            readonly __typename?: 'MixedDressingDetails';
            readonly ingredientId: string;
            readonly weight: DressingWeight;
          }>;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      }
    | null;
};

export type ProductIngredientFragment = {
  readonly __typename?: 'Ingredient';
  readonly id: string;
  readonly name: string;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly dietaryProperties: ReadonlyArray<{
    readonly __typename?: 'DietaryProperty';
    readonly id: string;
    readonly name: DietaryPropertyKind;
  }>;
};

export type IngredientModificationFragment = {
  readonly __typename?: 'IngredientModification';
  readonly calories: number;
  readonly removalCost: number;
  readonly additionCost: number;
  readonly substitutionCost: number;
  readonly kind: string | null;
  readonly subKind: string | null;
  readonly outOfStock: boolean;
  readonly mixable: boolean;
  readonly isGrain: boolean;
  readonly ingredient: {
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
  };
};

export type IngredientModificationKindFragment = {
  readonly __typename?: 'IngredientModificationKind';
  readonly minAggregateQuantity: number;
  readonly maxAggregateQuantity: number;
  readonly freeAggregateQuantity: number;
  readonly allowMultipleQuantity: boolean;
  readonly modifications: ReadonlyArray<{
    readonly __typename?: 'IngredientModification';
    readonly calories: number;
    readonly removalCost: number;
    readonly additionCost: number;
    readonly substitutionCost: number;
    readonly kind: string | null;
    readonly subKind: string | null;
    readonly outOfStock: boolean;
    readonly mixable: boolean;
    readonly isGrain: boolean;
    readonly ingredient: {
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
  }>;
};

export type ProductDetailsFragment = {
  readonly __typename?: 'Product';
  readonly id: string;
  readonly slug: string;
  readonly restaurantId: string;
  readonly name: string;
  readonly categoryId: string;
  readonly description: string;
  readonly calories: number;
  readonly outOfStock: boolean;
  readonly throttleItem: boolean;
  readonly maxModifications: number;
  readonly isModifiable: boolean;
  readonly isCustom: boolean;
  readonly cost: number;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly isOutpost: boolean;
  };
  readonly dietaryProperties: ReadonlyArray<{
    readonly __typename?: 'DietaryProperty';
    readonly id: string;
    readonly name: DietaryPropertyKind;
  }>;
  readonly ingredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
  }>;
  readonly ingredientModifications: {
    readonly __typename?: 'IngredientModifications';
    readonly bases: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly toppings: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly premiums: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly dressings: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly bread: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: DietaryPropertyKind;
          }>;
        };
      }>;
    };
  };
};

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  costChannel?: InputMaybe<CostChannel>;
}>;

export type GetProductQuery = {
  readonly __typename?: 'Query';
  readonly product: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly slug: string;
    readonly restaurantId: string;
    readonly name: string;
    readonly categoryId: string;
    readonly description: string;
    readonly calories: number;
    readonly outOfStock: boolean;
    readonly throttleItem: boolean;
    readonly maxModifications: number;
    readonly isModifiable: boolean;
    readonly isCustom: boolean;
    readonly cost: number;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly isOutpost: boolean;
    };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    }>;
    readonly ingredientModifications: {
      readonly __typename?: 'IngredientModifications';
      readonly bases: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly toppings: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly premiums: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly dressings: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly bread: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
        }>;
      };
    };
  };
};

export type UpdateCustomerProfileMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerProfileMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCustomer: {
    readonly __typename: 'UpdateCustomerResponse';
    readonly customer: {
      readonly __typename: 'Customer';
      readonly id: string;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly email: string | null;
      readonly phoneNumber: string | null;
      readonly birthday: string | null;
    };
  } | null;
};

export type CustomerProfileFieldsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CustomerProfileFieldsQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly birthday: string | null;
  } | null;
};

export type FeedbackQuestionsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type FeedbackQuestionsQuery = {
  readonly __typename?: 'Query';
  readonly feedbackQuestions: ReadonlyArray<{
    readonly __typename?: 'FeedbackQuestion';
    readonly id: string;
    readonly text: string | null;
    readonly slug: string | null;
    readonly responses: ReadonlyArray<{
      readonly __typename?: 'FeedbackResponse';
      readonly id: string;
      readonly text: string;
    }> | null;
  }>;
};

export type InStoreFeedbackQuestionsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type InStoreFeedbackQuestionsQuery = {
  readonly __typename?: 'Query';
  readonly inStoreFeedbackQuestions: ReadonlyArray<{
    readonly __typename?: 'FeedbackQuestion';
    readonly id: string;
    readonly text: string | null;
    readonly slug: string | null;
    readonly responses: ReadonlyArray<{
      readonly __typename?: 'FeedbackResponse';
      readonly id: string;
      readonly text: string;
    }> | null;
  }>;
};

export type FeedbackOrderStatusQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type FeedbackOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: FlattenedOrderStatuses;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly isPendingFeedback: boolean;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly quantity: number;
            readonly customName: string | null;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
            };
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly estimatedDeliveryTime: string | null;
          } | null;
        };
      }
    | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: FeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  readonly __typename?: 'Mutation';
  readonly submitFeedback: {
    readonly __typename: 'FeedbackSuccess';
    readonly success: boolean;
  };
};

export type SubmitInStoreFeedbackMutationVariables = Exact<{
  input: FeedbackInput;
}>;

export type SubmitInStoreFeedbackMutation = {
  readonly __typename?: 'Mutation';
  readonly submitInStoreFeedback: {
    readonly __typename: 'FeedbackSuccess';
    readonly success: boolean;
  };
};

export type ReferralShareUrlQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralShareUrlQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly referralShareUrlEmail: string;
  } | null;
};

export type ReorderOrderMutationVariables = Exact<{
  input: ReorderInput;
}>;

export type ReorderOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly reorderOrder:
    | {
        readonly __typename: 'CanNotReorder';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderConflict';
        readonly isNothingAvailable: boolean;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderSuccess';
        readonly isMixingSupported: boolean;
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ReorderLineItemMutationVariables = Exact<{
  input: ReorderInput;
}>;

export type ReorderLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly reorderLineItem:
    | {
        readonly __typename: 'CanNotReorder';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderConflict';
        readonly isNothingAvailable: boolean;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderSuccess';
        readonly isMixingSupported: boolean;
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: OrderType;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: ConflictType;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ReorderSuccessResponseFragment = {
  readonly __typename?: 'ReorderSuccess';
  readonly isMixingSupported: boolean;
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: OrderType;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type ConflictFragment = {
  readonly __typename?: 'OrderConflict';
  readonly type: ConflictType;
  readonly product: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly name: string;
    readonly calories: number;
    readonly cost: number;
    readonly description: string;
    readonly label: {
      readonly __typename?: 'ProductLabel';
      readonly name: string;
    } | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: DietaryPropertyKind;
    }>;
  };
  readonly unavailableIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly name: string;
  }>;
};

export type ReorderConflictResponseFragment = {
  readonly __typename?: 'ReorderConflict';
  readonly isNothingAvailable: boolean;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: ConflictType;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename?: 'ProductLabel';
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly name: string;
    }>;
  }>;
};

export type RestaurantMaxDeliveryQuantityExceededResponseFragment = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: ConflictType;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename?: 'ProductLabel';
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly name: string;
    }>;
  }>;
};

export type RestaurantMaxQuantityExceededResponseFragment = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: ConflictType;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename?: 'ProductLabel';
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly name: string;
    }>;
  }>;
};

export type CanNotReorderResponseFragment = {
  readonly __typename?: 'CanNotReorder';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: ConflictType;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename?: 'ProductLabel';
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly name: string;
    }>;
  }>;
};

export type ValidationErrorResponseFragment = {
  readonly __typename?: 'ValidationError';
  readonly message: string;
  readonly status: number;
  readonly fieldErrors: ReadonlyArray<{
    readonly __typename?: 'FieldValidationError';
    readonly field: string;
    readonly message: string;
  }>;
};

export type ReorderRestaurantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReorderRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly name: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly slug: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly phone: string;
    readonly flexMessage: string | null;
    readonly isAcceptingOrders: boolean;
    readonly notAcceptingOrdersReason: string;
    readonly hours: {
      readonly __typename?: 'RestaurantHours';
      readonly formatted: string | null;
    };
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  } | null;
};

export type ReorderRestaurantRequiredDataFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly isAcceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string;
  readonly hours: {
    readonly __typename?: 'RestaurantHours';
    readonly formatted: string | null;
  };
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type ReorderRestaurantDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReorderRestaurantDataQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly isOutpost: boolean;
    readonly deliveryFee: number;
  } | null;
};

export type ReorderDeliveryRestaurantDataQueryVariables = Exact<{
  input: LocationByAddressInput;
}>;

export type ReorderDeliveryRestaurantDataQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly isOutpost: boolean;
          readonly deliveryFee: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type ReorderDeliveryAddressDataQueryVariables = Exact<{
  addressId: Scalars['ID']['input'];
}>;

export type ReorderDeliveryAddressDataQuery = {
  readonly __typename?: 'Query';
  readonly address: {
    readonly __typename?: 'Address';
    readonly id: string;
    readonly name: string | null;
  } | null;
};

export type InStoreGiftCardsQueryVariables = Exact<{ [key: string]: never }>;

export type InStoreGiftCardsQuery = {
  readonly __typename?: 'Query';
  readonly inStorePaymentMethods: ReadonlyArray<{
    readonly __typename?: 'Credit';
    readonly id: string;
    readonly title: string;
    readonly remainingAmount: number;
  }>;
};

export type GetSharedLineItemQueryVariables = Exact<{
  input: LineItemBySlugInput;
}>;

export type GetSharedLineItemQuery = {
  readonly __typename?: 'Query';
  readonly lineItemBySlug:
    | {
        readonly __typename: 'LineItemNotFound';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LineItemSuccess';
        readonly lineItem: {
          readonly __typename?: 'LineItem';
          readonly id: string;
          readonly slug: string;
          readonly quantity: number;
          readonly customName: string | null;
          readonly customerName: string;
          readonly isCustom: boolean;
          readonly dressingMode: DressingMode;
          readonly product: {
            readonly __typename?: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly isCustom: boolean;
            readonly description: string;
            readonly label: {
              readonly __typename?: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: DietaryPropertyKind;
            }>;
            readonly restaurant: {
              readonly __typename?: 'Restaurant';
              readonly id: string;
              readonly slug: string;
              readonly name: string;
            };
          };
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      }
    | null;
};

export type ChallengeRewardFieldsFragment = {
  readonly __typename?: 'ChallengeReward';
  readonly id: string;
  readonly name: string;
  readonly expiresAt: string | null;
  readonly terms: string;
  readonly rewardType: RewardType;
  readonly redeemable: boolean | null;
  readonly redeemableAt: string | null;
};

export type ChallengeRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type ChallengeRewardsQuery = {
  readonly __typename?: 'Query';
  readonly challengeRewards: ReadonlyArray<{
    readonly __typename?: 'ChallengeReward';
    readonly id: string;
    readonly name: string;
    readonly expiresAt: string | null;
    readonly terms: string;
    readonly rewardType: RewardType;
    readonly redeemable: boolean | null;
    readonly redeemableAt: string | null;
  }>;
};

export type ChallengeRewardsWithRedemptionChannelQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ChallengeRewardsWithRedemptionChannelQuery = {
  readonly __typename?: 'Query';
  readonly challengeRewards: ReadonlyArray<{
    readonly __typename?: 'ChallengeReward';
    readonly redemptionChannel: RedemptionChannel;
    readonly id: string;
    readonly name: string;
    readonly expiresAt: string | null;
    readonly terms: string;
    readonly rewardType: RewardType;
    readonly redeemable: boolean | null;
    readonly redeemableAt: string | null;
  }>;
};

export type CancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;

export type CancelSubscriptionMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelSubscription:
    | {
        readonly __typename: 'CancelSubscriptionSuccessResponse';
        readonly subscription: {
          readonly __typename?: 'PaymentSubscription';
          readonly id: string;
          readonly paidThroughDate: string | null;
        };
      }
    | { readonly __typename: 'UnknownUserError' };
};

export type CancelSubscriptionAtCycleEndMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;

export type CancelSubscriptionAtCycleEndMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelSubscriptionAtCycleEnd:
    | {
        readonly __typename: 'CancelSubscriptionAtCycleEndSuccessResponse';
        readonly subscription: {
          readonly __typename?: 'PaymentSubscription';
          readonly id: string;
          readonly paidThroughDate: string | null;
        };
      }
    | { readonly __typename: 'UnknownUserError' };
};

export type RejoinSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;

export type RejoinSubscriptionMutation = {
  readonly __typename?: 'Mutation';
  readonly rejoinSubscription:
    | {
        readonly __typename: 'RejoinSubscriptionSuccessResponse';
        readonly subscription: {
          readonly __typename?: 'PaymentSubscription';
          readonly id: string;
          readonly status: PaymentSubscriptionStatus;
        };
      }
    | {
        readonly __typename: 'SubscriptionCannotBeReactivatedError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnknownUserError';
        readonly errorMessage: string;
      };
};

export type LoyaltyProfileDataFragment = {
  readonly __typename?: 'LoyaltyProfile';
  readonly customerId: string;
  readonly joinDate: string;
  readonly hasPaidTierHistory: boolean;
  readonly tierStatus: {
    readonly __typename?: 'TierStatus';
    readonly id: string;
    readonly name: TierStatusName;
  } | null;
  readonly trialEligible: ReadonlyArray<{
    readonly __typename?: 'TrialEligibility';
    readonly duration: number;
    readonly durationUnit: DurationUnit;
  }> | null;
  readonly tier: {
    readonly __typename?: 'Tier';
    readonly id: string;
    readonly name: TierName;
    readonly nextTier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: TierName;
    } | null;
  } | null;
};

export type SubscriptionCreditCardFragmentFragment = {
  readonly __typename?: 'SubscriptionCreditCard';
  readonly id: string;
  readonly cardType: BraintreeCardType;
  readonly lastFour: string;
  readonly expirationMonth: string | null;
  readonly expirationYear: string | null;
  readonly isActive: boolean;
};

export type PaymentSubscriptionProfileDataFragment = {
  readonly __typename?: 'PaymentSubscriptionProfile';
  readonly id: string;
  readonly creditCards: ReadonlyArray<{
    readonly __typename?: 'SubscriptionCreditCard';
    readonly expirationState: CardExpirationState;
    readonly id: string;
    readonly cardType: BraintreeCardType;
    readonly lastFour: string;
    readonly expirationMonth: string | null;
    readonly expirationYear: string | null;
    readonly isActive: boolean;
  }> | null;
};

export type SweetpassMembershipQueryVariables = Exact<{ [key: string]: never }>;

export type SweetpassMembershipQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly joinDate: string;
    readonly hasPaidTierHistory: boolean;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: TierStatusName;
    } | null;
    readonly trialEligible: ReadonlyArray<{
      readonly __typename?: 'TrialEligibility';
      readonly duration: number;
      readonly durationUnit: DurationUnit;
    }> | null;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: TierName;
      readonly nextTier: {
        readonly __typename?: 'Tier';
        readonly id: string;
        readonly name: TierName;
      } | null;
    } | null;
  } | null;
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly planId: string;
    readonly status: PaymentSubscriptionStatus;
    readonly paidThroughDate: string | null;
    readonly nextBillingDate: string;
    readonly nextBillingPeriodAmount: string;
    readonly firstBillingDate: string | null;
    readonly billingFrequencyUnit: DurationUnit | null;
    readonly price: string | null;
  }>;
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly expirationState: CardExpirationState;
      readonly id: string;
      readonly cardType: BraintreeCardType;
      readonly lastFour: string;
      readonly expirationMonth: string | null;
      readonly expirationYear: string | null;
      readonly isActive: boolean;
    }> | null;
  } | null;
};

export type UpdateSubscriptionPaymentMethodMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;

export type UpdateSubscriptionPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSubscriptionPaymentMethod: {
    readonly __typename: 'UpdateSubscriptionPaymentMethodSuccessResponse';
    readonly subscription: {
      readonly __typename?: 'PaymentSubscription';
      readonly id: string;
    };
  };
};

export type FetchPlansByTierIdQueryVariables = Exact<{
  tierId: Scalars['ID']['input'];
}>;

export type FetchPlansByTierIdQuery = {
  readonly __typename?: 'Query';
  readonly fetchPlansByTierId: ReadonlyArray<{
    readonly __typename?: 'PaymentPlan';
    readonly braintreePlanId: string;
  }> | null;
};

export type OptInUserMutationVariables = Exact<{ [key: string]: never }>;

export type OptInUserMutation = {
  readonly __typename?: 'Mutation';
  readonly optInFreeTier: boolean;
};

export type OptInUserV2MutationVariables = Exact<{ [key: string]: never }>;

export type OptInUserV2Mutation = {
  readonly __typename?: 'Mutation';
  readonly optInFreeTierV2: {
    readonly __typename: 'LoyaltyProfile';
    readonly customerId: string;
    readonly loyaltyId: string | null;
    readonly joinDate: string;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: TierName;
      readonly nextTier: {
        readonly __typename?: 'Tier';
        readonly id: string;
        readonly name: TierName;
      } | null;
    } | null;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: TierStatusName;
    } | null;
    readonly trialEligible: ReadonlyArray<{
      readonly __typename?: 'TrialEligibility';
      readonly duration: number;
      readonly durationUnit: DurationUnit;
    }> | null;
  } | null;
};

export const CartLedgerFragmentDoc = gql`
  fragment CartLedger on Ledger {
    tax
    subtotal
    feesTotal
    discountsTotal
    creditsTotal
    tip
    discounts {
      name
      amount
      description
    }
    credits {
      name
      amount
      description
    }
    fees {
      name
      amount
      description
    }
  }
`;
export const CartLineItemFragmentDoc = gql`
  fragment CartLineItem on LineItem {
    id
    slug
    quantity
    cost
    customName
    perItemCost
    favorited
    isCustom
    addedIngredients {
      id
      name
      kind
      asset {
        url
      }
    }
    removedIngredients {
      id
      name
      kind
      asset {
        url
      }
    }
    product {
      id
      name
      slug
      isCustom
      asset {
        url
      }
      ingredients {
        id
        name
        kind
      }
    }
    mixedDressingDetails {
      ingredientId
      weight
    }
    dressingMode
  }
`;
export const CartRestaurantFragmentDoc = gql`
  fragment CartRestaurant on Restaurant {
    id
    slug
    name
    city
    state
    address
    zipCode
    isOutpost
    deliveryMinSubtotal
    isOutpost
    showDeliveryFeeDisclosure
    deliveryFee
    availableDropOffLocations {
      id
      name
    }
    asset {
      url
    }
  }
`;
export const AvailableWantedTimeFragmentDoc = gql`
  fragment AvailableWantedTime on AvailableWantedTime {
    time
    deliveryOffset
  }
`;
export const DeliveryOrderDetailFragmentDoc = gql`
  fragment DeliveryOrderDetail on DeliveryOrderDetail {
    id
    tip
    deliveryFee
    vendor
    orderId
    vendorRestaurantId
    estimatedDeliveryTime
    address {
      id
      street
      secondaryStreet
      city
      state
      country
      zipCode
      deliveryPreference
      googlePlaceId
      latitude
      longitude
      name
      notes
    }
  }
`;
export const CartDataFragmentDoc = gql`
  fragment CartData on Order {
    id
    orderType
    canTrackOrderStatus
    ledger {
      ...CartLedger
    }
    lineItems {
      ...CartLineItem
    }
    restaurant {
      ...CartRestaurant
    }
    availableWantedTimes {
      ...AvailableWantedTime
    }
    deliveryOrderDetail {
      ...DeliveryOrderDetail
    }
  }
  ${CartLedgerFragmentDoc}
  ${CartLineItemFragmentDoc}
  ${CartRestaurantFragmentDoc}
  ${AvailableWantedTimeFragmentDoc}
  ${DeliveryOrderDetailFragmentDoc}
`;
export const AddLineItemToCartSuccessFieldsFragmentDoc = gql`
  fragment AddLineItemToCartSuccessFields on AddLineItemToCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const EditLineItemInCartSuccessFieldsFragmentDoc = gql`
  fragment EditLineItemInCartSuccessFields on EditLineItemInCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const RemoveFromCartSuccessFieldsFragmentDoc = gql`
  fragment RemoveFromCartSuccessFields on RemoveFromCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const RestaurantMaxQuantityExceededFieldsFragmentDoc = gql`
  fragment RestaurantMaxQuantityExceededFields on RestaurantMaxQuantityExceeded {
    message
    status
  }
`;
export const MaxModificationsExceededFieldsFragmentDoc = gql`
  fragment MaxModificationsExceededFields on MaxModificationsExceeded {
    message
    status
  }
`;
export const RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc = gql`
  fragment RestaurantMaxDeliveryQuantityExceededFields on RestaurantMaxDeliveryQuantityExceeded {
    message
    status
  }
`;
export const ProductOutOfStockFieldsFragmentDoc = gql`
  fragment ProductOutOfStockFields on ProductOutOfStock {
    message
    status
  }
`;
export const UnableToGetGiftCardBalanceErrorFieldsFragmentDoc = gql`
  fragment UnableToGetGiftCardBalanceErrorFields on UnableToGetGiftCardBalanceError {
    errorMessage
  }
`;
export const GiftCardBalanceFieldsFragmentDoc = gql`
  fragment GiftCardBalanceFields on GiftCardBalance {
    customerId
    giftCardBalance
  }
`;
export const RestaurantDetailsFragmentDoc = gql`
  fragment RestaurantDetails on Restaurant {
    id
    name
    slug
    phone
    flexMessage
    deliveryFee
  }
`;
export const LocationDetailsFragmentDoc = gql`
  fragment LocationDetails on StoreLocation {
    id
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    storeHours
    flexMessage
    enabled
    acceptingOrders
    notAcceptingOrdersReason
    imageUrl
    hidden
    showWarningDialog
    warningDialogDescription
    warningDialogTimeout
    warningDialogTitle
  }
`;
export const RecommendedItemFragmentDoc = gql`
  fragment RecommendedItem on LineItemRecommendation {
    id
    name
    recommendationType
    ingredientIds
    description
  }
`;
export const CancelOrderSuccessFieldsFragmentDoc = gql`
  fragment CancelOrderSuccessFields on CancelOrderSuccess {
    success
    reason
  }
`;
export const CancelSuccessRefundFailedFieldsFragmentDoc = gql`
  fragment CancelSuccessRefundFailedFields on CancelSuccessRefundFailed {
    errorMessage
  }
`;
export const CancelOrderFailedFieldsFragmentDoc = gql`
  fragment CancelOrderFailedFields on CancelOrderFailed {
    errorMessage
  }
`;
export const CancellationLimitExceededFieldsFragmentDoc = gql`
  fragment CancellationLimitExceededFields on CancellationLimitExceeded {
    errorMessage
  }
`;
export const CancellationFailedWithDeliveryFieldsFragmentDoc = gql`
  fragment CancellationFailedWithDeliveryFields on CancellationFailedWithDelivery {
    errorMessage
  }
`;
export const OrderAlreadyCanceledFieldsFragmentDoc = gql`
  fragment OrderAlreadyCanceledFields on OrderAlreadyCanceled {
    errorMessage
  }
`;
export const OrderNotFoundFieldsFragmentDoc = gql`
  fragment OrderNotFoundFields on OrderNotFound {
    errorMessage
  }
`;
export const OrderAlreadySentToKitchenFieldsFragmentDoc = gql`
  fragment OrderAlreadySentToKitchenFields on OrderAlreadySentToKitchen {
    errorMessage
  }
`;
export const InvalidGiftCardErrorFieldsFragmentDoc = gql`
  fragment InvalidGiftCardErrorFields on InvalidGiftCardError {
    errorMessage
  }
`;
export const GiftCardAssociatedWithAnotherAccountErrorFieldsFragmentDoc = gql`
  fragment GiftCardAssociatedWithAnotherAccountErrorFields on GiftCardAssociatedWithAnotherAccountError {
    errorMessage
  }
`;
export const UnableToRedeemGiftCardErrorFieldsFragmentDoc = gql`
  fragment UnableToRedeemGiftCardErrorFields on UnableToRedeemGiftCardError {
    errorMessage
  }
`;
export const GiftCardBalanceResponseFieldsFragmentDoc = gql`
  fragment GiftCardBalanceResponseFields on GiftCardBalance {
    customerId
    giftCardBalance
  }
`;
export const NoBalanceGiftCardErrorFieldsFragmentDoc = gql`
  fragment NoBalanceGiftCardErrorFields on NoBalanceGiftCardError {
    errorMessage
  }
`;
export const ChallengeDataFragmentDoc = gql`
  fragment ChallengeData on Challenge {
    achieved
    celebrationCopy
    descriptiveTitle
    expirationDate
    goal
    id
    legalTerms
    percentageCompleted
    permalink
    progress
    progressCopy
    status
    title
    __typename
  }
`;
export const RewardNotAppliedFieldsFragmentDoc = gql`
  fragment RewardNotAppliedFields on RewardNotApplied {
    message
    status
    failureCode
    failureReasons
    failureMetadata {
      requiredChannel
      requiredDays
    }
  }
`;
export const RewardNotRemovedFieldsFragmentDoc = gql`
  fragment RewardNotRemovedFields on RewardNotRemoved {
    message
    status
  }
`;
export const SuccessfulForgotPasswordFieldsFragmentDoc = gql`
  fragment SuccessfulForgotPasswordFields on ForgotPasswordSuccess {
    success
  }
`;
export const SuccessfulLoginFieldsFragmentDoc = gql`
  fragment SuccessfulLoginFields on LoginSuccess {
    csrf
  }
`;
export const InvalidCredentialsFieldsFragmentDoc = gql`
  fragment InvalidCredentialsFields on InvalidCredentials {
    message
    status
  }
`;
export const SuccessfulLogoutFieldsFragmentDoc = gql`
  fragment SuccessfulLogoutFields on LogoutSuccess {
    csrf
  }
`;
export const SuccessfulRegisterFieldsFragmentDoc = gql`
  fragment SuccessfulRegisterFields on RegisterSuccess {
    success
  }
`;
export const ResendVerificationCodeSuccessFieldsFragmentDoc = gql`
  fragment ResendVerificationCodeSuccessFields on ResendVerificationCodeSuccess {
    success
  }
`;
export const AddressFragmentDoc = gql`
  fragment address on Address {
    id
    street
    secondaryStreet
    name
    city
    state
    zipCode
    country
    deliveryPreference
    googlePlaceId
    latitude
    longitude
    notes
  }
`;
export const BillingAccountFragmentDoc = gql`
  fragment billingAccount on BillingAccount {
    __typename
    id
    description
    nickname
    isDefault
    cardType
    expirationState
  }
`;
export const CustomerCreditsFragmentDoc = gql`
  fragment customerCredits on Credit {
    id
    title
    description
    amount
    remainingAmount
    createdAt
    expiresAt
  }
`;
export const CustomerDataFragmentDoc = gql`
  fragment CustomerData on Customer {
    id
    trackingUuid
    firstName
    lastName
    phoneNumber
    email
    addresses {
      ...address
    }
    billingAccounts {
      ...billingAccount
    }
    useCredit
    availableCredit
    credits {
      ...customerCredits
    }
    deletionInProgress
  }
  ${AddressFragmentDoc}
  ${BillingAccountFragmentDoc}
  ${CustomerCreditsFragmentDoc}
`;
export const VerifyRegistrationSuccessFieldsFragmentDoc = gql`
  fragment VerifyRegistrationSuccessFields on VerifyRegistrationSuccess {
    csrf
    customer {
      ...CustomerData
    }
  }
  ${CustomerDataFragmentDoc}
`;
export const InvalidRegistrationCodeFieldsFragmentDoc = gql`
  fragment InvalidRegistrationCodeFields on InvalidRegistrationCode {
    message
    status
  }
`;
export const ValidationErrorFieldsFragmentDoc = gql`
  fragment ValidationErrorFields on ValidationError {
    message
    status
    fieldErrors {
      field
      message
    }
  }
`;
export const InvalidPromoCodeFieldsFragmentDoc = gql`
  fragment InvalidPromoCodeFields on InvalidPromoCode {
    message
    status
  }
`;
export const SuccessfulLogoutCustomerFieldsFragmentDoc = gql`
  fragment SuccessfulLogoutCustomerFields on LogoutSuccess {
    csrf
  }
`;
export const AddSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc = gql`
  fragment AddSubscriptionPaymentMethodSuccessResponseFields on AddSubscriptionPaymentMethodSuccessResponse {
    paymentMethodToken
    creditCard {
      id
      cardType
      lastFour
      expirationMonth
      expirationYear
      isActive
    }
    __typename
  }
`;
export const UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc = gql`
  fragment UpdateSubscriptionPaymentMethodSuccessResponseFields on UpdateSubscriptionPaymentMethodSuccessResponse {
    subscription {
      id
    }
    __typename
  }
`;
export const DeleteSubscriptionPaymentMethodSuccessFieldsFragmentDoc = gql`
  fragment DeleteSubscriptionPaymentMethodSuccessFields on DeleteSubscriptionPaymentMethodSuccess {
    creditCards {
      id
      cardType
      lastFour
      __typename
    }
    __typename
  }
`;
export const PaymentMethodAlreadyExistsErrorFieldsFragmentDoc = gql`
  fragment PaymentMethodAlreadyExistsErrorFields on PaymentMethodAlreadyExistsError {
    errorMessage
    __typename
  }
`;
export const ActivePaymentMethodErrorFieldsFragmentDoc = gql`
  fragment ActivePaymentMethodErrorFields on ActivePaymentMethodError {
    errorMessage
    __typename
  }
`;
export const UnknownUserErrorFieldsFragmentDoc = gql`
  fragment UnknownUserErrorFields on UnknownUserError {
    errorMessage
    __typename
  }
`;
export const UserErrorFieldsFragmentDoc = gql`
  fragment UserErrorFields on UserError {
    errorMessage
    __typename
  }
`;
export const InvalidPaymentMethodFieldsFragmentDoc = gql`
  fragment InvalidPaymentMethodFields on InvalidPaymentMethod {
    errorMessage
    __typename
  }
`;
export const SubmitOrderUsingPaymentMethodSuccessFragmentDoc = gql`
  fragment SubmitOrderUsingPaymentMethodSuccess on SubmitOrderUsingPaymentMethodSuccess {
    order {
      id
    }
  }
`;
export const SubmitOrderSuccessFragmentDoc = gql`
  fragment SubmitOrderSuccess on SubmitOrderUsingPaymentMethodCardSuccess {
    order {
      id
    }
  }
`;
export const RewardNotValidatedFieldsFragmentDoc = gql`
  fragment RewardNotValidatedFields on RewardNotValidated {
    message
    status
    failureCode
  }
`;
export const ProductUnavailableFieldsFragmentDoc = gql`
  fragment ProductUnavailableFields on ProductUnavailable {
    message
    status
  }
`;
export const RestaurantIsNotAcceptingOrdersFieldsFragmentDoc = gql`
  fragment RestaurantIsNotAcceptingOrdersFields on RestaurantIsNotAcceptingOrders {
    message
    status
  }
`;
export const InvalidTipFieldsFragmentDoc = gql`
  fragment InvalidTipFields on InvalidTip {
    message
    status
  }
`;
export const GiftCardOrderDetailFragmentDoc = gql`
  fragment GiftCardOrderDetail on GiftCardOrderDetail {
    id
    purchasedAt
    deliveryDetail {
      deliveryMethods
      isAsap
      deliveryDateTime
    }
    userGiftCards {
      id
      balance
      assetId
      recipientEmail
      recipientName
      message
    }
  }
`;
export const SubmitGiftCardOrderErrorFragmentDoc = gql`
  fragment SubmitGiftCardOrderError on SubmitGiftCardOrderError {
    errorMessage
  }
`;
export const GetOrderStatusFieldsFragmentDoc = gql`
  fragment GetOrderStatusFields on OrderStatus {
    __typename
    flattenedOrderStatus
    order {
      id
      wantedTime
      orderType
      canTrackOrderStatus
      isPendingFeedback
      dropoffLocation
      restaurant {
        id
        name
        isOutpost
      }
      deliveryOrderDetail {
        estimatedDeliveryTime
        address {
          id
          name
        }
      }
    }
  }
`;
export const LogInCustomerSuccessfulLoginFieldsFragmentDoc = gql`
  fragment LogInCustomerSuccessfulLoginFields on LoginSuccess {
    csrf
  }
`;
export const LogInCustomerInvalidCredentialsFieldsFragmentDoc = gql`
  fragment LogInCustomerInvalidCredentialsFields on InvalidCredentials {
    message
    status
  }
`;
export const CategoryProductFragmentDoc = gql`
  fragment CategoryProduct on Product {
    asset {
      url
    }
    calories
    categoryId
    cost: channelCost(costChannel: $costChannel)
    description
    id
    ingredients {
      asset {
        url
      }
      id
      name
    }
    isModifiable
    isCustom
    customType
    isSalad
    label {
      id
      name
    }
    dietaryProperties {
      id
      name
    }
    name
    outOfStock
    restaurantId
    slug
    throttleItem
  }
`;
export const CategoryFragmentDoc = gql`
  fragment Category on MenuCategory {
    id
    name
    isCustom
    products {
      ...CategoryProduct
    }
  }
  ${CategoryProductFragmentDoc}
`;
export const RestaurantMenuFragmentDoc = gql`
  fragment RestaurantMenu on Restaurant {
    id
    slug
    name
    city
    state
    zipCode
    address
    phone
    isOutpost
    deliveryFee
    showDeliveryFeeDisclosure
    hours {
      formatted
    }
    isAcceptingOrders
    notAcceptingOrdersReason
    deliveryFee
    flexMessage
    menu {
      id
      categories {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`;
export const BaseProductDataFragmentDoc = gql`
  fragment BaseProductData on BaseProduct {
    id
    name
    description
    heading
    subheading
    isSeasonal
    isDeepLinkingEnabled
    asset {
      url
    }
  }
`;
export const OrderProductRestaurantDataFragmentDoc = gql`
  fragment OrderProductRestaurantData on Restaurant {
    id
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    hours {
      formatted
    }
    flexMessage
    isAcceptingOrders
    notAcceptingOrdersReason
    asset {
      url
    }
  }
`;
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    id
    orderType
    wantedTime
    description
    total
    lineItems {
      id
      customName
      isCustom
      product {
        id
        name
        isCustom
        categoryName
      }
    }
    asset {
      url
    }
    restaurant {
      id
      name
      isOutpost
    }
    deliveryOrderDetail {
      id
      address {
        id
        name
      }
    }
  }
`;
export const PdpLineItemDetailsFragmentDoc = gql`
  fragment PDPLineItemDetails on LineItem {
    id
    quantity
    isCustom
    customName
    ingredients {
      id
      name
      asset {
        url
      }
      dietaryProperties {
        id
        name
      }
    }
    mixedDressingDetails {
      ingredientId
      weight
    }
  }
`;
export const LineItemSuccessFieldsFragmentDoc = gql`
  fragment LineItemSuccessFields on LineItemSuccess {
    lineItem {
      ...PDPLineItemDetails
    }
  }
  ${PdpLineItemDetailsFragmentDoc}
`;
export const LineItemNotFoundFieldsFragmentDoc = gql`
  fragment LineItemNotFoundFields on LineItemNotFound {
    message
    status
  }
`;
export const ProductIngredientFragmentDoc = gql`
  fragment ProductIngredient on Ingredient {
    id
    name
    asset {
      url
    }
    dietaryProperties {
      id
      name
    }
  }
`;
export const IngredientModificationFragmentDoc = gql`
  fragment IngredientModification on IngredientModification {
    calories
    removalCost
    additionCost
    substitutionCost
    kind
    subKind
    outOfStock
    ingredient {
      ...ProductIngredient
    }
    mixable
    isGrain
  }
  ${ProductIngredientFragmentDoc}
`;
export const IngredientModificationKindFragmentDoc = gql`
  fragment IngredientModificationKind on IngredientModificationKind {
    minAggregateQuantity
    maxAggregateQuantity
    freeAggregateQuantity
    allowMultipleQuantity
    modifications {
      ...IngredientModification
    }
  }
  ${IngredientModificationFragmentDoc}
`;
export const ProductDetailsFragmentDoc = gql`
  fragment ProductDetails on Product {
    id
    slug
    restaurantId
    name
    categoryId
    description
    cost: channelCost(costChannel: $costChannel)
    calories
    outOfStock
    throttleItem
    maxModifications
    isModifiable
    isCustom
    asset {
      url
    }
    restaurant {
      id
      isOutpost
    }
    dietaryProperties {
      id
      name
    }
    ingredients {
      ...ProductIngredient
    }
    ingredientModifications {
      bases {
        ...IngredientModificationKind
      }
      toppings {
        ...IngredientModificationKind
      }
      premiums {
        ...IngredientModificationKind
      }
      dressings {
        ...IngredientModificationKind
      }
      bread {
        ...IngredientModificationKind
      }
    }
  }
  ${ProductIngredientFragmentDoc}
  ${IngredientModificationKindFragmentDoc}
`;
export const ReorderSuccessResponseFragmentDoc = gql`
  fragment ReorderSuccessResponse on ReorderSuccess {
    cart {
      ...CartData
    }
    isMixingSupported
  }
  ${CartDataFragmentDoc}
`;
export const ConflictFragmentDoc = gql`
  fragment Conflict on OrderConflict {
    type
    product {
      id
      name
      calories
      cost
      label {
        name
      }
      description
      asset {
        url
      }
      dietaryProperties {
        id
        name
      }
    }
    unavailableIngredients {
      name
    }
  }
`;
export const ReorderConflictResponseFragmentDoc = gql`
  fragment ReorderConflictResponse on ReorderConflict {
    isNothingAvailable
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc = gql`
  fragment RestaurantMaxDeliveryQuantityExceededResponse on RestaurantMaxDeliveryQuantityExceeded {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const RestaurantMaxQuantityExceededResponseFragmentDoc = gql`
  fragment RestaurantMaxQuantityExceededResponse on RestaurantMaxQuantityExceeded {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const CanNotReorderResponseFragmentDoc = gql`
  fragment CanNotReorderResponse on CanNotReorder {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const ValidationErrorResponseFragmentDoc = gql`
  fragment ValidationErrorResponse on ValidationError {
    message
    status
    fieldErrors {
      field
      message
    }
  }
`;
export const ReorderRestaurantRequiredDataFragmentDoc = gql`
  fragment ReorderRestaurantRequiredData on Restaurant {
    id
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    hours {
      formatted
    }
    flexMessage
    isAcceptingOrders
    notAcceptingOrdersReason
    asset {
      url
    }
  }
`;
export const ChallengeRewardFieldsFragmentDoc = gql`
  fragment ChallengeRewardFields on ChallengeReward {
    id
    name
    expiresAt
    terms
    rewardType
    redeemable
    redeemableAt
  }
`;
export const LoyaltyProfileDataFragmentDoc = gql`
  fragment LoyaltyProfileData on LoyaltyProfile {
    customerId
    joinDate
    hasPaidTierHistory
    tierStatus {
      id
      name
    }
    trialEligible {
      duration
      durationUnit
    }
    tier {
      id
      name
      nextTier {
        id
        name
      }
    }
  }
`;
export const SubscriptionCreditCardFragmentFragmentDoc = gql`
  fragment SubscriptionCreditCardFragment on SubscriptionCreditCard {
    id
    cardType
    lastFour
    expirationMonth
    expirationYear
    isActive
  }
`;
export const PaymentSubscriptionProfileDataFragmentDoc = gql`
  fragment PaymentSubscriptionProfileData on PaymentSubscriptionProfile {
    id
    creditCards {
      ...SubscriptionCreditCardFragment
      expirationState
    }
  }
  ${SubscriptionCreditCardFragmentFragmentDoc}
`;
export const CustomerNameDocument = gql`
  query customerName {
    currentCustomer {
      id
      firstName
    }
  }
`;

export function useCustomerNameQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerNameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerNameQuery, CustomerNameQueryVariables>({
    query: CustomerNameDocument,
    ...options,
  });
}
export const AddAddressDocument = gql`
  mutation addAddress($input: AddAddressInput!) {
    addAddress(input: $input) {
      __typename
      ... on AddAddressSuccess {
        address {
          id
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useAddAddressMutation() {
  return Urql.useMutation<AddAddressMutation, AddAddressMutationVariables>(
    AddAddressDocument,
  );
}
export const UpdateAddressDocument = gql`
  mutation updateAddress($input: EditAddressInput!) {
    updateAddress(input: $input) {
      __typename
      ... on UpdateAddressSuccess {
        address {
          id
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useUpdateAddressMutation() {
  return Urql.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument);
}
export const DeleteAddressDocument = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(addressId: $id) {
      __typename
      ... on DeleteAddressSuccess {
        success
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${ValidationErrorResponseFragmentDoc}
`;

export function useDeleteAddressMutation() {
  return Urql.useMutation<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >(DeleteAddressDocument);
}
export const PaymentProfileDocument = gql`
  query PaymentProfile {
    paymentProfile {
      id
      defaultCardId
    }
  }
`;

export function usePaymentProfileQuery(
  options?: Omit<Urql.UseQueryArgs<PaymentProfileQueryVariables>, 'query'>,
) {
  return Urql.useQuery<PaymentProfileQuery, PaymentProfileQueryVariables>({
    query: PaymentProfileDocument,
    ...options,
  });
}
export const SetDefaultPaymentMethodDocument = gql`
  mutation SetDefaultPaymentMethod($paymentMethodId: String!) {
    setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      __typename
      id
      defaultCardId
    }
  }
`;

export function useSetDefaultPaymentMethodMutation() {
  return Urql.useMutation<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
  >(SetDefaultPaymentMethodDocument);
}
export const CartDocument = gql`
  query Cart {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;

export function useCartQuery(
  options?: Omit<Urql.UseQueryArgs<CartQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CartQuery, CartQueryVariables>({
    query: CartDocument,
    ...options,
  });
}
export const AddLineItemToCartDocument = gql`
  mutation AddLineItemToCart($input: AddLineItemToCartInput!) {
    addLineItemToCart(input: $input) {
      __typename
      ...AddLineItemToCartSuccessFields
      ...RestaurantMaxQuantityExceededFields
      ...RestaurantMaxDeliveryQuantityExceededFields
      ...MaxModificationsExceededFields
      ...ValidationErrorFields
      ...ProductOutOfStockFields
    }
  }
  ${AddLineItemToCartSuccessFieldsFragmentDoc}
  ${RestaurantMaxQuantityExceededFieldsFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc}
  ${MaxModificationsExceededFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${ProductOutOfStockFieldsFragmentDoc}
`;

export function useAddLineItemToCartMutation() {
  return Urql.useMutation<
    AddLineItemToCartMutation,
    AddLineItemToCartMutationVariables
  >(AddLineItemToCartDocument);
}
export const EditLineItemInCartDocument = gql`
  mutation EditLineItemInCart($input: EditLineItemInCartInput!) {
    editLineItemInCart(input: $input) {
      __typename
      ...EditLineItemInCartSuccessFields
      ...RestaurantMaxQuantityExceededFields
      ...RestaurantMaxDeliveryQuantityExceededFields
      ...MaxModificationsExceededFields
      ...ValidationErrorFields
    }
  }
  ${EditLineItemInCartSuccessFieldsFragmentDoc}
  ${RestaurantMaxQuantityExceededFieldsFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc}
  ${MaxModificationsExceededFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useEditLineItemInCartMutation() {
  return Urql.useMutation<
    EditLineItemInCartMutation,
    EditLineItemInCartMutationVariables
  >(EditLineItemInCartDocument);
}
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      __typename
      ...RemoveFromCartSuccessFields
      ...ValidationErrorFields
    }
  }
  ${RemoveFromCartSuccessFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useRemoveFromCartMutation() {
  return Urql.useMutation<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >(RemoveFromCartDocument);
}
export const AddonsDocument = gql`
  query addons($costChannel: CostChannel) {
    addons {
      products {
        id
        name
        cost: channelCost(costChannel: $costChannel)
        asset {
          url
        }
      }
    }
  }
`;

export function useAddonsQuery(
  options?: Omit<Urql.UseQueryArgs<AddonsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AddonsQuery, AddonsQueryVariables>({
    query: AddonsDocument,
    ...options,
  });
}
export const ChallengeCelebrationDocument = gql`
  query ChallengeCelebration {
    challengeCelebration {
      title
      celebrationCopy
      rewardUsageDisclaimer
      expirationDate
      challengesIds
    }
  }
`;

export function useChallengeCelebrationQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeCelebrationQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeCelebrationQuery,
    ChallengeCelebrationQueryVariables
  >({ query: ChallengeCelebrationDocument, ...options });
}
export const ChallengeCelebrationModalDocument = gql`
  query ChallengeCelebrationModal {
    challengeCelebrationV2 {
      title
      celebrationCopy
      expirationDate
      challengesIds
    }
  }
`;

export function useChallengeCelebrationModalQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeCelebrationModalQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeCelebrationModalQuery,
    ChallengeCelebrationModalQueryVariables
  >({ query: ChallengeCelebrationModalDocument, ...options });
}
export const GiftCardBalanceDocument = gql`
  query GiftCardBalance {
    giftCardBalance {
      __typename
      ...UnableToGetGiftCardBalanceErrorFields
      ...GiftCardBalanceFields
    }
  }
  ${UnableToGetGiftCardBalanceErrorFieldsFragmentDoc}
  ${GiftCardBalanceFieldsFragmentDoc}
`;

export function useGiftCardBalanceQuery(
  options?: Omit<Urql.UseQueryArgs<GiftCardBalanceQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GiftCardBalanceQuery, GiftCardBalanceQueryVariables>({
    query: GiftCardBalanceDocument,
    ...options,
  });
}
export const PickupOutpostRestaurantNameDocument = gql`
  query PickupOutpostRestaurantName($id: ID!) {
    restaurant(id: $id) {
      id
      name
    }
  }
`;

export function usePickupOutpostRestaurantNameQuery(
  options: Omit<
    Urql.UseQueryArgs<PickupOutpostRestaurantNameQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    PickupOutpostRestaurantNameQuery,
    PickupOutpostRestaurantNameQueryVariables
  >({ query: PickupOutpostRestaurantNameDocument, ...options });
}
export const ActiveCartDeliveryDetailsDocument = gql`
  query ActiveCartDeliveryDetails {
    cart {
      id
      deliveryOrderDetail {
        id
        address {
          id
          googlePlaceId
          name
        }
      }
    }
  }
`;

export function useActiveCartDeliveryDetailsQuery(
  options?: Omit<
    Urql.UseQueryArgs<ActiveCartDeliveryDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ActiveCartDeliveryDetailsQuery,
    ActiveCartDeliveryDetailsQueryVariables
  >({ query: ActiveCartDeliveryDetailsDocument, ...options });
}
export const AddDeliveryAddressDocument = gql`
  mutation addDeliveryAddress($input: AddAddressInput!) {
    addAddress(input: $input) {
      __typename
      ... on AddAddressSuccess {
        address {
          id
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useAddDeliveryAddressMutation() {
  return Urql.useMutation<
    AddDeliveryAddressMutation,
    AddDeliveryAddressMutationVariables
  >(AddDeliveryAddressDocument);
}
export const CustomerAddressesDocument = gql`
  query CustomerAddresses {
    currentCustomer {
      id
      addresses {
        id
        street
        secondaryStreet
        city
        state
        country
        zipCode
        deliveryPreference
        googlePlaceId
        latitude
        longitude
        name
        notes
      }
    }
  }
`;

export function useCustomerAddressesQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerAddressesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerAddressesQuery, CustomerAddressesQueryVariables>(
    { query: CustomerAddressesDocument, ...options },
  );
}
export const DeliveryLocationByAddressDocument = gql`
  query DeliveryLocationByAddress($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantDetails
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantDetailsFragmentDoc}
`;

export function useDeliveryLocationByAddressQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryLocationByAddressQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryLocationByAddressQuery,
    DeliveryLocationByAddressQueryVariables
  >({ query: DeliveryLocationByAddressDocument, ...options });
}
export const DeliveryLocationByAddressWithDisclosureFieldsDocument = gql`
  query DeliveryLocationByAddressWithDisclosureFields(
    $input: LocationByAddressInput!
  ) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantDetails
          showDeliveryPriceDifferenciationDisclosure
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantDetailsFragmentDoc}
`;

export function useDeliveryLocationByAddressWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryLocationByAddressWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryLocationByAddressWithDisclosureFieldsQuery,
    DeliveryLocationByAddressWithDisclosureFieldsQueryVariables
  >({
    query: DeliveryLocationByAddressWithDisclosureFieldsDocument,
    ...options,
  });
}
export const LastPlacedOrderDetailsDocument = gql`
  query LastPlacedOrderDetails($input: OrdersInput!) {
    orders(input: $input) {
      ... on OrdersResponseSuccess {
        __typename
        orders {
          id
          orderType
          restaurant {
            id
            name
            isOutpost
          }
          deliveryOrderDetail {
            id
          }
        }
      }
    }
  }
`;

export function useLastPlacedOrderDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<LastPlacedOrderDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LastPlacedOrderDetailsQuery,
    LastPlacedOrderDetailsQueryVariables
  >({ query: LastPlacedOrderDetailsDocument, ...options });
}
export const LocationsSearchByAreaDocument = gql`
  query LocationsSearchByArea(
    $topLeft: GeoCoordinates!
    $bottomRight: GeoCoordinates!
    $showHidden: Boolean
  ) {
    searchLocationsByBoundingBox(
      topLeft: $topLeft
      bottomRight: $bottomRight
      showHidden: $showHidden
    ) {
      location {
        ...LocationDetails
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchByAreaQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchByAreaQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchByAreaQuery,
    LocationsSearchByAreaQueryVariables
  >({ query: LocationsSearchByAreaDocument, ...options });
}
export const LocationsSearchByAreaWithDisclosureFieldsDocument = gql`
  query LocationsSearchByAreaWithDisclosureFields(
    $topLeft: GeoCoordinates!
    $bottomRight: GeoCoordinates!
    $showHidden: Boolean
  ) {
    searchLocationsByBoundingBox(
      topLeft: $topLeft
      bottomRight: $bottomRight
      showHidden: $showHidden
    ) {
      location {
        ...LocationDetails
        outpostPriceDifferentiationEnabled
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchByAreaWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchByAreaWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchByAreaWithDisclosureFieldsQuery,
    LocationsSearchByAreaWithDisclosureFieldsQueryVariables
  >({ query: LocationsSearchByAreaWithDisclosureFieldsDocument, ...options });
}
export const LocationsSearchBySearchStringDocument = gql`
  query LocationsSearchBySearchString(
    $searchString: String!
    $showHidden: Boolean
  ) {
    searchLocationsByString(
      searchString: $searchString
      showHidden: $showHidden
    ) {
      score
      location {
        ...LocationDetails
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchBySearchStringQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchBySearchStringQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchBySearchStringQuery,
    LocationsSearchBySearchStringQueryVariables
  >({ query: LocationsSearchBySearchStringDocument, ...options });
}
export const LocationsSearchBySearchStringWithDisclosureFieldsDocument = gql`
  query LocationsSearchBySearchStringWithDisclosureFields(
    $searchString: String!
    $showHidden: Boolean
  ) {
    searchLocationsByString(
      searchString: $searchString
      showHidden: $showHidden
    ) {
      score
      location {
        ...LocationDetails
        outpostPriceDifferentiationEnabled
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchBySearchStringWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchBySearchStringWithDisclosureFieldsQuery,
    LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables
  >({
    query: LocationsSearchBySearchStringWithDisclosureFieldsDocument,
    ...options,
  });
}
export const LineItemRecommendationsDocument = gql`
  query LineItemRecommendations {
    lineItemRecommendations {
      ...RecommendedItem
    }
  }
  ${RecommendedItemFragmentDoc}
`;

export function useLineItemRecommendationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<LineItemRecommendationsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LineItemRecommendationsQuery,
    LineItemRecommendationsQueryVariables
  >({ query: LineItemRecommendationsDocument, ...options });
}
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      __typename
      ...CancelOrderSuccessFields
      ...CancelSuccessRefundFailedFields
      ...CancelOrderFailedFields
      ...CancellationLimitExceededFields
      ...CancellationFailedWithDeliveryFields
      ...OrderAlreadyCanceledFields
      ...OrderNotFoundFields
      ...OrderAlreadySentToKitchenFields
    }
  }
  ${CancelOrderSuccessFieldsFragmentDoc}
  ${CancelSuccessRefundFailedFieldsFragmentDoc}
  ${CancelOrderFailedFieldsFragmentDoc}
  ${CancellationLimitExceededFieldsFragmentDoc}
  ${CancellationFailedWithDeliveryFieldsFragmentDoc}
  ${OrderAlreadyCanceledFieldsFragmentDoc}
  ${OrderNotFoundFieldsFragmentDoc}
  ${OrderAlreadySentToKitchenFieldsFragmentDoc}
`;

export function useCancelOrderMutation() {
  return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
  );
}
export const RedeemGiftCardDocument = gql`
  mutation RedeemGiftCard($code: String!, $regCode: String) {
    redeemGiftCard(code: $code, regCode: $regCode) {
      __typename
      ...InvalidGiftCardErrorFields
      ...GiftCardAssociatedWithAnotherAccountErrorFields
      ...UnableToRedeemGiftCardErrorFields
      ...NoBalanceGiftCardErrorFields
      ...GiftCardBalanceResponseFields
    }
  }
  ${InvalidGiftCardErrorFieldsFragmentDoc}
  ${GiftCardAssociatedWithAnotherAccountErrorFieldsFragmentDoc}
  ${UnableToRedeemGiftCardErrorFieldsFragmentDoc}
  ${NoBalanceGiftCardErrorFieldsFragmentDoc}
  ${GiftCardBalanceResponseFieldsFragmentDoc}
`;

export function useRedeemGiftCardMutation() {
  return Urql.useMutation<
    RedeemGiftCardMutation,
    RedeemGiftCardMutationVariables
  >(RedeemGiftCardDocument);
}
export const SweetpassInfoForAccountDeletionDocument = gql`
  query SweetpassInfoForAccountDeletion {
    loyaltyProfile {
      customerId
      tier {
        id
        name
      }
      tierStatus {
        id
        name
      }
    }
    getSubscriptions(status: [ACTIVE]) {
      id
      status
      nextBillingDate
    }
  }
`;

export function useSweetpassInfoForAccountDeletionQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassInfoForAccountDeletionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassInfoForAccountDeletionQuery,
    SweetpassInfoForAccountDeletionQueryVariables
  >({ query: SweetpassInfoForAccountDeletionDocument, ...options });
}
export const RequestAccountDeletionDocument = gql`
  mutation RequestAccountDeletion {
    requestAccountDeletion {
      ... on RequestAccountDeletionSuccess {
        success
        __typename
      }
    }
  }
`;

export function useRequestAccountDeletionMutation() {
  return Urql.useMutation<
    RequestAccountDeletionMutation,
    RequestAccountDeletionMutationVariables
  >(RequestAccountDeletionDocument);
}
export const SweetpassBillingHistoryDocument = gql`
  query SweetpassBillingHistory {
    paymentSubscriptionProfile {
      id
      transactionHistory {
        id
        transactionCreditCard {
          id
          cardType
          lastFour
        }
        date
        amount
        refund
      }
    }
  }
`;

export function useSweetpassBillingHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassBillingHistoryQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassBillingHistoryQuery,
    SweetpassBillingHistoryQueryVariables
  >({ query: SweetpassBillingHistoryDocument, ...options });
}
export const CustomerDocument = gql`
  query customer {
    currentCustomer {
      ...CustomerData
    }
  }
  ${CustomerDataFragmentDoc}
`;

export function useCustomerQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerQuery, CustomerQueryVariables>({
    query: CustomerDocument,
    ...options,
  });
}
export const CreateAccountDocument = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      __typename
      ... on CreateAccountSuccess {
        customer {
          id
        }
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
`;

export function useCreateAccountMutation() {
  return Urql.useMutation<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >(CreateAccountDocument);
}
export const SignInCustomerDocument = gql`
  mutation SignInCustomer {
    signIn {
      __typename
      ... on SignInSuccess {
        customer {
          id
        }
      }
      ... on CustomerNotFound {
        errorMessage
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
      ... on SignInFailed {
        message
        status
      }
    }
  }
`;

export function useSignInCustomerMutation() {
  return Urql.useMutation<
    SignInCustomerMutation,
    SignInCustomerMutationVariables
  >(SignInCustomerDocument);
}
export const ChallengesDocument = gql`
  query Challenges {
    challenges {
      ...ChallengeData
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengesQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ChallengesQuery, ChallengesQueryVariables>({
    query: ChallengesDocument,
    ...options,
  });
}
export const ChallengesWithThemeDocument = gql`
  query ChallengesWithTheme {
    challenges {
      ...ChallengeData
      theme
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengesWithThemeQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengesWithThemeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ChallengesWithThemeQuery,
    ChallengesWithThemeQueryVariables
  >({ query: ChallengesWithThemeDocument, ...options });
}
export const ChallengeOptInDocument = gql`
  mutation challengeOptIn($challengeId: String!) {
    challengeOptIn(challengeId: $challengeId) {
      ...ChallengeData
      theme
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengeOptInMutation() {
  return Urql.useMutation<
    ChallengeOptInMutation,
    ChallengeOptInMutationVariables
  >(ChallengeOptInDocument);
}
export const ChallengeOptInV2Document = gql`
  mutation challengeOptInV2($challengeId: String!) {
    challengeOptInV2(challengeId: $challengeId) {
      __typename
      ... on Challenge {
        ...ChallengeData
        theme
      }
      ... on LoyaltyOptInFailed {
        errorMessage
        __typename
      }
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengeOptInV2Mutation() {
  return Urql.useMutation<
    ChallengeOptInV2Mutation,
    ChallengeOptInV2MutationVariables
  >(ChallengeOptInV2Document);
}
export const HasUnseenChallengesDocument = gql`
  query HasUnseenChallenges {
    hasUnseenChallenges {
      hasUnseenChallenges
    }
  }
`;

export function useHasUnseenChallengesQuery(
  options?: Omit<Urql.UseQueryArgs<HasUnseenChallengesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    HasUnseenChallengesQuery,
    HasUnseenChallengesQueryVariables
  >({ query: HasUnseenChallengesDocument, ...options });
}
export const RewardsDocument = gql`
  query Rewards {
    rewards {
      id
      name
      expirationDate
      rewardType
      redeemable
      redeemableAt
    }
  }
`;

export function useRewardsQuery(
  options?: Omit<Urql.UseQueryArgs<RewardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<RewardsQuery, RewardsQueryVariables>({
    query: RewardsDocument,
    ...options,
  });
}
export const ApplyRewardDocument = gql`
  mutation applyReward($input: ApplyRewardInput!) {
    applyReward(input: $input) {
      __typename
      ... on ApplyRewardSuccess {
        order {
          ...CartData
        }
      }
      ...ValidationErrorFields
      ...RewardNotAppliedFields
    }
  }
  ${CartDataFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotAppliedFieldsFragmentDoc}
`;

export function useApplyRewardMutation() {
  return Urql.useMutation<ApplyRewardMutation, ApplyRewardMutationVariables>(
    ApplyRewardDocument,
  );
}
export const RemoveRewardDocument = gql`
  mutation removeReward($input: RemoveRewardInput!) {
    removeReward(input: $input) {
      __typename
      ... on RemoveRewardSuccess {
        order {
          ...CartData
        }
      }
      ...ValidationErrorFields
      ...RewardNotRemovedFields
    }
  }
  ${CartDataFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotRemovedFieldsFragmentDoc}
`;

export function useRemoveRewardMutation() {
  return Urql.useMutation<RemoveRewardMutation, RemoveRewardMutationVariables>(
    RemoveRewardDocument,
  );
}
export const CurrentCustomerDocument = gql`
  query currentCustomer {
    currentCustomer {
      ...CustomerData
    }
  }
  ${CustomerDataFragmentDoc}
`;

export function useCurrentCustomerQuery(
  options?: Omit<Urql.UseQueryArgs<CurrentCustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CurrentCustomerQuery, CurrentCustomerQueryVariables>({
    query: CurrentCustomerDocument,
    ...options,
  });
}
export const UpdateUseCreditDocument = gql`
  mutation updateUseCredit($useCredit: Boolean!) {
    updateUseCredit(useCredit: $useCredit) {
      __typename
      customer {
        id
        useCredit
      }
    }
  }
`;

export function useUpdateUseCreditMutation() {
  return Urql.useMutation<
    UpdateUseCreditMutation,
    UpdateUseCreditMutationVariables
  >(UpdateUseCreditDocument);
}
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      __typename
      ...SuccessfulForgotPasswordFields
      ...ValidationErrorFields
    }
  }
  ${SuccessfulForgotPasswordFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useForgotPasswordMutation() {
  return Urql.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument);
}
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      __typename
      ...SuccessfulLoginFields
      ...InvalidCredentialsFields
      ...ValidationErrorFields
    }
  }
  ${SuccessfulLoginFieldsFragmentDoc}
  ${InvalidCredentialsFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const LogoutDocument = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      __typename
      ...SuccessfulLogoutFields
      ...ValidationErrorFields
    }
  }
  ${SuccessfulLogoutFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
  );
}
export const RegisterDocument = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      __typename
      ...SuccessfulRegisterFields
      ...ValidationErrorFields
    }
  }
  ${SuccessfulRegisterFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useRegisterMutation() {
  return Urql.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
  );
}
export const ResendVerificationCodeDocument = gql`
  mutation ResendVerificationCode($input: ResendVerificationCodeInput!) {
    resendVerificationCode(input: $input) {
      __typename
      ...ResendVerificationCodeSuccessFields
      ...ValidationErrorFields
    }
  }
  ${ResendVerificationCodeSuccessFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useResendVerificationCodeMutation() {
  return Urql.useMutation<
    ResendVerificationCodeMutation,
    ResendVerificationCodeMutationVariables
  >(ResendVerificationCodeDocument);
}
export const VerifyRegistrationDocument = gql`
  mutation VerifyRegistration($input: VerifyRegistrationInput!) {
    verifyRegistration(input: $input) {
      __typename
      ...VerifyRegistrationSuccessFields
      ...InvalidRegistrationCodeFields
      ...ValidationErrorFields
    }
  }
  ${VerifyRegistrationSuccessFieldsFragmentDoc}
  ${InvalidRegistrationCodeFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useVerifyRegistrationMutation() {
  return Urql.useMutation<
    VerifyRegistrationMutation,
    VerifyRegistrationMutationVariables
  >(VerifyRegistrationDocument);
}
export const OrdersDocument = gql`
  query orders($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
          description
          orderType
          total
          asset {
            url
          }
          lineItems {
            id
            quantity
            customName
            isCustom
            product {
              id
              name
              isCustom
              categoryName
            }
          }
          restaurant {
            id
            name
            city
            state
            address
            zipCode
            isOutpost
            slug
          }
          deliveryOrderDetail {
            ...DeliveryOrderDetail
          }
        }
      }
      ...ValidationErrorFields
    }
  }
  ${DeliveryOrderDetailFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useOrdersQuery(
  options: Omit<Urql.UseQueryArgs<OrdersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrdersQuery, OrdersQueryVariables>({
    query: OrdersDocument,
    ...options,
  });
}
export const CustomerCreditsDocument = gql`
  query CustomerCredits {
    currentCustomer {
      id
      availableCredit
      useCredit
      credits {
        id
        title
        remainingAmount
        expiresAt
        storefrontEligibilityTitle
        creditType
        createdAt
      }
    }
  }
`;

export function useCustomerCreditsQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerCreditsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerCreditsQuery, CustomerCreditsQueryVariables>({
    query: CustomerCreditsDocument,
    ...options,
  });
}
export const DeletePaymentMethodCardDocument = gql`
  mutation deletePaymentMethodCard($input: DeletePaymentMethodCardInput!) {
    deletePaymentMethodCard(input: $input) {
      __typename
      id
    }
  }
`;

export function useDeletePaymentMethodCardMutation() {
  return Urql.useMutation<
    DeletePaymentMethodCardMutation,
    DeletePaymentMethodCardMutationVariables
  >(DeletePaymentMethodCardDocument);
}
export const DeliveryInFlightDocument = gql`
  query deliveryInFlight($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
          isInFlightDelivery
        }
      }
    }
  }
`;

export function useDeliveryInFlightQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryInFlightQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DeliveryInFlightQuery, DeliveryInFlightQueryVariables>({
    query: DeliveryInFlightDocument,
    ...options,
  });
}
export const InStoreOrderLastPendingFeedbackDocument = gql`
  query InStoreOrderLastPendingFeedback {
    inStoreOrderLastPendingFeedback {
      id
      wantedTime
    }
  }
`;

export function useInStoreOrderLastPendingFeedbackQuery(
  options?: Omit<
    Urql.UseQueryArgs<InStoreOrderLastPendingFeedbackQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    InStoreOrderLastPendingFeedbackQuery,
    InStoreOrderLastPendingFeedbackQueryVariables
  >({ query: InStoreOrderLastPendingFeedbackDocument, ...options });
}
export const PaymentMethodCardsDocument = gql`
  query paymentMethodCards {
    paymentMethodCards {
      ... on PaymentMethodBillingAccount {
        __typename
        id
        description
        nickname
        isDefault
        cardType
        expirationState
      }
      ... on PaymentMethodCard {
        __typename
        id
        description
        nickname
        isDefault
        cardType
        expirationState
      }
    }
  }
`;

export function usePaymentMethodCardsQuery(
  options?: Omit<Urql.UseQueryArgs<PaymentMethodCardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    PaymentMethodCardsQuery,
    PaymentMethodCardsQueryVariables
  >({ query: PaymentMethodCardsDocument, ...options });
}
export const SubmitPromoOrGiftCardCodeDocument = gql`
  mutation SubmitPromoOrGiftCardCode($input: SubmitPromoOrGiftCardCodeInput!) {
    submitPromoOrGiftCardCode(input: $input) {
      __typename
      ...ValidationErrorFields
      ...InvalidPromoCodeFields
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
  ${InvalidPromoCodeFieldsFragmentDoc}
`;

export function useSubmitPromoOrGiftCardCodeMutation() {
  return Urql.useMutation<
    SubmitPromoOrGiftCardCodeMutation,
    SubmitPromoOrGiftCardCodeMutationVariables
  >(SubmitPromoOrGiftCardCodeDocument);
}
export const LogoutCustomerDocument = gql`
  mutation LogoutCustomer($input: LogoutInput!) {
    logout(input: $input) {
      __typename
      ...SuccessfulLogoutCustomerFields
    }
  }
  ${SuccessfulLogoutCustomerFieldsFragmentDoc}
`;

export function useLogoutCustomerMutation() {
  return Urql.useMutation<
    LogoutCustomerMutation,
    LogoutCustomerMutationVariables
  >(LogoutCustomerDocument);
}
export const GetSessionDocument = gql`
  query getSession {
    session {
      csrf
      isLoggedIn
    }
  }
`;

export function useGetSessionQuery(
  options?: Omit<Urql.UseQueryArgs<GetSessionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetSessionQuery, GetSessionQueryVariables>({
    query: GetSessionDocument,
    ...options,
  });
}
export const SubmitPaymentMethodDocument = gql`
  mutation submitPaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      description
      nickname
      isDefault
      cardType
      expirationState
      __typename
    }
  }
`;

export function useSubmitPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitPaymentMethodMutation,
    SubmitPaymentMethodMutationVariables
  >(SubmitPaymentMethodDocument);
}
export const SubmitBraintreeBillingAccountDocument = gql`
  mutation submitBraintreeBillingAccount(
    $input: SubmitBraintreeBillingAccountInput!
  ) {
    submitBraintreeBillingAccount(input: $input) {
      __typename
      ... on UserError {
        errorMessage
      }
      ... on SubmitBraintreeBillingAccountSuccessResponse {
        billingAccount {
          id
          description
          nickname
          isDefault
          cardType
          expirationState
        }
      }
    }
  }
`;

export function useSubmitBraintreeBillingAccountMutation() {
  return Urql.useMutation<
    SubmitBraintreeBillingAccountMutation,
    SubmitBraintreeBillingAccountMutationVariables
  >(SubmitBraintreeBillingAccountDocument);
}
export const GetPaymentSubscriptionProfileDocument = gql`
  query getPaymentSubscriptionProfile {
    paymentSubscriptionProfile {
      id
      creditCards {
        id
        cardType
        lastFour
      }
    }
  }
`;

export function useGetPaymentSubscriptionProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetPaymentSubscriptionProfileQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetPaymentSubscriptionProfileQuery,
    GetPaymentSubscriptionProfileQueryVariables
  >({ query: GetPaymentSubscriptionProfileDocument, ...options });
}
export const SweetpassNextBillingDateDocument = gql`
  query SweetpassNextBillingDate {
    getSubscriptions(status: [ACTIVE, PAST_DUE]) {
      id
      nextBillingDate
    }
  }
`;

export function useSweetpassNextBillingDateQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassNextBillingDateQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassNextBillingDateQuery,
    SweetpassNextBillingDateQueryVariables
  >({ query: SweetpassNextBillingDateDocument, ...options });
}
export const SweetpassPaymentMethodsDocument = gql`
  query SweetpassPaymentMethods {
    getSubscriptions(status: [ACTIVE, PAST_DUE]) {
      id
      status
    }
    paymentSubscriptionProfile {
      id
      creditCards {
        id
        cardType
        lastFour
        expirationYear
        expirationMonth
        isActive
      }
    }
  }
`;

export function useSweetpassPaymentMethodsQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassPaymentMethodsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassPaymentMethodsQuery,
    SweetpassPaymentMethodsQueryVariables
  >({ query: SweetpassPaymentMethodsDocument, ...options });
}
export const AddSweetpassPaymentMethodDocument = gql`
  mutation AddSweetpassPaymentMethod($nonce: String!) {
    addSubscriptionPaymentMethod(paymentNonce: $nonce) {
      ...AddSubscriptionPaymentMethodSuccessResponseFields
      ...PaymentMethodAlreadyExistsErrorFields
      ...UnknownUserErrorFields
      ...InvalidPaymentMethodFields
    }
  }
  ${AddSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc}
  ${PaymentMethodAlreadyExistsErrorFieldsFragmentDoc}
  ${UnknownUserErrorFieldsFragmentDoc}
  ${InvalidPaymentMethodFieldsFragmentDoc}
`;

export function useAddSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    AddSweetpassPaymentMethodMutation,
    AddSweetpassPaymentMethodMutationVariables
  >(AddSweetpassPaymentMethodDocument);
}
export const UpdateSweetpassPaymentMethodDocument = gql`
  mutation UpdateSweetpassPaymentMethod(
    $subscriptionId: String!
    $paymentMethodId: String!
  ) {
    updateSubscriptionPaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      ...UpdateSubscriptionPaymentMethodSuccessResponseFields
    }
  }
  ${UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc}
`;

export function useUpdateSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    UpdateSweetpassPaymentMethodMutation,
    UpdateSweetpassPaymentMethodMutationVariables
  >(UpdateSweetpassPaymentMethodDocument);
}
export const RemoveSweetpassPaymentMethodDocument = gql`
  mutation RemoveSweetpassPaymentMethod($paymentMethodId: String!) {
    removeSubscriptionPaymentMethod(paymentMethodId: $paymentMethodId) {
      ...DeleteSubscriptionPaymentMethodSuccessFields
      ...ActivePaymentMethodErrorFields
      ...UnknownUserErrorFields
      ...UserErrorFields
    }
  }
  ${DeleteSubscriptionPaymentMethodSuccessFieldsFragmentDoc}
  ${ActivePaymentMethodErrorFieldsFragmentDoc}
  ${UnknownUserErrorFieldsFragmentDoc}
  ${UserErrorFieldsFragmentDoc}
`;

export function useRemoveSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    RemoveSweetpassPaymentMethodMutation,
    RemoveSweetpassPaymentMethodMutationVariables
  >(RemoveSweetpassPaymentMethodDocument);
}
export const SubscriptionPlanByIdDocument = gql`
  query SubscriptionPlanById($planId: ID!) {
    subscriptionPlanById(planId: $planId) {
      id
      price
      hasFreeTrial
      billingFrequencyUnit
    }
  }
`;

export function useSubscriptionPlanByIdQuery(
  options: Omit<Urql.UseQueryArgs<SubscriptionPlanByIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SubscriptionPlanByIdQuery,
    SubscriptionPlanByIdQueryVariables
  >({ query: SubscriptionPlanByIdDocument, ...options });
}
export const SubscriptionPlansByIdsDocument = gql`
  query SubscriptionPlansByIds($planIds: [ID!]!) {
    subscriptionPlansByIds(planIds: $planIds) {
      id
      price
      hasFreeTrial
      billingFrequency
      billingFrequencyUnit
    }
  }
`;

export function useSubscriptionPlansByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<SubscriptionPlansByIdsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SubscriptionPlansByIdsQuery,
    SubscriptionPlansByIdsQueryVariables
  >({ query: SubscriptionPlansByIdsDocument, ...options });
}
export const SubscribeDocument = gql`
  mutation Subscribe(
    $planId: String!
    $paymentId: String
    $campaignId: String
  ) {
    createSubscriptionV2(
      planId: $planId
      paymentId: $paymentId
      campaignId: $campaignId
    ) {
      ... on CreateSubscriptionResult {
        __typename
        id
        trialDuration
        status
        firstBillingDate
        subscriptionAmount
      }
      ... on FreeTrialNotEligible {
        __typename
        errorMessage
      }
    }
  }
`;

export function useSubscribeMutation() {
  return Urql.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
  );
}
export const SweetpassTierDocument = gql`
  query SweetpassTier {
    loyaltyProfile {
      __typename
      customerId
      loyaltyId
      joinDate
      tier {
        id
        name
        nextTier {
          id
          name
        }
      }
      tierStatus {
        id
        name
      }
      trialEligible {
        duration
        durationUnit
      }
    }
  }
`;

export function useSweetpassTierQuery(
  options?: Omit<Urql.UseQueryArgs<SweetpassTierQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SweetpassTierQuery, SweetpassTierQueryVariables>({
    query: SweetpassTierDocument,
    ...options,
  });
}
export const UpdatePaymentMethodDocument = gql`
  mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      description
      nickname
      isDefault
      cardType
      expirationState
      __typename
    }
  }
`;

export function useUpdatePaymentMethodMutation() {
  return Urql.useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument);
}
export const UpdateBillingAccountDocument = gql`
  mutation updateBillingAccount($input: UpdateBillingAccountInput!) {
    updateBillingAccount(input: $input) {
      billingAccount {
        __typename
        id
        description
        nickname
        isDefault
        cardType
        expirationState
      }
    }
  }
`;

export function useUpdateBillingAccountMutation() {
  return Urql.useMutation<
    UpdateBillingAccountMutation,
    UpdateBillingAccountMutationVariables
  >(UpdateBillingAccountDocument);
}
export const KustomerTokenDocument = gql`
  query KustomerToken {
    kustomerToken {
      token
    }
  }
`;

export function useKustomerTokenQuery(
  options?: Omit<Urql.UseQueryArgs<KustomerTokenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<KustomerTokenQuery, KustomerTokenQueryVariables>({
    query: KustomerTokenDocument,
    ...options,
  });
}
export const CreatePaymentIntentDocument = gql`
  mutation CreatePaymentIntent {
    createPaymentIntent
  }
`;

export function useCreatePaymentIntentMutation() {
  return Urql.useMutation<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables
  >(CreatePaymentIntentDocument);
}
export const CategoryUpsellsDocument = gql`
  query CategoryUpsells($restaurantId: String!) {
    categoryUpsells(restaurantId: $restaurantId) {
      id
      name
      categoryRanking
      products {
        id
        name
        cost
        imageUrl
        outOfStock
        menuId
      }
    }
  }
`;

export function useCategoryUpsellsQuery(
  options: Omit<Urql.UseQueryArgs<CategoryUpsellsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CategoryUpsellsQuery, CategoryUpsellsQueryVariables>({
    query: CategoryUpsellsDocument,
    ...options,
  });
}
export const GetAvailableWantedTimesDocument = gql`
  query getAvailableWantedTimes {
    cart {
      id
      availableWantedTimes {
        time
        deliveryOffset
      }
    }
  }
`;

export function useGetAvailableWantedTimesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetAvailableWantedTimesQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetAvailableWantedTimesQuery,
    GetAvailableWantedTimesQueryVariables
  >({ query: GetAvailableWantedTimesDocument, ...options });
}
export const DeliveryEstimateDocument = gql`
  query DeliveryEstimate($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        estimateRange {
          start
          end
        }
      }
    }
  }
`;

export function useDeliveryEstimateQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryEstimateQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DeliveryEstimateQuery, DeliveryEstimateQueryVariables>({
    query: DeliveryEstimateDocument,
    ...options,
  });
}
export const SubmitOrderUsingPaymentMethodCardDocument = gql`
  mutation SubmitOrderUsingPaymentMethodCard(
    $input: SubmitOrderUsingPaymentMethodCardInput!
  ) {
    submitOrderUsingPaymentMethodCard(input: $input) {
      __typename
      ...SubmitOrderSuccess
      ...ValidationErrorFields
      ...RewardNotValidatedFields
      ...ProductUnavailableFields
      ...RestaurantIsNotAcceptingOrdersFields
      ...InvalidTipFields
    }
  }
  ${SubmitOrderSuccessFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotValidatedFieldsFragmentDoc}
  ${ProductUnavailableFieldsFragmentDoc}
  ${RestaurantIsNotAcceptingOrdersFieldsFragmentDoc}
  ${InvalidTipFieldsFragmentDoc}
`;

export function useSubmitOrderUsingPaymentMethodCardMutation() {
  return Urql.useMutation<
    SubmitOrderUsingPaymentMethodCardMutation,
    SubmitOrderUsingPaymentMethodCardMutationVariables
  >(SubmitOrderUsingPaymentMethodCardDocument);
}
export const SubmitOrderUsingPaymentMethodDocument = gql`
  mutation SubmitOrderUsingPaymentMethod(
    $input: SubmitOrderUsingPaymentMethodInput!
  ) {
    submitOrderUsingPaymentMethod(input: $input) {
      __typename
      ...SubmitOrderUsingPaymentMethodSuccess
      ...ValidationErrorFields
      ...RewardNotValidatedFields
      ...ProductUnavailableFields
      ...RestaurantIsNotAcceptingOrdersFields
      ...InvalidTipFields
    }
  }
  ${SubmitOrderUsingPaymentMethodSuccessFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotValidatedFieldsFragmentDoc}
  ${ProductUnavailableFieldsFragmentDoc}
  ${RestaurantIsNotAcceptingOrdersFieldsFragmentDoc}
  ${InvalidTipFieldsFragmentDoc}
`;

export function useSubmitOrderUsingPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitOrderUsingPaymentMethodMutation,
    SubmitOrderUsingPaymentMethodMutationVariables
  >(SubmitOrderUsingPaymentMethodDocument);
}
export const AddressDocument = gql`
  query Address($addressId: ID!) {
    address(addressId: $addressId) {
      id
      street
      secondaryStreet
      city
      state
      country
      zipCode
      deliveryPreference
      googlePlaceId
      latitude
      longitude
      name
      notes
    }
  }
`;

export function useAddressQuery(
  options: Omit<Urql.UseQueryArgs<AddressQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AddressQuery, AddressQueryVariables>({
    query: AddressDocument,
    ...options,
  });
}
export const DeliveryRestaurantDocument = gql`
  query DeliveryRestaurant(
    $input: LocationByAddressInput!
    $costChannel: CostChannel
  ) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantMenu
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantMenuFragmentDoc}
`;

export function useDeliveryRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    DeliveryRestaurantQuery,
    DeliveryRestaurantQueryVariables
  >({ query: DeliveryRestaurantDocument, ...options });
}
export const FavoritesDocument = gql`
  query favorites($input: FavoritedLineItemsInput!) {
    favoritedLineItems(input: $input) {
      id
      slug
      customName
      quantity
      cost
      perItemCost
      favorited
      product {
        id
        name
        calories
        cost
        isCustom
        label {
          id
          name
        }
        description
        asset {
          url
        }
        restaurant {
          id
        }
        dietaryProperties {
          id
          name
        }
      }
      ingredients {
        id
        name
      }
      addedIngredients {
        id
        name
      }
      removedIngredients {
        id
        name
      }
      mixedDressingDetails {
        ingredientId
        weight
      }
      dressingMode
    }
  }
`;

export function useFavoritesQuery(
  options: Omit<Urql.UseQueryArgs<FavoritesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FavoritesQuery, FavoritesQueryVariables>({
    query: FavoritesDocument,
    ...options,
  });
}
export const SubmitGiftCardOrderUsingPaymentMethodDocument = gql`
  mutation SubmitGiftCardOrderUsingPaymentMethod(
    $input: SubmitGiftCardOrderUsingPaymentMethodInput!
  ) {
    submitGiftCardOrderUsingPaymentMethod(input: $input) {
      __typename
      ...GiftCardOrderDetail
      ...SubmitGiftCardOrderError
    }
  }
  ${GiftCardOrderDetailFragmentDoc}
  ${SubmitGiftCardOrderErrorFragmentDoc}
`;

export function useSubmitGiftCardOrderUsingPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitGiftCardOrderUsingPaymentMethodMutation,
    SubmitGiftCardOrderUsingPaymentMethodMutationVariables
  >(SubmitGiftCardOrderUsingPaymentMethodDocument);
}
export const GiftCardPurchasePaymentProfileDocument = gql`
  query GiftCardPurchasePaymentProfile {
    paymentProfile {
      id
      defaultCardId
    }
  }
`;

export function useGiftCardPurchasePaymentProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GiftCardPurchasePaymentProfileQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardPurchasePaymentProfileQuery,
    GiftCardPurchasePaymentProfileQueryVariables
  >({ query: GiftCardPurchasePaymentProfileDocument, ...options });
}
export const GiftCardOrderDetailDocument = gql`
  query GiftCardOrderDetail($orderId: String!) {
    giftCardOrderDetail(orderId: $orderId) {
      __typename
      ... on GiftCardOrderNotFoundError {
        errorMessage
      }
      ... on GiftCardOrderDetail {
        id
        deliveryDetail {
          deliveryDateTime
        }
        userGiftCards {
          id
          balance
          assetId
          recipientEmail
          recipientName
          message
        }
      }
    }
  }
`;

export function useGiftCardOrderDetailQuery(
  options: Omit<Urql.UseQueryArgs<GiftCardOrderDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    GiftCardOrderDetailQuery,
    GiftCardOrderDetailQueryVariables
  >({ query: GiftCardOrderDetailDocument, ...options });
}
export const GiftCardRedemptionDetailDocument = gql`
  query GiftCardRedemptionDetail($giftCardRedemptionDetailId: String!) {
    giftCardRedemptionDetail(id: $giftCardRedemptionDetailId) {
      __typename
      ... on GiftCardRedemptionDetailSuccess {
        balance
        assetId
        cardNumber
        cardRegCode
        recipientName
        recipientMessage
        senderName
        redeemId
      }
    }
  }
`;

export function useGiftCardRedemptionDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GiftCardRedemptionDetailQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardRedemptionDetailQuery,
    GiftCardRedemptionDetailQueryVariables
  >({ query: GiftCardRedemptionDetailDocument, ...options });
}
export const GetOrderStatusDocument = gql`
  query GetOrderStatus {
    orderStatus {
      ...GetOrderStatusFields
    }
  }
  ${GetOrderStatusFieldsFragmentDoc}
`;

export function useGetOrderStatusQuery(
  options?: Omit<Urql.UseQueryArgs<GetOrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>({
    query: GetOrderStatusDocument,
    ...options,
  });
}
export const SignInDocument = gql`
  mutation SignIn {
    signIn {
      __typename
      ... on SignInSuccess {
        customer {
          id
        }
      }
      ... on CustomerNotFound {
        errorMessage
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
      ... on SignInFailed {
        message
        status
      }
    }
  }
`;

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
  );
}
export const LogInCustomerDocument = gql`
  mutation LogInCustomer($input: LoginInput!) {
    login(input: $input) {
      __typename
      ...LogInCustomerSuccessfulLoginFields
      ...LogInCustomerInvalidCredentialsFields
      ...ValidationErrorFields
    }
  }
  ${LogInCustomerSuccessfulLoginFieldsFragmentDoc}
  ${LogInCustomerInvalidCredentialsFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useLogInCustomerMutation() {
  return Urql.useMutation<
    LogInCustomerMutation,
    LogInCustomerMutationVariables
  >(LogInCustomerDocument);
}
export const MenuCartDocument = gql`
  query MenuCart {
    cart {
      id
      lineItems {
        id
      }
      restaurant {
        id
      }
    }
  }
`;

export function useMenuCartQuery(
  options?: Omit<Urql.UseQueryArgs<MenuCartQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MenuCartQuery, MenuCartQueryVariables>({
    query: MenuCartDocument,
    ...options,
  });
}
export const RestaurantDocument = gql`
  query Restaurant($id: ID!, $costChannel: CostChannel) {
    restaurant(id: $id) {
      ...RestaurantMenu
    }
  }
  ${RestaurantMenuFragmentDoc}
`;

export function useRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<RestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<RestaurantQuery, RestaurantQueryVariables>({
    query: RestaurantDocument,
    ...options,
  });
}
export const MenuRedirectDocument = gql`
  query MenuRedirect($id: ID!) {
    restaurant(id: $id) {
      id
    }
  }
`;

export function useMenuRedirectQuery(
  options: Omit<Urql.UseQueryArgs<MenuRedirectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MenuRedirectQuery, MenuRedirectQueryVariables>({
    query: MenuRedirectDocument,
    ...options,
  });
}
export const BaseProductDocument = gql`
  query BaseProduct($slug: String!) {
    baseProduct(slug: $slug) {
      __typename
      ...BaseProductData
    }
  }
  ${BaseProductDataFragmentDoc}
`;

export function useBaseProductQuery(
  options: Omit<Urql.UseQueryArgs<BaseProductQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BaseProductQuery, BaseProductQueryVariables>({
    query: BaseProductDocument,
    ...options,
  });
}
export const OrderProductRestaurantDocument = gql`
  query OrderProductRestaurant($id: ID!) {
    restaurant(id: $id) {
      ...OrderProductRestaurantData
    }
  }
  ${OrderProductRestaurantDataFragmentDoc}
`;

export function useOrderProductRestaurantQuery(
  options: Omit<
    Urql.UseQueryArgs<OrderProductRestaurantQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    OrderProductRestaurantQuery,
    OrderProductRestaurantQueryVariables
  >({ query: OrderProductRestaurantDocument, ...options });
}
export const ProductForRestaurantDocument = gql`
  query ProductForRestaurant(
    $baseProductSlug: String!
    $restaurantId: String!
  ) {
    productForRestaurant(
      baseProductSlug: $baseProductSlug
      restaurantId: $restaurantId
    ) {
      id
      slug
    }
  }
`;

export function useProductForRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<ProductForRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ProductForRestaurantQuery,
    ProductForRestaurantQueryVariables
  >({ query: ProductForRestaurantDocument, ...options });
}
export const OrderStatusDocument = gql`
  query OrderStatus($id: ID!) {
    orderStatus(orderId: $id) {
      __typename
      ... on OrderStatus {
        gravyStatus
        gravyUpdatedAt
        orderOccasion
        orderStatus
        flattenedOrderStatus
        courierDetails {
          name
          phone
          latitude
          longitude
        }
        order {
          id
          description
          dropoffLocation
          orderType
          total
          canTrackOrderStatus
          isPendingFeedback
          wantedTime
          vendorId
          canCancel
          restaurant {
            id
            city
            state
            zipCode
            name
            address
            slug
            latitude
            longitude
            isOutpost
            phone
            availableDropOffLocations {
              id
              name
            }
          }
          ledger {
            ...CartLedger
          }
          lineItems {
            calories
            ...CartLineItem
          }
          deliveryOrderDetail {
            ...DeliveryOrderDetail
          }
        }
      }
      ... on InvalidOrder {
        message
      }
    }
  }
  ${CartLedgerFragmentDoc}
  ${CartLineItemFragmentDoc}
  ${DeliveryOrderDetailFragmentDoc}
`;

export function useOrderStatusQuery(
  options: Omit<Urql.UseQueryArgs<OrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrderStatusQuery, OrderStatusQueryVariables>({
    query: OrderStatusDocument,
    ...options,
  });
}
export const OrderStatusPollingDocument = gql`
  query OrderStatusPolling($id: ID!) {
    orderStatus(orderId: $id) {
      __typename
      ... on OrderStatus {
        flattenedOrderStatus
        courierDetails {
          name
          phone
          latitude
          longitude
        }
        order {
          id
          wantedTime
          canCancel
        }
      }
      ... on InvalidOrder {
        message
      }
    }
  }
`;

export function useOrderStatusPollingQuery(
  options: Omit<Urql.UseQueryArgs<OrderStatusPollingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    OrderStatusPollingQuery,
    OrderStatusPollingQueryVariables
  >({ query: OrderStatusPollingDocument, ...options });
}
export const CanCancelOrderDocument = gql`
  query CanCancelOrder($input: [CanCancelInput!]!) {
    canCancel(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrderQuery(
  options: Omit<Urql.UseQueryArgs<CanCancelOrderQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrderQuery, CanCancelOrderQueryVariables>({
    query: CanCancelOrderDocument,
    ...options,
  });
}
export const FavoriteLineItemDocument = gql`
  mutation FavoriteLineItem($input: FavoriteLineItemInput!) {
    favoriteLineItem(input: $input) {
      __typename
      id
      favorited
    }
  }
`;

export function useFavoriteLineItemMutation() {
  return Urql.useMutation<
    FavoriteLineItemMutation,
    FavoriteLineItemMutationVariables
  >(FavoriteLineItemDocument);
}
export const GiftCardPurchaseHistoryDocument = gql`
  query GiftCardPurchaseHistory {
    giftCardPurchaseHistory {
      __typename
      giftCardOrders {
        id
        purchasedAt
        userGiftCards {
          id
          balance
          recipientEmail
          recipientName
        }
      }
    }
  }
`;

export function useGiftCardPurchaseHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<GiftCardPurchaseHistoryQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardPurchaseHistoryQuery,
    GiftCardPurchaseHistoryQueryVariables
  >({ query: GiftCardPurchaseHistoryDocument, ...options });
}
export const OrderHistoryDocument = gql`
  query orderHistory($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        pagination {
          total
          last
        }
        orders {
          ...OrderFields
          canCancel
        }
      }
      ...ValidationErrorFields
    }
  }
  ${OrderFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useOrderHistoryQuery(
  options: Omit<Urql.UseQueryArgs<OrderHistoryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrderHistoryQuery, OrderHistoryQueryVariables>({
    query: OrderHistoryDocument,
    ...options,
  });
}
export const OrdersV2Document = gql`
  query ordersV2($input: OrdersInputV2!) {
    ordersV2(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        pagination {
          total
          last
        }
        orders {
          ...OrderFields
          isCanceled
        }
      }
      ... on ValidationError {
        fieldErrors {
          field
          message
        }
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`;

export function useOrdersV2Query(
  options: Omit<Urql.UseQueryArgs<OrdersV2QueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrdersV2Query, OrdersV2QueryVariables>({
    query: OrdersV2Document,
    ...options,
  });
}
export const CanCancelOrdersDocument = gql`
  query CanCancelOrders($input: [CanCancelInput!]!) {
    canCancel(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrdersQuery(
  options: Omit<Urql.UseQueryArgs<CanCancelOrdersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrdersQuery, CanCancelOrdersQueryVariables>({
    query: CanCancelOrdersDocument,
    ...options,
  });
}
export const GetLineItemDocument = gql`
  query GetLineItem($input: LineItemInput!) {
    getLineItem: lineItem(input: $input) {
      __typename
      ...LineItemSuccessFields
      ...LineItemNotFoundFields
      ...ValidationErrorFields
    }
  }
  ${LineItemSuccessFieldsFragmentDoc}
  ${LineItemNotFoundFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useGetLineItemQuery(
  options: Omit<Urql.UseQueryArgs<GetLineItemQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetLineItemQuery, GetLineItemQueryVariables>({
    query: GetLineItemDocument,
    ...options,
  });
}
export const GetProductDocument = gql`
  query GetProduct($id: ID!, $costChannel: CostChannel) {
    product(id: $id) {
      ...ProductDetails
    }
  }
  ${ProductDetailsFragmentDoc}
`;

export function useGetProductQuery(
  options: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({
    query: GetProductDocument,
    ...options,
  });
}
export const UpdateCustomerProfileDocument = gql`
  mutation UpdateCustomerProfile($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      __typename
      customer {
        id
        firstName
        lastName
        email
        phoneNumber
        birthday
        __typename
      }
    }
  }
`;

export function useUpdateCustomerProfileMutation() {
  return Urql.useMutation<
    UpdateCustomerProfileMutation,
    UpdateCustomerProfileMutationVariables
  >(UpdateCustomerProfileDocument);
}
export const CustomerProfileFieldsDocument = gql`
  query CustomerProfileFields {
    currentCustomer {
      id
      firstName
      lastName
      email
      phoneNumber
      birthday
      __typename
    }
  }
`;

export function useCustomerProfileFieldsQuery(
  options?: Omit<
    Urql.UseQueryArgs<CustomerProfileFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CustomerProfileFieldsQuery,
    CustomerProfileFieldsQueryVariables
  >({ query: CustomerProfileFieldsDocument, ...options });
}
export const FeedbackQuestionsDocument = gql`
  query feedbackQuestions($orderId: ID!) {
    feedbackQuestions(orderId: $orderId) {
      id
      text
      slug
      responses {
        id
        text
      }
    }
  }
`;

export function useFeedbackQuestionsQuery(
  options: Omit<Urql.UseQueryArgs<FeedbackQuestionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>(
    { query: FeedbackQuestionsDocument, ...options },
  );
}
export const InStoreFeedbackQuestionsDocument = gql`
  query inStoreFeedbackQuestions($orderId: ID!) {
    inStoreFeedbackQuestions(orderId: $orderId) {
      id
      text
      slug
      responses {
        id
        text
      }
    }
  }
`;

export function useInStoreFeedbackQuestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<InStoreFeedbackQuestionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    InStoreFeedbackQuestionsQuery,
    InStoreFeedbackQuestionsQueryVariables
  >({ query: InStoreFeedbackQuestionsDocument, ...options });
}
export const FeedbackOrderStatusDocument = gql`
  query feedbackOrderStatus($orderId: ID!) {
    orderStatus(orderId: $orderId) {
      __typename
      ... on OrderStatus {
        flattenedOrderStatus
        order {
          id
          orderType
          wantedTime
          description
          isPendingFeedback
          restaurant {
            id
            slug
            name
          }
          lineItems {
            id
            quantity
            customName
            product {
              id
              name
              slug
            }
          }
          deliveryOrderDetail {
            id
            estimatedDeliveryTime
          }
        }
      }
    }
  }
`;

export function useFeedbackOrderStatusQuery(
  options: Omit<Urql.UseQueryArgs<FeedbackOrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    FeedbackOrderStatusQuery,
    FeedbackOrderStatusQueryVariables
  >({ query: FeedbackOrderStatusDocument, ...options });
}
export const SubmitFeedbackDocument = gql`
  mutation submitFeedback($input: FeedbackInput!) {
    submitFeedback(input: $input) {
      __typename
      success
    }
  }
`;

export function useSubmitFeedbackMutation() {
  return Urql.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument);
}
export const SubmitInStoreFeedbackDocument = gql`
  mutation submitInStoreFeedback($input: FeedbackInput!) {
    submitInStoreFeedback(input: $input) {
      __typename
      success
    }
  }
`;

export function useSubmitInStoreFeedbackMutation() {
  return Urql.useMutation<
    SubmitInStoreFeedbackMutation,
    SubmitInStoreFeedbackMutationVariables
  >(SubmitInStoreFeedbackDocument);
}
export const ReferralShareUrlDocument = gql`
  query referralShareUrl {
    currentCustomer {
      id
      referralShareUrlEmail
    }
  }
`;

export function useReferralShareUrlQuery(
  options?: Omit<Urql.UseQueryArgs<ReferralShareUrlQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReferralShareUrlQuery, ReferralShareUrlQueryVariables>({
    query: ReferralShareUrlDocument,
    ...options,
  });
}
export const ReorderOrderDocument = gql`
  mutation reorderOrder($input: ReorderInput!) {
    reorderOrder(input: $input) {
      __typename
      ... on ReorderSuccess {
        ...ReorderSuccessResponse
      }
      ... on ReorderConflict {
        ...ReorderConflictResponse
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        ...RestaurantMaxDeliveryQuantityExceededResponse
      }
      ... on RestaurantMaxQuantityExceeded {
        ...RestaurantMaxQuantityExceededResponse
      }
      ... on CanNotReorder {
        ...CanNotReorderResponse
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
    }
  }
  ${ReorderSuccessResponseFragmentDoc}
  ${ReorderConflictResponseFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc}
  ${RestaurantMaxQuantityExceededResponseFragmentDoc}
  ${CanNotReorderResponseFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useReorderOrderMutation() {
  return Urql.useMutation<ReorderOrderMutation, ReorderOrderMutationVariables>(
    ReorderOrderDocument,
  );
}
export const ReorderLineItemDocument = gql`
  mutation reorderLineItem($input: ReorderInput!) {
    reorderLineItem(input: $input) {
      __typename
      ... on ReorderSuccess {
        ...ReorderSuccessResponse
      }
      ... on ReorderConflict {
        ...ReorderConflictResponse
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        ...RestaurantMaxDeliveryQuantityExceededResponse
      }
      ... on RestaurantMaxQuantityExceeded {
        ...RestaurantMaxQuantityExceededResponse
      }
      ... on CanNotReorder {
        ...CanNotReorderResponse
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
    }
  }
  ${ReorderSuccessResponseFragmentDoc}
  ${ReorderConflictResponseFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc}
  ${RestaurantMaxQuantityExceededResponseFragmentDoc}
  ${CanNotReorderResponseFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useReorderLineItemMutation() {
  return Urql.useMutation<
    ReorderLineItemMutation,
    ReorderLineItemMutationVariables
  >(ReorderLineItemDocument);
}
export const ReorderRestaurantDocument = gql`
  query ReorderRestaurant($id: ID!) {
    restaurant(id: $id) {
      ...ReorderRestaurantRequiredData
    }
  }
  ${ReorderRestaurantRequiredDataFragmentDoc}
`;

export function useReorderRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<ReorderRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReorderRestaurantQuery, ReorderRestaurantQueryVariables>(
    { query: ReorderRestaurantDocument, ...options },
  );
}
export const ReorderRestaurantDataDocument = gql`
  query ReorderRestaurantData($id: ID!) {
    restaurant(id: $id) {
      id
      slug
      name
      isOutpost
      deliveryFee
    }
  }
`;

export function useReorderRestaurantDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderRestaurantDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderRestaurantDataQuery,
    ReorderRestaurantDataQueryVariables
  >({ query: ReorderRestaurantDataDocument, ...options });
}
export const ReorderDeliveryRestaurantDataDocument = gql`
  query ReorderDeliveryRestaurantData($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          id
          slug
          name
          isOutpost
          deliveryFee
        }
        vendor {
          name
          restaurantId
        }
      }
    }
  }
`;

export function useReorderDeliveryRestaurantDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderDeliveryRestaurantDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderDeliveryRestaurantDataQuery,
    ReorderDeliveryRestaurantDataQueryVariables
  >({ query: ReorderDeliveryRestaurantDataDocument, ...options });
}
export const ReorderDeliveryAddressDataDocument = gql`
  query ReorderDeliveryAddressData($addressId: ID!) {
    address(addressId: $addressId) {
      id
      name
    }
  }
`;

export function useReorderDeliveryAddressDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderDeliveryAddressDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderDeliveryAddressDataQuery,
    ReorderDeliveryAddressDataQueryVariables
  >({ query: ReorderDeliveryAddressDataDocument, ...options });
}
export const InStoreGiftCardsDocument = gql`
  query InStoreGiftCards {
    inStorePaymentMethods {
      id
      title
      remainingAmount
    }
  }
`;

export function useInStoreGiftCardsQuery(
  options?: Omit<Urql.UseQueryArgs<InStoreGiftCardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<InStoreGiftCardsQuery, InStoreGiftCardsQueryVariables>({
    query: InStoreGiftCardsDocument,
    ...options,
  });
}
export const GetSharedLineItemDocument = gql`
  query GetSharedLineItem($input: LineItemBySlugInput!) {
    lineItemBySlug(input: $input) {
      __typename
      ... on LineItemSuccess {
        lineItem {
          id
          slug
          quantity
          customName
          customerName
          isCustom
          product {
            id
            name
            calories
            cost
            isCustom
            label {
              id
              name
            }
            description
            asset {
              url
            }
            dietaryProperties {
              id
              name
            }
            restaurant {
              id
              slug
              name
            }
          }
          dressingMode
        }
      }
      ...LineItemNotFoundFields
      ...ValidationErrorFields
    }
  }
  ${LineItemNotFoundFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useGetSharedLineItemQuery(
  options: Omit<Urql.UseQueryArgs<GetSharedLineItemQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetSharedLineItemQuery, GetSharedLineItemQueryVariables>(
    { query: GetSharedLineItemDocument, ...options },
  );
}
export const ChallengeRewardsDocument = gql`
  query ChallengeRewards {
    challengeRewards {
      ...ChallengeRewardFields
    }
  }
  ${ChallengeRewardFieldsFragmentDoc}
`;

export function useChallengeRewardsQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengeRewardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ChallengeRewardsQuery, ChallengeRewardsQueryVariables>({
    query: ChallengeRewardsDocument,
    ...options,
  });
}
export const ChallengeRewardsWithRedemptionChannelDocument = gql`
  query ChallengeRewardsWithRedemptionChannel {
    challengeRewards {
      ...ChallengeRewardFields
      redemptionChannel
    }
  }
  ${ChallengeRewardFieldsFragmentDoc}
`;

export function useChallengeRewardsWithRedemptionChannelQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeRewardsWithRedemptionChannelQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeRewardsWithRedemptionChannelQuery,
    ChallengeRewardsWithRedemptionChannelQueryVariables
  >({ query: ChallengeRewardsWithRedemptionChannelDocument, ...options });
}
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      __typename
      ... on CancelSubscriptionSuccessResponse {
        subscription {
          id
          paidThroughDate
        }
      }
    }
  }
`;

export function useCancelSubscriptionMutation() {
  return Urql.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument);
}
export const CancelSubscriptionAtCycleEndDocument = gql`
  mutation CancelSubscriptionAtCycleEnd($subscriptionId: String!) {
    cancelSubscriptionAtCycleEnd(subscriptionId: $subscriptionId) {
      __typename
      ... on CancelSubscriptionAtCycleEndSuccessResponse {
        subscription {
          id
          paidThroughDate
        }
      }
    }
  }
`;

export function useCancelSubscriptionAtCycleEndMutation() {
  return Urql.useMutation<
    CancelSubscriptionAtCycleEndMutation,
    CancelSubscriptionAtCycleEndMutationVariables
  >(CancelSubscriptionAtCycleEndDocument);
}
export const RejoinSubscriptionDocument = gql`
  mutation RejoinSubscription($subscriptionId: String!) {
    rejoinSubscription(subscriptionId: $subscriptionId) {
      __typename
      ... on RejoinSubscriptionSuccessResponse {
        subscription {
          id
          status
        }
      }
      ... on SubscriptionCannotBeReactivatedError {
        errorMessage
      }
      ... on UnknownUserError {
        errorMessage
      }
    }
  }
`;

export function useRejoinSubscriptionMutation() {
  return Urql.useMutation<
    RejoinSubscriptionMutation,
    RejoinSubscriptionMutationVariables
  >(RejoinSubscriptionDocument);
}
export const SweetpassMembershipDocument = gql`
  query SweetpassMembership {
    loyaltyProfile {
      ...LoyaltyProfileData
    }
    getSubscriptions(status: [ACTIVE, PENDING, PAST_DUE]) {
      id
      planId
      status
      paidThroughDate
      nextBillingDate
      nextBillingPeriodAmount
      firstBillingDate
      billingFrequencyUnit
      price
    }
    paymentSubscriptionProfile {
      ...PaymentSubscriptionProfileData
    }
  }
  ${LoyaltyProfileDataFragmentDoc}
  ${PaymentSubscriptionProfileDataFragmentDoc}
`;

export function useSweetpassMembershipQuery(
  options?: Omit<Urql.UseQueryArgs<SweetpassMembershipQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SweetpassMembershipQuery,
    SweetpassMembershipQueryVariables
  >({ query: SweetpassMembershipDocument, ...options });
}
export const UpdateSubscriptionPaymentMethodDocument = gql`
  mutation UpdateSubscriptionPaymentMethod(
    $subscriptionId: String!
    $paymentMethodId: String!
  ) {
    updateSubscriptionPaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      __typename
      ... on UpdateSubscriptionPaymentMethodSuccessResponse {
        subscription {
          id
        }
      }
    }
  }
`;

export function useUpdateSubscriptionPaymentMethodMutation() {
  return Urql.useMutation<
    UpdateSubscriptionPaymentMethodMutation,
    UpdateSubscriptionPaymentMethodMutationVariables
  >(UpdateSubscriptionPaymentMethodDocument);
}
export const FetchPlansByTierIdDocument = gql`
  query FetchPlansByTierId($tierId: ID!) {
    fetchPlansByTierId(tierId: $tierId) {
      braintreePlanId
    }
  }
`;

export function useFetchPlansByTierIdQuery(
  options: Omit<Urql.UseQueryArgs<FetchPlansByTierIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    FetchPlansByTierIdQuery,
    FetchPlansByTierIdQueryVariables
  >({ query: FetchPlansByTierIdDocument, ...options });
}
export const OptInUserDocument = gql`
  mutation OptInUser {
    optInFreeTier
  }
`;

export function useOptInUserMutation() {
  return Urql.useMutation<OptInUserMutation, OptInUserMutationVariables>(
    OptInUserDocument,
  );
}
export const OptInUserV2Document = gql`
  mutation OptInUserV2 {
    optInFreeTierV2 {
      __typename
      customerId
      loyaltyId
      joinDate
      tier {
        id
        name
        nextTier {
          id
          name
        }
      }
      tierStatus {
        id
        name
      }
      trialEligible {
        duration
        durationUnit
      }
    }
  }
`;

export function useOptInUserV2Mutation() {
  return Urql.useMutation<OptInUserV2Mutation, OptInUserV2MutationVariables>(
    OptInUserV2Document,
  );
}
export type WithTypename<T extends { __typename?: any }> = Partial<T> & {
  __typename: NonNullable<T['__typename']>;
};

export type GraphCacheKeysConfig = {
  AccountLocked?: (data: WithTypename<AccountLocked>) => null | string;
  ActivePaymentMethodError?: (
    data: WithTypename<ActivePaymentMethodError>,
  ) => null | string;
  AddAddressSuccess?: (data: WithTypename<AddAddressSuccess>) => null | string;
  AddCardFunctionalityDeprecated?: (
    data: WithTypename<AddCardFunctionalityDeprecated>,
  ) => null | string;
  AddLineItemToCartSuccess?: (
    data: WithTypename<AddLineItemToCartSuccess>,
  ) => null | string;
  AddSubscriptionPaymentMethodSuccessResponse?: (
    data: WithTypename<AddSubscriptionPaymentMethodSuccessResponse>,
  ) => null | string;
  Addons?: (data: WithTypename<Addons>) => null | string;
  Address?: (data: WithTypename<Address>) => null | string;
  ApplyRewardFailureMetadata?: (
    data: WithTypename<ApplyRewardFailureMetadata>,
  ) => null | string;
  ApplyRewardSuccess?: (
    data: WithTypename<ApplyRewardSuccess>,
  ) => null | string;
  Asset?: (data: WithTypename<Asset>) => null | string;
  AvailableWantedTime?: (
    data: WithTypename<AvailableWantedTime>,
  ) => null | string;
  BaseProduct?: (data: WithTypename<BaseProduct>) => null | string;
  BillingAccount?: (data: WithTypename<BillingAccount>) => null | string;
  CXCredit?: (data: WithTypename<CxCredit>) => null | string;
  CanCancel?: (data: WithTypename<CanCancel>) => null | string;
  CanCancelAlreadySent?: (
    data: WithTypename<CanCancelAlreadySent>,
  ) => null | string;
  CanCancelFailed?: (data: WithTypename<CanCancelFailed>) => null | string;
  CanCancelNotFound?: (data: WithTypename<CanCancelNotFound>) => null | string;
  CanCancelUnauthorized?: (
    data: WithTypename<CanCancelUnauthorized>,
  ) => null | string;
  CanNotReorder?: (data: WithTypename<CanNotReorder>) => null | string;
  CancelOrderFailed?: (data: WithTypename<CancelOrderFailed>) => null | string;
  CancelOrderSuccess?: (
    data: WithTypename<CancelOrderSuccess>,
  ) => null | string;
  CancelSubscriptionAtCycleEndSuccessResponse?: (
    data: WithTypename<CancelSubscriptionAtCycleEndSuccessResponse>,
  ) => null | string;
  CancelSubscriptionForCustomerSuccessResponse?: (
    data: WithTypename<CancelSubscriptionForCustomerSuccessResponse>,
  ) => null | string;
  CancelSubscriptionSuccessResponse?: (
    data: WithTypename<CancelSubscriptionSuccessResponse>,
  ) => null | string;
  CancelSuccessRefundFailed?: (
    data: WithTypename<CancelSuccessRefundFailed>,
  ) => null | string;
  CancellationFailedWithDelivery?: (
    data: WithTypename<CancellationFailedWithDelivery>,
  ) => null | string;
  CancellationLimitExceeded?: (
    data: WithTypename<CancellationLimitExceeded>,
  ) => null | string;
  CannotRefundSubscriptionTransactionUserError?: (
    data: WithTypename<CannotRefundSubscriptionTransactionUserError>,
  ) => null | string;
  CantCancelAlreadyCanceled?: (
    data: WithTypename<CantCancelAlreadyCanceled>,
  ) => null | string;
  CantCancelLimitExcceeded?: (
    data: WithTypename<CantCancelLimitExcceeded>,
  ) => null | string;
  CantCancelOldOrders?: (
    data: WithTypename<CantCancelOldOrders>,
  ) => null | string;
  Challenge?: (data: WithTypename<Challenge>) => null | string;
  ChallengeCelebration?: (
    data: WithTypename<ChallengeCelebration>,
  ) => null | string;
  ChallengeCelebrationV2?: (
    data: WithTypename<ChallengeCelebrationV2>,
  ) => null | string;
  ChallengeReward?: (data: WithTypename<ChallengeReward>) => null | string;
  CourierDetails?: (data: WithTypename<CourierDetails>) => null | string;
  CreateAccountSuccess?: (
    data: WithTypename<CreateAccountSuccess>,
  ) => null | string;
  CreateLoyaltyProfileResult?: (
    data: WithTypename<CreateLoyaltyProfileResult>,
  ) => null | string;
  CreateSetupIntent?: (data: WithTypename<CreateSetupIntent>) => null | string;
  CreateSubscriptionResult?: (
    data: WithTypename<CreateSubscriptionResult>,
  ) => null | string;
  Credit?: (data: WithTypename<Credit>) => null | string;
  Customer?: (data: WithTypename<Customer>) => null | string;
  CustomerNotFound?: (data: WithTypename<CustomerNotFound>) => null | string;
  CustomerPass?: (data: WithTypename<CustomerPass>) => null | string;
  CustomerSupportCreditsFailureError?: (
    data: WithTypename<CustomerSupportCreditsFailureError>,
  ) => null | string;
  CustomerSupportCreditsSuccessResponse?: (
    data: WithTypename<CustomerSupportCreditsSuccessResponse>,
  ) => null | string;
  DeclinedPaymentMethod?: (
    data: WithTypename<DeclinedPaymentMethod>,
  ) => null | string;
  DeleteAddressSuccess?: (
    data: WithTypename<DeleteAddressSuccess>,
  ) => null | string;
  DeleteBillingAccountResponse?: (
    data: WithTypename<DeleteBillingAccountResponse>,
  ) => null | string;
  DeleteSubscriptionPaymentMethodSuccess?: (
    data: WithTypename<DeleteSubscriptionPaymentMethodSuccess>,
  ) => null | string;
  DeliveryAlreadyInFlight?: (
    data: WithTypename<DeliveryAlreadyInFlight>,
  ) => null | string;
  DeliveryOrderDetail?: (
    data: WithTypename<DeliveryOrderDetail>,
  ) => null | string;
  DeliverySpecifyAddress?: (
    data: WithTypename<DeliverySpecifyAddress>,
  ) => null | string;
  DietaryProperty?: (data: WithTypename<DietaryProperty>) => null | string;
  Discount?: (data: WithTypename<Discount>) => null | string;
  DropOffLocation?: (data: WithTypename<DropOffLocation>) => null | string;
  EditLineItemInCartSuccess?: (
    data: WithTypename<EditLineItemInCartSuccess>,
  ) => null | string;
  EstimateRange?: (data: WithTypename<EstimateRange>) => null | string;
  FeedbackQuestion?: (data: WithTypename<FeedbackQuestion>) => null | string;
  FeedbackResponse?: (data: WithTypename<FeedbackResponse>) => null | string;
  FeedbackSuccess?: (data: WithTypename<FeedbackSuccess>) => null | string;
  FieldValidationError?: (
    data: WithTypename<FieldValidationError>,
  ) => null | string;
  ForgotPasswordSuccess?: (
    data: WithTypename<ForgotPasswordSuccess>,
  ) => null | string;
  FreeTrialNotEligible?: (
    data: WithTypename<FreeTrialNotEligible>,
  ) => null | string;
  GiftCardAssociatedWithAnotherAccountError?: (
    data: WithTypename<GiftCardAssociatedWithAnotherAccountError>,
  ) => null | string;
  GiftCardBalance?: (data: WithTypename<GiftCardBalance>) => null | string;
  GiftCardDeliveryDetail?: (
    data: WithTypename<GiftCardDeliveryDetail>,
  ) => null | string;
  GiftCardNotFoundError?: (
    data: WithTypename<GiftCardNotFoundError>,
  ) => null | string;
  GiftCardOrderDetail?: (
    data: WithTypename<GiftCardOrderDetail>,
  ) => null | string;
  GiftCardOrderNotFoundError?: (
    data: WithTypename<GiftCardOrderNotFoundError>,
  ) => null | string;
  GiftCardPurchaseHistory?: (
    data: WithTypename<GiftCardPurchaseHistory>,
  ) => null | string;
  GiftCardRedemptionDetailSuccess?: (
    data: WithTypename<GiftCardRedemptionDetailSuccess>,
  ) => null | string;
  Guest?: (data: WithTypename<Guest>) => null | string;
  HasUnseenChallenges?: (
    data: WithTypename<HasUnseenChallenges>,
  ) => null | string;
  InStoreOrder?: (data: WithTypename<InStoreOrder>) => null | string;
  IncorrectPaymentMethod?: (
    data: WithTypename<IncorrectPaymentMethod>,
  ) => null | string;
  Ingredient?: (data: WithTypename<Ingredient>) => null | string;
  IngredientModification?: (
    data: WithTypename<IngredientModification>,
  ) => null | string;
  IngredientModificationKind?: (
    data: WithTypename<IngredientModificationKind>,
  ) => null | string;
  IngredientModifications?: (
    data: WithTypename<IngredientModifications>,
  ) => null | string;
  InvalidAddress?: (data: WithTypename<InvalidAddress>) => null | string;
  InvalidBillingAccount?: (
    data: WithTypename<InvalidBillingAccount>,
  ) => null | string;
  InvalidCredentials?: (
    data: WithTypename<InvalidCredentials>,
  ) => null | string;
  InvalidCustomerPhone?: (
    data: WithTypename<InvalidCustomerPhone>,
  ) => null | string;
  InvalidGiftCardError?: (
    data: WithTypename<InvalidGiftCardError>,
  ) => null | string;
  InvalidInput?: (data: WithTypename<InvalidInput>) => null | string;
  InvalidOrder?: (data: WithTypename<InvalidOrder>) => null | string;
  InvalidPaymentMethod?: (
    data: WithTypename<InvalidPaymentMethod>,
  ) => null | string;
  InvalidPostalCode?: (data: WithTypename<InvalidPostalCode>) => null | string;
  InvalidPromoCode?: (data: WithTypename<InvalidPromoCode>) => null | string;
  InvalidRegistrationCode?: (
    data: WithTypename<InvalidRegistrationCode>,
  ) => null | string;
  InvalidSubmittedBillingAccount?: (
    data: WithTypename<InvalidSubmittedBillingAccount>,
  ) => null | string;
  InvalidTip?: (data: WithTypename<InvalidTip>) => null | string;
  IssueCustomerSupportCreditResult?: (
    data: WithTypename<IssueCustomerSupportCreditResult>,
  ) => null | string;
  Kustomer?: (data: WithTypename<Kustomer>) => null | string;
  Ledger?: (data: WithTypename<Ledger>) => null | string;
  LedgerItem?: (data: WithTypename<LedgerItem>) => null | string;
  LineItem?: (data: WithTypename<LineItem>) => null | string;
  LineItemModificationsInvalidException?: (
    data: WithTypename<LineItemModificationsInvalidException>,
  ) => null | string;
  LineItemNotFound?: (data: WithTypename<LineItemNotFound>) => null | string;
  LineItemRecommendation?: (
    data: WithTypename<LineItemRecommendation>,
  ) => null | string;
  LineItemSuccess?: (data: WithTypename<LineItemSuccess>) => null | string;
  Location?: (data: WithTypename<Location>) => null | string;
  LocationSearchResult?: (
    data: WithTypename<LocationSearchResult>,
  ) => null | string;
  LoginSuccess?: (data: WithTypename<LoginSuccess>) => null | string;
  LogoutSuccess?: (data: WithTypename<LogoutSuccess>) => null | string;
  LoyaltyOptInFailed?: (
    data: WithTypename<LoyaltyOptInFailed>,
  ) => null | string;
  LoyaltyProfile?: (data: WithTypename<LoyaltyProfile>) => null | string;
  MaxModificationsExceeded?: (
    data: WithTypename<MaxModificationsExceeded>,
  ) => null | string;
  Menu?: (data: WithTypename<Menu>) => null | string;
  MenuCategory?: (data: WithTypename<MenuCategory>) => null | string;
  MenuCollection?: (data: WithTypename<MenuCollection>) => null | string;
  MinimumDeliverySubtotalNotMet?: (
    data: WithTypename<MinimumDeliverySubtotalNotMet>,
  ) => null | string;
  MixedDressingDetails?: (
    data: WithTypename<MixedDressingDetails>,
  ) => null | string;
  NoBalanceGiftCardError?: (
    data: WithTypename<NoBalanceGiftCardError>,
  ) => null | string;
  NoValidRestaurants?: (
    data: WithTypename<NoValidRestaurants>,
  ) => null | string;
  OptOutLoyaltyForCustomerSuccessResponse?: (
    data: WithTypename<OptOutLoyaltyForCustomerSuccessResponse>,
  ) => null | string;
  OptOutLoyaltySuccessResponse?: (
    data: WithTypename<OptOutLoyaltySuccessResponse>,
  ) => null | string;
  Order?: (data: WithTypename<Order>) => null | string;
  OrderAlreadyCanceled?: (
    data: WithTypename<OrderAlreadyCanceled>,
  ) => null | string;
  OrderAlreadySentToKitchen?: (
    data: WithTypename<OrderAlreadySentToKitchen>,
  ) => null | string;
  OrderConflict?: (data: WithTypename<OrderConflict>) => null | string;
  OrderNotFound?: (data: WithTypename<OrderNotFound>) => null | string;
  OrderStatus?: (data: WithTypename<OrderStatus>) => null | string;
  OrdersResponseSuccess?: (
    data: WithTypename<OrdersResponseSuccess>,
  ) => null | string;
  Pagination?: (data: WithTypename<Pagination>) => null | string;
  Payment?: (data: WithTypename<Payment>) => null | string;
  PaymentMethodAlreadyExistsError?: (
    data: WithTypename<PaymentMethodAlreadyExistsError>,
  ) => null | string;
  PaymentMethodBillingAccount?: (
    data: WithTypename<PaymentMethodBillingAccount>,
  ) => null | string;
  PaymentMethodCard?: (data: WithTypename<PaymentMethodCard>) => null | string;
  PaymentPlan?: (data: WithTypename<PaymentPlan>) => null | string;
  PaymentProfile?: (data: WithTypename<PaymentProfile>) => null | string;
  PaymentSubscription?: (
    data: WithTypename<PaymentSubscription>,
  ) => null | string;
  PaymentSubscriptionProfile?: (
    data: WithTypename<PaymentSubscriptionProfile>,
  ) => null | string;
  Product?: (data: WithTypename<Product>) => null | string;
  ProductLabel?: (data: WithTypename<ProductLabel>) => null | string;
  ProductOutOfStock?: (data: WithTypename<ProductOutOfStock>) => null | string;
  ProductUnavailable?: (
    data: WithTypename<ProductUnavailable>,
  ) => null | string;
  PromoCodeAlreadyApplied?: (
    data: WithTypename<PromoCodeAlreadyApplied>,
  ) => null | string;
  RefundLoyaltyTransactionSuccessResponse?: (
    data: WithTypename<RefundLoyaltyTransactionSuccessResponse>,
  ) => null | string;
  RefundOrderSuccess?: (
    data: WithTypename<RefundOrderSuccess>,
  ) => null | string;
  RefundResult?: (data: WithTypename<RefundResult>) => null | string;
  RegisterGuestFailed?: (
    data: WithTypename<RegisterGuestFailed>,
  ) => null | string;
  RegisterGuestSuccess?: (
    data: WithTypename<RegisterGuestSuccess>,
  ) => null | string;
  RegisterSuccess?: (data: WithTypename<RegisterSuccess>) => null | string;
  RejoinSubscriptionSuccessResponse?: (
    data: WithTypename<RejoinSubscriptionSuccessResponse>,
  ) => null | string;
  RemoveFromCartSuccess?: (
    data: WithTypename<RemoveFromCartSuccess>,
  ) => null | string;
  RemoveRewardSuccess?: (
    data: WithTypename<RemoveRewardSuccess>,
  ) => null | string;
  ReorderConflict?: (data: WithTypename<ReorderConflict>) => null | string;
  ReorderSuccess?: (data: WithTypename<ReorderSuccess>) => null | string;
  RequestAccountDeletionFailed?: (
    data: WithTypename<RequestAccountDeletionFailed>,
  ) => null | string;
  RequestAccountDeletionSuccess?: (
    data: WithTypename<RequestAccountDeletionSuccess>,
  ) => null | string;
  ResendVerificationCodeSuccess?: (
    data: WithTypename<ResendVerificationCodeSuccess>,
  ) => null | string;
  Restaurant?: (data: WithTypename<Restaurant>) => null | string;
  RestaurantCapacity?: (
    data: WithTypename<RestaurantCapacity>,
  ) => null | string;
  RestaurantCapacitySlot?: (
    data: WithTypename<RestaurantCapacitySlot>,
  ) => null | string;
  RestaurantHours?: (data: WithTypename<RestaurantHours>) => null | string;
  RestaurantIsNotAcceptingOrders?: (
    data: WithTypename<RestaurantIsNotAcceptingOrders>,
  ) => null | string;
  RestaurantMaxDeliveryQuantityExceeded?: (
    data: WithTypename<RestaurantMaxDeliveryQuantityExceeded>,
  ) => null | string;
  RestaurantMaxQuantityExceeded?: (
    data: WithTypename<RestaurantMaxQuantityExceeded>,
  ) => null | string;
  RestaurantStoreHours?: (
    data: WithTypename<RestaurantStoreHours>,
  ) => null | string;
  Reward?: (data: WithTypename<Reward>) => null | string;
  RewardDiscount?: (data: WithTypename<RewardDiscount>) => null | string;
  RewardNotApplied?: (data: WithTypename<RewardNotApplied>) => null | string;
  RewardNotRemoved?: (data: WithTypename<RewardNotRemoved>) => null | string;
  RewardNotValidated?: (
    data: WithTypename<RewardNotValidated>,
  ) => null | string;
  RewardValidateAndPreviewError?: (
    data: WithTypename<RewardValidateAndPreviewError>,
  ) => null | string;
  RewardValidateAndReserveError?: (
    data: WithTypename<RewardValidateAndReserveError>,
  ) => null | string;
  RewardValidationFailureMetadata?: (
    data: WithTypename<RewardValidationFailureMetadata>,
  ) => null | string;
  RewardValidationGeneralError?: (
    data: WithTypename<RewardValidationGeneralError>,
  ) => null | string;
  RewardsValidateAndPreviewSuccess?: (
    data: WithTypename<RewardsValidateAndPreviewSuccess>,
  ) => null | string;
  RewardsValidateAndReserveSuccess?: (
    data: WithTypename<RewardsValidateAndReserveSuccess>,
  ) => null | string;
  Session?: (data: WithTypename<Session>) => null | string;
  SignInFailed?: (data: WithTypename<SignInFailed>) => null | string;
  SignInSuccess?: (data: WithTypename<SignInSuccess>) => null | string;
  StoreLocation?: (data: WithTypename<StoreLocation>) => null | string;
  SubmitBraintreeBillingAccountSuccessResponse?: (
    data: WithTypename<SubmitBraintreeBillingAccountSuccessResponse>,
  ) => null | string;
  SubmitGiftCardCodeSuccess?: (
    data: WithTypename<SubmitGiftCardCodeSuccess>,
  ) => null | string;
  SubmitGiftCardOrderError?: (
    data: WithTypename<SubmitGiftCardOrderError>,
  ) => null | string;
  SubmitOrderUsingBillingAccountSuccess?: (
    data: WithTypename<SubmitOrderUsingBillingAccountSuccess>,
  ) => null | string;
  SubmitOrderUsingPaymentMethodCardSuccess?: (
    data: WithTypename<SubmitOrderUsingPaymentMethodCardSuccess>,
  ) => null | string;
  SubmitOrderUsingPaymentMethodSuccess?: (
    data: WithTypename<SubmitOrderUsingPaymentMethodSuccess>,
  ) => null | string;
  SubmitOrderWithPaymentMethodSuccess?: (
    data: WithTypename<SubmitOrderWithPaymentMethodSuccess>,
  ) => null | string;
  SubmitPromoCodeSuccess?: (
    data: WithTypename<SubmitPromoCodeSuccess>,
  ) => null | string;
  SubmitPromoCodeSuccessV2?: (
    data: WithTypename<SubmitPromoCodeSuccessV2>,
  ) => null | string;
  SubscriptionCannotBeReactivatedError?: (
    data: WithTypename<SubscriptionCannotBeReactivatedError>,
  ) => null | string;
  SubscriptionCreditCard?: (
    data: WithTypename<SubscriptionCreditCard>,
  ) => null | string;
  SubscriptionPlan?: (data: WithTypename<SubscriptionPlan>) => null | string;
  SubscriptionTransactions?: (
    data: WithTypename<SubscriptionTransactions>,
  ) => null | string;
  Tier?: (data: WithTypename<Tier>) => null | string;
  TierGroup?: (data: WithTypename<TierGroup>) => null | string;
  TierStatus?: (data: WithTypename<TierStatus>) => null | string;
  TimeslotUnavailable?: (
    data: WithTypename<TimeslotUnavailable>,
  ) => null | string;
  TooManyRequests?: (data: WithTypename<TooManyRequests>) => null | string;
  TransactionCreditCard?: (
    data: WithTypename<TransactionCreditCard>,
  ) => null | string;
  TrialEligibility?: (data: WithTypename<TrialEligibility>) => null | string;
  UnableToGetGiftCardBalanceError?: (
    data: WithTypename<UnableToGetGiftCardBalanceError>,
  ) => null | string;
  UnableToRedeemGiftCardError?: (
    data: WithTypename<UnableToRedeemGiftCardError>,
  ) => null | string;
  UnknownUserError?: (data: WithTypename<UnknownUserError>) => null | string;
  UpdateAddressSuccess?: (
    data: WithTypename<UpdateAddressSuccess>,
  ) => null | string;
  UpdateBillingAccountResponse?: (
    data: WithTypename<UpdateBillingAccountResponse>,
  ) => null | string;
  UpdateCustomerResponse?: (
    data: WithTypename<UpdateCustomerResponse>,
  ) => null | string;
  UpdateKioskCustomerResponse?: (
    data: WithTypename<UpdateKioskCustomerResponse>,
  ) => null | string;
  UpdateSubscriptionPaymentMethodSuccessResponse?: (
    data: WithTypename<UpdateSubscriptionPaymentMethodSuccessResponse>,
  ) => null | string;
  UpdateUseCreditResponse?: (
    data: WithTypename<UpdateUseCreditResponse>,
  ) => null | string;
  UpsellCategory?: (data: WithTypename<UpsellCategory>) => null | string;
  UpsellCategoryProduct?: (
    data: WithTypename<UpsellCategoryProduct>,
  ) => null | string;
  UserGiftCard?: (data: WithTypename<UserGiftCard>) => null | string;
  ValidateRewardFailureMetadata?: (
    data: WithTypename<ValidateRewardFailureMetadata>,
  ) => null | string;
  ValidationError?: (data: WithTypename<ValidationError>) => null | string;
  Vendor?: (data: WithTypename<Vendor>) => null | string;
  VerifyRegistrationSuccess?: (
    data: WithTypename<VerifyRegistrationSuccess>,
  ) => null | string;
};

export type GraphCacheResolvers = {
  Query?: {
    addons?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Addons> | string
    >;
    address?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAddressArgs,
      WithTypename<Address> | string
    >;
    allLocations?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAllLocationsArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    baseProduct?: GraphCacheResolver<
      WithTypename<Query>,
      QueryBaseProductArgs,
      WithTypename<BaseProduct> | string
    >;
    canCancel?: GraphCacheResolver<
      WithTypename<Query>,
      QueryCanCancelArgs,
      Array<WithTypename<CanCancelResponseUnion> | string>
    >;
    cart?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    categoryUpsells?: GraphCacheResolver<
      WithTypename<Query>,
      QueryCategoryUpsellsArgs,
      Array<WithTypename<UpsellCategory> | string>
    >;
    challengeCelebration?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<ChallengeCelebration> | string
    >;
    challengeCelebrationV2?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<ChallengeCelebrationV2> | string
    >;
    challengeRewards?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<ChallengeReward> | string>
    >;
    challenges?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Challenge> | string>
    >;
    currentCustomer?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
    customerPass?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<CustomerPass> | string
    >;
    customerSupportCredits?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<CustomerSupportCreditsResponseUnion> | string
    >;
    favoritedLineItems?: GraphCacheResolver<
      WithTypename<Query>,
      QueryFavoritedLineItemsArgs,
      Array<WithTypename<LineItem> | string>
    >;
    feedbackQuestions?: GraphCacheResolver<
      WithTypename<Query>,
      QueryFeedbackQuestionsArgs,
      Array<WithTypename<FeedbackQuestion> | string>
    >;
    fetchPlansByTierId?: GraphCacheResolver<
      WithTypename<Query>,
      QueryFetchPlansByTierIdArgs,
      Array<WithTypename<PaymentPlan> | string>
    >;
    getSubscriptions?: GraphCacheResolver<
      WithTypename<Query>,
      QueryGetSubscriptionsArgs,
      Array<WithTypename<PaymentSubscription> | string>
    >;
    giftCardBalance?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<GiftCardBalanceResponseUnion> | string
    >;
    giftCardOrderDetail?: GraphCacheResolver<
      WithTypename<Query>,
      QueryGiftCardOrderDetailArgs,
      WithTypename<GiftCardOrderDetailResponseUnion> | string
    >;
    giftCardPurchaseHistory?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<GiftCardPurchaseHistory> | string
    >;
    giftCardRedemptionDetail?: GraphCacheResolver<
      WithTypename<Query>,
      QueryGiftCardRedemptionDetailArgs,
      WithTypename<GiftCardRedemptionDetailResponseUnion> | string
    >;
    hasUnseenChallenges?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<HasUnseenChallenges> | string
    >;
    inStoreFeedbackQuestions?: GraphCacheResolver<
      WithTypename<Query>,
      QueryInStoreFeedbackQuestionsArgs,
      Array<WithTypename<FeedbackQuestion> | string>
    >;
    inStoreOrderLastPendingFeedback?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<InStoreOrder> | string
    >;
    inStorePaymentMethods?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Credit> | string>
    >;
    kustomerToken?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Kustomer> | string
    >;
    lineItem?: GraphCacheResolver<
      WithTypename<Query>,
      QueryLineItemArgs,
      WithTypename<LineItemResponseUnion> | string
    >;
    lineItemBySlug?: GraphCacheResolver<
      WithTypename<Query>,
      QueryLineItemBySlugArgs,
      WithTypename<LineItemResponseUnion> | string
    >;
    lineItemRecommendations?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<LineItemRecommendation> | string>
    >;
    locationByAddress?: GraphCacheResolver<
      WithTypename<Query>,
      QueryLocationByAddressArgs,
      WithTypename<LocationByAddressResponseUnion> | string
    >;
    loyaltyProfile?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<LoyaltyProfile> | string
    >;
    menuCollection?: GraphCacheResolver<
      WithTypename<Query>,
      QueryMenuCollectionArgs,
      WithTypename<MenuCollection> | string
    >;
    menuCollections?: GraphCacheResolver<
      WithTypename<Query>,
      QueryMenuCollectionsArgs,
      Array<WithTypename<MenuCollection> | string>
    >;
    nonRefundedLoyaltyTransactionsForCustomer?: GraphCacheResolver<
      WithTypename<Query>,
      QueryNonRefundedLoyaltyTransactionsForCustomerArgs,
      Array<WithTypename<SubscriptionTransactions> | string>
    >;
    order?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOrderArgs,
      WithTypename<Order> | string
    >;
    orderLastPendingFeedback?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    orderStatus?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOrderStatusArgs,
      WithTypename<OrderStatusResponseUnion> | string
    >;
    orders?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOrdersArgs,
      WithTypename<OrdersResponseUnion> | string
    >;
    ordersV2?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOrdersV2Args,
      WithTypename<OrdersResponseUnion> | string
    >;
    paymentMethodCards?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<PaymentMethodCardUnion> | string>
    >;
    paymentProfile?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<PaymentProfile> | string
    >;
    paymentSubscriptionProfile?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<PaymentSubscriptionProfile> | string
    >;
    product?: GraphCacheResolver<
      WithTypename<Query>,
      QueryProductArgs,
      WithTypename<Product> | string
    >;
    productForRestaurant?: GraphCacheResolver<
      WithTypename<Query>,
      QueryProductForRestaurantArgs,
      WithTypename<Product> | string
    >;
    restaurant?: GraphCacheResolver<
      WithTypename<Query>,
      QueryRestaurantArgs,
      WithTypename<Restaurant> | string
    >;
    restaurantCapacity?: GraphCacheResolver<
      WithTypename<Query>,
      QueryRestaurantCapacityArgs,
      WithTypename<RestaurantCapacity> | string
    >;
    rewards?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Reward> | string>
    >;
    rewardsForUser?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<ChallengeReward> | string>
    >;
    searchLocationsByBoundingBox?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsByBoundingBoxArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    searchLocationsByEntityId?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsByEntityIdArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    searchLocationsByString?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsByStringArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    searchLocationsInKilometerRadius?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsInKilometerRadiusArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    searchLocationsInMeterRadius?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsInMeterRadiusArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    searchLocationsInMileRadius?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySearchLocationsInMileRadiusArgs,
      Array<WithTypename<LocationSearchResult> | string>
    >;
    session?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Session> | string
    >;
    subscriptionPlanById?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySubscriptionPlanByIdArgs,
      WithTypename<SubscriptionPlan> | string
    >;
    subscriptionPlansByIds?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySubscriptionPlansByIdsArgs,
      Array<WithTypename<SubscriptionPlan> | string>
    >;
  };
  AccountLocked?: {
    message?: GraphCacheResolver<
      WithTypename<AccountLocked>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<AccountLocked>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ActivePaymentMethodError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<ActivePaymentMethodError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AddAddressSuccess?: {
    address?: GraphCacheResolver<
      WithTypename<AddAddressSuccess>,
      Record<string, never>,
      WithTypename<Address> | string
    >;
  };
  AddCardFunctionalityDeprecated?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<AddCardFunctionalityDeprecated>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AddLineItemToCartSuccess?: {
    cart?: GraphCacheResolver<
      WithTypename<AddLineItemToCartSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  AddSubscriptionPaymentMethodSuccessResponse?: {
    creditCard?: GraphCacheResolver<
      WithTypename<AddSubscriptionPaymentMethodSuccessResponse>,
      Record<string, never>,
      WithTypename<SubscriptionCreditCard> | string
    >;
    paymentMethodToken?: GraphCacheResolver<
      WithTypename<AddSubscriptionPaymentMethodSuccessResponse>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Addons?: {
    products?: GraphCacheResolver<
      WithTypename<Addons>,
      Record<string, never>,
      Array<WithTypename<Product> | string>
    >;
  };
  Address?: {
    city?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    country?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    deliveryPreference?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      DeliveryPreferenceType | string
    >;
    googlePlaceId?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    latitude?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    longitude?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    secondaryStreet?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    state?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    street?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    zipCode?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ApplyRewardFailureMetadata?: {
    requiredChannel?: GraphCacheResolver<
      WithTypename<ApplyRewardFailureMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    requiredDays?: GraphCacheResolver<
      WithTypename<ApplyRewardFailureMetadata>,
      Record<string, never>,
      Array<Scalars['Float'] | string>
    >;
  };
  ApplyRewardSuccess?: {
    order?: GraphCacheResolver<
      WithTypename<ApplyRewardSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  Asset?: {
    url?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AvailableWantedTime?: {
    deliveryOffset?: GraphCacheResolver<
      WithTypename<AvailableWantedTime>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    time?: GraphCacheResolver<
      WithTypename<AvailableWantedTime>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  BaseProduct?: {
    asset?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    description?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    heading?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isDeepLinkingEnabled?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isSeasonal?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    subheading?: GraphCacheResolver<
      WithTypename<BaseProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  BillingAccount?: {
    cardType?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      CardType | string
    >;
    description?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expirationState?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      ExpirationState | string
    >;
    id?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isDefault?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    nickname?: GraphCacheResolver<
      WithTypename<BillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CXCredit?: {
    displayName?: GraphCacheResolver<
      WithTypename<CxCredit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<CxCredit>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  CanCancel?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CanCancel>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CanCancel>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    timeToFire?: GraphCacheResolver<
      WithTypename<CanCancel>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CanCancelAlreadySent?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CanCancelAlreadySent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CanCancelAlreadySent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CanCancelAlreadySent>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CanCancelFailed?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CanCancelFailed>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CanCancelFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CanCancelFailed>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CanCancelNotFound?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CanCancelNotFound>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CanCancelNotFound>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CanCancelNotFound>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CanCancelUnauthorized?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CanCancelUnauthorized>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CanCancelUnauthorized>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CanCancelUnauthorized>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CanNotReorder?: {
    conflicts?: GraphCacheResolver<
      WithTypename<CanNotReorder>,
      Record<string, never>,
      Array<WithTypename<OrderConflict> | string>
    >;
    message?: GraphCacheResolver<
      WithTypename<CanNotReorder>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<CanNotReorder>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  CancelOrderFailed?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CancelOrderFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CancelOrderFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<CancelOrderFailed>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CancelOrderSuccess?: {
    orderId?: GraphCacheResolver<
      WithTypename<CancelOrderSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    reason?: GraphCacheResolver<
      WithTypename<CancelOrderSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<CancelOrderSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CancelSubscriptionAtCycleEndSuccessResponse?: {
    subscription?: GraphCacheResolver<
      WithTypename<CancelSubscriptionAtCycleEndSuccessResponse>,
      Record<string, never>,
      WithTypename<PaymentSubscription> | string
    >;
  };
  CancelSubscriptionForCustomerSuccessResponse?: {
    success?: GraphCacheResolver<
      WithTypename<CancelSubscriptionForCustomerSuccessResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CancelSubscriptionSuccessResponse?: {
    subscription?: GraphCacheResolver<
      WithTypename<CancelSubscriptionSuccessResponse>,
      Record<string, never>,
      WithTypename<PaymentSubscription> | string
    >;
  };
  CancelSuccessRefundFailed?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CancelSuccessRefundFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CancelSuccessRefundFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<CancelSuccessRefundFailed>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CancellationFailedWithDelivery?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CancellationFailedWithDelivery>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CancellationFailedWithDelivery>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<CancellationFailedWithDelivery>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CancellationLimitExceeded?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CancellationLimitExceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CancellationLimitExceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<CancellationLimitExceeded>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CannotRefundSubscriptionTransactionUserError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CannotRefundSubscriptionTransactionUserError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CantCancelAlreadyCanceled?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CantCancelAlreadyCanceled>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CantCancelAlreadyCanceled>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CantCancelAlreadyCanceled>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CantCancelLimitExcceeded?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CantCancelLimitExcceeded>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CantCancelLimitExcceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CantCancelLimitExcceeded>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  CantCancelOldOrders?: {
    canCancel?: GraphCacheResolver<
      WithTypename<CantCancelOldOrders>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    errorMessage?: GraphCacheResolver<
      WithTypename<CantCancelOldOrders>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<CantCancelOldOrders>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  Challenge?: {
    achieved?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    celebrationCopy?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    descriptiveTitle?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expirationDate?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    goal?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    legalTerms?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    percentageCompleted?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    permalink?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    progress?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    progressCopy?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      ChallengeStatus | string
    >;
    theme?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      ChallengeTheme | string
    >;
    title?: GraphCacheResolver<
      WithTypename<Challenge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ChallengeCelebration?: {
    celebrationCopy?: GraphCacheResolver<
      WithTypename<ChallengeCelebration>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    challengesIds?: GraphCacheResolver<
      WithTypename<ChallengeCelebration>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    expirationDate?: GraphCacheResolver<
      WithTypename<ChallengeCelebration>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    rewardUsageDisclaimer?: GraphCacheResolver<
      WithTypename<ChallengeCelebration>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    title?: GraphCacheResolver<
      WithTypename<ChallengeCelebration>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ChallengeCelebrationV2?: {
    celebrationCopy?: GraphCacheResolver<
      WithTypename<ChallengeCelebrationV2>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    challengesIds?: GraphCacheResolver<
      WithTypename<ChallengeCelebrationV2>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    expirationDate?: GraphCacheResolver<
      WithTypename<ChallengeCelebrationV2>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    title?: GraphCacheResolver<
      WithTypename<ChallengeCelebrationV2>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ChallengeReward?: {
    expiresAt?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    redeemable?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    redeemableAt?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    redemptionChannel?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      RedemptionChannel | string
    >;
    rewardType?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      RewardType | string
    >;
    terms?: GraphCacheResolver<
      WithTypename<ChallengeReward>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CourierDetails?: {
    latitude?: GraphCacheResolver<
      WithTypename<CourierDetails>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    longitude?: GraphCacheResolver<
      WithTypename<CourierDetails>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<CourierDetails>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    phone?: GraphCacheResolver<
      WithTypename<CourierDetails>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CreateAccountSuccess?: {
    customer?: GraphCacheResolver<
      WithTypename<CreateAccountSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
  CreateLoyaltyProfileResult?: {
    id?: GraphCacheResolver<
      WithTypename<CreateLoyaltyProfileResult>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  CreateSetupIntent?: {
    clientSecret?: GraphCacheResolver<
      WithTypename<CreateSetupIntent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<CreateSetupIntent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  CreateSubscriptionResult?: {
    firstBillingDate?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    hasFreeTrial?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isTrialActive?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    subscriptionAmount?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    trialDuration?: GraphCacheResolver<
      WithTypename<CreateSubscriptionResult>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  Credit?: {
    amount?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    creditType?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      CreditType | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expiresAt?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    remainingAmount?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    storefrontEligibilityTitle?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    title?: GraphCacheResolver<
      WithTypename<Credit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Customer?: {
    addresses?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Array<WithTypename<Address> | string>
    >;
    availableCredit?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    billingAccounts?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Array<WithTypename<BillingAccount> | string>
    >;
    birthday?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    credits?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Array<WithTypename<Credit> | string>
    >;
    deletionInProgress?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    email?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    firstName?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    lastName?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    phoneNumber?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    referralShareUrlEmail?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    trackingUuid?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    useCredit?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  CustomerNotFound?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CustomerNotFound>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CustomerPass?: {
    authenticationToken?: GraphCacheResolver<
      WithTypename<CustomerPass>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    url?: GraphCacheResolver<
      WithTypename<CustomerPass>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CustomerSupportCreditsFailureError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<CustomerSupportCreditsFailureError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CustomerSupportCreditsSuccessResponse?: {
    credits?: GraphCacheResolver<
      WithTypename<CustomerSupportCreditsSuccessResponse>,
      Record<string, never>,
      Array<WithTypename<CxCredit> | string>
    >;
  };
  DeclinedPaymentMethod?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<DeclinedPaymentMethod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<DeclinedPaymentMethod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<DeclinedPaymentMethod>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  DeleteAddressSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<DeleteAddressSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  DeleteBillingAccountResponse?: {
    success?: GraphCacheResolver<
      WithTypename<DeleteBillingAccountResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  DeleteSubscriptionPaymentMethodSuccess?: {
    creditCards?: GraphCacheResolver<
      WithTypename<DeleteSubscriptionPaymentMethodSuccess>,
      Record<string, never>,
      Array<WithTypename<SubscriptionCreditCard> | string>
    >;
  };
  DeliveryAlreadyInFlight?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<DeliveryAlreadyInFlight>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DeliveryOrderDetail?: {
    address?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      WithTypename<Address> | string
    >;
    deliveryFee?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    estimatedDeliveryTime?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    tip?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    vendor?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    vendorRestaurantId?: GraphCacheResolver<
      WithTypename<DeliveryOrderDetail>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DeliverySpecifyAddress?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<DeliverySpecifyAddress>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DietaryProperty?: {
    id?: GraphCacheResolver<
      WithTypename<DietaryProperty>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<DietaryProperty>,
      Record<string, never>,
      DietaryPropertyKind | string
    >;
  };
  Discount?: {
    amount?: GraphCacheResolver<
      WithTypename<Discount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Discount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Discount>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Discount>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  DropOffLocation?: {
    id?: GraphCacheResolver<
      WithTypename<DropOffLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<DropOffLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  EditLineItemInCartSuccess?: {
    cart?: GraphCacheResolver<
      WithTypename<EditLineItemInCartSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  EstimateRange?: {
    end?: GraphCacheResolver<
      WithTypename<EstimateRange>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    start?: GraphCacheResolver<
      WithTypename<EstimateRange>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  FeedbackQuestion?: {
    id?: GraphCacheResolver<
      WithTypename<FeedbackQuestion>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    responses?: GraphCacheResolver<
      WithTypename<FeedbackQuestion>,
      Record<string, never>,
      Array<WithTypename<FeedbackResponse> | string>
    >;
    slug?: GraphCacheResolver<
      WithTypename<FeedbackQuestion>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    text?: GraphCacheResolver<
      WithTypename<FeedbackQuestion>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  FeedbackResponse?: {
    id?: GraphCacheResolver<
      WithTypename<FeedbackResponse>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    text?: GraphCacheResolver<
      WithTypename<FeedbackResponse>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  FeedbackSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<FeedbackSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  FieldValidationError?: {
    field?: GraphCacheResolver<
      WithTypename<FieldValidationError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<FieldValidationError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ForgotPasswordSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<ForgotPasswordSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  FreeTrialNotEligible?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<FreeTrialNotEligible>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  GiftCardAssociatedWithAnotherAccountError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<GiftCardAssociatedWithAnotherAccountError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  GiftCardBalance?: {
    customerId?: GraphCacheResolver<
      WithTypename<GiftCardBalance>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    giftCardBalance?: GraphCacheResolver<
      WithTypename<GiftCardBalance>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  GiftCardDeliveryDetail?: {
    deliveryDateTime?: GraphCacheResolver<
      WithTypename<GiftCardDeliveryDetail>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    deliveryMethods?: GraphCacheResolver<
      WithTypename<GiftCardDeliveryDetail>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    isAsap?: GraphCacheResolver<
      WithTypename<GiftCardDeliveryDetail>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  GiftCardNotFoundError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<GiftCardNotFoundError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  GiftCardOrderDetail?: {
    deliveryDetail?: GraphCacheResolver<
      WithTypename<GiftCardOrderDetail>,
      Record<string, never>,
      WithTypename<GiftCardDeliveryDetail> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<GiftCardOrderDetail>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    purchasedAt?: GraphCacheResolver<
      WithTypename<GiftCardOrderDetail>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    userGiftCards?: GraphCacheResolver<
      WithTypename<GiftCardOrderDetail>,
      Record<string, never>,
      Array<WithTypename<UserGiftCard> | string>
    >;
  };
  GiftCardOrderNotFoundError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<GiftCardOrderNotFoundError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  GiftCardPurchaseHistory?: {
    customerId?: GraphCacheResolver<
      WithTypename<GiftCardPurchaseHistory>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    giftCardOrders?: GraphCacheResolver<
      WithTypename<GiftCardPurchaseHistory>,
      Record<string, never>,
      Array<WithTypename<GiftCardOrderDetail> | string>
    >;
  };
  GiftCardRedemptionDetailSuccess?: {
    assetId?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    balance?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    cardNumber?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    cardRegCode?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recipientMessage?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recipientName?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    redeemId?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    senderName?: GraphCacheResolver<
      WithTypename<GiftCardRedemptionDetailSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Guest?: {
    email?: GraphCacheResolver<
      WithTypename<Guest>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    firstName?: GraphCacheResolver<
      WithTypename<Guest>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Guest>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    lastName?: GraphCacheResolver<
      WithTypename<Guest>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  HasUnseenChallenges?: {
    hasUnseenChallenges?: GraphCacheResolver<
      WithTypename<HasUnseenChallenges>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  InStoreOrder?: {
    id?: GraphCacheResolver<
      WithTypename<InStoreOrder>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    wantedTime?: GraphCacheResolver<
      WithTypename<InStoreOrder>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  IncorrectPaymentMethod?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<IncorrectPaymentMethod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<IncorrectPaymentMethod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<IncorrectPaymentMethod>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Ingredient?: {
    asset?: GraphCacheResolver<
      WithTypename<Ingredient>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    dietaryProperties?: GraphCacheResolver<
      WithTypename<Ingredient>,
      Record<string, never>,
      Array<WithTypename<DietaryProperty> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<Ingredient>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<Ingredient>,
      Record<string, never>,
      IngredientKind | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Ingredient>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  IngredientModification?: {
    additionCost?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    calories?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    ingredient?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      WithTypename<Ingredient> | string
    >;
    isGrain?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    mixable?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    outOfStock?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    removalCost?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    subKind?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    substitutionCost?: GraphCacheResolver<
      WithTypename<IngredientModification>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  IngredientModificationKind?: {
    allowMultipleQuantity?: GraphCacheResolver<
      WithTypename<IngredientModificationKind>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    freeAggregateQuantity?: GraphCacheResolver<
      WithTypename<IngredientModificationKind>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    maxAggregateQuantity?: GraphCacheResolver<
      WithTypename<IngredientModificationKind>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    minAggregateQuantity?: GraphCacheResolver<
      WithTypename<IngredientModificationKind>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    modifications?: GraphCacheResolver<
      WithTypename<IngredientModificationKind>,
      Record<string, never>,
      Array<WithTypename<IngredientModification> | string>
    >;
  };
  IngredientModifications?: {
    bases?: GraphCacheResolver<
      WithTypename<IngredientModifications>,
      Record<string, never>,
      WithTypename<IngredientModificationKind> | string
    >;
    bread?: GraphCacheResolver<
      WithTypename<IngredientModifications>,
      Record<string, never>,
      WithTypename<IngredientModificationKind> | string
    >;
    dressings?: GraphCacheResolver<
      WithTypename<IngredientModifications>,
      Record<string, never>,
      WithTypename<IngredientModificationKind> | string
    >;
    premiums?: GraphCacheResolver<
      WithTypename<IngredientModifications>,
      Record<string, never>,
      WithTypename<IngredientModificationKind> | string
    >;
    toppings?: GraphCacheResolver<
      WithTypename<IngredientModifications>,
      Record<string, never>,
      WithTypename<IngredientModificationKind> | string
    >;
  };
  InvalidAddress?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidAddress>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidAddress>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidBillingAccount?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidBillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<InvalidBillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidBillingAccount>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidCredentials?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidCredentials>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidCredentials>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidCustomerPhone?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidCustomerPhone>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  InvalidGiftCardError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidGiftCardError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  InvalidInput?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidInput>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidInput>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidOrder?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidOrder>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidOrder>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidPaymentMethod?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidPaymentMethod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  InvalidPostalCode?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidPostalCode>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  InvalidPromoCode?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidPromoCode>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidPromoCode>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidRegistrationCode?: {
    message?: GraphCacheResolver<
      WithTypename<InvalidRegistrationCode>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidRegistrationCode>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvalidSubmittedBillingAccount?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidSubmittedBillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  InvalidTip?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<InvalidTip>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<InvalidTip>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<InvalidTip>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  IssueCustomerSupportCreditResult?: {
    success?: GraphCacheResolver<
      WithTypename<IssueCustomerSupportCreditResult>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  Kustomer?: {
    token?: GraphCacheResolver<
      WithTypename<Kustomer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Ledger?: {
    credits?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Array<WithTypename<LedgerItem> | string>
    >;
    creditsTotal?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    discounts?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Array<WithTypename<LedgerItem> | string>
    >;
    discountsTotal?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fees?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Array<WithTypename<LedgerItem> | string>
    >;
    feesTotal?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    subtotal?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    tax?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    tip?: GraphCacheResolver<
      WithTypename<Ledger>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  LedgerItem?: {
    amount?: GraphCacheResolver<
      WithTypename<LedgerItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<LedgerItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<LedgerItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  LineItem?: {
    addedIngredients?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
    calories?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    cost?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    customName?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    customerName?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    dressingMode?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      DressingMode | string
    >;
    favorited?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    ingredients?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
    isCustom?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    mixedDressingDetails?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Array<WithTypename<MixedDressingDetails> | string>
    >;
    perItemCost?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    product?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      WithTypename<Product> | string
    >;
    quantity?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    removedIngredients?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
    slug?: GraphCacheResolver<
      WithTypename<LineItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  LineItemModificationsInvalidException?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<LineItemModificationsInvalidException>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<LineItemModificationsInvalidException>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<LineItemModificationsInvalidException>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  LineItemNotFound?: {
    message?: GraphCacheResolver<
      WithTypename<LineItemNotFound>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<LineItemNotFound>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  LineItemRecommendation?: {
    createdAt?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ingredientIds?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    ingredients?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recommendationType?: GraphCacheResolver<
      WithTypename<LineItemRecommendation>,
      Record<string, never>,
      RecommendationType | string
    >;
  };
  LineItemSuccess?: {
    lineItem?: GraphCacheResolver<
      WithTypename<LineItemSuccess>,
      Record<string, never>,
      WithTypename<LineItem> | string
    >;
  };
  Location?: {
    estimateRange?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      WithTypename<EstimateRange> | string
    >;
    restaurant?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      WithTypename<Restaurant> | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      LocationStatus | string
    >;
    vendor?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      WithTypename<Vendor> | string
    >;
  };
  LocationSearchResult?: {
    location?: GraphCacheResolver<
      WithTypename<LocationSearchResult>,
      Record<string, never>,
      WithTypename<StoreLocation> | string
    >;
    score?: GraphCacheResolver<
      WithTypename<LocationSearchResult>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  LoginSuccess?: {
    csrf?: GraphCacheResolver<
      WithTypename<LoginSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  LogoutSuccess?: {
    csrf?: GraphCacheResolver<
      WithTypename<LogoutSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  LoyaltyOptInFailed?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<LoyaltyOptInFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  LoyaltyProfile?: {
    customerId?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    hasPaidTierHistory?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    joinDate?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    loyaltyId?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    tier?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      WithTypename<Tier> | string
    >;
    tierStatus?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      WithTypename<TierStatus> | string
    >;
    trialEligible?: GraphCacheResolver<
      WithTypename<LoyaltyProfile>,
      Record<string, never>,
      Array<WithTypename<TrialEligibility> | string>
    >;
  };
  MaxModificationsExceeded?: {
    message?: GraphCacheResolver<
      WithTypename<MaxModificationsExceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<MaxModificationsExceeded>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Menu?: {
    categories?: GraphCacheResolver<
      WithTypename<Menu>,
      Record<string, never>,
      Array<WithTypename<MenuCategory> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<Menu>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  MenuCategory?: {
    id?: GraphCacheResolver<
      WithTypename<MenuCategory>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isCustom?: GraphCacheResolver<
      WithTypename<MenuCategory>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<MenuCategory>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    products?: GraphCacheResolver<
      WithTypename<MenuCategory>,
      Record<string, never>,
      Array<WithTypename<Product> | string>
    >;
  };
  MenuCollection?: {
    color?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    primaryDescription?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    primaryImageUrl?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    primaryTitle?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    products?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Array<WithTypename<Product> | string>
    >;
    secondaryDescription?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    secondaryTitle?: GraphCacheResolver<
      WithTypename<MenuCollection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  MinimumDeliverySubtotalNotMet?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<MinimumDeliverySubtotalNotMet>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<MinimumDeliverySubtotalNotMet>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<MinimumDeliverySubtotalNotMet>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  MixedDressingDetails?: {
    ingredientId?: GraphCacheResolver<
      WithTypename<MixedDressingDetails>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    weight?: GraphCacheResolver<
      WithTypename<MixedDressingDetails>,
      Record<string, never>,
      DressingWeight | string
    >;
  };
  NoBalanceGiftCardError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<NoBalanceGiftCardError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  NoValidRestaurants?: {
    message?: GraphCacheResolver<
      WithTypename<NoValidRestaurants>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<NoValidRestaurants>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  OptOutLoyaltyForCustomerSuccessResponse?: {
    success?: GraphCacheResolver<
      WithTypename<OptOutLoyaltyForCustomerSuccessResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  OptOutLoyaltySuccessResponse?: {
    success?: GraphCacheResolver<
      WithTypename<OptOutLoyaltySuccessResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  Order?: {
    asset?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    availableWantedTimes?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Array<WithTypename<AvailableWantedTime> | string>
    >;
    canCancel?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    canTrackOrderStatus?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    deliveryOrderDetail?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      WithTypename<DeliveryOrderDetail> | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    dropoffLocation?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    includeUtensils?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isCanceled?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isInFlight?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isInFlightDelivery?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isPendingFeedback?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    ledger?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      WithTypename<Ledger> | string
    >;
    lineItems?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Array<WithTypename<LineItem> | string>
    >;
    orderType?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      OrderType | string
    >;
    payments?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Array<WithTypename<Payment> | string>
    >;
    restaurant?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      WithTypename<Restaurant> | string
    >;
    selectedRewards?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    subtotal?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    total?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    vendorId?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    wantedTime?: GraphCacheResolver<
      WithTypename<Order>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  OrderAlreadyCanceled?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<OrderAlreadyCanceled>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<OrderAlreadyCanceled>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<OrderAlreadyCanceled>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  OrderAlreadySentToKitchen?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<OrderAlreadySentToKitchen>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<OrderAlreadySentToKitchen>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<OrderAlreadySentToKitchen>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  OrderConflict?: {
    product?: GraphCacheResolver<
      WithTypename<OrderConflict>,
      Record<string, never>,
      WithTypename<Product> | string
    >;
    type?: GraphCacheResolver<
      WithTypename<OrderConflict>,
      Record<string, never>,
      ConflictType | string
    >;
    unavailableIngredients?: GraphCacheResolver<
      WithTypename<OrderConflict>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
  };
  OrderNotFound?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<OrderNotFound>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<OrderNotFound>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<OrderNotFound>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  OrderStatus?: {
    courierDetails?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      WithTypename<CourierDetails> | string
    >;
    customerId?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    flattenedOrderStatus?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      FlattenedOrderStatuses | string
    >;
    gravyStatus?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      GravyStatusType | string
    >;
    gravyUpdatedAt?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    order?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    orderId?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    orderOccasion?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    orderStatus?: GraphCacheResolver<
      WithTypename<OrderStatus>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  OrdersResponseSuccess?: {
    orders?: GraphCacheResolver<
      WithTypename<OrdersResponseSuccess>,
      Record<string, never>,
      Array<WithTypename<Order> | string>
    >;
    pagination?: GraphCacheResolver<
      WithTypename<OrdersResponseSuccess>,
      Record<string, never>,
      WithTypename<Pagination> | string
    >;
  };
  Pagination?: {
    last?: GraphCacheResolver<
      WithTypename<Pagination>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    page?: GraphCacheResolver<
      WithTypename<Pagination>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    take?: GraphCacheResolver<
      WithTypename<Pagination>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    total?: GraphCacheResolver<
      WithTypename<Pagination>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Payment?: {
    amountInCents?: GraphCacheResolver<
      WithTypename<Payment>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Payment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    paymentTime?: GraphCacheResolver<
      WithTypename<Payment>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    processor?: GraphCacheResolver<
      WithTypename<Payment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  PaymentMethodAlreadyExistsError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<PaymentMethodAlreadyExistsError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  PaymentMethodBillingAccount?: {
    cardType?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      CardType | string
    >;
    description?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expirationState?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      CardExpirationState | string
    >;
    id?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isDefault?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    nickname?: GraphCacheResolver<
      WithTypename<PaymentMethodBillingAccount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  PaymentMethodCard?: {
    cardType?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      CardType | string
    >;
    description?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expirationState?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      CardExpirationState | string
    >;
    id?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isDefault?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    nickname?: GraphCacheResolver<
      WithTypename<PaymentMethodCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  PaymentPlan?: {
    braintreePlanId?: GraphCacheResolver<
      WithTypename<PaymentPlan>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    tier?: GraphCacheResolver<
      WithTypename<PaymentPlan>,
      Record<string, never>,
      WithTypename<Tier> | string
    >;
  };
  PaymentProfile?: {
    defaultCardId?: GraphCacheResolver<
      WithTypename<PaymentProfile>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<PaymentProfile>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  PaymentSubscription?: {
    balance?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    billingFrequencyUnit?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      DurationUnit | string
    >;
    billingHistory?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Array<WithTypename<SubscriptionTransactions> | string>
    >;
    billingPeriodEndDate?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    billingPeriodStartDate?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    billingRecurrence?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      DurationUnit | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    firstBillingDate?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    hasFreeTrial?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isTrialActive?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    nextBillingDate?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    nextBillingPeriodAmount?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    paidThroughDate?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    planId?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    price?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<PaymentSubscription>,
      Record<string, never>,
      PaymentSubscriptionStatus | string
    >;
  };
  PaymentSubscriptionProfile?: {
    creditCards?: GraphCacheResolver<
      WithTypename<PaymentSubscriptionProfile>,
      Record<string, never>,
      Array<WithTypename<SubscriptionCreditCard> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<PaymentSubscriptionProfile>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    transactionHistory?: GraphCacheResolver<
      WithTypename<PaymentSubscriptionProfile>,
      Record<string, never>,
      Array<WithTypename<SubscriptionTransactions> | string>
    >;
  };
  Product?: {
    asset?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    brinkPlu?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    calories?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    categoryId?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    categoryName?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    channelCost?: GraphCacheResolver<
      WithTypename<Product>,
      ProductChannelCostArgs,
      Scalars['Int'] | string
    >;
    cost?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    customType?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      CustomProductType | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    dietaryProperties?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Array<WithTypename<DietaryProperty> | string>
    >;
    expirationDate?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    ingredientModifications?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      WithTypename<IngredientModifications> | string
    >;
    ingredients?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Array<WithTypename<Ingredient> | string>
    >;
    isCustom?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isModifiable?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isSalad?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      WithTypename<ProductLabel> | string
    >;
    maxModifications?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    outOfStock?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    restaurant?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      WithTypename<Restaurant> | string
    >;
    restaurantId?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    throttleItem?: GraphCacheResolver<
      WithTypename<Product>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  ProductLabel?: {
    id?: GraphCacheResolver<
      WithTypename<ProductLabel>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ProductLabel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ProductOutOfStock?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<ProductOutOfStock>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<ProductOutOfStock>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ProductOutOfStock>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ProductUnavailable?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<ProductUnavailable>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<ProductUnavailable>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ProductUnavailable>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  PromoCodeAlreadyApplied?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<PromoCodeAlreadyApplied>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RefundLoyaltyTransactionSuccessResponse?: {
    success?: GraphCacheResolver<
      WithTypename<RefundLoyaltyTransactionSuccessResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RefundOrderSuccess?: {
    refundResults?: GraphCacheResolver<
      WithTypename<RefundOrderSuccess>,
      Record<string, never>,
      Array<WithTypename<RefundResult> | string>
    >;
  };
  RefundResult?: {
    id?: GraphCacheResolver<
      WithTypename<RefundResult>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    reason?: GraphCacheResolver<
      WithTypename<RefundResult>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<RefundResult>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RegisterGuestFailed?: {
    message?: GraphCacheResolver<
      WithTypename<RegisterGuestFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RegisterGuestFailed>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RegisterGuestSuccess?: {
    csrf?: GraphCacheResolver<
      WithTypename<RegisterGuestSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    guest?: GraphCacheResolver<
      WithTypename<RegisterGuestSuccess>,
      Record<string, never>,
      WithTypename<Guest> | string
    >;
  };
  RegisterSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<RegisterSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RejoinSubscriptionSuccessResponse?: {
    subscription?: GraphCacheResolver<
      WithTypename<RejoinSubscriptionSuccessResponse>,
      Record<string, never>,
      WithTypename<PaymentSubscription> | string
    >;
  };
  RemoveFromCartSuccess?: {
    cart?: GraphCacheResolver<
      WithTypename<RemoveFromCartSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    success?: GraphCacheResolver<
      WithTypename<RemoveFromCartSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RemoveRewardSuccess?: {
    order?: GraphCacheResolver<
      WithTypename<RemoveRewardSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  ReorderConflict?: {
    conflicts?: GraphCacheResolver<
      WithTypename<ReorderConflict>,
      Record<string, never>,
      Array<WithTypename<OrderConflict> | string>
    >;
    isNothingAvailable?: GraphCacheResolver<
      WithTypename<ReorderConflict>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  ReorderSuccess?: {
    cart?: GraphCacheResolver<
      WithTypename<ReorderSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    isMixingSupported?: GraphCacheResolver<
      WithTypename<ReorderSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RequestAccountDeletionFailed?: {
    message?: GraphCacheResolver<
      WithTypename<RequestAccountDeletionFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RequestAccountDeletionFailed>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RequestAccountDeletionSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<RequestAccountDeletionSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  ResendVerificationCodeSuccess?: {
    success?: GraphCacheResolver<
      WithTypename<ResendVerificationCodeSuccess>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  Restaurant?: {
    address?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    asset?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    availableDropOffLocations?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Array<WithTypename<DropOffLocation> | string>
    >;
    city?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    deliveryFee?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    deliveryMinSubtotal?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    entityId?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    flexMessage?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    hours?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      WithTypename<RestaurantHours> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isAcceptingOrders?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isOutpost?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    latitude?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    longitude?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    menu?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      WithTypename<Menu> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notAcceptingOrdersReason?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    parentId?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    phone?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    showDeliveryFeeDisclosure?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    showDeliveryPriceDifferenciationDisclosure?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    showOutpostPriceDifferenciationDisclosure?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    state?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    utcOffset?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    zipCode?: GraphCacheResolver<
      WithTypename<Restaurant>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RestaurantCapacity?: {
    availableWantedTimes?: GraphCacheResolver<
      WithTypename<RestaurantCapacity>,
      Record<string, never>,
      Array<WithTypename<RestaurantCapacitySlot> | string>
    >;
    restaurantAddress?: GraphCacheResolver<
      WithTypename<RestaurantCapacity>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    restaurantHours?: GraphCacheResolver<
      WithTypename<RestaurantCapacity>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    restaurantId?: GraphCacheResolver<
      WithTypename<RestaurantCapacity>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  RestaurantCapacitySlot?: {
    availableLineItems?: GraphCacheResolver<
      WithTypename<RestaurantCapacitySlot>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    formatted?: GraphCacheResolver<
      WithTypename<RestaurantCapacitySlot>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    maxLineItems?: GraphCacheResolver<
      WithTypename<RestaurantCapacitySlot>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    original?: GraphCacheResolver<
      WithTypename<RestaurantCapacitySlot>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RestaurantHours?: {
    formatted?: GraphCacheResolver<
      WithTypename<RestaurantHours>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    permanent?: GraphCacheResolver<
      WithTypename<RestaurantHours>,
      Record<string, never>,
      Array<WithTypename<RestaurantStoreHours> | string>
    >;
    store?: GraphCacheResolver<
      WithTypename<RestaurantHours>,
      Record<string, never>,
      Array<WithTypename<RestaurantStoreHours> | string>
    >;
  };
  RestaurantIsNotAcceptingOrders?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<RestaurantIsNotAcceptingOrders>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<RestaurantIsNotAcceptingOrders>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RestaurantIsNotAcceptingOrders>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RestaurantMaxDeliveryQuantityExceeded?: {
    conflicts?: GraphCacheResolver<
      WithTypename<RestaurantMaxDeliveryQuantityExceeded>,
      Record<string, never>,
      Array<WithTypename<OrderConflict> | string>
    >;
    message?: GraphCacheResolver<
      WithTypename<RestaurantMaxDeliveryQuantityExceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RestaurantMaxDeliveryQuantityExceeded>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RestaurantMaxQuantityExceeded?: {
    conflicts?: GraphCacheResolver<
      WithTypename<RestaurantMaxQuantityExceeded>,
      Record<string, never>,
      Array<WithTypename<OrderConflict> | string>
    >;
    message?: GraphCacheResolver<
      WithTypename<RestaurantMaxQuantityExceeded>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RestaurantMaxQuantityExceeded>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RestaurantStoreHours?: {
    day?: GraphCacheResolver<
      WithTypename<RestaurantStoreHours>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    end?: GraphCacheResolver<
      WithTypename<RestaurantStoreHours>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    start?: GraphCacheResolver<
      WithTypename<RestaurantStoreHours>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Reward?: {
    expirationDate?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    redeemable?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    redeemableAt?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    rewardType?: GraphCacheResolver<
      WithTypename<Reward>,
      Record<string, never>,
      RewardType | string
    >;
  };
  RewardDiscount?: {
    amount?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    appliedTime?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    discountSource?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    discountedLineIdQuantities?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Array<Scalars['Float'] | string>
    >;
    discountedLineIds?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    displayName?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    imageUrl?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    offerCustomData?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    posDiscountId?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    referenceId?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    referenceIdType?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    stackOrder?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    userId?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    userOfferCustomData?: GraphCacheResolver<
      WithTypename<RewardDiscount>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RewardNotApplied?: {
    failureCode?: GraphCacheResolver<
      WithTypename<RewardNotApplied>,
      Record<string, never>,
      ApplyRewardFailureCode | string
    >;
    failureMetadata?: GraphCacheResolver<
      WithTypename<RewardNotApplied>,
      Record<string, never>,
      WithTypename<ApplyRewardFailureMetadata> | string
    >;
    failureReasons?: GraphCacheResolver<
      WithTypename<RewardNotApplied>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    message?: GraphCacheResolver<
      WithTypename<RewardNotApplied>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RewardNotApplied>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RewardNotRemoved?: {
    failureCode?: GraphCacheResolver<
      WithTypename<RewardNotRemoved>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<RewardNotRemoved>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RewardNotRemoved>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RewardNotValidated?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<RewardNotValidated>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    failureCode?: GraphCacheResolver<
      WithTypename<RewardNotValidated>,
      Record<string, never>,
      ValidateRewardFailureCode | string
    >;
    failureMetadata?: GraphCacheResolver<
      WithTypename<RewardNotValidated>,
      Record<string, never>,
      WithTypename<ValidateRewardFailureMetadata> | string
    >;
    message?: GraphCacheResolver<
      WithTypename<RewardNotValidated>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RewardNotValidated>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RewardValidateAndPreviewError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<RewardValidateAndPreviewError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    failureMetadata?: GraphCacheResolver<
      WithTypename<RewardValidateAndPreviewError>,
      Record<string, never>,
      WithTypename<RewardValidationFailureMetadata> | string
    >;
    failureReasons?: GraphCacheResolver<
      WithTypename<RewardValidateAndPreviewError>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    offerId?: GraphCacheResolver<
      WithTypename<RewardValidateAndPreviewError>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  RewardValidateAndReserveError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<RewardValidateAndReserveError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    failureMetadata?: GraphCacheResolver<
      WithTypename<RewardValidateAndReserveError>,
      Record<string, never>,
      WithTypename<RewardValidationFailureMetadata> | string
    >;
    failureReasons?: GraphCacheResolver<
      WithTypename<RewardValidateAndReserveError>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    offerId?: GraphCacheResolver<
      WithTypename<RewardValidateAndReserveError>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  RewardValidationFailureMetadata?: {
    requiredChannel?: GraphCacheResolver<
      WithTypename<RewardValidationFailureMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    requiredDays?: GraphCacheResolver<
      WithTypename<RewardValidationFailureMetadata>,
      Record<string, never>,
      Array<Scalars['Float'] | string>
    >;
  };
  RewardValidationGeneralError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<RewardValidationGeneralError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RewardsValidateAndPreviewSuccess?: {
    rewardDiscountPreviews?: GraphCacheResolver<
      WithTypename<RewardsValidateAndPreviewSuccess>,
      Record<string, never>,
      Array<WithTypename<RewardDiscount> | string>
    >;
  };
  RewardsValidateAndReserveSuccess?: {
    reservedRewardDiscounts?: GraphCacheResolver<
      WithTypename<RewardsValidateAndReserveSuccess>,
      Record<string, never>,
      Array<WithTypename<RewardDiscount> | string>
    >;
  };
  Session?: {
    cart?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
    csrf?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    inFlightDeliveryOrderId?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    inFlightOrderId?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isLoggedIn?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  SignInFailed?: {
    message?: GraphCacheResolver<
      WithTypename<SignInFailed>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<SignInFailed>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  SignInSuccess?: {
    customer?: GraphCacheResolver<
      WithTypename<SignInSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
  StoreLocation?: {
    acceptingOrders?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    address?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    city?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    comingSoon?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    crossStreet?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    enabled?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    entityId?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    flexMessage?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    hidden?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    imageUrl?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    isOutpost?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    latitude?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    longitude?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    menuId?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    nativeDeliveryPriceDifferentiationEnabled?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    notAcceptingOrdersReason?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    oloId?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    outpostPriceDifferentiationEnabled?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    phone?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    pickupInstructions?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    regionId?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    showWarningDialog?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    state?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    storeHours?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    warningDialogDescription?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    warningDialogTimeout?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    warningDialogTitle?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    zipCode?: GraphCacheResolver<
      WithTypename<StoreLocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  SubmitBraintreeBillingAccountSuccessResponse?: {
    billingAccount?: GraphCacheResolver<
      WithTypename<SubmitBraintreeBillingAccountSuccessResponse>,
      Record<string, never>,
      WithTypename<BillingAccount> | string
    >;
  };
  SubmitGiftCardCodeSuccess?: {
    totalCredit?: GraphCacheResolver<
      WithTypename<SubmitGiftCardCodeSuccess>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  SubmitGiftCardOrderError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<SubmitGiftCardOrderError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  SubmitOrderUsingBillingAccountSuccess?: {
    customer?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingBillingAccountSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
    order?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingBillingAccountSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  SubmitOrderUsingPaymentMethodCardSuccess?: {
    customer?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingPaymentMethodCardSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
    order?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingPaymentMethodCardSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  SubmitOrderUsingPaymentMethodSuccess?: {
    customer?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingPaymentMethodSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
    order?: GraphCacheResolver<
      WithTypename<SubmitOrderUsingPaymentMethodSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  SubmitOrderWithPaymentMethodSuccess?: {
    order?: GraphCacheResolver<
      WithTypename<SubmitOrderWithPaymentMethodSuccess>,
      Record<string, never>,
      WithTypename<Order> | string
    >;
  };
  SubmitPromoCodeSuccess?: {
    loyaltyId?: GraphCacheResolver<
      WithTypename<SubmitPromoCodeSuccess>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  SubmitPromoCodeSuccessV2?: {
    placeholder?: GraphCacheResolver<
      WithTypename<SubmitPromoCodeSuccessV2>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  SubscriptionCannotBeReactivatedError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<SubscriptionCannotBeReactivatedError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  SubscriptionCreditCard?: {
    cardType?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      BraintreeCardType | string
    >;
    expirationMonth?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expirationState?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      CardExpirationState | string
    >;
    expirationYear?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isActive?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    lastFour?: GraphCacheResolver<
      WithTypename<SubscriptionCreditCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  SubscriptionPlan?: {
    billingDayOfMonth?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    billingFrequency?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    billingFrequencyUnit?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      DurationUnit | string
    >;
    billingRecurrence?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      DurationUnit | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    currencyIsoCode?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    discounts?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Array<WithTypename<Discount> | string>
    >;
    hasFreeTrial?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    price?: GraphCacheResolver<
      WithTypename<SubscriptionPlan>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  SubscriptionTransactions?: {
    amount?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    creditCard?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      WithTypename<SubscriptionCreditCard> | string
    >;
    date?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    refund?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    transactionCreditCard?: GraphCacheResolver<
      WithTypename<SubscriptionTransactions>,
      Record<string, never>,
      WithTypename<TransactionCreditCard> | string
    >;
  };
  Tier?: {
    displayName?: GraphCacheResolver<
      WithTypename<Tier>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Tier>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Tier>,
      Record<string, never>,
      TierName | string
    >;
    nextTier?: GraphCacheResolver<
      WithTypename<Tier>,
      Record<string, never>,
      WithTypename<Tier> | string
    >;
    tierGroup?: GraphCacheResolver<
      WithTypename<Tier>,
      Record<string, never>,
      WithTypename<TierGroup> | string
    >;
  };
  TierGroup?: {
    id?: GraphCacheResolver<
      WithTypename<TierGroup>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<TierGroup>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  TierStatus?: {
    id?: GraphCacheResolver<
      WithTypename<TierStatus>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<TierStatus>,
      Record<string, never>,
      TierStatusName | string
    >;
  };
  TimeslotUnavailable?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<TimeslotUnavailable>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<TimeslotUnavailable>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<TimeslotUnavailable>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  TooManyRequests?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<TooManyRequests>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TransactionCreditCard?: {
    cardType?: GraphCacheResolver<
      WithTypename<TransactionCreditCard>,
      Record<string, never>,
      BraintreeCardType | string
    >;
    id?: GraphCacheResolver<
      WithTypename<TransactionCreditCard>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    lastFour?: GraphCacheResolver<
      WithTypename<TransactionCreditCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TrialEligibility?: {
    duration?: GraphCacheResolver<
      WithTypename<TrialEligibility>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    durationUnit?: GraphCacheResolver<
      WithTypename<TrialEligibility>,
      Record<string, never>,
      DurationUnit | string
    >;
  };
  UnableToGetGiftCardBalanceError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<UnableToGetGiftCardBalanceError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UnableToRedeemGiftCardError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<UnableToRedeemGiftCardError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UnknownUserError?: {
    errorMessage?: GraphCacheResolver<
      WithTypename<UnknownUserError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateAddressSuccess?: {
    address?: GraphCacheResolver<
      WithTypename<UpdateAddressSuccess>,
      Record<string, never>,
      WithTypename<Address> | string
    >;
  };
  UpdateBillingAccountResponse?: {
    billingAccount?: GraphCacheResolver<
      WithTypename<UpdateBillingAccountResponse>,
      Record<string, never>,
      WithTypename<BillingAccount> | string
    >;
  };
  UpdateCustomerResponse?: {
    customer?: GraphCacheResolver<
      WithTypename<UpdateCustomerResponse>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
  UpdateKioskCustomerResponse?: {
    customer?: GraphCacheResolver<
      WithTypename<UpdateKioskCustomerResponse>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
  UpdateSubscriptionPaymentMethodSuccessResponse?: {
    subscription?: GraphCacheResolver<
      WithTypename<UpdateSubscriptionPaymentMethodSuccessResponse>,
      Record<string, never>,
      WithTypename<PaymentSubscription> | string
    >;
  };
  UpdateUseCreditResponse?: {
    customer?: GraphCacheResolver<
      WithTypename<UpdateUseCreditResponse>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
  UpsellCategory?: {
    categoryRanking?: GraphCacheResolver<
      WithTypename<UpsellCategory>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<UpsellCategory>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<UpsellCategory>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    products?: GraphCacheResolver<
      WithTypename<UpsellCategory>,
      Record<string, never>,
      Array<WithTypename<UpsellCategoryProduct> | string>
    >;
    restaurantId?: GraphCacheResolver<
      WithTypename<UpsellCategory>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpsellCategoryProduct?: {
    cost?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    imageUrl?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    menuId?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    outOfStock?: GraphCacheResolver<
      WithTypename<UpsellCategoryProduct>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  UserGiftCard?: {
    assetId?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    balance?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    message?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recipientEmail?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recipientName?: GraphCacheResolver<
      WithTypename<UserGiftCard>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ValidateRewardFailureMetadata?: {
    requiredChannel?: GraphCacheResolver<
      WithTypename<ValidateRewardFailureMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    requiredDays?: GraphCacheResolver<
      WithTypename<ValidateRewardFailureMetadata>,
      Record<string, never>,
      Array<Scalars['Float'] | string>
    >;
  };
  ValidationError?: {
    fieldErrors?: GraphCacheResolver<
      WithTypename<ValidationError>,
      Record<string, never>,
      Array<WithTypename<FieldValidationError> | string>
    >;
    message?: GraphCacheResolver<
      WithTypename<ValidationError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ValidationError>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Vendor?: {
    name?: GraphCacheResolver<
      WithTypename<Vendor>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    restaurantId?: GraphCacheResolver<
      WithTypename<Vendor>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  VerifyRegistrationSuccess?: {
    csrf?: GraphCacheResolver<
      WithTypename<VerifyRegistrationSuccess>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    customer?: GraphCacheResolver<
      WithTypename<VerifyRegistrationSuccess>,
      Record<string, never>,
      WithTypename<Customer> | string
    >;
  };
};

export type GraphCacheOptimisticUpdaters = {
  addAddress?: GraphCacheOptimisticMutationResolver<
    MutationAddAddressArgs,
    WithTypename<AddAddressResponseUnion>
  >;
  addLineItemBatchToCart?: GraphCacheOptimisticMutationResolver<
    MutationAddLineItemBatchToCartArgs,
    WithTypename<AddLineItemToCartResponseUnion>
  >;
  addLineItemToCart?: GraphCacheOptimisticMutationResolver<
    MutationAddLineItemToCartArgs,
    WithTypename<AddLineItemToCartResponseUnion>
  >;
  addSubscriptionPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationAddSubscriptionPaymentMethodArgs,
    WithTypename<AddSubscriptionPaymentMethodResponseUnion>
  >;
  applyReward?: GraphCacheOptimisticMutationResolver<
    MutationApplyRewardArgs,
    WithTypename<ApplyRewardResponseUnion>
  >;
  cancelOrder?: GraphCacheOptimisticMutationResolver<
    MutationCancelOrderArgs,
    WithTypename<CancelOrderResponseUnion>
  >;
  cancelSubscription?: GraphCacheOptimisticMutationResolver<
    MutationCancelSubscriptionArgs,
    WithTypename<CancelSubscriptionResponseUnion>
  >;
  cancelSubscriptionAtCycleEnd?: GraphCacheOptimisticMutationResolver<
    MutationCancelSubscriptionAtCycleEndArgs,
    WithTypename<CancelSubscriptionAtCycleEndResponseUnion>
  >;
  cancelSubscriptionForCustomer?: GraphCacheOptimisticMutationResolver<
    MutationCancelSubscriptionForCustomerArgs,
    WithTypename<CancelSubscriptionForCustomerResponseUnion>
  >;
  challengeOptIn?: GraphCacheOptimisticMutationResolver<
    MutationChallengeOptInArgs,
    WithTypename<Challenge>
  >;
  challengeOptInV2?: GraphCacheOptimisticMutationResolver<
    MutationChallengeOptInV2Args,
    WithTypename<ChallengeOptInResultUnion>
  >;
  createAccount?: GraphCacheOptimisticMutationResolver<
    MutationCreateAccountArgs,
    WithTypename<CreateAccountResponseUnion>
  >;
  createLoyaltyProfile?: GraphCacheOptimisticMutationResolver<
    MutationCreateLoyaltyProfileArgs,
    WithTypename<CreateLoyaltyProfileResult>
  >;
  createPaymentIntent?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Scalars['String']
  >;
  createPaymentSubscriptionProfile?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    WithTypename<PaymentSubscriptionProfile>
  >;
  createSetupPaymentIntent?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    WithTypename<CreateSetupIntent>
  >;
  createSubscription?: GraphCacheOptimisticMutationResolver<
    MutationCreateSubscriptionArgs,
    WithTypename<CreateSubscriptionResult>
  >;
  createSubscriptionV2?: GraphCacheOptimisticMutationResolver<
    MutationCreateSubscriptionV2Args,
    WithTypename<CreateSubscriptionResponseUnion>
  >;
  deleteAddress?: GraphCacheOptimisticMutationResolver<
    MutationDeleteAddressArgs,
    WithTypename<DeleteAddressResponseUnion>
  >;
  deleteBillingAccount?: GraphCacheOptimisticMutationResolver<
    MutationDeleteBillingAccountArgs,
    WithTypename<DeleteBillingAccountResponse>
  >;
  deletePaymentMethodCard?: GraphCacheOptimisticMutationResolver<
    MutationDeletePaymentMethodCardArgs,
    WithTypename<PaymentMethodCard>
  >;
  editLineItemInCart?: GraphCacheOptimisticMutationResolver<
    MutationEditLineItemInCartArgs,
    WithTypename<EditLineItemInCartResponseUnion>
  >;
  favoriteLineItem?: GraphCacheOptimisticMutationResolver<
    MutationFavoriteLineItemArgs,
    WithTypename<LineItem>
  >;
  forgotPassword?: GraphCacheOptimisticMutationResolver<
    MutationForgotPasswordArgs,
    WithTypename<ForgotPasswordResponseUnion>
  >;
  issueCustomerSupportCredit?: GraphCacheOptimisticMutationResolver<
    MutationIssueCustomerSupportCreditArgs,
    WithTypename<IssueCustomerSupportCreditResult>
  >;
  login?: GraphCacheOptimisticMutationResolver<
    MutationLoginArgs,
    WithTypename<LoginResponseUnion>
  >;
  logout?: GraphCacheOptimisticMutationResolver<
    MutationLogoutArgs,
    WithTypename<LogoutResponseUnion>
  >;
  optInFreeTier?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Scalars['Boolean']
  >;
  optInFreeTierV2?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Maybe<WithTypename<LoyaltyProfile>>
  >;
  optOutLoyalty?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    WithTypename<OptOutLoyaltyResponseUnion>
  >;
  optOutLoyaltyForCustomer?: GraphCacheOptimisticMutationResolver<
    MutationOptOutLoyaltyForCustomerArgs,
    WithTypename<OptOutLoyaltyForCustomerResponseUnion>
  >;
  redeemGiftCard?: GraphCacheOptimisticMutationResolver<
    MutationRedeemGiftCardArgs,
    WithTypename<RedeemGiftCardResponseUnion>
  >;
  refundLoyaltyTransactionForCustomer?: GraphCacheOptimisticMutationResolver<
    MutationRefundLoyaltyTransactionForCustomerArgs,
    WithTypename<RefundLoyaltyTransactionResponseUnion>
  >;
  refundOrder?: GraphCacheOptimisticMutationResolver<
    MutationRefundOrderArgs,
    WithTypename<RefundOrderSuccess>
  >;
  register?: GraphCacheOptimisticMutationResolver<
    MutationRegisterArgs,
    WithTypename<RegisterResponseUnion>
  >;
  registerGuest?: GraphCacheOptimisticMutationResolver<
    MutationRegisterGuestArgs,
    Maybe<WithTypename<RegisterGuestResponseUnion>>
  >;
  rejoinSubscription?: GraphCacheOptimisticMutationResolver<
    MutationRejoinSubscriptionArgs,
    WithTypename<RejoinSubscriptionResponseUnion>
  >;
  removeFromCart?: GraphCacheOptimisticMutationResolver<
    MutationRemoveFromCartArgs,
    WithTypename<RemoveFromCartResponseUnion>
  >;
  removeReward?: GraphCacheOptimisticMutationResolver<
    MutationRemoveRewardArgs,
    WithTypename<RemoveRewardResponseUnion>
  >;
  removeSubscriptionPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationRemoveSubscriptionPaymentMethodArgs,
    WithTypename<DeleteSubscriptionPaymentMethodResponseUnion>
  >;
  reorder?: GraphCacheOptimisticMutationResolver<
    MutationReorderArgs,
    WithTypename<ReorderResponseUnion>
  >;
  reorderLineItem?: GraphCacheOptimisticMutationResolver<
    MutationReorderLineItemArgs,
    WithTypename<ReorderResponseUnion>
  >;
  reorderOrder?: GraphCacheOptimisticMutationResolver<
    MutationReorderOrderArgs,
    WithTypename<ReorderResponseUnion>
  >;
  requestAccountDeletion?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Maybe<WithTypename<RequestAccountDeletionResponseUnion>>
  >;
  resendVerificationCode?: GraphCacheOptimisticMutationResolver<
    MutationResendVerificationCodeArgs,
    WithTypename<ResendVerificationCodeResponseUnion>
  >;
  sendLoyaltyTransaction?: GraphCacheOptimisticMutationResolver<
    MutationSendLoyaltyTransactionArgs,
    Scalars['Boolean']
  >;
  setDefaultPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationSetDefaultPaymentMethodArgs,
    WithTypename<PaymentProfile>
  >;
  signIn?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    WithTypename<SignInResponseUnion>
  >;
  submitBraintreeBillingAccount?: GraphCacheOptimisticMutationResolver<
    MutationSubmitBraintreeBillingAccountArgs,
    WithTypename<SubmitBraintreeBillingAccountResponseUnion>
  >;
  submitFeedback?: GraphCacheOptimisticMutationResolver<
    MutationSubmitFeedbackArgs,
    WithTypename<FeedbackSuccess>
  >;
  submitGiftCardOrderUsingPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationSubmitGiftCardOrderUsingPaymentMethodArgs,
    WithTypename<SubmitGiftCardOrderUsingPaymentMethodResponseUnion>
  >;
  submitInStoreFeedback?: GraphCacheOptimisticMutationResolver<
    MutationSubmitInStoreFeedbackArgs,
    WithTypename<FeedbackSuccess>
  >;
  submitOrderAsPayInStore?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderAsPayInStoreArgs,
    WithTypename<SubmitOrderWithPaymentMethodResponseUnion>
  >;
  submitOrderAsPayInStoreASAP?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderAsPayInStoreAsapArgs,
    WithTypename<SubmitOrderWithPaymentMethodResponseUnion>
  >;
  submitOrderAsPrepaid?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderAsPrepaidArgs,
    WithTypename<SubmitOrderWithPaymentMethodResponseUnion>
  >;
  submitOrderAsPrepaidASAP?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderAsPrepaidAsapArgs,
    WithTypename<SubmitOrderWithPaymentMethodResponseUnion>
  >;
  submitOrderUsingBillingAccount?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderUsingBillingAccountArgs,
    WithTypename<SubmitOrderUsingBillingAccountResponseUnion>
  >;
  submitOrderUsingPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderUsingPaymentMethodArgs,
    WithTypename<SubmitOrderUsingPaymentMethodResponseUnion>
  >;
  submitOrderUsingPaymentMethodCard?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderUsingPaymentMethodCardArgs,
    WithTypename<SubmitOrderUsingBillingAccountResponseUnion>
  >;
  submitOrderWithPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationSubmitOrderWithPaymentMethodArgs,
    WithTypename<SubmitOrderWithPaymentMethodResponseUnion>
  >;
  submitPromoCode?: GraphCacheOptimisticMutationResolver<
    MutationSubmitPromoCodeArgs,
    WithTypename<SubmitPromoCodeResponseUnion>
  >;
  submitPromoOrGiftCardCode?: GraphCacheOptimisticMutationResolver<
    MutationSubmitPromoOrGiftCardCodeArgs,
    WithTypename<SubmitPromoCodeOrGiftCardResponseUnion>
  >;
  unlockLoyaltyTransaction?: GraphCacheOptimisticMutationResolver<
    MutationUnlockLoyaltyTransactionArgs,
    Scalars['Boolean']
  >;
  updateAddress?: GraphCacheOptimisticMutationResolver<
    MutationUpdateAddressArgs,
    WithTypename<UpdateAddressResponseUnion>
  >;
  updateBillingAccount?: GraphCacheOptimisticMutationResolver<
    MutationUpdateBillingAccountArgs,
    WithTypename<UpdateBillingAccountResponse>
  >;
  updateCustomer?: GraphCacheOptimisticMutationResolver<
    MutationUpdateCustomerArgs,
    Maybe<WithTypename<UpdateCustomerResponse>>
  >;
  updateKioskCustomer?: GraphCacheOptimisticMutationResolver<
    MutationUpdateKioskCustomerArgs,
    Maybe<WithTypename<UpdateKioskCustomerResponse>>
  >;
  updatePaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationUpdatePaymentMethodArgs,
    WithTypename<PaymentMethodCard>
  >;
  updateSubscriptionPaymentMethod?: GraphCacheOptimisticMutationResolver<
    MutationUpdateSubscriptionPaymentMethodArgs,
    WithTypename<UpdateSubscriptionPaymentMethodSuccessResponse>
  >;
  updateUseCredit?: GraphCacheOptimisticMutationResolver<
    MutationUpdateUseCreditArgs,
    WithTypename<UpdateUseCreditResponse>
  >;
  validateAndPreviewRewards?: GraphCacheOptimisticMutationResolver<
    MutationValidateAndPreviewRewardsArgs,
    WithTypename<RewardsValidateAndPreviewUnion>
  >;
  validateAndReserveRewards?: GraphCacheOptimisticMutationResolver<
    MutationValidateAndReserveRewardsArgs,
    WithTypename<RewardsValidateAndReserveUnion>
  >;
  verifyRegistration?: GraphCacheOptimisticMutationResolver<
    MutationVerifyRegistrationArgs,
    WithTypename<VerifyRegistrationUnion>
  >;
};

export type GraphCacheUpdaters = {
  Query?: {
    addons?: GraphCacheUpdateResolver<
      { addons: WithTypename<Addons> },
      Record<string, never>
    >;
    address?: GraphCacheUpdateResolver<
      { address: Maybe<WithTypename<Address>> },
      QueryAddressArgs
    >;
    allLocations?: GraphCacheUpdateResolver<
      { allLocations: Maybe<Array<WithTypename<LocationSearchResult>>> },
      QueryAllLocationsArgs
    >;
    baseProduct?: GraphCacheUpdateResolver<
      { baseProduct: Maybe<WithTypename<BaseProduct>> },
      QueryBaseProductArgs
    >;
    canCancel?: GraphCacheUpdateResolver<
      { canCancel: Array<WithTypename<CanCancelResponseUnion>> },
      QueryCanCancelArgs
    >;
    cart?: GraphCacheUpdateResolver<
      { cart: Maybe<WithTypename<Order>> },
      Record<string, never>
    >;
    categoryUpsells?: GraphCacheUpdateResolver<
      { categoryUpsells: Array<WithTypename<UpsellCategory>> },
      QueryCategoryUpsellsArgs
    >;
    challengeCelebration?: GraphCacheUpdateResolver<
      { challengeCelebration: Maybe<WithTypename<ChallengeCelebration>> },
      Record<string, never>
    >;
    challengeCelebrationV2?: GraphCacheUpdateResolver<
      { challengeCelebrationV2: Maybe<WithTypename<ChallengeCelebrationV2>> },
      Record<string, never>
    >;
    challengeRewards?: GraphCacheUpdateResolver<
      { challengeRewards: Array<WithTypename<ChallengeReward>> },
      Record<string, never>
    >;
    challenges?: GraphCacheUpdateResolver<
      { challenges: Array<WithTypename<Challenge>> },
      Record<string, never>
    >;
    currentCustomer?: GraphCacheUpdateResolver<
      { currentCustomer: Maybe<WithTypename<Customer>> },
      Record<string, never>
    >;
    customerPass?: GraphCacheUpdateResolver<
      { customerPass: Maybe<WithTypename<CustomerPass>> },
      Record<string, never>
    >;
    customerSupportCredits?: GraphCacheUpdateResolver<
      {
        customerSupportCredits: WithTypename<CustomerSupportCreditsResponseUnion>;
      },
      Record<string, never>
    >;
    favoritedLineItems?: GraphCacheUpdateResolver<
      { favoritedLineItems: Array<WithTypename<LineItem>> },
      QueryFavoritedLineItemsArgs
    >;
    feedbackQuestions?: GraphCacheUpdateResolver<
      { feedbackQuestions: Array<WithTypename<FeedbackQuestion>> },
      QueryFeedbackQuestionsArgs
    >;
    fetchPlansByTierId?: GraphCacheUpdateResolver<
      { fetchPlansByTierId: Maybe<Array<WithTypename<PaymentPlan>>> },
      QueryFetchPlansByTierIdArgs
    >;
    getSubscriptions?: GraphCacheUpdateResolver<
      { getSubscriptions: Array<WithTypename<PaymentSubscription>> },
      QueryGetSubscriptionsArgs
    >;
    giftCardBalance?: GraphCacheUpdateResolver<
      { giftCardBalance: WithTypename<GiftCardBalanceResponseUnion> },
      Record<string, never>
    >;
    giftCardOrderDetail?: GraphCacheUpdateResolver<
      { giftCardOrderDetail: WithTypename<GiftCardOrderDetailResponseUnion> },
      QueryGiftCardOrderDetailArgs
    >;
    giftCardPurchaseHistory?: GraphCacheUpdateResolver<
      { giftCardPurchaseHistory: WithTypename<GiftCardPurchaseHistory> },
      Record<string, never>
    >;
    giftCardRedemptionDetail?: GraphCacheUpdateResolver<
      {
        giftCardRedemptionDetail: WithTypename<GiftCardRedemptionDetailResponseUnion>;
      },
      QueryGiftCardRedemptionDetailArgs
    >;
    hasUnseenChallenges?: GraphCacheUpdateResolver<
      { hasUnseenChallenges: WithTypename<HasUnseenChallenges> },
      Record<string, never>
    >;
    inStoreFeedbackQuestions?: GraphCacheUpdateResolver<
      { inStoreFeedbackQuestions: Array<WithTypename<FeedbackQuestion>> },
      QueryInStoreFeedbackQuestionsArgs
    >;
    inStoreOrderLastPendingFeedback?: GraphCacheUpdateResolver<
      { inStoreOrderLastPendingFeedback: Maybe<WithTypename<InStoreOrder>> },
      Record<string, never>
    >;
    inStorePaymentMethods?: GraphCacheUpdateResolver<
      { inStorePaymentMethods: Array<WithTypename<Credit>> },
      Record<string, never>
    >;
    kustomerToken?: GraphCacheUpdateResolver<
      { kustomerToken: Maybe<WithTypename<Kustomer>> },
      Record<string, never>
    >;
    lineItem?: GraphCacheUpdateResolver<
      { lineItem: Maybe<WithTypename<LineItemResponseUnion>> },
      QueryLineItemArgs
    >;
    lineItemBySlug?: GraphCacheUpdateResolver<
      { lineItemBySlug: Maybe<WithTypename<LineItemResponseUnion>> },
      QueryLineItemBySlugArgs
    >;
    lineItemRecommendations?: GraphCacheUpdateResolver<
      { lineItemRecommendations: Array<WithTypename<LineItemRecommendation>> },
      Record<string, never>
    >;
    locationByAddress?: GraphCacheUpdateResolver<
      { locationByAddress: WithTypename<LocationByAddressResponseUnion> },
      QueryLocationByAddressArgs
    >;
    loyaltyProfile?: GraphCacheUpdateResolver<
      { loyaltyProfile: Maybe<WithTypename<LoyaltyProfile>> },
      Record<string, never>
    >;
    menuCollection?: GraphCacheUpdateResolver<
      { menuCollection: WithTypename<MenuCollection> },
      QueryMenuCollectionArgs
    >;
    menuCollections?: GraphCacheUpdateResolver<
      { menuCollections: Array<WithTypename<MenuCollection>> },
      QueryMenuCollectionsArgs
    >;
    nonRefundedLoyaltyTransactionsForCustomer?: GraphCacheUpdateResolver<
      {
        nonRefundedLoyaltyTransactionsForCustomer: Array<
          WithTypename<SubscriptionTransactions>
        >;
      },
      QueryNonRefundedLoyaltyTransactionsForCustomerArgs
    >;
    order?: GraphCacheUpdateResolver<
      { order: WithTypename<Order> },
      QueryOrderArgs
    >;
    orderLastPendingFeedback?: GraphCacheUpdateResolver<
      { orderLastPendingFeedback: Maybe<WithTypename<Order>> },
      Record<string, never>
    >;
    orderStatus?: GraphCacheUpdateResolver<
      { orderStatus: Maybe<WithTypename<OrderStatusResponseUnion>> },
      QueryOrderStatusArgs
    >;
    orders?: GraphCacheUpdateResolver<
      { orders: WithTypename<OrdersResponseUnion> },
      QueryOrdersArgs
    >;
    ordersV2?: GraphCacheUpdateResolver<
      { ordersV2: WithTypename<OrdersResponseUnion> },
      QueryOrdersV2Args
    >;
    paymentMethodCards?: GraphCacheUpdateResolver<
      { paymentMethodCards: Array<WithTypename<PaymentMethodCardUnion>> },
      Record<string, never>
    >;
    paymentProfile?: GraphCacheUpdateResolver<
      { paymentProfile: Maybe<WithTypename<PaymentProfile>> },
      Record<string, never>
    >;
    paymentSubscriptionProfile?: GraphCacheUpdateResolver<
      {
        paymentSubscriptionProfile: Maybe<
          WithTypename<PaymentSubscriptionProfile>
        >;
      },
      Record<string, never>
    >;
    product?: GraphCacheUpdateResolver<
      { product: WithTypename<Product> },
      QueryProductArgs
    >;
    productForRestaurant?: GraphCacheUpdateResolver<
      { productForRestaurant: Maybe<WithTypename<Product>> },
      QueryProductForRestaurantArgs
    >;
    restaurant?: GraphCacheUpdateResolver<
      { restaurant: Maybe<WithTypename<Restaurant>> },
      QueryRestaurantArgs
    >;
    restaurantCapacity?: GraphCacheUpdateResolver<
      { restaurantCapacity: WithTypename<RestaurantCapacity> },
      QueryRestaurantCapacityArgs
    >;
    rewards?: GraphCacheUpdateResolver<
      { rewards: Array<WithTypename<Reward>> },
      Record<string, never>
    >;
    rewardsForUser?: GraphCacheUpdateResolver<
      { rewardsForUser: Array<WithTypename<ChallengeReward>> },
      Record<string, never>
    >;
    searchLocationsByBoundingBox?: GraphCacheUpdateResolver<
      {
        searchLocationsByBoundingBox: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsByBoundingBoxArgs
    >;
    searchLocationsByEntityId?: GraphCacheUpdateResolver<
      {
        searchLocationsByEntityId: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsByEntityIdArgs
    >;
    searchLocationsByString?: GraphCacheUpdateResolver<
      {
        searchLocationsByString: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsByStringArgs
    >;
    searchLocationsInKilometerRadius?: GraphCacheUpdateResolver<
      {
        searchLocationsInKilometerRadius: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsInKilometerRadiusArgs
    >;
    searchLocationsInMeterRadius?: GraphCacheUpdateResolver<
      {
        searchLocationsInMeterRadius: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsInMeterRadiusArgs
    >;
    searchLocationsInMileRadius?: GraphCacheUpdateResolver<
      {
        searchLocationsInMileRadius: Maybe<
          Array<WithTypename<LocationSearchResult>>
        >;
      },
      QuerySearchLocationsInMileRadiusArgs
    >;
    session?: GraphCacheUpdateResolver<
      { session: WithTypename<Session> },
      Record<string, never>
    >;
    subscriptionPlanById?: GraphCacheUpdateResolver<
      { subscriptionPlanById: Maybe<WithTypename<SubscriptionPlan>> },
      QuerySubscriptionPlanByIdArgs
    >;
    subscriptionPlansByIds?: GraphCacheUpdateResolver<
      { subscriptionPlansByIds: Maybe<Array<WithTypename<SubscriptionPlan>>> },
      QuerySubscriptionPlansByIdsArgs
    >;
  };
  Mutation?: {
    addAddress?: GraphCacheUpdateResolver<
      { addAddress: WithTypename<AddAddressResponseUnion> },
      MutationAddAddressArgs
    >;
    addLineItemBatchToCart?: GraphCacheUpdateResolver<
      { addLineItemBatchToCart: WithTypename<AddLineItemToCartResponseUnion> },
      MutationAddLineItemBatchToCartArgs
    >;
    addLineItemToCart?: GraphCacheUpdateResolver<
      { addLineItemToCart: WithTypename<AddLineItemToCartResponseUnion> },
      MutationAddLineItemToCartArgs
    >;
    addSubscriptionPaymentMethod?: GraphCacheUpdateResolver<
      {
        addSubscriptionPaymentMethod: WithTypename<AddSubscriptionPaymentMethodResponseUnion>;
      },
      MutationAddSubscriptionPaymentMethodArgs
    >;
    applyReward?: GraphCacheUpdateResolver<
      { applyReward: WithTypename<ApplyRewardResponseUnion> },
      MutationApplyRewardArgs
    >;
    cancelOrder?: GraphCacheUpdateResolver<
      { cancelOrder: WithTypename<CancelOrderResponseUnion> },
      MutationCancelOrderArgs
    >;
    cancelSubscription?: GraphCacheUpdateResolver<
      { cancelSubscription: WithTypename<CancelSubscriptionResponseUnion> },
      MutationCancelSubscriptionArgs
    >;
    cancelSubscriptionAtCycleEnd?: GraphCacheUpdateResolver<
      {
        cancelSubscriptionAtCycleEnd: WithTypename<CancelSubscriptionAtCycleEndResponseUnion>;
      },
      MutationCancelSubscriptionAtCycleEndArgs
    >;
    cancelSubscriptionForCustomer?: GraphCacheUpdateResolver<
      {
        cancelSubscriptionForCustomer: WithTypename<CancelSubscriptionForCustomerResponseUnion>;
      },
      MutationCancelSubscriptionForCustomerArgs
    >;
    challengeOptIn?: GraphCacheUpdateResolver<
      { challengeOptIn: WithTypename<Challenge> },
      MutationChallengeOptInArgs
    >;
    challengeOptInV2?: GraphCacheUpdateResolver<
      { challengeOptInV2: WithTypename<ChallengeOptInResultUnion> },
      MutationChallengeOptInV2Args
    >;
    createAccount?: GraphCacheUpdateResolver<
      { createAccount: WithTypename<CreateAccountResponseUnion> },
      MutationCreateAccountArgs
    >;
    createLoyaltyProfile?: GraphCacheUpdateResolver<
      { createLoyaltyProfile: WithTypename<CreateLoyaltyProfileResult> },
      MutationCreateLoyaltyProfileArgs
    >;
    createPaymentIntent?: GraphCacheUpdateResolver<
      { createPaymentIntent: Scalars['String'] },
      Record<string, never>
    >;
    createPaymentSubscriptionProfile?: GraphCacheUpdateResolver<
      {
        createPaymentSubscriptionProfile: WithTypename<PaymentSubscriptionProfile>;
      },
      Record<string, never>
    >;
    createSetupPaymentIntent?: GraphCacheUpdateResolver<
      { createSetupPaymentIntent: WithTypename<CreateSetupIntent> },
      Record<string, never>
    >;
    createSubscription?: GraphCacheUpdateResolver<
      { createSubscription: WithTypename<CreateSubscriptionResult> },
      MutationCreateSubscriptionArgs
    >;
    createSubscriptionV2?: GraphCacheUpdateResolver<
      { createSubscriptionV2: WithTypename<CreateSubscriptionResponseUnion> },
      MutationCreateSubscriptionV2Args
    >;
    deleteAddress?: GraphCacheUpdateResolver<
      { deleteAddress: WithTypename<DeleteAddressResponseUnion> },
      MutationDeleteAddressArgs
    >;
    deleteBillingAccount?: GraphCacheUpdateResolver<
      { deleteBillingAccount: WithTypename<DeleteBillingAccountResponse> },
      MutationDeleteBillingAccountArgs
    >;
    deletePaymentMethodCard?: GraphCacheUpdateResolver<
      { deletePaymentMethodCard: WithTypename<PaymentMethodCard> },
      MutationDeletePaymentMethodCardArgs
    >;
    editLineItemInCart?: GraphCacheUpdateResolver<
      { editLineItemInCart: WithTypename<EditLineItemInCartResponseUnion> },
      MutationEditLineItemInCartArgs
    >;
    favoriteLineItem?: GraphCacheUpdateResolver<
      { favoriteLineItem: WithTypename<LineItem> },
      MutationFavoriteLineItemArgs
    >;
    forgotPassword?: GraphCacheUpdateResolver<
      { forgotPassword: WithTypename<ForgotPasswordResponseUnion> },
      MutationForgotPasswordArgs
    >;
    issueCustomerSupportCredit?: GraphCacheUpdateResolver<
      {
        issueCustomerSupportCredit: WithTypename<IssueCustomerSupportCreditResult>;
      },
      MutationIssueCustomerSupportCreditArgs
    >;
    login?: GraphCacheUpdateResolver<
      { login: WithTypename<LoginResponseUnion> },
      MutationLoginArgs
    >;
    logout?: GraphCacheUpdateResolver<
      { logout: WithTypename<LogoutResponseUnion> },
      MutationLogoutArgs
    >;
    optInFreeTier?: GraphCacheUpdateResolver<
      { optInFreeTier: Scalars['Boolean'] },
      Record<string, never>
    >;
    optInFreeTierV2?: GraphCacheUpdateResolver<
      { optInFreeTierV2: Maybe<WithTypename<LoyaltyProfile>> },
      Record<string, never>
    >;
    optOutLoyalty?: GraphCacheUpdateResolver<
      { optOutLoyalty: WithTypename<OptOutLoyaltyResponseUnion> },
      Record<string, never>
    >;
    optOutLoyaltyForCustomer?: GraphCacheUpdateResolver<
      {
        optOutLoyaltyForCustomer: WithTypename<OptOutLoyaltyForCustomerResponseUnion>;
      },
      MutationOptOutLoyaltyForCustomerArgs
    >;
    redeemGiftCard?: GraphCacheUpdateResolver<
      { redeemGiftCard: WithTypename<RedeemGiftCardResponseUnion> },
      MutationRedeemGiftCardArgs
    >;
    refundLoyaltyTransactionForCustomer?: GraphCacheUpdateResolver<
      {
        refundLoyaltyTransactionForCustomer: WithTypename<RefundLoyaltyTransactionResponseUnion>;
      },
      MutationRefundLoyaltyTransactionForCustomerArgs
    >;
    refundOrder?: GraphCacheUpdateResolver<
      { refundOrder: WithTypename<RefundOrderSuccess> },
      MutationRefundOrderArgs
    >;
    register?: GraphCacheUpdateResolver<
      { register: WithTypename<RegisterResponseUnion> },
      MutationRegisterArgs
    >;
    registerGuest?: GraphCacheUpdateResolver<
      { registerGuest: Maybe<WithTypename<RegisterGuestResponseUnion>> },
      MutationRegisterGuestArgs
    >;
    rejoinSubscription?: GraphCacheUpdateResolver<
      { rejoinSubscription: WithTypename<RejoinSubscriptionResponseUnion> },
      MutationRejoinSubscriptionArgs
    >;
    removeFromCart?: GraphCacheUpdateResolver<
      { removeFromCart: WithTypename<RemoveFromCartResponseUnion> },
      MutationRemoveFromCartArgs
    >;
    removeReward?: GraphCacheUpdateResolver<
      { removeReward: WithTypename<RemoveRewardResponseUnion> },
      MutationRemoveRewardArgs
    >;
    removeSubscriptionPaymentMethod?: GraphCacheUpdateResolver<
      {
        removeSubscriptionPaymentMethod: WithTypename<DeleteSubscriptionPaymentMethodResponseUnion>;
      },
      MutationRemoveSubscriptionPaymentMethodArgs
    >;
    reorder?: GraphCacheUpdateResolver<
      { reorder: WithTypename<ReorderResponseUnion> },
      MutationReorderArgs
    >;
    reorderLineItem?: GraphCacheUpdateResolver<
      { reorderLineItem: WithTypename<ReorderResponseUnion> },
      MutationReorderLineItemArgs
    >;
    reorderOrder?: GraphCacheUpdateResolver<
      { reorderOrder: WithTypename<ReorderResponseUnion> },
      MutationReorderOrderArgs
    >;
    requestAccountDeletion?: GraphCacheUpdateResolver<
      {
        requestAccountDeletion: Maybe<
          WithTypename<RequestAccountDeletionResponseUnion>
        >;
      },
      Record<string, never>
    >;
    resendVerificationCode?: GraphCacheUpdateResolver<
      {
        resendVerificationCode: WithTypename<ResendVerificationCodeResponseUnion>;
      },
      MutationResendVerificationCodeArgs
    >;
    sendLoyaltyTransaction?: GraphCacheUpdateResolver<
      { sendLoyaltyTransaction: Scalars['Boolean'] },
      MutationSendLoyaltyTransactionArgs
    >;
    setDefaultPaymentMethod?: GraphCacheUpdateResolver<
      { setDefaultPaymentMethod: WithTypename<PaymentProfile> },
      MutationSetDefaultPaymentMethodArgs
    >;
    signIn?: GraphCacheUpdateResolver<
      { signIn: WithTypename<SignInResponseUnion> },
      Record<string, never>
    >;
    submitBraintreeBillingAccount?: GraphCacheUpdateResolver<
      {
        submitBraintreeBillingAccount: WithTypename<SubmitBraintreeBillingAccountResponseUnion>;
      },
      MutationSubmitBraintreeBillingAccountArgs
    >;
    submitFeedback?: GraphCacheUpdateResolver<
      { submitFeedback: WithTypename<FeedbackSuccess> },
      MutationSubmitFeedbackArgs
    >;
    submitGiftCardOrderUsingPaymentMethod?: GraphCacheUpdateResolver<
      {
        submitGiftCardOrderUsingPaymentMethod: WithTypename<SubmitGiftCardOrderUsingPaymentMethodResponseUnion>;
      },
      MutationSubmitGiftCardOrderUsingPaymentMethodArgs
    >;
    submitInStoreFeedback?: GraphCacheUpdateResolver<
      { submitInStoreFeedback: WithTypename<FeedbackSuccess> },
      MutationSubmitInStoreFeedbackArgs
    >;
    submitOrderAsPayInStore?: GraphCacheUpdateResolver<
      {
        submitOrderAsPayInStore: WithTypename<SubmitOrderWithPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderAsPayInStoreArgs
    >;
    submitOrderAsPayInStoreASAP?: GraphCacheUpdateResolver<
      {
        submitOrderAsPayInStoreASAP: WithTypename<SubmitOrderWithPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderAsPayInStoreAsapArgs
    >;
    submitOrderAsPrepaid?: GraphCacheUpdateResolver<
      {
        submitOrderAsPrepaid: WithTypename<SubmitOrderWithPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderAsPrepaidArgs
    >;
    submitOrderAsPrepaidASAP?: GraphCacheUpdateResolver<
      {
        submitOrderAsPrepaidASAP: WithTypename<SubmitOrderWithPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderAsPrepaidAsapArgs
    >;
    submitOrderUsingBillingAccount?: GraphCacheUpdateResolver<
      {
        submitOrderUsingBillingAccount: WithTypename<SubmitOrderUsingBillingAccountResponseUnion>;
      },
      MutationSubmitOrderUsingBillingAccountArgs
    >;
    submitOrderUsingPaymentMethod?: GraphCacheUpdateResolver<
      {
        submitOrderUsingPaymentMethod: WithTypename<SubmitOrderUsingPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderUsingPaymentMethodArgs
    >;
    submitOrderUsingPaymentMethodCard?: GraphCacheUpdateResolver<
      {
        submitOrderUsingPaymentMethodCard: WithTypename<SubmitOrderUsingBillingAccountResponseUnion>;
      },
      MutationSubmitOrderUsingPaymentMethodCardArgs
    >;
    submitOrderWithPaymentMethod?: GraphCacheUpdateResolver<
      {
        submitOrderWithPaymentMethod: WithTypename<SubmitOrderWithPaymentMethodResponseUnion>;
      },
      MutationSubmitOrderWithPaymentMethodArgs
    >;
    submitPromoCode?: GraphCacheUpdateResolver<
      { submitPromoCode: WithTypename<SubmitPromoCodeResponseUnion> },
      MutationSubmitPromoCodeArgs
    >;
    submitPromoOrGiftCardCode?: GraphCacheUpdateResolver<
      {
        submitPromoOrGiftCardCode: WithTypename<SubmitPromoCodeOrGiftCardResponseUnion>;
      },
      MutationSubmitPromoOrGiftCardCodeArgs
    >;
    unlockLoyaltyTransaction?: GraphCacheUpdateResolver<
      { unlockLoyaltyTransaction: Scalars['Boolean'] },
      MutationUnlockLoyaltyTransactionArgs
    >;
    updateAddress?: GraphCacheUpdateResolver<
      { updateAddress: WithTypename<UpdateAddressResponseUnion> },
      MutationUpdateAddressArgs
    >;
    updateBillingAccount?: GraphCacheUpdateResolver<
      { updateBillingAccount: WithTypename<UpdateBillingAccountResponse> },
      MutationUpdateBillingAccountArgs
    >;
    updateCustomer?: GraphCacheUpdateResolver<
      { updateCustomer: Maybe<WithTypename<UpdateCustomerResponse>> },
      MutationUpdateCustomerArgs
    >;
    updateKioskCustomer?: GraphCacheUpdateResolver<
      { updateKioskCustomer: Maybe<WithTypename<UpdateKioskCustomerResponse>> },
      MutationUpdateKioskCustomerArgs
    >;
    updatePaymentMethod?: GraphCacheUpdateResolver<
      { updatePaymentMethod: WithTypename<PaymentMethodCard> },
      MutationUpdatePaymentMethodArgs
    >;
    updateSubscriptionPaymentMethod?: GraphCacheUpdateResolver<
      {
        updateSubscriptionPaymentMethod: WithTypename<UpdateSubscriptionPaymentMethodSuccessResponse>;
      },
      MutationUpdateSubscriptionPaymentMethodArgs
    >;
    updateUseCredit?: GraphCacheUpdateResolver<
      { updateUseCredit: WithTypename<UpdateUseCreditResponse> },
      MutationUpdateUseCreditArgs
    >;
    validateAndPreviewRewards?: GraphCacheUpdateResolver<
      {
        validateAndPreviewRewards: WithTypename<RewardsValidateAndPreviewUnion>;
      },
      MutationValidateAndPreviewRewardsArgs
    >;
    validateAndReserveRewards?: GraphCacheUpdateResolver<
      {
        validateAndReserveRewards: WithTypename<RewardsValidateAndReserveUnion>;
      },
      MutationValidateAndReserveRewardsArgs
    >;
    verifyRegistration?: GraphCacheUpdateResolver<
      { verifyRegistration: WithTypename<VerifyRegistrationUnion> },
      MutationVerifyRegistrationArgs
    >;
  };
  Subscription?: {};
  AccountLocked?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AccountLocked>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AccountLocked>>,
      Record<string, never>
    >;
  };
  ActivePaymentMethodError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivePaymentMethodError>>,
      Record<string, never>
    >;
  };
  AddAddressSuccess?: {
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AddAddressSuccess>>,
      Record<string, never>
    >;
  };
  AddCardFunctionalityDeprecated?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AddCardFunctionalityDeprecated>>,
      Record<string, never>
    >;
  };
  AddLineItemToCartSuccess?: {
    cart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AddLineItemToCartSuccess>>,
      Record<string, never>
    >;
  };
  AddSubscriptionPaymentMethodSuccessResponse?: {
    creditCard?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AddSubscriptionPaymentMethodSuccessResponse>>,
      Record<string, never>
    >;
    paymentMethodToken?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AddSubscriptionPaymentMethodSuccessResponse>>,
      Record<string, never>
    >;
  };
  Addons?: {
    products?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Addons>>,
      Record<string, never>
    >;
  };
  Address?: {
    city?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    country?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    deliveryPreference?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    googlePlaceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    latitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    longitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    secondaryStreet?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    street?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
    zipCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Address>>,
      Record<string, never>
    >;
  };
  ApplyRewardFailureMetadata?: {
    requiredChannel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ApplyRewardFailureMetadata>>,
      Record<string, never>
    >;
    requiredDays?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ApplyRewardFailureMetadata>>,
      Record<string, never>
    >;
  };
  ApplyRewardSuccess?: {
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ApplyRewardSuccess>>,
      Record<string, never>
    >;
  };
  Asset?: {
    url?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
  };
  AvailableWantedTime?: {
    deliveryOffset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AvailableWantedTime>>,
      Record<string, never>
    >;
    time?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AvailableWantedTime>>,
      Record<string, never>
    >;
  };
  BaseProduct?: {
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    heading?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    isDeepLinkingEnabled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    isSeasonal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
    subheading?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BaseProduct>>,
      Record<string, never>
    >;
  };
  BillingAccount?: {
    cardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
    expirationState?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
    isDefault?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
    nickname?: GraphCacheUpdateResolver<
      Maybe<WithTypename<BillingAccount>>,
      Record<string, never>
    >;
  };
  CXCredit?: {
    displayName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CxCredit>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CxCredit>>,
      Record<string, never>
    >;
  };
  CanCancel?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancel>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancel>>,
      Record<string, never>
    >;
    timeToFire?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancel>>,
      Record<string, never>
    >;
  };
  CanCancelAlreadySent?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelAlreadySent>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelAlreadySent>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelAlreadySent>>,
      Record<string, never>
    >;
  };
  CanCancelFailed?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelFailed>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelFailed>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelFailed>>,
      Record<string, never>
    >;
  };
  CanCancelNotFound?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelNotFound>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelNotFound>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelNotFound>>,
      Record<string, never>
    >;
  };
  CanCancelUnauthorized?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelUnauthorized>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelUnauthorized>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanCancelUnauthorized>>,
      Record<string, never>
    >;
  };
  CanNotReorder?: {
    conflicts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanNotReorder>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanNotReorder>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CanNotReorder>>,
      Record<string, never>
    >;
  };
  CancelOrderFailed?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderFailed>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderFailed>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderFailed>>,
      Record<string, never>
    >;
  };
  CancelOrderSuccess?: {
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderSuccess>>,
      Record<string, never>
    >;
    reason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderSuccess>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelOrderSuccess>>,
      Record<string, never>
    >;
  };
  CancelSubscriptionAtCycleEndSuccessResponse?: {
    subscription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSubscriptionAtCycleEndSuccessResponse>>,
      Record<string, never>
    >;
  };
  CancelSubscriptionForCustomerSuccessResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSubscriptionForCustomerSuccessResponse>>,
      Record<string, never>
    >;
  };
  CancelSubscriptionSuccessResponse?: {
    subscription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSubscriptionSuccessResponse>>,
      Record<string, never>
    >;
  };
  CancelSuccessRefundFailed?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSuccessRefundFailed>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSuccessRefundFailed>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancelSuccessRefundFailed>>,
      Record<string, never>
    >;
  };
  CancellationFailedWithDelivery?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationFailedWithDelivery>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationFailedWithDelivery>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationFailedWithDelivery>>,
      Record<string, never>
    >;
  };
  CancellationLimitExceeded?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationLimitExceeded>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationLimitExceeded>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CancellationLimitExceeded>>,
      Record<string, never>
    >;
  };
  CannotRefundSubscriptionTransactionUserError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CannotRefundSubscriptionTransactionUserError>>,
      Record<string, never>
    >;
  };
  CantCancelAlreadyCanceled?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelAlreadyCanceled>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelAlreadyCanceled>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelAlreadyCanceled>>,
      Record<string, never>
    >;
  };
  CantCancelLimitExcceeded?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelLimitExcceeded>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelLimitExcceeded>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelLimitExcceeded>>,
      Record<string, never>
    >;
  };
  CantCancelOldOrders?: {
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelOldOrders>>,
      Record<string, never>
    >;
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelOldOrders>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CantCancelOldOrders>>,
      Record<string, never>
    >;
  };
  Challenge?: {
    achieved?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    celebrationCopy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    descriptiveTitle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    expirationDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    goal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    legalTerms?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    percentageCompleted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    permalink?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    progress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    progressCopy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    theme?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Challenge>>,
      Record<string, never>
    >;
  };
  ChallengeCelebration?: {
    celebrationCopy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebration>>,
      Record<string, never>
    >;
    challengesIds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebration>>,
      Record<string, never>
    >;
    expirationDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebration>>,
      Record<string, never>
    >;
    rewardUsageDisclaimer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebration>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebration>>,
      Record<string, never>
    >;
  };
  ChallengeCelebrationV2?: {
    celebrationCopy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebrationV2>>,
      Record<string, never>
    >;
    challengesIds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebrationV2>>,
      Record<string, never>
    >;
    expirationDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebrationV2>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeCelebrationV2>>,
      Record<string, never>
    >;
  };
  ChallengeReward?: {
    expiresAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    redeemable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    redeemableAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    redemptionChannel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    rewardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
    terms?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ChallengeReward>>,
      Record<string, never>
    >;
  };
  CourierDetails?: {
    latitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CourierDetails>>,
      Record<string, never>
    >;
    longitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CourierDetails>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CourierDetails>>,
      Record<string, never>
    >;
    phone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CourierDetails>>,
      Record<string, never>
    >;
  };
  CreateAccountSuccess?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateAccountSuccess>>,
      Record<string, never>
    >;
  };
  CreateLoyaltyProfileResult?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateLoyaltyProfileResult>>,
      Record<string, never>
    >;
  };
  CreateSetupIntent?: {
    clientSecret?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSetupIntent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSetupIntent>>,
      Record<string, never>
    >;
  };
  CreateSubscriptionResult?: {
    firstBillingDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    hasFreeTrial?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    isTrialActive?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    subscriptionAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
    trialDuration?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CreateSubscriptionResult>>,
      Record<string, never>
    >;
  };
  Credit?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    creditType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    expiresAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    remainingAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    storefrontEligibilityTitle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Credit>>,
      Record<string, never>
    >;
  };
  Customer?: {
    addresses?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    availableCredit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    billingAccounts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    birthday?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    credits?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    deletionInProgress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    email?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    firstName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    lastName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    phoneNumber?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    referralShareUrlEmail?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    trackingUuid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
    useCredit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Customer>>,
      Record<string, never>
    >;
  };
  CustomerNotFound?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CustomerNotFound>>,
      Record<string, never>
    >;
  };
  CustomerPass?: {
    authenticationToken?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CustomerPass>>,
      Record<string, never>
    >;
    url?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CustomerPass>>,
      Record<string, never>
    >;
  };
  CustomerSupportCreditsFailureError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CustomerSupportCreditsFailureError>>,
      Record<string, never>
    >;
  };
  CustomerSupportCreditsSuccessResponse?: {
    credits?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CustomerSupportCreditsSuccessResponse>>,
      Record<string, never>
    >;
  };
  DeclinedPaymentMethod?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeclinedPaymentMethod>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeclinedPaymentMethod>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeclinedPaymentMethod>>,
      Record<string, never>
    >;
  };
  DeleteAddressSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeleteAddressSuccess>>,
      Record<string, never>
    >;
  };
  DeleteBillingAccountResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeleteBillingAccountResponse>>,
      Record<string, never>
    >;
  };
  DeleteSubscriptionPaymentMethodSuccess?: {
    creditCards?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeleteSubscriptionPaymentMethodSuccess>>,
      Record<string, never>
    >;
  };
  DeliveryAlreadyInFlight?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryAlreadyInFlight>>,
      Record<string, never>
    >;
  };
  DeliveryOrderDetail?: {
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    deliveryFee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    estimatedDeliveryTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    tip?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    vendor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
    vendorRestaurantId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliveryOrderDetail>>,
      Record<string, never>
    >;
  };
  DeliverySpecifyAddress?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DeliverySpecifyAddress>>,
      Record<string, never>
    >;
  };
  DietaryProperty?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DietaryProperty>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DietaryProperty>>,
      Record<string, never>
    >;
  };
  Discount?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Discount>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Discount>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Discount>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Discount>>,
      Record<string, never>
    >;
  };
  DropOffLocation?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DropOffLocation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<DropOffLocation>>,
      Record<string, never>
    >;
  };
  EditLineItemInCartSuccess?: {
    cart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<EditLineItemInCartSuccess>>,
      Record<string, never>
    >;
  };
  EstimateRange?: {
    end?: GraphCacheUpdateResolver<
      Maybe<WithTypename<EstimateRange>>,
      Record<string, never>
    >;
    start?: GraphCacheUpdateResolver<
      Maybe<WithTypename<EstimateRange>>,
      Record<string, never>
    >;
  };
  FeedbackQuestion?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackQuestion>>,
      Record<string, never>
    >;
    responses?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackQuestion>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackQuestion>>,
      Record<string, never>
    >;
    text?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackQuestion>>,
      Record<string, never>
    >;
  };
  FeedbackResponse?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackResponse>>,
      Record<string, never>
    >;
    text?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackResponse>>,
      Record<string, never>
    >;
  };
  FeedbackSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FeedbackSuccess>>,
      Record<string, never>
    >;
  };
  FieldValidationError?: {
    field?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FieldValidationError>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FieldValidationError>>,
      Record<string, never>
    >;
  };
  ForgotPasswordSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ForgotPasswordSuccess>>,
      Record<string, never>
    >;
  };
  FreeTrialNotEligible?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<FreeTrialNotEligible>>,
      Record<string, never>
    >;
  };
  GiftCardAssociatedWithAnotherAccountError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardAssociatedWithAnotherAccountError>>,
      Record<string, never>
    >;
  };
  GiftCardBalance?: {
    customerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardBalance>>,
      Record<string, never>
    >;
    giftCardBalance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardBalance>>,
      Record<string, never>
    >;
  };
  GiftCardDeliveryDetail?: {
    deliveryDateTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardDeliveryDetail>>,
      Record<string, never>
    >;
    deliveryMethods?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardDeliveryDetail>>,
      Record<string, never>
    >;
    isAsap?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardDeliveryDetail>>,
      Record<string, never>
    >;
  };
  GiftCardNotFoundError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardNotFoundError>>,
      Record<string, never>
    >;
  };
  GiftCardOrderDetail?: {
    deliveryDetail?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardOrderDetail>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardOrderDetail>>,
      Record<string, never>
    >;
    purchasedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardOrderDetail>>,
      Record<string, never>
    >;
    userGiftCards?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardOrderDetail>>,
      Record<string, never>
    >;
  };
  GiftCardOrderNotFoundError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardOrderNotFoundError>>,
      Record<string, never>
    >;
  };
  GiftCardPurchaseHistory?: {
    customerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardPurchaseHistory>>,
      Record<string, never>
    >;
    giftCardOrders?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardPurchaseHistory>>,
      Record<string, never>
    >;
  };
  GiftCardRedemptionDetailSuccess?: {
    assetId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    balance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    cardNumber?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    cardRegCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    recipientMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    recipientName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    redeemId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
    senderName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<GiftCardRedemptionDetailSuccess>>,
      Record<string, never>
    >;
  };
  Guest?: {
    email?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Guest>>,
      Record<string, never>
    >;
    firstName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Guest>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Guest>>,
      Record<string, never>
    >;
    lastName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Guest>>,
      Record<string, never>
    >;
  };
  HasUnseenChallenges?: {
    hasUnseenChallenges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<HasUnseenChallenges>>,
      Record<string, never>
    >;
  };
  InStoreOrder?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InStoreOrder>>,
      Record<string, never>
    >;
    wantedTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InStoreOrder>>,
      Record<string, never>
    >;
  };
  IncorrectPaymentMethod?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IncorrectPaymentMethod>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IncorrectPaymentMethod>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IncorrectPaymentMethod>>,
      Record<string, never>
    >;
  };
  Ingredient?: {
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ingredient>>,
      Record<string, never>
    >;
    dietaryProperties?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ingredient>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ingredient>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ingredient>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ingredient>>,
      Record<string, never>
    >;
  };
  IngredientModification?: {
    additionCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    calories?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    ingredient?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    isGrain?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    mixable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    outOfStock?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    removalCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    subKind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
    substitutionCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModification>>,
      Record<string, never>
    >;
  };
  IngredientModificationKind?: {
    allowMultipleQuantity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModificationKind>>,
      Record<string, never>
    >;
    freeAggregateQuantity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModificationKind>>,
      Record<string, never>
    >;
    maxAggregateQuantity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModificationKind>>,
      Record<string, never>
    >;
    minAggregateQuantity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModificationKind>>,
      Record<string, never>
    >;
    modifications?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModificationKind>>,
      Record<string, never>
    >;
  };
  IngredientModifications?: {
    bases?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModifications>>,
      Record<string, never>
    >;
    bread?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModifications>>,
      Record<string, never>
    >;
    dressings?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModifications>>,
      Record<string, never>
    >;
    premiums?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModifications>>,
      Record<string, never>
    >;
    toppings?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IngredientModifications>>,
      Record<string, never>
    >;
  };
  InvalidAddress?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidAddress>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidAddress>>,
      Record<string, never>
    >;
  };
  InvalidBillingAccount?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidBillingAccount>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidBillingAccount>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidBillingAccount>>,
      Record<string, never>
    >;
  };
  InvalidCredentials?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidCredentials>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidCredentials>>,
      Record<string, never>
    >;
  };
  InvalidCustomerPhone?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidCustomerPhone>>,
      Record<string, never>
    >;
  };
  InvalidGiftCardError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidGiftCardError>>,
      Record<string, never>
    >;
  };
  InvalidInput?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidInput>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidInput>>,
      Record<string, never>
    >;
  };
  InvalidOrder?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidOrder>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidOrder>>,
      Record<string, never>
    >;
  };
  InvalidPaymentMethod?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidPaymentMethod>>,
      Record<string, never>
    >;
  };
  InvalidPostalCode?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidPostalCode>>,
      Record<string, never>
    >;
  };
  InvalidPromoCode?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidPromoCode>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidPromoCode>>,
      Record<string, never>
    >;
  };
  InvalidRegistrationCode?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidRegistrationCode>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidRegistrationCode>>,
      Record<string, never>
    >;
  };
  InvalidSubmittedBillingAccount?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidSubmittedBillingAccount>>,
      Record<string, never>
    >;
  };
  InvalidTip?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidTip>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidTip>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<InvalidTip>>,
      Record<string, never>
    >;
  };
  IssueCustomerSupportCreditResult?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IssueCustomerSupportCreditResult>>,
      Record<string, never>
    >;
  };
  Kustomer?: {
    token?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Kustomer>>,
      Record<string, never>
    >;
  };
  Ledger?: {
    credits?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    creditsTotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    discounts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    discountsTotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    fees?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    feesTotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    subtotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    tax?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
    tip?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Ledger>>,
      Record<string, never>
    >;
  };
  LedgerItem?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LedgerItem>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LedgerItem>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LedgerItem>>,
      Record<string, never>
    >;
  };
  LineItem?: {
    addedIngredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    calories?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    cost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    customName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    customerName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    dressingMode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    favorited?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    ingredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    isCustom?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    mixedDressingDetails?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    perItemCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    product?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    quantity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    removedIngredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItem>>,
      Record<string, never>
    >;
  };
  LineItemModificationsInvalidException?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemModificationsInvalidException>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemModificationsInvalidException>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemModificationsInvalidException>>,
      Record<string, never>
    >;
  };
  LineItemNotFound?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemNotFound>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemNotFound>>,
      Record<string, never>
    >;
  };
  LineItemRecommendation?: {
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    ingredientIds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    ingredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
    recommendationType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemRecommendation>>,
      Record<string, never>
    >;
  };
  LineItemSuccess?: {
    lineItem?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LineItemSuccess>>,
      Record<string, never>
    >;
  };
  Location?: {
    estimateRange?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Location>>,
      Record<string, never>
    >;
    restaurant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Location>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Location>>,
      Record<string, never>
    >;
    vendor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Location>>,
      Record<string, never>
    >;
  };
  LocationSearchResult?: {
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LocationSearchResult>>,
      Record<string, never>
    >;
    score?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LocationSearchResult>>,
      Record<string, never>
    >;
  };
  LoginSuccess?: {
    csrf?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoginSuccess>>,
      Record<string, never>
    >;
  };
  LogoutSuccess?: {
    csrf?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LogoutSuccess>>,
      Record<string, never>
    >;
  };
  LoyaltyOptInFailed?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyOptInFailed>>,
      Record<string, never>
    >;
  };
  LoyaltyProfile?: {
    customerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    hasPaidTierHistory?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    joinDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    loyaltyId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    tier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    tierStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
    trialEligible?: GraphCacheUpdateResolver<
      Maybe<WithTypename<LoyaltyProfile>>,
      Record<string, never>
    >;
  };
  MaxModificationsExceeded?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MaxModificationsExceeded>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MaxModificationsExceeded>>,
      Record<string, never>
    >;
  };
  Menu?: {
    categories?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Menu>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Menu>>,
      Record<string, never>
    >;
  };
  MenuCategory?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCategory>>,
      Record<string, never>
    >;
    isCustom?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCategory>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCategory>>,
      Record<string, never>
    >;
    products?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCategory>>,
      Record<string, never>
    >;
  };
  MenuCollection?: {
    color?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    primaryDescription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    primaryImageUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    primaryTitle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    products?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    secondaryDescription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
    secondaryTitle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MenuCollection>>,
      Record<string, never>
    >;
  };
  MinimumDeliverySubtotalNotMet?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MinimumDeliverySubtotalNotMet>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MinimumDeliverySubtotalNotMet>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MinimumDeliverySubtotalNotMet>>,
      Record<string, never>
    >;
  };
  MixedDressingDetails?: {
    ingredientId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MixedDressingDetails>>,
      Record<string, never>
    >;
    weight?: GraphCacheUpdateResolver<
      Maybe<WithTypename<MixedDressingDetails>>,
      Record<string, never>
    >;
  };
  NoBalanceGiftCardError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<NoBalanceGiftCardError>>,
      Record<string, never>
    >;
  };
  NoValidRestaurants?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<NoValidRestaurants>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<NoValidRestaurants>>,
      Record<string, never>
    >;
  };
  OptOutLoyaltyForCustomerSuccessResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OptOutLoyaltyForCustomerSuccessResponse>>,
      Record<string, never>
    >;
  };
  OptOutLoyaltySuccessResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OptOutLoyaltySuccessResponse>>,
      Record<string, never>
    >;
  };
  Order?: {
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    availableWantedTimes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    canCancel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    canTrackOrderStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    deliveryOrderDetail?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    dropoffLocation?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    includeUtensils?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    isCanceled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    isInFlight?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    isInFlightDelivery?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    isPendingFeedback?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    ledger?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    lineItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    orderType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    payments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    restaurant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    selectedRewards?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    subtotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    total?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    vendorId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
    wantedTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Order>>,
      Record<string, never>
    >;
  };
  OrderAlreadyCanceled?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadyCanceled>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadyCanceled>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadyCanceled>>,
      Record<string, never>
    >;
  };
  OrderAlreadySentToKitchen?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadySentToKitchen>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadySentToKitchen>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderAlreadySentToKitchen>>,
      Record<string, never>
    >;
  };
  OrderConflict?: {
    product?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderConflict>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderConflict>>,
      Record<string, never>
    >;
    unavailableIngredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderConflict>>,
      Record<string, never>
    >;
  };
  OrderNotFound?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderNotFound>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderNotFound>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderNotFound>>,
      Record<string, never>
    >;
  };
  OrderStatus?: {
    courierDetails?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    customerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    flattenedOrderStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    gravyStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    gravyUpdatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    orderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    orderOccasion?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
    orderStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrderStatus>>,
      Record<string, never>
    >;
  };
  OrdersResponseSuccess?: {
    orders?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrdersResponseSuccess>>,
      Record<string, never>
    >;
    pagination?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OrdersResponseSuccess>>,
      Record<string, never>
    >;
  };
  Pagination?: {
    last?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Pagination>>,
      Record<string, never>
    >;
    page?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Pagination>>,
      Record<string, never>
    >;
    take?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Pagination>>,
      Record<string, never>
    >;
    total?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Pagination>>,
      Record<string, never>
    >;
  };
  Payment?: {
    amountInCents?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Payment>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Payment>>,
      Record<string, never>
    >;
    paymentTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Payment>>,
      Record<string, never>
    >;
    processor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Payment>>,
      Record<string, never>
    >;
  };
  PaymentMethodAlreadyExistsError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodAlreadyExistsError>>,
      Record<string, never>
    >;
  };
  PaymentMethodBillingAccount?: {
    cardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
    expirationState?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
    isDefault?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
    nickname?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodBillingAccount>>,
      Record<string, never>
    >;
  };
  PaymentMethodCard?: {
    cardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
    expirationState?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
    isDefault?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
    nickname?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentMethodCard>>,
      Record<string, never>
    >;
  };
  PaymentPlan?: {
    braintreePlanId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentPlan>>,
      Record<string, never>
    >;
    tier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentPlan>>,
      Record<string, never>
    >;
  };
  PaymentProfile?: {
    defaultCardId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentProfile>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentProfile>>,
      Record<string, never>
    >;
  };
  PaymentSubscription?: {
    balance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    billingFrequencyUnit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    billingHistory?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    billingPeriodEndDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    billingPeriodStartDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    billingRecurrence?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    firstBillingDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    hasFreeTrial?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    isTrialActive?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    nextBillingDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    nextBillingPeriodAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    paidThroughDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    planId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    price?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscription>>,
      Record<string, never>
    >;
  };
  PaymentSubscriptionProfile?: {
    creditCards?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscriptionProfile>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscriptionProfile>>,
      Record<string, never>
    >;
    transactionHistory?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PaymentSubscriptionProfile>>,
      Record<string, never>
    >;
  };
  Product?: {
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    brinkPlu?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    calories?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    categoryId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    categoryName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    channelCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      ProductChannelCostArgs
    >;
    cost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    customType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    dietaryProperties?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    expirationDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    ingredientModifications?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    ingredients?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    isCustom?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    isModifiable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    isSalad?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    label?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    maxModifications?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    outOfStock?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    restaurant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    restaurantId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
    throttleItem?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Product>>,
      Record<string, never>
    >;
  };
  ProductLabel?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductLabel>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductLabel>>,
      Record<string, never>
    >;
  };
  ProductOutOfStock?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductOutOfStock>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductOutOfStock>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductOutOfStock>>,
      Record<string, never>
    >;
  };
  ProductUnavailable?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductUnavailable>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductUnavailable>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ProductUnavailable>>,
      Record<string, never>
    >;
  };
  PromoCodeAlreadyApplied?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PromoCodeAlreadyApplied>>,
      Record<string, never>
    >;
  };
  RefundLoyaltyTransactionSuccessResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RefundLoyaltyTransactionSuccessResponse>>,
      Record<string, never>
    >;
  };
  RefundOrderSuccess?: {
    refundResults?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RefundOrderSuccess>>,
      Record<string, never>
    >;
  };
  RefundResult?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RefundResult>>,
      Record<string, never>
    >;
    reason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RefundResult>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RefundResult>>,
      Record<string, never>
    >;
  };
  RegisterGuestFailed?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RegisterGuestFailed>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RegisterGuestFailed>>,
      Record<string, never>
    >;
  };
  RegisterGuestSuccess?: {
    csrf?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RegisterGuestSuccess>>,
      Record<string, never>
    >;
    guest?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RegisterGuestSuccess>>,
      Record<string, never>
    >;
  };
  RegisterSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RegisterSuccess>>,
      Record<string, never>
    >;
  };
  RejoinSubscriptionSuccessResponse?: {
    subscription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RejoinSubscriptionSuccessResponse>>,
      Record<string, never>
    >;
  };
  RemoveFromCartSuccess?: {
    cart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RemoveFromCartSuccess>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RemoveFromCartSuccess>>,
      Record<string, never>
    >;
  };
  RemoveRewardSuccess?: {
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RemoveRewardSuccess>>,
      Record<string, never>
    >;
  };
  ReorderConflict?: {
    conflicts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ReorderConflict>>,
      Record<string, never>
    >;
    isNothingAvailable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ReorderConflict>>,
      Record<string, never>
    >;
  };
  ReorderSuccess?: {
    cart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ReorderSuccess>>,
      Record<string, never>
    >;
    isMixingSupported?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ReorderSuccess>>,
      Record<string, never>
    >;
  };
  RequestAccountDeletionFailed?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RequestAccountDeletionFailed>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RequestAccountDeletionFailed>>,
      Record<string, never>
    >;
  };
  RequestAccountDeletionSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RequestAccountDeletionSuccess>>,
      Record<string, never>
    >;
  };
  ResendVerificationCodeSuccess?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ResendVerificationCodeSuccess>>,
      Record<string, never>
    >;
  };
  Restaurant?: {
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    availableDropOffLocations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    city?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    deliveryFee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    deliveryMinSubtotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    entityId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    flexMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    hours?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    isAcceptingOrders?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    isOutpost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    latitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    longitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    menu?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    notAcceptingOrdersReason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    parentId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    phone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    showDeliveryFeeDisclosure?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    showDeliveryPriceDifferenciationDisclosure?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    showOutpostPriceDifferenciationDisclosure?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    utcOffset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
    zipCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Restaurant>>,
      Record<string, never>
    >;
  };
  RestaurantCapacity?: {
    availableWantedTimes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacity>>,
      Record<string, never>
    >;
    restaurantAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacity>>,
      Record<string, never>
    >;
    restaurantHours?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacity>>,
      Record<string, never>
    >;
    restaurantId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacity>>,
      Record<string, never>
    >;
  };
  RestaurantCapacitySlot?: {
    availableLineItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacitySlot>>,
      Record<string, never>
    >;
    formatted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacitySlot>>,
      Record<string, never>
    >;
    maxLineItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacitySlot>>,
      Record<string, never>
    >;
    original?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantCapacitySlot>>,
      Record<string, never>
    >;
  };
  RestaurantHours?: {
    formatted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantHours>>,
      Record<string, never>
    >;
    permanent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantHours>>,
      Record<string, never>
    >;
    store?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantHours>>,
      Record<string, never>
    >;
  };
  RestaurantIsNotAcceptingOrders?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantIsNotAcceptingOrders>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantIsNotAcceptingOrders>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantIsNotAcceptingOrders>>,
      Record<string, never>
    >;
  };
  RestaurantMaxDeliveryQuantityExceeded?: {
    conflicts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxDeliveryQuantityExceeded>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxDeliveryQuantityExceeded>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxDeliveryQuantityExceeded>>,
      Record<string, never>
    >;
  };
  RestaurantMaxQuantityExceeded?: {
    conflicts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxQuantityExceeded>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxQuantityExceeded>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantMaxQuantityExceeded>>,
      Record<string, never>
    >;
  };
  RestaurantStoreHours?: {
    day?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantStoreHours>>,
      Record<string, never>
    >;
    end?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantStoreHours>>,
      Record<string, never>
    >;
    start?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RestaurantStoreHours>>,
      Record<string, never>
    >;
  };
  Reward?: {
    expirationDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
    redeemable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
    redeemableAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
    rewardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Reward>>,
      Record<string, never>
    >;
  };
  RewardDiscount?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    appliedTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    discountSource?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    discountedLineIdQuantities?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    discountedLineIds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    displayName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    imageUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    offerCustomData?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    posDiscountId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    referenceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    referenceIdType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    stackOrder?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    userId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
    userOfferCustomData?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardDiscount>>,
      Record<string, never>
    >;
  };
  RewardNotApplied?: {
    failureCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotApplied>>,
      Record<string, never>
    >;
    failureMetadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotApplied>>,
      Record<string, never>
    >;
    failureReasons?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotApplied>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotApplied>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotApplied>>,
      Record<string, never>
    >;
  };
  RewardNotRemoved?: {
    failureCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotRemoved>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotRemoved>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotRemoved>>,
      Record<string, never>
    >;
  };
  RewardNotValidated?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotValidated>>,
      Record<string, never>
    >;
    failureCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotValidated>>,
      Record<string, never>
    >;
    failureMetadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotValidated>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotValidated>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardNotValidated>>,
      Record<string, never>
    >;
  };
  RewardValidateAndPreviewError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndPreviewError>>,
      Record<string, never>
    >;
    failureMetadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndPreviewError>>,
      Record<string, never>
    >;
    failureReasons?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndPreviewError>>,
      Record<string, never>
    >;
    offerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndPreviewError>>,
      Record<string, never>
    >;
  };
  RewardValidateAndReserveError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndReserveError>>,
      Record<string, never>
    >;
    failureMetadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndReserveError>>,
      Record<string, never>
    >;
    failureReasons?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndReserveError>>,
      Record<string, never>
    >;
    offerId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidateAndReserveError>>,
      Record<string, never>
    >;
  };
  RewardValidationFailureMetadata?: {
    requiredChannel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidationFailureMetadata>>,
      Record<string, never>
    >;
    requiredDays?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidationFailureMetadata>>,
      Record<string, never>
    >;
  };
  RewardValidationGeneralError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardValidationGeneralError>>,
      Record<string, never>
    >;
  };
  RewardsValidateAndPreviewSuccess?: {
    rewardDiscountPreviews?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardsValidateAndPreviewSuccess>>,
      Record<string, never>
    >;
  };
  RewardsValidateAndReserveSuccess?: {
    reservedRewardDiscounts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<RewardsValidateAndReserveSuccess>>,
      Record<string, never>
    >;
  };
  Session?: {
    cart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
    csrf?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
    inFlightDeliveryOrderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
    inFlightOrderId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
    isLoggedIn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
  };
  SignInFailed?: {
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SignInFailed>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SignInFailed>>,
      Record<string, never>
    >;
  };
  SignInSuccess?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SignInSuccess>>,
      Record<string, never>
    >;
  };
  StoreLocation?: {
    acceptingOrders?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    city?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    comingSoon?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    crossStreet?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    enabled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    entityId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    flexMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    hidden?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    imageUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    isOutpost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    latitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    longitude?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    menuId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    nativeDeliveryPriceDifferentiationEnabled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    notAcceptingOrdersReason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    oloId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    outpostPriceDifferentiationEnabled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    phone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    pickupInstructions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    regionId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    showWarningDialog?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    storeHours?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    warningDialogDescription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    warningDialogTimeout?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    warningDialogTitle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
    zipCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StoreLocation>>,
      Record<string, never>
    >;
  };
  SubmitBraintreeBillingAccountSuccessResponse?: {
    billingAccount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitBraintreeBillingAccountSuccessResponse>>,
      Record<string, never>
    >;
  };
  SubmitGiftCardCodeSuccess?: {
    totalCredit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitGiftCardCodeSuccess>>,
      Record<string, never>
    >;
  };
  SubmitGiftCardOrderError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitGiftCardOrderError>>,
      Record<string, never>
    >;
  };
  SubmitOrderUsingBillingAccountSuccess?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingBillingAccountSuccess>>,
      Record<string, never>
    >;
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingBillingAccountSuccess>>,
      Record<string, never>
    >;
  };
  SubmitOrderUsingPaymentMethodCardSuccess?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingPaymentMethodCardSuccess>>,
      Record<string, never>
    >;
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingPaymentMethodCardSuccess>>,
      Record<string, never>
    >;
  };
  SubmitOrderUsingPaymentMethodSuccess?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingPaymentMethodSuccess>>,
      Record<string, never>
    >;
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderUsingPaymentMethodSuccess>>,
      Record<string, never>
    >;
  };
  SubmitOrderWithPaymentMethodSuccess?: {
    order?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitOrderWithPaymentMethodSuccess>>,
      Record<string, never>
    >;
  };
  SubmitPromoCodeSuccess?: {
    loyaltyId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitPromoCodeSuccess>>,
      Record<string, never>
    >;
  };
  SubmitPromoCodeSuccessV2?: {
    placeholder?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubmitPromoCodeSuccessV2>>,
      Record<string, never>
    >;
  };
  SubscriptionCannotBeReactivatedError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCannotBeReactivatedError>>,
      Record<string, never>
    >;
  };
  SubscriptionCreditCard?: {
    cardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    expirationMonth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    expirationState?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    expirationYear?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    isActive?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
    lastFour?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionCreditCard>>,
      Record<string, never>
    >;
  };
  SubscriptionPlan?: {
    billingDayOfMonth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    billingFrequency?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    billingFrequencyUnit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    billingRecurrence?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    currencyIsoCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    discounts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    hasFreeTrial?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
    price?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionPlan>>,
      Record<string, never>
    >;
  };
  SubscriptionTransactions?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
    creditCard?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
    date?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
    refund?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
    transactionCreditCard?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SubscriptionTransactions>>,
      Record<string, never>
    >;
  };
  Tier?: {
    displayName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tier>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tier>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tier>>,
      Record<string, never>
    >;
    nextTier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tier>>,
      Record<string, never>
    >;
    tierGroup?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tier>>,
      Record<string, never>
    >;
  };
  TierGroup?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TierGroup>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TierGroup>>,
      Record<string, never>
    >;
  };
  TierStatus?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TierStatus>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TierStatus>>,
      Record<string, never>
    >;
  };
  TimeslotUnavailable?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TimeslotUnavailable>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TimeslotUnavailable>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TimeslotUnavailable>>,
      Record<string, never>
    >;
  };
  TooManyRequests?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TooManyRequests>>,
      Record<string, never>
    >;
  };
  TransactionCreditCard?: {
    cardType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransactionCreditCard>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransactionCreditCard>>,
      Record<string, never>
    >;
    lastFour?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransactionCreditCard>>,
      Record<string, never>
    >;
  };
  TrialEligibility?: {
    duration?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TrialEligibility>>,
      Record<string, never>
    >;
    durationUnit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TrialEligibility>>,
      Record<string, never>
    >;
  };
  UnableToGetGiftCardBalanceError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UnableToGetGiftCardBalanceError>>,
      Record<string, never>
    >;
  };
  UnableToRedeemGiftCardError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UnableToRedeemGiftCardError>>,
      Record<string, never>
    >;
  };
  UnknownUserError?: {
    errorMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UnknownUserError>>,
      Record<string, never>
    >;
  };
  UpdateAddressSuccess?: {
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateAddressSuccess>>,
      Record<string, never>
    >;
  };
  UpdateBillingAccountResponse?: {
    billingAccount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateBillingAccountResponse>>,
      Record<string, never>
    >;
  };
  UpdateCustomerResponse?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateCustomerResponse>>,
      Record<string, never>
    >;
  };
  UpdateKioskCustomerResponse?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateKioskCustomerResponse>>,
      Record<string, never>
    >;
  };
  UpdateSubscriptionPaymentMethodSuccessResponse?: {
    subscription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateSubscriptionPaymentMethodSuccessResponse>>,
      Record<string, never>
    >;
  };
  UpdateUseCreditResponse?: {
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpdateUseCreditResponse>>,
      Record<string, never>
    >;
  };
  UpsellCategory?: {
    categoryRanking?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategory>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategory>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategory>>,
      Record<string, never>
    >;
    products?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategory>>,
      Record<string, never>
    >;
    restaurantId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategory>>,
      Record<string, never>
    >;
  };
  UpsellCategoryProduct?: {
    cost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
    imageUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
    menuId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
    outOfStock?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UpsellCategoryProduct>>,
      Record<string, never>
    >;
  };
  UserGiftCard?: {
    assetId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
    balance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
    recipientEmail?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
    recipientName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<UserGiftCard>>,
      Record<string, never>
    >;
  };
  ValidateRewardFailureMetadata?: {
    requiredChannel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ValidateRewardFailureMetadata>>,
      Record<string, never>
    >;
    requiredDays?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ValidateRewardFailureMetadata>>,
      Record<string, never>
    >;
  };
  ValidationError?: {
    fieldErrors?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ValidationError>>,
      Record<string, never>
    >;
    message?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ValidationError>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ValidationError>>,
      Record<string, never>
    >;
  };
  Vendor?: {
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Vendor>>,
      Record<string, never>
    >;
    restaurantId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Vendor>>,
      Record<string, never>
    >;
  };
  VerifyRegistrationSuccess?: {
    csrf?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VerifyRegistrationSuccess>>,
      Record<string, never>
    >;
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VerifyRegistrationSuccess>>,
      Record<string, never>
    >;
  };
};

export type GraphCacheConfig = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
};

export type Operations = {
  query: {
    CustomerName: CustomerNameQuery;
    PaymentProfile: PaymentProfileQuery;
    Cart: CartQuery;
    Addons: AddonsQuery;
    ChallengeCelebration: ChallengeCelebrationQuery;
    ChallengeCelebrationModal: ChallengeCelebrationModalQuery;
    GiftCardBalance: GiftCardBalanceQuery;
    PickupOutpostRestaurantName: PickupOutpostRestaurantNameQuery;
    ActiveCartDeliveryDetails: ActiveCartDeliveryDetailsQuery;
    CustomerAddresses: CustomerAddressesQuery;
    DeliveryLocationByAddress: DeliveryLocationByAddressQuery;
    DeliveryLocationByAddressWithDisclosureFields: DeliveryLocationByAddressWithDisclosureFieldsQuery;
    LastPlacedOrderDetails: LastPlacedOrderDetailsQuery;
    LocationsSearchByArea: LocationsSearchByAreaQuery;
    LocationsSearchByAreaWithDisclosureFields: LocationsSearchByAreaWithDisclosureFieldsQuery;
    LocationsSearchBySearchString: LocationsSearchBySearchStringQuery;
    LocationsSearchBySearchStringWithDisclosureFields: LocationsSearchBySearchStringWithDisclosureFieldsQuery;
    LineItemRecommendations: LineItemRecommendationsQuery;
    SweetpassInfoForAccountDeletion: SweetpassInfoForAccountDeletionQuery;
    SweetpassBillingHistory: SweetpassBillingHistoryQuery;
    Customer: CustomerQuery;
    Challenges: ChallengesQuery;
    ChallengesWithTheme: ChallengesWithThemeQuery;
    HasUnseenChallenges: HasUnseenChallengesQuery;
    Rewards: RewardsQuery;
    CurrentCustomer: CurrentCustomerQuery;
    Orders: OrdersQuery;
    CustomerCredits: CustomerCreditsQuery;
    DeliveryInFlight: DeliveryInFlightQuery;
    InStoreOrderLastPendingFeedback: InStoreOrderLastPendingFeedbackQuery;
    PaymentMethodCards: PaymentMethodCardsQuery;
    GetSession: GetSessionQuery;
    GetPaymentSubscriptionProfile: GetPaymentSubscriptionProfileQuery;
    SweetpassNextBillingDate: SweetpassNextBillingDateQuery;
    SweetpassPaymentMethods: SweetpassPaymentMethodsQuery;
    SubscriptionPlanById: SubscriptionPlanByIdQuery;
    SubscriptionPlansByIds: SubscriptionPlansByIdsQuery;
    SweetpassTier: SweetpassTierQuery;
    KustomerToken: KustomerTokenQuery;
    CategoryUpsells: CategoryUpsellsQuery;
    GetAvailableWantedTimes: GetAvailableWantedTimesQuery;
    DeliveryEstimate: DeliveryEstimateQuery;
    Address: AddressQuery;
    DeliveryRestaurant: DeliveryRestaurantQuery;
    Favorites: FavoritesQuery;
    GiftCardPurchasePaymentProfile: GiftCardPurchasePaymentProfileQuery;
    GiftCardOrderDetail: GiftCardOrderDetailQuery;
    GiftCardRedemptionDetail: GiftCardRedemptionDetailQuery;
    GetOrderStatus: GetOrderStatusQuery;
    MenuCart: MenuCartQuery;
    Restaurant: RestaurantQuery;
    MenuRedirect: MenuRedirectQuery;
    BaseProduct: BaseProductQuery;
    OrderProductRestaurant: OrderProductRestaurantQuery;
    ProductForRestaurant: ProductForRestaurantQuery;
    OrderStatus: OrderStatusQuery;
    OrderStatusPolling: OrderStatusPollingQuery;
    CanCancelOrder: CanCancelOrderQuery;
    GiftCardPurchaseHistory: GiftCardPurchaseHistoryQuery;
    OrderHistory: OrderHistoryQuery;
    OrdersV2: OrdersV2Query;
    CanCancelOrders: CanCancelOrdersQuery;
    GetLineItem: GetLineItemQuery;
    GetProduct: GetProductQuery;
    CustomerProfileFields: CustomerProfileFieldsQuery;
    FeedbackQuestions: FeedbackQuestionsQuery;
    InStoreFeedbackQuestions: InStoreFeedbackQuestionsQuery;
    FeedbackOrderStatus: FeedbackOrderStatusQuery;
    ReferralShareUrl: ReferralShareUrlQuery;
    ReorderRestaurant: ReorderRestaurantQuery;
    ReorderRestaurantData: ReorderRestaurantDataQuery;
    ReorderDeliveryRestaurantData: ReorderDeliveryRestaurantDataQuery;
    ReorderDeliveryAddressData: ReorderDeliveryAddressDataQuery;
    InStoreGiftCards: InStoreGiftCardsQuery;
    GetSharedLineItem: GetSharedLineItemQuery;
    ChallengeRewards: ChallengeRewardsQuery;
    ChallengeRewardsWithRedemptionChannel: ChallengeRewardsWithRedemptionChannelQuery;
    SweetpassMembership: SweetpassMembershipQuery;
    FetchPlansByTierId: FetchPlansByTierIdQuery;
  };
  mutation: {
    AddAddress: AddAddressMutation;
    UpdateAddress: UpdateAddressMutation;
    DeleteAddress: DeleteAddressMutation;
    SetDefaultPaymentMethod: SetDefaultPaymentMethodMutation;
    AddLineItemToCart: AddLineItemToCartMutation;
    EditLineItemInCart: EditLineItemInCartMutation;
    RemoveFromCart: RemoveFromCartMutation;
    AddDeliveryAddress: AddDeliveryAddressMutation;
    CancelOrder: CancelOrderMutation;
    RedeemGiftCard: RedeemGiftCardMutation;
    RequestAccountDeletion: RequestAccountDeletionMutation;
    CreateAccount: CreateAccountMutation;
    SignInCustomer: SignInCustomerMutation;
    ChallengeOptIn: ChallengeOptInMutation;
    ChallengeOptInV2: ChallengeOptInV2Mutation;
    ApplyReward: ApplyRewardMutation;
    RemoveReward: RemoveRewardMutation;
    UpdateUseCredit: UpdateUseCreditMutation;
    ForgotPassword: ForgotPasswordMutation;
    Login: LoginMutation;
    Logout: LogoutMutation;
    Register: RegisterMutation;
    ResendVerificationCode: ResendVerificationCodeMutation;
    VerifyRegistration: VerifyRegistrationMutation;
    DeletePaymentMethodCard: DeletePaymentMethodCardMutation;
    SubmitPromoOrGiftCardCode: SubmitPromoOrGiftCardCodeMutation;
    LogoutCustomer: LogoutCustomerMutation;
    SubmitPaymentMethod: SubmitPaymentMethodMutation;
    SubmitBraintreeBillingAccount: SubmitBraintreeBillingAccountMutation;
    AddSweetpassPaymentMethod: AddSweetpassPaymentMethodMutation;
    UpdateSweetpassPaymentMethod: UpdateSweetpassPaymentMethodMutation;
    RemoveSweetpassPaymentMethod: RemoveSweetpassPaymentMethodMutation;
    Subscribe: SubscribeMutation;
    UpdatePaymentMethod: UpdatePaymentMethodMutation;
    UpdateBillingAccount: UpdateBillingAccountMutation;
    CreatePaymentIntent: CreatePaymentIntentMutation;
    SubmitOrderUsingPaymentMethodCard: SubmitOrderUsingPaymentMethodCardMutation;
    SubmitOrderUsingPaymentMethod: SubmitOrderUsingPaymentMethodMutation;
    SubmitGiftCardOrderUsingPaymentMethod: SubmitGiftCardOrderUsingPaymentMethodMutation;
    SignIn: SignInMutation;
    LogInCustomer: LogInCustomerMutation;
    FavoriteLineItem: FavoriteLineItemMutation;
    UpdateCustomerProfile: UpdateCustomerProfileMutation;
    SubmitFeedback: SubmitFeedbackMutation;
    SubmitInStoreFeedback: SubmitInStoreFeedbackMutation;
    ReorderOrder: ReorderOrderMutation;
    ReorderLineItem: ReorderLineItemMutation;
    CancelSubscription: CancelSubscriptionMutation;
    CancelSubscriptionAtCycleEnd: CancelSubscriptionAtCycleEndMutation;
    RejoinSubscription: RejoinSubscriptionMutation;
    UpdateSubscriptionPaymentMethod: UpdateSubscriptionPaymentMethodMutation;
    OptInUser: OptInUserMutation;
    OptInUserV2: OptInUserV2Mutation;
  };
  subscription: {};
};
