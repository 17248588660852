import React, { memo, useCallback, useEffect } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { DisplayText, FocusScope, TextSpace } from '@sg/garnish';
import { type DeliveryOrderDetailInput } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { LoadingAnimation } from '@order/components';
import { Bag } from '@order/features/ordering';
import { type Product } from '@order/graphql';
import { useNavigateToMenu } from '@order/hooks';
import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { CategoryUpsells } from '../../../components';
import { useCloseBag } from '../../../hooks';
import {
  useBagContent,
  type useOrderState,
  useRewardsPromoHeading,
  useUpsells,
  useViewMenuRendering,
} from '../../hooks';
import { BagLineItems } from '../BagLineItems';

export const BagContent = memo((props: BagContentProps) => {
  const { orderState } = props;

  // ─── Flags ────────────────────────────────────────────────────────────────

  const {
    isBagEmpty,
    isBagValid,
    isFetchingInitialData,
    isApplyingPromoCode,
    isSubmittingOrder,
    isShowingFutureDateWarning,
    isShowingRewardWarning,
    isPerformingBagAction,
    isShowingWarning,
    isShowingModal,
    isPostOrder,
  } = orderState;

  const isFocusable = !isShowingModal && !isShowingWarning;

  // ─── Context ──────────────────────────────────────────────────────────────

  const {
    bagTracking,
    wantedTime = '',
    dropoffLocation = '',
    rewardIdBeingUpdated,
    lineItemIdsBeingUpdated,
    cart,
    rewards,
    rewardError,
    appliedRewardId,
    shouldIncludeUtensils,
    customer: { isNewCustomer },
  } = orderState;

  // ─── Cart ─────────────────────────────────────────────────────────────────

  const {
    orderId = '',
    orderChannel = 'pickup',
    locationName = '',
    restaurantId = '',
    restaurantSlug = '',
    deliveryOrderDetail,
    dropoffLocations,
    addressId,
    lineItems,
    ledger,
  } = cart;

  // ─── Actions ──────────────────────────────────────────────────────────────

  const {
    startOrdering,
    addUpsell,
    changeLineItemQuantity,
    changeLineItemName,
    applyBagReward,
    removeBagReward,
    applyBagPromoCode,
    cancelRequest,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
    requestTimeChange,
    changeDropoffLocation,
    changeUtensilsUsage,
    requestCheckoutLedger,
  } = orderState;

  // Fetch ordering data on bag focus.
  useFocusEffect(startOrdering as () => void);

  // ─── Tracking ─────────────────────────────────────────────────────────────

  const {
    trackViewMenuPressed,
    trackLineItemPressed,
    trackLineItemNameEditPressed,
  } = bagTracking;

  // ─── Navigation ───────────────────────────────────────────────────────────

  const { shouldRenderViewMenu, shouldShowFloatingViewMenu } =
    useViewMenuRendering();

  const navigation = useNavigation();
  const { closeBag } = useCloseBag();

  const handleViewMenu = useNavigateToLastInteractedStore();
  const navigateToMenu = useNavigateToMenu();
  const navigateToBagMenu = useCallback(() => {
    trackViewMenuPressed();
    navigateToMenu({
      orderType: orderChannel,
      restaurantSlug,
      deliveryOrderDetail: { addressId },
    });
  }, [
    orderChannel,
    restaurantSlug,
    addressId,
    navigateToMenu,
    trackViewMenuPressed,
  ]);

  // ─── Removing native header ───────────────────────────────────────────────
  // This is removed here for now because the other bag screen (v1) still uses it.

  useEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation]);

  // ─── Auth State ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const handleContinue = useCallback(() => {
    if (!isLoggedIn) {
      navigation.navigate('Auth', {
        screen: 'Login',
        params: { redirect: 'bag' },
      });

      return;
    }

    requestCheckoutLedger();
  }, [isLoggedIn, navigation, requestCheckoutLedger]);

  // ─── Promo Code New Customer Filtering ────────────────────────────────────

  const isPromoCodeNewCustomerFilteringEnabled = useFeatureFlag(
    'CELS-2917-reject-promo-code-previous-orders-enabled',
  );

  const shouldShowPromoCodeField =
    isLoggedIn && (!isPromoCodeNewCustomerFilteringEnabled || isNewCustomer);

  // ─── Rewards / Promo Heading ──────────────────────────────────────────────

  const rewardsPromoHeading = useRewardsPromoHeading({
    hasRewards: rewards.length > 0,
    shouldShowPromoCodeField,
  });

  // ─── Contentful ───────────────────────────────────────────────────────────

  const { viewMenuCta, menuIconCta, continueToCheckoutCta } = useBagContent();

  // ─── Channel Font Size ────────────────────────────────────────────────────

  const channelFontSize = locationName.length > 24 ? '24' : '32';

  // ─── Upsells ──────────────────────────────────────────────────────────────

  const {
    categoryUpsells,
    isLoadingUpsells,
    shouldRenderCategoryUpsells,
    hideCategoryUpsells,
  } = useUpsells({ orderId, restaurantId, orderChannel, lineItems });

  const addUpsellToBag = useCallback(
    (payload: {
      product: Partial<Product>;
      deliveryDetails?: DeliveryOrderDetailInput;
    }) => {
      const { product, deliveryDetails } = payload;

      addUpsell({
        productId: product.id ?? '',
        productName: product.name ?? '',
        deliveryDetails,
      });
    },
    [addUpsell],
  );

  // ──────────────────────────────────────────────────────────────────────────

  if (shouldRenderCategoryUpsells) {
    return (
      <FocusScope enabled={isFocusable}>
        <CategoryUpsells
          categories={categoryUpsells}
          deliveryOrderDetail={deliveryOrderDetail}
          upsellIdsBeingAdded={lineItemIdsBeingUpdated}
          addUpsellToBag={addUpsellToBag}
          onClose={hideCategoryUpsells}
        />
      </FocusScope>
    );
  }

  return (
    <FocusScope enabled={isFocusable}>
      {/* Loading state for the bag. */}
      {isFetchingInitialData || isLoadingUpsells || isPostOrder ? (
        <LoadingAnimation />
      ) : null}

      {/* Empty state for the bag. */}
      {isBagEmpty ? <Bag.EmptyState onViewMenu={handleViewMenu} /> : null}

      {/* Loading state overlay for when actions are performed in the bag, not needed for initial fetching or placing orders. */}
      <Bag.LoadingState
        isLoading={Boolean(
          isPerformingBagAction && !isFetchingInitialData && !isSubmittingOrder,
        )}
      />

      {/* Warning that the selected date is in the future. */}
      <Bag.WarningModal visible={isShowingWarning} onClose={cancelRequest}>
        {isShowingFutureDateWarning ? (
          <Bag.FutureDateWarning onClose={cancelRequest} />
        ) : null}

        {isShowingRewardWarning ? (
          <Bag.RewardWarning message={rewardError} onClose={cancelRequest} />
        ) : null}
      </Bag.WarningModal>

      {/* Actual bag contents. */}
      {isBagValid && !isLoadingUpsells ? (
        <Bag.ScrollView
          orderChannel={orderChannel}
          locationName={locationName}
          requestChannelOrLocationChange={requestChannelOrLocationChange}
          requestDeliveryPreferencesChange={requestDeliveryPreferencesChange}
          onHeaderCloseBtnPress={closeBag}
        >
          <Bag.ContentContainer>
            {/* Header with channel, location, time and dropoff location. */}
            <Bag.HeaderContainer onClose={closeBag}>
              <Bag.HeaderLine sizeMatch={[channelFontSize]}>
                <Bag.ChannelHeader
                  orderChannel={orderChannel}
                  requestChannelOrLocationChange={
                    requestChannelOrLocationChange
                  }
                />

                <TextSpace />

                <Bag.TimeConnector time={wantedTime} />

                <TextSpace />

                <Bag.TimeHeader
                  time={wantedTime}
                  requestTimeChange={requestTimeChange}
                />
              </Bag.HeaderLine>

              <Bag.HeaderLine sizeMatch={[channelFontSize]}>
                {orderChannel === 'pickup' ? (
                  <Bag.LocationFromConnector />
                ) : null}

                {orderChannel === 'delivery' ? (
                  <Bag.LocationToConnector />
                ) : null}

                {orderChannel === 'outpost' ? null : <TextSpace />}

                <Bag.LocationHeader
                  orderChannel={orderChannel}
                  locationName={locationName}
                  requestChannelOrLocationChange={
                    requestChannelOrLocationChange
                  }
                  requestDeliveryPreferencesChange={
                    requestDeliveryPreferencesChange
                  }
                />
              </Bag.HeaderLine>
            </Bag.HeaderContainer>

            {orderChannel !== 'delivery' && dropoffLocations.length >= 2 ? (
              <Bag.DropoffLocationPicker
                selectedDropoffLocationId={dropoffLocation}
                firstDropoffLocation={dropoffLocations[0]}
                secondDropoffLocation={dropoffLocations[1]}
                changeDropoffLocation={changeDropoffLocation}
              />
            ) : null}

            {/* View menu CTA */}
            {shouldRenderViewMenu && !shouldShowFloatingViewMenu ? (
              <Bag.ViewMenuCta
                cta={viewMenuCta}
                isDisabled={isPerformingBagAction}
                onPress={navigateToBagMenu}
              />
            ) : null}

            {/* List of line items, able to edit quantity or change name. */}
            <BagLineItems
              lineItems={lineItems}
              restaurantSlug={restaurantSlug}
              addressId={addressId}
              isDelivery={orderChannel === 'delivery'}
              isDisabled={isPerformingBagAction}
              trackLineItemPressed={trackLineItemPressed}
              trackLineItemNameEditPressed={trackLineItemNameEditPressed}
              changeLineItemQuantity={changeLineItemQuantity}
              changeLineItemName={changeLineItemName}
            />

            {/* Rewards rail and add promo code field. */}
            {rewards.length > 0 || shouldShowPromoCodeField ? (
              <Bag.PromoRewardsContainer>
                {rewards.length > 0 ? (
                  <Bag.RewardsRail heading={rewardsPromoHeading}>
                    {rewards.map((reward) => {
                      const isApplied = appliedRewardId === reward.id;
                      const onPress = isApplied
                        ? removeBagReward
                        : applyBagReward;

                      const isLoading = rewardIdBeingUpdated === reward.id;
                      const isDisabled = isPerformingBagAction && !isLoading;

                      return (
                        <Bag.Reward
                          key={reward.id}
                          id={reward.id}
                          title={reward.name}
                          expirationDate={reward.expirationDate}
                          isApplied={isApplied}
                          isLoading={isLoading}
                          isDisabled={isDisabled}
                          onPress={onPress}
                        />
                      );
                    })}
                  </Bag.RewardsRail>
                ) : null}

                {shouldShowPromoCodeField ? (
                  <>
                    {rewards.length === 0 ? (
                      <DisplayText sizeMatch={['24']}>
                        {rewardsPromoHeading}
                      </DisplayText>
                    ) : null}

                    <Bag.PromoField
                      rewards={rewards}
                      isLoading={isApplyingPromoCode}
                      onPress={applyBagPromoCode}
                      isDisabled={Boolean(
                        isPerformingBagAction && !isApplyingPromoCode,
                      )}
                    />
                  </>
                ) : null}
              </Bag.PromoRewardsContainer>
            ) : null}

            {orderChannel === 'delivery' ? (
              <Bag.UtensilsCta
                shouldIncludeUtensils={shouldIncludeUtensils}
                isDisabled={isPerformingBagAction}
                onChange={changeUtensilsUsage}
              />
            ) : null}
          </Bag.ContentContainer>

          <Bag.Footer>
            <Bag.Subtotal subtotal={ledger?.subtotal ?? 0} />
          </Bag.Footer>
        </Bag.ScrollView>
      ) : null}

      {isBagValid && !isLoadingUpsells ? (
        <Bag.FloatingCtas>
          {shouldRenderViewMenu && shouldShowFloatingViewMenu ? (
            <Bag.MenuIconCta
              cta={menuIconCta}
              isDisabled={Boolean(isPerformingBagAction && !isShowingModal)}
              onPress={navigateToBagMenu}
            />
          ) : null}

          <Bag.ContinueToCheckoutCta
            cta={continueToCheckoutCta}
            isDisabled={Boolean(isPerformingBagAction && !isShowingModal)}
            onPress={handleContinue}
          />
        </Bag.FloatingCtas>
      ) : null}
    </FocusScope>
  );
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagContentProps = {
  orderState: ReturnType<typeof useOrderState>;
};
