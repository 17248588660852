import React from 'react';
import { FormattedDate } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Header } from '@expo/html-elements';
import { BodyText, theme, useResponsive, webOnlyStyles } from '@sg/garnish';

import { ignoreTimezone } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersListOrderHeader = (props: OrdersListOrderHeaderProps) => {
  const { date, location } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerStyles = [styles.outerContainer, outerContainerWebStyles];

  const headerStyles = [
    styles.header,
    match([styles.headerXS, styles.headerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={outerContainerStyles}>
      <Header style={headerStyles}>
        <View style={styles.contentContainer}>
          <BodyText sizeMatch={['14']}>
            <FormattedDate
              value={ignoreTimezone(date)}
              year="2-digit"
              month="2-digit"
              day="2-digit"
            />
          </BodyText>

          <BodyText sizeMatch={['14']} style={styles.headerLocationText}>
            {location}
          </BodyText>
        </View>
      </Header>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: theme.colors.OATMEAL,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['2'],
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    paddingVertical: theme.spacing['4'],
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    columnGap: theme.spacing['2'],
  },
  headerXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  headerSM: {
    paddingHorizontal: theme.spacing['6'],
  },
  headerLocationText: {
    flex: 1,
    color: theme.colors.CHARCOAL,
  },
});

const outerContainerWebStyles = webOnlyStyles({
  position: 'sticky',
  top: 0,
  zIndex: 1,
} as unknown as Parameters<typeof webOnlyStyles>[0]);

// ─── Types ───────────────────────────────────────────────────────────────────

type OrdersListOrderHeaderProps = {
  date: string; // ISO date string
  location: string;
};
