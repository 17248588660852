import React from 'react';
import { StyleSheet } from 'react-native';
import { Container, theme } from '@sg/garnish';

import { AccountMenu, PageWrapper } from '@order/components';

export const AccountScreenContent = () => {
  return (
    <PageWrapper style={styles.container} safeAreaEdges={['top']}>
      <Container style={styles.contentContainer}>
        <AccountMenu />
      </Container>
    </PageWrapper>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.KHAKI_3,
  },
  contentContainer: {
    paddingVertical: theme.spacing['4'],
  },
});
