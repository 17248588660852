import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HStack, Modal, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const CreditCardFormFooter = (props: CreditCardFormFooterProps) => {
  const { mode, isSaving, isDeleting, canSubmit, onDelete, onSubmit } = props;

  const { t } = useLocalizationContext();
  const isEditing = mode === 'edit';

  return (
    <Modal.Footer style={styles.footer} withoutTopBorder>
      <HStack itemsPerRow={isEditing ? 2 : 1} gap={theme.spacing['4']}>
        {isEditing ? (
          <View style={styles.ctaWrapper}>
            <Button
              testID="credit-card-form.remove"
              size="large-wide"
              palette="caution"
              isLoading={isDeleting}
              accessibilityRole="button"
              accessibilityLabel={t('credit-card-form.remove')}
              accessibilityHint={t('credit-card-form.remove')}
              onPress={onDelete}
            >
              {t('credit-card-form.remove')}
            </Button>
          </View>
        ) : null}

        <View style={styles.ctaWrapper}>
          <Button
            testID="credit-card-form.submit"
            size="large-wide"
            palette="primary"
            isLoading={isSaving}
            disabled={!canSubmit}
            accessibilityRole="button"
            accessibilityLabel={t('credit-card-form.save')}
            accessibilityHint={t('credit-card-form.save')}
            accessibilityState={{ disabled: !canSubmit }}
            onPress={onSubmit}
          >
            {t('credit-card-form.save')}
          </Button>
        </View>
      </HStack>
    </Modal.Footer>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardFormFooterProps = Readonly<{
  mode: 'add' | 'edit' | 'replace';
  isSaving?: boolean;
  isDeleting?: boolean;
  canSubmit: boolean;
  onSubmit: () => void;
  onDelete?: () => void;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    padding: theme.spacing['6'],
    paddingTop: theme.spacing['6'],
  },
  ctaWrapper: {
    alignSelf: 'center',
  },
});
