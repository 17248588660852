import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@sg/garnish';

export const BagContentContainer = (props: ViewProps) => {
  return <View style={styles.bagContentContainer}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  bagContentContainer: {
    flex: 1,
    paddingHorizontal: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
});
