import {
  MenuMoreInfoContainer,
  MenuMoreInfoHeading,
  MenuMoreInfoModalCloseButton,
  MenuMoreInfoModalHeader,
  MenuMoreInfoNotice,
  MenuMoreInfoRestaurantAddress,
  MenuMoreInfoText,
  MenuMoreInfoTimetable,
} from './components';

export const MenuMoreInfo = {
  Container: MenuMoreInfoContainer,
  Heading: MenuMoreInfoHeading,
  ModalCloseButton: MenuMoreInfoModalCloseButton,
  ModalHeader: MenuMoreInfoModalHeader,
  Notice: MenuMoreInfoNotice,
  Timetable: MenuMoreInfoTimetable,
  Text: MenuMoreInfoText,
  RestaurantAddress: MenuMoreInfoRestaurantAddress,
};
