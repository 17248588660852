/* istanbul ignore file */

import { type ComponentProps, type RefObject } from 'react';
import React, { memo, useCallback, useLayoutEffect, useRef } from 'react';
import type { TextInput } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme } from '@garnish/constants';
import type { Region } from '@sg/garnish';
import { useResponsive } from '@sg/garnish';

import type { LocationSearchMachineContext } from '../../machine';
import {
  LocationResultsListsContainer,
  LocationResultsListsV2,
} from '../LocationResults';
import { LocationSearchFieldForSearchType } from '../LocationSearchFieldForSearchType';
import { LocationSearchMap } from '../LocationSearchMap';
import { LocationSearchResults } from '../LocationSearchResults';
import { LocationSearchTypePicker } from '../LocationSearchTypePicker';
import { useRenderLocationSearchTypePicker } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchContent = memo(
  (props: LocationSearchContentProps) => {
    const { match } = useResponsive();

    const locationSearchFieldRef = useRef<TextInput>(null);

    // ─── Helpers ─────────────────────────────────────────────────────────

    const clearDeliverySearch = useCallback(() => {
      props.onDeliverySearchStringChange('');
    }, [props]);

    const changeToPickupSearchType = useCallback(() => {
      props.onLocationSearchTypeChange('pickup');
    }, [props]);

    // ─────────────────────────────────────────────────────────────────────

    const ResponsiveComponent = match([
      LocationSearchContentXS,
      LocationSearchContentSM,
    ]);

    return (
      <ResponsiveComponent
        locationSearchFieldRef={locationSearchFieldRef}
        clearDeliverySearchString={clearDeliverySearch}
        changeToPickupSearchType={changeToPickupSearchType}
        {...props}
      />
    );
  },
);

// ─── Responsive Components ───────────────────────────────────────────────────

const LocationSearchContentXS = memo(
  (props: LocationSearchContentInnerProps) => {
    const {
      locationSearchType,
      searchString,
      deliverySearchString,
      currentPosition,
      locations,
      recentLocations,
      nearbyLocations,
      deliveryLocation,
      predictionDeliveryAddresses,
      minimalCharactersForSearch,
      isLoadingLocations,
      isLoadingPredictions,
      isLoadingRecentAndNearbyLocations,
      isUsingSearchField,
      focusedPinId,
      focusedLocationId,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      locationSearchFieldRef,
      clearDeliverySearchString,
      changeToPickupSearchType,
      withSafeAreaInsets,
      shouldRenderRecentAndNearbyLocations,
      onLocationsSearchAreaChange,
      onLocationSearchTypeChange,
      onLocationSearchStringSubmit,
      onDeliverySearchStringChange,
      onPredictionPress,
      onGetCurrentPosition,
      onSearchAreaPress,
      onMapPinPress,
      onLocationPinPress,
      onDeliveryPinPress,
      onLocationCardPress,
      onLocationCardFocus,
      onDeliveryLocationCardPress,
      onLocationResultsExpandCollapse,
      onRecentOrNearbyLocationCardPress,
      showRecentAndNearbyLocations,
    } = props;

    const navigation = useNavigation();

    const LocationSearchTypePickerAsScreenHeader =
      useRenderLocationSearchTypePicker({
        locationSearchType,
        isDisabled: isLoadingLocations,
        onLocationSearchTypeChange,
      });

    // ─── Effects ─────────────────────────────────────────────────────────

    useLayoutEffect(() => {
      navigation.setOptions({
        headerShown: true,
        headerTitle: LocationSearchTypePickerAsScreenHeader,
      });
    }, [LocationSearchTypePickerAsScreenHeader, navigation]);

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View style={styles.containerXS}>
        <View style={styles.locationsContainerHeaderXS}>
          <LocationSearchFieldForSearchType
            ref={locationSearchFieldRef}
            locationSearchType={locationSearchType}
            searchString={searchString}
            deliverySearchString={deliverySearchString}
            isLoadingLocations={isLoadingLocations}
            onClear={showRecentAndNearbyLocations}
            onLocationSearchStringSubmit={onLocationSearchStringSubmit}
            onDeliverySearchStringChange={onDeliverySearchStringChange}
          />
        </View>

        <View style={styles.locationsContainerXS}>
          <View
            style={styles.locationsContainerShadowXS}
            pointerEvents="none"
            needsOffscreenAlphaCompositing
            importantForAccessibility="no"
          />

          <LocationSearchMap
            variation={
              shouldRenderRecentAndNearbyLocations
                ? 'recent-and-nearby'
                : 'standard'
            }
            locationSearchType={locationSearchType}
            locations={
              shouldRenderRecentAndNearbyLocations ? nearbyLocations : locations
            }
            deliveryLocation={deliveryLocation}
            currentPosition={currentPosition}
            isLoadingLocations={isLoadingLocations}
            focusedPinId={focusedPinId}
            onGetCurrentPosition={onGetCurrentPosition}
            onLocationsSearchAreaChange={onLocationsSearchAreaChange}
            onSearchAreaPress={onSearchAreaPress}
            onMapPinPress={onMapPinPress}
            onRecentOrNearbyLocationPinPress={onRecentOrNearbyLocationCardPress}
            onLocationPinPress={onLocationPinPress}
            onDeliveryPinPress={onDeliveryPinPress}
          />

          {shouldRenderRecentAndNearbyLocations ? (
            <LocationResultsListsContainer>
              <LocationResultsListsV2.XS
                variation="recent-and-nearby"
                selectedLocationSearchType={locationSearchType}
                recentLocations={recentLocations}
                nearbyLocations={nearbyLocations}
                isLoading={isLoadingRecentAndNearbyLocations}
                isExpandedByDefault={true}
                isUsingSearchField={isUsingSearchField}
                focusedLocationId={focusedLocationId}
                onRecentOrNearbyLocationCardPress={
                  onRecentOrNearbyLocationCardPress
                }
                onLocationCardPress={onLocationCardPress}
                onDeliveryLocationCardPress={onDeliveryLocationCardPress}
                onLocationCardFocus={onLocationCardFocus}
                interactedLocationRestaurantSlug={
                  interactedLocationRestaurantSlug
                }
                interactedLocationDeliveryAddressId={
                  interactedLocationDeliveryAddressId
                }
              />
            </LocationResultsListsContainer>
          ) : (
            <LocationSearchResults
              locationSearchType={locationSearchType}
              locations={locations}
              deliveryLocation={deliveryLocation}
              deliverySearchString={deliverySearchString}
              minimalCharactersForSearch={minimalCharactersForSearch}
              predictionDeliveryAddresses={predictionDeliveryAddresses}
              isLoadingPredictions={isLoadingPredictions}
              isLoadingLocations={isLoadingLocations}
              isUsingSearchField={isUsingSearchField}
              focusedLocationId={focusedLocationId}
              interactedLocationRestaurantSlug={
                interactedLocationRestaurantSlug
              }
              interactedLocationDeliveryAddressId={
                interactedLocationDeliveryAddressId
              }
              withSafeAreaInsets={withSafeAreaInsets}
              onPredictionPress={onPredictionPress}
              onLocationCardFocus={onLocationCardFocus}
              onLocationCardPress={onLocationCardPress}
              onDeliveryLocationCardPress={onDeliveryLocationCardPress}
              onTryNewAddressBtnPress={clearDeliverySearchString}
              onChangeToPickupSearchTypeBtnPress={changeToPickupSearchType}
              onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
            />
          )}
        </View>
      </View>
    );
  },
);

const LocationSearchContentSM = memo(
  (props: LocationSearchContentInnerProps) => {
    const {
      locationSearchType,
      searchString,
      deliverySearchString,
      currentPosition,
      locations,
      recentLocations,
      nearbyLocations,
      deliveryLocation,
      minimalCharactersForSearch,
      predictionDeliveryAddresses,
      isLoadingPredictions,
      isLoadingLocations,
      isLoadingRecentAndNearbyLocations,
      isUsingSearchField,
      focusedPinId,
      focusedLocationId,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      locationSearchFieldRef,
      withSafeAreaInsets,
      shouldRenderRecentAndNearbyLocations,
      clearDeliverySearchString,
      changeToPickupSearchType,
      onLocationsSearchAreaChange,
      onLocationSearchTypeChange,
      onLocationSearchStringSubmit,
      onDeliverySearchStringChange,
      onPredictionPress,
      onGetCurrentPosition,
      onSearchAreaPress,
      onMapPinPress,
      onLocationPinPress,
      onDeliveryPinPress,
      onLocationCardPress,
      onLocationCardFocus,
      onDeliveryLocationCardPress,
      onLocationResultsExpandCollapse,
      onRecentOrNearbyLocationCardPress,
      showRecentAndNearbyLocations,
    } = props;

    const navigation = useNavigation();

    // ─── Effects ─────────────────────────────────────────────────────────

    // remove possible custom header title component
    useLayoutEffect(() => {
      navigation.setOptions({ headerTitle: null });
    }, [navigation]);

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View style={styles.container}>
        <LocationSearchMap
          variation={
            shouldRenderRecentAndNearbyLocations
              ? 'recent-and-nearby'
              : 'standard'
          }
          locationSearchType={locationSearchType}
          locations={
            shouldRenderRecentAndNearbyLocations ? nearbyLocations : locations
          }
          deliveryLocation={deliveryLocation}
          currentPosition={currentPosition}
          isLoadingLocations={isLoadingLocations}
          focusedPinId={focusedPinId}
          onGetCurrentPosition={onGetCurrentPosition}
          onLocationsSearchAreaChange={onLocationsSearchAreaChange}
          onSearchAreaPress={onSearchAreaPress}
          onMapPinPress={onMapPinPress}
          onLocationPinPress={onLocationPinPress}
          onDeliveryPinPress={onDeliveryPinPress}
          onRecentOrNearbyLocationPinPress={onRecentOrNearbyLocationCardPress}
        />

        <View style={styles.locationsContainerSM}>
          <View style={styles.locationsContainerHeaderSM}>
            <View style={styles.locationSearchTypePickerContainer}>
              <View>
                <LocationSearchTypePicker
                  onLocationSearchTypeChange={onLocationSearchTypeChange}
                  locationSearchType={locationSearchType}
                  isDisabled={isLoadingLocations}
                />
              </View>
            </View>

            <LocationSearchFieldForSearchType
              ref={locationSearchFieldRef}
              locationSearchType={locationSearchType}
              searchString={searchString}
              deliverySearchString={deliverySearchString}
              isLoadingLocations={isLoadingLocations}
              onLocationSearchStringSubmit={onLocationSearchStringSubmit}
              onDeliverySearchStringChange={onDeliverySearchStringChange}
              onClear={showRecentAndNearbyLocations}
            />
          </View>

          {shouldRenderRecentAndNearbyLocations ? (
            <LocationResultsListsContainer>
              <LocationResultsListsV2.SM
                variation="recent-and-nearby"
                selectedLocationSearchType={locationSearchType}
                recentLocations={recentLocations}
                nearbyLocations={nearbyLocations}
                isLoading={isLoadingRecentAndNearbyLocations}
                isUsingSearchField={isUsingSearchField}
                focusedLocationId={focusedLocationId}
                onLocationCardFocus={onLocationCardFocus}
                onRecentOrNearbyLocationCardPress={
                  onRecentOrNearbyLocationCardPress
                }
                onLocationCardPress={onLocationCardPress}
                onDeliveryLocationCardPress={onDeliveryLocationCardPress}
                interactedLocationRestaurantSlug={
                  interactedLocationRestaurantSlug
                }
                interactedLocationDeliveryAddressId={
                  interactedLocationDeliveryAddressId
                }
              />
            </LocationResultsListsContainer>
          ) : (
            <LocationSearchResults
              locationSearchType={locationSearchType}
              locations={locations}
              deliveryLocation={deliveryLocation}
              deliverySearchString={deliverySearchString}
              minimalCharactersForSearch={minimalCharactersForSearch}
              predictionDeliveryAddresses={predictionDeliveryAddresses}
              isLoadingPredictions={isLoadingPredictions}
              isLoadingLocations={isLoadingLocations}
              isUsingSearchField={isUsingSearchField}
              focusedLocationId={focusedLocationId}
              interactedLocationRestaurantSlug={
                interactedLocationRestaurantSlug
              }
              interactedLocationDeliveryAddressId={
                interactedLocationDeliveryAddressId
              }
              withSafeAreaInsets={withSafeAreaInsets}
              onPredictionPress={onPredictionPress}
              onLocationCardFocus={onLocationCardFocus}
              onLocationCardPress={onLocationCardPress}
              onDeliveryLocationCardPress={onDeliveryLocationCardPress}
              onTryNewAddressBtnPress={clearDeliverySearchString}
              onChangeToPickupSearchTypeBtnPress={changeToPickupSearchType}
              onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
            />
          )}
        </View>
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
    backgroundColor: theme.colors.OATMEAL,
  },
  containerXS: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  locationsContainerXS: {
    flex: 1,
    zIndex: 1,
  },
  locationsContainerSM: {
    width: 375,
    flexGrow: 0,
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
  locationsContainerHeaderXS: {
    backgroundColor: theme.colors.OATMEAL,
    zIndex: 2,
  },
  locationsContainerHeaderSM: {
    backgroundColor: theme.colors.OATMEAL,
    paddingTop: theme.spacing['6'],
  },
  locationsContainerShadowXS: {
    position: 'absolute',
    height: 5,
    top: -5,
    right: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.colors.WHITE,
    ...theme.elevations['4'],
  },
  locationSearchTypePickerContainer: {
    alignItems: 'center',
    paddingBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchContentProps = Readonly<{
  locationSearchType: LocationSearchMachineContext['locationSearchType'];
  searchString: LocationSearchMachineContext['searchString'];
  deliverySearchString: LocationSearchMachineContext['deliverySearchString'];
  locations: LocationSearchMachineContext['locations'];
  recentLocations: LocationSearchMachineContext['recentLocations'];
  nearbyLocations: LocationSearchMachineContext['nearbyLocations'];
  deliveryLocation: LocationSearchMachineContext['deliveryLocation'];
  predictionDeliveryAddresses: LocationSearchMachineContext['deliveryAddressPredictions'];
  currentPosition: LocationSearchMachineContext['currentPosition'];
  isLoadingPredictions: boolean;
  isLoadingLocations: boolean;
  isLoadingRecentAndNearbyLocations: boolean;
  isUsingSearchField: boolean;
  focusedPinId: string | undefined;
  focusedLocationId: string | undefined;
  interactedLocationRestaurantSlug: string | undefined;
  interactedLocationDeliveryAddressId: string | undefined;
  minimalCharactersForSearch: number;
  withSafeAreaInsets?: boolean;
  shouldRenderRecentAndNearbyLocations: boolean;
  onLocationSearchTypeChange: (searchType: string) => void;
  onLocationSearchStringSubmit: (searchString: string) => void;
  onDeliverySearchStringChange: (searchString: string) => void;
  onLocationPinPress: ComponentProps<
    typeof LocationSearchMap
  >['onLocationPinPress'];
  onDeliveryPinPress: ComponentProps<
    typeof LocationSearchMap
  >['onDeliveryPinPress'];
  onRecentOrNearbyLocationCardPress: ComponentProps<
    typeof LocationResultsListsV2.XS
  >['onRecentOrNearbyLocationCardPress'];
  onLocationCardPress: ComponentProps<
    typeof LocationSearchResults
  >['onLocationCardPress'];
  onLocationCardFocus: NonNullable<
    ComponentProps<typeof LocationSearchResults>['onLocationCardFocus']
  >;
  onDeliveryLocationCardPress: ComponentProps<
    typeof LocationSearchResults
  >['onDeliveryLocationCardPress'];
  onLocationResultsExpandCollapse: ComponentProps<
    typeof LocationSearchResults
  >['onLocationResultsExpandCollapse'];
  onLocationsSearchAreaChange: (region: Region) => void;
  onPredictionPress: (placeId: string) => void;
  onGetCurrentPosition: () => void;
  onSearchAreaPress: () => void;
  onMapPinPress: (id: string | undefined) => void;
  showRecentAndNearbyLocations: () => void;
}>;

type LocationSearchContentInnerProps = LocationSearchContentProps &
  Readonly<{
    locationSearchFieldRef: RefObject<TextInput>;
    clearDeliverySearchString: () => void;
    changeToPickupSearchType: () => void;
  }>;
