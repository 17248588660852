import React, { useLayoutEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { Header } from '@expo/html-elements';
import {
  ScrollViewWithHeaderTitle,
  theme,
  TitleText,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { useHeaderRight } from '@order/components';
import { Bag } from '@order/features/ordering';
import type {
  DeliveryOrderDetail,
  DeliveryOrderDetailInput,
  Product,
} from '@order/graphql';

import { categoryUpsellsMessages as messages } from './CategoryUpsells.messages';
import { UpsellCategory } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const CategoryUpsells = (props: CategoryUpsellsProps) => {
  const {
    isFetchingCart = false,
    upsellIdsBeingAdded,
    categories,
    deliveryOrderDetail,
    addUpsellToBag,
    onClose,
  } = props;

  const { match } = useResponsive();
  const navigation = useNavigation();
  const { formatMessage } = useIntl();

  // ─── Navigation Controls ─────────────────────────────────────────────

  const navigationHeaderRightItems = useMemo<NavigationHeaderRightItems>(
    () => [
      {
        key: 'bag.category-upsells.close-btn',
        icon: 'IconClose',
        accessibilityLabel: formatMessage(messages.dismissUpsells),
        onPress: onClose,
      },
    ],
    [formatMessage, onClose],
  );

  const navigationHeaderRight = useHeaderRight({
    items: navigationHeaderRightItems,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  // Set custom title and close button
  useLayoutEffect(() => {
    navigation.setOptions({
      title: formatMessage(messages.title),
      headerRight: match([navigationHeaderRight, () => null]),
    });
  }, [formatMessage, match, navigation, navigationHeaderRight]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <ScrollViewWithHeaderTitle
        contentContainerStyle={styles.contentContainer}
        borderBottomColor={theme.colors.OPACITY.TRANSPARENT}
        testID="category-upsells.container"
      >
        <SafeAreaView edges={['top']}>
          <Header style={styles.header}>
            <TitleText sizeMatch={['32']}>
              <FormattedMessage {...messages.title} />
            </TitleText>
          </Header>
        </SafeAreaView>

        <VStack gap={theme.spacing['4']}>
          {categories.map((category) => (
            <UpsellCategory
              key={category.id}
              name={category.name}
              products={category.products}
              deliveryOrderDetail={deliveryOrderDetail}
              upsellIdsBeingAdded={upsellIdsBeingAdded}
              addUpsellToBag={addUpsellToBag}
            />
          ))}
        </VStack>
      </ScrollViewWithHeaderTitle>

      <Bag.FloatingCtas>
        <Bag.ContinueToBagCta isDisabled={isFetchingCart} onPress={onClose} />
      </Bag.FloatingCtas>
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['30'],
  },
  header: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CategoryUpsellsProps = {
  categories: UpsellCategories;
  isFetchingCart?: boolean;
  upsellIdsBeingAdded: string[];
  deliveryOrderDetail?: DeliveryOrderDetail;
  addUpsellToBag: (payload: {
    product: Partial<Product>;
    deliveryDetails?: DeliveryOrderDetailInput;
  }) => void;
  onClose: () => void;
};

type UpsellCategories = {
  id: string;
  name: string;
  categoryRanking: number;
  products: UpsellProduct[];
}[];

type UpsellProduct = Readonly<{
  id: string;
  name: string;
  cost: number;
  imageUrl: string;
  outOfStock: boolean;
  menuId: number;
}>;

type NavigationHeaderRightItems = Parameters<typeof useHeaderRight>[0]['items'];
