import React, { useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet, View } from 'react-native';
import {
  BodyText,
  Icon,
  type IconName,
  theme,
  usePressableState,
} from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagPaymentMethodCheckCta = (props: CheckCtaProps) => {
  const { id, icon, title, checked, onPress } = props;

  const { formatMessage } = useIntl();
  const a11yLabel = formatMessage(messages.a11yLabel, { title });

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  const iconColor = checked ? theme.colors.WHITE : UNCHECKED_COLOR;
  const iconStyle = checked ? styles.checkerChecked : styles.checkerUnchecked;

  const interactiveStyle = isInteracting ? styles.checkerInteracting : null;

  const handleOnPress = useCallback(() => {
    onPress(id);
  }, [id, onPress]);

  return (
    <Pressable
      ref={ref}
      style={[styles.checker, interactiveStyle]}
      accessibilityLabel={a11yLabel}
      accessibilityRole="switch"
      onPress={handleOnPress}
    >
      <View style={styles.checkerInfo}>
        <Icon name={icon} width={PICKER_ICON_SIZE} height={PICKER_ICON_SIZE} />

        <BodyText sizeMatch={['24']}>{title}</BodyText>
      </View>

      <View style={[styles.checkerBase, iconStyle]}>
        <Icon
          name="IconCheck"
          width={CHECK_ICON_SIZE}
          height={CHECK_ICON_SIZE}
          color={iconColor}
        />
      </View>
    </Pressable>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Select payment method {title}',
    description: 'Bag | Payment Method Check | a11y',
  },
});

// ─── Constants ──────────────────────────────────────────────────────────────

const PICKER_ICON_SIZE = 32;
const CHECK_ICON_SIZE = 24;
const CHECK_WRAPPER_SIZE = 40;
const UNCHECKED_COLOR = theme.colors.OPACITY.TRANSPARENT;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  checker: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: theme.spacing['2'],
    marginHorizontal: -theme.spacing['2'],
    borderRadius: theme.radius.xxxlarge,
  },
  checkerInteracting: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  checkerInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
  },
  checkerBase: {
    width: CHECK_WRAPPER_SIZE,
    height: CHECK_WRAPPER_SIZE,
    borderRadius: theme.radius.xxxlarge,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkerChecked: {
    backgroundColor: theme.colors.KALE,
  },
  checkerUnchecked: {
    backgroundColor: UNCHECKED_COLOR,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type CheckCtaProps = {
  id: string;
  icon: IconName;
  title: string;
  checked: boolean;
  onPress: (id: string) => void;
};
