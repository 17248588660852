import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

/**
 * Selects between two dropoff locations.
 * If there are more than two dropoff locations available, only the first two are considered.
 */
export const BagDropoffLocationPicker = (
  props: BagDropoffLocationPickerProps,
) => {
  const {
    selectedDropoffLocationId,
    firstDropoffLocation,
    secondDropoffLocation,
    changeDropoffLocation,
  } = props;

  const { formatMessage } = useIntl();

  // ─── Palettes ─────────────────────────────────────────────────────────────

  const firstDropoffLocationPalette =
    selectedDropoffLocationId === firstDropoffLocation.value
      ? 'spinach'
      : 'kaleLightest';

  const secondDropoffLocationPalette =
    selectedDropoffLocationId === secondDropoffLocation.value
      ? 'spinach'
      : 'kaleLightest';

  // ─── Callbacks ────────────────────────────────────────────────────────────

  const selectFirstOption = useCallback(() => {
    changeDropoffLocation(firstDropoffLocation.value);
  }, [firstDropoffLocation.value, changeDropoffLocation]);

  const selectSecondOption = useCallback(() => {
    changeDropoffLocation(secondDropoffLocation.value);
  }, [secondDropoffLocation.value, changeDropoffLocation]);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <View style={styles.dropoffCtaWrapper}>
        <Button
          size="large"
          palette={firstDropoffLocationPalette}
          accessibilityLabel={formatMessage(messages.dropoffLocationA11y, {
            option: firstDropoffLocation.label,
          })}
          onPress={selectFirstOption}
        >
          {firstDropoffLocation.label}
        </Button>
      </View>

      <View style={styles.dropoffCtaWrapper}>
        <Button
          size="large"
          palette={secondDropoffLocationPalette}
          accessibilityLabel={formatMessage(messages.dropoffLocationA11y, {
            option: secondDropoffLocation.label,
          })}
          onPress={selectSecondOption}
        >
          {secondDropoffLocation.label}
        </Button>
      </View>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  dropoffLocationA11y: {
    defaultMessage: 'Dropoff location, {option}',
    description: 'Bag | Dropoff Location Picker | a11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['4'],
  },
  dropoffCtaWrapper: {
    flex: 1,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagDropoffLocationPickerProps = {
  selectedDropoffLocationId: string;
  firstDropoffLocation: { value: string; label: string };
  secondDropoffLocation: { value: string; label: string };
  changeDropoffLocation: (dropoffLocationId: string) => void;
};
