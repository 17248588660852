import { defineMessages, useIntl } from 'react-intl';

/**
 * Returns different verbiages of promo codes or rewards depending on what is available.
 */
export const useRewardsPromoHeading = (props: UseRewardsPromoHeadingProps) => {
  const { hasRewards, shouldShowPromoCodeField } = props;
  const { formatMessage } = useIntl();

  if (hasRewards && shouldShowPromoCodeField) {
    return formatMessage(messages.promoRewardHeading);
  }

  if (hasRewards) {
    return formatMessage(messages.rewardHeading);
  }

  if (shouldShowPromoCodeField) {
    return formatMessage(messages.promoHeading);
  }
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  promoRewardHeading: {
    defaultMessage: 'Apply promo or reward',
    description: 'Bag | Promo Rewards | Title',
  },
  rewardHeading: {
    defaultMessage: 'Apply a reward',
    description: 'Bag | Rewards | Title',
  },
  promoHeading: {
    defaultMessage: 'Apply promo code',
    description: 'Bag | Promo | Title',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

export type UseRewardsPromoHeadingProps = {
  hasRewards: boolean;
  shouldShowPromoCodeField: boolean;
};
