import { useCallback } from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  APPLE_PAY,
  GOOGLE_PAY,
  type OrderingContext,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import { getCheckoutTelemetryData } from '../../utils';

/**
 * Telemetry events for bag/checkout.
 */
export const useBagTracking = () => {
  const { params } = useRoute<BagScreenRouteParams>();
  const { track } = useTelemetry();

  const trackBagView = useCallback(() => {
    track('bag.view', { entryPoint: params?.referrer });
  }, [params?.referrer, track]);

  const trackLocationNamePressed = useCallback(() => {
    track('bag.location-name.pressed');
  }, [track]);

  const trackWantedTimePressed = useCallback(() => {
    track('bag.wanted-time.pressed');
  }, [track]);

  const trackWantedTimeChanged = useCallback(() => {
    track('bag.wanted-time.changed');
  }, [track]);

  const trackDeliveryPreferencesPressed = useCallback(() => {
    track('bag.delivery-preferences.pressed');
  }, [track]);

  const trackDropoffLocationChanged = useCallback(() => {
    track('bag.dropoff-location.changed');
  }, [track]);

  const trackViewMenuPressed = useCallback(() => {
    track('bag.view-menu.pressed');
  }, [track]);

  const trackLineItemPressed = useCallback(() => {
    track('bag.line-item.pressed');
  }, [track]);

  const trackLineItemRemoved = useCallback(() => {
    track('bag.line-item.removed');
  }, [track]);

  const trackLineItemQuantityChanged = useCallback(() => {
    track('bag.line-item.quantity.changed');
  }, [track]);

  const trackLineItemNameEditPressed = useCallback(() => {
    track('bag.line-item.name.pressed');
  }, [track]);

  const trackLineItemNameEditSuccess = useCallback(() => {
    track('bag.line-item.name.changed');
  }, [track]);

  const trackApplyRewardPressed = useCallback(() => {
    track('bag.apply-reward.pressed');
  }, [track]);

  const trackRemoveRewardPressed = useCallback(() => {
    track('bag.remove-reward.pressed');
  }, [track]);

  const trackPromoCodeAddPressed = useCallback(() => {
    track('bag.promo-code.add.pressed');
  }, [track]);

  const trackUtensilsUsagePressed = useCallback(() => {
    track('bag.utensils-usage.pressed');
  }, [track]);

  const trackContinuePressed = useCallback(() => {
    track('bag.continue.pressed');
  }, [track]);

  const trackExpressCheckoutStart = useCallback(
    (paymentMethodId: string) => {
      track('bag.express-checkout.start', {
        isApplePay: paymentMethodId === APPLE_PAY,
        isGooglePay: paymentMethodId === GOOGLE_PAY,
      });
    },
    [track],
  );

  const trackChangePaymentMethodPressed = useCallback(() => {
    track('bag.change-payment-method.pressed');
  }, [track]);

  const trackCreditPressed = useCallback(() => {
    track('bag.credit.pressed');
  }, [track]);

  const trackPaymentMethodPressed = useCallback(() => {
    track('bag.payment-method.pressed');
  }, [track]);

  const trackAddPaymentMethodPressed = useCallback(() => {
    track('bag.add-payment-method.pressed');
  }, [track]);

  const trackAddPaymentMethodSubmitted = useCallback(() => {
    track('bag.add-payment-method.submitted');
  }, [track]);

  const trackRedeemGiftCardPressed = useCallback(() => {
    track('bag.redeem-gift-card.pressed');
  }, [track]);

  const trackRedeemGiftCardSubmitted = useCallback(() => {
    track('bag.redeem-gift-card.submitted');
  }, [track]);

  const trackCheckoutStart = useCallback(
    (context: OrderingContext<PartialLineItem, DeliveryOrderDetail>) => {
      track('checkout.start', getCheckoutTelemetryData(context));
    },
    [track],
  );

  return {
    trackBagView,
    trackLocationNamePressed,
    trackWantedTimePressed,
    trackWantedTimeChanged,
    trackDeliveryPreferencesPressed,
    trackDropoffLocationChanged,
    trackViewMenuPressed,
    trackLineItemPressed,
    trackLineItemRemoved,
    trackLineItemQuantityChanged,
    trackLineItemNameEditPressed,
    trackLineItemNameEditSuccess,
    trackApplyRewardPressed,
    trackRemoveRewardPressed,
    trackPromoCodeAddPressed,
    trackUtensilsUsagePressed,
    trackContinuePressed,
    trackExpressCheckoutStart,
    trackChangePaymentMethodPressed,
    trackCreditPressed,
    trackPaymentMethodPressed,
    trackAddPaymentMethodPressed,
    trackAddPaymentMethodSubmitted,
    trackRedeemGiftCardPressed,
    trackRedeemGiftCardSubmitted,
    trackCheckoutStart,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagScreenRouteParams = RouteProp<BagScreenProps>;
type BagScreenProps = Readonly<{
  params?: { referrer?: string };
}>;
