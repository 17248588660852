import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { IconLink } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoModalCloseButton = (
  props: MenuMoreInfoModalCloseButtonProps,
) => {
  const { formatMessage } = useIntl();

  return (
    <IconLink
      style={styles.button}
      name="IconClose"
      width={40}
      height={40}
      palette="muted"
      accessibilityLabel={formatMessage(messages.a11yLabel)}
      {...props}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Close modal',
    description: 'Menu | More Info | Modal | Close button label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-end',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoModalCloseButtonProps = Omit<
  ComponentProps<typeof IconLink>,
  'name' | 'palette' | 'width' | 'height' | 'accessibilityLabel'
>;
