import React, { useCallback, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { ScrollViewWithHeaderTitle, theme } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { LoadingAnimation, SweetpassSignedOut } from '@order/components';
import { TierName } from '@order/graphql';
import {
  useSweetpassPlan,
  useSweetpassPlusPlans,
  useSweetpassTier,
} from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { useNavigateToMainTabs } from '../../navigation';
import type { SweetpassBenefitsScreenProps } from '../../navigation/AppNavigation.props';
import type {
  SweetpassBenefitsPageBenefitCtaHandler,
  SweetpassBenefitsPageCardCtaHandler,
  SweetpassBenefitsPageTag,
  SweetpassBenefitsPageUpgradeCtaHandler,
} from './hooks';
import {
  useSweetpassBenefitsContentfulData,
  useSweetpassBenefitsTelemetry,
} from './hooks';
import { SweetpassBenefitsScreenContent } from './SweetpassBenefitsScreenContent';

// ─────────────────────────────────────────────────────────────────────

export const SweetpassBenefitsScreen = (
  props: SweetpassBenefitsScreenProps,
) => {
  const { navigation } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const { track } = useTelemetry();

  const { defaultPlan, isLoadingPlans } = useSweetpassPlusPlans();
  const freeTrialPlanId = defaultPlan?.id;
  const { planPrice, billingFrequencyUnit, isLoadingPlan } = useSweetpassPlan();
  const { isSweetpassTierLoading, sweetpassTierName, isEligibleForFreeTrial } =
    useSweetpassTier();

  // ─── Derived Data ────────────────────────────────────────────────────

  const benefitsPageTag = getBenefitsPageTagForTier(sweetpassTierName);

  const { content: loadedContent, isLoading: isContentLoading } =
    useSweetpassBenefitsContentfulData({
      tag: isSweetpassTierLoading ? undefined : benefitsPageTag,
      planPrice,
      billingFrequencyUnit,
      isEligibleForFreeTrial,
    });

  const isLoading =
    isSweetpassTierLoading ||
    isLoadingPlan ||
    isLoadingPlans ||
    isContentLoading;

  const hasContent = Boolean(loadedContent);

  const shouldRedirectToHome = !isLoading && !hasContent;

  // ─── Effects ─────────────────────────────────────────────────────────

  useSweetpassBenefitsTelemetry({
    isLoading,
    hasContent,
    shouldRedirectToHome,
  });

  useLayoutEffect(() => {
    if (shouldRedirectToHome) {
      navigation.navigate('MainTabs', {
        screen: 'HomeTab',
        params: { screen: 'Home' },
      });
    }
  }, [navigation, shouldRedirectToHome]);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleBenefitCta: SweetpassBenefitsPageBenefitCtaHandler = useCallback(
    (action) => {
      if (action === 'challenges-rewards') {
        navigation.navigate('MainTabs', {
          screen: 'LoyaltyTab',
          params: { screen: 'SweetpassHome' },
        });
      }
    },
    [navigation],
  );

  const handleUpgradeCta: SweetpassBenefitsPageUpgradeCtaHandler = useCallback(
    async (action) => {
      if (action !== 'upgrade_to_sweetpass_plus') return;

      track('sweetpass_benefits.upgrade_cta.tapped');

      navigation.navigate('Modal', {
        screen: 'SweetpassUpgrade',
        params: {
          planId: isEligibleForFreeTrial ? freeTrialPlanId : undefined,
        },
      });
    },
    [freeTrialPlanId, isEligibleForFreeTrial, navigation, track],
  );

  const navigateToMainTabs = useNavigateToMainTabs(navigation);

  const handleOnFaq = useCallback(() => {
    track('sweetpass_benefits.faq_cta.tapped');
    void WebBrowser.openBrowserAsync(
      'https://www.sweetgreen.com/sweetpass/faq',
      { windowFeatures: { target: '_blank' } },
    );
  }, [track]);

  const handleOnSweetpassAccount = useCallback(() => {
    track('sweetpass_benefits.membership_cta.tapped');
    void navigateToMainTabs('AccountTab', { screen: 'SweetpassMembership' });
  }, [navigateToMainTabs, track]);

  const handleCardCta: SweetpassBenefitsPageCardCtaHandler = useCallback(
    (action: string) => {
      if (action === 'view-all') {
        handleOnFaq();
      }

      if (action === 'edit') {
        handleOnSweetpassAccount();
      }
    },
    [handleOnFaq, handleOnSweetpassAccount],
  );

  // ─────────────────────────────────────────────────────────────────

  /**
   * Redirects logged-out customers to the login, then to Sweetpass Benefits screen.
   */
  const handlePressSignIn = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Login',
      params: { redirect: 'sweetpassBenefits' },
    });
  }, [navigation]);

  const handlePressJoinNow = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Join',
      params: { redirect: 'sweetpassBenefits' },
    });
  }, [navigation]);

  if (!isLoggedIn) {
    return (
      <SweetpassSignedOut
        testID="sweetpass-signed-out"
        onPressSignIn={handlePressSignIn}
        onPressJoinNow={handlePressJoinNow}
      />
    );
  }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (!loadedContent || !sweetpassTierName) {
    return null;
  }

  return (
    <ScrollViewWithHeaderTitle
      testID="sweetpass-benefits.root.container"
      style={styles.scrollContainer}
      contentContainerStyle={styles.scrollContent}
    >
      <SweetpassBenefitsScreenContent
        testID="sweetpass-benefits-screen"
        content={loadedContent}
        handleUpgradeCta={handleUpgradeCta}
        handleBenefitCta={handleBenefitCta}
        handleCardCta={handleCardCta}
      />
    </ScrollViewWithHeaderTitle>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: theme.colors.KHAKI_3,
  },
  scrollContent: {
    height: '100%',
  },
});

// ─── Helpers ───────────────────────────────────────────────────────────

const getBenefitsPageTagForTier = (
  tierName?: TierName,
): SweetpassBenefitsPageTag | undefined => {
  if (tierName === TierName.Sweetpass) {
    return 'sweetpass-benefits-page';
  }

  if (tierName === TierName.SweetpassPlus) {
    return 'sweetpass-plus-benefits-page';
  }

  return undefined;
};
