import React, { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { IconLink } from '../../Icon';
import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────

export const LineItemQuantityStepper = (props: ProductFooterProps) => {
  const { quantity, isDisabled, onQuantityChange } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  // ─── Selected Quantity ────────────────────────────────────────────────────

  const [selectedQuantity, setSelectedQuantity] = useState(quantity);

  const increaseQuantity = useCallback(() => {
    setSelectedQuantity((currentQuantity) => currentQuantity + 1);
  }, []);

  const decreaseQuantity = useCallback(() => {
    setSelectedQuantity((currentQuantity) =>
      currentQuantity > 0 ? currentQuantity - 1 : currentQuantity,
    );
  }, []);

  // ─── Timeout Behavior ─────────────────────────────────────────────────────

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (selectedQuantity === quantity) {
      return;
    }

    // eslint-disable-next-line functional/immutable-data
    timeoutRef.current = setTimeout(() => {
      onQuantityChange(selectedQuantity);
    }, 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [quantity, selectedQuantity, onQuantityChange]);

  return (
    <View style={match([styles.wrapperXs, styles.wrapperSm])}>
      <IconLink
        style={match([styles.iconXs, styles.iconSm])}
        name="IconMinus2"
        palette="darkKale"
        disabled={isDisabled}
        width={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        height={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        accessibilityLabel={formatMessage(messages.decreaseQuantityA11y)}
        onPress={decreaseQuantity}
      />

      <BodyText
        accessibilityLabel={formatMessage(messages.currentQuantityA11y)}
        sizeMatch={['18']}
      >
        {selectedQuantity}
      </BodyText>

      <IconLink
        style={match([styles.iconXs, styles.iconSm])}
        name="IconPlus2"
        palette="darkKale"
        disabled={isDisabled}
        width={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        height={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        accessibilityLabel={formatMessage(messages.increaseQuantityA11y)}
        onPress={increaseQuantity}
      />
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  currentQuantityA11y: {
    defaultMessage: 'Current product quantity',
    description: 'Line Item Quantity Stepper | Current Quantity | a11y',
  },
  increaseQuantityA11y: {
    defaultMessage: 'Increase product quantity',
    description: 'Line Item Quantity Stepper | Increase Quantity | a11y',
  },
  decreaseQuantityA11y: {
    defaultMessage: 'Decrease product quantity',
    description: 'Line Item Quantity Stepper | Decrease Quantity | a11y',
  },
});

// ─── Constants ──────────────────────────────────────────────────────────────

export const QUANTITY_STEPPER_SIZE_XS = 32;
export const QUANTITY_STEPPER_SIZE_SM = 40;

const ICON_SIZE_XS = 24;
const ICON_SIZE_SM = 30;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXs: {
    position: 'absolute',
    bottom: QUANTITY_STEPPER_SIZE_XS / 2,
    gap: theme.spacing['3'],
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperSm: {
    position: 'absolute',
    bottom: QUANTITY_STEPPER_SIZE_SM / 2,
    gap: theme.spacing['3'],
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconXs: {
    width: QUANTITY_STEPPER_SIZE_XS,
    height: QUANTITY_STEPPER_SIZE_XS,
  },
  iconSm: {
    width: QUANTITY_STEPPER_SIZE_SM,
    height: QUANTITY_STEPPER_SIZE_SM,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type ProductFooterProps = Readonly<{
  quantity: number;
  isDisabled: boolean;
  onQuantityChange: (selectedQuantity: number) => void;
}>;
