import React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

/**
 * Subtotal for this order.
 */
export const BagSubtotal = (props: BagSubtotalProps) => {
  const { subtotal } = props;

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['22']}>
        <FormattedMessage {...messages.subtotal} />
      </BodyText>

      <BodyText sizeMatch={['22']}>
        <FormattedNumber
          value={subtotal / 100}
          currency="USD"
          style="currency" // eslint-disable-line react/style-prop-object
        />
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  subtotal: {
    defaultMessage: 'Subtotal',
    description: 'Bag | Subtotal',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing['8'],
    paddingBottom: theme.spacing['10'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagSubtotalProps = {
  subtotal: number;
};
