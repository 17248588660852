import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderChangeLocationButton = (
  props: MenuHeaderChangeLocationButtonProps,
) => {
  const { style, ...buttonProps } = props;

  return (
    <Button
      palette="kaleLightest"
      size="small"
      style={[styles.button, style]}
      {...buttonProps}
    >
      <FormattedMessage {...messages.label} />
    </Button>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  label: {
    defaultMessage: 'Change location',
    description: 'Menu | Header | Change location button | Label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderChangeLocationButtonProps = Omit<
  ComponentProps<typeof Button>,
  'children'
>;
