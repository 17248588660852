import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { PortalProvider } from '@gorhom/portal';
import { theme } from '@sg/garnish';

import {
  OtaUpdateDialog,
  useResetDietaryRestrictionsOnLogout,
} from '@order/components';
import { useCustomerTelemetry } from '@order/Customer';
import {
  useAccessibilityTracking,
  useAppTrackingTransparency,
  useFetchUserCart,
  useNetworkSubscription,
  usePushNotifications,
  useRegisterUrqlErrorTracker,
  useRegisterUrqlLogOutHandler,
} from '@order/hooks';
import { useTrackLastInteractedStore } from '@order/LastInteractedStore';
import { useLiveActivityTelemetry } from '@order/LiveActivities';
import { useResetPlacedOrdersCounterOnLogout } from '@order/PlacedOrdersCounter';

import { useFacebookPixel } from './integrations/facebook-pixel';
import { AppNavigation } from './navigation/AppNavigation';

// ────────────────────────────────────────────────────────────────────────────────

export const AppContent = memo(() => {
  useCustomerTelemetry();
  useFetchUserCart();
  useNetworkSubscription();
  usePushNotifications();
  useAppTrackingTransparency();
  useFacebookPixel();
  useTrackLastInteractedStore();
  useAccessibilityTracking();
  useRegisterUrqlErrorTracker();
  useRegisterUrqlLogOutHandler();
  useLiveActivityTelemetry();

  useResetDietaryRestrictionsOnLogout();
  useResetPlacedOrdersCounterOnLogout();

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View testID="app.container" style={styles.appContainer}>
      <PortalProvider>
        <OtaUpdateDialog />
        <AppNavigation />
      </PortalProvider>
    </View>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  appContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OATMEAL,
  },
});
