/**
 * Garnish Illustrations (Web platform).
 * [Figma source]{@link https://www.figma.com/file/b7C0OFJIY0KwccakPwngUM/Garnish---Visual-Assets?node-id=1856%3A18}.
 *
 * To support a variety of DPI displays, all assets are exported in 3 sizes.
 * Illustrations from Figma source should be used only. For custom illustrations, please use the relative local folder.
 *
 * How to add a new image?
 *
 * 1. Export the asset from Figma in 3 sizes.
 * 2. Use a tool like TinyPNG to optimize them.
 * 3. Create an exportable variable containing an array of all those assets paths.
 *
 * Once all those steps are completed, the newly added asset can be imported from Garnish:
 *
 * import { Illus404 } from '@sg/garnish'
 *
 * Make sure you follow the same process for the Native platforms (index.ts).
 *
 * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */

// ────────────────────────────────────────────────────────────────────────────────

export const Illus404 = [
  require('./Illus-404.png'),
  require('./Illus-404@2x.png'),
  require('./Illus-404@3x.png'),
];
export const IllusAppleWallet = [
  require('./Illus-Apple_Wallet.png'),
  require('./Illus-Apple_Wallet@2x.png'),
  require('./Illus-Apple_Wallet@3x.png'),
];
export const IllusBirthday = [
  require('./Illus-Birthday.png'),
  require('./Illus-Birthday@2x.png'),
  require('./Illus-Birthday@3x.png'),
];
export const IllusBowlEmpty = [
  require('./Illus-BowlEmpty.png'),
  require('./Illus-BowlEmpty@2x.png'),
  require('./Illus-BowlEmpty@3x.png'),
];
export const IllusBowlFull = [
  require('./Illus-BowlFull.png'),
  require('./Illus-BowlFull@2x.png'),
  require('./Illus-BowlFull@3x.png'),
];
export const IllusBread_1 = [
  require('./Illus-Bread_1.png'),
  require('./Illus-Bread_1@2x.png'),
  require('./Illus-Bread_1@3x.png'),
];
export const IllusBread_2 = [
  require('./Illus-Bread_2.png'),
  require('./Illus-Bread_2@2x.png'),
  require('./Illus-Bread_2@3x.png'),
];
export const IllusCongrats = [
  require('./Illus-Congrats.png'),
  require('./Illus-Congrats@2x.png'),
  require('./Illus-Congrats@3x.png'),
];
export const IllusCredit_1 = [
  require('./Illus-Credit_1.png'),
  require('./Illus-Credit_1@2x.png'),
  require('./Illus-Credit_1@3x.png'),
];
export const IllusCredit_2 = [
  require('./Illus-Credit_2.png'),
  require('./Illus-Credit_2@2x.png'),
  require('./Illus-Credit_2@3x.png'),
];
export const IllusDelivery_1 = [
  require('./Illus-Delivery_1.png'),
  require('./Illus-Delivery_1@2x.png'),
  require('./Illus-Delivery_1@3x.png'),
];
export const IllusDelivery_2 = [
  require('./Illus-Delivery_2.png'),
  require('./Illus-Delivery_2@2x.png'),
  require('./Illus-Delivery_2@3x.png'),
];
export const IllusDiversity_1 = [
  require('./Illus-Diversity_1.png'),
  require('./Illus-Diversity_1@2x.png'),
  require('./Illus-Diversity_1@3x.png'),
];
export const IllusDiversity_2 = [
  require('./Illus-Diversity_2.png'),
  require('./Illus-Diversity_2@2x.png'),
  require('./Illus-Diversity_2@3x.png'),
];
export const IllusDressing_1 = [
  require('./Illus-Dressing_1.png'),
  require('./Illus-Dressing_1@2x.png'),
  require('./Illus-Dressing_1@3x.png'),
];
export const IllusDressing_2 = [
  require('./Illus-Dressing_2.png'),
  require('./Illus-Dressing_2@2x.png'),
  require('./Illus-Dressing_2@3x.png'),
];
export const IllusDressing_3 = [
  require('./Illus-Dressing_3.png'),
  require('./Illus-Dressing_3@2x.png'),
  require('./Illus-Dressing_3@3x.png'),
];
export const IllusEmployee_1 = [
  require('./Illus-Employee_1.png'),
  require('./Illus-Employee_1@2x.png'),
  require('./Illus-Employee_1@3x.png'),
];
export const IllusEmployee_2 = [
  require('./Illus-Employee_2.png'),
  require('./Illus-Employee_2@2x.png'),
  require('./Illus-Employee_2@3x.png'),
];
export const IllusEmployee_3 = [
  require('./Illus-Employee_3.png'),
  require('./Illus-Employee_3@2x.png'),
  require('./Illus-Employee_3@3x.png'),
];
export const IllusEmployee_4 = [
  require('./Illus-Employee_4.png'),
  require('./Illus-Employee_4@2x.png'),
  require('./Illus-Employee_4@3x.png'),
];
export const IllusEmployeeBag = [
  require('./Illus-EmployeeBag.png'),
  require('./Illus-EmployeeBag@2x.png'),
  require('./Illus-EmployeeBag@3x.png'),
];
export const IllusEmployeeFork = [
  require('./Illus-EmployeeFork.png'),
  require('./Illus-EmployeeFork@2x.png'),
  require('./Illus-EmployeeFork@3x.png'),
];
export const IllusEmployeeSpoon = [
  require('./Illus-EmployeeSpoon.png'),
  require('./Illus-EmployeeSpoon@2x.png'),
  require('./Illus-EmployeeSpoon@3x.png'),
];
export const IllusEmpty_1 = [
  require('./Illus-Empty_1.png'),
  require('./Illus-Empty_1@2x.png'),
  require('./Illus-Empty_1@3x.png'),
];
export const IllusEmpty_2 = [
  require('./Illus-Empty_2.png'),
  require('./Illus-Empty_2@2x.png'),
  require('./Illus-Empty_2@3x.png'),
];
export const IllusFall = [
  require('./Illus-Fall.png'),
  require('./Illus-Fall@2x.png'),
  require('./Illus-Fall@3x.png'),
];
export const IllusFoodOnItsWay = [
  require('./Illus-FoodOnItsWay.png'),
  require('./Illus-FoodOnItsWay@2x.png'),
  require('./Illus-FoodOnItsWay@3x.png'),
];
export const IllusLocation = [
  require('./Illus-Location.png'),
  require('./Illus-Location@2x.png'),
  require('./Illus-Location@3x.png'),
];
export const IllusLoyalty = [
  require('./Illus-Loyalty.png'),
  require('./Illus-Loyalty@2x.png'),
  require('./Illus-Loyalty@3x.png'),
];
export const IllusLoyaltyBloom = [
  require('./Illus-LoyaltyBloom.png'),
  require('./Illus-LoyaltyBloom@2x.png'),
  require('./Illus-LoyaltyBloom@3x.png'),
];
export const IllusLoyaltyHarvest = [
  require('./Illus-LoyaltyHarvest.png'),
  require('./Illus-LoyaltyHarvest@2x.png'),
  require('./Illus-LoyaltyHarvest@3x.png'),
];
export const IllusLoyaltySpring = [
  require('./Illus-LoyaltySpring.png'),
  require('./Illus-LoyaltySpring@2x.png'),
  require('./Illus-LoyaltySpring@3x.png'),
];
export const IllusNutrition_1 = [
  require('./Illus-Nutrition_1.png'),
  require('./Illus-Nutrition_1@2x.png'),
  require('./Illus-Nutrition_1@3x.png'),
];
export const IllusNutrition_2 = [
  require('./Illus-Nutrition_2.png'),
  require('./Illus-Nutrition_2@2x.png'),
  require('./Illus-Nutrition_2@3x.png'),
];
export const IllusOutpost_1 = [
  require('./Illus-Outpost_1.png'),
  require('./Illus-Outpost_1@2x.png'),
  require('./Illus-Outpost_1@3x.png'),
];
export const IllusOutpost_2 = [
  require('./Illus-Outpost_2.png'),
  require('./Illus-Outpost_2@2x.png'),
  require('./Illus-Outpost_2@3x.png'),
];
export const IllusPickup_1 = [
  require('./Illus-Pickup_1.png'),
  require('./Illus-Pickup_1@2x.png'),
  require('./Illus-Pickup_1@3x.png'),
];
export const IllusPickup_2 = [
  require('./Illus-Pickup_2.png'),
  require('./Illus-Pickup_2@2x.png'),
  require('./Illus-Pickup_2@3x.png'),
];
export const IllusPlateEmpty = [
  require('./Illus-PlateEmpty.png'),
  require('./Illus-PlateEmpty@2x.png'),
  require('./Illus-PlateEmpty@3x.png'),
];
export const IllusPlateFull = [
  require('./Illus-PlateFull.png'),
  require('./Illus-PlateFull@2x.png'),
  require('./Illus-PlateFull@3x.png'),
];
export const IllusSeasonal = [
  require('./Illus-Seasonal.png'),
  require('./Illus-Seasonal@2x.png'),
  require('./Illus-Seasonal@3x.png'),
];
export const IllusSideProtein = [
  require('./Illus-SideProtein.png'),
  require('./Illus-SideProtein@2x.png'),
  require('./Illus-SideProtein@3x.png'),
];
export const IllusSideVeggie = [
  require('./Illus-SideVeggie.png'),
  require('./Illus-SideVeggie@2x.png'),
  require('./Illus-SideVeggie@3x.png'),
];
export const IllusSpring = [
  require('./Illus-Spring.png'),
  require('./Illus-Spring@2x.png'),
  require('./Illus-Spring@3x.png'),
];
export const IllusSprout = [
  require('./Illus-Sprout.png'),
  require('./Illus-Sprout@2x.png'),
  require('./Illus-Sprout@3x.png'),
];
export const IllusStoreOpening = [
  require('./Illus-StoreOpening.png'),
  require('./Illus-StoreOpening@2x.png'),
  require('./Illus-StoreOpening@3x.png'),
];
export const IllusSummerEarly = [
  require('./Illus-SummerEarly.png'),
  require('./Illus-SummerEarly@2x.png'),
  require('./Illus-SummerEarly@3x.png'),
];
export const IllusSummerLate = [
  require('./Illus-SummerLate.png'),
  require('./Illus-SummerLate@2x.png'),
  require('./Illus-SummerLate@3x.png'),
];
export const IllusSupplyChain = [
  require('./Illus-SupplyChain.png'),
  require('./Illus-SupplyChain@2x.png'),
  require('./Illus-SupplyChain@3x.png'),
];
export const IllusThanks = [
  require('./Illus-Thanks.png'),
  require('./Illus-Thanks@2x.png'),
  require('./Illus-Thanks@3x.png'),
];
export const IllusWinter = [
  require('./Illus-Winter.png'),
  require('./Illus-Winter@2x.png'),
  require('./Illus-Winter@3x.png'),
];
export const IllusMushroom = [
  require('./Illus-mushroom.png'),
  require('./Illus-mushroom@2x.png'),
  require('./Illus-mushroom@3x.png'),
];
