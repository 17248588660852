import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Container,
  JumpTarget,
  slugify,
  theme,
  useResponsive,
} from '@sg/garnish';

import type {
  DeliveryOrderDetailInput,
  RecommendedItemFragment,
} from '@order/graphql';

import type { CategoryFragment } from '../../GraphQL/Restaurant.query.generated';
import { MenuCategory, MenuCustomCategory } from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuCategories = (props: CategoriesProps) => {
  const { categories, restaurantSlug, deliveryDetails, recommendations } =
    props;

  return (
    <View style={styles.wrapper} testID="menu.categories.wrapper">
      <Container>
        {categories.map((category) => (
          <MenuCategoryWrapper
            key={category.id}
            categoryId={category.id}
            categoryName={category.name}
          >
            {category.isCustom ? (
              <MenuCustomCategory
                customProducts={category.products}
                restaurantSlug={restaurantSlug}
                deliveryDetails={deliveryDetails}
                recommendations={recommendations}
              />
            ) : (
              <MenuCategory
                category={category}
                restaurantSlug={restaurantSlug}
                deliveryDetails={deliveryDetails}
              />
            )}
          </MenuCategoryWrapper>
        ))}
      </Container>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const MenuCategoryWrapper = (props: MenuCategoryWrapperProps) => {
  const { categoryId, categoryName, children } = props;

  const { match } = useResponsive();

  const testID = slugify(`menupage.${categoryName}-container`);

  return (
    <JumpTarget id={categoryId}>
      <View
        style={match([styles.categoryWrapper, styles.categoryWrapperSM])}
        testID={testID}
      >
        {children}
      </View>
    </JumpTarget>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: theme.spacing['10'],
    backgroundColor: theme.colors.KHAKI_3,
  },
  categoryWrapper: {
    paddingTop: theme.spacing['10'],
  },
  categoryWrapperSM: {
    paddingTop: theme.spacing['16'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CategoriesProps = Readonly<{
  categories: readonly CategoryFragment[];
  restaurantSlug: string;
  deliveryDetails?: DeliveryOrderDetailInput;
  recommendations?: readonly RecommendedItemFragment[];
}>;

type MenuCategoryWrapperProps = Readonly<{
  categoryId: string;
  categoryName: string;
  children: React.ReactNode;
}>;
