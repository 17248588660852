import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';

/**
 * Returns the contentful data for the bag.
 */
export const useBagContent = () => {
  const content = useContentfulContentTypeEntry<BagContent>({
    contentType: 'bag',
  });

  const contentfulData = content.data?.fields;

  const { formatMessage } = useIntl();
  const fallbackData = {
    viewMenuCta: formatMessage(fallbacks.viewMenuCta),
    menuIconCta: formatMessage(fallbacks.menuIconCta),
    continueToCheckoutCta: formatMessage(fallbacks.continueToCheckoutCta),
  };

  const viewMenuCta = contentfulData?.viewMenuCta ?? fallbackData.viewMenuCta;
  const menuIconCta = contentfulData?.menuIconCta ?? fallbackData.menuIconCta;
  const continueToCheckoutCta =
    contentfulData?.continueToCheckoutCta ?? fallbackData.continueToCheckoutCta;

  return { viewMenuCta, menuIconCta, continueToCheckoutCta };
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  viewMenuCta: {
    defaultMessage: 'View menu',
    description: 'Bag | CTAs | View menu',
  },
  menuIconCta: {
    defaultMessage: 'Menu',
    description: 'Bag | CTAs | View menu',
  },
  continueToCheckoutCta: {
    defaultMessage: 'Continue to checkout',
    description: 'Bag | CTAs | Continue to checkout',
  },
});

type BagContent = {
  viewMenuCta: string;
  menuIconCta: string;
  continueToCheckoutCta: string;
};
