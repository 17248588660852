import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollView, StyleSheet, View, type ViewProps } from 'react-native';
import { DisplayText, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The payment method form for the bag.
 */
export const BagPaymentMethodForm = (props: BagPaymentMethodFormProps) => {
  const { children } = props;
  const { match } = useResponsive();

  return (
    <View style={styles.container}>
      <DisplayText style={styles.title} sizeMatch={['32']}>
        <FormattedMessage
          defaultMessage="Add card"
          description="Bag | Payment method form | Title"
        />
      </DisplayText>

      <ScrollView
        contentContainerStyle={styles.scrollView}
        style={match([styles.contentXs, styles.contentMd])}
      >
        {children}
      </ScrollView>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  scrollView: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing['2'],
  },
  contentXs: {
    marginHorizontal: -theme.spacing['4'],
  },
  contentMd: {
    marginHorizontal: -theme.spacing['6'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagPaymentMethodFormProps = Readonly<{
  children: ViewProps['children'];
}>;
