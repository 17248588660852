import { type RefObject, useEffect } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

/**
 * Keeps the amount to be used in the Stripe payment request in sync.
 */
export const useUpdatePaymentAmount = (
  stripeReference: RefObject<HostedFrameRef>,
  amount: number,
  isLoading: boolean,
) => {
  useEffect(() => {
    if (isLoading) return;

    stripeReference?.current?.postMessage?.(
      JSON.stringify({ type: 'UPDATE_PAYMENT_AMOUNT', amount }),
    );
  }, [amount, isLoading, stripeReference]);
};
