import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, type ViewProps } from 'react-native';
import { Header } from '@expo/html-elements';
import { IconLink, theme, useResponsive } from '@sg/garnish';

/**
 * Channel, location and time wrapper.
 */
export const BagHeaderContainer = (props: BagHeaderContainerProps) => {
  const { children, onClose } = props;

  const { formatMessage } = useIntl();
  const {
    minWidth: { isSM },
  } = useResponsive();

  return (
    <Header style={styles.container}>
      {isSM ? (
        <IconLink
          name="IconClose"
          width={40}
          height={40}
          style={styles.closeIcon}
          accessibilityLabel={formatMessage(messages.closeBag)}
          onPress={onClose}
        />
      ) : null}

      {children}
    </Header>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  closeBag: {
    defaultMessage: 'Close Bag',
    description: 'Bag | Header | Close bag button',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagHeaderContainerProps = ViewProps & {
  onClose: () => void;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing['2'],
  },
  closeIcon: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.xxxlarge,
    padding: theme.spacing['1'],
    alignSelf: 'flex-end',
  },
});
