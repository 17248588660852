import React, { type PropsWithChildren, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet } from 'react-native';

import { useResponsive } from '../../hooks';
import { Button } from '../Button';
import { Modal, ModalDialogue } from '../Modal';
import { WithTooltip } from '../WithTooltip';
import { type LedgerItemLabelProps } from './Ledger.types';

// ────────────────────────────────────────────────────────────────────────────

export const LedgerRowLabel = (
  props: LedgerItemLabelProps & PropsWithChildren,
) => {
  const { formatMessage } = useIntl();

  const {
    helperText,
    label,
    helperTextModalDismissLabel = formatMessage(
      messages.helperTextModalDismissLabelFallback,
    ),
    helperTextToggleA11yLabel = formatMessage(
      messages.helperTextToggleA11yLabelFallback,
    ),
    children,
  } = props;

  // ──────────────────────────────────────────────────────────────────────────

  const [shouldShowHelperModal, setShouldShowHelperModal] = useState(false);
  const { currentBreakpoint } = useResponsive();

  // ──────────────────────────────────────────────────────────────────────────

  const toggleHelperTextModal = () => {
    setShouldShowHelperModal((currentValue) => !currentValue);
  };

  // ──────────────────────────────────────────────────────────────────────────

  if (!helperText) {
    return children;
  }

  // <Modal> should be used in case of mobile breakpoint
  if (currentBreakpoint.isXS) {
    return (
      <>
        <Pressable
          onPress={toggleHelperTextModal}
          accessibilityRole="button"
          accessibilityLabel={helperTextToggleA11yLabel}
          style={styles.helpTextToggle}
        >
          {children}
        </Pressable>

        <ModalDialogue
          size="medium"
          accessibilityLabel={helperTextToggleA11yLabel}
          visible={shouldShowHelperModal}
          headline={label}
          body={helperText}
          onRequestClose={toggleHelperTextModal}
        >
          <Modal.Footer withoutTopBorder>
            <Button
              onPress={toggleHelperTextModal}
              palette="primary"
              accessibilityRole="button"
              accessibilityLabel={helperTextToggleA11yLabel}
              size="large"
            >
              {helperTextModalDismissLabel}
            </Button>
          </Modal.Footer>
        </ModalDialogue>
      </>
    );
  }

  // and <Tooltip> otherwise
  return (
    <WithTooltip
      text={helperText}
      placement="right"
      triggerMode="hover"
      toggleAccessibilityLabel={helperTextToggleA11yLabel}
    >
      {children}
    </WithTooltip>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  helpTextToggle: {
    flexShrink: 1,
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  helperTextModalDismissLabelFallback: {
    defaultMessage: 'Got it',
    description: 'Ledger | Helper Text Modal Dismiss Label | Fallback',
  },
  helperTextToggleA11yLabelFallback: {
    defaultMessage: 'Toggle helper text',
    description: 'Ledger | Helper Text Toggle A11y Label | Fallback',
  },
});
