import { format, parse } from 'date-fns';

/**
 * A small function that converts a 24-hour time string into a 12-hour time string.
 *
 * NOTE: Because the `parse` helper can throw an error in case of invalid input,
 *       try...catch is used, which uses original input as a fallback.
 *
 * @example
 * convert24HoursTo12Hours('13:00') // => 1:00pm
 * convert24HoursTo12Hours('07:00') // => 7:00am
 */
export function convert24HoursTo12Hours(timeString: string) {
  try {
    const parsedTime = parse(timeString, 'HH:mm', new Date());

    return format(parsedTime, 'h:mm aaa');
  } catch {
    return timeString;
  }
}
