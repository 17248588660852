import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import { AnimatedHeader, BodyText, TextSpace } from '@sg/garnish';

import {
  BagChannelHeader,
  BagLocationFromConnector,
  BagLocationHeader,
  BagLocationToConnector,
} from '../../Header';
import { type BagScrollViewProps } from './BagScrollView.props';

/**
 * ScrollView for the bag on the XS view port.
 * When scrolled displays summarized header info.
 * When not scrolled, displays the channel and time info.
 */
export const BagScrollView = (props: BagScrollViewProps) => {
  const {
    children,
    orderChannel,
    locationName,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
    onHeaderCloseBtnPress,
  } = props;

  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useScrollViewOffset(animatedRef);

  const { formatMessage } = useIntl();
  const limitedLocationName =
    locationName.length > 20 ? locationName.slice(0, 17) + '...' : locationName;

  return (
    <Animated.ScrollView
      ref={animatedRef}
      style={styles.bagScrollView}
      contentContainerStyle={styles.bagScrollView}
      stickyHeaderIndices={[0]}
    >
      <AnimatedHeader
        scrollOffset={scrollOffset}
        accessibilityLabelClose={formatMessage(messages.closeBag)}
        onCloseBtnPress={onHeaderCloseBtnPress}
      >
        <BodyText style={styles.headerText} sizeMatch={['16']}>
          <FormattedMessage {...messages.title} />
        </BodyText>

        <BodyText style={styles.headerText} sizeMatch={['16']}>
          {orderChannel === 'outpost' ? null : (
            <BagChannelHeader
              orderChannel={orderChannel}
              requestChannelOrLocationChange={requestChannelOrLocationChange}
            />
          )}

          {orderChannel === 'outpost' ? null : <TextSpace />}

          {orderChannel === 'pickup' ? <BagLocationFromConnector /> : null}

          {orderChannel === 'delivery' ? <BagLocationToConnector /> : null}

          {orderChannel === 'outpost' ? null : <TextSpace />}

          <BagLocationHeader
            orderChannel={orderChannel}
            locationName={limitedLocationName}
            requestDeliveryPreferencesChange={requestDeliveryPreferencesChange}
            requestChannelOrLocationChange={requestChannelOrLocationChange}
          />
        </BodyText>
      </AnimatedHeader>

      {children}
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Your bag',
    description: 'Bag | Header | Title',
  },
  closeBag: {
    defaultMessage: 'Close Bag',
    description: 'Bag | Header | Close bag button',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerText: {
    textAlign: 'center',
  },
  bagScrollView: {
    flexGrow: 1,
  },
});
