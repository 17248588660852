import React, {
  type ComponentProps,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type View,
  type ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../../utils';
import {
  type ButtonFluidPressablePalette,
  paletteStyles,
} from './ButtonFluidPressable.pallets';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonFluidPressable = forwardRef<View, ButtonFluidPressableProps>(
  (props, ref) => {
    const {
      style,
      disabled,
      palette = 'kale',
      safeAreaEdge,
      ...restProps
    } = props;

    // ─── Refs ────────────────────────────────────────────────────────────

    const localRef = useRef(null);
    const mergedRef = useMergedRef(localRef, ref);

    // ─── Safe Area ───────────────────────────────────────────────────────

    const safeAreaInsets = useSafeAreaInsets();

    const shouldUseSafeAreaEdgeTop =
      safeAreaEdge === 'top' || safeAreaEdge === 'vertical';
    const shouldUseSafeAreaEdgeBottom =
      safeAreaEdge === 'bottom' || safeAreaEdge === 'vertical';

    // ─── Styles ──────────────────────────────────────────────────────────

    const safeAreaStyles = useStyle(() => {
      const { top, bottom } = safeAreaInsets;

      return {
        paddingTop: shouldUseSafeAreaEdgeTop
          ? Math.max(PRESSABLE_PADDING, top)
          : PRESSABLE_PADDING,
        paddingBottom: shouldUseSafeAreaEdgeBottom
          ? Math.max(PRESSABLE_PADDING, bottom)
          : PRESSABLE_PADDING,
      };
    }, [safeAreaInsets.top, safeAreaInsets.bottom]);

    const pressableStyles = useCallback(
      (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
        const { pressed, hovered } = state as InteractionState;

        return [
          styles.pressable,
          pressableWebStyles,
          safeAreaStyles,
          disabled ? paletteStyles.disabled.idle : paletteStyles[palette].idle,
          hovered && !disabled ? paletteStyles[palette].hover : undefined,
          pressed && !disabled ? styles.pressablePressed : undefined,
          style,
        ];
      },
      [disabled, palette, safeAreaStyles, style],
    );

    // ─────────────────────────────────────────────────────────────────────

    return (
      <Pressable
        ref={mergedRef}
        accessibilityRole="button"
        disabled={disabled}
        style={pressableStyles}
        {...restProps}
      />
    );
  },
);

// ─── Constants ───────────────────────────────────────────────────────────────

const PRESSABLE_PADDING = theme.spacing['6'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    width: '100%',
    minHeight: 72,
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    backgroundColor: theme.colors.KALE, // default background color
    padding: theme.spacing['6'],
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.KALE_HOVER, // default hover background color
  },
  pressablePressed: {
    opacity: 0.8,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms, opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonFluidPressableProps = {
  safeAreaEdge?: 'top' | 'bottom' | 'vertical';
  style?: ViewStyle;

  /**
   * @default "kale"
   */
  palette?: ButtonFluidPressablePalette;
} & Omit<ComponentProps<typeof Pressable>, 'style'>;

type InteractionState = {
  hovered: boolean;
} & PressableStateCallbackType;
