import React, { useCallback } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Icon } from '../../Icon';
import { BodyText } from '../../Text';
import { Button } from '../Button';

export const ButtonBag = (props: ButtonBagProps) => {
  const { bagCount, children, variation, ...buttonProps } = props;

  const btnRightElement = useCallback(
    () => <BagIcon bagCount={bagCount} variation={variation} />,
    [bagCount, variation],
  );

  if (!bagCount) return null;

  return (
    <Button
      palette="lime"
      rightElement={btnRightElement}
      style={[styles.button, buttonProps.style]}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export const BagIcon = ({ bagCount, variation }: ButtonBagProps) => {
  const { fontScale } = useWindowDimensions();
  const iconSize = 24 * fontScale;

  return (
    <View style={styles.container}>
      <Icon
        width={iconSize}
        height={iconSize}
        name={variation === 'new' ? 'IconBagFill2' : 'IconBagFill'}
        color={theme.colors.GREEN_1}
      />
      <BodyText size={5} style={styles.label}>
        {bagCount > 9 ? '9+' : bagCount}
      </BodyText>
    </View>
  );
};

type ButtonBagProps = Readonly<{
  bagCount: number;
  variation?: 'legacy' | 'new';
}> &
  React.ComponentProps<typeof Button>;

const styles = StyleSheet.create({
  button: {
    ...theme.elevations['12'],
  },
  container: {
    marginLeft: theme.spacing['1'],
    marginTop: -2,
  },
  label: {
    position: 'absolute',
    right: 0,
    bottom: 1,
    left: 0,
    textAlign: 'center',
    color: theme.colors.LIME,
  },
});
