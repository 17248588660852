import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

/**
 * Floating buttons container for the bag screen.
 */
export const BagFloatingCtas = (props: PropsWithChildren) => {
  const { children } = props;

  const { bottom } = useSafeAreaInsets();
  const insetStyles = useStyle(() => ({
    bottom: Math.max(bottom + theme.spacing['4'], theme.spacing['8']),
  }));

  return <View style={[styles.floatingCta, insetStyles]}>{children}</View>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  floatingCta: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
    marginHorizontal: theme.spacing['8'],
    gap: theme.spacing['4'],
    flexDirection: 'row',
  },
});
