import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';

/**
 * View menu button to be used beneath the header.
 */
export const BagViewMenuCta = (props: BagViewMenuCtaProps) => {
  return (
    <View style={styles.cta}>
      <Button
        palette="secondary"
        accessibilityLabel={props.cta}
        disabled={props.isDisabled}
        onPress={props.onPress}
      >
        {props.cta}
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cta: {
    alignSelf: 'flex-start',
    marginBottom: -theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagViewMenuCtaProps = {
  cta: string;
  isDisabled: boolean;
  onPress: () => void;
};
