import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  HStack,
  ProductBannerCard,
  ProductBannerCardWithOverlay,
  theme,
  useResponsive,
} from '@sg/garnish';

import type {
  DeliveryOrderDetailInput,
  RecommendedItemFragment,
} from '@order/graphql';
import { CustomProductType, type LineItemRecommendation } from '@order/graphql';
import { useNavigateToProductDetails } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { usePersonalizedMenuRecommendationsTelemetry } from '../../../../../components/MenuPersonalizationRail/hooks';
import type { PartialProduct } from '../../../../../graphql/partials';
import { useCustomProductBannerCardContentfulData } from '../../../hooks/useCustomProductBannerCardContentfulData';
import { usePersonalizedRecommendations } from '../../../hooks/usePersonalizedRecommendations';
import { MenuRecommendationItem } from './MenuRecommendationItem';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuCustomCategory = (props: MenuCustomCategoryProps) => {
  const { customProducts, restaurantSlug, deliveryDetails } = props;

  const { match } = useResponsive();

  // ─── Personalized Recommendations ──────────────────────────────────────────────────────────────

  const { menuRecommendationsList, isFetching: isFetchingRecommendations } =
    usePersonalizedRecommendations();

  const isFetching = isFetchingRecommendations;

  const hasRecommendations =
    Boolean(menuRecommendationsList) && menuRecommendationsList.length > 0;

  const recommendationProductCardVariant = useFeatureFlag(
    'em-873-product-recommendation-card-variant',
  );

  usePersonalizedMenuRecommendationsTelemetry({
    isFetching,
    hasRecommendations,
    recommendations: menuRecommendationsList as LineItemRecommendation[],
    cardVariant: recommendationProductCardVariant,
  });

  const personalizedProduct = customProducts?.find(
    (product) => product.customType === CustomProductType.Bowl,
  );

  // ─── Layout ──────────────────────────────────────────────────────────

  const stackDirection = match(['column', 'row'] as const);
  const cardsPerRow = match([1, 2, 3, 4]);
  const columnGap = match([0, theme.spacing['6']]);

  const itemsCount = Math.max(
    Number(customProducts.length),
    Number(menuRecommendationsList.length),
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = useStyle(
    () => ({
      flexDirection: stackDirection,
      marginHorizontal: -(columnGap / 2),
      rowGap: theme.spacing['6'],
    }),
    [stackDirection, columnGap],
  );
  const singleColumnStyles = useStyle(
    () => ({
      width: '100%',
      paddingHorizontal: columnGap / 2,
    }),
    [columnGap],
  );
  const customCategoryColumnStyles = useStyle(
    () => ({
      width: cardsPerRow > 1 ? `${100 - 100 / cardsPerRow}%` : '100%',
      paddingHorizontal: columnGap / 2,
    }),
    [columnGap, cardsPerRow],
  );
  const recommendationColumnStyles = useStyle(
    () => ({
      width: cardsPerRow > 1 ? `${100 / cardsPerRow}%` : '100%',
      paddingHorizontal: columnGap / 2,
    }),
    [columnGap, cardsPerRow],
  );

  // ─────────────────────────────────────────────────────────────────────

  const customCategoriesAndRecommendations = useMemo<
    CustomProductAndRecommendations[]
  >(() => {
    const items = Array.from({ length: itemsCount });

    return items.reduce<CustomProductAndRecommendations[]>(
      (allItems, _current, index) => {
        const newCustomProductAndRecommendations: CustomProductAndRecommendations =
          {
            key: `custom-product-and-recommendations-${index + 1}`,
            customProduct: customProducts[index],
            recommendation: menuRecommendationsList[index],
          };

        return [...allItems, newCustomProductAndRecommendations];
      },
      [],
    );
  }, [customProducts, itemsCount, menuRecommendationsList]);

  const customProductsAndRecommendationsView = (
    <View style={styles.container}>
      {customCategoriesAndRecommendations.map((item) => {
        const hasBothColumns =
          item.customProduct !== undefined && item.recommendation !== undefined;

        const customProductColumnConditionalStyles = hasBothColumns
          ? customCategoryColumnStyles
          : singleColumnStyles;

        return (
          <View key={item.key} style={containerStyles}>
            {item.customProduct ? (
              <View style={customProductColumnConditionalStyles}>
                <MenuCustomCategoryProduct
                  key={item.customProduct.id}
                  customProduct={item.customProduct}
                  restaurantSlug={restaurantSlug}
                  deliveryDetails={deliveryDetails}
                />
              </View>
            ) : null}

            {item.recommendation ? (
              <View style={recommendationColumnStyles}>
                <MenuRecommendationItem
                  // @ts-expect-error TS(2322): Type 'Partial<Readonly<{ label: Partial<ProductLab... Remove this comment to see the full error message
                  product={personalizedProduct}
                  recommendation={item.recommendation}
                  key={item.recommendation.id}
                />
              </View>
            ) : null}
          </View>
        );
      })}
    </View>
  );

  // ─── Non-recommendation layout  ─────────────────────────────────────
  const hasMultipleProducts = customProducts.length > 1;
  const itemsPerRow = match([1, hasMultipleProducts ? 2 : 1]);

  const customProductsHStack = (
    <HStack itemsPerRow={itemsPerRow}>
      {customProducts.map((customProduct) => (
        <MenuCustomCategoryProduct
          key={customProduct.id}
          customProduct={customProduct}
          restaurantSlug={restaurantSlug}
          deliveryDetails={deliveryDetails}
        />
      ))}
    </HStack>
  );

  // ─────────────────────────────────────────────────────────────────────

  return hasRecommendations
    ? customProductsAndRecommendationsView
    : customProductsHStack;
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const MenuCustomCategoryProduct = (props: MenuCustomCategoryProductProps) => {
  const { customProduct, restaurantSlug, deliveryDetails } = props;
  const isCustomPlate = customProduct.customType === CustomProductType.Plate;
  const navigateToProduct = useNavigateToProductDetails({
    restaurantSlug,
    deliveryAddressId: deliveryDetails?.addressId,
  });
  const onCardPress = useCallback(() => {
    navigateToProduct(customProduct);
  }, [customProduct, navigateToProduct]);

  const { t } = useLocalizationContext();
  const shouldUseNewCyoBanner = useFeatureFlag('MNU-3430-short-s5-cyo-banner');
  const contentfulData = useCustomProductBannerCardContentfulData({
    customProduct,
    pause: !shouldUseNewCyoBanner,
  });

  if (shouldUseNewCyoBanner) {
    return (
      <ProductBannerCardWithOverlay
        key={customProduct.id}
        title={contentfulData.heading}
        description={contentfulData.body}
        onCtaPress={onCardPress}
        ctaLabel={contentfulData.buttonLabel}
        ctaPalette={contentfulData.buttonPalette}
        isDisabled={customProduct.outOfStock}
        backgroundImage={contentfulData.backgroundImage}
        backgroundOverlayColor={contentfulData.backgroundOverlayColor}
        textColor={contentfulData.textColor}
      />
    );
  }

  // ─────────────────────────────────────────────────────────────────

  const title = isCustomPlate
    ? t('menu.cyo.plate.title')
    : t('menu.cyo.bowl.title');
  const description = isCustomPlate
    ? t('menu.cyo.plate.description')
    : t('menu.cyo.bowl.description');
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const ctaLabel = customProduct.outOfStock
    ? t('menu.cyo.out-of-stock.cta')
    : isCustomPlate
      ? t('menu.cyo.plate.cta')
      : t('menu.cyo.bowl.cta');
  const ctaTestID = isCustomPlate ? 'menu.cyo.plate.cta' : 'menu.cyo.bowl.cta';

  // ─────────────────────────────────────────────────────────────────

  return (
    <ProductBannerCard
      key={customProduct.id}
      title={title}
      description={description}
      ctaLabel={ctaLabel}
      illustration={isCustomPlate ? 'plate' : 'bowl'}
      onCtaPress={onCardPress}
      ctaTestID={ctaTestID}
      isDisabled={customProduct.outOfStock}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['6'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuCustomCategoryProps = Readonly<{
  customProducts: readonly CustomProduct[];
  restaurantSlug: string;
  deliveryDetails?: DeliveryOrderDetailInput;
  recommendations?: readonly Recommendation[];
}>;

type MenuCustomCategoryProductProps = Readonly<{
  customProduct: PartialProduct;
  restaurantSlug: string;
  deliveryDetails?: DeliveryOrderDetailInput;
}>;

type CustomProductAndRecommendations = {
  key: string;
  customProduct: CustomProduct | undefined;
  recommendation: Recommendation | undefined;
};

type CustomProduct = PartialProduct;

type Recommendation = RecommendedItemFragment;
