import React from 'react';
import { StyleSheet } from 'react-native';
import type { ImageTransform } from 'cloudinary-tiny-js';

import { IllusEmpty_2 } from '../../../assets';
import { useResponsive } from '../../../hooks';
import { FallbackImage } from '../../Image';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemThumbnail = (props: LineItemThumbnailProps) => {
  const { cloudinaryImageUrl } = props;
  const { match } = useResponsive();

  return (
    <FallbackImage
      baseUrl={cloudinaryImageUrl}
      defaultImage={IllusEmpty_2}
      testID="product-card-image"
      contentFit="contain"
      style={match([styles.imageXs, styles.imageSm])}
      cloudinaryConfig={match([CLOUDINARY_CONFIG_XS, CLOUDINARY_CONFIG_SM])}
    />
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const CLOUDINARY_CONFIG_XS: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: 80 },
];

const CLOUDINARY_CONFIG_SM: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: 120 },
];

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageXs: {
    width: 80,
    height: 80,
  },
  imageSm: {
    width: 120,
    height: 120,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemThumbnailProps = Readonly<{
  cloudinaryImageUrl: string;
}>;
