import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Button } from '@sg/garnish';

/**
 * "Add utensils to my bag" button in the bottom of the bag.
 */
export const BagUtensilsCta = (props: BagUtensilsCtaProps) => {
  const { shouldIncludeUtensils, isDisabled, onChange } = props;

  const handleOnChange = useCallback(() => {
    onChange({ shouldIncludeUtensils: !shouldIncludeUtensils });
  }, [shouldIncludeUtensils, onChange]);

  return (
    <View style={styles.container}>
      <Button
        size="large"
        palette={shouldIncludeUtensils ? 'primary' : 'secondary'}
        accessibilityRole="button"
        disabled={isDisabled}
        onPress={handleOnChange}
      >
        {shouldIncludeUtensils ? (
          <FormattedMessage {...messages.utensilsCtaOn} />
        ) : (
          <FormattedMessage {...messages.utensilsCtaOff} />
        )}
      </Button>

      <BodyText style={styles.hint} sizeMatch={['14']}>
        <FormattedMessage {...messages.utensilsHint} />
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  utensilsCtaOff: {
    defaultMessage: 'Add utensils to my bag',
    description: 'Bag | Utensils | Cta (on)',
  },
  utensilsCtaOn: {
    defaultMessage: 'Utensils added',
    description: 'Bag | Utensils | Cta (off)',
  },
  utensilsHint: {
    defaultMessage: 'Our utensils and napkins are 100% compostable.',
    description: 'Bag | Utensils | Hint',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderBottomWidth: theme.spacing['2'],
    padding: theme.spacing['4'],
    marginHorizontal: -theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  hint: {
    alignSelf: 'center',
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagUtensilsCtaProps = {
  shouldIncludeUtensils: boolean;
  isDisabled: boolean;
  onChange: (payload: { shouldIncludeUtensils: boolean }) => void;
};
