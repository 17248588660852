import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { theme } from '@sg/garnish';

/**
 * Footer for the bag.
 */
export const BagFooter = (props: PropsWithChildren) => {
  return <Footer style={styles.container}>{props.children}</Footer>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.LINEN,
    paddingBottom: theme.spacing['24'],
  },
});
