/* istanbul ignore file */

import React, { memo } from 'react';
import type { ViewProps } from 'react-native';
import type { AddressType } from '@sg/garnish';
import { Chip } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useNewLocationsLayout } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchTypePicker = memo(
  (props: LocationSearchTypePickerProps) => {
    const {
      locationSearchType = '',
      isDisabled = false,
      onLocationSearchTypeChange,
    } = props;
    const { t } = useLocalizationContext();

    const shouldUseNewLocationsLayout = useNewLocationsLayout();

    return (
      <Chip.Menu
        value={locationSearchType}
        onChange={onLocationSearchTypeChange}
      >
        {LocationSearchTypes.map((addressType) => {
          const isChecked = addressType === locationSearchType;

          return (
            <Chip
              value={addressType}
              key={addressType}
              disabled={isDisabled}
              accessibilityLabel={t('general.address-type', { addressType })}
              accessibilityState={{ checked: isChecked }}
              maxFontSizeMultiplier={1.2}
              palette={
                shouldUseNewLocationsLayout ? 'primary' : 'primaryOutline'
              }
              testID={`search-bar.chip.${addressType}`}
            >
              {t('general.address-type', { addressType })}
            </Chip>
          );
        })}
      </Chip.Menu>
    );
  },
);

// ─────────────────────────────────────────────────────────────────────────────

const LocationSearchTypes: readonly AddressType[] = [
  'pickup',
  'delivery',
  'outpost',
];

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchTypePickerProps = Readonly<{
  locationSearchType?: string;
  isDisabled?: boolean;
  onLocationSearchTypeChange: (searchType: string) => void;
}> &
  Pick<ViewProps, 'testID'>;
