import React from 'react';
import { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { AnimatedDialogItem } from '@sg/garnish';

export const BagDialogItemWithoutPadding = (props: PropsWithChildren) => {
  return (
    <AnimatedDialogItem style={styles.container}>
      {props.children}
    </AnimatedDialogItem>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    padding: 0,
    paddingBottom: 0,
  },
});
