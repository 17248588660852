/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions */

import { ContextFrom, createMachine } from 'xstate';
import { assign } from 'xstate/lib/actions';

import { APPLE_PAY, GOOGLE_PAY } from '../utils';
import {
  BagReward,
  OrderingCart,
  OrderingMachineActions,
  OrderingMachineContext,
  OrderingMachineEvents,
  OrderingMachineServices,
  PaymentMethod,
} from './ordering-machine.types';
import { orderingMachineLogger } from './ordering-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine to manage the ordering flow on bag + checkout.
 */
export const createOrderingMachine = <LineItem, DeliveryOrderDetail>() => {
  return createMachine(
    {
      tsTypes: {} as import('./ordering-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'ordering-state-machine',
      schema: {
        context: {} as OrderingMachineContext<LineItem, DeliveryOrderDetail>,
        events: {} as OrderingMachineEvents,
        services: {} as OrderingMachineServices<LineItem, DeliveryOrderDetail>,
        actions: {} as OrderingMachineActions<LineItem, DeliveryOrderDetail>,
      },
      context: getInitialContext<LineItem, DeliveryOrderDetail>(),
      type: 'parallel',
      states: {
        ordering: {
          initial: 'idle',
          states: {
            idle: {
              initial: 'waiting',
              states: {
                waiting: {
                  description: 'An empty state used for the initial setup.',
                },
                postOrder: {
                  id: 'post-order',
                  entry: ['navigatePostOrder', 'resetContext'],
                },
                idle: {
                  id: 'idle',
                  description: 'An idle state that awaits the next event.',
                  on: {
                    FETCH_INITIAL_DATA: {
                      description: 'Fetches the initial data (cart + rewards).',
                      target: '#fetching-initial-data',
                    },
                    ADD_UPSELL_TO_BAG: {
                      description: 'Adds an upsell to the bag.',
                      target: '#adding-upsell-to-bag',
                      actions: ['setUpsellBeingAdded'],
                    },
                    CHANGE_LINE_ITEM_QUANTITY: {
                      description: 'Changes a line item quantity.',
                      target: '#changing-line-item-quantity',
                      actions: ['setLineItemQuantity'],
                    },
                    CHANGE_LINE_ITEM_NAME: {
                      description: 'Changes a line item name.',
                      target: '#changing-line-item-name',
                      actions: ['setLineItemName'],
                    },
                    CHANGE_UTENSILS_USAGE: {
                      description: 'Changes the utensils usage',
                      actions: ['changeUtensilsUsage'],
                    },
                    CHANGE_DROPOFF_LOCATION: {
                      description: 'Changes the dropoff location',
                      actions: ['changeDropoffLocation'],
                    },
                    APPLY_REWARD: {
                      description: 'Applies a reward.',
                      actions: ['setRewardIdBeingUpdated'],
                      target: '#applying-reward',
                    },
                    REMOVE_REWARD: {
                      description: 'Removes a reward.',
                      actions: ['setRewardIdBeingUpdated'],
                      target: '#removing-reward',
                    },
                    APPLY_PROMO_CODE: {
                      description: 'Applies a promo code.',
                      target: '#applying-promo-code',
                    },
                    REQUEST_CHANNEL_LOCATION_CHANGE: {
                      description:
                        'Requests the location or channel to be changed',
                      actions: ['changeChannelOrLocation'],
                    },
                    REQUEST_TIME_CHANGE: {
                      description: 'Requests the time to be changed',
                      target: '#changing-time',
                    },
                    REQUEST_DELIVERY_PREFERENCES_CHANGE: {
                      description:
                        'Requests the delivery preferences to be changed',
                      target: '#changing-delivery-preferences',
                    },
                    REQUEST_CHECKOUT_LEDGER: {
                      description: 'Requests the checkout ledger',
                      target: '#checkout-ledger',
                    },
                  },
                },
                changingTime: {
                  id: 'changing-time',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    CHANGE_TIME: [
                      {
                        description: 'Changes the time',
                        actions: ['changeTime'],
                        target: '#idle',
                        cond: 'checkIfDoesNotNeedsToShowFutureDateModal',
                      },
                      {
                        description:
                          'Changes the time and shows future date warning',
                        actions: ['changeTime'],
                        target: '#showing-future-date-warning',
                        cond: 'checkIfNeedsToShowFutureDateModal',
                      },
                    ],
                  },
                },
                changingDeliveryPreferences: {
                  id: 'changing-delivery-preferences',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    CHANGE_DELIVERY_PREFERENCES: {
                      actions: ['changeDeliveryPreferences'],
                      target: '#idle',
                    },
                  },
                },
                showingFutureDateWarning: {
                  id: 'showing-future-date-warning',
                  on: {
                    CANCEL_REQUEST: '#idle',
                  },
                },
                showingRewardWarning: {
                  id: 'showing-reward-warning',
                  on: {
                    CANCEL_REQUEST: '#idle',
                  },
                },
                checkoutLedger: {
                  id: 'checkout-ledger',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    CHANGE_TIP: { actions: ['changeTip'] },
                    REQUEST_PAYMENT_METHOD_CHANGE: '#changing-payment-method',
                    REQUEST_PAYMENT_METHOD_ADD: '#payment-method-form',
                    CHANGE_CREDIT_USAGE: { actions: ['changeCreditUsage'] },
                    SUBMIT_ORDER: '#submitting-order',
                  },
                },
                changingPaymentMethod: {
                  id: 'changing-payment-method',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    REQUEST_CHECKOUT_LEDGER: '#checkout-ledger',
                    REQUEST_GIFT_CARD_FORM: '#gift-card-form',
                    REQUEST_PAYMENT_METHOD_ADD: '#payment-method-form',
                    CHANGE_PAYMENT_METHOD: {
                      description: 'Changes the payment method',
                      actions: ['changePaymentMethod'],
                    },
                  },
                },
                paymentMethodForm: {
                  id: 'payment-method-form',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    REQUEST_PAYMENT_METHOD_CHANGE: '#changing-payment-method',
                    ADD_PAYMENT_METHOD: '#adding-payment-method',
                  },
                },
                giftCardForm: {
                  id: 'gift-card-form',
                  on: {
                    CANCEL_REQUEST: '#idle',
                    REQUEST_PAYMENT_METHOD_CHANGE: '#changing-payment-method',
                    APPLY_GIFT_CARD: '#applying-gift-card',
                  },
                },
              },
            },
            loading: {
              states: {
                fetchingInitialData: {
                  id: 'fetching-initial-data',
                  initial: 'fetching',
                  states: {
                    fetching: {
                      description: `A state for fetching the initial data (cart + rewards).`,
                      invoke: {
                        src: 'fetchInitialData',
                        onDone: {
                          target: '#idle',
                          actions: ['onFetchInitialDataDone'],
                        },
                      },
                    },
                  },
                },
                addingUpsellToBag: {
                  id: 'adding-upsell-to-bag',
                  initial: 'adding',
                  states: {
                    adding: {
                      description: 'A state for adding an upsell to the bag.',
                      invoke: {
                        src: 'addUpsellToBag',
                        onDone: {
                          target: '#idle',
                          actions: ['onAddUpsellToBagDone'],
                        },
                      },
                    },
                  },
                },
                changingLineItemQuantity: {
                  id: 'changing-line-item-quantity',
                  initial: 'changing',
                  states: {
                    changing: {
                      description:
                        'A state for changing the line item quantity.',
                      invoke: {
                        src: 'updateLineItem',
                        onDone: {
                          target: '#idle',
                          actions: ['onUpdateLineItemDone'],
                        },
                      },
                      on: {
                        CHANGE_LINE_ITEM_QUANTITY: {
                          description:
                            'Changes another line item quantity while the present one is being changed.',
                          target: '#changing-line-item-quantity',
                          actions: ['setLineItemQuantity'],
                        },
                      },
                    },
                  },
                },
                changingLineItemName: {
                  id: 'changing-line-item-name',
                  initial: 'changing',
                  states: {
                    changing: {
                      description: 'A state for changing the line item name.',
                      invoke: {
                        src: 'updateLineItem',
                        onDone: {
                          target: '#idle',
                          actions: ['onUpdateLineItemDone'],
                        },
                      },
                    },
                  },
                },
                applyingReward: {
                  id: 'applying-reward',
                  initial: 'fetching',
                  states: {
                    fetching: {
                      description: 'A state for applying the reward.',
                      invoke: {
                        src: 'applyReward',
                        onDone: [
                          {
                            target: '#idle',
                            actions: ['onApplyRewardDone'],
                            cond: (_context, event) => 'cart' in event.data,
                          },
                          {
                            target: '#showing-reward-warning',
                            actions: ['onRewardError'],
                            cond: (_context, event) => 'error' in event.data,
                          },
                        ],
                      },
                    },
                  },
                },
                removingReward: {
                  id: 'removing-reward',
                  initial: 'fetching',
                  states: {
                    fetching: {
                      description: 'A state for removing the reward.',
                      invoke: {
                        src: 'removeReward',
                        onDone: [
                          {
                            target: ['#idle', '#fetching-rewards'],
                            actions: ['onRemoveRewardDone'],
                            cond: (_context, event) => 'cart' in event.data,
                          },
                          {
                            target: '#showing-reward-warning',
                            actions: ['onRewardError'],
                            cond: (_context, event) => 'error' in event.data,
                          },
                        ],
                      },
                    },
                  },
                },
                applyingPromoCode: {
                  id: 'applying-promo-code',
                  initial: 'applying',
                  states: {
                    applying: {
                      description: 'A state for applying the promo code.',
                      invoke: {
                        src: 'applyPromoCode',
                        onDone: [
                          {
                            target: '#idle',
                            actions: ['onApplyPromoCodeDone'],
                            cond: (_context, event) => event.data.success,
                          },
                          {
                            target: '#showing-reward-warning',
                            actions: ['onRewardError'],
                            cond: (_context, event) => !event.data.success,
                          },
                        ],
                      },
                    },
                  },
                },
                addingPaymentMethod: {
                  id: 'adding-payment-method',
                  initial: 'adding',
                  states: {
                    adding: {
                      description: 'A state for adding a payment method.',
                      invoke: {
                        src: 'addPaymentMethod',
                        onDone: [
                          {
                            target: '#checkout-ledger',
                            actions: ['onAddPaymentMethodDone'],
                            cond: 'checkIfPaymentMethodAdded',
                          },
                          {
                            target: '#payment-method-form',
                            cond: 'checkIfPaymentMethodNotAdded',
                          },
                        ],
                      },
                    },
                  },
                },
                applyingGiftCard: {
                  id: 'applying-gift-card',
                  initial: 'applying',
                  states: {
                    applying: {
                      description: 'A state for applying a gift card.',
                      invoke: {
                        src: 'applyGiftCard',
                        onDone: [
                          {
                            target: '#changing-payment-method',
                            actions: ['onApplyGiftCardDone'],
                            cond: 'checkIfGiftCardApplied',
                          },
                          {
                            target: '#gift-card-form',
                            cond: 'checkIfGiftCardNotApplied',
                          },
                        ],
                      },
                    },
                  },
                },
                submittingOrder: {
                  id: 'submitting-order',
                  initial: 'submitting',
                  states: {
                    submitting: {
                      description: `A state for submitting the order`,
                      on: { CANCEL_REQUEST: '#idle' },
                      invoke: {
                        src: 'submitOrder',
                        onDone: [
                          {
                            target: '#post-order',
                            cond: 'checkIfOrderSubmissionSuccess',
                          },
                          {
                            target: '#checkout-ledger',
                            actions: ['handleTimeslotUnavailable'],
                            cond: 'checkIfTimeslotIsUnavailable',
                          },
                          {
                            target: '#checkout-ledger',
                            cond: 'checkIfGenericOrderSubmissionError',
                          },
                        ],
                      },
                    },
                  },
                },
              },
            },
          },
        },
        wantedTimes: {
          initial: 'waiting',
          states: {
            polling: {
              invoke: {
                src: 'fetchWantedTimes',
                onDone: {
                  target: 'waiting',
                  actions: ['updateWantedTimes'],
                  cond: 'checkIfHasWantedTimes',
                },
              },
            },
            waiting: {
              after: {
                60_000: {
                  target: 'polling',
                },
              },
            },
          },
        },
        rewards: {
          initial: 'idle',
          states: {
            idle: {
              id: 'rewards-idle',
            },
            preparingToFetchRewards: {
              id: 'preparing-to-fetch-rewards',
              always: [
                {
                  target: '#fetching-rewards',
                  cond: 'checkIfLoggedIn',
                },
                {
                  target: '#rewards-idle',
                },
              ],
            },
            fetchingRewards: {
              id: 'fetching-rewards',
              invoke: {
                src: 'fetchRewards',
                onDone: {
                  target: '#rewards-idle',
                  actions: ['updateRewards'],
                },
                onError: {
                  target: '#rewards-idle',
                  actions: ['onFetchRewardsError'],
                },
              },
            },
          },
        },
        stripe: {
          initial: 'idle',
          states: {
            idle: {
              always: [
                {
                  target: 'initializing',
                  cond: 'checkIfExpressPaymentIsEnabled',
                },
                {
                  target: 'ready',
                },
              ],
            },
            initializing: {
              on: {
                SET_AVAILABLE_EXPRESS_PAYMENTS: {
                  target: 'ready',
                  actions: ['setAvailableExpressPayments'],
                },
              },
              always: [
                {
                  target: 'ready',
                  cond: 'checkIfLoggedOut',
                },
              ],
              after: {
                5000: 'ready',
              },
            },
            ready: {
              always: [
                {
                  actions: ['setPaymentMethodIdAsApplePay'],
                  cond: 'checkShouldSetPaymentMethodIdAsApplePay',
                },
                {
                  actions: ['setPaymentMethodIdAsGooglePay'],
                  cond: 'checkShouldSetPaymentMethodIdAsGooglePay',
                },
                {
                  actions: ['setPaymentMethodIdAsDefaultPaymentMethod'],
                  cond: 'checkShouldSetPaymentMethodIdAsDefaultPaymentMethod',
                },
                {
                  actions: ['setPaymentMethodIdAsFirstPaymentMethod'],
                  cond: 'checkShouldSetPaymentMethodIdAsFirstPaymentMethod',
                },
              ],
            },
          },
        },
      },
      on: {
        START: {
          description:
            'Starts/restarts the machine by fetching the initial data and clearing the state.',
          target: ['#fetching-initial-data', '#preparing-to-fetch-rewards'],
          actions: ['resetContext'],
        },
        SET_DEFAULT_PAYMENT_METHOD: {
          actions: ['setDefaultPaymentMethod'],
        },
      },
    },
    {
      actions: {
        /**
         * Stores the resolved initial data in the context.
         */
        onFetchInitialDataDone: assign({
          isInitialDataReady: true,
          isWantedTimeAsap: true,

          cart: (_context, event) => event.data.cart,
          paymentMethods: (_context, event) => event.data.paymentMethods,
          defaultPaymentMethodId: (_context, event) =>
            event.data.defaultPaymentMethodId,
          hasDefaultPaymentMethodCard: (_context, event) =>
            event.data.paymentMethods.some(
              (pm) => pm.id === event.data.defaultPaymentMethodId,
            ),
          availableCredit: (_context, event) => event.data.availableCredit,
          giftCardBalance: (_context, event) => event.data.giftCardBalance,
          customer: (_context, event) => event.data,
          wantedTime: (_context, event) => event.data.cart.wantedTimes[0],
          dropoffLocation: (_context, event) =>
            event.data.cart.dropoffLocations[0]?.value,
          appliedRewardId(context, event) {
            const { appliedDiscounts } = event.data.cart;

            if (!appliedDiscounts) return undefined;

            return context.rewards.find((reward) =>
              appliedDiscounts.has(reward.name),
            )?.id;
          },
        }),

        /**
         * Stores the rewards that were returned.
         */
        updateRewards: assign({
          rewards: (_context, event) => event.data.rewards,
          appliedRewardId(context, event) {
            const { appliedDiscounts } = context.cart;

            if (!appliedDiscounts) return undefined;

            return event.data.rewards.find((reward) =>
              appliedDiscounts.has(reward.name),
            )?.id;
          },
        }),

        /**
         * Logs errors with fetching rewards.
         */
        onFetchRewardsError() {
          orderingMachineLogger.error('Failed to fetch rewards');
        },

        /**
         * Stores which upsell is being added.
         */
        setUpsellBeingAdded: assign({
          lineItemIdsBeingUpdated: (context, event) => [
            ...context.lineItemIdsBeingUpdated,
            event.params.productId,
          ],
        }),

        /**
         * Stores which line item quantity is being changed.
         */
        setLineItemQuantity: assign({
          lineItemIdsBeingUpdated: (context, event) => [
            ...context.lineItemIdsBeingUpdated,
            event.params.lineItemId,
          ],
        }),

        /**
         * Stores which line item is being renamed or updated.
         */
        setLineItemName: assign({
          lineItemIdBeingRenamed: (_context, event) => event.params.lineItemId,
        }),

        /**
         * Updates the resolved cart in the context.
         */
        onAddUpsellToBagDone: assign({
          lineItemIdsBeingUpdated: [],
          cart(context, event) {
            if ('cart' in event.data) {
              return event.data.cart;
            }

            return context.cart;
          },
        }),

        /**
         * Updates the resolved cart in the context.
         */
        onUpdateLineItemDone: assign({
          cart: (context, event) => event?.data ?? context.cart,
          lineItemIdsBeingUpdated: [],
          lineItemIdBeingRenamed: undefined,
        }),

        /**
         * Stores which reward is being updated.
         */
        setRewardIdBeingUpdated: assign({
          rewardIdBeingUpdated: (_context, event) => event.params.rewardId,
        }),

        /**
         * Stores the updated cart in the context.
         */
        onApplyRewardDone: assign({
          rewardIdBeingUpdated: undefined,
          appliedRewardId(context, event) {
            if ('cart' in event.data) {
              const { appliedDiscounts } = event.data.cart;

              return context.rewards.find((reward) =>
                appliedDiscounts.has(reward.name),
              )?.id;
            }
          },
          cart(context, event) {
            if ('cart' in event.data) {
              return event.data.cart;
            }

            return context.cart;
          },
        }),

        /**
         * Stores the updated cart in the context.
         */
        onRemoveRewardDone: assign({
          rewardIdBeingUpdated: undefined,
          appliedRewardId: undefined,
          cart(context, event) {
            if ('cart' in event.data) {
              return event.data.cart;
            }

            return context.cart;
          },
        }),

        /**
         * Stores the reward error in the context.
         */
        onRewardError: assign({
          rewardIdBeingUpdated: undefined,
          rewardError(_context, event) {
            if ('error' in event.data) {
              return event.data.error;
            }
          },
        }),

        /**
         * Stores the resulting rewards post promo code in the context.
         */
        onApplyPromoCodeDone: assign({
          rewards: (_context, event) => event.data.rewards,
        }),

        /**
         * Stores the resulting gift card balance and available credit in the context.
         */
        onApplyGiftCardDone: assign({
          giftCardBalance: (context, event) =>
            event.data?.giftCardBalance ?? context.giftCardBalance,
          availableCredit: (context, event) =>
            event.data?.availableCredit ?? context.availableCredit,
        }),

        /**
         * Stores the resulting payment method in the context.
         */
        onAddPaymentMethodDone: assign({
          paymentMethodId: (_context, event) => event.data?.id,
          paymentMethods: (context, event) => [
            event.data!,
            ...context.paymentMethods,
          ],
        }),

        /**
         * Stores the available express payments for this device.
         */
        setAvailableExpressPayments: assign({
          expressPayments: (_context, event) => ({
            isExpressPaymentsEnabled: true,
            isStripeReady: true,
            canApplePay: event.params.canApplePay,
            canGooglePay: event.params.canGooglePay,
          }),
        }),

        /**
         * Set the selected payment method id as Apple Pay.
         */
        setPaymentMethodIdAsApplePay: assign({
          paymentMethodId: APPLE_PAY,
        }),

        /**
         * Set the selected payment method id as Google Pay.
         */
        setPaymentMethodIdAsGooglePay: assign({
          paymentMethodId: GOOGLE_PAY,
        }),

        /**
         * Set the selected payment method id as the default payment method.
         */
        setPaymentMethodIdAsDefaultPaymentMethod: assign({
          paymentMethodId(context) {
            const { defaultPaymentMethodId } = context;
            return defaultPaymentMethodId;
          },
        }),

        /**
         * Set the selected payment method id as the first available payment method.
         */
        setPaymentMethodIdAsFirstPaymentMethod: assign({
          paymentMethodId(context) {
            const { paymentMethods } = context;
            const firstAvailablePaymentMethod = paymentMethods?.[0]?.id;
            return firstAvailablePaymentMethod;
          },
        }),

        /**
         * Resets the context of the machine.
         *
         * Can be used to clear the state before restarting the machine.
         */
        resetContext: assign(
          getInitialContextWithoutExpressPayments<
            LineItem,
            DeliveryOrderDetail
          >(),
        ),

        /**
         * Changes the channel or location.
         */
        changeChannelOrLocation(_context) {
          orderingMachineLogger.debug(
            'Please provide `changeChannelOrLocation` action.',
          );
        },

        /**
         * Changes the time.
         */
        changeTime: assign({
          wantedTime: (_context, event) => event.params.wantedTime,
          isWantedTimeAsap: (context, event) =>
            context.cart.wantedTimes[0] === event.params.wantedTime,
          hasShownFutureDateWarning: (context, event) =>
            event.params.isFutureDate || context.hasShownFutureDateWarning,
        }),

        /**
         * Changes the delivery preferences.
         */
        changeDeliveryPreferences: assign({
          cart: (context, event) => ({
            ...context.cart,
            deliveryPreferences: event.params,
          }),
        }),

        /**
         * Updates the wanted times.
         */
        updateWantedTimes: assign({
          cart: (context, event) => ({
            ...context.cart,
            wantedTimes: event.data.wantedTimes,
          }),
          wantedTime: (context, event) =>
            context.isWantedTimeAsap
              ? event.data.wantedTimes[0]
              : context.wantedTime,
        }),

        /**
         * Changes the tip.
         */
        changeTip: assign({
          selectedTip: (_context, event) => event.params.tip,
        }),

        /**
         * Changes the dropoff location.
         */
        changeDropoffLocation: assign({
          dropoffLocation: (_context, event) => event.params.dropoffLocation,
        }),

        /**
         * Changes the payment method id for this order.
         */
        changePaymentMethod: assign({
          paymentMethodId: (_context, event) => event.params.paymentMethodId,
        }),

        /**
         * Changes whether or not this order should use credit.
         */
        changeCreditUsage: assign({
          shouldUseCredit: (_context, event) => event.params.shouldUseCredit,
        }),

        /**
         * Changes the payment method id and whether credit is used.
         */
        changeUtensilsUsage: assign({
          shouldIncludeUtensils: (_context, event) =>
            event.params.shouldIncludeUtensils,
        }),

        /**
         * If the order fails due to timeslot unavailable, select the next available time.
         * Also removes the failed timeslot from the available times.
         */
        handleTimeslotUnavailable: assign({
          cart: (context) => ({
            ...context.cart,
            wantedTimes: context.cart.wantedTimes.filter(
              (wantedTime) => wantedTime !== context.wantedTime,
            ),
          }),
          wantedTime: (context) =>
            context.cart.wantedTimes.find(
              (wantedTime) => wantedTime !== context.wantedTime,
            ),
          isWantedTimeAsap: true,
        }),

        async setDefaultPaymentMethod(_context) {
          orderingMachineLogger.debug(
            'Please provide `setDefaultPaymentMethod` action.',
          );

          return undefined;
        },
      },
      services: {
        async fetchInitialData(_context) {
          orderingMachineLogger.debug('Please provide `fetchCart` service.');

          return {
            cart: {} as OrderingCart<LineItem, DeliveryOrderDetail>,
            paymentMethods: [] as readonly PaymentMethod[],
            defaultPaymentMethodId: undefined,
            hasDefaultPaymentMethodCard: false,
            availableCredit: 0,
            giftCardBalance: 0,
            customerId: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            isNewCustomer: true,
          };
        },
        async fetchRewards(_context) {
          orderingMachineLogger.debug('Please provide `fetchRewards` service.');

          return {
            rewards: [] as readonly BagReward[],
          };
        },
        async fetchWantedTimes(_context) {
          orderingMachineLogger.debug(
            'Please provide `fetchWantedTimes` service.',
          );

          return {
            wantedTimes: [],
          };
        },
        async addUpsellToBag(_context) {
          orderingMachineLogger.debug(
            'Please provide `addUpsellToBag` service.',
          );

          return { cart: {} as OrderingCart<LineItem, DeliveryOrderDetail> };
        },
        async updateLineItem(_context) {
          orderingMachineLogger.debug(
            'Please provide `updateLineItem` service.',
          );

          return {} as OrderingCart<LineItem, DeliveryOrderDetail>;
        },
        async applyReward(_context) {
          orderingMachineLogger.debug('Please provide `applyReward` service.');

          return { cart: {} as OrderingCart<LineItem, DeliveryOrderDetail> };
        },
        async removeReward(_context) {
          orderingMachineLogger.debug('Please provide `removeReward` service.');

          return { cart: {} as OrderingCart<LineItem, DeliveryOrderDetail> };
        },
        async applyPromoCode(_context) {
          orderingMachineLogger.debug(
            'Please provide `applyPromoCode` service.',
          );

          return { rewards: [], success: false, error: undefined };
        },
        async applyGiftCard(_context) {
          orderingMachineLogger.debug(
            'Please provide `applyGiftCard` service.',
          );

          return null;
        },
        async addPaymentMethod(_context) {
          orderingMachineLogger.debug(
            'Please provide `addPaymentMethod` service.',
          );

          return null;
        },
        async submitOrder(_context) {
          orderingMachineLogger.debug('Please provide `submitOrder` service.');

          return {
            success: false,
            isTimeslotUnavailable: false,
            error: undefined,
          };
        },
      },
      guards: {
        checkIfDoesNotNeedsToShowFutureDateModal: (context, event) =>
          !event.params.isFutureDate || context.hasShownFutureDateWarning,
        checkIfNeedsToShowFutureDateModal: (context, event) =>
          event.params.isFutureDate && !context.hasShownFutureDateWarning,
        checkIfOrderSubmissionSuccess: (_context, event) => event.data.success,
        checkIfTimeslotIsUnavailable: (_context, event) =>
          !event.data.success && event.data.isTimeslotUnavailable,
        checkIfGenericOrderSubmissionError: (_context, event) =>
          !event.data.success && !event.data.isTimeslotUnavailable,
        checkIfGiftCardApplied: (_context, event) =>
          Boolean(event.data?.giftCardBalance),
        checkIfGiftCardNotApplied: (_context, event) =>
          !event.data?.giftCardBalance,
        checkIfPaymentMethodAdded: (_context, event) => Boolean(event.data?.id),
        checkIfPaymentMethodNotAdded: (_context, event) => !event.data?.id,
        checkIfHasWantedTimes: (_context, event) =>
          event.data.wantedTimes.length > 0,

        checkIfLoggedIn() {
          orderingMachineLogger.debug(
            'Please provide `checkIfLoggedIn` guard.',
          );

          return false;
        },

        checkIfLoggedOut() {
          orderingMachineLogger.debug(
            'Please provide `checkIfLoggedOut` guard.',
          );

          return false;
        },

        checkIfExpressPaymentIsEnabled(context) {
          return context.expressPayments.isExpressPaymentsEnabled;
        },

        /**
         * For Apple Pay to be the initial payment method id, the following must be true:
         * - The device supports Apple Pay.
         * - One of:
         *   - The default payment method id stored in payments-db is Apple Pay.
         *   - There is no default payment method id.
         *   - The default payment method id is for a credit card that was deleted.
         */
        checkShouldSetPaymentMethodIdAsApplePay(context) {
          const {
            expressPayments: { canApplePay },
            defaultPaymentMethodId,
            hasDefaultPaymentMethodCard,
          } = context;

          // Skipping the payment method selection if it's already selected or not ready yet.
          if (shouldSkipPaymentMethodAssignment(context)) return false;

          // Device must support Apple Pay.
          if (!canApplePay) return false;

          // If the default is Apple Pay, use it.
          if (defaultPaymentMethodId === APPLE_PAY) return true;

          // If there is no default, use it.
          if (!defaultPaymentMethodId) return true;

          // If the default is a deleted card, use it.
          if (!hasDefaultPaymentMethodCard) return true;

          return false;
        },

        /**
         * For Google Pay to be the initial payment method id, the following must be true:
         * - The device supports Google Pay.
         * - One of:
         *   - The default payment method id stored in payments-db is Google Pay.
         *   - There is no default payment method id.
         *   - The default payment method id is for a credit card that was deleted.
         */
        checkShouldSetPaymentMethodIdAsGooglePay(context) {
          const {
            expressPayments: { canGooglePay },
            defaultPaymentMethodId,
            hasDefaultPaymentMethodCard,
          } = context;

          // Skipping the payment method selection if it's already selected or not ready yet.
          if (shouldSkipPaymentMethodAssignment(context)) return false;

          // Device must support Google Pay.
          if (!canGooglePay) return false;

          // If the default is Google Pay, use it.
          if (defaultPaymentMethodId === GOOGLE_PAY) return true;

          // If there is no default, use it.
          if (!defaultPaymentMethodId) return true;

          // If the default is a deleted card, use it.
          if (!hasDefaultPaymentMethodCard) return true;

          return false;
        },

        /**
         * For the default payment method id to be the initial payment method id, the following must be true:
         * - It must be for a credit card that is still valid.
         */
        checkShouldSetPaymentMethodIdAsDefaultPaymentMethod(context) {
          const { hasDefaultPaymentMethodCard } = context;

          // Skipping the payment method selection if it's already selected or not ready yet.
          if (shouldSkipPaymentMethodAssignment(context)) return false;

          // If the default is an existing card, use it.
          if (hasDefaultPaymentMethodCard) return true;

          return false;
        },

        /**
         * For the first payment method in the list to be the initial payment method id, the following must be true:
         * - There is no default payment method id or it is for a deleted card.
         * - Neither express checkout option is supported by this device.
         */
        checkShouldSetPaymentMethodIdAsFirstPaymentMethod(context) {
          const {
            expressPayments: { canApplePay, canGooglePay },
            paymentMethods,
            hasDefaultPaymentMethodCard,
          } = context;

          // Skipping the payment method selection if it's already selected or not ready yet.
          if (shouldSkipPaymentMethodAssignment(context)) return false;

          // Must have payment methods available.
          if (paymentMethods.length === 0) return false;

          // Express checkout options have priority.
          if (canApplePay || canGooglePay) return false;

          // Default cards have priority.
          if (hasDefaultPaymentMethodCard) return false;

          return true;
        },
      },
    },
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

/**
 * Skipping the payment method selection if the initial data isn't ready or the payment method id was already set.
 */
const shouldSkipPaymentMethodAssignment = (context: {
  isInitialDataReady: boolean;
  paymentMethodId: string | undefined;
}) => {
  return !context.isInitialDataReady || Boolean(context.paymentMethodId);
};

const getInitialContext = <
  LineItem,
  DeliveryOrderDetail,
>(): OrderingMachineContext<LineItem, DeliveryOrderDetail> => {
  return {
    ...getInitialContextWithoutExpressPayments(),
    expressPayments: {
      isExpressPaymentsEnabled: false,
      isStripeReady: false,
      canApplePay: false,
      canGooglePay: false,
    },
  };
};

const getInitialContextWithoutExpressPayments = <
  LineItem,
  DeliveryOrderDetail,
>(): Omit<
  OrderingMachineContext<LineItem, DeliveryOrderDetail>,
  'expressPayments'
> => {
  return {
    isInitialDataReady: false,
    cart: {} as OrderingCart<LineItem, DeliveryOrderDetail>,
    rewards: [],
    rewardError: undefined,
    appliedRewardId: undefined,
    paymentMethods: [],
    defaultPaymentMethodId: undefined,
    hasDefaultPaymentMethodCard: false,
    availableCredit: 0,
    giftCardBalance: 0,

    customer: {
      customerId: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      isNewCustomer: true,
    },

    wantedTime: undefined,
    isWantedTimeAsap: true,
    hasShownFutureDateWarning: false,
    dropoffLocation: undefined,
    paymentMethodId: undefined,
    shouldUseCredit: true,
    shouldIncludeUtensils: false,
    selectedTip: 0,

    lineItemIdsBeingUpdated: [],
    lineItemIdBeingRenamed: undefined,
    rewardIdBeingUpdated: undefined,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

export type OrderingContext<PartialLineItem, DeliveryOrderDetail> = ContextFrom<
  ReturnType<typeof createOrderingMachine<PartialLineItem, DeliveryOrderDetail>>
>;
