import React, { useCallback } from 'react';
import { ProductLineItemCardV2 } from '@sg/garnish';

import {
  type ChangeLineItemNameParams,
  type ChangeLineItemQuantityParams,
} from '@order/features/ordering';
import type { PartialLineItem, PartialProduct } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
  useLineItemPrice,
} from '@order/shared/hooks';

/**
 * This component is a wrapper for the {ProductLineItemCardV2} from garnish.
 * It loads the messages and sets up the intermediate callbacks for it.
 */
export const BagLineItem = (props: BagLineItemProps) => {
  const {
    lineItem,
    isDisabled,
    onPress,
    trackLineItemNameEditPressed,
    changeLineItemQuantity,
    changeLineItemName,
  } = props;

  // ─── Line Item Info ───────────────────────────────────────────────────────

  const { id: lineItemId = '', quantity = 0, product } = lineItem;

  const productId = product?.id ?? '';
  const productName = useLineItemName(product, lineItem);
  const lineItemPrice = useLineItemPrice(lineItem);
  const lineItemDescription = useLineItemDescription(lineItem);
  const lineItemAssetUrl = product?.asset?.url ?? '';

  const { addedIngredients, removedIngredients } =
    useLineItemIngredients(lineItem);

  // ─── Telemetry And Feature Flag ───────────────────────────────────────────

  const isEditingNameEnabled = useFeatureFlag(
    'CELS-2628-bag-product-name-edit-enabled',
  );

  // ─── Callbacks ────────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(lineItemId, product);
  }, [onPress, product, lineItemId]);

  const handleQuantityChange = useCallback(
    (updatedQuantity: number) => {
      changeLineItemQuantity({
        lineItemId,
        productId,
        quantity: updatedQuantity,
      });
    },
    [lineItemId, productId, changeLineItemQuantity],
  );

  const handleNameChange = useCallback(
    (updatedName: string) => {
      changeLineItemName({
        lineItemId,
        productId,
        customName: updatedName,
      });
    },
    [lineItemId, productId, changeLineItemName],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <ProductLineItemCardV2
      productName={productName}
      price={lineItemPrice}
      cloudinaryImageUrl={lineItemAssetUrl}
      addedIngredients={addedIngredients}
      removedIngredients={removedIngredients}
      description={lineItemDescription}
      quantity={quantity}
      isDisabled={isDisabled}
      isModifiable={product.isModifiable}
      showLabels={!product.slug?.includes('create-your-own')}
      isEditingNameEnabled={isEditingNameEnabled}
      onStartEditingName={trackLineItemNameEditPressed}
      onPress={handleOnPress}
      onQuantityChange={handleQuantityChange}
      onNameChange={handleNameChange}
    />
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLineItemProps = Readonly<{
  lineItem: LineItem;
  isDisabled: boolean;
  onPress: (lineItemId: string, product: PartialProduct) => void;
  trackLineItemNameEditPressed: () => void;
  changeLineItemQuantity: (
    params: ChangeLineItemQuantityParams['params'],
  ) => void;
  changeLineItemName: (params: ChangeLineItemNameParams['params']) => void;
}>;

type LineItem = Omit<PartialLineItem, 'ingredients'>;
