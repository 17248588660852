import React, { type PropsWithChildren, type ReactNode } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { BodyText, useContainerSpacing, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardCheckoutFormGroupContainer = (
  props: GiftCardCheckoutFormGroupContainerProps,
) => {
  const { children, style } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerSpacingXS = useStyle(
    () => ({ paddingHorizontal: containerSpacing }),
    [containerSpacing],
  );
  const containerDynamicStyles = match([
    [containerSpacingXS, styles.containerXS],
    styles.containerSM,
  ]);
  const containerStyles = [styles.container, containerDynamicStyles, style];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyles}>{children}</View>;
};

const GiftCardCheckoutFormGroupFieldContainer = (props: PropsWithChildren) => {
  return <View style={styles.fieldContainer}>{props.children}</View>;
};

const GiftCardCheckoutFormGroupTitle = (props: PropsWithChildren) => {
  return (
    <BodyText bold sizeMatch={['16']}>
      {props.children}
    </BodyText>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutFormGroup = {
  Container: GiftCardCheckoutFormGroupContainer,
  FieldContainer: GiftCardCheckoutFormGroupFieldContainer,
  Title: GiftCardCheckoutFormGroupTitle,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.OATMEAL,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  containerXS: {
    paddingVertical: theme.spacing['6'],
  },
  containerSM: {
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['6'],
  },
  fieldContainer: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutFormGroupContainerProps = {
  children: ReactNode;
  style?: ViewProps['style'];
};
