import React from 'react';
import type { ViewProps } from 'react-native';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Aside } from '@expo/html-elements';
import { theme, useResponsive, webOnlyStyles } from '@sg/garnish';

import { PageWrapper } from '@order/components';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import { ProductBannerDesktop } from '../ProductBanner';
import { ProductDressingAndDetails } from '../ProductDressingAndDetails';
import { ProductModificationsMD } from '../ProductModifications';
import { ProductNoticeBannersTablet } from '../ProductNoticeBanners';
import {
  ProductDetailsCTAs,
  ProductDetailsHeader,
  ProductDetailsIngredients,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductDetailsMD = () => (
  <PageWrapper contentContainerStyle={styles.contentContainer}>
    <View style={styles.wrapper}>
      <ProductNoticeBannersTablet />
      <ProductDetailsMDIdleMode />
      <ProductDetailsMDCustomizationMode />
    </View>
  </PageWrapper>
);

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductDetailsMDIdleMode = () => {
  const { isCustomizationActive, product } = useProductDetailsScreenContext();

  if (isCustomizationActive) return null;

  return (
    <>
      <ProductDetailsSidebarMD>
        <ProductDressingAndDetails />
      </ProductDetailsSidebarMD>

      <ProductBannerDesktop
        imgSrc={product?.asset.url}
        imgAriaLabel={product?.name}
      />
    </>
  );
};

const ProductDetailsMDCustomizationMode = () => {
  const { isCustomizationActive, product } = useProductDetailsScreenContext();

  if (!isCustomizationActive) return null;

  return (
    <>
      <ProductDetailsSidebarMD />
      {/* @ts-expect-error TS(2322): Type '{ readonly __typename?: "Product" | undefine... Remove this comment to see the full error message */}
      <ProductModificationsMD product={product} />
    </>
  );
};

const ProductDetailsSidebarMD = (props: ViewProps) => {
  const { children, ...restProps } = props;

  const { minWidth } = useResponsive();
  const {
    ingredientsDesktopScrollViewRef,
    setIngredientsScrollMdWrappersOffset,
  } = useProductDetailsScreenContext();

  // ─── STYLES ─────────────────────────────────────────────────────────

  const scrollContentContainerStyle = [
    styles.scrollContentContainer,
    webOnlyStyles({ flexBasis: 0 }),
  ];

  const sidebarStyle = [
    styles.sidebar,
    minWidth.isMD && styles.sidebarDesktop,
    minWidth.isLG && styles.sidebarLargeDisplay,
  ];

  return (
    <Aside style={sidebarStyle} {...restProps}>
      <ScrollView
        ref={ingredientsDesktopScrollViewRef}
        contentContainerStyle={scrollContentContainerStyle}
        scrollEventThrottle={16}
        onScroll={setIngredientsScrollMdWrappersOffset}
      >
        <ProductDetailsHeader />
        <ProductDetailsIngredients />

        {children}
      </ScrollView>

      <ProductDetailsCTAs />
    </Aside>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    minHeight: '100%',
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    minHeight: '100%',
    backgroundColor: theme.colors.QUINOA,
  },
  sidebar: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  sidebarDesktop: {
    flexBasis: 475,
    maxWidth: 475,
    flexShrink: 0,
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
  },
  sidebarLargeDisplay: {
    flexBasis: 525,
    maxWidth: 525,
  },
  scrollContentContainer: {
    flexGrow: 1,
  },
});
