import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { type BagScrollViewProps } from './BagScrollView.props';

/**
 * ScrollView for the bag on SM+ viewport.
 */
export const BagScrollView = (props: BagScrollViewProps) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      {props.children}
    </ScrollView>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing['4'],
  },
});
