/* istanbul ignore file */

import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useDeliveryOrderInFlight } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { LocationWarningModal } from '../LocationWarningModal';
import { CompleteAddressFormModal, LocationSearchContent } from './components';
import { getAddressForm } from './helpers';
import { useLocationSearchMachine, useLocationSearchTelemetry } from './hooks';
import type { LocationSearchMachineContext } from './machine';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearch = memo((props: LocationSearchProps) => {
  const {
    withSafeAreaInsets,
    interactedLocationRestaurantSlug,
    interactedLocationDeliveryAddressId,
    navigateToLocation,
    navigateToDeliveryLocation,
  } = props;

  useDeliveryOrderInFlight();

  const minimalCharactersForSearch = 3;

  const { state, helpers, flags } = useLocationSearchMachine({
    minimalCharactersForSearch,
    navigateToLocation,
    navigateToDeliveryLocation,
  });
  const { trackLocationResultsExpandCollapse } = useLocationSearchTelemetry();

  const { context } = state;
  const {
    searchString,
    deliverySearchString,
    locations,
    locationSearchType,
    deliveryLocation,
    deliveryAddressPredictions,
    activeLocation,
    currentPosition,
    focusedPinId,
    focusedLocationId,
    recentLocations,
    nearbyLocations,
  } = context;

  const {
    setLocationSearchType,
    searchBasedOnCurrentPosition,
    searchLocationsByArea,
    searchLocationsByString,
    searchDeliveryAddresses,
    setLocationSearchArea,
    setDeliveryPredictionPlaceId,
    setFocusedLocation,
    setFocusedLocationPin,
    confirmLocationWarning,
    cancelLocationWarning,
    navigateToSelectedLocation,
    navigateToSelectedDeliveryLocation,
    navigateToRecentOrNearbyLocation,
    cancelDeliveryAddressForm,
    submitDeliveryAddressForm,
    showRecentAndNearbyLocations,
  } = helpers;

  const {
    isLoadingLocations,
    isLoadingRecentAndNearbyLocations,
    isLoadingPredictions,
    isAddingAddress,
    isUsingSearchField,
    shouldShowLocationWarning,
    shouldRenderRecentAndNearbyLocations,
    shouldRenderDeliveryAddressForm,
  } = flags;

  const shouldHideOutpostResults = useFeatureFlag(
    'em-888-hide-outpost-from-map-pickup-section',
  );

  const filteredLocations = useMemo<
    LocationSearchMachineContext['locations']
  >(() => {
    if (!locations) return;

    if (locationSearchType === 'pickup') {
      return { ...locations, outpost: [] };
    }

    return locations;
  }, [locationSearchType, locations]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <LocationSearchContent
        locationSearchType={locationSearchType}
        searchString={searchString}
        deliverySearchString={deliverySearchString}
        currentPosition={currentPosition}
        locations={shouldHideOutpostResults ? filteredLocations : locations}
        recentLocations={recentLocations}
        nearbyLocations={nearbyLocations}
        deliveryLocation={deliveryLocation}
        shouldRenderRecentAndNearbyLocations={
          shouldRenderRecentAndNearbyLocations
        }
        minimalCharactersForSearch={minimalCharactersForSearch}
        predictionDeliveryAddresses={deliveryAddressPredictions}
        focusedPinId={focusedPinId}
        focusedLocationId={focusedLocationId}
        isLoadingPredictions={isLoadingPredictions}
        isLoadingLocations={isLoadingLocations}
        isLoadingRecentAndNearbyLocations={isLoadingRecentAndNearbyLocations}
        isUsingSearchField={isUsingSearchField}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
        withSafeAreaInsets={withSafeAreaInsets}
        onLocationSearchTypeChange={setLocationSearchType}
        onLocationSearchStringSubmit={searchLocationsByString}
        onLocationsSearchAreaChange={setLocationSearchArea}
        onDeliverySearchStringChange={searchDeliveryAddresses}
        onGetCurrentPosition={searchBasedOnCurrentPosition}
        onPredictionPress={setDeliveryPredictionPlaceId}
        onSearchAreaPress={searchLocationsByArea}
        onMapPinPress={setFocusedLocation}
        onLocationPinPress={navigateToSelectedLocation}
        onDeliveryPinPress={navigateToSelectedDeliveryLocation}
        onLocationCardFocus={setFocusedLocationPin}
        onLocationCardPress={navigateToSelectedLocation}
        onDeliveryLocationCardPress={navigateToSelectedDeliveryLocation}
        onLocationResultsExpandCollapse={trackLocationResultsExpandCollapse}
        onRecentOrNearbyLocationCardPress={navigateToRecentOrNearbyLocation}
        showRecentAndNearbyLocations={showRecentAndNearbyLocations}
      />

      {deliveryLocation ? (
        <CompleteAddressFormModal
          address={getAddressForm(deliveryLocation)}
          isVisible={shouldRenderDeliveryAddressForm}
          isAddingAddress={isAddingAddress}
          onRequestClose={cancelDeliveryAddressForm}
          onSubmit={submitDeliveryAddressForm}
        />
      ) : null}

      {shouldShowLocationWarning && activeLocation ? (
        <View style={StyleSheet.absoluteFill}>
          <LocationWarningModal
            locations={[activeLocation]}
            onClose={cancelLocationWarning}
            onConfirm={confirmLocationWarning}
            restaurantSlug={activeLocation.restaurantSlug}
          />
        </View>
      ) : null}
    </>
  );
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchProps = Readonly<{
  withSafeAreaInsets?: boolean;

  /**
   * While interacting with a location card (reordering, navigation, etc.),
   * we occasionally need to display the loading, disabled states.
   * To display the corresponding additional state, we match the target location
   * card restaurant slug to the interacted location restaurant slug and/or delivery address ID.
   * */
  interactedLocationRestaurantSlug: string | undefined;
  interactedLocationDeliveryAddressId: string | undefined;

  /*
    Optional actions that initiates the navigation process for the selected location.
    When those properties are not provided, the standard navigation is used.

    Helpful when a custom navigation method is utilized or when other actions
    need to be performed before navigation.
   */

  navigateToLocation?: (context: LocationSearchMachineContext) => void;
  navigateToDeliveryLocation?: (context: LocationSearchMachineContext) => void;
}>;
