import React from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  HStack,
  Modal,
  ModalFloatingCloseBtn,
  TextLink,
  theme,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { TransparentModal } from '../../../../components/TransparentModal';
import type { PartialOrderConflict } from '../../../../graphql/partials';
import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import { useReorderRightIcon } from '../ReorderConfirmLocation/ReorderConfirmLocation.components';
import { ConflictCard } from './ReorderConflictReview.card';

const cannotReorderTypes = new Set([
  'RestaurantMaxDeliveryQuantityExceeded',
  'RestaurantMaxQuantityExceeded',
  'CanNotReorder',
]);

export const ReorderConflictReviewContent = ({
  navigation,
  conflicts,
  reorderType,
  currentStep,
  restaurantName,
  deliveryAddressName,
  isLoading,
  shouldShowClearWarning,
  finishReorder,
  changeLocation,
  cancelReorder,
}: ReorderConflictContentProps) => {
  const { isCartEmpty } = useCart();
  const { currentBreakpoint } = useResponsive();
  const DynamicConflictContent = currentBreakpoint.isXS
    ? ConflictContentMobile
    : ConflictContentDesktop;

  if (currentStep !== 'conflictReview' && Platform.OS === 'web') return null;
  const cannotReorder = cannotReorderTypes.has(reorderType ?? '');

  return (
    <DynamicConflictContent
      navigation={navigation}
      cannotReorder={cannotReorder}
      conflictCount={conflicts.length}
      restaurantName={restaurantName}
      deliveryAddressName={deliveryAddressName}
      showClearWarning={shouldShowClearWarning ? !isCartEmpty : undefined}
      isLoading={isLoading}
      cancelReorder={cancelReorder}
      changeLocation={changeLocation}
      finishReorder={finishReorder}
    >
      {conflicts.map((conflict) => (
        <ConflictCard key={conflict.product.id} conflict={conflict} />
      ))}
    </DynamicConflictContent>
  );
};

const ConflictContentDesktop = ({
  cannotReorder,
  restaurantName,
  conflictCount,
  deliveryAddressName,
  showClearWarning,
  isLoading,
  children,
  cancelReorder,
  finishReorder,
  changeLocation,
}: Omit<ConflictContentProps, 'navigation'> &
  Readonly<{
    conflictCount: number;
  }>) => {
  const { t } = useLocalizationContext();

  return (
    <TransparentModal
      fitHeight
      size={conflictCount > 1 ? 'large' : 'small'}
      mobileEdges={['bottom']}
      onClose={cancelReorder}
    >
      <View style={styles.bannerWrapper}>
        <ModalFloatingCloseBtn
          onPress={cancelReorder}
          style={styles.closeButton}
        />
        <ScrollView
          style={[
            styles.bodyDesktop,
            conflictCount > 1 && styles.bodyDesktopMultiple,
          ]}
        >
          <Modal.Headline>{t('reorder.conflicts.headline')}</Modal.Headline>
          <Modal.Row>
            <BodyText size={3} style={styles.bodyText}>
              {deliveryAddressName
                ? t('reorder.conflicts.reordering-to')
                : t('reorder.conflicts.reordering-from')}
              <TextLink style={styles.textUnderline} onPress={changeLocation}>
                {deliveryAddressName ? deliveryAddressName : restaurantName}
              </TextLink>
            </BodyText>
          </Modal.Row>
          <Modal.BodyText>{t('reorder.conflicts.notice')}</Modal.BodyText>
          <Modal.Row>
            <HStack
              style={styles.conflictItemsDesktop}
              itemsPerRow={conflictCount > 1 ? 2 : 1}
            >
              {children}
            </HStack>
          </Modal.Row>
        </ScrollView>
      </View>
      <Modal.Footer style={styles.footer}>
        {cannotReorder ? (
          <Button
            size="large"
            onPress={changeLocation}
            testID="reorder.change-locations"
            accessibilityLabel={t('reorder.change-location')}
            accessibilityHint={t('reorder.change-location')}
            accessibilityRole="button"
            style={styles.singleButtonDesktop}
          >
            {t('reorder.change-location')}
          </Button>
        ) : (
          <HStack itemsPerRow={2} gap={theme.spacing['4']}>
            <Button
              size="large"
              palette="secondary"
              onPress={changeLocation}
              testID="reorder.change-locations"
              accessibilityLabel={t('reorder.change-location')}
              accessibilityHint={t('reorder.change-location')}
              accessibilityRole="button"
            >
              {t('reorder.change-location')}
            </Button>
            <Button
              size="large"
              onPress={finishReorder}
              isLoading={isLoading}
              testID="reorder.continue-to-bag"
              accessibilityLabel={t('reorder.continue')}
              accessibilityHint={t('reorder.continue')}
              accessibilityRole="button"
            >
              {t('reorder.continue')}
            </Button>
          </HStack>
        )}

        {showClearWarning ? (
          <View style={styles.clearWarning}>
            <BodyText size={5}>{t('reorder.clear-warning')}</BodyText>
          </View>
        ) : null}
      </Modal.Footer>
    </TransparentModal>
  );
};

const ConflictContentMobile = ({
  navigation,
  cannotReorder,
  restaurantName,
  deliveryAddressName,
  showClearWarning,
  isLoading,
  children,
  cancelReorder,
  finishReorder,
  changeLocation,
}: ConflictContentProps) => {
  const { t } = useLocalizationContext();
  const rightIcon = useReorderRightIcon();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components -- @REVIEW
      headerTitle: () => (
        <MobileHeader
          restaurantName={restaurantName}
          deliveryAddressName={deliveryAddressName}
          changeLocation={changeLocation}
        />
      ),
      headerRight: () => rightIcon,
      headerShown: true,
    });
  }, [
    navigation,
    deliveryAddressName,
    restaurantName,
    rightIcon,
    changeLocation,
  ]);

  return (
    <TransparentModal
      testID="reorder-conflict-review"
      size="full"
      onClose={cancelReorder}
      mobileEdges={['bottom']}
    >
      <View style={styles.bannerWrapper}>
        <ScrollView style={styles.bodyMobile}>
          <Modal.Headline>{t('reorder.conflicts.headline')}</Modal.Headline>
          <Modal.BodyText>{t('reorder.conflicts.notice')}</Modal.BodyText>
          <Modal.Row>
            <VStack style={styles.conflictItemsMobile}>{children}</VStack>
          </Modal.Row>
        </ScrollView>
      </View>

      <Modal.Footer style={[styles.footer, styles.footerMobile]}>
        {cannotReorder ? (
          <Button
            size="large"
            onPress={changeLocation}
            accessibilityLabel={t('reorder.change-location')}
            accessibilityHint={t('reorder.change-location')}
            accessibilityRole="button"
          >
            {t('reorder.change-location')}
          </Button>
        ) : (
          <HStack itemsPerRow={2} gap={theme.spacing['4']}>
            <Button
              size="large"
              palette="secondary"
              onPress={changeLocation}
              accessibilityLabel={t('reorder.change-location')}
              accessibilityHint={t('reorder.change-location')}
              accessibilityRole="button"
            >
              {t('reorder.change-location')}
            </Button>
            <Button
              size="large"
              onPress={finishReorder}
              isLoading={isLoading}
              accessibilityLabel={t('reorder.continue-to-bag')}
              accessibilityHint={t('reorder.continue-to-bag')}
              accessibilityRole="button"
            >
              {t('reorder.continue-to-bag')}
            </Button>
          </HStack>
        )}

        {showClearWarning ? (
          <View style={styles.clearWarning}>
            <BodyText size={5}>{t('reorder.clear-warning')}</BodyText>
          </View>
        ) : null}
      </Modal.Footer>
    </TransparentModal>
  );
};

const MobileHeader = ({
  restaurantName,
  deliveryAddressName,
  changeLocation,
}: Pick<
  ConflictContentProps,
  'restaurantName' | 'deliveryAddressName' | 'changeLocation'
>) => {
  const { t } = useLocalizationContext();

  return (
    <View style={styles.modalHeader}>
      <BodyText size={4}>
        {deliveryAddressName
          ? t('reorder.conflicts.reordering-to')
          : t('reorder.conflicts.reordering-from')}
      </BodyText>
      <TextLink style={styles.textUnderline} onPress={changeLocation}>
        {deliveryAddressName ? deliveryAddressName : restaurantName}
      </TextLink>
    </View>
  );
};

type ConflictContentProps = Readonly<{
  navigation: ModalStackChildProps<'ReorderConflictReview'>['navigation'];
  restaurantName: string;
  deliveryAddressName?: string;
  cannotReorder: boolean;
  showClearWarning?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  finishReorder: () => void;
  changeLocation: () => void;
  cancelReorder: () => void;
}>;

type ReorderConflictContentProps = Readonly<{
  navigation: ModalStackChildProps<'ReorderConflictReview'>['navigation'];
  restaurantName: string;
  deliveryAddressName?: string;
  reorderType?: string;
  conflicts: readonly PartialOrderConflict[];
  currentStep: string;
  isLoading?: boolean;
  shouldShowClearWarning?: boolean;
  finishReorder: () => void;
  changeLocation: () => void;
  cancelReorder: () => void;
}>;

const styles = StyleSheet.create({
  footer: {
    paddingTop: theme.spacing['6'],
    borderTopColor: theme.colors.GREEN_1,
  },
  footerMobile: {
    paddingTop: theme.spacing['4'],
  },
  clearWarning: {
    width: '100%',
    paddingTop: theme.spacing['4'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  bodyText: {
    paddingBottom: theme.spacing['4'],
  },
  textUnderline: {
    color: theme.colors.KALE,
    textDecorationLine: 'underline',
    textDecorationColor: theme.colors.KALE,
  },
  bannerWrapper: {
    position: 'relative',
    zIndex: theme.zIndex.fixed,
    flex: 1,
  },
  bodyDesktop: {
    paddingTop: theme.spacing['6'],
    maxHeight: '100%',
  },
  bodyDesktopMultiple: {
    height: 504,
  },
  bodyMobile: {
    flex: 1,
    minHeight: 0,
  },
  singleButtonDesktop: { maxWidth: 400, alignSelf: 'center', minWidth: '50%' },
  conflictItemsDesktop: { paddingBottom: theme.spacing['6'] },
  conflictItemsMobile: { paddingBottom: theme.spacing['4'] },
  closeButton: { backgroundColor: theme.colors.KHAKI_3 },
});
