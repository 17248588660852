import { type RouteProp, useRoute } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

/**
 * Only render view menu if the route under the bag is not the menu.
 * If on XS, render the view menu button regardless.
 */
export const useViewMenuRendering = () => {
  const { match } = useResponsive();
  const { params } = useRoute<BagScreenRouteParams>();

  const shouldShowFloatingViewMenu = useFeatureFlag(
    'CELS-2900-order-bag-floating-view-menu-enabled',
  );

  const referrer = params?.referrer ?? '';
  const isValidReferrer = !EXCLUDED_REFERRERS.has(referrer);
  const shouldRenderViewMenu = match([true, isValidReferrer]);

  // Note that the view menu is not supposed to show if the menu screen can be seen underneath on larger viewports.

  return { shouldRenderViewMenu, shouldShowFloatingViewMenu };
};

// ─── Constants ──────────────────────────────────────────────────────────────

const EXCLUDED_REFERRERS = new Set<BagScreenProps['params']['referrer']>([
  'Menu',
  'DeliveryMenu',
]);

// ─── Types ──────────────────────────────────────────────────────────────────

type BagScreenRouteParams = RouteProp<BagScreenProps>;
type BagScreenProps = Readonly<{
  params: { referrer: 'Menu' | 'DeliveryMenu' };
}>;
