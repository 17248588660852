import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Pressable, StyleSheet } from 'react-native';
import { Icon, theme } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { locationResultsListsV2ExpandButtonMessages as messages } from './LocationResultsListsV2ExpandButton.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2ExpandButton = (
  props: LocationResultsListsV2ExpandButtonProps,
) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();
  const { track } = useTelemetry();

  const handleOnPress = useCallback(() => {
    onPress?.();

    track('location.listview.tapped');
  }, [onPress, track]);

  return (
    <Pressable
      accessibilityRole="button"
      onPress={handleOnPress}
      aria-label={formatMessage(messages.expandButtonA11yLabel)}
      style={styles.pressable}
    >
      <Icon
        name="IconList"
        width={ICON_SIZE}
        height={ICON_SIZE}
        color={theme.colors.DARK_KALE}
      />
    </Pressable>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_SIZE = 24;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    alignSelf: 'flex-end',
    backgroundColor: theme.colors.OATMEAL,
    padding: theme.spacing[2],
    borderRadius: ICON_SIZE,
    ...theme.elevations['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

export type LocationResultsListsV2ExpandButtonProps = {
  onPress: () => void;
};
