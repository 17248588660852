import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { LoadingPlaceholder } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInLoadingPlaceholder = () => {
  return (
    <View style={styles.container}>
      <LoadingPlaceholder rows={2} palette="cream" rowHeight={80} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    zIndex: theme.zIndex.popover,
    backgroundColor: theme.colors.OATMEAL,
  },
});
