import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, DisplayText, Form, theme, TYPE_CONFIG } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The gift card form for the bag.
 */
export const BagGiftCardForm = (props: BagGiftCardFormProps) => {
  const { isLoading, onSubmit } = props;

  const { formatMessage } = useIntl();

  // ─── Form & Validation ────────────────────────────────────────────────────

  const validationSchema = useMemo(() => {
    const giftCardCodeValidation = yup
      .string()
      .required(formatMessage(messages.requiredGiftCardNumber));

    const registrationCodeValidation = yup
      .string()
      .required(formatMessage(messages.requiredRegistrationCode));

    return yup
      .object({
        giftCardCode: giftCardCodeValidation,
        registrationCode: registrationCodeValidation,
      })
      .required();
  }, [formatMessage]);

  const form = useForm<BagGiftCardFormData>({
    defaultValues: { giftCardCode: '', registrationCode: '' },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const { control, formState } = form;
  const { errors, dirtyFields } = formState;
  const shouldDisableApplyCta = !(
    dirtyFields.giftCardCode && dirtyFields.registrationCode
  );

  // ──────────────────────────────────────────────────────────────────────────

  const submitForm = useCallback(() => {
    const { giftCardCode, registrationCode } = form.getValues();

    onSubmit(giftCardCode, registrationCode);
  }, [form, onSubmit]);

  return (
    <View style={styles.container}>
      <DisplayText style={styles.title} sizeMatch={['32']}>
        <FormattedMessage
          defaultMessage="Redeem gift card"
          description="Bag | Gift card form | Title"
        />
      </DisplayText>

      <View style={styles.fieldContainer}>
        <Form.TextFieldWithBackground
          name="giftCardCode"
          hideSubmitButton
          style={styles.field}
          control={control}
          rules={{ required: true }}
          placeholder={formatMessage(messages.giftCardNumber)}
          accessibilityLabel={formatMessage(messages.giftCardNumber)}
          notice={errors.giftCardCode?.message}
          disabled={isLoading}
          onSubmit={submitForm}
        />
      </View>

      <View style={styles.fieldContainer}>
        <Form.TextFieldWithBackground
          name="registrationCode"
          hideSubmitButton
          style={styles.field}
          control={control}
          rules={{ required: true }}
          placeholder={formatMessage(messages.registrationCode)}
          accessibilityLabel={formatMessage(messages.registrationCode)}
          notice={errors.registrationCode?.message}
          disabled={isLoading}
          onSubmit={submitForm}
        />
      </View>

      <View style={styles.ctaContainer}>
        <Button
          size="large"
          onPress={submitForm}
          disabled={shouldDisableApplyCta}
          isLoading={isLoading}
          accessibilityLabel={formatMessage(messages.applyGiftCardCtaA11y)}
        >
          <FormattedMessage {...messages.submitButtonLabel} />
        </Button>
      </View>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  giftCardNumber: {
    defaultMessage: 'Gift card number',
    description: 'Bag | Gift card | Gift card number',
  },
  registrationCode: {
    defaultMessage: 'Registration code',
    description: 'Bag | Gift card | Registration code',
  },
  applyGiftCardCtaA11y: {
    defaultMessage: 'Apply gift card',
    description: 'Bag | Gift card | CTA a11y',
  },
  submitButtonLabel: {
    defaultMessage: 'Redeem',
    description: 'Redeem Gift Card Form | Form submit button label',
  },
  requiredGiftCardNumber: {
    defaultMessage: 'Gift card number is required',
    description: 'Bag | Gift card | Required gift card number',
  },
  requiredRegistrationCode: {
    defaultMessage: 'Registration code is required',
    description: 'Bag | Gift card | Required gift card reg code',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  title: {
    marginBottom: theme.spacing['2'],
  },
  fieldContainer: {
    flexGrow: 1,
  },
  field: {
    ...TYPE_CONFIG.BODY['18'],
  },
  ctaContainer: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagGiftCardFormProps = Readonly<{
  isLoading: boolean;
  onSubmit: (code: string, regCode: string) => void;
}>;

type BagGiftCardFormData = Readonly<{
  giftCardCode: string;
  registrationCode: string;
}>;
