import { defineMessages } from 'react-intl';

export const categoryUpsellsMessages = defineMessages({
  title: {
    defaultMessage: 'Round out your meal',
    description: 'Category Upsells | Title',
  },
  dismissUpsells: {
    defaultMessage: 'Dismiss upsells',
    description: 'Category Upsells | Dismiss upsells',
  },
});
