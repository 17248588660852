import { useMenuContentRestaurantQuery } from '../../graphql/MenuContentRestaurant.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useMenuContentData = (params: UseMenuContentDataParams) => {
  const { restaurantSlug } = params;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [restaurantQueryResponse] = useMenuContentRestaurantQuery({
    variables: { id: restaurantSlug! },
    requestPolicy: 'cache-and-network',
    pause: !restaurantSlug,
  });

  const { fetching, error, data } = restaurantQueryResponse;

  const restaurantData = data?.restaurant;
  const categories = restaurantData?.menu.categories ?? [];

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasNoProducts = fetching
    ? false
    : categories.every((category) => category.products.length === 0);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isFetching: fetching,
    hasError: error !== undefined,
    hasNoProducts,
    restaurantData,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseMenuContentDataParams = {
  restaurantSlug: string | undefined;
};
