import React from 'react';
import type { ViewProps } from 'react-native';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Aside } from '@expo/html-elements';
import { Container, theme, useResponsive } from '@sg/garnish';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';

import type { AccountTabScreenId } from '../../../navigation/AppNavigation.props';
import { LoadingAnimation } from '../../LoadingAnimation';
import { PageWrapper } from '../../PageWrapper';
import { AccountMenu } from '../AccountMenu';
import { AccountSignedOutMessage } from '../AccountSignedOutMessage';

// ────────────────────────────────────────────────────────────────────────────────

export const AccountScreenContainer = (props: AccountScreenContainerProps) => {
  const { screen: screenId, testID, children } = props;

  const { currentBreakpoint, minWidth, match } = useResponsive();
  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();

  // ─── STYLES ──────────────────────────────────────────────────────

  const menuWrapperStyles = [
    styles.menuWrapper,
    match([{}, styles.menuWrapperSM, styles.menuWrapperMD]),
  ];
  const tabContentStyles = [
    styles.tabContent,
    minWidth.isLG ? styles.tabContentLG : {},
  ];

  // ─────────────────────────────────────────────────────────────────

  if (isLoggingIn) return <LoadingAnimation />;

  if (!isLoggedIn) {
    return <AccountSignedOutMessage redirectTo={screenId} />;
  }

  if (currentBreakpoint.isXS) {
    return (
      <AccountScreenContainerPageWrapper testID={testID}>
        <Container wrapperStyle={styles.containerXS} style={styles.containerXS}>
          {children}
        </Container>
      </AccountScreenContainerPageWrapper>
    );
  }

  return (
    <AccountScreenContainerPageWrapper testID={testID}>
      <Container
        customSpacing={0}
        wrapperStyle={styles.containerSM}
        style={styles.containerSM}
      >
        <View style={styles.contentWrapper}>
          <Aside style={menuWrapperStyles}>
            <AccountMenu screen={screenId} />
          </Aside>

          <ScrollView
            scrollEnabled={false}
            contentContainerStyle={styles.tabContentContainer}
            style={tabContentStyles}
            focusable // see: https://dequeuniversity.com/rules/axe/4.5/scrollable-region-focusable
          >
            {children}

            {/* Because browsers ignore padding in scroll containers,
                bottom spacing is added using an additional component. */}
            <View style={styles.tabContentBottomSpacer} />
          </ScrollView>
        </View>
      </Container>
    </AccountScreenContainerPageWrapper>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const AccountScreenContainerPageWrapper = (
  props: Pick<ViewProps, 'children' | 'testID'>,
) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <PageWrapper
      testID={props.testID}
      showsVerticalScrollIndicator={false}
      style={styles.pageWrapper}
      withHeaderTitle
      withoutFooter={currentBreakpoint.isXS}
    >
      {props.children}
    </PageWrapper>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  contentWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  containerXS: {
    minHeight: '100%',
    paddingBottom: theme.spacing['6'],
  },
  containerSM: {
    minHeight: 700,
    flex: 1,
  },
  tabContentContainer: {
    maxHeight: '100%',
    paddingTop: theme.spacing['12'],
    paddingHorizontal: theme.spacing['10'],
  },
  tabContentBottomSpacer: {
    height: theme.spacing['10'],
  },
  tabContent: {
    flex: 1,
    minHeight: '100%',
  },
  tabContentLG: {
    paddingHorizontal: theme.spacing['20'],
  },
  menuWrapper: {
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
  },
  menuWrapperSM: {
    width: 280,
  },
  menuWrapperMD: {
    width: 320,
  },
});

// ────────────────────────────────────────────────────────────────────────────────

export type AccountScreenContainerProps = Readonly<{
  screen: AccountTabScreenId;
}> &
  ViewProps;
