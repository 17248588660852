import React from 'react';
import { Platform, StyleSheet, View, type ViewProps } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { IconLink } from '../Icon';

/**
 * This is a header that, when used together with an animated scrollview, changes its opacity based on the scroll amount.
 */
export const AnimatedHeader = (props: AnimatedHeaderProps) => {
  const {
    scrollOffset,
    palette = 'oatmeal',
    children,
    accessibilityLabelBack,
    accessibilityLabelClose,
    onBackBtnPress,
    onCloseBtnPress,
  } = props;

  // ─── Animated Styles ──────────────────────────────────────────────────────

  const { top } = useSafeAreaInsets();
  const topInset = Platform.OS === 'android' ? top : 0;

  const heightStyle = useStyle(
    () => ({
      paddingTop: theme.spacing['4'] + topInset,
      maxHeight: HEADER_SIZE + topInset,
    }),
    [topInset],
  );

  const headerAnimatedStyle = useAnimatedStyle(() => {
    const headerHeight = HEADER_SIZE + topInset;
    const amountNeededToShowHeader = headerHeight * HEADER_SCROLL_REQUIREMENT;

    return {
      alignSelf: 'center',
      opacity: interpolate(
        scrollOffset?.value ?? 0,
        [headerHeight, amountNeededToShowHeader],
        [0, 1],
        Extrapolation.CLAMP,
      ),
    };
  });

  const borderAnimatedStyle = useAnimatedStyle(() => ({
    borderBottomColor:
      (scrollOffset?.value ?? 0) > 0
        ? theme.colors.DARK_KALE
        : theme.colors.OPACITY.TRANSPARENT,
  }));

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Animated.View
      style={[
        styles.header,
        paletteColor[palette],
        heightStyle,
        borderAnimatedStyle,
      ]}
    >
      {onBackBtnPress ? (
        <IconLink
          name="IconArrowLeft"
          palette="darkKale"
          width={ICON_SIZE}
          height={ICON_SIZE}
          accessibilityLabel={accessibilityLabelBack}
          onPress={onBackBtnPress}
        />
      ) : (
        <View style={styles.iconOffset} />
      )}

      <Animated.View style={headerAnimatedStyle}>{children}</Animated.View>

      {onCloseBtnPress ? (
        <IconLink
          name="IconClose"
          palette="darkKale"
          width={ICON_SIZE}
          height={ICON_SIZE}
          accessibilityLabel={accessibilityLabelClose}
          onPress={onCloseBtnPress}
        />
      ) : (
        <View style={styles.iconOffset} />
      )}
    </Animated.View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const ICON_SIZE = 40;
const HEADER_SIZE = 72;
const HEADER_SCROLL_REQUIREMENT = 1.5;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
    borderBottomColor: theme.colors.DARK_KALE,
    borderBottomWidth: 1,
    padding: theme.spacing['4'],
  },
  oatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
  cream: {
    backgroundColor: theme.colors.CREAM,
  },
  iconOffset: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
});

const paletteColor = {
  oatmeal: styles.oatmeal,
  cream: styles.cream,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimatedHeaderProps = {
  children: ViewProps['children'];
  palette?: 'oatmeal' | 'cream';
  scrollOffset?: SharedValue<number>;
  accessibilityLabelBack?: string;
  accessibilityLabelClose?: string;
  onBackBtnPress?: () => void;
  onCloseBtnPress?: () => void;
};
