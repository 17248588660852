import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import {
  Button,
  Container,
  HStack,
  LoadingDots,
  TextLink,
  theme,
} from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import { useProductDetailsScreenContext } from '../../../../ProductDetailsScreen.provider';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductDetailsCTAs = () => {
  const { isCustomizationActive } = useProductDetailsScreenContext();

  const { bottom: bottomInset } = useSafeAreaInsets();

  const bottomInsetStyle = useStyle(
    () => ({
      paddingBottom: Math.max(theme.spacing['4'], bottomInset),
    }),
    [bottomInset],
  );

  return (
    <Container style={[styles.actionsWrapper, bottomInsetStyle]}>
      {isCustomizationActive ? (
        <ProductDetailsModificationCTAs />
      ) : (
        <ProductDetailsStandardCTAs />
      )}
    </Container>
  );
};

// ─── TEMPLATES ──────────────────────────────────────────────────────────────────

const ProductDetailsStandardCTAs = () => {
  const {
    lineItemId,
    product,
    stale,
    startCustomization,
    isModifiableProduct,
    modifyCart,
  } = useProductDetailsScreenContext();
  const { t } = useLocalizationContext();
  const { isFetchingCart, pendingCartMutations } = useCart();
  const isRemovingFromCart = pendingCartMutations.has('remove-line-item');

  // ──────────────── Telemetry ─────────────────

  const { track } = useTelemetry();

  const onCustomize = React.useCallback(() => {
    track('modify.view', {
      selectedProduct: telemetryProductFromInput(product ?? {}),
      entryPoint: lineItemId ? 'bag' : 'pdp',
    });

    startCustomization();
  }, [product, lineItemId, track, startCustomization]);

  const itemsPerRow = isModifiableProduct ? 2 : 1;

  return (
    <View style={styles.wrapper}>
      <HStack itemsPerRow={itemsPerRow}>
        {isModifiableProduct ? (
          <Button
            palette="secondary"
            onPress={onCustomize}
            testID="customize-button"
            disabled={isFetchingCart || stale}
            size="large"
          >
            {t('pdp.details.customize')}
          </Button>
        ) : null}

        <Button
          isLoading={isFetchingCart || stale}
          palette="primary"
          onPress={modifyCart}
          disabled={isRemovingFromCart}
          testID={lineItemId ? 'edit-bag-button' : 'add-to-bag-button'}
          size="large"
        >
          {lineItemId ? t('pdp.details.update') : t('pdp.details.add-to-bag')}
        </Button>
      </HStack>

      <ProductDetailsRemoveFromBagCTA />
    </View>
  );
};

const ProductDetailsModificationCTAs = () => {
  const { cancelCustomization, finishCustomization, stale } =
    useProductDetailsScreenContext();
  const { t } = useLocalizationContext();
  const { isFetchingCart } = useCart();

  // ────────────────────────────────────────────────────────────────────

  return (
    <HStack itemsPerRow={2}>
      <Button
        testID="pdp-details-cancel"
        palette="secondary"
        onPress={cancelCustomization}
        disabled={isFetchingCart || stale}
        size="large"
      >
        {t('pdp.details.cancel')}
      </Button>

      <Button
        testID="pdp-details-done"
        isLoading={isFetchingCart || stale}
        palette="primary"
        onPress={finishCustomization}
        size="large"
      >
        {t('pdp.details.done')}
      </Button>
    </HStack>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductDetailsRemoveFromBagCTA = () => {
  const { t } = useLocalizationContext();
  const { lineItemId = '', removeLineItemFromCart } =
    useProductDetailsScreenContext();
  const { isFetchingCart, pendingCartMutations } = useCart();

  const isRemovingFromCart = pendingCartMutations.has('remove-line-item');

  // ────────────────────────────────────────────────────────────────────

  const onPress = useCallback(async () => {
    await removeLineItemFromCart(lineItemId);
  }, [lineItemId, removeLineItemFromCart]);

  // ────────────────────────────────────────────────────────────────────

  if (!lineItemId) return null;

  return (
    <View style={styles.removeFromBagWrapper}>
      {isRemovingFromCart ? (
        <LoadingDots color={theme.colors.TEXT_COLOR} />
      ) : (
        <TextLink
          onPress={onPress}
          disabled={isFetchingCart}
          testID="remove-from-bag-button"
          palette="primaryWithCautionHover"
        >
          {t('pdp.details.remove-from-bag')}
        </TextLink>
      )}
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
  },
  actionsWrapper: {
    flexDirection: 'row',
    backgroundColor: theme.colors.OATMEAL,
    paddingVertical: theme.spacing['4'],
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
  },
  removeFromBagWrapper: {
    paddingTop: theme.spacing['4'],
    paddingBottom: theme.spacing['2'],
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 46,
  },
});
