import {
  ButtonFluidIcon,
  ButtonFluidLabel,
  ButtonFluidPressable,
} from './components';

export const ButtonFluid = {
  Pressable: ButtonFluidPressable,
  Label: ButtonFluidLabel,
  Icon: ButtonFluidIcon,
};
