import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { theme } from '@sg/garnish';

import { LoadingAnimation } from '@order/components';

export const SweetpassHomeLoading = ({ testID }: SweetpassHomeLoadingProps) => (
  <SafeAreaView testID={testID} style={styles.wrapper}>
    <LoadingAnimation />
  </SafeAreaView>
);

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.KHAKI_3,
    minHeight: '100%',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type SweetpassHomeLoadingProps = Pick<ViewProps, 'testID'>;
