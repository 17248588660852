// NEUTRALS
export const NEUTRAL_SCALE = {
  NEUTRAL_1: 'rgba(0, 0, 0, 1)',
  'NEUTRAL_1.5': 'rgba(64, 64, 64, 1)',
  NEUTRAL_2: 'rgba(72, 77, 72, 1)',
  NEUTRAL_3: 'rgba(189, 189, 177, 1)',
  NEUTRAL_4: 'rgba(224, 224, 213, 1)',
  NEUTRAL_5: 'rgba(232, 232, 224, 1)',
  NEUTRAL_6: 'rgba(248, 247, 240, 1)',
  NEUTRAL_7: 'rgba(255, 255, 255, 1)',
};

export const NEUTRAL_ALIASES = {
  BLACK: NEUTRAL_SCALE.NEUTRAL_1,
  LIGHT_CHARCOAL: NEUTRAL_SCALE['NEUTRAL_1.5'],
  CHARCOAL: NEUTRAL_SCALE.NEUTRAL_2,
  GRAY: NEUTRAL_SCALE.NEUTRAL_3,
  LIGHT: NEUTRAL_SCALE.NEUTRAL_4,
  LINEN: NEUTRAL_SCALE.NEUTRAL_5,
  CREAM: NEUTRAL_SCALE.NEUTRAL_6,
  WHITE: NEUTRAL_SCALE.NEUTRAL_7,
};

/* -------------------------------------------------------------------------- */

// PRIMARY
export const GREEN_SCALE = {
  GREEN_1: 'rgba(14, 21, 14, 1)',
  GREEN_2: 'rgba(0, 71, 60, 1)',
  GREEN_2_HOVER: 'rgba(5, 47, 41, 1)',
  GREEN_3: 'rgba(45, 107, 82, 1)',
  GREEN_4: 'rgba(169, 193, 169, 1)',
  GREEN_5: 'rgba(216, 229, 214, 1)',
  GREEN_5_HOVER: 'rgba(205, 218, 203, 1)',
  GREEN_6: 'rgba(230, 255, 85, 1)',
  GREEN_6_HOVER: 'rgba(216, 239, 81, 1)',
  GREEN_7: 'rgba(141, 255, 111, 1)',
};

export const GREEN_ALIASES = {
  DARK_KALE: GREEN_SCALE.GREEN_1,
  KALE: GREEN_SCALE.GREEN_2,
  KALE_HOVER: GREEN_SCALE.GREEN_2_HOVER,
  SPINACH: GREEN_SCALE.GREEN_3,
  AVOCADO: GREEN_SCALE.GREEN_4,
  CUCUMBER: GREEN_SCALE.GREEN_5,
  CUCUMBER_HOVER: GREEN_SCALE.GREEN_5_HOVER,
  LIME: GREEN_SCALE.GREEN_6,
  LIME_HOVER: GREEN_SCALE.GREEN_6_HOVER,
  KIWI: GREEN_SCALE.GREEN_7,
};

/* -------------------------------------------------------------------------- */

// SECONDARY
export const KHAKI_SCALE = {
  KHAKI_1: 'rgba(172, 153, 126, 1)',
  KHAKI_2: 'rgba(232, 220, 198, 1)',
  KHAKI_2_HOVER: 'rgba(220, 209, 188, 1)',
  KHAKI_3: 'rgba(244, 243, 231, 1)',
  KHAKI_3_HOVER: 'rgba(234, 233, 221, 1)',
  KHAKI_3_NATURAL_OPAQUE: 'rgba(170, 170, 161, 1)',
};

export const KHAKI_ALIASES = {
  POTATO: KHAKI_SCALE.KHAKI_1,
  QUINOA: KHAKI_SCALE.KHAKI_2,
  QUINOA_HOVER: KHAKI_SCALE.KHAKI_2_HOVER,
  OATMEAL: KHAKI_SCALE.KHAKI_3,
  OATMEAL_HOVER: KHAKI_SCALE.KHAKI_3_HOVER,
  OATMEAL_NATURAL_OPAQUE: KHAKI_SCALE.KHAKI_3_NATURAL_OPAQUE,
};

export const BLUE_SCALE = {
  BLUE_1: 'rgba(163, 190, 202, 1)',
  BLUE_2: 'rgba(214, 233, 233, 1)',
  BLUE_2_HOVER: 'rgba(202, 222, 223, 1)',
  BLUE_3: 'rgba(235, 245, 245, 1)',
};

export const BLUE_ALIASES = {
  OCEAN: BLUE_SCALE.BLUE_1,
  SKY: BLUE_SCALE.BLUE_2,
  SKY_HOVER: BLUE_SCALE.BLUE_2_HOVER,
  CLOUD: BLUE_SCALE.BLUE_3,
};

export const PINK_SCALE = {
  PINK_1: 'rgba(212, 163, 146, 1)',
  PINK_2: 'rgba(238, 192, 173, 1)',
  PINK_3: 'rgba(249, 223, 206, 1)',
};

export const PINK_ALIASES = {
  APRICOT: PINK_SCALE.PINK_1,
  BLUSH: PINK_SCALE.PINK_2,
  PEACH: PINK_SCALE.PINK_3,
};

/* -------------------------------------------------------------------------- */

export const SEASONAL = {
  STRAWBERRY: 'rgba(247, 183, 210, 1)',
  DRAGON_FRUIT: 'rgba(255, 93, 186, 1)',
  POMEGRANATE: 'rgba(166, 24, 70, 1)',
  TANGERINE: 'rgba(255, 148, 0, 1)',
  GRAPEFRUIT: 'rgba(255, 88, 0, 1)',
  SWEET_POTATO: 'rgba(167, 39, 0, 1)',
  SQUASH: 'rgba(236, 213, 125, 1)',
  MUSTARD: 'rgba(231, 157, 0, 1)',
  MUSHROOM: 'rgba(153, 86, 0, 1)',
  LAVENDER: 'rgba(170, 181, 220, 1)',
  BLUEBERRY: 'rgba(65, 75, 200, 1)',
  ACAI_BERRY: 'rgba(32, 62, 112, 1)',
  LILAC: 'rgba(179, 165, 208, 1)',
  PLUM: 'rgba(157, 106, 170, 1)',
  EGGPLANT: 'rgba(108, 62, 115, 1)',
};

export const STATUS = {
  CAUTION: 'rgba(171, 55, 38, 1)',
  SUCCESS: 'rgba(13, 110, 76, 1)',
};

/* -------------------------------------------------------------------------- */

export const OPACITY = {
  TRANSPARENT: 'transparent',
  DARK_KALE: {
    DARKEST: 'rgba(14, 21, 14, 0.8)',
    DARKER: 'rgba(14, 21, 14, 0.64)',
    DARK: 'rgba(14, 21, 14, 0.4)',
    LIGHT: 'rgba(14, 21, 14, 0.24)',
    LIGHTER: 'rgba(14, 21, 14, 0.16)',
    LIGHTEST: 'rgba(14, 21, 14, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(14, 21, 14, 0.04)',
  },
  KALE: {
    DARKEST: 'rgba(0, 71, 60, 0.8)',
    DARKER: 'rgba(0, 71, 60, 0.64)',
    DARK: 'rgba(0, 71, 60, 0.4)',
    LIGHT: 'rgba(0, 71, 60, 0.24)',
    LIGHTER: 'rgba(0, 71, 60, 0.16)',
    LIGHTEST: 'rgba(0, 71, 60, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(0, 71, 60, 0.04)',
  },
  SPINACH: {
    DARKEST: 'rgba(45, 107, 82, 0.8)',
    DARKER: 'rgba(45, 107, 82, 0.64)',
    DARK: 'rgba(45, 107, 82, 0.4)',
    LIGHT: 'rgba(45, 107, 82, 0.24)',
    LIGHTER: 'rgba(45, 107, 82, 0.16)',
    NEAR_LIGHTEST: 'rgba(45, 107, 82, 0.12)',
    LIGHTEST: 'rgba(45, 107, 82, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(45, 107, 82, 0.04)',
  },
  CUCUMBER: {
    DARKEST: 'rgba(216, 229, 214, 0.8)',
    DARKER: 'rgba(216, 229, 214, 0.64)',
    DARK: 'rgba(216, 229, 214, 0.4)',
    LIGHT: 'rgba(216, 229, 214, 0.24)',
    LIGHTER: 'rgba(216, 229, 214, 0.16)',
    LIGHTEST: 'rgba(216, 229, 214, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(216, 229, 214, 0.04)',
  },
  QUINOA: {
    DARKEST: 'rgba(232, 220, 198, 0.8)',
    DARKER: 'rgba(232, 220, 198, 0.64)',
    DARK: 'rgba(232, 220, 198, 0.4)',
    LIGHT: 'rgba(232, 220, 198, 0.24)',
    LIGHTER: 'rgba(232, 220, 198, 0.16)',
    LIGHTEST: 'rgba(232, 220, 198, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(232, 220, 198, 0.04)',
  },
  OATMEAL: {
    DARKEST: 'rgba(244, 243, 231, 0.8)',
    DARKER: 'rgba(244, 243, 231, 0.64)',
    DARK: 'rgba(244, 243, 231, 0.4)',
    LIGHT: 'rgba(244, 243, 231, 0.24)',
    LIGHTER: 'rgba(244, 243, 231, 0.16)',
    LIGHTEST: 'rgba(244, 243, 231, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(244, 243, 231, 0.04)',
  },
  CAUTION: {
    DARKEST: 'rgba(171, 55, 38, 0.8)',
    DARKER: 'rgba(171, 55, 38, 0.64)',
    DARK: 'rgba(171, 55, 38, 0.4)',
    LIGHT: 'rgba(171, 55, 38, 0.24)',
    LIGHTER: 'rgba(171, 55, 38, 0.16)',
    LIGHTEST: 'rgba(171, 55, 38, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(171, 55, 38, 0.04)',
  },
  WHITE: {
    DARKEST: 'rgba(255, 255, 255, 0.8)',
    DARKER: 'rgba(255, 255, 255, 0.64)',
    DARK: 'rgba(255, 255, 255, 0.4)',
    LIGHT: 'rgba(255, 255, 255, 0.24)',
    LIGHTER: 'rgba(255, 255, 255, 0.16)',
    LIGHTEST: 'rgba(255, 255, 255, 0.08)',
    ALMOST_TRANSPARENT: 'rgba(255, 255, 255, 0.04)',
  },
};

/* -------------------------------------------------------------------------- */

export const GENERAL = {
  APP_BACKGROUND: KHAKI_SCALE.KHAKI_3,
  TEXT_COLOR: GREEN_SCALE.GREEN_1,
};

/* -------------------------------------------------------------------------- */

export const HOVER = {
  KHAKI_2: 'rgba(220, 209, 188, 1)',
};
export const COLORS = {
  ...NEUTRAL_SCALE,
  ...GREEN_SCALE,
  ...KHAKI_SCALE,
  ...BLUE_SCALE,
  ...PINK_SCALE,

  ...NEUTRAL_ALIASES,
  ...GREEN_ALIASES,
  ...KHAKI_ALIASES,
  ...BLUE_ALIASES,
  ...PINK_ALIASES,

  ...SEASONAL,
  ...STATUS,

  ...GENERAL,

  OPACITY,
  HOVER,
};
