import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { DisplayText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoHeading = (props: MenuMoreInfoHeadingProps) => {
  const { locationName, orderChannel } = props;

  return (
    <DisplayText sizeMatch={['24']}>
      <FormattedMessage
        {...messages.heading}
        values={{ order_channel: orderChannel, location: locationName }}
      />
    </DisplayText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  heading: {
    defaultMessage: `{order_channel, select,
      pickup {Pickup from {location}}
      delivery {Delivery to {location}}
      outpost {Outpost at {location}}
      other {{location}}
    }`,
    description: 'Menu | More Info | Heading',
  },
});

// ─── Props ───────────────────────────────────────────────────────────────────

type MenuMoreInfoHeadingProps = {
  orderChannel: 'pickup' | 'outpost' | 'delivery';
  locationName: string;
};
