import React, { useLayoutEffect } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ScrollViewWithHeaderTitle, theme, useResponsive } from '@sg/garnish';

import { PageWrapper } from '@order/components';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import { ProductBannerMobile } from '../ProductBanner';
import { ProductDressingAndDetails } from '../ProductDressingAndDetails';
import { ProductModificationsXS } from '../ProductModifications';
import { ProductNoticeBannersMobile } from '../ProductNoticeBanners';
import {
  ProductDetailsCTAs,
  ProductDetailsHeader,
  ProductDetailsIngredients,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductDetailsXS = () => {
  const { isCustomizationActive, product } = useProductDetailsScreenContext();
  const navigation = useNavigation();

  // toggles screen title based on the current mode (idle/customization)
  useLayoutEffect(() => {
    const title = isCustomizationActive ? ' ' : product?.name;

    navigation.setOptions({ title });
  }, [navigation, isCustomizationActive, product?.name]);

  return (
    <View style={styles.wrapper}>
      {isCustomizationActive ? (
        <ProductDetailsXSCustomizationMode />
      ) : (
        <ProductDetailsXSIdleMode />
      )}

      <View>
        <ProductNoticeBannersMobile />
        <ProductDetailsCTAs />
      </View>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductDetailsXSIdleMode = () => {
  const { product } = useProductDetailsScreenContext();

  return (
    <PageWrapper withHeaderTitle withoutFooter>
      <ProductDetailsContentWrapper>
        <ScrollViewWithHeaderTitle>
          <ProductBannerMobile
            imgSrc={product?.asset?.url}
            imgAriaLabel={product?.name}
          />
          <ProductDetailsHeader />
          <ProductDetailsIngredients />
          <ProductDressingAndDetails />
        </ScrollViewWithHeaderTitle>
      </ProductDetailsContentWrapper>
    </PageWrapper>
  );
};

const ProductDetailsXSCustomizationMode = () => {
  const { currentBreakpoint } = useResponsive();

  const headerStyle = currentBreakpoint.isXS
    ? styles.headerCustomizationXS
    : undefined;

  return (
    <ProductDetailsContentWrapper>
      <ProductDetailsHeader style={headerStyle} />
      <ProductDetailsIngredients />
      <ProductModificationsXS />
    </ProductDetailsContentWrapper>
  );
};

const ProductDetailsContentWrapper = (props: ViewProps) => (
  <View style={styles.contentWrapper}>{props.children}</View>
);

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const PDP_BG_COLOR = theme.colors.OATMEAL;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    minHeight: '100%',
    backgroundColor: PDP_BG_COLOR,
  },
  contentWrapper: {
    flex: 1,

    // to avoid painting issues during entering/exit animations,
    // we must use background color also for the nested container/s
    backgroundColor: PDP_BG_COLOR,
  },
  headerCustomizationXS: {
    paddingTop: 0,
  },
});
