import React from 'react';
import { theme } from '@sg/garnish';

import {
  ErrorFallback,
  ForceUpdateMode,
  LoadingAnimation,
  MaintenanceMode,
  useIsForceUpdateModeEnabled,
  useIsMaintenanceModeEnabled,
} from '@order/components';
import { use3rdPartyIntegrations, useAppFonts, useSession } from '@order/hooks';
import { useLaunchDarkly } from '@order/LaunchDarkly';

import { AppContent } from './App.content';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Conditionally renders the app by detecting possible blocks
 * (app fonts loading, "Force Update" mode, "Maintenance" mode, and so on).
 */
export const App = () => {
  const appFontsLoaded = useAppFonts();
  const { sessionReady, sessionError } = useSession();
  const { isReady: isLaunchDarklyReady } = useLaunchDarkly();
  const isForceUpdatedModeEnabled = useIsForceUpdateModeEnabled();
  const isMaintenanceModeEnabled = useIsMaintenanceModeEnabled();

  use3rdPartyIntegrations();

  // ─────────────────────────────────────────────────────────────────────

  const isLoading = !appFontsLoaded || !sessionReady || !isLaunchDarklyReady;

  if (sessionError) return <ErrorFallback error={sessionError} />;

  if (isLoading) {
    return (
      <LoadingAnimation
        withNavbarOffset
        backgroundColor={theme.colors.OATMEAL}
      />
    );
  }

  if (isForceUpdatedModeEnabled) return <ForceUpdateMode />;

  if (isMaintenanceModeEnabled) return <MaintenanceMode />;

  return <AppContent />;
};
