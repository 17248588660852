import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Button, formatDate, IllusSprout, Image } from '@sg/garnish';

import { ignoreTimezone } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The reward card for the bag.
 * It contains an "Apply" / "Applied" button which either applies or removed the reward from the bag.
 */
export const BagReward = (props: BagRewardProps) => {
  const {
    id,
    title,
    expirationDate,
    isApplied,
    isLoading,
    isDisabled,
    onPress,
  } = props;
  const validDate = expirationDate ? ignoreTimezone(expirationDate) : null;

  const { fontScale } = useWindowDimensions();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(id);
  }, [id, onPress]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <View style={styles.rewardRow}>
        <View style={styles.rewardColumn}>
          <BodyText
            style={styles.title}
            numberOfLines={fontScale > 1.1 ? 2 : 3}
            ellipsizeMode="tail"
            sizeMatch={['18']}
          >
            {title}
          </BodyText>

          {validDate ? (
            <BodyText style={styles.expiration} sizeMatch={['14']}>
              <FormattedMessage
                defaultMessage="Ends in {date}"
                description="Bag | Reward | Expiration date"
                values={{ date: formatDate(validDate, 'MM/dd') }}
              />
            </BodyText>
          ) : null}
        </View>

        <Image
          contentFit="cover"
          source={IllusSprout}
          style={styles.illustration}
        />
      </View>

      {isApplied ? (
        <Button
          size="small"
          palette="primary"
          onPress={handleOnPress}
          accessibilityLabel={formatMessage(messages.appliedA11yLabel)}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          <FormattedMessage {...messages.appliedCta} />
        </Button>
      ) : (
        <Button
          size="small"
          palette="spinachMuted"
          onPress={handleOnPress}
          accessibilityLabel={formatMessage(messages.applyA11yLabel)}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          <FormattedMessage {...messages.applyCta} />
        </Button>
      )}
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  applyA11yLabel: {
    defaultMessage: 'Applies this reward to your order',
    description: 'Bag | Rewards | Apply CTA a11y',
  },
  appliedA11yLabel: {
    defaultMessage: 'Removes this reward from your order',
    description: 'Bag | Rewards | Apply CTA a11y',
  },
  applyCta: {
    defaultMessage: 'Apply',
    description: 'Bag | Rewards | Apply CTA',
  },
  appliedCta: {
    defaultMessage: 'Applied',
    description: 'Bag | Rewards | Apply CTA',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 310,
    minWidth: 310,
    maxWidth: 310,
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
    padding: theme.spacing['4'],
    gap: theme.spacing['4'],
    borderRadius: theme.radius.large,
  },
  rewardRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: theme.spacing['4'],
  },
  title: {
    flexWrap: 'wrap',
  },
  rewardColumn: {
    flex: 1,
  },
  expiration: {
    color: theme.colors.CHARCOAL,
  },
  illustration: {
    width: 40,
    height: 40,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagRewardProps = Readonly<{
  id: string;
  title: string;
  expirationDate: string | undefined | null;
  isApplied: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onPress: (id: string) => void;
}>;
