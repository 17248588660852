import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DisplayText, IllusEmpty_2, Image, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const CreditCardFormErrorState = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={styles.wrapper}>
      <Image source={IllusEmpty_2} style={styles.illustration} />

      <DisplayText
        size={4}
        style={styles.heading}
        testID="error-boundary-fallback-title"
      >
        {t('general.error')}
      </DisplayText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['8'],
    backgroundColor: theme.colors.OATMEAL,
  },
  illustration: {
    width: 150,
    height: 150,
    alignSelf: 'center',
  },
  heading: {
    textAlign: 'center',
  },
});
