/* eslint-disable functional/immutable-data */

import { useCallback, useState } from 'react';
import type {
  GestureEventPayload,
  PanGestureHandlerEventPayload,
} from 'react-native-gesture-handler';
import {
  runOnJS,
  useAnimatedGestureHandler,
  useSharedValue,
} from 'react-native-reanimated';

import { withWebTiming } from '@order/utils';

import {
  ANIMATION_CONFIG,
  checkIfFullyExpanded,
  clampVerticalSwipeDistance,
  getFormattedSwipeDistanceFromTop,
  getSnapPointDistanceFromTop,
  getSnapPointIndexByDistanceAndDirection,
  getSwipeDirection,
} from './BottomSheet.helpers';

/**
 * "Bottom Sheet" gestures handler custom hook.
 *
 * Returns a generated handler for <PanGestureHandler> component,
 * a helper for external controls and corresponding state.
 *
 * This hook is using React Native Reanimated library.
 * @see https://docs.swmansion.com/react-native-reanimated/
 */
export const useBottomSheetGestures = ({
  snapPoints = [0],
  initialSnapPointIndex = 0,
  isSwipingEnabled,
  onSnapPointChange,
}: UseBottomSheetGesturesProps) => {
  const [isFullyExpanded, setIsFullyExpanded] = useState(
    checkIfFullyExpanded(snapPoints, initialSnapPointIndex),
  );

  //
  // ─── SWIPE DISTANCE ─────────────────────────────────────────────────────────────
  //

  const initialSwipedDistance = getSnapPointDistanceFromTop(
    snapPoints,
    initialSnapPointIndex,
  );
  const currentSwipedDistance = useSharedValue(initialSwipedDistance);

  //
  // ─── HELPERS ────────────────────────────────────────────────────────────────────
  //

  const swipeToSnapPoint = useCallback(
    (targetSnapPointIndex: number) => {
      'worklet';

      const targetSwipeDistance = getSnapPointDistanceFromTop(
        snapPoints,
        targetSnapPointIndex,
      );

      currentSwipedDistance.value = withWebTiming(
        targetSwipeDistance,
        ANIMATION_CONFIG,
      );

      const isSheetFullyExpanded = checkIfFullyExpanded(
        snapPoints,
        targetSnapPointIndex,
      );

      runOnJS(setIsFullyExpanded)(isSheetFullyExpanded);
      runOnJS(onSnapPointChange)(targetSnapPointIndex, isSheetFullyExpanded);
    },
    [currentSwipedDistance, onSnapPointChange, snapPoints],
  );

  const toggleFullyExpandedState = useCallback(
    (targetSnapPointIndex: number) => {
      'worklet';

      runOnJS(setIsFullyExpanded)(
        checkIfFullyExpanded(snapPoints, targetSnapPointIndex),
      );
    },
    [snapPoints],
  );

  const runIfSwipingEnabled = useCallback(
    (cb: () => void) => {
      'worklet';

      if (isSwipingEnabled) cb();
    },
    [isSwipingEnabled],
  );

  //
  // ─── GESTURE HANDLERS ───────────────────────────────────────────────────────────
  //

  const trackPreviousSwipeDistance: GestureHandler = (_, ctx) => {
    'worklet';

    runIfSwipingEnabled(() => {
      ctx.offsetY = currentSwipedDistance.value;
    });
  };

  /** Change current swipe distance on swiping */
  const handleActiveSwiping: GestureHandler = ({ translationY }, ctx) => {
    'worklet';

    runIfSwipingEnabled(() => {
      const previousDistance = ctx.offsetY || 0;
      const currentDistance = translationY;
      const targetSwipeDistance = previousDistance + currentDistance;

      currentSwipedDistance.value = clampVerticalSwipeDistance(
        snapPoints,
        targetSwipeDistance,
      );
    });
  };

  // snaps position to the closest snap point based on the last swipe distance
  const setClosestSnapPoint: GestureHandler = (
    { translationY: currentSwipeDistance },
    ctx,
  ) => {
    'worklet';

    runIfSwipingEnabled(() => {
      const previousSwipeDistance = ctx.offsetY || 0;
      const targetSwipeDistance = previousSwipeDistance + currentSwipeDistance;
      const swipeDistance = getFormattedSwipeDistanceFromTop(
        snapPoints,
        targetSwipeDistance,
      );
      const direction = getSwipeDirection(currentSwipeDistance);
      const targetSnapPointIndex = getSnapPointIndexByDistanceAndDirection({
        snapPoints,
        swipeDistance,
        direction,
      });

      swipeToSnapPoint(targetSnapPointIndex);
    });
  };

  const handleSwipeGestures = useAnimatedGestureHandler(
    {
      onStart: trackPreviousSwipeDistance,
      onActive: handleActiveSwiping,
      onEnd: setClosestSnapPoint,
    },
    [isSwipingEnabled, snapPoints, isFullyExpanded],
  );

  return {
    handleSwipeGestures,
    swipeToSnapPoint,
    toggleFullyExpandedState,
    isFullyExpanded,
    swipedDistance: currentSwipedDistance,
  };
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

export type UseBottomSheetGesturesProps = Readonly<{
  snapPoints: readonly number[];
  initialSnapPointIndex?: number;
  isSwipingEnabled: boolean;
  onSnapPointChange: (snapPointIndex: number, isFullyExpanded: boolean) => void;
}>;

export type GestureHandlerContext = {
  offsetY: number;
};

export type GestureHandlerEvent = Readonly<
  GestureEventPayload & PanGestureHandlerEventPayload
>;

export type GestureHandler = (
  event: GestureHandlerEvent,
  context: GestureHandlerContext,
) => void;
