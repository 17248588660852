import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, theme, useResponsive } from '@sg/garnish';

/**
 * Warning message for the bag.
 */
export const BagWarning = (props: BagRewardWarningProps) => {
  const { message, cta, accessibilityLabel, onClose } = props;

  const { match } = useResponsive();
  const style = [
    styles.container,
    match([styles.containerXs, styles.containerSm]),
  ];

  return (
    <View style={style}>
      <BodyText sizeMatch={['24']}>{message}</BodyText>

      <Button
        size="large-wide"
        palette="primaryOutline"
        accessibilityLabel={accessibilityLabel}
        onPress={onClose}
      >
        <BodyText style={styles.ctaColor} sizeMatch={['18']}>
          {cta}
        </BodyText>
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    paddingVertical: theme.spacing['10'],
    gap: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerXs: {
    paddingHorizontal: theme.spacing['4'],
  },
  containerSm: {
    paddingHorizontal: theme.spacing['6'],
  },
  ctaColor: {
    color: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRewardWarningProps = {
  message: string;
  cta: string;
  accessibilityLabel: string;
  onClose: () => void;
};
