import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  BodyText,
  Button,
  DisplayText,
  IllusDressing_1,
  Image,
  theme,
  TYPE_CONFIG,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { PageWrapper } from '../PageWrapper';

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundViewWrapper = (props: WrapperProps) => {
  const { withoutFooter, children } = props;

  return (
    <PageWrapper
      contentContainerStyle={styles.container}
      safeAreaEdges={['top']}
      withoutFooter={withoutFooter}
    >
      <View testID="not-found-view" style={styles.wrapper}>
        {children}
      </View>
    </PageWrapper>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundViewImage = (props: ImageProps) => {
  const { illustration } = props;

  return (
    <Image
      source={illustration ?? IllusDressing_1}
      // @ts-expect-error TS(2322): Type 'ViewStyle | TextStyle | ImageStyle' is not a... Remove this comment to see the full error message
      style={styles.img}
      contentFit="contain"
    />
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundViewTitle = (props: TitleProps) => {
  const { title } = props;
  const { t } = useLocalizationContext();

  return (
    <DisplayText
      size={2}
      style={styles.heading}
      accessibilityRole="header"
      {...{ accessibilityLevel: 1 }}
    >
      {title ?? t('not-found-view.heading')}
    </DisplayText>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundViewDescription = (props: DescriptionProps) => {
  const { description } = props;
  const { t } = useLocalizationContext();

  return (
    <BodyText style={styles.text}>
      {description ?? t('not-found-view.text')}
    </BodyText>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundViewCta = (props: CtaProps) => {
  const { btnLabel, onBtnPress } = props;
  const { t } = useLocalizationContext();
  const navigation = useNavigation();

  const handlePressHome = useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'HomeTab',
      params: { screen: 'Home' },
    });
  }, [navigation]);

  return (
    <View style={styles.btnContainer}>
      <Button
        testID="not-found-view.btn"
        palette="primary"
        size="large"
        style={styles.btn}
        onPress={onBtnPress ?? handlePressHome}
      >
        {btnLabel ?? t('not-found-view.return-home')}
      </Button>
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  wrapper: {
    flex: 1,
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: 200,
    height: 200,
  },
  heading: {
    marginVertical: theme.spacing['4'],
    textAlign: 'center',
  },
  // @ts-expect-error TS(2322): Type '{ fontSize: number; fontFamily: string; font... Remove this comment to see the full error message
  text: {
    textAlign: 'center',

    // We override fluid text styles because we need static 16px styles,
    // which the present fluid text styles cannot provide.
    ...TYPE_CONFIG.BODY['16'],
  },
  btnContainer: {
    paddingTop: theme.spacing['10'],
  },
  btn: {
    minWidth: 193,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type WrapperProps = Readonly<{
  withoutFooter?: boolean;
  children: ViewProps['children'];
}>;

type ImageProps = Readonly<{
  illustration?: ComponentProps<typeof Image>['source'];
}>;

type TitleProps = Readonly<{
  title?: string;
}>;

type DescriptionProps = Readonly<{
  description?: string;
}>;

type CtaProps = Readonly<{
  btnLabel?: string;
  onBtnPress?: () => void;
}>;
