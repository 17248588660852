import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  DisplayText,
  IconLink,
  QRCodeScanner,
  ScrollViewWithHeaderTitle,
  theme,
} from '@sg/garnish';

import {
  AccountScreenTitle,
  CreditOverviewPromoCodeForm,
  TransparentModal,
  useHeaderRight,
} from '@order/components';
import { usePromoCode } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';

export const CreditAddScreen = () => {
  const { t } = useLocalizationContext();

  const title = t('account.scan-to-pay.enter-promo-code');

  const {
    promoCode,
    promoCodeInvalid,
    isPromoCodeApplied,
    isSubmittingPromoCode,
    setPromoCode,
    submitPromoCode,
  } = usePromoCode();
  const navigation = useNavigation();

  const [isScanning, setIsScanning] = useState(false);

  // ─────────────────────── Telemetry ──────────────────────────────────

  const { track } = useTelemetry();

  useTrackEffect('payment_credit.view_promo_code');

  const onMonitoredCameraShown = useCallback(() => {
    track('payment_credit.camera_opened');
  }, [track]);

  // ─────────────────────── Callbacks ──────────────────────────────────

  const handleShowScanner = useCallback(() => {
    onMonitoredCameraShown();
    setIsScanning(true);
  }, [onMonitoredCameraShown]);

  const handleHideScanner = useCallback(() => {
    setIsScanning(false);
  }, []);

  const handleGoBack = useCallback(() => {
    setPromoCode('');
    navigation.goBack();
  }, [navigation, setPromoCode]);

  const onPromoCodeScanned = useCallback(
    (code: string) => {
      setPromoCode(code);
      setIsScanning(false);
    },
    [setIsScanning, setPromoCode],
  );

  // ─────────────────────── Navigation ─────────────────────────────────

  const navigationHeaderRightItems = useMemo<NavigationHeaderRightItems>(
    () => [
      {
        key: 'account.gift-code.header.close-btn',
        icon: 'IconClose',
        accessibilityLabel: t('general.close'),
        onPress: handleGoBack,
      },
    ],
    [handleGoBack, t],
  );
  const navigationHeaderRight = useHeaderRight({
    items: navigationHeaderRightItems,
  });

  // removes the header when scanning.
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: !isScanning,
      headerRight: navigationHeaderRight,
    });
  }, [isScanning, navigation, navigationHeaderRight]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TransparentModal size="full" onClose={handleGoBack} disableAnimation>
      {isScanning ? (
        <>
          <FloatingCloseScanIcon onClose={handleHideScanner} />
          <ScannerComponent
            onPromoCodeScanned={onPromoCodeScanned}
            handleHideScanner={handleHideScanner}
          />
        </>
      ) : (
        <ScrollViewWithHeaderTitle testID="account.gift-code.root.container">
          <View style={styles.padding}>
            <AccountScreenTitle title={title} />
            <CreditOverviewPromoCodeForm
              buttonPlacement="bottom"
              hasPromoCodeError={promoCodeInvalid}
              promoCode={promoCode}
              isPromoCodeApplied={isPromoCodeApplied}
              isSubmittingPromoCode={isSubmittingPromoCode}
              onPressApplyPromoCode={submitPromoCode}
              setPromoCode={setPromoCode}
              handleShowScanner={handleShowScanner}
            />
          </View>
        </ScrollViewWithHeaderTitle>
      )}
    </TransparentModal>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const ScannerComponent = (props: PromoCodeScannerProps) => {
  const { handleHideScanner, onPromoCodeScanned } = props;

  const { t } = useLocalizationContext();
  const [requestPermissionResponse, onRequestPermissionResponse] =
    useState(false);

  return (
    <View style={styles.scanner}>
      <QRCodeScanner
        onRequestPermissionResponse={onRequestPermissionResponse}
        onScanSuccess={onPromoCodeScanned}
      />
      <View style={styles.scannerMessageContainer}>
        <DisplayText style={styles.text} size={4}>
          {t(
            requestPermissionResponse
              ? 'account.scan-to-pay.scan-gift-code-message'
              : 'account.scan-to-pay.scan-gift-code-camera-permission-message',
          )}
        </DisplayText>

        <Button size="large" onPress={handleHideScanner}>
          {t('general.cancel')}
        </Button>
      </View>
    </View>
  );
};

const FloatingCloseScanIcon = ({
  onClose,
}: Readonly<{
  onClose: () => void;
}>) => {
  const { t } = useLocalizationContext();

  return (
    <IconLink
      width={24}
      height={24}
      name="IconClose"
      testID="qr-code.scanner.close"
      color={theme.colors.GREEN_1}
      accessibilityLabel={t('general.close')}
      onPress={onClose}
      style={styles.floatingButton}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  padding: {
    flex: 1,
    backgroundColor: theme.colors.KHAKI_3,
    paddingHorizontal: theme.spacing['4'],
  },
  floatingButton: {
    position: 'absolute',
    padding: theme.spacing['1'],
    top: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: theme.zIndex.popover,
    backgroundColor: theme.colors.OPACITY.OATMEAL.DARKEST,
    borderRadius: theme.radius.xxxlarge,
    ...theme.elevations['4'],
  },
  scanner: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.BLACK,
  },
  scannerMessageContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: theme.spacing['4'],
    justifyContent: 'space-evenly',
    backgroundColor: theme.colors.OATMEAL,
    borderTopLeftRadius: theme.radius.medium,
    borderTopRightRadius: theme.radius.medium,
    paddingHorizontal: theme.spacing['4'],
  },
  text: {
    paddingBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type NavigationHeaderRightItems = Parameters<typeof useHeaderRight>[0]['items'];
type PromoCodeScannerProps = Readonly<{
  onPromoCodeScanned: (code: string) => void;
  handleHideScanner: () => void;
}>;
