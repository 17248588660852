import React, { useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Main } from '@expo/html-elements';
import {
  DisplayText,
  Image,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { LoadingAnimation } from '../LoadingAnimation';
import { PageWrapperSafeAreaView } from '../PageWrapper/PageWrapperSafeAreaView';
import { useMaintenanceModeData } from './hooks';

// ────────────────────────────────────────────────────────────────────────────────

export const MaintenanceMode = () => {
  const { t } = useLocalizationContext();
  const { body, illustration, isLoadingContentfulData } =
    useMaintenanceModeData();
  const { disable: disableNoticeBanners, enable: enableNoticeBanners } =
    useNoticeBannersStackContext();

  // ─────────────────────────────────────────────────────────────────

  // block new notifications, while in maintenance mode
  useLayoutEffect(() => {
    disableNoticeBanners();

    return enableNoticeBanners;
  }, [disableNoticeBanners, enableNoticeBanners]);

  // ─────────────────────────────────────────────────────────────────

  if (isLoadingContentfulData) {
    return <LoadingAnimation />;
  }

  // ─────────────────────────────────────────────────────────────────

  return (
    <View testID="maintenance-mode" style={styles.contentContainerStyle}>
      <PageWrapperSafeAreaView edges={['top']}>
        <Main style={styles.wrapper}>
          <Image
            source={illustration}
            style={styles.img}
            aria-label={t('general.maintenance-image.a11y')}
            contentFit="contain"
          />

          <DisplayText
            size={3}
            style={[styles.heading, styles.headingSM]}
            accessibilityRole="header"
            // @ts-expect-error TS(2322): Type '{ children: string; size: number; style: { t... Remove this comment to see the full error message
            accessibilityLevel={1}
          >
            {body}
          </DisplayText>
        </Main>
      </PageWrapperSafeAreaView>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainerStyle: {
    flexGrow: 1,
    backgroundColor: theme.colors.OATMEAL,
    alignItems: 'center',
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 660,
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
  },
  img: {
    width: 200,
    height: 200,
  },
  heading: {
    marginTop: theme.spacing['4'],
    textAlign: 'center',
  },
  headingSM: {
    textAlign: 'center',
  },
});
