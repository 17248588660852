import type { ComponentProps, Ref } from 'react';
import React, { forwardRef, useCallback, useRef } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import type { usePlacesAutocomplete } from '@sg/garnish';
import {
  getSubstringsByPositions,
  HighlightWords,
  theme,
  usePressableState,
  useResponsive,
  VStack,
  webOnlyStyles,
} from '@sg/garnish';

import type { Customer } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { ErrorMessage } from './ErrorMessage';
import { LoadingResults } from './LoadingResults/LoadingResults.desktop';
import { NoResults } from './NoResults';

export const AddressSearchResults = forwardRef(
  (props: AddressSearchResultsProps, ref: Ref<ScrollView>) => {
    const { match } = useResponsive();
    const { customerAddresses, predictionsData, skipLoading, style, onPress } =
      props;
    const { loading, error, noResults, predictions } = predictionsData;

    const containerStyles =
      style ??
      match([
        styles.addressSearchResultsContainerXs,
        styles.addressSearchResultsContainerSm,
      ]);

    return (
      <ScrollView
        testID="location.auto-complete-results"
        ref={ref}
        style={containerStyles}
      >
        {/* eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10 */}
        {loading && !skipLoading ? (
          <LoadingResults />
        ) : noResults ? (
          <NoResults noAutocompleteResults />
        ) : (
          <VStack gap={0} hasDivider>
            {predictions
              .filter(({ place_id }) => Boolean(place_id))
              .map((location) => (
                <AddressSearchResult
                  key={location.place_id}
                  placeId={location.place_id}
                  customerAddresses={customerAddresses}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  text={removeCountry(location.description, location.terms)}
                  matchedSubstrings={location.matched_substrings}
                  onPress={onPress}
                />
              ))}
          </VStack>
        )}

        {error ? <ErrorMessage minimalStyles /> : null}
      </ScrollView>
    );
  },
);

const removeCountry = (location: string, terms: readonly Term[]): string => {
  const lastTerm = (terms?.length ?? 1) - 1;
  const countryTerm = terms?.[lastTerm]?.value;

  if (!countryTerm) return location;
  const country = `, ${countryTerm}`;

  if (location.endsWith(country)) return location.replace(country, '');

  return location;
};

// ----------------------------------------
// ---- subcomponents
// ----------------------------------------

export const AddressSearchResult = ({
  placeId,
  text,
  matchedSubstrings,
  customerAddresses,
  onPress,
}: Readonly<{
  placeId: string;
  text: string;
  // @ts-expect-error TS(2503): Cannot find namespace 'google'.
  matchedSubstrings: readonly google.maps.places.PredictionSubstring[];
  customerAddresses?: Customer['addresses'];
  onPress?: (placeId: string) => void;
}>) => {
  const { t } = useLocalizationContext();

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);
  const highlights = getSubstringsByPositions(text, matchedSubstrings as never);
  const existingAddress = customerAddresses?.find(
    ({ googlePlaceId }) => placeId === googlePlaceId,
  );
  const customerAddress =
    existingAddress?.name && `${existingAddress.name} - ${text}`;
  const address = customerAddress ?? text;

  const handleOnPress = useCallback(() => {
    if (onPress) onPress(placeId);
  }, [placeId, onPress]);

  return (
    <TouchableOpacity
      ref={ref}
      onPress={handleOnPress}
      accessibilityRole="button"
      accessibilityLabel={t('addresses.select-location.label', {
        text,
      })}
      accessibilityHint={t('addresses.select-location.hint')}
      testID={`location-search-result-${text}`}
    >
      <View
        style={[
          styles.addressSearchResult,
          webOnlyStyles({ transition: `color ${theme.transitions.base}ms` }),
        ]}
        pointerEvents="box-only"
      >
        <HighlightWords
          text={address}
          highlights={highlights}
          numberOfLines={1}
          underline={isInteracting}
        />
      </View>
    </TouchableOpacity>
  );
};

// ──── Types ──────────────────────────────────────────────────

type AddressSearchResultsProps = Readonly<{
  style?: ComponentProps<typeof View>['style'];
  skipLoading?: boolean;
  customerAddresses?: Customer['addresses'];
  predictionsData: Pick<
    ReturnType<typeof usePlacesAutocomplete>,
    'noResults' | 'error' | 'loading' | 'predictions'
  >;
  onPress?: (placeId: string) => void;
}>;

type Term = Readonly<{ value: string }>;

// ─── Constants ─────────────────────────────────────────────────────────

export const SEARCH_RESULT_HEIGHT = 56;

// ──── Styles ───────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  addressSearchResultsContainerXs: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.fixed + 1, // cover up components that are below
    padding: theme.spacing['4'],
    backgroundColor: theme.colors.OATMEAL,
  },
  addressSearchResultsContainerSm: {
    backgroundColor: theme.colors.OATMEAL,
    padding: theme.spacing['4'],
  },
  addressSearchResult: {
    height: SEARCH_RESULT_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
