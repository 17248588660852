import type { MixedDressingDetails } from '../../../../graphql/types';
import { DressingWeight } from '../../../../graphql/types';
import type {
  ProductModificationsModelContext,
  ProductModificationsModelEvent,
} from '../ProductModifications.machine';
import { ProductModificationsModel } from '../ProductModifications.model';
import type {
  DressingDetails,
  DressingsDetails,
} from '../ProductModifications.types';
import { sortActiveIngredients } from './activeModifications';
import {
  getModificationKindLimit,
  handleIngredientModificationChange,
} from './common';
import { getComputedModifications } from './computedModifications';

// ─── ACTIONS ────────────────────────────────────────────────────────────────────

export const withIncreasedDressingPortionNumber =
  ProductModificationsModel.assign((ctx, event) => {
    const handleChange = handleIngredientModificationChange('addition');

    return handleChange(ctx, event);
  }, 'INCREASE_DRESSING_PORTION_NUMBER');

export const withDecreasedDressingPortionNumber =
  ProductModificationsModel.assign((ctx, event) => {
    const handleChange = handleIngredientModificationChange('removal');

    return handleChange(ctx, event);
  }, 'DECREASE_DRESSING_PORTION_NUMBER');

export const withIncreasedDressingWeight = ProductModificationsModel.assign(
  (ctx, event) => {
    const { ingredientModification } = event;
    const dressingId = ingredientModification?.ingredient?.id;

    return {
      mixedDressingDetails: changeDressingWeight('increase', ctx, dressingId),
    };
  },
  'INCREASE_DRESSING_WEIGHT',
);

export const withDecreasedDressingWeight = ProductModificationsModel.assign(
  (ctx, event) => {
    const { ingredientModification } = event;
    const dressingId = ingredientModification?.ingredient?.id;

    return {
      mixedDressingDetails: changeDressingWeight('decrease', ctx, dressingId),
    };
  },
  'DECREASE_DRESSING_WEIGHT',
);

export const withMixedDressingDetails = ProductModificationsModel.assign(
  (ctx) => {
    const mixableDressings = ctx.active.filter((active) => {
      return checkIfDressingKind(active.kind) && active.mixable;
    });

    const mixedDressingDetails = mixableDressings.map<MixedDressingDetails>(
      (mixableDressing) => {
        const { ingredient } = mixableDressing;
        const existingMixableDressingDetails = ctx.mixedDressingDetails.find(
          (dressing) => dressing.ingredientId === ingredient.id,
        );

        return {
          ingredientId: ingredient.id,
          weight:
            existingMixableDressingDetails?.weight ?? DressingWeight.Medium,
        };
      },
    );

    return { mixedDressingDetails };
  },
);

export const resetMixedDressingDetails = ProductModificationsModel.assign({
  mixedDressingDetails: [],
});

export const resetActiveDressingModifications =
  ProductModificationsModel.assign((ctx) => {
    const activeWithResetDressings = ctx.active.map(
      (activeIngredientModification) => {
        const { kind } = activeIngredientModification;
        const isDressing = checkIfDressingKind(kind);

        if (!isDressing) return activeIngredientModification;

        return { ...activeIngredientModification, quantity: 1 };
      },
    );

    const computedModifications = getComputedModifications(
      ctx.defaults,
      activeWithResetDressings,
    );

    return {
      active: sortActiveIngredients(activeWithResetDressings),
      ...computedModifications,
    };
  });

// ─── HELPERS ────────────────────────────────────────────────────────────────────

const changeDressingWeight = (
  action: 'increase' | 'decrease',
  ctx: ProductModificationsModelContext,
  dressingId = '',
) => {
  const { mixedDressingDetails } = ctx;

  if (!dressingId) return mixedDressingDetails;

  return mixedDressingDetails.map((mixedDressing: MixedDressingDetails) => {
    const isTargetDressing = mixedDressing.ingredientId === dressingId;
    const currentWeight = mixedDressing.weight;
    const isDressingHasWeight = currentWeight !== undefined;
    const shouldUpdateWeight = isTargetDressing && isDressingHasWeight;

    if (shouldUpdateWeight) {
      const currentWeightInd = DRESSING_WEIGHTS_MAP.indexOf(currentWeight);
      const targetWeight =
        action === 'increase'
          ? DRESSING_WEIGHTS_MAP[currentWeightInd + 1]
          : DRESSING_WEIGHTS_MAP[currentWeightInd - 1];
      const updatedWeight = targetWeight ?? currentWeight;

      return { ...mixedDressing, weight: updatedWeight };
    }

    return mixedDressing;
  });
};

// ─── EXTERNAL HELPERS ───────────────────────────────────────────────────────────

export const getDressingsDetails = (ctx: ProductModificationsModelContext) => {
  const { active, mixedDressingDetails } = ctx;
  const mixedDressingDetailsMap = new Map(
    mixedDressingDetails.map(({ ingredientId, weight }) => [
      ingredientId,
      weight,
    ]),
  );

  return active.reduce<DressingsDetails>(
    (allDressingsDetails, activeIngredientModification) => {
      const { kind, ingredient, quantity } = activeIngredientModification;
      const { id, name } = ingredient;

      const isDressing = checkIfDressingKind(kind);

      if (!isDressing) return allDressingsDetails;

      const weight = mixedDressingDetailsMap.get(id);
      const portionsNumber = quantity ?? 1;
      const dressingDetail: DressingDetails = {
        id,
        name,
        weight,
        portionsNumber,
        ingredientModification: activeIngredientModification,
      };

      return [...allDressingsDetails, dressingDetail];
    },
    [],
  );
};

/**
 * Returns corresponding stepper value for provided weight.
 */
export const getWeightValue = (weight: DressingWeight) => {
  return DRESSING_WEIGHTS_MAP.indexOf(weight) + 1;
};

export const getDressingPortionsMaxNumber = (
  ctx: ProductModificationsModelContext,
) => {
  return (
    getModificationKindLimit('dressings', ctx.limitations)?.kindMaxLimit ??
    MAX_DRESSINGS_PORTIONS_NUMBER
  );
};

export const getSelectedDressingsPortionsNumber = (
  ctx: ProductModificationsModelContext,
) => {
  const { active } = ctx;

  return active.reduce((portionsNumber, dressing) => {
    if (!checkIfDressingKind(dressing.kind)) return portionsNumber;

    return portionsNumber + (dressing.quantity ?? 0);
  }, 0);
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

export const MAX_DRESSINGS_PORTIONS_NUMBER = 3;

const DRESSING_WEIGHTS_MAP: readonly DressingWeight[] = [
  DressingWeight.Light,
  DressingWeight.Medium,
  DressingWeight.Heavy,
];

// ────────────────────────────────────────────────────────────────────────────────

export const checkIfDressingPortionCannotBeIncreased = (
  ctx: ProductModificationsModelContext,
  event: ProductModificationsModelEvent,
) => {
  const { type } = event;
  const selectedPortionsNumber = getSelectedDressingsPortionsNumber(ctx);

  const shouldCheckPortionsNumber =
    type === 'INCREASE_DRESSING_PORTION_NUMBER' ||
    type === 'ADD_INGREDIENT_MODIFICATION';

  if (shouldCheckPortionsNumber) {
    return selectedPortionsNumber >= getDressingPortionsMaxNumber(ctx);
  }

  return false;
};

export const checkIfDressingModeShouldBeMixedIn = (
  ctx: ProductModificationsModelContext,
) => {
  return ctx.mixedDressingDetails.length > 0;
};

export const checkIfNoDressingsSelected = (
  ctx: ProductModificationsModelContext,
) => {
  const { active, defaults, isCustom } = ctx;

  const isDressingSelected = active.some(({ kind }) =>
    checkIfDressingKind(kind),
  );
  const hasDefaultDressing = [...defaults.values()].some(({ kind }) =>
    checkIfDressingKind(kind),
  );

  if (isCustom && !isDressingSelected) return true;

  return hasDefaultDressing && !isDressingSelected;
};

export const checkIfDressingKind = (kind: string | undefined | null) =>
  kind === 'dressings';
