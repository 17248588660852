import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import type { NoticeBannersStack } from '@sg/garnish';
import {
  NOTICE_BANNER_PADDING,
  theme,
  useModalContext,
  useResponsive,
} from '@sg/garnish';

import { useActiveScreenOptions } from '@order/hooks';

import { checkIfPresentedAsModalWithoutHeader } from '../../navigation';
import { DESKTOP_HEADER_HEIGHT } from '../Header/constants';
import { NoticeBannersOverlayStack } from './components/';

// ────────────────────────────────────────────────────────────────────────────────

export const NoticeBannersOverlay = () => {
  const { currentBreakpoint } = useResponsive();
  const safeAreaInsets = useSafeAreaInsets();
  const modalContext = useModalContext();

  const activeScreenOptions = useActiveScreenOptions();

  // To identify whether the active screen is presented as a `Modal`
  // or whether a `Modal` component (garnish) is open.
  const isFullScreenModal =
    checkIfPresentedAsModalWithoutHeader(activeScreenOptions) ||
    modalContext?.hasActiveModal === true;

  // ─────────────────────────────────────────────────────────────────

  const isPresentedFromBottom = currentBreakpoint.isXS;
  const topOffset = isFullScreenModal ? 0 : DESKTOP_HEADER_HEIGHT;
  const offsetsStyle = useStyle(
    () => (isPresentedFromBottom ? { bottom: 0 } : { top: topOffset }),
    [isPresentedFromBottom, topOffset],
  );

  const bannerPadding: ComponentProps<
    typeof NoticeBannersStack
  >['bannerPadding'] = isPresentedFromBottom
    ? {
        ...NOTICE_BANNER_PADDING,
        bottom: Math.max(
          NOTICE_BANNER_PADDING.bottom,
          safeAreaInsets.bottom + theme.spacing['2'],
        ),
      }
    : {
        ...NOTICE_BANNER_PADDING,
        top: Math.max(
          NOTICE_BANNER_PADDING.top,
          safeAreaInsets.top + theme.spacing['2'],
        ),
      };

  // ─────────────────────────────────────────────────────────────────

  return (
    <NoticeBannersOverlayStack
      bannerPadding={bannerPadding}
      style={[styles.stack, StyleSheet.absoluteFill, offsetsStyle]}
      pointerEvents="box-none"
    />
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  stack: {
    zIndex: theme.zIndex.overModal,
  },
});
