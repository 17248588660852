import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { LocationSearch, TransparentModal } from '@order/components';

import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import {
  useCancelReorder,
  useNavigationActions,
  useReorderState,
} from '../../hooks';
import {
  useReorderLeftIcon,
  useReorderRightIcon,
} from '../ReorderConfirmLocation/ReorderConfirmLocation.components';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderChangeLocation = (props: ReorderChangeLocationProps) => {
  const { navigation } = props;

  const {
    reordering: isReordering,
    restaurantSlug: reorderRestaurantSlug,
    deliveryOrderDetail,
  } = useReorderState();

  const activeReorderRestaurantSlug = isReordering
    ? reorderRestaurantSlug
    : undefined;
  const activeReorderDeliveryAddressId = isReordering
    ? deliveryOrderDetail?.addressId
    : undefined;

  const { currentStep } = useReorderState();
  const cancelReorder = useCancelReorder();
  const { currentBreakpoint } = useResponsive();

  const { onBack, onClose } = useNavigationActions();
  const leftIcon = useReorderLeftIcon();
  const rightIcon = useReorderRightIcon();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: onBack && currentBreakpoint.isXS ? () => leftIcon : undefined,
      headerRight:
        onClose && currentBreakpoint.isXS ? () => rightIcon : undefined,
      headerShown: currentBreakpoint.isXS,
    });
  }, [
    navigation,
    leftIcon,
    rightIcon,
    currentBreakpoint.isXS,
    onBack,
    onClose,
  ]);

  if (currentStep !== 'locationSearch' && Platform.OS === 'web') return null;

  return (
    <TransparentModal
      testID="reorder-change-location"
      disableAnimation={currentBreakpoint.isXS}
      size="large"
      offset="10%"
      onClose={cancelReorder}
    >
      <LocationSearch
        withSafeAreaInsets
        interactedLocationRestaurantSlug={activeReorderRestaurantSlug}
        interactedLocationDeliveryAddressId={activeReorderDeliveryAddressId}
      />
    </TransparentModal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderChangeLocationProps = ModalStackChildProps<'ReorderChangeLocation'>;
