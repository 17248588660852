import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, TextFieldWithBackground, theme } from '@sg/garnish';

import { type BagReward } from '../../../machines/ordering-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The promo field for the bag.
 * It contains an "Apply" CTA next to a text field.
 */
export const BagPromoField = (props: BagPromoFieldProps) => {
  const { rewards, isLoading, isDisabled, onPress } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const [code, setCode] = useState('');
  const handleOnPress = useCallback(() => {
    onPress(code);
  }, [code, onPress]);

  // ─────────────────────────────────────────────────────────────────────
  // Clear the field after successfully applying (the rewards will change)

  useEffect(() => {
    setCode('');
  }, [rewards]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <TextFieldWithBackground
          hideNoticeSpace
          value={code}
          editable={!isDisabled}
          containerStyle={styles.field}
          placeholderTextColor={theme.colors.CHARCOAL}
          placeholder={formatMessage(messages.fieldPlaceholder)}
          accessibilityLabel={formatMessage(messages.fieldPlaceholder)}
          onChangeText={setCode}
        />

        <Button
          palette="primary"
          isLoading={isLoading}
          disabled={isDisabled}
          accessibilityLabel={formatMessage(messages.addA11yLabel)}
          onPress={handleOnPress}
        >
          <BodyText
            style={isDisabled ? styles.ctaDisabled : styles.cta}
            sizeMatch={['16']}
          >
            <FormattedMessage {...messages.addCta} />
          </BodyText>
        </Button>
      </View>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  fieldPlaceholder: {
    defaultMessage: 'Enter promo code',
    description: 'Bag | Promo | Field placeholder',
  },
  addCta: {
    defaultMessage: 'Add',
    description: 'Bag | Promo | Promo field CTA',
  },
  addA11yLabel: {
    defaultMessage: 'Adds this promo code to your order',
    description: 'Bag | Promo | Add CTA a11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: theme.spacing['2'],
    paddingVertical: theme.spacing['4'],
  },
  row: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing['2'],
  },
  field: {
    flex: 1,
  },
  cta: {
    color: theme.colors.WHITE,
  },
  ctaDisabled: {
    color: theme.colors.OPACITY.DARK_KALE.DARK,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagPromoFieldProps = Readonly<{
  rewards: readonly BagReward[];
  isLoading: boolean;
  isDisabled: boolean;
  onPress: (code: string) => void;
}>;
