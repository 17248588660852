import type { ComponentProps } from 'react';
import React, { useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { PressableProps, ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState, useResponsive, useToggleState } from '../../hooks';
import { ProductIngredientsList } from '../ProductIngredientsList';
import { BodyText } from '../Text';
import {
  LineItemEditableHeader,
  LineItemQuantityStepper,
  LineItemThumbnail,
  QUANTITY_STEPPER_SIZE_SM,
  QUANTITY_STEPPER_SIZE_XS,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────

export const ProductLineItemCardV2 = (props: ProductLineItemCardProps) => {
  const {
    cloudinaryImageUrl = '',
    productName,
    quantity = 0,
    description = '',
    price,
    addedIngredients = [],
    removedIngredients = [],
    isModifiable = false,
    isDisabled = false,
    showLabels = true,
    onPress,
    onNameChange,
    onQuantityChange,

    // Telemetry and feature flag for editing name.
    isEditingNameEnabled,
    onStartEditingName,
  } = props;

  const { match } = useResponsive();

  // ─── Localization ─────────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const addMessage = formatMessage(messages.add);
  const removeMessage = formatMessage(messages.remove);
  const addedIngredientsLabel = showLabels ? addMessage : '';
  const removedIngredientsLabel = showLabels ? removeMessage : '';

  // ─── Name Editing ─────────────────────────────────────────────────────────

  const {
    value: isEditing,
    toggleOn: setEditing,
    toggleOff: unsetEditing,
  } = useToggleState(false);

  const handleStartEditing = useCallback(() => {
    onStartEditingName();
    setEditing();
  }, [onStartEditingName, setEditing]);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.card}>
      <View style={styles.detailsRow}>
        <DynamicPressableContent
          isDisabled={isDisabled || !isModifiable}
          isEditing={isEditing}
          onPress={onPress}
        >
          {cloudinaryImageUrl ? (
            <View style={styles.thumbnail}>
              <LineItemThumbnail cloudinaryImageUrl={cloudinaryImageUrl} />
            </View>
          ) : null}

          <View style={match([styles.detailsColumnXs, styles.detailsColumnSm])}>
            <LineItemEditableHeader
              productName={productName}
              isDisabled={isDisabled}
              isEditing={isEditing}
              unsetEditing={unsetEditing}
              onNameChange={onNameChange}
            />

            <ProductIngredientsList
              sizeMatch={['14']}
              addedIngredients={addedIngredients}
              removedIngredients={removedIngredients}
              addedIngredientsLabel={addedIngredientsLabel}
              removedIngredientsLabel={removedIngredientsLabel}
            />

            {description ? (
              <BodyText sizeMatch={['14']} style={styles.description}>
                {description}
              </BodyText>
            ) : null}

            <BodyText sizeMatch={['14']}>{price}</BodyText>
          </View>
        </DynamicPressableContent>

        {isEditingNameEnabled ? (
          <View style={styles.editIcon}>
            <LineItemEditableHeader.Icon
              isModifiable={isModifiable}
              isDisabled={isDisabled}
              setEditing={handleStartEditing}
            />
          </View>
        ) : null}
      </View>

      <View style={match([styles.quantityStepperXs, styles.quantityStepperSm])}>
        <LineItemQuantityStepper
          quantity={quantity}
          isDisabled={isDisabled}
          onQuantityChange={onQuantityChange}
        />
      </View>
    </View>
  );
};

// ─── Sub Components ─────────────────────────────────────────────────────────

/**
 * This component will either render the {PressableContent}
 * or a {View} depending on whether the name is being edited.
 */
const DynamicPressableContent = (props: PressableContentProps) => {
  const { isDisabled, isEditing, children, onPress } = props;
  const Wrapper = isEditing ? View : PressableContent;

  return (
    <Wrapper
      style={styles.pressableContent}
      disabled={isDisabled}
      onPress={onPress}
    >
      {children}
    </Wrapper>
  );
};

const PressableContent = (
  props: Pick<PressableProps, 'children' | 'disabled' | 'onPress'>,
) => {
  const { formatMessage } = useIntl();

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);

  const shouldUseHoverStyle = !props.disabled && isHovered;
  const hoveredStyle = shouldUseHoverStyle && styles.hoveringPressableContent;

  return (
    <Pressable
      ref={ref}
      disabled={props.disabled}
      style={[styles.pressableContent, hoveredStyle]}
      accessibilityRole="button"
      accessibilityLabel={formatMessage(messages.pressableA11y)}
      onPress={props.onPress}
    >
      {props.children}
    </Pressable>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    paddingVertical: theme.spacing['6'],
  },
  thumbnail: {
    marginTop: theme.spacing['2'],
  },
  editIcon: {
    marginTop: theme.spacing['2'],
  },
  quantityStepperXs: {
    marginTop: theme.spacing['3'],
    marginLeft: theme.spacing['20'],
  },
  quantityStepperSm: {
    marginTop: theme.spacing['3'],
    marginLeft: theme.spacing['30'],
  },
  detailsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['3'],
  },
  detailsColumnXs: {
    flex: 1,
    gap: theme.spacing['1'],
    paddingBottom: theme.spacing['2'] + QUANTITY_STEPPER_SIZE_XS,
  },
  detailsColumnSm: {
    flex: 1,
    gap: theme.spacing['1'],
    paddingBottom: theme.spacing['2'] + QUANTITY_STEPPER_SIZE_SM,
  },
  pressableContent: {
    flex: 1,
    flexDirection: 'row',
    gap: theme.spacing['3'],
    marginTop: -theme.spacing['2'],
    marginLeft: -theme.spacing['2'],
    padding: theme.spacing['2'],
  },
  hoveringPressableContent: {
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  description: {
    color: theme.colors.CHARCOAL,
    marginBottom: theme.spacing['1'],
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  pressableA11y: {
    defaultMessage: 'Open product details',
    description: 'Product Line Item | Pressable a11y',
  },
  add: {
    defaultMessage: 'Add',
    description: 'Product Line Item | Add',
  },
  remove: {
    defaultMessage: 'Remove',
    description: 'Product Line Item | Remove',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type ProductLineItemCardProps = Readonly<{
  productName: string;
  description?: string;
  cloudinaryImageUrl?: string;
  price?: string;
  quantity?: number;
  isDisabled?: boolean;
  isModifiable?: boolean;
  showLabels?: boolean;
  addedIngredients: Ingredients;
  removedIngredients: Ingredients;
  isEditingNameEnabled: boolean;
  onStartEditingName: () => void;
  onNameChange: (updatedName: string) => void;
  onPress: () => void;
  onQuantityChange: (selectedQuantity: number) => void;
}>;

type Ingredients = ComponentProps<
  typeof ProductIngredientsList
>['addedIngredients'];

type PressableContentProps = {
  children: ViewProps['children'];
  isDisabled: boolean;
  isEditing: boolean;
  onPress: () => void;
};
